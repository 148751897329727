import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Icon, Typography, Tooltip } from '@mui/material';
import { StoreUtil } from 'doctivity-shared/utils';
import { DynamicTable } from 'components';
import { ActivityLogsUtil } from 'utils';
import { activityLogsByUserId } from 'store/actions/activityLogsActions';
import { queryOrganizations } from 'store/actions/organizationsActions';
import { OrganizationsTabs } from './OrganizationsTabs';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
});

class RecentOrganizationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        label: '',
        key: 'affiliated',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: (affiliated) => {
          if (affiliated?.length > 0) {
            return (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 200,
                    }}
                  >
                    Affiliated Organization
                  </Typography>
                }
              >
                <div
                  style={{
                    width: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon className={props.classes.affiliatedIcon}>hub</Icon>
                </div>
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        label: 'Name',
        key: 'name',
        fulltext: true,
        sortable: false,
      },
      {
        label: 'Type',
        key: 'facility_type',
        sortable: false,
      },
      {
        label: 'City',
        key: 'primary_location.city',
        fulltext: true,
        sortable: false,
      },
      {
        label: 'State',
        key: 'primary_location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
        sortable: false,
      },
    ];

    this.state = {
      query: {
        include: [
          {
            attributes: ['id', 'city', 'state'],
            association: 'primary_location',
          },
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { recents } = this.props;

    if (StoreUtil.needsLoadNoCache(recents)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { recents } = this.props;

    if (!StoreUtil.isLoaded(prevProps.recents) && StoreUtil.isLoaded(recents)) {
      this.fetchAffiliated();
    }
  }

  fetchData() {
    const { dispatch, userId, clientId, organizations } = this.props;

    const { query } = this.state;

    StoreUtil.setInvalid(organizations, StoreUtil.COMMON_TABLE);
    dispatch(
      activityLogsByUserId(userId, 'Organizations', clientId, {
        ...query,
      })
    );
  }

  fetchAffiliated() {
    const { dispatch, recents, clientId } = this.props;

    const { query } = this.state;

    if (recents?.data?.rows) {
      const recentIds = recents.data.rows.map((recent) => recent.id);
      dispatch(
        queryOrganizations({
          ...query,
          where: { ...query.where, id: recentIds },
          include: [
            {
              attributes: ['id'],
              association: 'affiliated',
              required: false,
              through: { attributes: [] }, // don't need the join table data
              where: {
                id: clientId,
              },
            },
          ],
        })
      );
    }
  }

  render() {
    const { recents, classes, organizations } = this.props;

    const { query } = this.state;

    const organizationsData = StoreUtil.getData(organizations);
    const recentsData = StoreUtil.getData(recents);
    if (
      organizationsData?.rows &&
      organizationsData?.rows.length === recentsData?.rows?.length
    ) {
      for (let i = 0; i < organizationsData.rows.length; i++) {
        recentsData.rows[i].affiliated = organizationsData.rows.find(
          (organization) => {
            return organization.id === recentsData.rows[i].id;
          }
        )?.affiliated;
      }
    }

    return (
      <>
        <OrganizationsTabs tab='recent_organizations' />
        <div className={classes.container}>
          <DynamicTable
            header='Recent Organizations'
            showFilters={false}
            columns={this.columns}
            query={query}
            data={recents}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/organizations/${row.id}`}
          />
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };
}

RecentOrganizationsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  recents: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  organizations: PropTypes.object,
};

function mapStateToProps(state) {
  const { activityLogs } = state;

  const userId = state.user ? state.user.id : null;

  return {
    userId,
    clientId: state.app.selectedClient,
    organizations: StoreUtil.get(state.organizations, StoreUtil.COMMON_TABLE),
    recents: ActivityLogsUtil.getOrganizationsFromLogs(activityLogs, userId),
  };
}

const styled = withStyles(styles)(RecentOrganizationsPage);
const connected = connect(mapStateToProps)(styled);
export { connected as RecentOrganizationsPage };
