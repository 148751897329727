import React from 'react';
import { PropTypes } from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Grid, Button } from '@mui/material';
import { withIsMobile } from 'utils';

function ListItemDetail({
  isMobile,
  isEarlyMobile,
  items = [],
  renderItem = () => {},
  renderDetail = () => {},
  selectedItemIndex = -1,
}) {
  return (
    <>
      <Grid item xs={isMobile || isEarlyMobile ? 12 : 6}>
        {items.map((item, index) => {
          return renderItem({
            item,
            index,
            renderDetail,
          });
        })}
      </Grid>
      {!isMobile && !isEarlyMobile && renderDetail(selectedItemIndex)}
    </>
  );
}

ListItemDetail.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isEarlyMobile: PropTypes.bool.isRequired,
  items: PropTypes.array,
  renderItem: PropTypes.func,
  renderDetail: PropTypes.func,
  selectedItemIndex: PropTypes.number,
};

const ListItemDetailContainer = styled(Box)(({ theme }) => ({
  padding: '20px 25px',
  [theme.breakpoints.down('md')]: {
    overflow: 'scroll',
  },
}));

const ListItemDetailContainerGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    minWidth: '550px',
  },
}));

function DetailContainer({ children, isMobile, isEarlyMobile }) {
  return (
    <Grid
      item
      xs={isMobile || isEarlyMobile ? 12 : 6}
      sx={{ background: '#EBEBEB' }}
    >
      <ListItemDetailContainer>
        <ListItemDetailContainerGrid container spacing={2}>
          {children}
        </ListItemDetailContainerGrid>
      </ListItemDetailContainer>
    </Grid>
  );
}
DetailContainer.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isEarlyMobile: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export const ListItem = styled(Box)((props) => ({
  border: '1px solid #ebebeb',
  height: 40,
  borderRadius: '5px',
  borderTopRightRadius: props.isSelected ? '0' : '5px',
  borderBottomRightRadius: props.isSelected ? '0' : '5px',
  background: props.isSelected ? '#ebebeb' : '#ffffff',
  '&:hover': {
    background: '#F5F5F5',
  },
}));

export const ListItemActionButton = styled(Button)({
  fontSize: '12px',
  fontWeight: '500',
  border: '1px solid #214E56',
  borderRadius: '20px',
  color: '#214E56',
  padding: '0 5px',
  textTransform: 'none',
});

export const ListItemLink = styled(Button)({
  fontSize: '12px',
  color: '#214E56',
  textTransform: 'none',
  fontWeight: '400',
  textDecoration: 'underline',
  padding: '3px 5px',
  minWidth: 0,
  '&:hover': {
    background: 'none',
    textDecoration: 'underline',
  },
});

const withMobileDetailContainer = withIsMobile(DetailContainer);
export { withMobileDetailContainer as DetailContainer };

export default withIsMobile(ListItemDetail);
