import React, { useEffect, useState, useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import axiosInstance from 'utils/axiosUtil';
import DebouncedSearch from './DebouncedSearch';
import PropTypes from 'prop-types';

const styles = () => ({
  container: {
    maxWidth: '35rem',
    width: '35rem',
  },
});

function PayorSearch(props) {
  const { classes, initialNames } = props;
  const [payors, setPayors] = useState([]);
  const [loadedPayorIds, setLoadedPayorIds] = useState([]);
  const loadSuggestions = useCallback(async (name) => {
    try {
      const query = {
        where: {
          name: { $match: name },
          claims_type: { $ne: null },
        },
        limit: 10,
      };

      const response = await axiosInstance(
        `/Payers?opts=${encodeURIComponent(JSON.stringify(query))}`,
      );
      return await response.data;
    } catch (error) {
      console.error('Error fetching payors:', error);
    }
  }, []);
  useEffect(() => {
    if (initialNames && loadedPayorIds.length === 0) {
      (async function () {
        const query = {
          where: {
            $and: {
              $or: [
                ...initialNames.slice(0, 3).map((name) => ({ name: name })),
              ],
              claims_type: { $ne: null },
            },
          },
          limit: 3,
        };

        const response = await axiosInstance(
          `/Payers?opts=${encodeURIComponent(JSON.stringify(query))}`,
        );
        setPayors(response.data);
        props.onPayorsChanged?.(response.data);
      })();
    }
  }, []);
  useEffect(() => {
    if (
      !props.payorIds?.filter((p) => !!p)?.length ||
      props.payorIds.every((id) => loadedPayorIds.includes(id))
    ) {
      return;
    }
    console.error(loadedPayorIds, props.payorIds);
    (async function () {
      const query = {
        where: {
          $and: {
            $or: { id: props.payorIds },
            claims_type: { $ne: null },
          },
        },
        limit: 3,
      };

      const response = await axiosInstance(
        `/Payers?opts=${encodeURIComponent(JSON.stringify(query))}`,
      );
      const orderedPayors = props.payorIds
        .map((id) => response.data.find((p) => id === p.id))
        .filter((p) => !!p);
      setLoadedPayorIds(orderedPayors.map((p) => p.id));
      setPayors(orderedPayors);
      props.onPayorsChanged?.(orderedPayors);
    })();
  }, [props.payorIds]);

  return (
    <div className={classes.container}>
      <DebouncedSearch
        multiple={true}
        title='Payor'
        handleSelectedValue={(v) => {
          setPayors(v);
          props.onPayorsChanged?.(v);
        }}
        selectedValues={payors}
        loadSuggestions={loadSuggestions}
        classes={classes}
        displayPath='name'
        limit={3}
      />
    </div>
  );
}
PayorSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  onPayorsChanged: PropTypes.func,
  initialNames: PropTypes.array,
  payorIds: PropTypes.array,
};
const styled = withStyles(styles)(PayorSearch);
export { styled as PayorSearch };
