import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Card, CardContent, ButtonBase } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  TitleTooltip,
  LoadingView,
  ServiceLineSelect,
  PatientTypeSelect,
} from 'components';

import { ColorUtil, ServiceLineUtil, withRouter } from 'utils';

import { CsvUtil, StoreUtil, PatientTypes, PatientType } from 'doctivity-shared/utils';

import fileDownload from 'js-file-download';
import moment from 'moment';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { setServiceline } from 'store/actions/appActions';

const styles = (theme) => ({
  card: {
    minHeight: 400,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  spacer: {
    flex: 1,
  },
  filterDropDown: {
    width: 180,
    marginRight: theme.spacing(2),
  },
  providers: {
    width: '80%',
    paddingTop: 20,
    paddingBottom: 20,
  },
  providerRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 24,
    marginBottom: 4,
    color: '#FF6178',
    fontSize: 12,
    fontWeight: 300,
    position: 'relative',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  providerBar: {
    position: 'absolute',
    backgroundColor: '#FF6178',
    transitionProperty: 'width',
    transitionDelay: '1s',
    transitionDuration: '1s',
    width: 0,
    height: 24,
    top: 0,
    left: 0,
    borderRadius: 2,
    zIndex: 1,
    overflow: 'hidden',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  providerRowAffiliated: {
    color: '#2E93fA',
  },
  providerBarAffiliated: {
    backgroundColor: '#2E93fA',
  },
  providerName: {
    paddingTop: 2,
    paddingLeft: 8,
    zIndex: 0,
  },
  providerCount: {
    position: 'absolute',
    top: 7,
    left: 250,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 0,
  },
  providerNameBar: {
    paddingTop: 6,
    paddingLeft: 8,
    zIndex: 2,
    overflow: 'hidden',
  },
  providerNameIcon: {
    fontSize: 10,
    paddingLeft: 6,
  },
  providerNameIconBar: {
    color: '#2E93fA',
  },
  providerCountBar: {
    position: 'absolute',
    top: 7,
    left: 250,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 3,
    overflow: 'hidden',
  },
  infoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  colorKey: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 400,
    minHeight: 40,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  colorKeyIcon: {
    width: 14,
    height: 14,
    marginRight: 2,
    borderRadius: 2,
  },
  colorKeyText: {
    marginRight: 12,
  },
});

class ClaimsProviders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceline: null,
    };
  }

  componentDidMount() {
    const { providers } = this.props;

    if (StoreUtil.needsLoadNoCache(providers)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.organizationId !== prevProps.organizationId ||
      this.props.patientType !== prevProps.patientType
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, organizationId, patientType, clientId } = this.props;

    const options = {
      filter: {
        organization_id: organizationId,
        serviceline: this.getServicelineId(),
        patient_type: patientType,
      },
      opts: {
        limit: 100,
        offset: 0,
      },
    };

    dispatch(
      loadAnalytics({
        type: 'CLAIMS_IN_ORGANIZATION',
        ...options,
      })
    );
    dispatch(
      loadAnalytics({
        type: 'CLAIMS_IN_ORGANIZATION_AFFILIATED_PROVIDERS',
        ...options,
        filter: {
          ...options.filter,
          includeAffiliated: true,
          requireAffiliated: true,
          affiliated_client_id: clientId,
        },
      })
    );
  }

  render() {
    const { classes, providers, affiliatedProviders } = this.props;

    let max = 100;
    const isLoading = !providers || !StoreUtil.isLoaded(providers)
    const rows = providers?.data?.rows;
    rows?.forEach((claims) => {
      if (claims.total) {
        claims.total = parseInt(claims.total, 10);
        max = Math.max(max, claims.total);
      } else {
        claims.total = 0;
      }

      if (
        affiliatedProviders?.data?.rows &&
        affiliatedProviders.data.rows.some(
          (affiliatedProvider) =>
            affiliatedProvider.provider_id === claims.provider.id
        )
      ) {
        claims.affiliated = true;
      }
    });

    const showPatients = this.state.serviceline !== 'all';

    return (
      <Card className={classes.card}>
        <CardContent>
          <TitleTooltip
            title='Providers'
            tooltip='A breakdown of providers that work at this organization and the number of shared patients.'
            rightLabel={null && 'Based on claims from all ages'}
            onExport={this.onExport}
          />
          <ServiceLineSelect
            value={this.getServicelineId()}
            onChange={this.onServicelineChange}
          />
          <PatientTypeSelect noCapturedClaims />
          {isLoading && <LoadingView />}
          {!isLoading && rows.length > 0 && (
            <div className={classes.colorKey}>
              <div
                className={classes.colorKeyIcon}
                style={{
                  backgroundColor: ColorUtil.AffiliatedColor,
                }}
              />
              <div className={classes.colorKeyText}>Affiliated</div>

              <div
                className={classes.colorKeyIcon}
                style={{
                  backgroundColor: ColorUtil.NonAffiliatedColor,
                }}
              />
              <div className={classes.colorKeyText}>Non-Affiliated</div>
            </div>
          )}
          <div className={classes.providers}>
            {!isLoading && rows.map((claims) => (
              <ButtonBase
                key={`claims:${claims.provider_id}:${claims.location_type}`}
                className={clsx(
                  classes.providerRow,
                  claims.affiliated && classes.providerRowAffiliated
                )}
                href={`/providers/${claims.provider_id}/market`}
                onClick={() => {
                  this.props.router.navigate(
                    `/providers/${claims.provider_id}/market`
                  );
                }}
              >
                <div
                  className={clsx(
                    classes.providerBar,
                    claims.affiliated && classes.providerBarAffiliated
                  )}
                  style={{ width: `${(claims.total / max) * 100}%` }}
                >
                  <div className={classes.providerNameBar}>
                    {`${claims.provider.last_name}, ${claims.provider.first_name}`}
                  </div>
                  <div className={classes.providerCountBar}>
                    {showPatients && claims.total
                      ? ` ${claims.total.toLocaleString()} patients`
                      : ' '}
                  </div>
                </div>
                <div className={classes.providerName}>
                  {`${claims.provider.last_name}, ${claims.provider.first_name}`}
                </div>
                <div className={classes.providerCount}>
                  {showPatients && claims.total
                    ? ` ${claims.total.toLocaleString()} patients`
                    : ' '}
                </div>
              </ButtonBase>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  }

  getServicelineId() {
    return this.state.serviceline || this.props.servicelineId;
  }

  onServicelineChange = (id, parentId) => {
    if (id !== 'all') {
      this.props.dispatch(setServiceline(id, parentId));
    }
    this.setState(
      {
        serviceline: id,
      },
      this.fetchData
    );
  };

  onExport = () => {
    const { providers, affiliatedProviders, organization } = this.props;
    let max = -1;
    const rows = providers?.data?.rows;
    rows?.forEach((claims) => {
      if (claims.total) {
        claims.total = parseInt(claims.total, 10);
        max = Math.max(max, claims.total);
      } else {
        claims.total = 0;
      }

      if (
        affiliatedProviders?.data?.rows &&
        affiliatedProviders.data.rows.some(
          (affiliatedProvider) =>
            affiliatedProvider.provider_id === claims.provider.id
        )
      ) {
        claims.affiliated = true;
      }
    });
    const slName = ServiceLineUtil.findServiceLine(this.props.servicelines, this.getServicelineId())?.name;
    const data = rows.map((p) => {
      return  [
        p.provider.npi,
        p.provider.first_name,
        p.provider.last_name,
        p.affiliated ? 'Affiliated' : 'Non-Affiliated',
        organization.data.name,
        slName,
        p.total,
      ];
    });
    const patientType  = this.props.patientType === 'ALL_PATIENTS' ? 'Projected Patients' : 'Projected Pediatric Patients';
    const header = ['NPI', 'First Name', 'Last Name','Affiliated', 'Organization', 'Service Line', patientType];
    const filename = `doctivity_${organization.data.name}_PROVIDERS_${moment().format('YY_MM_DD')}.csv`;
    fileDownload(CsvUtil.matrixToString([header, ...data]), filename);
  }
}

ClaimsProviders.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number,
  providers: PropTypes.object,
  affiliatedProviders: PropTypes.object,
  organizationId: PropTypes.number.isRequired,
  organization: PropTypes.object,
  servicelineId: PropTypes.number, 
  servicelines: PropTypes.array,
  patientType: PropTypes.oneOf(PatientTypes),
};

function mapStateToProps(state, props) {
  return {
    clientId: state.app.selectedClient,
    providers: StoreUtil.get(state.analytics, 'CLAIMS_IN_ORGANIZATION'),
    affiliatedProviders: StoreUtil.get(
      state.analytics,
      'CLAIMS_IN_ORGANIZATION_AFFILIATED_PROVIDERS'
    ),
    servicelineId: ServiceLineUtil.getTopLevelSelected(state),
    servicelines: state.claims && state.claims.servicelines_grouped,
    organization: StoreUtil.get(
      state.organizations,
      StoreUtil.COMMON_ITEM,
      props.organizationId
    ),
    patientType:
      state.app.patientType === PatientType.CAPTURED_CLAIMS
        ? PatientType.ALL_PATIENTS
        : state.app.patientType,
  };
}

const styled = withStyles(styles)(ClaimsProviders);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ClaimsProviders };
