export const API_PROVIDERS_TABLE = 'API_PROVIDERS_TABLE';
export const API_PROVIDERS_LIST = 'API_PROVIDERS_LIST';
export const API_PROVIDERS_UPSERT = 'API_PROVIDERS_UPSERT';
export const API_PROVIDERS_LOAD = 'API_PROVIDERS_LOAD';

export const queryProviders = (options, type = API_PROVIDERS_TABLE) => ({
  type,
  request: {
    url: '/Providers',
    method: 'GET',
    params: { opts: options },
  },
});

export const listProviders = () => (queryProviders({
  order: [
    ['last_name', 'ASC'],
  ],
}, API_PROVIDERS_LIST));

export const loadProvider = (providerId, clientId) => {
  const include = [
    {
      association: 'clients',
      attributes: ['id', 'name'],
    },
    {
      association: 'taxonomy',
    },
    {
      association: 'location',
      include: [
        {
          attributes: ['id', 'name'],
          association: 'organization',
        },
      ],
    },
    {
      association: 'tags',
      attributes: ['id', 'name', 'namespace_id'],
      ...(clientId ? { where: { client_id: clientId }, required: false } : {}),
      include: [
        {
          association: 'namespace',
          attributes: ['id', 'name'],
        },
      ],
    },
 
  ];

  if (clientId > 0) {
    include.push({
      association: 'profile_notes',
      where: { client_id: clientId },
      required: false,
    });
  }

  return {
    type: API_PROVIDERS_LOAD,
    id: providerId,
    request: {
      method: 'GET',
      url: `/Providers/${providerId}`,
      params: {
        opts: {
          include,
        },
      },
    },
  };
};

export const upsertProvider = (data) => {
  let id = 0;
  let options = {
    include: [],
  };
 
  if (data.id && data.id > 0) {
    id = data.id;
    options.where = { id };
  }

  if (data.organizations) {
    options.include.push({ model: 'Organizations', as: 'organizations' });
  }
  if (data.tags) {
    options.include.push({ model: 'Tags', as: 'tags' });
  }

  return {
    type: API_PROVIDERS_UPSERT,
    id,
    request: {
      url: '/Providers',
      method: 'PUT',
      params: { opts: options },
      data,
    },
    nextAction: id && loadProvider(id, data.selectedClient),
  };
};
