/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  useMediaQuery,
  useTheme,
} from '@mui/material';

const withIsMobile = (Component) => function WithIsMobile(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isEarlyMobile = useMediaQuery(theme.breakpoints.down('xl'));

  return (
    <Component
      {...props}
      isMobile={isMobile}
      isEarlyMobile={isEarlyMobile}
    />
  );
};

export { withIsMobile };
export default withIsMobile;
