import { StoreUtil } from './StoreUtil';

const untouched = {
  actionConsumed: false,
  state: null,
};

// pulled from redux-saga-requests to avoid having to use it as
// dependency for this small piece of code
const SUCCESS_SUFFIX = '_SUCCESS';
const ERROR_SUFFIX = '_ERROR';
const ABORT_SUFFIX = '_ABORT';
const getActionWithSuffix = (suffix) => (actionType) => actionType + suffix;
const success = getActionWithSuffix(SUCCESS_SUFFIX);
const error = getActionWithSuffix(ERROR_SUFFIX);
const abort = getActionWithSuffix(ABORT_SUFFIX);

class ReducerUtil {
  static consumedResponse(newState) {
    return {
      actionConsumed: true,
      state: newState,
    };
  }

  static simpleReduce(state, action, tableAction, listAction, upsertAction, idField = 'id') {
    const table = ReducerUtil.reduceTableGet(state, action, tableAction);
    if (table.actionConsumed) {
      return table;
    }

    const list = ReducerUtil.reduceListGet(state, action, listAction);
    if (list.actionConsumed) {
      return list;
    }

    const update = ReducerUtil.reduceUpsert(state, action, upsertAction, idField);
    if (update.actionConsumed) {
      return update;
    }

    // not touched
    return untouched;
  }

  static reduceBulkGet(state, action, listAction, idCreationFunction) {
    switch (action.type) {
      case success(listAction): {
      // save list to state by id
        const listData = action.data;
        if (listData && Array.isArray(listData)) {
        // save each item by id
          const byId = {};
          let hash;
          listData.forEach((item) => {
            if (idCreationFunction) {
              hash = idCreationFunction(item);
              item.hash = hash;
              byId[hash] = item;
            } else {
              byId[item.id] = item;
            }
          });
          return ReducerUtil.consumedResponse(
            StoreUtil.setLoaded(byId, state, StoreUtil.COMMON_BULK),
          );
        }
      }// fallthrough
      case error(listAction):
      case abort(listAction):
      case listAction: {
        return ReducerUtil.consumedResponse(
          ReducerUtil.reduceLoadStatus(state, action, listAction, StoreUtil.COMMON_BULK),
        );
      }

      default:
        return untouched;
    }
  }

  static reduceListGet(state, action, listAction, storeField = StoreUtil.COMMON_LIST) {
    // check to see if this is the most recent request of this type
    if (action.type === listAction
      || !state[storeField]
      || !state[storeField].meta
      || !action.meta
      || action.meta.requestAction.loadRequestTime
        === state[storeField].meta.statusTime.STATUS_LOADING) {
      switch (action.type) {
        case success(listAction): {
        // save surgeon list to state
          const listData = action.data;
          if (listData && Array.isArray(listData)) {
            return ReducerUtil.consumedResponse(
              StoreUtil.setLoaded(listData, state, storeField),
            );
          }
        }// fallthrough
        case error(listAction):
        case abort(listAction):
        case listAction: {
          return ReducerUtil.consumedResponse(
            ReducerUtil.reduceLoadStatus(state, action, listAction, storeField),
          );
        }

        default:
          return untouched;
      }
    }
    return untouched;
  }

  static reduceTableGet(state, action, tableAction, storeField = StoreUtil.COMMON_TABLE) {
    // check to see if this is the most recent request of this type
    if (action.type === tableAction
      || !state[storeField]
      || !state[storeField].meta
      || !action.meta
      || action.meta.requestAction.loadRequestTime
        === state[storeField].meta.statusTime.STATUS_LOADING) {
      switch (action.type) {
        case success(tableAction): {
          const tableData = action.data;
          if (tableData && tableData.rows) {
            return ReducerUtil.consumedResponse(
              StoreUtil.setLoaded(tableData, state, storeField),
            );
          }
        }// fallthrough
        case error(tableAction):
        case abort(tableAction):
        case tableAction: {
          return ReducerUtil.consumedResponse(
            ReducerUtil.reduceLoadStatus(state, action, tableAction, storeField),
          );
        }

        default:
          return untouched;
      }
    } else {
      // console.log('OLDE REQUEST MADE!!!!');
    }
    return untouched;
  }

  static reduceQuery(state, action, queryAction, storeField = 'query', idField = 'id') {
    // check to see if this is the most recent request of this type

    if (action.type.startsWith(queryAction)) {
      let id = action[idField];
      if (action.meta) {
        id = action.meta.requestAction[idField];
      }
      if (id == null) {
        id = -1;
      }
      if (id) {
        switch (action.type) {
          case success(queryAction): {
            const queryData = action.data;
            if (queryData) {
              return ReducerUtil.consumedResponse(
                StoreUtil.setLoaded(queryData, state, storeField, id),
              );
            }
          }// fallthrough
          case error(queryAction):
          case abort(queryAction):
          case queryAction: {
            return ReducerUtil.consumedResponse(
              ReducerUtil.reduceLoadStatus(state, action, queryAction, storeField, id),
            );
          }

          default:
            return untouched;
        }
      } else {
        // console.log('OLDE REQUEST MADE!!!!');
      }
    }
    return untouched;
  }

  static reduceSingleGet(state, action, singleAction,
    idField = 'id',
    storeField = StoreUtil.COMMON_ITEM,
    arrayExpected = false,
    dataResolver = null) {
    switch (action.type) {
      case success(singleAction): {
        const id = action.meta.requestAction[idField];
        let loadedData;
        if (dataResolver != null) {
          loadedData = dataResolver(action);
        } else {
          loadedData = action.data;

          if (!arrayExpected && Array.isArray(loadedData)) {
            loadedData = loadedData[0];
          }
        }
        if (loadedData
        && ((arrayExpected && Array.isArray(loadedData)) || loadedData.id)) {
          return ReducerUtil.consumedResponse(
            StoreUtil.setLoaded(loadedData, state, storeField, id),
          );
        }
      }// fallthrough
      case error(singleAction):
      case abort(singleAction):
      case singleAction: {
        let id;
        if (action[idField]) {
          id = action[idField];
        } else {
          id = action.meta.requestAction[idField];
        }
        return ReducerUtil.consumedResponse(
          ReducerUtil.reduceLoadStatus(state, action, singleAction, storeField, id),
        );
      }
      default:
        return untouched;
    }
  }

  static reduceUpsert(state, action, updateAction, idField = 'id') {
    return ReducerUtil.reduceUpdate(
      state,
      action,
      updateAction,
      idField,
      false,
      StoreUtil.COMMON_EDIT_ITEM,
      StoreUtil.COMMON_EDIT_ITEM,
      false,
    );
  }

  static reduceUpdate(state, action, updateAction,
    idField = 'id',
    saveResponse = false,
    storeField = StoreUtil.COMMON_ITEM,
    newStoreField = StoreUtil.COMMON_NEW_ITEM,
    saveById = true) {
    switch (action.type) {
      case success(updateAction): {
        let id = action.meta.requestAction[idField];
        if (id == null) {
          id = -1;
        }
        let data = action.data;

        if (Array.isArray(data)) {
          data = data[0];
        }

        // new items return object with id, but didn't have an id prior
        if (id < 1 && data && data[idField]) {
          // new item
          if (saveById) {
            id = data[idField];
          } else {
            id = -1;
          }

          const newStore = StoreUtil.setSaved(data, state, storeField, id);

          return ReducerUtil.consumedResponse(
            StoreUtil.setSaved(data, newStore, newStoreField),
          );
        }
        // update
        let responseSave = null;
        if (saveResponse) {
          responseSave = data;
        }
        if (!saveById) {
          id = -1;
        }
        return ReducerUtil.consumedResponse(
          StoreUtil.setSaved(responseSave, state, storeField, id),
        );
      }
      case updateAction:
      case abort(updateAction):
      case error(updateAction): {
        let id = -1;
        if (saveById) {
          if (action.type === updateAction) {
            if (action[idField] && action[idField] > 0) {
              id = action[idField];
            }
          } else if (action.meta.requestAction[idField]
            && action.meta.requestAction[idField] > 0) {
            id = action.meta.requestAction[idField];
          }
        }
        if (id !== -1 && id !== '') {
          return ReducerUtil.consumedResponse(
            ReducerUtil.reduceSaveStatus(state, action, updateAction, storeField, id),
          );
        }
        return ReducerUtil.consumedResponse(
          ReducerUtil.reduceSaveStatus(state, action, updateAction, newStoreField, id),
        );
      }
      default:
        return untouched;
    }
  }

  static reduceSaveStatus(store, action, eventName, itemName, id = -1) {
    switch (action.type) {
      case eventName:
        return StoreUtil.setSaving(store, itemName, id);
      case abort(eventName):
        return StoreUtil.setSaveAborted(store, itemName, id);
      case error(eventName):
      case success(eventName):// assume fallthrough error
        return StoreUtil.setSaveFailed(store, itemName, id);
      default:
        return store;
    }
  }

  static reduceLoadStatus(store, action, eventName, itemName, id = -1) {
    switch (action.type) {
      case eventName: {
        const obj = StoreUtil.setLoading(store, itemName, id);
        if (id !== -1 && id !== '') {
          action.loadRequestTime = obj[itemName][id].meta.statusTime.STATUS_LOADING;
        } else {
          action.loadRequestTime = obj[itemName].meta.statusTime.STATUS_LOADING;
        }
        return obj;
      }
      case abort(eventName):
        return StoreUtil.setLoadAborted(store, itemName, id);
      case error(eventName):
      case success(eventName):// assume fallthrough error
        return StoreUtil.setLoadFailed(store, itemName, id);
      default:
        return store;
    }
  }
}

export { ReducerUtil };
export default ReducerUtil;
