import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_TABLE_USERS,
  API_LIST_USERS,
  API_UPSERT_USER,
  API_LOAD_USER,
} from 'store/actions/usersActions';

export const users = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_TABLE_USERS,
    API_LIST_USERS,
    API_UPSERT_USER,
  );
  if (result.actionConsumed) {
    if (action.type === API_LIST_USERS
      && action.clientId && result.state) {
      result.state.listClientId = action.clientId;
    }
    return result.state;
  }
  result = ReducerUtil.reduceSingleGet(state, action, API_LOAD_USER);
  if (result.actionConsumed) {
    return result.state;
  }
  return state;
};
