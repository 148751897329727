import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_ORGANIZATION_TAGS_TABLE,
  API_ORGANIZATION_TAGS_LIST,
  API_ORGANIZATION_TAGS_UPSERT,
  API_ORGANIZATION_TAGS_GET,
  API_ORGANIZATION_TAGS_ORGANIZATIONS,
} from 'store/actions/organizationTagsActions';

export const organizationTags = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_ORGANIZATION_TAGS_TABLE,
    API_ORGANIZATION_TAGS_LIST,
    API_ORGANIZATION_TAGS_UPSERT,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceSingleGet(state, action, API_ORGANIZATION_TAGS_GET);
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceQuery(state, action, API_ORGANIZATION_TAGS_ORGANIZATIONS, 'organizations');
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
