import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import axiosInstance from 'utils/axiosUtil';
import PropTypes from 'prop-types';

import { PhoneUtil } from 'doctivity-shared/utils';

import { DialogForm } from 'components';

const styles = (theme) => ({
  instructions: {
    fontSize: 14,
    fontWeight: 300,
    color: theme.palette.primary.main,
  },
});

const schema = {
  type: 'object',
  required: ['first_name', 'last_name', 'email'],
  properties: {
    first_name: { type: 'string', title: 'First Name' },
    last_name: { type: 'string', title: 'Last Name' },
    email: { type: 'string', title: 'Email', readOnly: false },
    phone: { type: 'string', title: 'Phone', mask: 'phone', maxLength: 14 },
    is_active: { type: 'boolean', title: 'Active' },
    send_invite: {
      type: 'boolean',
      title: 'Send Email for Creation',
      default: false,
    },
    is_admin: {
      type: 'boolean',
      title: 'System Administrator (Access to ALL Clients)',
    },
  },
  dependencies: {
    is_admin: {
      oneOf: [
        {
          properties: {
            is_admin: { enum: [false] }, // if this is true, then below added
            client_ids: {
              type: 'array',
              title: 'Client Access',
              uniqueItems: true,
              minItems: 1,
              items: {
                type: 'number',
                enum: [], // will be added below from server
              },
            },
          },
        },
      ],
    },
  },
};

const uiSchema = {
  first_name: {
    classNames: 'two-column-field',
  },
  last_name: {
    classNames: 'two-column-field',
  },
  email: {
    classNames: 'two-column-field',
  },
  phone: {
    classNames: 'two-column-field',
  },
  password: {
    'ui:widget': 'password',
  },
  client_ids: {
    'ui:widget': 'checkboxes',
  },
};

const NEW_USER = {
  is_active: true,
  is_admin: false,
};

class UserEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  userExists(email, id, callback) {
    axiosInstance
      .get('/Users', {
        params: {
          opts: {
            where: {
              email: { $like: email },
              id: { $ne: id ? parseInt(id, 10) : 0 },
            },
          },
        },
      })
      .then((response) => {
        if (response.data && response.data.length > 0) {
          callback(true);
        } else {
          callback(false);
        }
      });
  }

  render() {
    const { user, clients, open, userData } = this.props;

    if (userData) {
      if (userData.is_admin) {
        userData.client_ids = []; // admin user has access to everyone
      } else if (userData.clients && userData.clients.length > 0) {
        userData.client_ids = userData.clients.map((c) => c.id);
      }

      if (userData.phone) {
        userData.phone = PhoneUtil.formatNumber(userData.phone);
      }
    }

    // Hide send invite checkbox when editing a patient.
    if (userData && userData.id) {
      uiSchema.send_invite = { 'ui:widget': 'hidden' };
    } else {
      delete uiSchema.send_invite;
    }

    if (clients && Array.isArray(clients)) {
      // set facility names for drop downs
      const clientIds =
        schema.dependencies.is_admin.oneOf[0].properties.client_ids.items;
      // schema.properties.client_id;
      clientIds.enum = clients.map((client) => client.id);
      clientIds.enumNames = clients.map((client) => client.name);
      // clientIds.enum.unshift(-1);
      // clientIds.enumNames.unshift('Administrator (All Clients)');
    }

    // Hide admin options and email from non-admin users, make phone required
    if (!user.is_admin) {
      delete schema.properties.is_active;
      delete schema.properties.send_invite;
      delete schema.properties.is_admin;
      schema.properties.email.readOnly = true;
      if (!schema.required.includes('phone')) {
        schema.required.push('phone');
      }
    }

    return (
      <DialogForm
        open={open}
        formData={userData || NEW_USER}
        schema={schema}
        uiSchema={uiSchema}
        onClose={this.props.onClose}
        onFormSubmit={this.onSave}
        status={
          this.state.userDialogError ? this.state.userDialogErrorMessage : ''
        }
        statusIsError
      />
    );
  }

  onSave = (data) => {
    const { user } = this.props;
    data.clients = data.client_ids;

    if (data.phone) {
      data.phone = PhoneUtil.unformat(data.phone);
      if (!data.phone || !PhoneUtil.isValid(data.phone)) {
        this.setState({
          userDialogError: true,
          userDialogErrorMessage: 'Invalid phone number.',
        });
        return;
      }
    }

    // If user is not admin, then the user must already exist since non-admins cannot create users
    // No need to check
    if (user.is_admin) {
      this.userExists(data.email, data.id, (exists) => {
        if (exists) {
          this.setState({
            userDialogError: true,
            userDialogErrorMessage: 'User already exists with this email.',
          });
        } else {
          this.props.onSave(data);
        }
      });
    } else {
      this.props.onSave(data);
    }
  };
}

UserEditDialog.propTypes = {
  user: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  clients: PropTypes.array,
  userData: PropTypes.object,
  onClose: PropTypes.func,
};

function mapStateToProps(state) {
  const { user } = state;

  return {
    user,
  };
}

const styled = withStyles(styles)(UserEditDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as UserEditDialog };
