import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import clsx from 'clsx';

import { StoreUtil } from 'doctivity-shared/utils';

import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import {
  Chip,
  Icon,
  InputAdornment,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';

import { LoadingView, FixedHeader } from 'components';

import {
  API_TAXONOMY_LIST,
  queryTaxonomy,
} from 'store/actions/taxonomyActions';

const CustomTreeItemProps = {
  onExpand: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  hidden: PropTypes.bool,
};

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onExpand,
    onSelect,
    hidden,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleExpansionClick = (event) => {
    onExpand(event);
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    onSelect(event);
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onClick={onSelect ? handleSelectionClick : handleExpansionClick}
      style={{ display: hidden ? 'hidden' : 'visible' }}
      ref={ref}
    >
      <div className={classes.iconContainer}>{icon}</div>
      <Typography
        component='div'
        className={classes.label}
        style={{
          fontStyle: label.startsWith('Select All - ') ? 'italic' : 'normal',
        }}
      >
        {label}
      </Typography>
    </div>
  );
});

CustomContent.propTypes =
  {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    displayIcon: PropTypes.node,
    expansionIcon: PropTypes.node,
    icon: PropTypes.node,
    label: PropTypes.node,
    nodeId: PropTypes.string.isRequired,
  } & CustomTreeItemProps;

function CustomTreeItem({ onExpand, onSelect, ...props }) {
  return (
    <TreeItem
      ContentComponent={CustomContent}
      ContentProps={{ onExpand, onSelect }}
      {...props}
    />
  );
}

CustomTreeItem.propTypes = {
  ...CustomTreeItemProps,
};

const styles = (theme) => ({
  dialogContent: {
    overflowY: 'scroll',
    overflow: '-moz-scrollbars-vertical',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '7px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '4px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
  container: {
    minHeight: '90vh',
    maxHeight: '90vh',
  },
  header: {
    position: 'sticky',
    backgroundColor: '#FAFAFA',
    top: 0,
    zIndex: 10,
  },
  treeItem: {
    '& > .MuiTreeItem-content .MuiTreeItem-label': {
      minHeight: 40,
      display: 'flex',
      alignItems: 'center',
    },
  },
  selectedTaxonomies: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    '& > *': {
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(),
    },
    maxHeight: '20rem',
    overflowY: 'auto',
  },
  filterInputField: theme.typography.caption,
  filterInputField2: {
    lineHeight: 'inherit',
    marginLeft: theme.spacing(),
  },
  filterIcon: {
    fontSize: 14,
    color: '#cccccc',
  },
  filterRow: {
    paddingBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end'
  },
  searchBar: {
    width: '50%',
  },
  tree: {
    paddingRight: theme.spacing(3),
  },
  selectedTaxonomyItem: {
    maxWidth: 240,
  },
  noResults: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  clearAllButton: {
    fontSize: '12px',
    fontFamily: 'Roboto',
    cursor: 'pointer',
    color: '#214E56',
    textTransform: 'none',
    fontWeight: '400',
    textDecoration: 'underline',
    padding: '3px 5px 0 5px',
    minWidth: 0,
    '&:hover': {
      background: 'none',
      textDecoration: 'underline',
    },
  }
});

class SpecialtiesTreeDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentFilter: '',
      expandedTaxonomies: [],
      selectedTaxonomies: [],
      hiddenTaxonomies: [],
      hasChanged: false,
    };
  }

  componentDidMount() {
    const { taxonomy } = this.props;

    if (StoreUtil.needsLoadLongCache(taxonomy)) {
      this.fetchData();
    } else if (StoreUtil.isLoaded(taxonomy) && this.props.values?.length > 0) {
      this.onSave(false);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      (StoreUtil.didLoadSinceLastUpdate(
        this.props.taxonomy,
        prevProps.taxonomy
      ) || this.props.taxonomy !== prevProps.taxonomy ||
      this.props.values !== prevProps.values) &&
      this.props.values?.length > 0
    ) {
      this.onSave(false);
    }
  }

  fetchData() {
    const { dispatch } = this.props;

    dispatch(
      queryTaxonomy(
        {
          where: { import_provider: true },
          order: [
            ['grouping', 'ASC'],
            ['classification', 'ASC'],
            ['specialization', 'ASC'],
          ],
        }, 
        API_TAXONOMY_LIST,
      )
    );
  }

  render() {
    const { open, classes, taxonomy, groupings, taxonomyCount, values } =
      this.props;
    const {
      selectedTaxonomies: stateSelected,
      expandedTaxonomies,
      hiddenTaxonomies,
      hasChanged,
    } = this.state;

    const selectedTaxonomies = values && !hasChanged ? values : stateSelected;

    const taxonomyData = StoreUtil.getData(taxonomy);

    const noResults = taxonomyData && hiddenTaxonomies.length === taxonomyCount;

    const renderTree = (nodes) => {
      const hasChildren =
        Array.isArray(nodes.children) && nodes.children.length > 0;

      return (
        <CustomTreeItem
          key={nodes.id}
          nodeId={nodes.id}
          label={nodes.name}
          className={classes.treeItem}
          hidden={hiddenTaxonomies.some((node) => node.id === nodes.id)}
          onExpand={() => this.onTaxonomyExpanded(nodes)}
          onSelect={
            !hasChildren
              ? () => this.onTaxonomySelected(nodes.id, nodes.name)
              : null
          }
        >
          {hasChildren ? nodes.children.map((node) => renderTree(node)) : null}
        </CustomTreeItem>
      );
    };

    return (
      <Dialog
        onClose={this.onClose}
        aria-labelledby='simple-dialog-title'
        fullWidth
        open={open}
        scroll='paper'
      >
        <DialogTitle>Select Specialties</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.container}>
            {!taxonomyData ? (
              <LoadingView />
            ) : (
              <div>
                <FixedHeader top={0}>
                  <div className={classes.selectedTaxonomies}>
                    {selectedTaxonomies.map((taxonomyItem) => (
                      <Chip
                        label={taxonomyItem.name}
                        onDelete={() =>
                          this.onTaxonomySelected(
                            taxonomyItem.id,
                            taxonomyItem.name
                          )}
                        variant='outlined'
                        key={taxonomyItem.id}
                        className={classes.selectedTaxonomyItem}
                      />
                    ))}
                  </div>
                  <div className={classes.filterRow}>
                    <TextField
                      InputProps={{
                        className: clsx(
                          classes.filterInputField,
                          classes.filterInputField2
                        ),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Icon className={classes.filterIcon}>search</Icon>
                          </InputAdornment>
                        ),
                      }}
                      className={classes.searchBar}
                      onChange={this.onSearch}
                      variant='standard'
                      margin='none'
                      type='search'
                      autoComplete='disabled'
                    />
                    <div style={{ bottom: '-4px', position: 'relative' }}>
                      {selectedTaxonomies?.length > 0 && <span className={classes.clearAllButton} onClick={this.clearAll}>Clear All Specialties</span>}
                    </div>
                  </div>
                  <Divider />
                </FixedHeader>
                {noResults ? (
                  <Typography
                    color='primary'
                    alignSelf='center'
                    className={classes.noResults}
                  >
                    No results for that filter
                  </Typography>
                ) : (
                  <TreeView
                    aria-label='rich object'
                    defaultCollapseIcon={<ExpandMoreIcon color='primary' />}
                    defaultExpanded={['root']}
                    defaultExpandIcon={<ChevronRightIcon color='primary' />}
                    selected={selectedTaxonomies.map(
                      (taxonomyItem) => taxonomyItem.id
                    )}
                    expanded={expandedTaxonomies.map(
                      (taxonomyItem) => taxonomyItem.id
                    )}
                    multiSelect
                    className={classes.tree}
                  >
                    {groupings.map((grouping) => renderTree(grouping))}
                  </TreeView>
                )}
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={() => this.onSave(true)} color='primary' variant='contained'>
            {selectedTaxonomies.length === 0 ? 'Select All' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  onSearch = (event) => {
    const { groupings } = this.props;

    const filter = event.target.value;
    const regex = new RegExp(`(${filter})`, 'i');

    const expandedTaxonomies = [];
    const hiddenTaxonomies = [];

    if (filter !== '') {
      groupings.forEach((grouping) => {
        let groupingMatch = false;
        if (regex.test(grouping.name)) {
          groupingMatch = true;
        }
        grouping.children?.forEach((classification) => {
          let classificationMatch = false;
          if (regex.test(classification.name)) {
            classificationMatch = true;
            groupingMatch = true;
          }
          classification.children?.forEach((specialization) => {
            if (regex.test(specialization.name)) {
              classificationMatch = true;
              groupingMatch = true;
            } else {
              hiddenTaxonomies.push(specialization);
            }
          });
          if (!classificationMatch) {
            hiddenTaxonomies.push(classification);
          } else {
            expandedTaxonomies.push(classification);
          }
        });
        if (!groupingMatch) {
          hiddenTaxonomies.push(grouping);
        } else {
          expandedTaxonomies.push(grouping);
        }
      });
    }

    this.setState({ expandedTaxonomies, hiddenTaxonomies });
  };

  onTaxonomySelected = (taxonomyItemId, taxonomyName) => {
    const { taxonomy, values } = this.props;
    const { selectedTaxonomies: stateSelected, hasChanged } = this.state;

    const selectedTaxonomies = values && !hasChanged ? values : stateSelected;

    let newSelectedTaxonomies = selectedTaxonomies;
    if (taxonomyItemId.startsWith('all-')) {
      const filterById = taxonomyItemId.substr(4);
      const classificationCodeFilter = taxonomyItemId.substr(4, 4);
      const allSpecializationsUnderSelectedClass = taxonomy.data.filter(
        (taxonomyItem) =>
          taxonomyItem.code.startsWith(classificationCodeFilter) &&
          taxonomyItem.code.substr(4) !== '00000X'
      );
      if (
        !newSelectedTaxonomies.some(
          (taxonomyItem) => taxonomyItem.id === filterById
        )
      ) {
        newSelectedTaxonomies.push({
          id: taxonomyItemId.substr(4),
          name: taxonomyName.substr(13),
        });
      }

      allSpecializationsUnderSelectedClass.forEach((specialization) => {
        if (
          !newSelectedTaxonomies.some(
            (taxonomyItem) => taxonomyItem.id === specialization.code
          )
        ) {
          newSelectedTaxonomies.push({
            id: specialization.code,
            name: specialization.specialization,
          });
        }
      });
    } else {
      if (
        !newSelectedTaxonomies.some(
          (taxonomyItem) => taxonomyItem.id === taxonomyItemId
        )
      ) {
        newSelectedTaxonomies.push({
          id: taxonomyItemId,
          name: taxonomyName,
        });
      } else {
        newSelectedTaxonomies = newSelectedTaxonomies.filter(
          (taxonomyItem) => taxonomyItem.id !== taxonomyItemId
        );
      }
    }

    this.setState({
      selectedTaxonomies: newSelectedTaxonomies,
      hasChanged: true,
    });
  };

  clearAll = () => {
    this.setState({
      selectedTaxonomies: [],
      hasChanged: true,
    });
  }

  onTaxonomyExpanded = (taxonomyItem) => {
    const { expandedTaxonomies } = this.state;

    let newExpandedTaxonomies = expandedTaxonomies;

    if (
      expandedTaxonomies.some((taxonomy) => taxonomyItem.id === taxonomy.id)
    ) {
      newExpandedTaxonomies = expandedTaxonomies.filter(
        (taxonomy) => taxonomyItem.id !== taxonomy.id
      );
    } else {
      newExpandedTaxonomies.push(taxonomyItem);
    }

    this.setState({
      expandedTaxonomies: newExpandedTaxonomies,
    });
  };

  onSave = (userChanged) => {
    const { onChange, values } = this.props;
    const { selectedTaxonomies: stateSelected, hasChanged } = this.state;

    const selectedTaxonomies = values && !hasChanged ? values : stateSelected;

    onChange(selectedTaxonomies, userChanged);
    this.setState({ hiddenTaxonomies: [] });
  };

  onClose = () => {
    const { onClose } = this.props;

    this.setState({ selectedTaxonomies: [], hiddenTaxonomies: [] });
    onClose();
  };
}

SpecialtiesTreeDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  classes: PropTypes.object,
  taxonomy: PropTypes.object.isRequired,
  values: PropTypes.array,
  groupings: PropTypes.array,
  taxonomyCount: PropTypes.number,
};

function mapStateToProps(state, props) {
  const { taxonomy } = state;
  const { values } = props;

  let taxonomyProps = StoreUtil.get(taxonomy, StoreUtil.COMMON_LIST);
  const groupings = [];
  let count = 0;

  const taxonomyData = StoreUtil.getData(taxonomyProps);
  let initialSelected = [];

  if (taxonomyData) {
    const uniqueGroupingNames = new Set();
    taxonomyData.map((taxonomyItem) => {
      if (taxonomyItem.grouping && taxonomyItem.grouping !== '') {
        uniqueGroupingNames.add(taxonomyItem.grouping);
      }
    });

    uniqueGroupingNames.forEach((grouping, index) => {
      groupings.push({
        id: `group-${index}`,
        name: grouping,
        children: taxonomyData
          .filter(
            (groupingItem) =>
              groupingItem.grouping === grouping &&
              (!groupingItem.specialization ||
                groupingItem.specialization === '')
          )
          .map((classificationItem) => {
            const children = taxonomyData
              .filter(
                (specializationItem) =>
                  specializationItem.grouping === grouping &&
                  specializationItem.classification ==
                    classificationItem.classification &&
                  specializationItem.specialization &&
                  specializationItem.specialization !== ''
              )
              .map((specializationItem) => {
                count += 1;
                const taxonomy = {
                  id: `${specializationItem.code}`,
                  name: specializationItem.specialization,
                };
                if (values?.includes(taxonomy.id)) {
                  initialSelected.push(taxonomy);
                }
                return taxonomy;
              });
            if (children.length > 0) {
              const taxonomy = {
                id: `all-${classificationItem.code}`,
                name: `Select All - ${classificationItem.classification}`,
              };
              children.unshift(taxonomy);
              if (values?.includes(taxonomy.id)) {
                initialSelected.push(taxonomy);
              }
              count += 1;
            }

            count += 1;
            const taxonomy = {
              id: `${classificationItem.code}`,
              name: classificationItem.classification,
              children,
            };
            if (values?.includes(taxonomy.id)) {
              initialSelected.push(taxonomy);
            }
            return taxonomy;
          }),
      });
      count += 1;
    });
  }

  return {
    taxonomy: taxonomyProps,
    groupings,
    values: initialSelected,
    taxonomyCount: count,
  };
}

const styled = withStyles(styles)(SpecialtiesTreeDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as SpecialtiesTreeDialog };
