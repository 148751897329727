import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';

import { Button, Icon, Card, CardContent, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { EditProfileNotesDialog } from 'components';

const styles = (theme) => ({
  card: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  linksContainer: {
    textAlign: 'right',
    marginRight: -1 * parseInt(theme.spacing(2)),
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  profileDisclaimer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  disclaimerContent: {
    color: '#9E9E9E',
    fontStyle: 'italic',
    fontSize: 13,
  },
  noteRowIcon: {
    fontSize: 12,
    color: theme.palette.secondary.accent,
    marginRight: theme.spacing(2),
  },
  noteRow: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    borderWidth: 1,
    borderBottomStyle: 'solid',
    borderColor: theme.palette.tertiary.main,
  },
  editButton: {
    color: theme.palette.secondary.main,
  },
});

class ProfileNotes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editProfileOpen: false,
    };
  }

  render() {
    const { classes, model, data } = this.props;
    const { editProfileOpen } = this.state;

    const isProvider = model === 'provider';
    let profileNotes = data.profile_notes;
    if (isProvider) {
      if (profileNotes?.length > 0) {
        profileNotes = profileNotes[0].profile_notes
          ?.split('\n')
          ?.filter((note) => note !== '');
      } else {
        profileNotes = null;
      }
    } else {
      profileNotes = profileNotes?.split('\n')
      ?.filter((note) => note !== '');
    }

    return (
      <>
        <Card className={classes.card}>
          <CardContent>
            <div className={classes.titleContainer}>
              <Typography component='h2' variant='h6' color='primary'>
                {isProvider ? 'Provider' : 'Contact'} Profile
              </Typography>
              <div className={classes.linksContainer}>
                <Button
                  variant='text'
                  color='primary'
                  onClick={this.editProfile}
                  className={classes.editButton}
                >
                  <EditOutlinedIcon className={classes.leftIcon}>
                    edit
                  </EditOutlinedIcon>
                  Edit
                </Button>
              </div>
            </div>
            {profileNotes ? (
              profileNotes.map((profileNote, index) => (
                <div key={index} className={classes.noteRow}>
                  <Icon className={classes.noteRowIcon}>circle</Icon>
                  <Typography style={{ whiteSpace: 'pre-wrap' }}>
                    {profileNote}
                  </Typography>
                </div>
              ))
            ) : (
              <div className={classes.profileDisclaimer}>
                <Typography className={classes.disclaimerContent}>
                  A profile is a place to put reference details information.
                </Typography>
                <Typography className={classes.disclaimerContent}>
                  Examples - favorite beverage, partner details, working hours,
                  etc.
                </Typography>
                <Typography className={classes.disclaimerContent}>
                  NOTE - profile information can be seen by everyone in your
                  company. These are not private to you.
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>

        <EditProfileNotesDialog
          open={editProfileOpen}
          data={isProvider ? { ...data, profile_notes: profileNotes } : data}
          model={model}
          onClose={this.closeEditProfile}
        />
      </>
    );
  }

  editProfile = () => {
    this.setState({ editProfileOpen: true });
  };

  closeEditProfile = () => {
    this.setState({ editProfileOpen: false });
  };
}

ProfileNotes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  model: PropTypes.oneOf(['provider', 'contact']).isRequired,
  data: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { app } = state;

  return {
    clientId: app.selectedClient,
  };
}

const styled = withStyles(styles)(ProfileNotes);
const connected = connect(mapStateToProps)(styled);
export { connected as ProfileNotes };
