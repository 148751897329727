import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import {
  PhoneUtil,
} from 'doctivity-shared/utils';

import {
  DialogForm,
} from 'components';

const styles = () => ({
});

const schema = {
  type: 'object',
  required: ['name', 'npi', 'facility_type', 'specialty'],
  properties: {
    name: { type: 'string', title: 'Name' },
    npi: { type: 'string', title: 'NPI #', maxLength: 10 },
    phone: {
      type: 'string',
      title: 'Phone',
      maxLength: 11,
      mask: 'phone-ext',
    },
    email: { type: 'string', title: 'Email' },
    facility_type: {
      type: 'string',
      title: 'Facility Type',
      maxLength: 30,
      enum: [
        'Hospital',
        'Hospital Pediatric',
        'Group Practice',
        'Outpatient Facility',
        'Miscellaneous',
        'Hospital Specialty',
        'Extended Care',
        'Equipment Supplies',
        'Pharmacy',
        'Laboratory',
      ],
    },
    specialty: {
      type: 'string',
      title: 'Specialty',
      maxLength: 30,
      enum: [
        'Acute Care',
        'General Medical',
        'Long Term Acute Care',
        'Ambulatory Surgical Center',
        'Unspecified',
        'Federally Qualified Health Center',
        'Rehabilitation',
        'Skilled Nursing Facility',
        'Durable Medical Equipment',
        'Retail/Chain',
        'Government/Military/VA',
        'Urgent Care Center',
        'Clinic',
        'Physical Therapy Center',
        'OBGYN',
        'Oncology',
        'Surgical',
      ],
    },
    is_affiliated: { type: 'boolean', title: 'Affiliated' },
  },
};

const uiSchema = {
  email: {
    classNames: 'two-column-field',
  },
  phone: {
    classNames: 'two-column-field',
  },
  facility_type: {
    classNames: 'two-column-field',
  },
  specialty: {
    classNames: 'two-column-field',
  },
  is_affiliated: {
    classNames: 'two-column-field',
  },
};

const NEW_ORGANIZATION = {
  is_affiliated: false,
};

class OrganizationEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const {
      open,
      organizationData,
    } = this.props;

    if (organizationData) {
      organizationData.is_affiliated = (organizationData.affiliated
        && organizationData.affiliated.length > 0);
    }

    return (
      <DialogForm
        title={organizationData && organizationData.id > 0 ? 'Edit Organization' : 'Add Organization'}
        open={open}
        formData={organizationData || NEW_ORGANIZATION}
        schema={schema}
        uiSchema={uiSchema}
        onClose={this.props.onClose}
        onFormSubmit={this.onSave}
        status={this.state.userDialogError ? this.state.userDialogErrorMessage : ''}
        statusIsError
      />
    );
  }

  onSave = (data) => {
    if (data.phone) {
      data.phone = PhoneUtil.unformat(data.phone);
      if (!data.phone || !PhoneUtil.isValid(data.phone)) {
        this.setState({
          userDialogError: true,
          userDialogErrorMessage: 'Invalid phone number.',
        });
        return;
      }
    }

    this.props.onSave(data);
  };
}

OrganizationEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  organizationData: PropTypes.object,
};

const styled = withStyles(styles)(OrganizationEditDialog);
export { styled as OrganizationEditDialog };
