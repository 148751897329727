import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Icon, Typography, Tooltip } from '@mui/material';
import { StoreUtil } from 'doctivity-shared/utils';
import { DynamicTable, ProvidersTabs } from 'components';
import { ActivityLogsUtil } from 'utils';
import { activityLogsByUserId } from 'store/actions/activityLogsActions';
import { queryProviders } from 'store/actions/providersActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
});

class RecentProvidersPage extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        label: '',
        key: 'affiliated',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: (affiliated) => {
          if (affiliated) {
            return (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 200,
                    }}
                  >
                    Affiliated Provider
                  </Typography>
                }
              >
                <div
                  style={{
                    width: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon className={props.classes.affiliatedIcon}>hub</Icon>
                </div>
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        label: 'Last Name',
        key: 'last_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'First Name',
        key: 'first_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'NPI',
        key: 'npi',
      },
      {
        label: 'Specialty',
        key: 'taxonomy.classification',
      },
      {
        label: 'City',
        key: 'location.city',
        fulltext: true,
      },
      {
        label: 'State',
        key: 'location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
      },
    ];

    this.state = {
      query: {
        include: [
          {
            association: 'taxonomy',
          },
          {
            attributes: ['id', 'city', 'state'],
            association: 'location',
          },
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { recents } = this.props;

    if (StoreUtil.needsLoadNoCache(recents)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { recents } = this.props;

    if (!StoreUtil.isLoaded(prevProps.recents) && StoreUtil.isLoaded(recents)) {
      this.fetchAffiliated();
    }
  }

  fetchData() {
    const { dispatch, userId, clientId, providers } = this.props;

    const { query } = this.state;

    StoreUtil.setInvalid(providers, StoreUtil.COMMON_TABLE);
    dispatch(
      activityLogsByUserId(userId, 'Providers', clientId, {
        ...query,
      })
    );
  }

  fetchAffiliated() {
    const { dispatch, recents, clientId } = this.props;

    const { query } = this.state;

    if (recents?.data?.rows) {
      const recentIds = recents.data.rows.map((recent) => recent.id);
      dispatch(
        queryProviders({
          ...query,
          where: { ...query.where, id: recentIds },
          include: [
            {
              association: 'clients',
              attributes: ['id'],
              required: false,
              through: { attributes: [] }, // don't need the join table data
              where: {
                id: clientId,
              },
            },
          ],
        })
      );
    }
  }

  render() {
    const { recents, classes, providers } = this.props;

    const { query } = this.state;

    const providersData = StoreUtil.getData(providers);
    const recentsData = StoreUtil.getData(recents);
    if (
      providersData?.rows &&
      providersData?.rows.length === recentsData?.rows?.length
    ) {
      for (let i = 0; i < providersData.rows.length; i++) {
        recentsData.rows[i].affiliated =
          providersData.rows.find((provider) => {
            return provider.id === recentsData.rows[i].id;
          })?.clients?.length > 0;
      }
    }

    return (
      <>
        <ProvidersTabs tab='recent_providers' />
        <div className={classes.container}>
          <DynamicTable
            header='Recent Providers'
            showFilters={false}
            columns={this.columns}
            query={query}
            data={recents}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/providers/${row.id}`}
          />
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };
}

RecentProvidersPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  recents: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  providers: PropTypes.object,
};

function mapStateToProps(state) {
  const { activityLogs } = state;

  const userId = state.user ? state.user.id : null;

  return {
    userId,
    clientId: state.app.selectedClient,
    providers: StoreUtil.get(state.providers, StoreUtil.COMMON_TABLE),
    recents: ActivityLogsUtil.getProvidersFromLogs(activityLogs, userId),
  };
}

const styled = withStyles(styles)(RecentProvidersPage);
const connected = connect(mapStateToProps)(styled);
export { connected as RecentProvidersPage };
