import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import { Typography, Tabs, Tab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';
import { TagsAllTable } from '../../components/TagsAllTable'

const styles = (theme) => ({
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    backgroundColor: theme.palette.primary.accent,
  },
  headerContainer: {
    marginBottom: theme.spacing(3),
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingTop: 3,
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.primary.accent,
  },
  indicator: {
    backgroundColor: '#FFFFFF',
    height: 2,
    marginBottom: 8,
  },
  tabPanel: {
    color: '#E0E0E0',
    '&.Mui-selected': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
});

function TagsPage(props) {
  const { classes } = props
  const [currentTab, setCurrentTab] = useState('all')  
  const onTabChange = (_, tab) => setCurrentTab(tab);
  const onRowClick = useCallback((row) => {
    props.router.navigate(`/labels/${row.id}`);
  }, []);
    return (
       <>
        <Helmet defer={false}>
          <title>
            Labels 
          </title>
        </Helmet>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography className={classes.headerTitle}>
              Labels
            </Typography>
          </div>
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            TabIndicatorProps={{ className: classes.indicator }}
            value={currentTab}
            onChange={onTabChange}
          >
            <Tab
              className={classes.tabPanel}
              label='All'
              value='all'
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Provider Labels'
              value='provider_tags'
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Activity Note Labels'
              value='activity_notes'
              disableRipple
            />
          </Tabs>
        </div>
        {currentTab === 'all' && <TagsAllTable embedded={false}  onRowClick={onRowClick} key='all' />}
        {currentTab === 'provider_tags' && <TagsAllTable embedded={false}  onRowClick={onRowClick} type='provider' key='providers' />}
        {currentTab === 'activity_notes' && <TagsAllTable embedded={false}  onRowClick={onRowClick} type='activity_note' key='notes' />}
      </>
    );
}

TagsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

function mapStateToProps(/* state */) {

  return {
  };
}

const styled = withStyles(styles)(TagsPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as TagsPage };
