import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Icon, Select, Tooltip, MenuItem, InputLabel, FormControl } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { StoreUtil } from 'doctivity-shared/utils';
import { TagsUtil } from '../utils';

import { listTagNamespacesByClient } from 'store/actions/tagNamespacesActions';
import { withRouter } from 'utils';

const styles = (theme) => ({
  darkBackgroundSelect: {
    height: '58px',
    '&.MuiOutlinedInput-root': {
      padding: '1px',
      '& fieldset': {
        borderColor: theme.palette.tertiary.main,
        color: theme.palette.tertiary.main,
      },
      '& svg': {
        color: theme.palette.tertiary.main,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.tertiary.main,
        color: theme.palette.tertiary.main,
      },
      '&:hover svg': {
        color: theme.palette.tertiary.main,
      },
    },
    color: theme.palette.tertiary.main,
    '& .MuiInputBase-input': {
      borderRadius: 4,
      position: 'relative',
      color: theme.palette.tertiary.main,
    },
    '& $namespaceContainer': {
      minWidth: '7rem',
    },
    '& $namespaceContainer:hover $tagTypeIcons': {
      display: 'none',
      opacity: 0,
      visibility: 'hidden',
    }
  },
  select: {
    '& $namespaceContainer:hover $tagTypeIcons': {
      display: 'none',
      opacity: 0,
      visibility: 'hidden',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(2),
    maxWidth: '85%',
  },
  namespaceDropDown: {
    textOverflow: 'ellipsis',
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 150,
  },
  tagDropDown: {
    textOverflow: 'ellipsis',
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 300,
  },
  darkDropdown: {
    '& label': {
      color: theme.palette.tertiary.main,
    },
  },
  noTagsAvailable: {
    paddingLeft: theme.spacing(1),
    fontSize: '13px',
    fontStyle: 'italic',
    color: '#9E9E9E',
    margin: theme.spacing(2),
    fontFamily: 'Roboto',
    width: '100%',
    textAlign: 'center',
  },
  namespaceContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: '15rem',
    '&:hover $tagTypeIcons': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  tagTypeIcons: {
    display: 'inline-flex',
    flexDirecton: 'row',
    overflowX: 'hidden',
    justifyContent: 'flex-end',
    width: 80,
    flex: 1,
    paddingLeft: theme.spacing(2),
    color: theme.palette.tertiary.accent,
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s, opacity 0.5s linear',
  },
  disabledTypeIcon: {
    color: theme.palette.tertiary.secondary,
  },
});

class TagSelector extends React.Component {
  componentDidMount() {
    this.checkState();
  }

  componentDidUpdate() {
    this.checkState();
  }

  checkState() {
    if (
      StoreUtil.needsLoadLongCache(this.props.tagNamespaces) ||
      this.props.tagNamespaces.clientId !== this.props.clientId
    ) {
      this.fetchData();
    } else if (
      StoreUtil.isLoaded(this.props.tagNamespaces) &&
      !this.props.tagId
    ) {
      const savedTag = localStorage.getItem('tagId');
      let tag;
      if (savedTag) {
        tag = TagsUtil.findTag(savedTag, this.props.tagNamespaces);
        if (tag) {
          this.sendChange(tag);
        }
      }
      if (!tag) {
        const namespaces = TagsUtil.getNamespacesByType(
          this.props.type,
          this.props.tagNamespaces,
        );
        if (
          namespaces &&
          namespaces.length > 0 &&
          namespaces[0].tags.length > 0
        ) {
          this.sendChange(namespaces[0].tags[0]);
        }
      }
    }
  }

  fetchData() {
    this.props.dispatch(listTagNamespacesByClient(this.props.clientId));
  }

  sendChange(tag) {
    if (this.props.onChange) {
      localStorage.setItem('tagId', tag?.id); 
      this.props.onChange(tag);
    }
  }

  render() {
    const { classes } = this.props;

    const namespaces = TagsUtil.getNamespacesByType(
      this.props.type,
      this.props.tagNamespaces,
    ).filter((ns) => (ns.activity_notes_drop_down ||
                      ns.providers_drop_down ||
                      ns.organizations_drop_down) &&
                      ns.tags.length > 0);
    let selectedNamespace = null;
    if (this.props.tagId) {
      selectedNamespace = TagsUtil.findNamespaceForTagId(
        this.props.tagId,
        this.props.tagNamespaces,
      );
      if (!namespaces.find((ns) => ns.id === selectedNamespace?.id)) {
        selectedNamespace = null;
      }
    }
    if (Object.keys(namespaces)?.length < 1) {
      return (
        <span className={classes.noTagsAvailable}>
          Labels allow you to group organizations and providers.
          <br />
          Contact a Doctivity admin if you’d like a new Label Type or Labels
          added to your client.
        </span>
      );
    }
    return (
      <div className={classes.container}>
        <FormControl
          variant='outlined'
          className={`${classes.namespaceDropDown} ${this.props.darkBackground && classes.darkDropdown}`}
          key='filter-tag-namespace'
        >
          <InputLabel>Label Type</InputLabel>
          <Select
            className={
              this.props.darkBackground
                ? classes.darkBackgroundSelect
                : classes.select
            }
            value={selectedNamespace?.id || ''}
            label='Label Type'
            onChange={this.handleTagNamespaceChange}
          >
            {namespaces?.map((namespace) => (
              <MenuItem className={`${classes.namespaceContainer} ${classes.namespaceContainerHover}`} key={namespace.id} value={namespace.id}>
                  <span>{namespace.name}</span>

                  {this.props.darkBackground && 
                  <div className={classes.tagTypeIcons}>
                    <Tooltip title='Activity Notes'>
                      <Icon
                        className={
                          namespace.activity_notes_drop_down
                            ? ''
                            : classes.disabledTypeIcon
                        }
                      >
                        speaker_notes
                      </Icon>
                    </Tooltip>
                    <Tooltip title='Providers'>
                      <Icon
                        className={
                          namespace.providers_drop_down
                            ? ''
                            : classes.disabledTypeIcon
                        }
                      >
                        people
                      </Icon>
                    </Tooltip>
                    <Tooltip title='Organizations'>
                      <Icon
                        className={
                          namespace.organizations_drop_down
                            ? ''
                            : classes.disabledTypeIcon
                        }
                      >
                        apartment
                      </Icon>
                    </Tooltip>
                  </div>}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          variant='outlined'
          className={`${classes.tagDropDown} ${this.props.darkBackground && classes.darkDropdown}`}
          key='filter-tag'
        >
          <InputLabel>Label</InputLabel>
          <Select
            className={
              this.props.darkBackground
                ? classes.darkBackgroundSelect
                : classes.select
            }
            value={(selectedNamespace?.tags && this.props.tagId) || ''}
            label='Label'
            onChange={this.handleTagChange}
          >
            {selectedNamespace?.tags?.map((tag) => (
              <MenuItem key={tag.id} value={tag.id}>
                {tag.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  }

  handleTagNamespaceChange = (event) => {
    const namespace = TagsUtil.findNamespace(
      event.target.value,
      this.props.tagNamespaces,
    );
    if (namespace.tags.length > 0) {
      this.sendChange(namespace.tags[0]);
    } else {
      this.sendChange(null);
    }
  };

  handleTagChange = (event) => {
    const tag = TagsUtil.findTag(event.target?.value, this.props.tagNamespaces);
    this.sendChange(tag || null);
  };
}

TagSelector.propTypes = {
  clientId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  darkBackground: PropTypes.bool,
  router: PropTypes.object.isRequired,
  type: PropTypes.oneOf([
    'all',
    'organizations',
    'providers',
    'activity_notes',
  ]),
  tagId: PropTypes.number,
  tagNamespaces: PropTypes.object,
  onChange: PropTypes.func,
};

function mapStateToProps(state, props) {
  const { app } = state;
  const tagId = parseInt(props.tagId, 10);

  return {
    clientId: app.selectedClient,
    tagNamespaces: StoreUtil.get(
      state.tagNamespaces,
      'API_TAG_NAMESPACES_LIST_BY_CLIENT',
    ),
    tagId,
  };
}

const styled = withStyles(styles)(TagSelector);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as TagSelector };
