import { PatientType } from 'doctivity-shared/utils';
import { success } from 'redux-saga-requests';

import {
  APP_SELECT_CLIENT,
  APP_SET_DATE_RANGE,
  APP_SET_MARKET,
  APP_SET_SERVICELINE,
  APP_SET_PATIENT_TYPE,
  APP_SET_PROVIDERTYPE,
} from 'store/actions/appActions';
import {
  API_CLIENTS_LIST,
} from 'store/actions/clientsActions';
import {
  API_LOGOUT,
} from 'store/actions/loginActions';

/**
 * Store global application state/settings here
 * @param {*} state
 * @param {*} action
 */
export const app = (state = {}, action = null) => {
  if (!state.patientType) {
    state.patientType = PatientType.ALL_PATIENTS;
  }

  switch (action.type) {
    case API_LOGOUT:
      return {};
    case APP_SELECT_CLIENT: {
      const dateRange = state.dateRange;
      if (dateRange
        && dateRange.preset
        && dateRange.start
        && dateRange.end) {
        // this forces header to look at the NEW client end dates
        dateRange.start = dateRange.start.toString();
        dateRange.end = dateRange.end.toString();
      }
      return {
        ...state,
        selectedClient: action.payload,
        dateRange,
      };
    }
    case APP_SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case APP_SET_SERVICELINE:
      return {
        ...state,
        serviceline: action.payload,
      };
    case APP_SET_PROVIDERTYPE:
      return {
        ...state,
        providerType: action.payload,
      };
    case APP_SET_MARKET:
      return {
        ...state,
        market: action.payload,
      };
    case success(API_CLIENTS_LIST):
      let selectedClient = state.selectedClient;
      if (action.data && action.data.length > 0) {
        if (selectedClient) {
          const client = action.data.find((client) => client.id === selectedClient);
          if (!client) { // do not have access to previously selected client, default ot first one
            selectedClient = action.data[0].id;
          }
        } else {
          selectedClient = action.data[0].id;
        }
      }
      return {
        ...state,
        selectedClient,
      };
    case APP_SET_PATIENT_TYPE:
      return {
        ...state,
        patientType: action.payload,
      };
    default:
      return state;
  }
};
