import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import {
  Typography,
} from '@mui/material';

import {
  DialogForm,
  DynamicTable,
  ConfirmationDialog,
  SmallIconTextButton,
} from 'components';

const styles = (theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
});

class TableWithDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      confirmationDialogOpen: false,
      editData: {
      },
      confirmationFormData: {
      },
    };

    this.onSave = this.onSave.bind(this);
    this.openDialog = this.openDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
  }

  onRowClick(row) {
    const {
      allowEdit,
      decorateEditItem,
    } = this.props;

    row = { ...row };
    if (decorateEditItem) {
      row = decorateEditItem(row);
    }

    if (allowEdit) {
      this.setState({
        dialogOpen: true,
        editData: row,
      });
    }

    if (this.props.onRowClick) {
      this.props.onRowClick(row);
    }
  }

  onSave(data) {
    const {
      onSave,
      showConfirmation,
    } = this.props;

    if (showConfirmation && data.id) {
      this.setState({
        confirmationDialogOpen: true,
        confirmationFormData: data,
      });
    } else {
      if (onSave) {
        onSave(data);
      }

      this.closeDialog();
    }
  }

  openDialog() {
    const {
      newItem,
    } = this.props;

    this.setState({
      dialogOpen: true,
      editData: newItem || {},
    });
  }

  closeDialog() {
    this.setState({ dialogOpen: false });
  }

  closeConfirmation = () => {
    this.setState({
      confirmationDialogOpen: false,
    });
  };

  confirmConfirmation = () => {
    this.setState({
      confirmationDialogOpen: false,
      dialogOpen: false,
    }, () => {
      if (this.props.onSave) {
        this.props.onSave(this.state.confirmationFormData);
      }
    });
  };

  render() {
    const {
      classes,
      dialogSchema,
      dialogUISchema,
      title,
      dialogTitle,
      allowNew,
      secondaryText,
    } = this.props;

    const {
      dialogOpen,
      editData,
    } = this.state;

    if (editData && editData.id) {
      dialogSchema.title = `Edit ${dialogTitle}`;
    } else {
      dialogSchema.title = `New ${dialogTitle}`;
    }

    return (
      <>
        <DynamicTable
          header={(
            <div className={classes.headerContainer}>
              <Typography component='h2' variant='h6' color='primary' gutterBottom>
                {title}
              </Typography>
              <div className={classes.header} />
              {allowNew && (
                <SmallIconTextButton
                  onClick={this.openDialog}
                  icon='add'
                  text={`Add ${dialogTitle}`}
                />
              )}
            </div>
        )}
          onRowClick={this.onRowClick}
          {...this.props}
        />

        <DialogForm
          formData={editData}
          open={dialogOpen}
          schema={dialogSchema}
          uiSchema={dialogUISchema}
          onClose={this.closeDialog}
          onFormSubmit={this.onSave}
          secondaryText={secondaryText}
          validate={this.props.formValidate}
          renderAdditionalFormContent={this.props.dialogAdditionalContent}
        />

        <ConfirmationDialog
          open={this.state.confirmationDialogOpen}
          title={dialogSchema.title}
          content={dialogSchema.confirmationContent}
          onCancel={this.closeConfirmation}
          onConfirm={this.confirmConfirmation}
          onClose={this.closeConfirmation}
        />

      </>
    );
  }
}
TableWithDialog.defaultProps = {
  allowEdit: true,
  allowNew: true,
};

TableWithDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  dialogTitle: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  dialogSchema: PropTypes.object.isRequired,
  dialogUISchema: PropTypes.object.isRequired,
  allowNew: PropTypes.bool,
  allowEdit: PropTypes.bool,
  newItem: PropTypes.object,
  decorateEditItem: PropTypes.func,
  onSave: PropTypes.func,
  secondaryText: PropTypes.string,
  onRowClick: PropTypes.func,
  formValidate: PropTypes.func,
  showConfirmation: PropTypes.bool,
  dialogAdditionalContent: PropTypes.func,
};

const styled = withStyles(styles)(TableWithDialog);
export { styled as TableWithDialog };
