export const API_SCORECARDS_TABLE = 'API_SCORECARDS_TABLE';
export const API_SCORECARDS_LIST = 'API_SCORECARDS_LIST';
export const API_SCORECARDS_UPSERT = 'API_SCORECARDS_UPSERT';

export const queryScorecards = (options, type = API_SCORECARDS_TABLE) => ({
  type,
  request: {
    url: '/Scorecards',
    method: 'GET',
    params: { opts: options },
  },
});

export const listScorecards = (providerId, clientId) => (queryScorecards({
  where: {
    provider_id: {
      $eq: providerId,
    },
    client_id: {
      $eq: clientId,
    },
  },
  order: [
    ['year', 'ASC'],
    ['month', 'ASC'],
  ],
}, API_SCORECARDS_LIST));

export const upsertScorecards = (data) => {
  const id = 0; // should be an id, but this is an array?
  return {
    type: API_SCORECARDS_UPSERT,
    id,
    request: {
      url: '/Scorecards',
      method: 'PUT',
      params: { opts: null },
      data,
    },
    nextAction: listScorecards(data[0].provider_id, data[0].client_id),
  };
};
