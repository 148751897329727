import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_LOCATIONS_TABLE,
  API_LOCATIONS_LIST,
  API_LOCATIONS_UPSERT,
  API_LOCATIONS_LOAD,
  API_LOCATIONS_TABLE_PROVIDERS,
  API_LOCATIONS_TABLE_CONTACTS,
  API_LOCATIONS_TABLE_PROVIDER_COMMENTS,
} from 'store/actions/locationsActions';

export const locations = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_LOCATIONS_TABLE,
    API_LOCATIONS_LIST
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceUpsert(state, action, API_LOCATIONS_UPSERT);
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceSingleGet(state, action, API_LOCATIONS_LOAD);
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceTableGet(
    state,
    action,
    API_LOCATIONS_TABLE_PROVIDERS,
    'providers'
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceTableGet(
    state,
    action,
    API_LOCATIONS_TABLE_CONTACTS,
    'contacts'
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceTableGet(
    state,
    action,
    API_LOCATIONS_TABLE_PROVIDER_COMMENTS,
    'providerComments'
  );
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
