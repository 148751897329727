import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
// import { goBack } from 'connected-react-router';
import { withIsMobile, withRouter } from 'utils';
import { connect } from 'react-redux';
import { MuiForm5 as Form } from '@rjsf/material-ui';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { TextWidget } from 'components/material-form/components/TextWidget';
import { SelectWidget } from 'components/material-form/components/SelectWidget';
import { CheckboxWidget } from 'components/material-form/components/CheckboxWidget';
import { DateWidget } from 'components/material-form/components/DateWidget';
import { CheckboxesWidget } from 'components/material-form/components/CheckboxesWidget';
import { FileWidget } from 'components/material-form/components/FileWidget';
import { RadioWidget } from 'components/material-form/components/RadioWidget';
import { TitleField } from 'components/material-form/components/TitleField';
import { TextareaWidget } from 'components/material-form/components/TextareaWidget';
import { DateTimeWidget } from 'components/material-form/components/DateTimeWidget';
import { MaterialArrayFieldTemplate } from 'components/material-form/components/MaterialArrayFieldTemplate';
import { AutocompleteSingleWidget } from 'components/material-form/components/AutocompleteSingleWidget';

const styles = (theme) => ({
  formContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    maxWidth: 800,
  },
  formDialog: {
    width: '100%',
    maxWidth: 500,
  },
  formCard: {
    width: '100%',
  },
  button: {
    marginLeft: theme.spacing(2),
  },
  submitContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    width: '100%',
  },
});

function FieldTemplate(propsTemplate) {
  const { classNames, children, hidden } = propsTemplate;
  if (hidden) {
    return children;
  }

  return <div className={classNames}>{children}</div>;
}

function ObjectFieldTemplate(propsTemplate) {
  const { DescriptionField } = propsTemplate;

  return (
    <>
      {(propsTemplate.uiSchema['ui:title'] || propsTemplate.title) && (
        <TitleField
          id={`${propsTemplate.idSchema.$id}__title`}
          title={propsTemplate.title || propsTemplate.uiSchema['ui:title']}
          required={propsTemplate.required}
          formContext={propsTemplate.formContext}
        />
      )}
      {propsTemplate.description && (
        <DescriptionField
          id={`${propsTemplate.idSchema.$id}__description`}
          description={propsTemplate.description}
          formContext={propsTemplate.formContext}
        />
      )}
      {propsTemplate.properties.map((prop) => prop.content)}
    </>
  );
}

const customFields = { TitleField };
const customWidgets = {
  TextWidget,
  SelectWidget,
  CheckboxWidget,
  CheckboxesWidget,
  FileWidget,
  RadioWidget,
  DateWidget,
  TextareaWidget,
  DateTimeWidget,
  AutocompleteSingleWidget,
};

const uiSchemaDefault = {
  'ui:options': {
    displayLabel: false,
  },
};

class MaterialForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasBeenSubmittedWithErrors: false,
      errorMessage: null,
      refresh: false, // Used to manually rerender for mobile because isMobile is always false on first load
    };

    this.onFormError = this.onFormError.bind(this);
    this.onPrimaryClick = this.onPrimaryClick.bind(this);
    this.onSecondaryClick = this.onSecondaryClick.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.transformFormErrors = this.transformFormErrors.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
  }

  onFormChange(data) {
    const { onChange, onDataTransform } = this.props;

    let formData = data.formData;

    // All fields that are blank return undefined, change to null so that the
    // database is updated correctly
    Object.keys(formData).forEach((key) => {
      if (formData[key] === undefined) {
        formData[key] = null;
      }
    });

    if (onDataTransform) {
      formData = onDataTransform(formData);
    }

    this.setState({
      localFormData: formData,
      errorMessage: null,
    });

    data.formData = formData;

    if (onChange) {
      onChange(data);
    }
  }

  onFormError(data) {
    const { onError } = this.props;

    const { hasBeenSubmittedWithErrors, errorMessage } = this.state;

    let newErrorMessage;
    if (data && data.length > 0) {
      newErrorMessage = this.getMessageFromError(data[0]);
    }

    if (!hasBeenSubmittedWithErrors || errorMessage !== newErrorMessage) {
      this.setState({
        hasBeenSubmittedWithErrors: true,
        errorMessage: newErrorMessage,
      });
    }

    if (onError) {
      onError(data);
    }
  }

  onPrimaryClick() {
    const { submitText } = this.props;

    if (submitText) {
      this.form.state.submitSource = submitText;
    } else {
      this.form.state.submitSource = 'Save';
    }
  }

  onSecondaryClick() {
    const { onSecondarySubmit } = this.props;

    if (onSecondarySubmit) {
      onSecondarySubmit();
    }
  }

  onCancelClick() {
    const { backOnCancel, onCancel } = this.props;

    if (backOnCancel) {
      this.props.router.navigate(-1);
    } else if (onCancel) {
      onCancel();
    }
  }

  getMessageFromError(error) {
    if (error && error.property && error.message) {
      const fieldName = this.findNameOfProperty(error.property);
      const message = `${fieldName} ${error.message}`;
      return message;
    }

    return '';
  }

  findNameOfProperty(property) {
    const { schema } = this.props;

    const propertyName = this.scrubProperNameInError(schema, property);

    if (
      schema &&
      schema.properties &&
      schema.properties[propertyName] &&
      schema.properties[propertyName].title
    ) {
      return schema.properties[propertyName].title;
    }

    return '';
  }

  scrubProperNameInError(schema, propertyName) {
    /* there seems to be a bug in form code that property is ['propery']
    instead of just property */
    if (propertyName.startsWith("['") && propertyName.endsWith("']")) {
      let propNameParsed = propertyName.replace("['", '');
      propNameParsed = propNameParsed.replace("']", '');
      if (schema.properties && schema.properties[propNameParsed]) {
        propertyName = propNameParsed;
      }
    } else if (propertyName.startsWith('.')) {
      const propNameParsed = propertyName.substring(1, propertyName.length);
      if (schema.properties && schema.properties[propNameParsed]) {
        propertyName = propNameParsed;
      }
    }

    return propertyName;
  }

  transformFormErrors(errors) {
    if (!errors) {
      return null;
    }

    const { schema, formData, extraTransformError } = this.props;

    const { localFormData } = this.state;

    let usedFormData = formData;
    if (localFormData) {
      usedFormData = localFormData;
    }

    let usedErrors = [];
    const errorMap = {};
    if (errors) {
      errors.forEach((oldError) => {
        const propertyName = this.scrubProperNameInError(
          schema,
          oldError.property
        );

        if (!errorMap[propertyName]) {
          errorMap[propertyName] = [oldError.name];
        } else {
          errorMap[propertyName].push(oldError.name);
        }

        if (oldError.name === 'format') {
          // format errors is only valid if the field is required!!
          // this allows empty values, needed for non-required items
          if (schema.required && schema.required.includes(propertyName)) {
            usedErrors.push(oldError);
          }
        } else if (oldError.name === 'enum') {
          // error is only valid if the field is required!!
          // this allows empty enum values, needed for non-required enums

          if (schema.required && schema.required.includes(propertyName)) {
            usedErrors.push(oldError);
          }
        } else if (oldError.name === 'minLength') {
          if (
            (usedFormData && usedFormData[propertyName]) ||
            (schema.required && schema.required.includes(propertyName))
          ) {
            usedErrors.push(oldError);
          }
        } else if (
          oldError.name === 'type' &&
          oldError.params &&
          oldError.params.type === 'number'
        ) {
          if (usedFormData && usedFormData[propertyName]) {
            // parse it and see if a number
            const parsed = Number(usedFormData[propertyName]);
            if (Number.isNaN(parsed)) {
              usedErrors.push(oldError);
            }
          }
        } else if (
          oldError.name === 'type' &&
          oldError.params &&
          oldError.params.type === 'string'
        ) {
          const isRequired =
            schema.required && schema.required.includes(propertyName);
          if (isRequired || (usedFormData && usedFormData[propertyName])) {
            usedErrors.push(oldError);
          }
        } else {
          usedErrors.push(oldError);
        }
      });

      // check on the required, sometimes not there
      if (schema.required && schema.required.length > 0) {
        schema.required.forEach((requiredField) => {
          if (
            usedFormData &&
            (!usedFormData[requiredField] ||
              (Array.isArray(usedFormData[requiredField]) &&
                (usedFormData[requiredField].length === 0 ||
                  !usedFormData[requiredField][0])))
          ) {
            // empty required field... make sure in errors
            if (
              !errorMap[requiredField] ||
              !errorMap[requiredField].includes('required')
            ) {
              usedErrors.push({
                message: 'is a required property',
                name: 'required',
                params: {
                  missingProperty: requiredField,
                },
                property: `.${requiredField}`,
                stack: `.${requiredField} is a required property`,
              });
            }
          }
        });
      }
    }

    if (extraTransformError) {
      usedErrors = extraTransformError(usedFormData, usedErrors);
    }

    return usedErrors;
  }

  render() {
    const {
      classes,
      status,
      allowSubmit,
      statusIsError,
      schema,
      uiSchema,
      onError,
      onChange,
      formData,
      inDialog,
      inCard,
      submitText,
      secondaryText,
      validateImmediate,
      onDataTransform,
      key,
      showCancel,
      onClear,
      validate,
      isMobile,
      ...otherProps
    } = this.props;

    const { hasBeenSubmittedWithErrors, localFormData, errorMessage, refresh } =
      this.state;

    if (onError || onChange || onDataTransform) {
      // needs to be grabbed so not in otherProps
    }

    let usedStatus = status;
    const disableSubmit = allowSubmit === false;
    let statusColor = 'textSecondary';
    if (statusIsError === true || errorMessage) {
      statusColor = 'error';
    }
    if (errorMessage) {
      usedStatus = errorMessage;
    }

    let usedUISchema = uiSchema;
    if (usedUISchema) {
      if (isMobile) {
        Object.keys(usedUISchema).forEach((key) => {
          if (usedUISchema[key].classNames) {
            usedUISchema[key].classNames = usedUISchema[key].classNames.replace(
              'two-column-field',
              'one-column-field'
            );
          }
        });
      }
      usedUISchema['ui:options'] = {
        displayLabel: false,
      };
    } else {
      usedUISchema = uiSchemaDefault;
    }

    let usedFormData = formData;
    if (localFormData) {
      usedFormData = localFormData;
    }

    let formCls = classes.form;
    if (inDialog) {
      formCls = classes.formDialog;
    }
    if (inCard) {
      formCls = classes.formCard;
    }

    let usedSubmitText;
    if (submitText) {
      usedSubmitText = submitText;
    } else {
      usedSubmitText = 'Save';
    }

    const leftButtonStyle = {
      marginLeft: '0',
    };

    let secondaryButton;

    if (secondaryText) {
      secondaryButton = typeof secondaryText === 'string' ? (
        <Button
          className={classes.button}
          color='primary'
          margin='normal'
          variant='contained'
          onClick={this.onSecondaryClick}
          style={leftButtonStyle}
          disabled={disableSubmit}
        >
          {secondaryText}
        </Button>) : secondaryText;
    }

    let formKey;

    if (!key) {
      formKey = 'form';
    } else {
      formKey = key;
    }

    if (isMobile && !refresh) {
      this.setState({ refresh: true });
    }

    return (
      <div className={classes.formContainer} key={+refresh}>
        <Form
          key={formKey}
          ObjectFieldTemplate={ObjectFieldTemplate}
          FieldTemplate={FieldTemplate}
          ArrayFieldTemplate={MaterialArrayFieldTemplate}
          className={formCls}
          fields={customFields}
          widgets={customWidgets}
          showErrorList={false}
          schema={schema}
          uiSchema={usedUISchema}
          liveValidate={validateImmediate || hasBeenSubmittedWithErrors}
          onChange={this.onFormChange}
          onError={this.onFormError}
          formData={usedFormData}
          {...otherProps}
          noHtml5Validate
          validate={validate}
          ref={(form) => {
            this.form = form;
          }}
          transformErrors={this.transformFormErrors}
        >
          <Grid
            container
            direction='row'
            justifyContent='space-between'
            alignItems='flex-start'
          >
            {this.props.renderAdditionalFormContent &&
              this.props.renderAdditionalFormContent(usedFormData)}
            <div className={classes.submitContainer}>
              {secondaryButton}
              {usedStatus && (
                <Typography color={statusColor} variant='subtitle1'>
                  {usedStatus}
                </Typography>
              )}
              {onClear && (
                <Button
                  className={classes.button}
                  margin='normal'
                  variant='contained'
                  onClick={onClear}
                >
                  Clear
                </Button>
              )}
              {showCancel && (
                <Button
                  className={classes.button}
                  margin='normal'
                  variant='text'
                  onClick={this.onCancelClick}
                >
                  Cancel
                </Button>
              )}
              <Button
                className={classes.button}
                type='submit'
                color='primary'
                margin='normal'
                variant='contained'
                onClick={this.onPrimaryClick}
                disabled={disableSubmit}
              >
                {usedSubmitText}
              </Button>
            </div>
          </Grid>
        </Form>
      </div>
    );
  }
}

MaterialForm.defaultProps = {
  showCancel: true,
  backOnCancel: true,
};

MaterialForm.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  status: PropTypes.string,
  statusIsError: PropTypes.bool,
  allowSubmit: PropTypes.bool,
  validateImmediate: PropTypes.bool,
  uiSchema: PropTypes.object,
  schema: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onSecondarySubmit: PropTypes.func,
  onChange: PropTypes.func,
  onError: PropTypes.func,
  formData: PropTypes.object,
  inDialog: PropTypes.bool,
  inCard: PropTypes.bool,
  submitText: PropTypes.string,
  secondaryText: PropTypes.string,
  secondaryTextContainerClass: PropTypes.string,
  onDataTransform: PropTypes.func,
  key: PropTypes.string,
  extraTransformError: PropTypes.func,
  showCancel: PropTypes.bool,
  backOnCancel: PropTypes.bool,
  onCancel: PropTypes.func,
  onClear: PropTypes.func,
  renderAdditionalFormContent: PropTypes.func,
  validate: PropTypes.func,
};

const styled = withStyles(styles)(withIsMobile(MaterialForm));
const connected = connect()(styled);
const routed = withRouter(connected);
export { routed as MaterialForm };
