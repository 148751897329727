import React from 'react';
import { PropTypes } from 'prop-types';

import {
  Tooltip,
  Typography,
  IconButton,
  Icon,
} from '@mui/material';
import {
  SmallIconTextButton,
} from 'components';

import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  infoTipIcon: {
    position: 'relative',
    top: -4,
    marginLeft: theme.spacing(),
    fontSize: 14,
    fontWeight: 200,
    color: '#999999',
  },
  infoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  spacer: {
    flex: 1,
    minWidth: theme.spacing(2),
  },
  exportIcon: {
    fontSize: 16,
    fontWeight: 200,
  },
  rightLabel: {
    marginLeft: theme.spacing(),
    textAlign: 'right',
    fontSize: 13,
    fontWeight: 200,
    color: '#999999',
  },
});

class TitleTooltip extends React.Component {
  render() {
    const {
      classes,
      title,
      tooltip,
      onExport,
    } = this.props;

    return (
      <div className={classes.container}>
        <Typography component='h2' variant='h6' color='primary' gutterBottom>
          {title}
        </Typography>
        { tooltip && (
          <Tooltip
            placement='bottom-start'
            title={(
              <Typography className={classes.infoTip}>
                {tooltip}
              </Typography>
          )}
          >
            <Icon className={classes.infoTipIcon}>info_outlined</Icon>
          </Tooltip>
        )}
        { onExport && (
        <IconButton onClick={onExport} size='large'>
          <Icon className={classes.exportIcon}>get_app</Icon>
        </IconButton>
        )}
        { this.props.onAction && (
        <>
          <div className={classes.spacer} />
          <SmallIconTextButton
            icon={this.props.actionIcon}
            text={this.props.actionText}
            onClick={this.props.onAction}
          />
        </>
        )}
        { this.props.rightLabel && (
          <>
            <div className={classes.spacer} />
            <Typography className={classes.rightLabel}>
              {this.props.rightLabel}
            </Typography>
          </>
        )}
      </div>
    );
  }
}

TitleTooltip.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  onExport: PropTypes.func,
  actionIcon: PropTypes.string,
  actionText: PropTypes.string,
  rightLabel: PropTypes.string,
  onAction: PropTypes.func,
};

const styled = withStyles(styles)(TitleTooltip);
export { styled as TitleTooltip };
