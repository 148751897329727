import React from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Button, Icon, Card, CardContent, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { LoadingView, ProviderBillingDataDialog } from 'components';

import { StoreUtil } from 'doctivity-shared/utils';

import { loadAnalytics } from 'store/actions/analyticsActions';
import { upsertProviderRevenue } from 'store/actions/providerRevenueActions';

const styles = (theme) => ({
  card: {
    marginBottom: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  cardTitle: {
    flex: 1,
  },
  totalRevenue: {
    fontWeight: 300,
    fontSize: 14,
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class ClientRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openEditDialog: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.clientId !== prevProps.clientId ||
      this.props.dateRange !== prevProps.dateRange ||
      this.props.providerId !== prevProps.providerId
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, clientId, providerId, dateRange } = this.props;

    dispatch(
      loadAnalytics({
        type: 'NEW_REVENUE',
        filter: {
          client_id: clientId,
          from: dateRange.start,
          to: dateRange.end,
          provider_id: providerId,
        },
      }),
    );
  }

  render() {
    const { classes, clientId, providerId, report, dateRange, user } =
      this.props;

    let graph;
    if (!report || StoreUtil.isLoading(report)) {
      graph = <LoadingView />;
    } else {
      const data = StoreUtil.getData(report);
      if (data && data.length > 0) {
        const series = [];
        const months = [];
        let total = 0;
        let hb_charges = [];
        let pb_charges = [];

        data.forEach((row) => {
          months.push(
            moment().month(row.month).year(row.year).format('MMM YY'),
          );
          series.push(row.revenue || 0);
          if (!!row.hb_charged) {
            hb_charges.push(row.hb_charged);
          } else {
            hb_charges.push(null);
          }
          if (!!row.pb_charged) {
            pb_charges.push(row.pb_charged);
          } else {
            pb_charges.push(null);
          }

          total += row.revenue;
        });
        const hasPBHBCharged =
          hb_charges.find((c) => c > 0) || pb_charges.find((c) => c > 0);
        const seriesOpts = [
          {
            name: 'Total Billing',
            type: 'line',
            data: series,
          },
        ];
        if (hb_charges.length > 0 && hasPBHBCharged) {
          seriesOpts.push({
            name: 'Hospital Billing',
            type: 'column',
            data: hb_charges,
          });
          seriesOpts.push({
            name: 'Professional Billing',
            type: 'column',
            data: pb_charges,
          });
        }
        graph = (
          <>
            <Typography className={classes.totalRevenue}>
              <b>{`$${Math.round(total).toLocaleString()} `}</b>
              &nbsp;billing from
              {` ${moment(dateRange.start).format('MMMM YYYY')} `}
              to
              {` ${moment(dateRange.end).format('MMMM YYYY')} `}
            </Typography>
            <Chart
              height={225}
              series={seriesOpts}
              options={{
                chart: {
                  type: 'line',
                  stacked: false,
                  toolbar: {
                    show: false,
                  },
                },
                xaxis: {
                  categories: months,
                },
                yaxis: [
                  {
                    seriesName: 'Total Billing',
                    labels: {
                      formatter: (value) =>
                        `$${Math.round(value).toLocaleString()}`,
                    },
                  },
                  ...(hasPBHBCharged
                    ? [
                        {
                          seriesName: 'Total Billing',
                          labels: {
                            show: false,
                            formatter: (value) =>
                              `$${Math.round(value).toLocaleString()}`,
                          },
                        },
                        {
                          seriesName: 'Total Billing',
                          labels: {
                            show: false,
                            formatter: (value) =>
                              `$${Math.round(value).toLocaleString()}`,
                          },
                        },
                      ]
                    : []),
                ],
                tooltip: {
                  enabled: true,
                },
                stroke: {
                  curve: 'smooth',
                },
              }}
            />
          </>
        );
      } else {
        graph = (
          <Typography className={classes.nothingFound}>
            No billing data found.
          </Typography>
        );
      }
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <Typography
              component='h2'
              variant='h6'
              color='primary'
              className={classes.cardTitle}
              gutterBottom
            >
              Billing
            </Typography>
            {user.is_admin && (
              <Button color='primary' size='small' onClick={this.openDialog}>
                <Icon className={classes.leftIcon}>edit</Icon>
                Edit
              </Button>
            )}
          </div>

          {graph}
          <ProviderBillingDataDialog
            clientId={clientId}
            providerId={providerId}
            data={StoreUtil.getData(report)}
            onClose={this.closeDialog}
            onSave={this.onSave}
            open={this.state.openEditDialog}
          />
        </CardContent>
      </Card>
    );
  }
  closeDialog = () => {
    this.setState({ openEditDialog: false });
  };
  openDialog = () => {
    this.setState({ openEditDialog: true });
  };
  onSave = (changedData) => {
    const { dispatch, clientId, providerId, dateRange } = this.props;
    if (changedData && changedData.length > 0) {
      dispatch(
        upsertProviderRevenue(
          changedData,
          loadAnalytics({
            type: 'NEW_REVENUE',
            filter: {
              client_id: clientId,
              from: dateRange.start,
              to: dateRange.end,
              provider_id: providerId,
            },
          }),
        ),
      );
    }
    this.closeDialog();
  };
}

ClientRevenue.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  clientId: PropTypes.number.isRequired,
  providerId: PropTypes.number,
  dateRange: PropTypes.object.isRequired,
  report: PropTypes.object,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  const { analytics, user } = state;

  return {
    report: StoreUtil.get(analytics, 'NEW_REVENUE'),
    user,
  };
}

const styled = withStyles(styles)(ClientRevenue);
const connected = connect(mapStateToProps)(styled);
export { connected as ClientRevenue };
