import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  text: {
    minHeight: '1em',
    marginBottom: '0.5em',
    textAlign: 'left',
  },
});

class UnderlineData extends React.Component {
  render() {
    const { classes, children } = this.props;

    return <Typography className={classes.text}>{children}</Typography>;
  }
}

UnderlineData.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
const styled = withStyles(styles)(UnderlineData);
export { styled as UnderlineData };
