import React, { useState, useEffect, useCallback } from 'react';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import { TagsDropdowns } from 'tags';
import {
  upsertProviderTag,
  deleteProviderTag,
} from 'store/actions/providerTagsActions';
import { listTagNamespacesByClient } from 'store/actions/tagNamespacesActions';
import { loadProvider } from 'store/actions/providersActions'
import { StoreUtil } from 'doctivity-shared/utils';
import { TagsUtil } from '../utils';

const styles = () => ({});

function EditProviderTags({ open, setOpen, provider, dispatch, tagNamespaces, clientId }) {
  const [selectedTags, setSelectedTags] = useState([]);
  const onCancel = useCallback(() => {
    setSelectedTags([]);
    setOpen(false);
  }, []);
  const onSaveMultiSelect = useCallback(() => {
    const tagIds = new Set(provider.tags.map((t) => t.id));
    const selectedTagIds = new Set(selectedTags.map((t) => t.id));
    const addedTags = selectedTags.filter((st) => !tagIds.has(st.id));
    const removedTags = provider.tags.filter((t) => !selectedTagIds.has(t.id));
    // console.error('adding', addedTags, 'removing', removedTags);
    if (addedTags.length > 0) {
      dispatch(
        upsertProviderTag(
          addedTags.map((t) => ({ tag_id: t.id, provider_id: provider.id })),
          loadProvider(provider.id, clientId),
        ),
      );
    }
    if (removedTags.length > 0) {
      dispatch(
        deleteProviderTag(
          removedTags.map((tag) => ({
            provider_id: provider.id,
            tag_id: tag.id,
          })),
          undefined,
          loadProvider(provider.id, clientId),
        ),
      );
    }
    setOpen(false);
  }, [dispatch, provider, selectedTags, clientId]);
  useEffect(() => {
    if (StoreUtil.needsLoadLongCache(tagNamespaces) || tagNamespaces.clientId !== clientId) {
      dispatch(listTagNamespacesByClient(clientId));
    }
  });
  useEffect(() => {
    const selTags = provider?.tags;
    if (Array.isArray(selTags) && selTags.length > 0) {
      setSelectedTags(selTags);
    }
  }, [tagNamespaces, provider, open]);
  const clearNamespace = useCallback((nsId) => {
    setSelectedTags(selectedTags.filter((t) => TagsUtil.findNamespaceForTagId(t.id, tagNamespaces)?.id !== nsId));
  }, [selectedTags]);

  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby='simple-dialog-title'
      fullWidth
      open={open}
      scroll='paper'
    >
      <DialogTitle>Edit Provider Labels</DialogTitle>
      <DialogContent dividers>
        <TagsDropdowns 
          type={'providers'}
          embedded={true}
          selectedTags={selectedTags}
          tagNamespaces={tagNamespaces}
          clearSelectedNamespace={clearNamespace}
          selectTag={(tag) => {
            const currentTagIndex = selectedTags.findIndex((t) => t.id === tag.id);
            if (currentTagIndex > -1) {
              setSelectedTags(selectedTags.toSpliced(currentTagIndex, 1));
            } else {
              setSelectedTags([...selectedTags, tag]);
            }
          }}
        />
        <DialogActions>
          <Button onClick={onCancel} color='primary'>
            Cancel
          </Button>
          <Button
            onClick={onSaveMultiSelect}
            variant='contained'
            color='primary'
          >
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

function mapStateToProps(state) {
  return {
    tagNamespaces: StoreUtil.get(state.tagNamespaces, 'API_TAG_NAMESPACES_LIST_BY_CLIENT'),
    clientId: state.app.selectedClient,
  };
}

EditProviderTags.propTypes = {
  dispatch: PropTypes.func,
  classes: PropTypes.object,
  open: PropTypes.bool,
  provider: PropTypes.object,
  setOpen: PropTypes.func,
  tagNamespaces: PropTypes.object.isRequired,
  clientId: PropTypes.number,
};
const styled = withStyles(styles)(EditProviderTags);
const connected = connect(mapStateToProps)(styled);
export { connected as EditProviderTags };
