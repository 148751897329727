import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from 'react-google-charts';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { StoreUtil, PatientType } from 'doctivity-shared/utils';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { Card, CardContent } from '@mui/material';
import { TitleTooltip, LoadingView } from 'components';
import { ColorUtil } from 'utils';
import { ComboFilter } from 'providers';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  filters: {
    flexBasis: '100%',
  },
  chartTitle: {
    fontSize: 16,
    fontWeight: 900,
    fontFamily: 'Roboto',
    marginBottom: theme.spacing(1),
    flexBasis: '50%',
  },
  container: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
  providerSankeyDivider: {
    writingMode: 'vertical-rl',
    textOrientation: 'upright',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: 'white',
    width: '50px',
    minWidth: '50px',
    maxWidth: '50px',
    borderRadius: '5px',
  },
  chart: {
    flexGrow: 1,
    flexBasis: '100%',
    width: '100%',
  },
  chartNote: {
    flexGrow: 1,
    paddingTop: theme.spacing(2),
    fontSize: 12,
    fontWeight: 300,
  },
});

function ProviderSankey(props) {
  const { providerId, classes } = props;
  const dispatch = useDispatch();
  const flow = useSelector((state) =>
    StoreUtil.get(state.analytics, 'BIDIRECTIONAL_FLOW'),
  );
  const { data } = flow;
  const selectedClient = useSelector((state) => state.app.selectedClient);
  const [rows, setRows] = useState([]);
  const [height, setHeight] = useState(null);
  const [query, setQuery] = useState({
    type: 'BIDIRECTIONAL_FLOW',
    filter: {
      client_id: selectedClient,
      provider_id: providerId,
    },
    opts: {
      limit: 100,
      offset: 0,
    },
  });
  useEffect(() => {
    setHeight(null);
    dispatch(loadAnalytics(query));
  }, [providerId, query]);
  useEffect(() => {
    const rows = [];
    rows.push([
      { label: 'From', type: 'string' },
      { label: 'To', type: 'string' },
      { label: 'Shared Patients', type: 'number' },
    ]);
    if (data && data.length > 0) {
      data.forEach((row) => {
        if (row.provider_1_id === providerId) {
          rows.push([
            {
              v: `${row.provider1.last_name}, ${row.provider1.first_name}`,
              f: row.provider_1_id,
            },
            {
              v: `${row.provider2.last_name}, ${row.provider2.first_name} `,
              f: row.provider_2_id,
            },
            row.sum_shared_patients,
          ]);
        } else {
          rows.push([
            {
              v: `${row.provider1.last_name}, ${row.provider1.first_name}`,
              f: row.provider_1_id,
            },
            {
              v: `${row.provider2.last_name}, ${row.provider2.first_name}`,
              f: row.provider_2_id,
            },
            row.sum_shared_patients,
          ]);
        }
      });
    }
    setRows(rows);
    if (rows.length > 1) {
      setTimeout(() => {
        // the react wrapper around the Sankey chart seems to have
        // trouble resizing when you adjust the height while its
        // rendering the first time
        setHeight((rows.length ?? 3) * 16);
      }, 500);
    }
  }, [data]);

  const setFilter = useCallback(
    ({ serviceline, specialty, patientType }) => {
      const newQuery = { ...query };
      newQuery.filter = {
        client_id: selectedClient,
        provider_id: providerId,
        serviceline: serviceline || undefined,
        specialty: specialty || undefined,
        patientType: patientType || PatientType.ALL_PATIENTS,
      };
      setQuery(newQuery);
    },
    [query, dispatch, selectedClient, providerId],
  );
  const loading = !data || StoreUtil.isLoading(flow);
  return (
    <Card className={classes.card}>
      <CardContent>
        <TitleTooltip
          title='Providers Shared Patients Flow'
          tooltip='Insight into relatiionships between providers based on their shared patients.'
          rightLabel={null && 'Based on claims from all ages'}
        />
        <div className={classes.content}>
          <div className={classes.filters}>
            <ComboFilter setFilter={setFilter} />
          </div>
          {loading && <LoadingView />}
          {!loading && (
            <div className={classes.container}>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'end',
                }}
              >
                <span className={classes.chartTitle}>Claims Date Before</span>
                <span
                  className={classes.chartTitle}
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  Claims Date After
                </span>
                {height !== null && (
                  <Chart
                    height={height}
                    style={{ flexGrow: 1, flexBasis: '100%' }}
                    chartType='Sankey'
                    data={rows}
                    className={classes.chart}
                    options={{
                      sankey: {
                        node: {
                          interactivity: true,
                          label: {
                            fontName: 'Open Sans',
                            fontSize: 12,
                            fontWeight: 300,
                          },
                          colors: ColorUtil.COLORS,
                        },
                        link: {
                          interactivity: false,
                          colorMode: 'gradient',
                          colors: ColorUtil.COLORS,
                        },
                      },
                      tooltip: {
                        textStyle: {
                          fontName: 'Open Sans',
                          fontSize: 16,
                          fontWeight: 300,
                        },
                      },
                    }}
                  />
                )}
                <span className={classes.chartNote}>
                  Note: The chart above shows the top 100 providers with shared
                  patients.
                </span>
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

ProviderSankey.propTypes = {
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  providerId: PropTypes.number.isRequired,
};

const styled = withStyles(styles)(ProviderSankey);
export { styled as ProviderSankey };
