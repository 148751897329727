import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  AffiliatedIcon,
  TitleTooltip,
  DynamicTable,
  VisitsSparkline,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import {
  loadAnalytics,
} from 'store/actions/analyticsActions';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class TopReferralsInTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        limit: 50,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.providerId !== prevProps.providerId ||
      this.props.clientId !== prevProps.clientId ||
      this.props.department !== prevProps.department ||
      this.props.referringOrganization !== prevProps.referringOrganization ||
      this.props.app.dateRange !== prevProps.app.dateRange
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, providerId, clientId, department } = this.props;

    dispatch(
      loadAnalytics({
        type: 'TOP_REFERRERS_BY_PROVIDER',
        filter: {
          provider_id: providerId,
          client_id: clientId,
          department,
          referring_organization: this.props.referringOrganization,
        },
        opts: {
          ...this.state.query,
          from: this.props.app.dateRange.start,
          to: this.props.app.dateRange.end,
        },
      })
    );
  }

  render() {
    const { classes, data, providerId } = this.props;

    const noDataMessage = 'No referrals recieved.';
    return (
      <Card className={classes.card}>
        <CardContent>
          <DynamicTable
            ContainerType={React.Fragment}
            header={
              <TitleTooltip
                title={
                  this.props.providerId
                    ? "Provider's Top Referrers"
                    : 'Top Referrers'
                }
                tooltip={
                  this.props.providerId
                    ? 'Top referring providers specific to each affiliated Doctivity provider. The information is organized by providers with the most referrals at the top and designates whether the referring provider is employed or non-employed with the organization.'
                    : null
                }
              />
            }
            idKey='referrer_id'
            columns={[
              {
                label: '',
                sortable: false,
                showFilter: false,
                key: 'referrer.clients.id',
                type: 'bool',
                format: (value) => value ? <AffiliatedIcon /> : '',
                style: { width: 16 },
              },
              {
                label: 'Referring Provider',
                key: 'referrer',
                sortable: false,
                format: (value) =>
                  value
                    ? `${value.last_name}, ${value.first_name} ${value.middle_name}`
                    : '',
              },
              {
                label: 'Count',
                key: 'count',
                style: { width: 100 },
              },
              {
                label: 'Trend',
                key: 'referrer_id',
                format: (id, row) => {
                  console.log(row); 
                  if (id) {
                    return <VisitsSparkline providerId={providerId} referrerId={id} />;
                  }
                  return 'nope';
                },
                style: { width: 60, verticalAlign: 'top' },
                sortable: false,
                showFilter: false,
              },
            ]}
            showFilters={false}
            isso
            query={this.state.query}
            data={data}
            noDataMessage={noDataMessage}
            getRowHref={this.getRowHref}
            onQueryChange={this.onQueryChange}
          />
        </CardContent>
      </Card>
    );
  }

  onQueryChange = (query) => {
    this.setState(
      {
        query,
      },
      () => {
        this.fetchData();
      }
    );
  };

  getRowHref = (row) => {
    return `/providers/${row.referrer_id}/visits`;
  };
}

TopReferralsInTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  data: PropTypes.object,
  providerId: PropTypes.number,
  clientId: PropTypes.number.isRequired,
  department: PropTypes.string,
  referringOrganization: PropTypes.string,
};

function mapStateToProps(state) {
  const {
    analytics,
    app,
  } = state;

  return {
    app,
    data: StoreUtil.get(analytics, 'TOP_REFERRERS_BY_PROVIDER'),
  };
}

const styled = withStyles(styles)(TopReferralsInTable);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as TopReferralsInTable };
