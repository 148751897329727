class PhoneUtil {
  static formatNumber(phoneNumberString) {
    const cleaned = (`${phoneNumberString}`).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})(\d{0,6})$/);
    if (match) {
      const intlCode = match[1] ? '+1 ' : '';
      const extension = match[5] ? ` ext ${match[5]}` : '';
      return [
        intlCode,
        '(',
        match[2],
        ') ',
        match[3],
        '-',
        match[4],
        extension,
      ].join('');
    }
    return null;
  }

  static unformat(phone) {
    if (phone) {
      return `${phone}`.replace(/\D/g, '');
    }
    return '';
  }

  static isValid(phone) {
    const unformattedPhone = PhoneUtil.unformat(phone);
    return unformattedPhone.length >= 10 && unformattedPhone.length <= 16;
  }

  static obfuscate(phone) {
    const cleaned = (`${phone}`).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})(\d{0,6})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', '***', '-', `**${match[4].slice(-2)}`].join('');
    }
    return null;
  }
}

module.exports = { PhoneUtil };
