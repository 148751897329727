class ServiceLineUtil {
  static getSelected(state) {
    if (state.app.serviceline && state.app.serviceline.id) {
      return state.app.serviceline.id;
    }

    return ServiceLineUtil.getDefault();
  }

  // get selected, but if sub service, use top level
  static getTopLevelSelected(state) {
    if (state.app.serviceline && state.app.serviceline.id) {
      if (state.app.serviceline.parent_id) {
        return state.app.serviceline.parent_id;
      }
      return state.app.serviceline.id;
    }

    return ServiceLineUtil.getDefault();
  }

  static findServiceLine(servicelines, id) {
    let serviceline = null;
    if (servicelines) {
      servicelines.some((s) => {
        if (s.id === id) {
          serviceline = s;
          return true;
        }
        if (s.children) {
          s.children.some((ss) => {
            if (ss.id === id) {
              serviceline = ss;
              return true;
            }
            return false;
          });
        }
        return !!serviceline; // make sure child didnt find it
      });
    }
    return serviceline;
  }

  static hasChildren(servicelines, id) {
    if (servicelines.length >= id) {
      return servicelines[id - 1].children?.length > 0;
    }

    return false;
  }

  static getDefault() {
    // in future this could be based on client
    return process.env.SERVICE_LINE_DEFAULT_ID;
  }
}
export { ServiceLineUtil };
export default ServiceLineUtil;
