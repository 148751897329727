import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { StoreUtil, PatientType } from 'doctivity-shared/utils';
import axiosInstance from 'utils/axiosUtil';
import { ClaimsPageQueryParams } from 'params';
import { upsertSavedCriteria } from 'store/actions/savedCriteriaActions';
import { MarketsUtil } from 'utils';
import { useSearchParams } from 'react-router-dom';
import { ObjectUtil } from 'utils';

export default function useReportCriteria(report, queryParamsEnum) {
  const savedCriteriaInterval = 3;
  const [state, setState] = useState({
    loadedFromSaved: false,
    loadedFromQuery: false,
    report,
    affiliation: 'all',
  });
  const [lastTimeCriteriaChanged, setLastTimeCriteriaChanged] = useState(null);
  const dispatch = useDispatch();
  const userId = useSelector((s) => s.user)?.id;
  const clientId = useSelector((s) => s.app.selectedClient);
  const markets = useSelector((state) => MarketsUtil.getMarkets(state));
  const [searchParams, setSearchParams] = useSearchParams();
  const marketsLoaded = StoreUtil.isLoaded(markets);

  const internal = {
    saveCriteria: () => {
      let criteriaArray = [
        {
          field: 'before',
          value: state.before,
        },
        {
          field: 'affiliation',
          value: state.affiliation,
        },
        {
          field: 'groupByServicelines',
          value: state.groupByServicelines,
        },
        {
          field: 'isMarket',
          value: state.isMarket,
        },
        {
          field: 'market',
          value: state.market,
        },
        {
          field: 'tag',
          value: state.tag,
        },
        {
          field: 'report',
          value: report,
        },
        {
          field: 'patient_type',
          value: state.patientType,
        },
        {
          field: 'serviceLine',
          value: state.serviceline,
        },
        {
          field: 'specialties',
          value: state.taxonomies,
        },
        {
          field: 'payors',
          value: state.payors?.map((p) => p.id ?? p),
        },
      ];
      dispatch(
        upsertSavedCriteria({
          criteria_type_id: 1,
          criteria_json: JSON.stringify({
            criteria: criteriaArray,
          }),
          client_id: clientId,
          user_id: userId,
        }),
      );
      setLastTimeCriteriaChanged(null);
    },
    setQueryParams: () => {
      const allParams = Object.values(queryParamsEnum);
      const newParams = {};
      allParams.forEach((param) => {
        const value = ObjectUtil.getPropertyByString(state, param.accessor);
        if (value) {
          if (Array.isArray(value)) {
            newParams[param.name] = value.map((v) => v.id ?? v).join(',');
          } else if (typeof value === 'object') {
            newParams[param.name] = value?.id?.toLowerCase
              ? value.id.toLowerCase()
              : value?.id;
          } else {
            newParams[param.name] = value.toLowerCase
              ? value.toLowerCase()
              : value;
          }
        } else if (value === false || value === 0) {
          newParams[param.name] = value.toLowerCase
            ? value.toLowerCase()
            : value;
        } else {
          delete newParams[param.name];
        }
      });
      setSearchParams(newParams);
    },
    loadFromLastSaved: async () => {
      console.log('load last saved...');
      const savedCriteria = await axiosInstance.get('/UsersSavedCriteria', {
        params: {
          opts: {
            clientId: clientId,
            criteriaTypeId: 1,
          },
        },
      });
      console.log('saved criteria', savedCriteria);
      if (savedCriteria.data && savedCriteria.data.length > 0) {
        const criteria = savedCriteria.data[0].criteria_json;

        let mostRecent = (
          typeof criteria === 'string' ? JSON.parse(criteria) : criteria
        )?.criteria;

        let mostRecentAffiliated = mostRecent.find(
          (c) => c.field === 'affiliation',
        );
        let mostRecentBefore = mostRecent.find((c) => c.field === 'before');
        let mostRecentServiceLine = mostRecent.find(
          (c) => c.field === 'serviceline',
        );
        let mostRecentMarket = mostRecent.find((c) => c.field === 'market');
        let mostRecentTag = mostRecent.find((c) => c.field === 'tag');
        let mostRecentIsMarket = mostRecent.find((c) => c.field === 'isMarket');
        let mostRecentPayors = mostRecent.find((c) => c.field === 'payors');
        let mostRecentGroupBy = mostRecent.find(
          (c) => c.field === 'groubByServicelines',
        );
        let mostRecentTaxonomy = mostRecent.find(
          (c) => c.field === 'specialties' && c.value?.length > 0,
        );
        const payors = (
          Array.isArray(mostRecentPayors?.value)
            ? mostRecentPayors.value
            : (mostRecentPayors?.value ?? '').split(',')
        ).filter((v) => v.length > 0);

        const newState = {
          ...state,
          report,
          loadedFromSaved: true,
          market: mostRecentMarket?.value,
          tag: mostRecentTag?.value,
          isMarket: mostRecentIsMarket?.value ?? true,
          serviceline: mostRecentServiceLine?.value ?? 'all',
          before: mostRecentBefore?.value ?? false,
          affiliation: mostRecentAffiliated?.value ?? 'all',
          groupByServicelines: mostRecentGroupBy?.value ?? false,
          taxonomies: mostRecentTaxonomy?.value ?? [],
          payors,
        };
        console.log('set state from recent search', newState);
        setState(newState);
      } else {
        setState((state) => ({ report, ...state, loadedFromSaved: true }));
      }
    },
  };
  const service = {
    getCriteria: useCallback(() => {
      const queryClaimsDate = searchParams.get(
        ClaimsPageQueryParams.CLAIMS_DATE.name,
      );
      const queryMarket = searchParams.get(ClaimsPageQueryParams.MARKET.name);
      const queryServiceLine = searchParams.get(
        ClaimsPageQueryParams.SERVICELINE.name,
      );
      const queryPatientType = searchParams.get(
        ClaimsPageQueryParams.PATIENT_TYPE.name,
      );

      const queryAffiliation = searchParams.get(
        ClaimsPageQueryParams.AFFILIATION.name,
      );

      const queryPayors = searchParams.get(ClaimsPageQueryParams.PAYORS.name);

      const queryIsMarket = searchParams.get('isMarket');
      const queryGroupBy = searchParams.get('groupByServicelines');
      const queryTag = searchParams.get('tag');

      if (marketsLoaded) {
        let querySelectedMarket = null;
        const marketsData = StoreUtil.getData(markets);
        if (marketsData && marketsData.length > 0) {
          querySelectedMarket = marketsData.find(
            (market) => market.id === parseInt(queryMarket, 10),
          );
        }
        if (!querySelectedMarket && marketsData.length > 0) {
          querySelectedMarket = marketsData[0];
        }

        console.log('get state from query string', searchParams.toString());
        const state = {
          report,
          affiliated_client_id: clientId,
          before: String(queryClaimsDate).toLowerCase() == 'true' ?? false,
          isMarket: queryIsMarket === 'true' || !queryTag,
          market: querySelectedMarket?.id,
          tag: queryTag ? parseInt(queryTag, 10) : undefined,
          serviceline: queryServiceLine,
          patientType: queryPatientType ?? PatientType.ALL_PATIENTS,
          affiliation: queryAffiliation ?? 'all',
          groupByServicelines: queryGroupBy === 'true',
          taxonomies:
            searchParams
              .get(ClaimsPageQueryParams.TAXONOMIES.name)
              ?.split(',')
              ?.filter((t) => t.length > 0) ?? [],
          payors: (
            queryPayors?.split(',').filter((p) => p?.length > 0) ?? []
          ).map((p) => parseInt(p, 10)),
        };
        console.log('state: ', state);
        return state;
      }
      return undefined;
    }, [searchParams, markets]),
    current: state,
    updateCriteria: (newState) => {
      setState((state) => ({ report, ...state, ...newState }));
      setLastTimeCriteriaChanged(new Date().getTime());
    },
  };
  useEffect(() => {
    if (!state.loadedFromSaved && !state.loadedFromQuery) {
      return;
    }
    internal.setQueryParams();
  }, [state]);
  useEffect(() => {
    const interval = setInterval(() => {
      if (
        lastTimeCriteriaChanged !== null &&
        (new Date().getTime() - lastTimeCriteriaChanged) / 1000 >
          savedCriteriaInterval
      ) {
        internal.saveCriteria();
        setLastTimeCriteriaChanged(null);
      }
    }, savedCriteriaInterval * 1000);
    return () => clearInterval(interval);
  }, [state, lastTimeCriteriaChanged]);

  useEffect(() => {
    if (!marketsLoaded || state.loadedFromSaved || state.loadedFromQuery) {
      return;
    }
    if (searchParams.size > 1) {
      service.updateCriteria({
        ...service.getCriteria(),
        loadedFromQuery: true,
      });
    } else {
      setState((old) => ({ ...old, loadedFromSaved: true }));
      internal.loadFromLastSaved();
    }
  }, [marketsLoaded, searchParams, state]);

  return service;
}
