import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Button,
  Typography,
  Icon,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import axios from 'axios';
import axiosInstance from 'utils/axiosUtil';
import { DateUtil, StoreUtil } from 'doctivity-shared/utils';
import {
  DynamicTable,
  LoadingView,
  UploadDialog,
} from 'components';
import {
  queryUploads,
  listUploadTypes,
} from 'store/actions/uploadsActions';

import {
  wakeupServer,
  showConfirmationDialog,
} from 'store/actions/systemActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class UploadPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        where: {
        },
        offset: 0,
        limit: 25,
        order: [
          ['created_at', 'DESC'],
        ],
      },

      isUploadDialogOpen: false,
      uploadDialogState: 'EDIT',
      uploadProgress: 0,

      isErrorDialogOpen: false,
      errorDialogContent: '',
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch } = this.props;
    dispatch(queryUploads({
      ...this.state.query,
      where: {
        ...this.state.query.where,
        client_id: this.props.selectedClient,
      },
    }));
  }

  fetchUploadTypes() {
    const {
      dispatch,
      uploads,
    } = this.props;

    if (StoreUtil.needsLoad(uploads.types)) {
      dispatch(listUploadTypes());
    }
  }

  render() {
    const {
      classes,
      uploads,
    } = this.props;

    const {
      query,
    } = this.state;

    if (uploads.meta && uploads.loading) {
      return <LoadingView />;
    }

    return (
      <>
        <Helmet defer={false}>
          <title>Uploads</title>
        </Helmet>
        <div className={classes.container}>
          <DynamicTable
            header={
              <div className={classes.headerContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
                >
                  Data Uploads
                </Typography>
                <div className={classes.header} />
                <Button
                  color='primary'
                  size='small'
                  onClick={this.openUploadDialog}
                >
                  <Icon className={classes.leftIcon}>backup</Icon>
                  Upload Data
                </Button>
              </div>
            }
            columns={[
              {
                label: 'File Name',
                key: 'original_filename',
              },
              {
                label: 'Type',
                key: 'mapping_version',
              },
              {
                label: 'Date',
                key: 'created_at',
                format: DateUtil.formatDateTimeFromDB,
              },
              {
                label: 'Status',
                key: 'status',
              },
            ]}
            data={uploads.list}
            query={query}
            onRowClick={this.onRowClick}
            onQueryChange={this.onQueryChange}
          />
          <UploadDialog
            open={this.state.isUploadDialogOpen}
            onClose={this.closeUploadDialog}
            onExited={this.handleUploadExited}
            onUpload={this.handleUploadDialogOnUpload}
            uploadState={this.state.uploadDialogState}
            uploadProgress={this.state.uploadProgress}
            types={StoreUtil.getData(uploads.types)}
          />
          <Dialog
            open={this.state.isErrorDialogOpen}
            onClose={this.closeErrorDialog}
            fullWidth
            maxWidth='sm'
          >
            <DialogTitle>Upload Result</DialogTitle>
            <DialogContent>
              <Typography>{this.state.errorDialogContent}</Typography>
            </DialogContent>
          </Dialog>
        </div>
      </>
    );
  }

  handleUploadDialogOnUpload = (selectedType, selectedFile) => {
    this.setState({
      isUploadDialogOpen: false
    });
    const { 
      dispatch, 
      uploads,
      selectedClientName,
    } = this.props;

    const uploadTypes = StoreUtil.getData(uploads.types);
    const selectedUploadType = uploadTypes.filter((type) => { return type.id === selectedType; })[0];
  
    dispatch(
      showConfirmationDialog({
        title: `Uploading ${selectedUploadType.name} to ${selectedClientName}`,
        content:
          'Please double check the client and .csv type. Uploading to the wrong client could cause bad data which cannot be automatically removed from Doctivity.',
        confirmLabel: 'Upload',
        onConfirm: () => {
          this.setState({
            isUploadDialogOpen: true
          }, () => {
            this.startUpload(selectedType, selectedFile);
          });
        },
        onCancel: () => {
          this.setState({
            isUploadDialogOpen: true
          })
        },
      })
    );
  }

  startUpload = (selectedType, selectedFile) => {
    this.setState({
      uploadDialogState: 'LOADING',
    });

    const wakeupInterval = setInterval(
      () => {
        this.props.dispatch(wakeupServer());
      },
      5 * 60 * 1000, // ever 5 min make sure app doesn't logout
    );
    axiosInstance.put('/Upload', {
      clientId: this.props.app.selectedClient,
      fileName: selectedFile.name,
      fileBytes: selectedFile.size,
      mapping_version: selectedType,
    }, {
      params: {
        selected_client_id: this.props.app.selectedClient,
      }
    }).then((response) => {
      const result = response.data;
      axios(result.uploadUrl, {
        method: 'PUT',
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 1000) / progressEvent.total,
          ) / 10;
          this.setState({
            uploadProgress: percentCompleted,
          });
          // console.log(`Upload Progress: ${percentCompleted}`);
        },
        data: selectedFile,
        headers: {
          'content-type': 'text/csv',
          // 'x-amz-acl': 'authenticated-read',
          // 'x-amz-meta-clientid': this.props.app.selectedClient.toString(),
          // 'x-amz-meta-key': result.fileName.substring(0, result.fileName.length - 4),
          // 'x-amz-expires': '900',
        },
      }).then(() => {
        this.setState({
          uploadDialogState: 'SUCCESS',
        }, () => {
          this.fetchData();
        });
        clearInterval(wakeupInterval);
      }).catch(() => {
        this.setState({
          uploadDialogState: 'ERROR',
        });
        clearInterval(wakeupInterval);
      });
    });
  };

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  openUploadDialog = () => {
    this.fetchUploadTypes();
    this.setState({
      isUploadDialogOpen: true,
    });
  };

  closeUploadDialog = () => {
    if (this.state.uploadDialogState !== 'LOADING') {
      this.setState({
        isUploadDialogOpen: false,
      });
    }
  };

  handleUploadExited = () => {
    this.setState({
      uploadDialogState: 'EDIT',
    });
  };

  onRowClick = (row) => {
    if (row.result && row.status === 'ERROR') {
      this.setState({
        isErrorDialogOpen: true,
        errorDialogContent: row.result,
      });
    }
  };

  closeErrorDialog = () => {
    this.setState({
      isErrorDialogOpen: false,
    });
  };
}

UploadPage.propTypes = {
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  uploads: PropTypes.object,
  dispatch: PropTypes.func,
  selectedClient: PropTypes.number,
  selectedClientName: PropTypes.string,
};

function mapStateToProps(state) {
  const {
    app,
    uploads,
    clients,
  } = state;
  const clientsData = StoreUtil.get(clients, StoreUtil.COMMON_LIST);
  const selectedClient = app.selectedClient
    ? app.selectedClient
    : StoreUtil.getData(clientsData)[0].id;
  const selectedClientName = clientsData.data.find((c) => c.id === selectedClient).name;
  return {
    app,
    uploads,
    selectedClient,
    selectedClientName,
  };
}

const styled = withStyles(styles)(UploadPage);
const connected = connect(mapStateToProps)(styled);
export { connected as UploadPage };
