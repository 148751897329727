import { takeEvery, put, select } from 'redux-saga/effects';
import {
 APP_SELECT_CLIENT
} from 'store/actions/appActions';
import {
  upsertActivityLog
} from 'store/actions/activityLogsActions';

const getUser = (state) => state.user

function* logClientSelectActivity(selectedClient) {
  const user = yield select(getUser)
  yield put(upsertActivityLog({
    type: 'VIEWED',
    model: 'Users',
    object_id: selectedClient.payload,
    user_id: user.id,
    description: 'Selected Client: ' + selectedClient.payload
  }));
}

function* clientSelectSaga() {
  yield takeEvery(APP_SELECT_CLIENT, logClientSelectActivity);
}

export default clientSelectSaga;
