import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {
  Button,
  Icon,
  Card,
  CardContent,
  Typography,
  Divider,
  Link,
  Tabs,
  Tab
} from '@mui/material';
import {
  LoadingView,
  OverlineLabel,
  UnderlineData,
  ProviderComments,
  FavoriteIconButton,
  ProfileNotes,
  FixedHeader,
  StickyHeader,
  BreadcrumbRow,
  IconGridItem,
} from 'components';
import { LocationView } from 'locations';
import { PhoneUtil, StoreUtil } from 'doctivity-shared/utils';
import { withRouter, withAppbarHeight } from 'utils';
import { loadContact, upsertContact } from 'store/actions/contactsActions';

import { ContactEditDialog } from './ContactEditDialog';
import { ConfirmAddContactDialog } from 'locations';
import ContactIcon from 'assets/images/contact_icon.svg';

const styles = (theme) => ({
  leftIcon: {
    marginRight: theme.spacing(),
  },
  cardSpacer: {
    marginTop: theme.spacing(3),
  },
  linksContainer: {
    textAlign: 'right',
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    backgroundColor: theme.palette.tertiary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.tertiary.main,
    paddingLeft: theme.spacing(5.5),
    paddingTop: -5,
    paddingBottom: 3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      alignItems: 'start',
    },
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    fontSize: 'clamp(16px, 2.5vw, 24px)',
    paddingRight: 30,
  },
  headerItem: {
    fontSize: 14,
    paddingLeft: 15,
    color: theme.palette.primary.main,
    textDecoration: 'none',
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  headerEmptySpacer: {
    paddingBottom: 21
  },
  favIcon: {
    [theme.breakpoints.down('md')]: {
      marginTop: -6.5
    },
  },
  headerIcon: {
    color: theme.palette.secondary.accent,
    marginRight: theme.spacing(1),
    paddingTop: 6,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0
    },
  },
  tabs: {
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.tertiary.main,
  },
  headerDivider: {},
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    maxWidth: 380,
    borderColor: theme.palette.tertiary.main,
    [theme.breakpoints.down('md')]: {
      borderRight: 'none',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      maxWidth: '100%',
    },
  },
  detailsCard: {
    position: 'relative',
    display: 'flex',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cardDecorationBar: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary.accent,
    width: 13,
    top: 0,
    left: 0,
    height: '100%',
  },
  container: {
    padding: theme.spacing(3),
  },
  spacer: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  linksContainer: {
    position: 'absolute',
    textAlign: 'right',
    top: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      top: 14,
      right: 0,
    },
  },
  contactIcon: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: 110,
  },
  contactDetailsTitle: {
    color: theme.palette.primary.dark,
    flexShrink: 0,
    font: 'normal normal 24px/37px Roboto',
    textAlign: 'center',
  },
  editButton: {
    color: theme.palette.secondary.main,
  },
  detailsFields: {
    paddingLeft: theme.spacing(5),
    paddingRight: 100,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  detailsRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: theme.palette.tertiary.main,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      borderBottom: 'none',
    },
  },
  detailsItem: {
    flexBasis: '50%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: theme.palette.tertiary.main,
    },
  },
  phoneRow: {
    display: 'flex',
    alignItems: 'start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  phoneColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  verticalSpacer: {
    marginBottom: theme.spacing(3),
  },
  company: {
    overflowX: 'hidden', 
    textOverflow: 'ellipsis',
    maxWidth: '15rem',
  }
});

class ContactDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editDialogOpen: false,
      headerHeight: 0,
      confirmChangeLocationData: undefined,
      stickyTab: undefined,
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.contact)) {
      this.fetchContact();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (
      this.props.params.contactId !== prevProps.params.contactId ||
      this.props.app.selectedClient !== prevProps.app.selectedClient
    ) {
      this.fetchContact();
    }

    if (this.props.editing !== prevProps.editing) {
      // if so and it is newr than the last update
      if (StoreUtil.hasSavedSinceLoad(this.props.editing, this.props.contact)) {
        this.fetchContact();
      }
    }
  }

  fetchContact() {
    this.props.dispatch(
      loadContact(this.getContactId(), this.props.app.selectedClient)
    );
  }

  getContactId() {
    return parseInt(this.props.params.contactId, 10);
  }

  render() {
    const {
      classes,
      clientId,
      appbarHeight,
      params
    } = this.props;
    const {
      confirmChangeLocationData,
      headerHeight,
      stickyTab,
    } = this.state;

    const currentTab = params.tab || 'details';

    if (
      !this.props.contact ||
      !StoreUtil.isLoaded(this.props.contact) ||
      this.props.contact.data.id !== this.getContactId() ||
      StoreUtil.isSaving(this.props.editing)
    ) {
      return <LoadingView />;
    }

    const contact = StoreUtil.getData(this.props.contact);

    const contactName = `${contact.last_name}, ${contact.first_name} ${contact.credential ? ` | ${contact.credential}` : ''
      }`;

    const showProfileNotes = contact && contact.client_id === clientId;

    return (
      <div>
        <Helmet defer={false}>
          <title>{contactName}</title>
        </Helmet>
        <FixedHeader>
          <div>
            <BreadcrumbRow
              label='Contacts'
              path='/contacts'
              icon='chevron_left'
            />
          </div>
          <div className={classes.header}>
            <Icon className={classes.headerIcon}>contact_phone</Icon>
            <Typography className={classes.headerTitle}>
              {contactName}
            </Typography>
            <div className={classes.spacer} />
            <div className={classes.favIcon}>
              <FavoriteIconButton objectType='Contacts' objectId={contact.id} />
            </div>
          </div>
          <div className={classes.headerContent}>
            {contact.location && contact.location.organization ? (
              <Link
                className={classes.headerItem}
                href={`/organizations/${contact.location.organization.id}`}
              >
                {contact.location.organization.name}
              </Link>
            ) : (
              <div className={classes.headerEmptySpacer} />
            )}
          </div>

          <Divider
            style={{
              borderColor: stickyTab === false ? '#0000001f' : '#EDEDED',
              transition: 'borderColor 0.1s ease-in',
            }}
          />
        </FixedHeader>
        <StickyHeader
          headerTop={headerHeight + appbarHeight}
          onChangeIsSticky={this.onChangeIsSticky}
        >
          <Tabs
            variant='scrollable'
            scrollButtons='off'
            className={classes.tabs}
            value={currentTab}
            onChange={this.onTabChange}
          >
            <Tab label='Details & Contact' value='details' disableRipple />
            <Tab label='Activity Notes' value='notes' disableRipple />
          </Tabs>
          <Divider className={classes.headerDivider} />
        </StickyHeader>
        <div className={classes.container}>
          {currentTab === 'details' && (
            <div>
              <Card>
                <CardContent className={classes.detailsCard}>
                  <div className={classes.cardDecorationBar} />
                  <div className={classes.titleContainer}>
                    <Typography noWrap className={classes.contactDetailsTitle}>
                      Contact Details
                    </Typography>
                    <img
                      src={ContactIcon}
                      alt='Contact Icon'
                      className={classes.contactIcon}
                    />
                    <OverlineLabel>Title</OverlineLabel>
                    <UnderlineData>{contact.title}</UnderlineData>
                    <OverlineLabel>Owner</OverlineLabel>
                    <UnderlineData noWrap>
                      {contact.owner
                        ? `${contact.owner.last_name}, ${contact.owner.first_name}`
                        : ''}
                    </UnderlineData>
                  </div>

                  <div className={classes.detailsFields}>
                    <div className={classes.detailsRow}>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='place'>
                          <OverlineLabel>LOCATION</OverlineLabel>
                          <LocationView
                            location={contact.location}
                            onChange={this.onLocationSelect}
                          />
                        </IconGridItem>
                      </div>
                       <div className={classes.detailsItem}>
                        <IconGridItem iconName='work'>
                          <OverlineLabel>Company</OverlineLabel>
                          <UnderlineData classes={{ text: classes.company }}>
                            {contact.company}
                          </UnderlineData>
                        </IconGridItem>
                      </div>
                   </div>
                    <div className={classes.detailsRow}>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='smartphone'>
                          <div className={classes.phoneRow}>
                            <div
                              className={classes.phoneColumn}
                              style={{ marginRight: 48 }}
                            >
                              <OverlineLabel>PHONE</OverlineLabel>
                              <UnderlineData>
                                {PhoneUtil.formatNumber(contact.phone)}
                              </UnderlineData>
                            </div>
                            <div className={classes.phoneColumn}>
                              <OverlineLabel>MOBILE</OverlineLabel>
                              <UnderlineData>
                                {PhoneUtil.formatNumber(contact.mobile_phone)}
                              </UnderlineData>
                            </div>
                          </div>
                        </IconGridItem>
                      </div>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='tag'>
                          <OverlineLabel>FAX</OverlineLabel>
                          <UnderlineData>
                            {PhoneUtil.formatNumber(contact.fax)}
                          </UnderlineData>
                        </IconGridItem>
                      </div>
                    </div>
                    <div
                      className={classes.detailsRow}
                      style={{ borderBottom: 'none' }}
                    >
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='email'>
                          <OverlineLabel>EMAIL</OverlineLabel>
                          <UnderlineData>{contact.email}</UnderlineData>
                        </IconGridItem>
                      </div>
                    </div>
                  </div>

                  <div className={classes.linksContainer}>
                    <Button
                      variant='text'
                      color='primary'
                      onClick={this.onOpenEditDialog}
                      className={classes.editButton}
                    >
                      <EditOutlinedIcon className={classes.leftIcon} />
                      Edit
                    </Button>
                  </div>
                </CardContent>
              </Card>
              <div className={classes.verticalSpacer} />
              {showProfileNotes && (
                <div className={classes.cardContainer}>
                  <ProfileNotes model='contact' data={contact} />
                </div>
              )}
            </div>
          )}

          {currentTab === 'notes' && (
            <ProviderComments
              contact={contact}
              clientId={this.props.app.selectedClient}
            />
          )}
          {this.state.editDialogOpen && (
            <ContactEditDialog
              open={this.state.editDialogOpen}
              onSave={this.onSaveContact}
              onClose={this.onCloseEditDialog}
              contactData={contact}
            />
          )}
          {confirmChangeLocationData && (
            <ConfirmAddContactDialog
              open={!!confirmChangeLocationData}
              locationData={confirmChangeLocationData}
              contact={contact}
              onConfirm={this.onConfirmChangeLocation}
              onClose={this.closeConfirmChangeLocationDialog}
            />
          )}
        </div>
      </div>
    );
  }

  onTabChange = (event, newValue) => {
    this.props.router.navigate(`/contacts/${this.getContactId()}/${newValue}`);
  };

  onOpenEditDialog = () => {
    this.setState({
      editDialogOpen: true,
    });
  };

  onCloseEditDialog = () => {
    this.setState({
      editDialogOpen: false,
    });
  };

  onSaveContact = (contact) => {
    contact.client_id = this.props.app.selectedClient;
    this.props.dispatch(upsertContact(contact));
    this.onCloseEditDialog();
  };

  onChangeHeaderHeight = (headerHeight) => {
    this.setState({ headerHeight });
  };

  onChangeIsSticky = (isSticky) => {
    this.setState({ stickyTab: isSticky });
  };

  onLocationSelect = (location) => {
    this.setState({ confirmChangeLocationData: location });
  };

  onConfirmChangeLocation = () => {
    const { confirmChangeLocationData } = this.state;
    const contact = StoreUtil.getData(this.props.contact);
    if (contact && confirmChangeLocationData) {
      contact.location = confirmChangeLocationData;
      contact.location_id = confirmChangeLocationData.id;
      this.props.dispatch(upsertContact(contact));
    }
  };

  closeConfirmChangeLocationDialog = () => {
    this.setState({ confirmChangeLocationData: undefined });
  };
}

ContactDetailPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  appbarHeight: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  user: PropTypes.object,
  contact: PropTypes.object,
  editing: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    user: state.user,
    app: state.app,
    clientId: state.app.selectedClient,
    contact: StoreUtil.get(
      state.contacts,
      StoreUtil.COMMON_ITEM,
      props.params.contactId
    ),
    editing: StoreUtil.get(state.contacts, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(withAppbarHeight(ContactDetailPage));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ContactDetailPage };
