import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import { Icon } from '@mui/material';

const styles = () => ({
  copyToClipboard: {
    position: 'relative',
    cursor: 'pointer',
  },
  clipboardIcon: {
    position: 'absolute',
    top: '-1rem',
    right: '-2rem',
    fontSize: '2rem',
    animation: 'fadeInOut 4s',
  },
  '@keyframes fadeInOut': {
    '0%': { opacity: 0 },
    '25%': { opacity: 1 },
    '75%': { opacity: 1 },
    '100%': { opacity: 0 },
  },
});

const ClipboardIcon = () => <span><Icon>content_copy</Icon></span>;

const CopyToClipboard = (props) => {
  const [showIcon, setShowIcon] = useState(false);
  const { classes, children } = props;
  const handleClick = async () => {
    try {
      const text = React.Children.toArray(children).reduce(
        (acc, child) => acc + child,
        '',
      );
      await navigator.clipboard.writeText(text);
      setShowIcon(true);
      setTimeout(() => setShowIcon(false), 2000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  return (
    <div className={classes.copyToClipboard} onClick={handleClick}>
      {children}
      {showIcon && (
        <div className={classes.clipboardIcon}>
          <ClipboardIcon />
        </div>
      )}
    </div>
  );
};
CopyToClipboard.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const styled = withStyles(styles)(CopyToClipboard);
export { styled as ClickToCopy };
