export const API_CLIENTS_TABLE = 'API_CLIENTS_TABLE';
export const API_CLIENTS_LIST = 'API_CLIENTS_LIST';
export const API_CLIENTS_UPSERT = 'API_CLIENTS_UPSERT';

export const queryClients = (options, type = API_CLIENTS_TABLE) => ({
  type,
  request: {
    url: '/Clients',
    method: 'GET',
    params: { opts: options },
  },
});

export const listClients = () => (queryClients({
  where: {
    is_active: true,
  },
  order: [
    ['name', 'ASC'],
  ],
}, API_CLIENTS_LIST));

export const upsertClient = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  return {
    type: API_CLIENTS_UPSERT,
    id,
    request: {
      url: '/Clients',
      method: 'PUT',
      params: { opts: options },
      data,
    },
    nextAction: listClients(),
  };
};
