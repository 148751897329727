import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { LoadingView, TitleTooltip, PatientTypeSelect } from 'components';

const styles = (theme) => ({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
  row: {
    paddingRight: theme.spacing(2),
  },
  spacer: {
    height: 56,
  },
  label: {
    fontSize: 12,
    color: '#9e9e9e',
    lineHeight: 1,
    marginTop: '0.3em',
  },
  value: {
    fontSize: 18,
    marginBottom: 10,
  },
  rankBar: {
    marginTop: 2,
    marginBottom: 10,
    backgroundColor: '#2E93fA',
    fontSize: 16,
    color: '#ffffff',
    padding: 3,
    paddingLeft: 8,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    borderRadius: 2,
  },
  rankTotal: {
    marginLeft: 4,
    paddingTop: 8,
    fontSize: 11,
    color: '#ffffff',
    opacity: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  filterServiceline: {
    width: 240,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  filterLocation: {
    width: 120,
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
});

class ClaimsPatients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceline: '',
      locationType: '',
      servicelines: [],
      serviceLinesByName: {},
      data: [],
    };
  }

  componentDidMount() {
    if (this.props.claims) this.updateCharts();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.claims !== this.props.claims && this.props.claims) {
      this.updateCharts()
    }
  }
  updateCharts() {
      let { serviceline, locationType } = this.state;
      // find the best one
      let best = null;
      this.props.claims?.forEach((d) => {
        // filter out sub servicelines
        const isSameProvider = d.provider_id === this.props.provider?.id;
        if (
          (best == null || best.total_patients < d.total_patients) &&
          !d.serviceline.parent_id &&
          isSameProvider
        ) {
          best = d;
        }
      });
      if (!serviceline && best) {
        serviceline = best.serviceline.id;
      }
      if (!locationType && best) {
        locationType = best.location_type;
      }
      const servicelinesByName = {};
      let data = {};
      this.props.claims?.forEach((d) => {
        if (!d.serviceline.parent_id) {
          // filter out sub servicelines
          servicelinesByName[d.serviceline.name] = d.serviceline.id;
          if (
            serviceline === d.serviceline.id &&
            locationType === d.location_type
          ) {
            data = d;
          }
        }
      });

      const servicelines = [];
      Object.keys(servicelinesByName)
        .sort()
        .forEach((name) => {
          servicelines.push({
            id: servicelinesByName[name],
            name,
          });
        });
      this.setState({ serviceline, locationType, servicelines, data });
  }

  render() {
    const { classes, claims } = this.props;
    const { serviceline, locationType, data, servicelines } = this.state;
    let total = data.total_patients;
    if (this.props.patientType === 'CAPTURED_CLAIMS') {
      total = data.total_claims;
    }
    return (
      <Card>
        <CardContent>
          <div className={classes.header}>
            <TitleTooltip
              title='Patients'
              tooltip='Measures of a specific providers claims data.'
            />
          </div>
          <FormControl
            variant='outlined'
            className={classes.filterServiceline}
            key='filter-serviceline'
          >
            <InputLabel>Service Line</InputLabel>
            <Select
              value={serviceline}
              onChange={this.onServicelineChange}
              label='Service Line'
            >
              {servicelines.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {this.props.patientType !== 'CAPTURED_CLAIMS' && (
            <FormControl
              variant='outlined'
              className={classes.filterLocation}
              key='filter-location'
            >
              <InputLabel>Setting</InputLabel>
              <Select
                value={locationType}
                onChange={this.onLocationChange}
                label='Setting'
              >
                <MenuItem value={1}>
                  <em>ASC</em>
                </MenuItem>
                <MenuItem value={2}>
                  <em>OP</em>
                </MenuItem>
                <MenuItem value={3}>
                  <em>Office</em>
                </MenuItem>
                <MenuItem value={4}>
                  <em>IP</em>
                </MenuItem>
                <MenuItem value={5}>
                  <em>Lab</em>
                </MenuItem>
              </Select>
            </FormControl>
          )}

          <PatientTypeSelect />
          {!claims && <LoadingView />}
          <div className={classes.row}>
            <Typography className={classes.label}>
              {this.props.patientType == 'CAPTURED_CLAIMS'
                ? 'Captured Claims'
                : 'Total Patients'}
            </Typography>
            <Typography className={classes.value}>
              {total?.toLocaleString() || 0}
            </Typography>
          </div>
          {this.props.patientType !== 'CAPTURED_CLAIMS' && (
            <>
              <div className={classes.row}>
                <Typography className={classes.label}>National Rank</Typography>
                <div
                  className={classes.rankBar}
                  style={{ width: `${data.national_rank || 1}0%` }}
                >
                  {data.national_rank || 1}
                  <Typography className={classes.rankTotal}>of 10</Typography>
                </div>
              </div>
              <div className={classes.spacer} />
            </>
          )}
          {this.props.patientType === 'CAPTURED_CLAIMS' && (
            <>
              <div className={classes.row}>&nbsp;</div>
              <div className={classes.row}>&nbsp;</div>
              <div className={classes.row}>&nbsp;</div>
              <div className={classes.row}>&nbsp;</div>
              <div className={classes.row}>&nbsp;</div>
            </>
          )}
        </CardContent>
      </Card>
    );
  }

  onServicelineChange = (event) => {
    this.setState({
      serviceline: parseInt(event.target.value, 10),
    }, this.updateCharts);
  };

  onLocationChange = (event) => {
    this.setState({
      locationType: parseInt(event.target.value, 10),
    }, this.updateCharts);
  };
}

ClaimsPatients.propTypes = {
  classes: PropTypes.object,
  claims: PropTypes.array.isRequired,
  patientType: PropTypes.string,
  provider: PropTypes.object.isRequired,
};

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state) {
  return {
    patientType: state.app.patientType,
  };
}

const styled = withStyles(styles)(ClaimsPatients);
const connected = connect(mapStateToProps)(styled);
export { connected as ClaimsPatients };
