import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import { CodesTable } from 'codes';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  card: {},
});

class CodesPage extends React.Component {
  render() {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CodesTable
              provider={this.props.provider}
              organization={this.props.organization}
              diagnosis
            />
          </Grid>
          <Grid item xs={12}>
            <CodesTable
              provider={this.props.provider}
              organization={this.props.organization}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

CodesPage.propTypes = {
  provider: PropTypes.object,
  organization: PropTypes.object,
};

const styled = withStyles(styles)(CodesPage);
export { styled as CodesPage };
