export const API_WAKEUP = 'API_WAKEUP';
export const APP_CONFIRMATION_DIALOG = 'APP_CONFIRMATION_DIALOG';
export const APP_SHOW_DIALOG = 'APP_SHOW_DIALOG';
export const APP_HIDE_DIALOG = 'APP_HIDE_DIALOG';

export const wakeupServer = () => ({
  type: API_WAKEUP,
  request: {
    url: '/wakeup',
    method: 'GET',
  },
});

export const showConfirmationDialog = (config) => ({
  type: APP_CONFIRMATION_DIALOG,
  payload: config,
});

export const hideConfirmationDialog = () => ({
  type: APP_CONFIRMATION_DIALOG,
  payload: null, // null hides it
});

export const showDialog = (dialog) => ({
  type: APP_SHOW_DIALOG,
  payload: dialog,
});

export const hideDialog = (id) => ({
  type: APP_HIDE_DIALOG,
  payload: id, // null hides it
});
