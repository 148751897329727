import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

import { StoreUtil } from 'doctivity-shared/utils';

import { LoadingView } from 'components';

import { queryProviders } from 'store/actions/providersActions';
import { queryContacts } from 'store/actions/contactsActions';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {},
  subtitle: {
    fontSize: 14,
  },
  locationStack: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  locationStackTitle: {
    fontWeight: 600,
    fontSize: 18,
  },
  spacer: {
    marginBottom: theme.spacing(2),
  },
});

class ConfirmAddLocationDialog extends Component {
  constructor(props) {
    super(props);

    const { locationData } = props;

    this.state = {
      query: {
        where: {
          location_id: locationData.id,
        },
        // All we need is the count property of findAndCount all,
        // so include the bare minimum to get that
        limit: 1,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { dispatch } = this.props;

    dispatch(queryProviders(this.state.query));
    dispatch(queryContacts(this.state.query));
  }

  render() {
    const {
      classes,
      contactCount,
      providerCount,
      locationData,
      organization,
      open,
      onClose,
    } = this.props;

    if (
      contactCount === undefined ||
      contactCount === null ||
      providerCount === undefined ||
      providerCount === null
    ) {
      return <LoadingView />;
    }

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby='simple-dialog-title'
        open={open}
      >
        <div className={classes.container}>
          <DialogTitle className={classes.title}>
            Confirm New Location For {organization.name}
          </DialogTitle>
          <DialogContent>
            <div className={classes.content}>
              <Typography color='primary' className={classes.subtitle}>
                Adding this location will move the following to this
                organization:
              </Typography>
              <div className={classes.spacer} />
              <div className={classes.locationStack}>
                <div>
                  <Typography className={classes.locationStackTitle}>
                    New Location
                  </Typography>
                  <Typography color='primary'>{organization.name}</Typography>
                  {/* <Typography color='primary'>{locationData.name}</Typography> */}
                  <Typography>{locationData.address1},</Typography>
                  {locationData.address2 && (
                    <Typography>{locationData.address2},</Typography>
                  )}
                  <Typography>{`${locationData.city}, ${locationData.state} ${locationData.postal_code}`}</Typography>
                </div>
                <div>
                  <Typography className={classes.locationStackTitle}>
                    Providers
                  </Typography>
                  <Typography>{providerCount}</Typography>
                </div>
                <div>
                  <Typography className={classes.locationStackTitle}>
                    Contacts
                  </Typography>
                  <Typography>{contactCount}</Typography>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={onClose}>
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={this.onConfirm}
            >
              Confirm
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  onConfirm = () => {
    const { locationData, onConfirm, onClose } = this.props;

    if (onConfirm) {
      onConfirm(locationData);
    }
    onClose();
  };
}

ConfirmAddLocationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  locationData: PropTypes.object.isRequired,
  organization: PropTypes.object.isRequired,
  providerCount: PropTypes.number,
  contactCount: PropTypes.number,
};

function mapStateToProps(state) {
  const providers = StoreUtil.get(state.providers, StoreUtil.COMMON_TABLE);
  const contacts = StoreUtil.get(state.contacts, StoreUtil.COMMON_TABLE);
  return {
    // TODO: create StoreUtil helper for getting count of table
    providerCount: providers.data?.count,
    contactCount: contacts.data?.count,
  };
}

const styled = withStyles(styles)(ConfirmAddLocationDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as ConfirmAddLocationDialog };
