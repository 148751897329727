import React from 'react';
import { connect } from 'react-redux';
import axiosInstance from 'utils/axiosUtil';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { showLoginInfoMessage } from 'store/actions/loginActions';
import { withRouter } from 'utils';

const styles = ({
  form: {
    width: '100%',
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width: '100%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    marginTop: 30,
  },
  buttonContainer: {
    marginTop: '10px',
  },
  helpContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    marginTop: 30,
    textAlign: 'center',
  },
});

class RecoveryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: '',
      },
      error: null,
      isRegistering: false,
    };

    this.onChange = this.onChange.bind(this);
  }

  render() {
    const {
      // user,
      classes,
    } = this.props;

    const {
      form,
    } = this.state;

    return (
      <Card className={classes.container}>
        <form className={classes.form} onSubmit={this.onSubmit}>
          <CardHeader title='Forgot Password' />
          <CardContent>
            {this.state.error ? <Typography color='error'>{this.state.error}</Typography> : null}
            <TextField
              id='email'
              autoFocus
              name='email'
              label='Email Address'
              placeholder='Enter email address'
              onChange={this.onChange}
              value={form.email}
              className={classes.textField}
              margin='normal'
              fullWidth
            />
            <div className={classes.buttonContainer}>
              <Button
                id='login'
                className={classes.button}
                color='primary'
                margin='normal'
                variant='contained'
                disabled={this.registerButtonDisabled()}
                onClick={this.onSubmit}
              >
                Submit
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    );
  }

  registerButtonDisabled() {
    const {
      form,
      isRegistering,
    } = this.state;

    if (isRegistering) {
      return true;
    }

    if (!form.email) {
      return true;
    }

    return false;
  }

  onChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [e.target.name]: e.target.value,
      },
    }));
  };

  onSubmit = (event) => {
    const {
      email,
    } = this.state.form;

    if (event) {
      event.preventDefault();
    }

    if (email) {
      axiosInstance.put('/auth/recovery', {
        email,
      }).then(() => {
        this.props.router.navigate('/login');
        this.props.dispatch(showLoginInfoMessage('We have sent an email to your email address containing instructions to reset your password.'));
      }).catch((err) => {
        this.setState({
          error: err.response.data.error.message,
        });
      });
    }
  };
}

RecoveryPage.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
};

const styledPage = withStyles(styles)(RecoveryPage);
const connectedPage = connect()(styledPage);
const routed = withRouter(connectedPage);
export { routed as RecoveryPage };
