import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Sparklines, SparklinesCurve } from 'react-sparklines';
import { StoreUtil } from 'doctivity-shared/utils';
import { loadAnalytics } from 'store/actions/analyticsActions';

const styles = () => ({
  text: {
    minHeight: '1em',
    marginBottom: '0.5em',
  },
});

function dateStr(date) {
  if (date) {
    if (typeof date === 'string' || date instanceof String) {
      return date.substring(0, 7);
    }
    return date.format('YYYY-MM');
  }
  return '';
}
function getStoreId(providerId, referrerId, app) {
  // eslint-disable-next-line prefer-template
  return (
    'visits_sparkline(' +
    app.selectedClient +
    '-' +
    providerId + '-' + referrerId +
    '-' +
    dateStr(app.dateRange.start) +
    '-' +
    dateStr(app.dateRange.end) +
    ')'
  );
}

class VisitsSparkline extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.app.selectedClient !== prevProps.app.selectedClient ||
      this.props.app.dateRange !== prevProps.app.dateRange ||
      this.props.providerId !== prevProps.providerId ||
      this.props.referrerId !== prevProps.referrerId
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { data, app, providerId, referrerId, dispatch } = this.props;
    let isReferrer = !!referrerId;

    if (StoreUtil.needsLoadLongCache(data)) {
      dispatch(
        loadAnalytics({
          type: 'VISIT_COUNT_BY_MONTH',
          store_id: getStoreId(providerId, referrerId, app),
          filter: {
            referrer_id: referrerId,
            provider_id: providerId,
            client_id: app.selectedClient,
            from: app.dateRange.start,
            to: app.dateRange.end,
          },
        }),
      );
    }
  }

  render() {
    const { data } = this.props;

    let color = '#66666688';

    if (!data || !StoreUtil.isLoaded(data)) {
      return '';
    }
    const counts = data.data.map((row) => row.count);

    if (counts.length >= 6) {
      const start = (counts[0] + counts[1] + counts[2]) / 3;
      const end =
        (counts[counts.length - 3] +
          counts[counts.length - 2] +
          counts[counts.length - 1]) /
        3;

      const diff = end / start;
      if (diff > 1.1) {
        color = '#00A887cc';
      } else if (diff < 0.9) {
        color = '#ff434399';
      }
    }
    return (
      <Sparklines data={counts} width={60} height={18}>
        <SparklinesCurve
          color={color}
          style={{ fill: 'none', strokeWidth: 2 }}
          divisor={0.5}
        />
      </Sparklines>
    );
  }
}

VisitsSparkline.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  data: PropTypes.object,
  providerId: PropTypes.number,
  referrerId: PropTypes.number,
};

function mapStateToProps(state, props) {
  const { app, analytics } = state;

  return {
    app,
    data: StoreUtil.get(analytics, getStoreId(props.providerId, props.referrerId, app)),
  };
}

const styled = withStyles(styles)(VisitsSparkline);
const connected = connect(mapStateToProps)(styled);
export { connected as VisitsSparkline };
