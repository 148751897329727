import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Icon,
  Typography,
  Tab,
  Tabs,
  Chip,
} from '@mui/material';
import PropTypes from 'prop-types';

import { ActivityLogsUtil } from 'utils';
import { StoreUtil } from 'doctivity-shared/utils';
import { DynamicTable } from 'components';
import { queryContacts, upsertContact } from 'store/actions/contactsActions';
import { ContactEditDialog } from './ContactEditDialog';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { activityLogsByUserId } from 'store/actions/activityLogsActions';

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Last Name',
    key: 'last_name',
  },
  {
    label: 'First Name',
    key: 'first_name',
  },
  {
    label: 'Company',
    key: 'company',
  }
];

const styles = (theme) => ({
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  instructions: {
    fontSize: 14,
    fontWeight: 300,
    flex: 1,
    color: theme.palette.primary.main,
  },
  table: {
    minHeight: 420,
  },
  tabs: {
    marginBottom: theme.spacing(2),
  },
  selectedContacts: {
    minHeight: 32 + parseInt(theme.spacing()),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    '& > *': {
      marginRight: theme.spacing(),
      marginBottom: theme.spacing(),
    },
  },
});

class FindContactDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addDialogOpen: false,
      contactCategory: props.onMultiSelect ? 'suggested' : 'all',
      query: {
        attributes: ['id', 'first_name', 'last_name', 'email', 'phone', 'company'],
        include: [
          {
            association: 'location',
            attributes: [
              'id',
              'name',
              'address1',
              'address2',
              'city',
              'state',
              'postal_code',
              'organization_id',
            ],
          },
        ],
        where: {},
        limit: 10,
        offset: 0,
        order: [['id', 'ASC']],
      },
      selectedSmartContacts: props.contact ? [props.contact] : [],
    };
  }

  componentDidMount() {
    const { contacts } = this.props;

    if (StoreUtil.needsLoadNoCache(contacts)) {
      this.fetchData();
    }
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };

  fetchData() {
    const {
      dispatch,
      clientId,
      userId,
      selectedLocationId,
      selectedProviderId,
    } = this.props;
    const { query, contactCategory, selectedSmartContacts } = this.state;

    switch (contactCategory) {
      case 'suggested':
        dispatch(
          loadAnalytics({
            type: 'CONTACTS_SMART_SUGGESTIONS',
            filter: {
              client_id: clientId,
              user_id: userId,
              selectedProviderId,
              selectedContactId:
                selectedSmartContacts.length > 0
                  ? selectedSmartContacts[0].id
                  : undefined,
              selectedLocationId,
            },
            opts: query,
          })
        );
        break;
      case 'recent':
        dispatch(
          activityLogsByUserId(userId, 'Contacts', clientId, {
            ...query,
            order: undefined,
          })
        );
      default:
        dispatch(queryContacts({
          ...query,
          where: {
            ...query.where,
            client_id: clientId,
          }
        }));
        break;
    }
  }

  render() {
    const {
      classes,
      contacts,
      contact,
      open,
      recents,
      smartContacts,
      onMultiSelect,
    } = this.props;

    const { query, contactCategory, selectedSmartContacts } = this.state;

    let mobileColumns = columns;
    if (window.innerWidth < 900) {
      // only 2 columns for mobile
      mobileColumns = [columns[0], columns[1]];
    }

    return (
      <Dialog
        onClose={this.onCancel}
        aria-labelledby='simple-dialog-title'
        open={open}
        fullWidth
        maxWidth='lg'
        scroll='paper'
      >
        <DialogTitle>Find a Contact</DialogTitle>
        <DialogContent dividers>
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            value={contactCategory}
            onChange={this.onTabChange}
          >
            {onMultiSelect && (
              <Tab label='Suggested' value='suggested' disableRipple />
            )}
            <Tab label='Recent' value='recent' disableRipple />
            <Tab label='All' value='all' disableRipple />
          </Tabs>
          {onMultiSelect && (
            <div className={classes.selectedContacts}>
              {selectedSmartContacts.map((p) => (
                <Chip
                  label={`${p.last_name}, ${p.first_name}`}
                  onDelete={
                    contact && p.id === contact.id
                      ? undefined
                      : () => {
                          this.onDeleteContact(p);
                        }
                  }
                  variant='outlined'
                  key={p.id}
                />
              ))}
            </div>
          )}
          <div className={classes.filters}>
            <Typography className={classes.instructions}>
              Filter and select the contact
            </Typography>
            <Button color='primary' size='small' onClick={this.onOpenAddDialog}>
              <Icon className={classes.leftIcon}>add</Icon>
              Add Contact
            </Button>
          </div>
          <div className={classes.table}>
            {contactCategory === 'suggested' && (
              <>
                <DynamicTable
                  columns={mobileColumns}
                  query={query}
                  data={smartContacts}
                  multiSelectedRows={selectedSmartContacts}
                  noDataMessage='No contacts found.'
                  onQueryChange={this.onQueryChange}
                  onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                  onMultiSelect={
                    onMultiSelect ? this.onSmartSuggestionSelected : undefined
                  }
                  ContainerType={React.Fragment}
                />
              </>
            )}
            {contactCategory === 'recent' && (
              <DynamicTable
                columns={mobileColumns}
                query={query}
                data={recents}
                multiSelectedRows={selectedSmartContacts}
                noDataMessage='No contacts found.'
                onQueryChange={this.onQueryChange}
                onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                onMultiSelect={
                  onMultiSelect ? this.onSmartSuggestionSelected : undefined
                }
                ContainerType={React.Fragment}
                className={classes.table}
              />
            )}
            {contactCategory === 'all' && (
              <DynamicTable
                columns={mobileColumns}
                query={query}
                data={contacts}
                multiSelectedRows={selectedSmartContacts}
                noDataMessage='No contacts found.'
                onQueryChange={this.onQueryChange}
                onRowClick={!onMultiSelect ? this.onRowClick : undefined}
                onMultiSelect={
                  onMultiSelect ? this.onSmartSuggestionSelected : undefined
                }
                ContainerType={React.Fragment}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCancel} color='primary'>
            Cancel
          </Button>
          {onMultiSelect && (
            <Button
              onClick={this.onSaveMultiSelect}
              variant='contained'
              color='primary'
            >
              Save
            </Button>
          )}
        </DialogActions>
        <ContactEditDialog
          open={this.state.addDialogOpen}
          onSave={this.onSaveContact}
          onClose={this.onCloseAddDialog}
          submitText='Save and Use'
        />
      </Dialog>
    );
  }

  onCancel = () => {
    const { onSelect, onMultiSelect } = this.props;

    if (onSelect) {
      onSelect(null);
    } else if (onMultiSelect) {
      onMultiSelect(null);
    }
  };

  onRowClick = (row) => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect(row);
    }
  };

  onOpenAddDialog = () => {
    this.setState({
      addDialogOpen: true,
    });
  };

  onCloseAddDialog = () => {
    this.setState({
      addDialogOpen: false,
    });
  };

  onSaveContact = (contact) => {
    contact.client_id = this.props.app.selectedClient;
    this.props.dispatch(upsertContact(contact));
    this.onCloseAddDialog();
    this.onCancel();
  };

  onTabChange = (_event, newValue) => {
    const { query } = this.state;

    this.setState(
      { contactCategory: newValue, query: { ...query, where: {} } },
      this.fetchData.bind(this)
    );
  };

  onSaveMultiSelect = () => {
    const { onMultiSelect } = this.props;
    const { selectedSmartContacts } = this.state;

    if (onMultiSelect) {
      onMultiSelect(selectedSmartContacts);
    }
  };

  onSmartSuggestionSelected = (selectedContacts) => {
    const shouldFetch = selectedContacts.length <= 1;
    this.setState(
      { selectedSmartContacts: selectedContacts },
      () => shouldFetch && this.fetchData()
    );
  };

  onDeleteContact = (provider) => {
    const { selectedSmartContacts } = this.state;

    const newSelectedContacts = selectedSmartContacts.filter(
      (selected) => selected.id !== provider.id
    );

    this.setState(
      {
        selectedSmartContacts: newSelectedContacts,
      },
      () => newSelectedContacts.length === 0 && this.fetchData()
    );
  };
}

FindContactDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  contacts: PropTypes.object.isRequired,
  recents: PropTypes.object.isRequired,
  smartContacts: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  onSelect: PropTypes.func,
  open: PropTypes.bool.isRequired,
  app: PropTypes.object,
  created: PropTypes.object,
  contact: PropTypes.object,
  onMultiSelect: PropTypes.func,
  selectedProviderId: PropTypes.number,
  selectedLocationId: PropTypes.number,
};

function mapStateToProps(state) {
  const { app, contacts, activityLogs, analytics } = state;

  const userId = state.user ? state.user.id : null;

  return {
    app,
    userId,
    clientId: app.selectedClient,
    contacts: StoreUtil.get(contacts, StoreUtil.COMMON_TABLE),
    smartContacts: StoreUtil.get(analytics, 'CONTACTS_SMART_SUGGESTIONS'),
    recents: ActivityLogsUtil.getContactsFromLogs(activityLogs, userId),
  };
}

const styled = withStyles(styles)(FindContactDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as FindContactDialog };
