import React from 'react';
import { Chart } from 'react-google-charts';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Typography,
  Button,
  Icon,
} from '@mui/material';
import {
  StoreUtil,
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { LoadingView, VisitsTabs, DateRangeSelector } from 'components';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { loadProvider } from 'store/actions/providersActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    fontSize: 24,
    paddingRight: 30,
  },
  instructionsRow: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(3),
  },
  instructions: {
    fontSize: 14,
    fontWeight: 300,
  },
  clickExample: {
    backgroundColor: '#8BAAF3',
    width: 6,
    height: 16,
    marginTop: 2,
    marginLeft: 4,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  spacer: {
    flex: 1,
  },
  dateRangeSelectorContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    marginTop: theme.spacing(2),
  },
});

class ReferralFlowPage extends React.Component {
  componentDidMount() {
    const {
      data,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(data)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (this.props.app.selectedClient !== prevProps.app.selectedClient
      || this.props.app.dateRange !== prevProps.app.dateRange
      || this.props.params.providerId !== prevProps.params.providerId
      || this.props.params.direction !== prevProps.params.direction) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      dispatch,
      app,
    } = this.props;

    let idField = 'provider_id';
    if (this.props.params.direction === 'out') {
      idField = 'referrer_id';
    }
    const providerId = this.getProviderId();
    dispatch(loadAnalytics({
      type: 'FLOW',
      filter: {
        client_id: app.selectedClient,
        from: app.dateRange.start,
        to: app.dateRange.end,
        [idField]: providerId,
      },
      opts: {
        limit: 100,
        offset: 0,
      },
    }));
    if (providerId) {
      dispatch(loadProvider(providerId));
    }
  }

  getProviderId() {
    if (this.props.params.providerId
      && this.props.params.providerId.length > 0) {
      return parseInt(this.props.params.providerId, 10);
    }
    return undefined;
  }

  render() {
    const {
      classes,
      data,
    } = this.props;

    if (data && StoreUtil.isLoading(data)) {
      return (<LoadingView />);
    }

    let title = 'Top Referrals';
    const providerId = this.getProviderId();
    if (providerId) {
      if (!StoreUtil.isLoaded(this.props.provider)) {
        title = '...';
      } else {
        const name = `${this.props.provider.data.last_name}, ${this.props.provider.data.first_name}`;
        if (this.props.params.direction === 'out') {
          title = `Refferred BY ${name}`;
        } else {
          title = `Refferred TO ${name}`;
        }
      }
    }

    const rows = [];
    rows.push([
      { label: 'Referrer', type: 'string' },
      { label: 'Provider', type: 'string' },
      { label: 'Referrals', type: 'number' },
    ]);
    if (data && data.data && data.data.length > 0) {
      data.data.forEach((row) => {
        rows.push([
          { v: `${row.referrer.last_name}, ${row.referrer.first_name}`, f: row.referrer.id },
          { v: `${row.provider.last_name}, ${row.provider.first_name} `, f: row.provider.id },
          row.count,
        ]);
      });
    }
    console.log('ROWS: ', rows);
    const height = Math.max(80, rows.length * 15);
    return (
      <>
        <VisitsTabs tab='flow' />
        <div className={classes.dateRangeSelectorContainer}>
          <DateRangeSelector />
        </div>
        <div className={classes.container}>
          {providerId && (
            <Button
              color='primary'
              size='small'
              onClick={() => {
                this.props.router.navigate('/flow/');
              }}
            >
              <Icon className={classes.leftIcon} size='small'>
                arrow_back
              </Icon>
              Top Referrers
            </Button>
          )}
          <div className={classes.header}>
            <Typography className={classes.headerTitle}>{title}</Typography>
            {providerId && (
              <Button
                color='primary'
                size='small'
                onClick={() => {
                  this.props.router.navigate(`/providers/${providerId}`);
                }}
              >
                Provider Details
                <Icon className={classes.rightIcon} size='small'>
                  arrow_forward
                </Icon>
              </Button>
            )}
          </div>
          <div className={classes.instructionsRow}>
            <Typography className={classes.instructions}>
              To navigate the flow of referrals, click on the small bars to left
              or right of provider names.
            </Typography>
            <div className={classes.clickExample} />
          </div>
          <Chart
            height={`${height}px`}
            chartType='Sankey'
            loader={<div>Loading Chart</div>}
            data={rows}
            options={{
              sankey: {
                node: {
                  interactivity: true,
                  label: {
                    fontName: 'Open Sans',
                    fontSize: 12,
                    fontWeight: 300,
                    color: '#333333',
                  },
                },
                link: {
                  colorMode: 'gradient',
                  interactivity: false,
                },
              },
              tooltip: {
                textStyle: {
                  fontName: 'Open Sans',
                  fontSize: 16,
                  fontWeight: 300,
                  color: '#333333',
                },
                showColorCode: true,
              },
            }}
            rootProps={{ 'data-testid': '1' }}
            chartEvents={[
              { eventName: 'select', callback: this.onChartSelect },
            ]}
          />
        </div>
      </>
    );
  }

  onChartSelect = ({ chartWrapper }) => {
    const chart = chartWrapper.getChart();
    const selection = chart.getSelection();

    if (selection && selection.length > 0) {
      const name = selection[0].name;
      const isReferrer = !name.endsWith(' ');
      const data = chartWrapper.getDataTable();
      if (data && data.fg && data.fg.length > 0) {
        const i = isReferrer ? 0 : 1;
        let id = 0;
        data.fg.some((row) => {
          if (row.c[i].v === name) {
            id = row.c[i].f;
            return true; // break some
          }
          return false;
        });
        if (id) {
          if (isReferrer) {
            this.props.router.navigate(`/flow/out/${id}`);
          } else {
            this.props.router.navigate(`/flow/in/${id}`);
          }
        }
      }
    }
  };
}

ReferralFlowPage.propTypes = {
  router: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  provider: PropTypes.object,
};

function mapStateToProps(state, props) {
  const {
    app,
    analytics,
  } = state;

  return {
    app,
    data: StoreUtil.get(analytics, 'FLOW'),
    provider: StoreUtil.get(
      state.provider,
      StoreUtil.COMMON_ITEM,
      props.params.providerId,
    ),
  };
}

const styled = withStyles(styles)(ReferralFlowPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ReferralFlowPage };
