import React from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  TitleTooltip,
  LoadingView,
  ServiceLineSelect,
  PatientTypeSelect,
  SmallIconTextButton,
} from 'components';

import { StoreUtil, PatientTypes, PatientType } from 'doctivity-shared/utils';
import {
  ColorUtil,
  ServiceLineUtil,
  StringUtil,
  withIsMobile,
  withRouter
} from 'utils';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { listClaimsServicelines } from 'store/actions/claimsActions';
import fileDownload from 'js-file-download';
import { CsvUtil, DateUtil } from 'doctivity-shared/utils';

const styles = (theme) => ({
  card: {},
  filterDropDown: {
    width: 320,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dropDown: {
    marginBottom: theme.spacing(2),
  },
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  filterLabel: {
    backgroundColor: 'white',
    paddingRight: 4,
  },
  noData: {
    textAlign: 'center',
    paddingTop: 50,
    minHeight: 200,
  },
  colorKey: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 400,
    minHeight: 40,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  colorKeyIcon: {
    width: 14,
    height: 14,
    marginRight: 2,
    borderRadius: 2,
  },
  colorKeyText: {
    marginRight: 12,
  },
});

class ClaimsNetworkAffiliated extends React.Component {
  constructor(props) {
    super(props);

    this.cardElement = React.createRef(null);

    this.state = {
      serviceline: 'all',
      before: false,
    };
  }

  componentDidMount() {
    const { network } = this.props;

    if (StoreUtil.needsLoadNoCache(network)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.provider !== prevProps.provider ||
      this.props.patientType !== prevProps.patientType
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, provider, patientType, clientId, servicelines } =
      this.props;

    if (!servicelines) {
      dispatch(listClaimsServicelines());
    }

    dispatch(
      loadAnalytics({
        type: 'CLAIMS_NETWORK_AFFILIATED',
        filter: {
          provider_id: provider.id,
          serviceline:
            this.state.serviceline === 'all'
              ? undefined
              : this.state.serviceline,
          before: this.state.before,
          client_id: clientId,
          patient_type: patientType,
        },
        opts: {
          limit: 32,
          offset: 0,
        },
      })
    );
  }

  render() {
    const { classes, network, provider, servicelines } = this.props;
    const { serviceline } = this.state;
    const isLoaded = !(!network || !StoreUtil.isLoaded(network) || !servicelines);

    const categories = [];
    const series = [
      { name: 'Affiliated', data: [] },
      { name: 'Non-Affiliated', data: [] },
    ];

    const showSpecificServicelineIds =
      serviceline === 'all' ? [] : [serviceline];
    network?.data?.rows?.forEach((row) => {
      if (
        (serviceline === 'all' && row.parent_id === null) ||
        row.parent_id === serviceline
      ) {
        series[0].data.push(row.sum_shared_patients_affiliated);
        series[1].data.push(row.sum_shared_patients_non_affiliated);

        if (row.serviceline_id && row.sum_shared_patients) {
          showSpecificServicelineIds.push(row.serviceline_id);
        }
        categories.push({
          label: row.abbreviation ?? row.name,
          total: row.sum_shared_patients,
          affiliatedPercentage: row.sum_shared_patients
            ? Math.round(
                (row.sum_shared_patients_affiliated / row.sum_shared_patients) *
                  100
              )
            : 0,
          isParent: row.parent_id === null,
          servicelineId: row.serviceline_id,
        });
      }
    });

    const hasChildren =
      serviceline === 'all'
        ? true
        : ServiceLineUtil.hasChildren(servicelines, serviceline);

    return (
      <Card className={classes.card} ref={this.cardElement}>
        <CardContent>
          <TitleTooltip
            title='Shared Patient Provider Affiliation by Service Line'
            onExport={this.exportChart}
          />
          <div className={classes.dropDownContainer}>
            <FormControl
              variant='outlined'
              className={classes.filterDropDown}
              key='direction'
            >
              <InputLabel className={classes.filterLabel}>
                Claims Date
              </InputLabel>
              <Select
                key='select'
                value={this.state.before}
                onChange={this.onDirectionChange}
                label='Claims Date'
              >
                <MenuItem value={false} key='after'>
                  {`After ${provider.last_name}, ${provider.first_name}`}
                </MenuItem>
                <MenuItem value key='before'>
                  {`Before ${provider.last_name}, ${provider.first_name}`}
                </MenuItem>
              </Select>
            </FormControl>
            <div className={classes.dropDown}>
              <ServiceLineSelect
                value={serviceline}
                onChange={this.onServicelineChange}
                hideChildlessTopLevels
                showSpecificServicelineIds={
                  serviceline === 'all' && showSpecificServicelineIds
                }
                showAll
              />
            </div>
            <div className={classes.dropDown}>
              <PatientTypeSelect noCapturedClaims />
            </div>
          </div>
          {serviceline !== 'all' && (
            <SmallIconTextButton
              icon='chevron_left'
              text='Top Level Service Lines'
              onClick={() => this.onServicelineChange('all')}
            />
          )}
          {!isLoaded && <LoadingView />}
          {isLoaded && network.data.rows.length === 0 && hasChildren && (
            <Typography className={classes.noData}>
              No shared patients.
            </Typography>
          )}
          {isLoaded && network.data.rows.length === 0 && !hasChildren && (
            <Typography className={classes.noData}>
              No Sub-Service Lines available for this Service Line
            </Typography>
          )}
          {isLoaded && network.data.rows.length > 0 && (
            <Chart
              type='bar'
              height={Math.max(50 + network.data.rows.length * 26, 180)}
              options={{
                chart: {
                  id: 'ClaimsByServicelineAffiliated',
                  toolbar: {
                    show: false,
                  },
                  events: {
                    click: this.onChartClick,
                  },
                  animations: {
                    enabled: false,
                  },
                  stacked: true,
                },
                yaxis: {
                  labels: {
                    formatter: (obj) => obj.label,
                    minWidth: 100,
                  },
                },
                xaxis: {
                  categories,
                  labels: {
                    show: false,
                    hideOverlappingLabels: true,
                    rotate: -45,
                    formatter: StringUtil.numberForChart,
                  },
                  tickPlacement: 'on',
                },
                tooltip: {
                  enabled: true,
                  shared: true,
                  intersect: false,
                  x: {
                    formatter: (x) => {
                      return `${x.label}`;
                    },
                  },
                  y: {
                    formatter: (y, opt) => {
                      if (opt.seriesIndex === 0) {
                        return `${
                          opt.w.config.xaxis.categories[opt.dataPointIndex]
                            .affiliatedPercentage
                        }%`;
                      } else {
                        return `${
                          100 -
                          opt.w.config.xaxis.categories[opt.dataPointIndex]
                            .affiliatedPercentage
                        }%`;
                      }
                    },
                  },
                },
                colors: [
                  ColorUtil.AffiliatedColor,
                  ColorUtil.NonAffiliatedColor,
                ],
                dataLabels: {
                  enabled: true,
                  hideOverflowingLabels: true,
                  dropShadow: {
                    enabled: true,
                    left: 0,
                    top: 0,
                    opacity: 0.4,
                  },
                  formatter: (val, opt) => {
                    if (!val) {
                      return '';
                    }

                    if (opt.seriesIndex === 0) {
                      return `${
                        opt.w.config.xaxis.categories[opt.dataPointIndex]
                          .affiliatedPercentage
                      }%`;
                    } else {
                      return `${
                        100 -
                        opt.w.config.xaxis.categories[opt.dataPointIndex]
                          .affiliatedPercentage
                      }%`;
                    }
                  },
                  offsetY: 7,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
              }}
              series={series}
            />
          )}
        </CardContent>
      </Card>
    );
  }

  exportChart = () => {
    const columns = ['Provider NPI', 'First Name', 'Last Name', 'Before/After', 'Affiliation Status', 'Service Line', '% Affiliated', '% Non Affiliated'];
    const data = [columns];
    const { network, provider, servicelines, patientType } = this.props;
    const beforeOrAfter = this.state.before ? 'Before' : 'After';
    network.data.rows.forEach((row) => {
      data.push([
        provider.npi,
        provider.first_name,
        provider.last_name,
        beforeOrAfter,
        provider.clients?.length > 0 ? 'Affiliated' : 'Non-affiliated',
        row.abbreviation ?? row.name,
        ((row.sum_shared_patients_affiliated / row.sum_shared_patients )*100).toFixed(2),
        ((row.sum_shared_patients_non_affiliated / row.sum_shared_patients )*100).toFixed(2),

      ])
    });
    const serviceline = this.state.serviceline === 'all' ? 'ALL' : servicelines.find((l) => l.id === this.state.serviceline);
    const pediatric = patientType === 'PEDIATRIC_PATIENTS' ? '_PEDIATRIC' : '';
    const date = DateUtil.formatDateForUser(new Date());
    fileDownload(CsvUtil.matrixToString(data), `doctivity_SHARED_${beforeOrAfter.toLocaleUpperCase()}_${((serviceline?.abbreviation??serviceline?.name)??'ALL').toLocaleUpperCase()}${pediatric}_${date}.csv`);
  }

  onChartClick = (event, chartContext, config) => {
    const { isMobile } = this.props;
    if (!isMobile && config.dataPointIndex > -1) {
      const selectedServiceline =
        config.config.xaxis.categories[config.dataPointIndex];
      if (selectedServiceline.isParent) {
        this.setState(
          {
            serviceline: selectedServiceline.servicelineId,
          },
          this.fetchData
        );
      }
    }
  };

  onServicelineChange = (id) => {
    this.setState(
      {
        serviceline: id,
      },
      this.fetchData
    );
  };

  onDirectionChange = (event) => {
    this.setState(
      {
        before: event.target.value,
      },
      this.fetchData
    );
  };
}

ClaimsNetworkAffiliated.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  provider: PropTypes.object,
  network: PropTypes.object,
  servicelines: PropTypes.array,
  patientType: PropTypes.oneOf(PatientTypes),
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    network: StoreUtil.get(state.analytics, 'CLAIMS_NETWORK_AFFILIATED'),
    servicelines: state.claims && state.claims.servicelines_grouped,
    patientType:
      state.app.patientType === PatientType.CAPTURED_CLAIMS
        ? PatientType.ALL_PATIENTS
        : state.app.patientType,
  };
}

const styled = withStyles(styles)(withIsMobile(ClaimsNetworkAffiliated));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ClaimsNetworkAffiliated };
