import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import {
  Dialog,
  DialogTitle,
} from '@mui/material';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';

import { MaterialForm } from 'components/material-form/MaterialForm';

import { ConfirmationDialog } from 'components';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  containerWithTitle: {
    paddingTop: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  loadingContainer: {
    padding: theme.spacing(4),
  },
});

class DialogForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataModified: false,
      confirmCloseOpen: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onFormError = this.onFormError.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onSaveAndApproveClick = this.onSaveAndApproveClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  onFormSubmit(formData) {
    const { onFormSubmit, dataOnly } = this.props;

    if (formData.submitSource === 'Save and Approve') {
      this.onSaveAndApproveClick(formData);
    } else if (onFormSubmit) {
      let data = formData;

      if (dataOnly) {
        data = formData.formData;
      }

      onFormSubmit(data);
    }
  }

  onSaveAndApproveClick(formData) {
    const { onSaveAndApprove, dataOnly } = this.props;

    if (onSaveAndApprove) {
      let data = formData;

      if (dataOnly) {
        data = formData.formData;
      }

      onSaveAndApprove(data);
    }
  }

  onFormError() {
    const { onFormError } = this.props;

    if (onFormError) {
      onFormError();
    }
  }

  onFormChange(form) {
    const { onFormChange } = this.props;

    const { dataModified } = this.state;

    if (!dataModified) {
      this.setState({ dataModified: true }, () => {
        if (onFormChange) {
          onFormChange(form);
        }
      });
    } else {
      if (onFormChange) {
        onFormChange(form);
      }
    }
  }

  onCancel = (event, reason) => {
    const { dataModified } = this.state;

    if (dataModified && reason && reason === 'backdropClick') {
      this.setState({ confirmCloseOpen: true });
      return;
    }

    this.handleClose();
  };

  handleClose() {
    const { onClose } = this.props;

    this.onCloseConfirmCancel();

    if (onClose) {
      onClose();
    }
  }

  onCloseConfirmCancel = () => {
    this.setState({ confirmCloseOpen: false, dataModified: false });
  };

  render() {
    const {
      classes,
      open,
      secondaryText,
      loading,
      validate,
      onSecondarySubmit,
      ...props
    } = this.props;

    const { confirmCloseOpen } = this.state;

    if (loading) {
      return (
        <Dialog
          onClose={this.onCancel}
          aria-labelledby='simple-dialog-title'
          open={open}
        >
          <div className={classes.loadingContainer}>
            <CircularProgress color='primary' />
          </div>
        </Dialog>
      );
    }

    return (
      <>
        <Dialog
          onClose={this.onCancel}
          aria-labelledby='simple-dialog-title'
          open={open}
        >
          {this.props.title && <DialogTitle>{this.props.title}</DialogTitle>}
          <div
            className={
              this.props.title ? classes.containerWithTitle : classes.container
            }
          >
            <MaterialForm
              onChange={this.onFormChange}
              onSubmit={this.onFormSubmit}
              onSecondarySubmit={onSecondarySubmit}
              secondaryText={secondaryText}
              onError={this.onFormError}
              inDialog
              backOnCancel={false}
              onCancel={this.onCancel}
              renderAdditionalFormContent={
                this.props.renderAdditionalFormContent
              }
              validate={validate}
              {...props}
            />
          </div>
        </Dialog>
        <ConfirmationDialog
          open={confirmCloseOpen}
          title='Close Dialog?'
          content='You will lose any unsaved changes.'
          onClose={this.onCloseConfirmCancel}
          onCancel={this.onCloseConfirmCancel}
          onConfirm={this.handleClose}
        />
      </>
    );
  }
}

DialogForm.defaultProps = {
  formData: {},
  dataOnly: true,
};

DialogForm.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onFormChange: PropTypes.func,
  onDataTransform: PropTypes.func,
  onFormSubmit: PropTypes.func,
  onSecondarySubmit: PropTypes.func,
  onFormError: PropTypes.func,
  onSaveAndApprove: PropTypes.func,
  open: PropTypes.bool.isRequired,
  schema: PropTypes.object.isRequired,
  uiSchema: PropTypes.object.isRequired,
  formData: PropTypes.object,
  dataOnly: PropTypes.bool,
  secondaryText: PropTypes.string,
  loading: PropTypes.bool,
  renderAdditionalFormContent: PropTypes.func,
  validate: PropTypes.func,
  title: PropTypes.string,
};

const styled = withStyles(styles)(DialogForm);
export { styled as DialogForm };
