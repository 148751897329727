import { StoreUtil } from 'doctivity-shared/utils';

class TagsUtil {

  // store a cache map of the tags in data to avoid having to search through the data array
  static indexTags(state) {
    state.cache = {};
    const namespaces = state.data;
    if (namespaces) {
      namespaces.forEach((namespace) => {
        state.cache[`ns${namespace.id}`] = namespace;
        namespace.tags.forEach((tag) => {
          state.cache[tag.id] = [tag, namespace];
        });
      });
    }
  }

  static getAllNamespaces(state) {
    return StoreUtil.get(state, 'API_TAG_NAMESPACES_LIST_BY_CLIENT');
  }

  static getNamespacesByType(type, state) {
    let namespaces = [];
    if (StoreUtil.isLoaded(state)) {
      namespaces = StoreUtil.getData(state);
      if (type === 'providers') {
        namespaces = namespaces.filter((ns) => ns.providers_drop_down);
      } else if (type === 'activity_notes') {
        namespaces = namespaces.filter((ns) => ns.activity_notes_drop_down);
      } else if (type === 'organizations') {
        namespaces = namespaces.filter((ns) => ns.organizations_drop_down);
      }
    }
    return namespaces;
  }
  
  static findTag(id, state) {
    if (state.cache) {
      return state.cache[id]?.[0];
    }
    return null;
  }

  static findNamespace(id, state) {
    if (state.cache) {
      return state.cache[`ns${id}`];
    }
    return null;
  }

  static findNamespaceForTagId(tagId, state) {
    if (state.cache) {
      return state.cache[tagId]?.[1];
    }
    return null;
  }
}
export { TagsUtil };
export default TagsUtil;
