import { loadOrganization } from './organizationsActions'

export const API_ORGANIZATION_TAGS_UPSERT = 'API_ORGANIZATION_TAGS_UPSERT';
export const API_ORGANIZATION_TAGS_TABLE = 'API_ORGANIZATION_TAGS_TABLE ';
export const API_ORGANIZATION_TAGS_LIST = 'API_ORGANIZATION_TAGS_LIST ';
export const API_ORGANIZATION_TAGS_LOAD = 'API_ORGANIZATION_TAGS_LOAD ';
export const API_ORGANIZATION_TAGS_GET = 'API_ORGANIZATION_TAGS_GET ';
export const API_ORGANIZATION_TAGS_DELETE = 'API_ORGANIZATION_TAGS_DELETE';
export const API_ORGANIZATION_TAGS_ORGANIZATIONS = 'API_ORGANIZATION_TAGS_ORGANIZATIONS';

export const queryOrganizationTags = (options, type = API_ORGANIZATION_TAGS_TABLE) => ({
  type,
  request: {
    url: '/OrganizationTags',
    method: 'GET',
    params: { opts: options },
  },
});

export const listOrganizationTags = () => (queryOrganizationTags({
  order: [
    ['last_name', 'ASC'],
  ],
}, API_ORGANIZATION_TAGS_LIST));

// duplicate of organizationActions.queryOrganizations, to store in different location
export const queryOrganizations = (options, type = API_ORGANIZATION_TAGS_ORGANIZATIONS) => ({
  type,
  request: {
    url: '/Organizations',
    method: 'GET',
    params: { opts: options },
  },
});


export const loadOrganizationTagsByTagId = (tagId) => {
  const include = [
    {
      association: 'organization',
      attributes: ['id', 'name'],
    },
    {
      association: 'location',
      include: [
        {
          attributes: ['id', 'name'],
          association: 'organization',
        },
      ],
    },
 
    {
      association: 'tag',
      attributes: ['id', 'name', 'namespace_id'],
      include: [
        {
          association: 'namespace',
          attributes: ['id', 'name'],
        },
      ],
   },
 
  ];

  return {
    type: API_ORGANIZATION_TAGS_LOAD,
    id: organizationId,
    request: {
      method: 'GET',
      url: '/OrganizationTags',
      params: {
        opts: {
          where: {
            tag_id: tagId,
          },
          include,
        },
      },
    },
  };
};

export const loadOrganizationTagsByOrganizationId = (organizationId) => {
  const include = [
    {
      association: 'organization',
      attributes: ['id', 'name'],
    },
    {
      association: 'location',
      include: [
        {
          attributes: ['id', 'name'],
          association: 'organization',
        },
      ],
    },
 
    {
      association: 'tag',
      attributes: ['id', 'name', 'namespace_id'],
      include: [
        {
          association: 'namespace',
          attributes: ['id', 'name'],
        },
      ],
   },
 
  ];

  return {
    type: API_ORGANIZATION_TAGS_LOAD,
    id: organizationId,
    request: {
      method: 'GET',
      url: '/OrganizationTags',
      params: {
        opts: {
          where: {
            organization_id: organizationId,
          },
          include,
        },
      },
    },
  };
};

export const upsertOrganizationTag = (data, nextAction) => {
  let id = 0;
  let options = {
    include: [],
  };

  return {
    type: API_ORGANIZATION_TAGS_UPSERT,
    id,
    request: {
      url: '/OrganizationTags',
      method: 'PUT',
      params: { opts: options },
      data,
    },
    nextAction
  };
};

export const deleteOrganizationTag = (data, organizationId, nextAction) => {
  return {
    type: API_ORGANIZATION_TAGS_DELETE,
    id: undefined,
    request: {
      url: '/OrganizationTags',
      method: 'DELETE',
      data
    },
    nextAction: !!nextAction ? nextAction : loadOrganization(organizationId, data.selectedClient),
  };
};
