import React from 'react';

import { LocationsTabs } from './LocationsTabs';
import { LocationsTable } from './LocationsTable';

class LocationsPage extends React.Component {
  render() {
    return (
      <div>
        <LocationsTabs tab='locations' />
        <LocationsTable />
      </div>
    );
  }
}

LocationsPage.propTypes = {};

export { LocationsPage };
