import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    const [query, setQuery] = useSearchParams();

    // params must be passd as own property because mapStateToProps
    // doesn't do deep comparison
    return (
      <Component
        {...props}
        router={{ location, navigate, params, query, setQuery }}
        params={params}
      />
    );
  }

  return ComponentWithRouterProp;
}

export { withRouter };
export default withRouter;
