import React from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  LoadingView,
  TitleTooltip,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  loadAnalytics,
} from 'store/actions/analyticsActions';
import { withIsMobile } from 'utils';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class ReferringAffiliationPieChart extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.providerId !== prevProps.providerId
      || this.props.clientId !== prevProps.clientId
      || this.props.department !== prevProps.department
      || this.props.referringOrganization !== prevProps.referringOrganization
      || this.props.app.dateRange !== prevProps.app.dateRange) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      dispatch,
      providerId,
      clientId,
    } = this.props;

    dispatch(loadAnalytics({
      type: 'REFERRING_PROVIDER_AFFILIATION',
      filter: {
        department: this.props.department,
        referring_organization: this.props.referringOrganization,
        provider_id: providerId,
        client_id: clientId,
      },
      opts: {
        from: this.props.app.dateRange.start,
        to: this.props.app.dateRange.end,
      },
    }));
  }

  render() {
    const {
      classes,
      data,
      isMobile,
    } = this.props;

    let graph;
    if (!data
      || StoreUtil.isLoading(data)) {
      graph = (<LoadingView />);
    } else if (data && data.data && data.data.length > 0) {
      const labels = ['Affiliated', 'Non-Affiliated'];
      const cleanData = [0, 0];
      const rows = StoreUtil.getData(data);
      if (rows && rows.length > 0) {
        cleanData[0] = rows[0].total_affiliated;
        cleanData[1] = rows[0].total_referred - rows[0].total_affiliated;
      }

      graph = (
        <Chart
          type='pie'
          {...(isMobile ? {} : {height: 210})}
          options={{
            ...(isMobile ? { legend: { horizontalAlign: 'left', position: 'bottom' } } : {}),
            labels,
          }}
          series={cleanData}
        />
      );
    } else {
      graph = (
        <Typography className={classes.nothingFound}>
          No referring affiliation data available.
        </Typography>
      );
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <TitleTooltip
              title='Referring Affiliation'
              tooltip=''
            />
          </div>

          {graph}

        </CardContent>
      </Card>
    );
  }
}

ReferringAffiliationPieChart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  data: PropTypes.object,
  providerId: PropTypes.number,
  clientId: PropTypes.number.isRequired,
  department: PropTypes.string,
  referringOrganization: PropTypes.string,
  isMobile: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
    analytics,
    app,
  } = state;

  return {
    app,
    data: StoreUtil.get(analytics, 'REFERRING_PROVIDER_AFFILIATION'),
  };
}

const styled = withStyles(styles)(withIsMobile(ReferringAffiliationPieChart));
const connected = connect(mapStateToProps)(styled);
export { connected as ReferringAffiliationPieChart };
