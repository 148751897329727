export const API_PROVIDER_REPORTS_TABLE = 'API_PROVIDER_REPORTS_TABLE';
export const API_PROVIDER_REPORTS_LIST = 'API_PROVIDER_REPORTS_LIST';
export const API_PROVIDER_REPORTS_UPSERT = 'API_PROVIDER_REPORTS_UPSERT';

export const queryProviderReports = (options, type = API_PROVIDER_REPORTS_TABLE) => ({
  type,
  request: {
    url: '/ProviderReports',
    method: 'GET',
    params: { opts: options },
  },
});

export const listProviderReports = () => (queryProviderReports({
  order: [
    ['last_sent_date', 'DESC'],
  ],
}, API_PROVIDER_REPORTS_LIST));

export const upsertProviderReport = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  return {
    type: API_PROVIDER_REPORTS_UPSERT,
    id,
    request: {
      url: '/ProviderReports',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};
