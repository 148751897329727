import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import {
  LoadingView,
} from 'components';

export class DashboardCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        isLoading: true,
      });

      this.props.getData().then((response) => {
        this.setState({
          isLoading: false,
          data: response.data,
        });
      });
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.props.getData().then((response) => {
      this.setState({
        isLoading: false,
        data: response.data,
      });
    });
  }

  render() {
    const {
      title,
    } = this.props;

    const {
      isLoading,
      data,
    } = this.state;

    if (isLoading) {
      return (
        <Card>
          <CardContent>
            <LoadingView />
          </CardContent>
        </Card>
      );
    }

    return (
      <Card>
        <CardContent>
          <Typography component='h2' variant='h6' color='primary'>{title}</Typography>
          {(this.props.renderContent && data) && this.props.renderContent(data)}
        </CardContent>
      </Card>
    );
  }
}

DashboardCard.propTypes = {
  title: PropTypes.string,
  getData: PropTypes.func,
  renderContent: PropTypes.func,
  filter: PropTypes.object,
};
