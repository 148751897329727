import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  LoadingView,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';

import {
  favoritesByType,
} from 'store/actions/favoritesActions';

const styles = () => ({
  card: {
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 250,
  },
  button: {
    color: '#444444',
    padding: 2,
    fontWeight: 200,
    fontSize: 12,
    lineHeight: 0.97,
    textAlign: 'left',
    paddingTop: 6,
    paddingBottom: 6,
  },
  noData: {
    textAlign: 'center',
    color: '#444444',
    fontWeight: 200,
    fontSize: 12,
  },
});

class FavoritesList extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.objectType !== prevProps.objectType
      || this.props.clientId !== prevProps.clientId
      || this.props.userId !== prevProps.userId) {
      this.fetchData();
    }
  }

  fetchData() {
    if (!StoreUtil.isLoaded(this.props.favorites)) {
      this.props.dispatch(favoritesByType(
        this.props.clientId,
        this.props.userId,
        this.props.objectType,
      ));
    }
  }

  render() {
    const {
      classes,
    } = this.props;

    if (!this.props.favorites || !StoreUtil.isLoaded(this.props.favorites)) {
      return (<LoadingView />);
    }

    const favorites = this.props.favorites.data.rows;
    const objectName = this.props.objectType.toLowerCase().substring(
      0,
      this.props.objectType.length - 1,
    );

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography component='h2' variant='h6' color='primary' gutterBottom>
            {`Favorite ${this.props.title || this.props.objectType}`}
          </Typography>

          <div className={classes.list}>
            {(!favorites || favorites.length === 0) && (
              <Typography className={classes.noData}>
                {`No favorite ${(
                  this.props.title || this.props.objectType
                ).toLowerCase()}.`}
              </Typography>
            )}
            {favorites.map((fav) => {
              return (
                fav[objectName] && (
                  <Button
                    key={fav.object_id}
                    size='small'
                    onClick={() => {
                      this.onClick(fav[objectName]);
                    }}
                    className={classes.button}
                  >
                    {this.props.name
                      ? this.props.name(fav[objectName])
                      : fav[objectName].name}
                    {fav[objectName].location &&
                      fav[objectName].location.organization &&
                      ` - ${fav[objectName].location.organization.name}`}
                  </Button>
                )
              );
            })}
          </div>
        </CardContent>
      </Card>
    );
  }

  onClick = (obj) => {
    const path = this.props.path || `${this.props.objectType.toLowerCase()}/`;
    this.props.router.navigate(`${path}${obj.id}`);
  };
}

FavoritesList.propTypes = {
  classes: PropTypes.object,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  objectType: PropTypes.string.isRequired,
  clientId: PropTypes.number.isRequired,
  userId: PropTypes.number.isRequired,
  title: PropTypes.string,
  name: PropTypes.func,
  favorites: PropTypes.object,
  path: PropTypes.string,
};

function mapStateToProps(state, props) {
  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;
  return {
    userId,
    clientId,
    favorites: StoreUtil.get(state.favorites, 'by_type', `${clientId}-${userId}-${props.objectType}`),
  };
}

const styled = withStyles(styles)(FavoritesList);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as FavoritesList };
