import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import {
  StoreUtil,
} from 'doctivity-shared/utils';
import {
  listDepartments,
} from 'store/actions/visitsActions';

const styles = (theme) => ({
  filterDropDown: {
    width: 220,
    marginRight: theme.spacing(2),
  },
});

class DepartmentSelect extends React.Component {
  componentDidMount() {
    if (!this.props.departments
      || this.props.departmentsClientId !== this.props.clientId) {
      this.fetchData();
    }
  }

  componentDidUpdate() {
    if (!this.props.departments
      || this.props.departmentsClientId !== this.props.clientId) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(listDepartments(this.props.clientId));
  }

  render() {
    const {
      classes,
    } = this.props;

    const menuItems = [];
    if (StoreUtil.isLoaded(this.props.departments)) {
      menuItems.push((
        <MenuItem value='all' key='all'>
          <em>All</em>
        </MenuItem>
      ));

      StoreUtil.getData(this.props.departments).forEach((row) => {
        menuItems.push((
          <MenuItem value={row.department} key={row.department}>
            {row.department}
          </MenuItem>
        ));
      });
    }

    return (
      <FormControl variant='outlined' className={classes.filterDropDown} key='filter-department'>
        <InputLabel>Department</InputLabel>
        <Select
          value={this.props.value || 'all'}
          onChange={this.onChange}
          label='Department'
        >
          { menuItems }
        </Select>
      </FormControl>
    );
  }

  onChange = (event) => {
    if (event.target.value === 'all') {
      this.props.onChange(null);
    } else {
      this.props.onChange(event.target.value);
    }
  };
}

DepartmentSelect.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  departments: PropTypes.object,
  value: PropTypes.string,
  clientId: PropTypes.number,
  departmentsClientId: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    departmentsClientId: state.visits.departmentsClientId,
    departments: StoreUtil.get(state.visits, 'departments'),
  };
}

const styled = withStyles(styles)(DepartmentSelect);
const connected = connect(mapStateToProps)(styled);
export { connected as DepartmentSelect };
