export const API_PROVIDER_REVENUE_UPSERT = 'API_PROVIDER_REVENUE_UPSERT';

export const upsertProviderRevenue = (data, nextAction) => {
  const id = 0; // should be an id, but this is an array?
  return {
    type: API_PROVIDER_REVENUE_UPSERT,
    id,
    request: {
      url: '/ProviderRevenue',
      method: 'PUT',
      params: { opts: null },
      data,
    },
    nextAction
  };
};
