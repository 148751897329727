import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  DynamicTable,
} from 'components';

import {
  queryUsers,
} from 'store/actions/usersActions';

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Last Name',
    key: 'last_name',
  },
  {
    label: 'First Name',
    key: 'first_name',
  },
  {
    label: 'Email',
    key: 'email',
  },
];

const styles = (theme) => ({
  filters: {
    display: 'flex',
    flexDirection: 'row',
  },
  instructions: {
    fontSize: 14,
    fontWeight: 300,
    flex: 1,
    color: theme.palette.primary.main,
  },
});

class FindUserDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        attributes: ['id', 'first_name', 'last_name', 'email'],
        where: { },
        limit: 10,
        offset: 0,
        order: [
          ['id', 'ASC'],
        ],
      },
    };
  }

  componentDidMount() {
    const {
      users,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(users)) {
      this.fetchData();
    }
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    const {
      dispatch,
      clientId,
    } = this.props;

    const {
      query,
    } = this.state;

    const include = [{
      association: 'clients',
      attributes: [],
      through: {
        attributes: ['user_id'],
        where: {
          client_id: clientId,
        },
      },
      duplicating: false,
      paranoid: false,
      required: true,
    }];

    dispatch(queryUsers({
      ...query,
      include,
    }));
  }

  render() {
    const {
      classes,
      users,
      open,
    } = this.props;

    const {
      query,
    } = this.state;

    return (
      <Dialog
        onClose={this.onCancel}
        aria-labelledby='simple-dialog-title'
        open={open}
        fullWidth
        maxWidth='lg'
        scroll='paper'
      >
        <DialogTitle>
          Find a User
        </DialogTitle>
        <DialogContent dividers>
          <div className={classes.filters}>
            <Typography className={classes.instructions}>Filter and select the user</Typography>
          </div>
          <DynamicTable
            columns={columns}
            query={query}
            data={users}
            onQueryChange={this.onQueryChange}
            onRowClick={this.onRowClick}
            ContainerType={React.Fragment}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCancel} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  onCancel = () => {
    const {
      onSelect,
    } = this.props;

    if (onSelect) {
      onSelect(null);
    }
  };

  onRowClick = (row) => {
    const {
      onSelect,
    } = this.props;

    if (onSelect) {
      onSelect(row);
    }
  };
}

FindUserDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  users: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  onSelect: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

function mapStateToProps(state) {
  const {
    users,
  } = state;

  return {
    users: StoreUtil.get(users, StoreUtil.COMMON_TABLE),
  };
}

const styled = withStyles(styles)(FindUserDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as FindUserDialog };
