import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import { Icon, Typography, Card, CardContent } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { StoreUtil } from 'doctivity-shared/utils';

import { DynamicTable } from 'components';

import { queryTags } from 'store/actions/tagsActions';

import { withRouter } from 'utils';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  condensedCell: {
    padding: 2,
    fontSize: 12,
    fontWeight: 'inherit',
  },
  emptyExpandedRowData: {
    fontSize: 12,
    fontWeight: 'inherit',
  },
  tagType: {
    color: theme.palette.primary.main,
  }
});

function TagType({ isProvider, isActivityNote, classes }) {
  const children = []  
  if (isProvider) {
    children.push(<Icon className={classes.tagType}>groupOutlined</Icon>)
  }
  if (isActivityNote) {
    children.push(<Icon className={classes.tagType}>listItem</Icon>)
  }

  return <>{children}</> 
}
TagType.propTypes = {
  isProvider: PropTypes.bool,
  isActivityNote: PropTypes.bool,
  classes: PropTypes.object
};
const StyledTagType = withStyles(styles)(TagType);

const columns = [
  {
    label: '',
    key: 'type',
    sortable: false,
    filter: false,
    style: { width: 50 },
    format: (_, row) => (<StyledTagType key={row.id} isProvider={row?.namespace?.providers_drop_down ?? false} isActivityNote={row?.namespace?.activity_notes_drop_down ?? false} />)
  },
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Label Type',
    key: 'namespace.name',
  },
];

class TagsAllTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        include: [
          {
            association: 'namespace',
            where: {
              ...(props.type === 'provider' && { providers_drop_down: true }),
              ...(props.type === 'activity_note' && { activity_notes_drop_down: true }),
            },
         },
        ],
        where: {
          client_id: props.clientId
        },
        order: [['namespace.name', 'DESC'], ['name', 'DESC']],
        limit: 25,
        offset: 0,
      }, 
    };
  }

  componentDidMount() {
    const { tags } = this.props;

    if (StoreUtil.needsLoadNoCache(tags)) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch } = this.props;

    const { query } = this.state;

    console.log(query);
    dispatch(queryTags(query));
  }

  render() {
    const { tags, classes, embedded } = this.props;

    const { query } = this.state;
    const extraProps = embedded ? {
      multiSelectedRows: this.props.selectedTags,
      onMultiSelect: this.onRowClick
    } : {
        onRowClick: this.onRowClick,
    };
    if (this.props.onRowClick) {
      extraProps.onRowClick = this.props.onRowClick;
    }
    const children = (<DynamicTable
                header={
                  <Typography
                    component='h2'
                    variant='h6'
                    color='primary'
                    gutterBottom
                  >{ embedded ? '' : 'Labels'}</Typography>
                }
                columns={columns}
                data={tags}
                {...extraProps} 
                ContainerType={React.Fragment}
                query={query}
                onQueryChange={this.onQueryChange}
              />)
    if (embedded) {
      return <>{children}</>
    }
    return (<>
        <Helmet defer={false}>
          <title>Labels</title>
        </Helmet>
        <div className={classes.container}>
          <Card>
            <CardContent>{children}</CardContent>
          </Card>
        </div>
      </>);
 }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  onRowClick = (row) => {
    if (this.props.embedded && this.props.selectTag) {
      this.props.selectTag(row);
    } else {
      this.props.router.navigate(`/labels/${row.id}`);
    }
  };
}

TagsAllTable.propTypes = {
  clientId: PropTypes.number.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  tags: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['all', 'provider', 'activity_note']),
  embedded: PropTypes.bool,
  selectTag: PropTypes.func,
  selectedTags: PropTypes.arrayOf(PropTypes.object),
  onRowClick: PropTypes.func,
};
TagsAllTable.defaultProps = {
  type: 'all',
  embedded: false,
};

function mapStateToProps(state) {
  const {
    tags,
    app
  } = state;

  return {
    tags: StoreUtil.get(tags, StoreUtil.COMMON_TABLE),
    clientId: app.selectedClient,
  };
}

const styled = withStyles(styles)(TagsAllTable);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as TagsAllTable };
