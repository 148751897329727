export const API_LOGIN_SETTINGS_TABLE = 'API_LOGIN_SETTINGS_TABLE';
export const API_LOGIN_SETTINGS_LIST = 'API_LOGIN_SETTINGS_LIST';
export const API_LOGIN_SETTINGS_UPSERT = 'API_LOGIN_SETTINGS_UPSERT';

export const queryLoginSettings = (options, type = API_LOGIN_SETTINGS_TABLE) => ({
  type,
  request: {
    url: '/LoginSettings',
    method: 'GET',
    params: { opts: options },
  },
});

export const listLoginSettings = () => (queryLoginSettings({
  order: [
    ['email_domain', 'ASC'],
  ],
}, API_LOGIN_SETTINGS_LIST));

export const upsertLoginSettings = (data) => {
  let options = null;
  let id = null;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  return {
    type: API_LOGIN_SETTINGS_UPSERT,
    id,
    request: {
      url: '/LoginSettings',
      method: 'PUT',
      params: { opts: options },
      data,
    },
    nextAction: listLoginSettings(),
  };
};
