import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  contentContainer: {
    paddingBottom: theme.spacing(1),
  },
  title: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
  },
  subtitle: {
    fontSize: 14,
    textAlign: 'center',
  },
  spacer: {
    marginBottom: theme.spacing(2),
  },
});

class PreventAddLocationDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, locationData, open, onClose } = this.props;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby='simple-dialog-title'
        open={open}
      >
        <div className={classes.container}>
          <DialogTitle className={classes.title}>
            Organization Already Set For This Location
          </DialogTitle>
          <DialogContent className={classes.contentContainer}>
            <div className={classes.content}>
              <Typography color='primary' className={classes.subtitle}>
                The selected location is already tied to an organization. Only
                one organization can be connected to each location.
              </Typography>
              <div className={classes.spacer} />
              <div>
                {locationData.organization && (
                  <Typography color='primary'>
                    {locationData.organization.name}
                  </Typography>
                )}
                {/* <Typography color='primary'>{locationData.name}</Typography> */}
                <Typography>{locationData.address1},</Typography>
                {locationData.address2 && (
                  <Typography>{locationData.address2},</Typography>
                )}
                <Typography>{`${locationData.city}, ${locationData.state} ${locationData.postal_code}`}</Typography>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={onClose}>
              Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

PreventAddLocationDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  locationData: PropTypes.object.isRequired,
};

function mapStateToProps() {
  return {};
}

const styled = withStyles(styles)(PreventAddLocationDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as PreventAddLocationDialog };
