import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_PROCEDURES_TABLE,
  API_PROCEDURES_LIST,
  API_PROCEDURES_UPSERT,
} from 'store/actions/proceduresActions';

export const procedures = (state = {}, action = null) => {
  const result = ReducerUtil.simpleReduce(
    state,
    action,
    API_PROCEDURES_TABLE,
    API_PROCEDURES_LIST,
    API_PROCEDURES_UPSERT,
  );
  if (result.actionConsumed) {
    return result.state;
  }
  return state;
};
