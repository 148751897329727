export const API_TAGS_TABLE = 'API_TAGS_TABLE';
export const API_TAGS_LIST = 'API_TAGS_LIST';
export const API_TAGS_UPSERT = 'API_TAGS_UPSERT';
export const API_TAGS_GET = 'API_TAGS_GET';

export const queryTags = (options, type = API_TAGS_TABLE) => ({
  type,
  request: {
    url: '/Tags',
    method: 'GET',
    params: { opts: options },
  },
});

export const listTags = (providerId, contactId, clientId) => (queryTags({
  where: {
    client_id: {
      $eq: clientId,
    },
  },
  order: [
    ['name', 'DESC'],
  ],
  include: [
    {
      association: 'user',
      attributes: ['id', 'first_name', 'last_name'],
    },
    {
      association: providerId ? 'providers' : 'contacts',
      attributes: [],
      through: {
        attributes: [],
        where: {
          [providerId ? 'provider_id' : 'contact_id']: providerId || contactId,
        },
      },
      required: true,
    },
  ],

}, API_TAGS_LIST));

export const getTag = (tagId, clientId) => {
  const include = [
    {
      association: 'user',
      attributes: ['id', 'first_name', 'last_name'],
    },
    {
      association: 'providers',
      attributes: ['id', 'first_name', 'last_name', 'middle_name'],
    },
    {
      association: 'namespace',
      attributes: ['id', 'name', 'activity_notes_drop_down', 'providers_drop_down', 'organizations_drop_down']
    }
  ]
  if (clientId) {
    include[1].include = [{
      association: 'clients',
      required: false,
      attributes: ['id', 'name'],
    }]
  }
 
  return {
    type: API_TAGS_GET,
    id: tagId,
    request: {
      url: '/Tags',
      method: 'GET',
      params: {
        opts: {
          where: {
            id: {
              $eq: tagId,
            },
          },
          include,
        },
      },
    }
  }
};

export const upsertTag = (data) => {
  let options = {
    include: [],
  };

  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      ...options,
      where: { id },
    };
  }

  return {
    type: API_TAGS_UPSERT,
    id,
    request: {
      url: '/Tags',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};
