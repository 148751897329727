class ValidationUtil {
  static isPasswordValid(password, passwordLength) {
    return !(password.length < passwordLength
      || password.toUpperCase() === password
      || password.toLowerCase() === password
      || !/[ !@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)
      || !/\d/.test(password));
  }
}

export { ValidationUtil };
export default ValidationUtil;
