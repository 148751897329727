import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';

const styles = (theme) => ({
  c: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  cPath: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: 0,
  },
  cPathAlt: {
    backgroundColor: '#F5F5F5',
  },
  itemContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing.unit,
  },
  itemContainerPathway: {
    justifyContent: 'flex-end',
    '& > div > div > div > div': {
      backgroundColor: 'transparent',
    },
  },
});

function ArrayFieldTitle(params) {
  const {
    TitleField, idSchema, title, required, rawErrors,
  } = params;
  if (!title) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }
  const id = `${idSchema.$id}__title`;
  return <TitleField id={id} title={title} required={required} rawErrors={rawErrors} />;
}

function ArrayFieldDescription(params) {
  const { DescriptionField, idSchema, description } = params;
  if (!description) {
    // See #312: Ensure compatibility with old versions of React.
    return <div />;
  }
  const id = `${idSchema.$id}__description`;
  return <DescriptionField id={id} description={description} />;
}

// Used in the two templates
function DefaultArrayItem(params, classes, isPathway) {
  let itemCls;
  if (isPathway) {
    itemCls = `${classes.itemContainer} ${classes.itemContainerPathway}`;
  } else {
    itemCls = `${classes.itemContainer}`;
  }
  return (
    <div key={params.index} className={itemCls}>
      <div>
        {params.children}
      </div>

      {params.hasToolbar && (
        <div className='array-item-toolbox'>
          <div
            className='btn-group'
            style={{
              display: 'flex',
              justifyContent: 'space-around',
            }}
          >

            {params.hasRemove && (
              <IconButton
                aria-label='Delete'
                color='primary'
                onClick={params.onDropIndexClick(params.index)}
                disabled={params.disabled || params.readonly}
                tabIndex='-1'
                size='large'
              >
                <RemoveIcon />
              </IconButton>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function MaterialArrayFieldTemplate(params) {
  const classes = params.classes;
  let containerClsList;
  let groupClsList;

  const isPathway = params.uiSchema
      && params.uiSchema['ui:options']
      && params.uiSchema['ui:options'].pathwayMode;
  if (isPathway) {
    if (params.uiSchema['ui:options'].altRow) {
      containerClsList = `${classes.c} ${classes.cPath} ${classes.cPathAlt}`;
      groupClsList = `${params.className} ${classes.cPathAlt}`;
    } else {
      containerClsList = `${classes.c} ${classes.cPath}`;
      groupClsList = `${params.className}`;
    }
  } else {
    containerClsList = `${classes.c}`;
    groupClsList = `${params.className}`;
  }

  return (
    <div className={groupClsList}>
      <div className={containerClsList}>
        <ArrayFieldTitle
          key={`array-field-title-${params.idSchema.$id}`}
          TitleField={params.TitleField}
          idSchema={params.idSchema}
          title={params.uiSchema['ui:title'] || params.title}
          required={params.required}
          rawErrors={params.rawErrors}
        />
        { params.uiSchema
          && params.uiSchema['ui:options']
          && params.uiSchema['ui:options'].arrayAddLabel
          && (
            <Typography variant='body1'>
              {params.uiSchema['ui:options'].arrayAddLabel}
            </Typography>
          )}
        {params.canAdd && (
          <Button
            aria-label='Add'
            onClick={params.onAddClick}
            color='primary'
            variant='fab'
            disabled={params.disabled || params.readonly}
            mini
          >
            <AddIcon />
          </Button>
        )}
      </div>
      {(params.uiSchema['ui:description'] || params.schema.description) && (
        <ArrayFieldDescription
          key={`array-field-description-${params.idSchema.$id}`}
          DescriptionField={params.DescriptionField}
          idSchema={params.idSchema}
          description={
            params.uiSchema['ui:description'] || params.schema.description
          }
        />
      )}

      <div
        className='row array-item-list'
        key={`array-item-list-${params.idSchema.$id}`}
      >
        {params.items && params.items.map((p) => DefaultArrayItem(p, params.classes, isPathway))}
      </div>
    </div>
  );
}

const styledPage = withStyles(styles)(MaterialArrayFieldTemplate);
export { styledPage as MaterialArrayFieldTemplate };
