import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  ValidationUtil,
  PhoneUtil,
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';

const styles = ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width: '100%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    marginTop: 30,
  },
  helpContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    marginTop: 30,
    textAlign: 'center',
  },
  smallText: {
    fontSize: 12,
    color: '#999999',
  },
});

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        email: '',
        phoneNumber: '',
        password: '',
        confirmPassword: '',
      },
      error: null,
      isRegistering: false,
      registerSuccess: null, 
      passwordLength: ''
    };

    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    const token = new URLSearchParams(this.props.router.location.search).get('token');
    if (token) {
      axios.get(`${process.env.API_BASE_URL}auth/invite/${token}`).then(async (response) => {
        if (!response.data.result.isValid) {
          this.props.router.navigate('/login');
        }

        await this.setPasswordLength(response.data.result.password_length)
      });      
    }
    else {
      await this.setPasswordLength(8)
    }
  }

  async setPasswordLength(passwordLength) {
    this.setState({passwordLength: passwordLength})
  }

  render() {
    const {
      // user,
      classes,
    } = this.props;

    const {
      form,
      registerSuccess,
    } = this.state;

    if (registerSuccess) {
      return (
        <Card className={classes.container}>
          <CardContent>
            <Typography>Account created.</Typography>
            <Link to='/login'><Button variant='contained' color='primary'>Login</Button></Link>
          </CardContent>
        </Card>
      );
    }

    return (
      <Card className={classes.container}>
        <form className={classes.form}>
          <CardHeader title='Create Account' />
          <CardContent>
            {this.state.error ? <Typography color='error'>{this.state.error}</Typography> : null}
            <TextField
              id='email'
              type='text'
              name='email'
              label='Email Address'
              placeholder='Enter email address'
              onChange={this.onChange}
              value={form.email}
              className={classes.textField}
              margin='normal'
              fullWidth
              required
            />
            <TextField
              id='phoneNumber'
              type='text'
              name='phoneNumber'
              label='Phone Number'
              placeholder='Enter phone number'
              onChange={this.onChange}
              value={form.phoneNumber}
              className={classes.textField}
              margin='normal'
              fullWidth
              required
            />
            <FormGroup>
              <FormControlLabel
                control={<Checkbox 
                  defaultChecked 
                  id='tfaCheckbox'
                  onChange={this.onChange}
                   />}
                label={(
                  <Typography className={classes.smallText}>
                    Enable Two Factor Authentication(TFA) for added account security.
                    Messaging and Data Rates may apply.
                  </Typography>
                )}
              />
            </FormGroup>
            <TextField
              id='password'
              type='password'
              name='password'
              label='Password'
              placeholder='Enter password'
              onChange={this.onChange}
              value={form.password}
              className={classes.textField}
              margin='normal'
              fullWidth
              helperText={`Password must contain a lowercase letter, an uppercase letter, a number, a special character, and be at least ${this.state.passwordLength} characters long.`}
              required
            />
            <TextField
              id='confirmPassword'
              type='password'
              name='confirmPassword'
              label='Confirm Password'
              placeholder='Enter password'
              onChange={this.onChange}
              value={form.confirmPassword}
              className={classes.textField}
              margin='normal'
              fullWidth
              required
            />
            <div className={classes.buttonContainer}>
              <Button
                id='login'
                className={classes.button}
                color='primary'
                margin='normal'
                variant='contained'
                disabled={this.registerButtonDisabled()}
                onClick={this.onSubmit}
              >
                Create Account
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    );
  }

  registerButtonDisabled() {
    const {
      form,
      isRegistering,
    } = this.state;

    if (isRegistering) {
      return true;
    }

    if (!form.email || form.phoneNumber.length < 10 || !form.password || !form.confirmPassword || tfaCheckbox.checked == false) {
      return true;
    }

    return false;
  }

  onChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [e.target.name]: e.target.value,
      },
    }));
  };

  onSubmit = () => {
    const {
      password,
      confirmPassword,
      phoneNumber,
    } = this.state.form;

    if (password !== confirmPassword) {
      this.setState({
        error: 'Passwords must match.',
      });
      return;
    }

    if (!ValidationUtil.isPasswordValid(password, this.state.passwordLength)) {
      this.setState({
        error: `Password must contain a lowercase letter, an uppercase letter, a number, a special character, and be at least ${this.state.passwordLength} characters long.`,
      });
      return;
    }

    if (phoneNumber && phoneNumber.length > 0 && !PhoneUtil.isValid(phoneNumber)) {
      this.setState({
        error: 'Invalid phone number',
      });
      return;
    }

    const token = new URLSearchParams(this.props.router.location.search).get('token');
    axios.post(`${process.env.API_BASE_URL}auth/invite`, {
      token,
      user: {
        email: this.state.form.email,
        phone: PhoneUtil.unformat(phoneNumber),
        password: this.state.form.password,
        confirmPassword: this.state.form.confirmPassword,
      },
    }).then((response) => {
      const result = response.data;
      this.setState({
        registerSuccess: result,
      });
    }).catch((err) => {
      this.setState({
        error: err.response.data.error.message,
      });
    });
  };
}

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

const styledPage = withStyles(styles)(RegisterPage);
const routed = withRouter(styledPage);
export { routed as RegisterPage };
