export const API_MIGRATION_TABLE = 'API_MIGRATION_TABLE';

export const queryMigrations = (
  isCounty,
  id,
  store_id,
  options,
  type = API_MIGRATION_TABLE
) => {
  if (!options.where) {
    options.where = {};
  }
  options.where.organization_id = id;
  return {
    type,
    oid: id,
    store_id,
    request: {
      url: isCounty ? '/PatientMigrationCounty' : '/PatientMigrationZip3',
      method: 'GET',
      params: { opts: options },
    },
  };
};