import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { withRouter } from 'utils';
import { PhoneUtil, StateUtil } from 'doctivity-shared/utils';

import { DialogForm } from 'components';

const styles = () => ({});

const schema = {
  type: 'object',
  required: ['address1', 'city', 'state', 'postal_code'],
  properties: {
    name: { type: 'string', title: 'Name' },
    address1: { type: 'string', title: 'Address 1' },
    address2: { type: 'string', title: 'Address 2' },
    city: { type: 'string', title: 'City' },
    state: {
      type: 'string',
      title: 'State',
      enum: StateUtil.getAbbreviations(),
      enumNames: StateUtil.getNames(),
      options: { enumOptions: StateUtil.getNames() },
    },
    postal_code: { type: 'string', title: 'Zip Code' },
    phone: {
      type: 'string',
      title: 'Phone',
      mask: 'phone-ext',
    },
    fax: {
      type: 'string',
      title: 'Fax',
      mask: 'phone-ext',
    },
    email: {
      type: 'string',
      title: 'Email',
    },
 },
};

const uiSchema = {
  name: {
    classNames: 'two-column-field',
  },
  address1: {
    classNames: 'two-column-field',
  },
  address2: {
    classNames: 'two-column-field',
  },
  city: {
    classNames: 'two-column-field',
  },
  state: {
    classNames: 'two-column-field',
    'ui:widget': 'select',
  },
  postal_code: {
    classNames: 'two-column-field',
  },
  phone: {
    classNames: 'two-column-field',
  },
  fax: {
    classNames: 'two-column-field',
  },
  email: {
    classNames: 'two-column-field',
  },
};

const NEW_LOCATION = {};

class LocationEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogError: false,
      findOrgDialogOpen: false,
    };
  }

  getLocationId() {
    const { location } = this.props;

    return location ? parseInt(location.id, 10) : null;
  }

  render() {
    const { open, location, submitText } = this.props;
    return (
      <div>
        <DialogForm
          title={
            location && location.id > 0 ? 'Edit Location' : 'Create Location'
          }
          open={open}
          formData={location || NEW_LOCATION}
          schema={schema}
          uiSchema={uiSchema}
          onClose={this.props.onClose}
          onFormSubmit={this.onSave}
          submitText={submitText}
          status={this.state.dialogError ? this.state.dialogErrorMessage : ''}
        />
      </div>
    );
  }

  onSave = (data) => {
    if (data.phone) {
      data.phone = PhoneUtil.unformat(data.phone);
      if (!data.phone || !PhoneUtil.isValid(data.phone)) {
        this.setState({
          dialogError: true,
          dialogErrorMessage: 'Invalid phone number.',
        });
        return;
      }
    }

    if (data.fax) {
      data.fax = PhoneUtil.unformat(data.fax);
      if (!data.fax || !PhoneUtil.isValid(data.fax)) {
        this.setState({
          dialogError: true,
          dialogErrorMessage: 'Invalid fax number.',
        });
        return;
      }
    }

    this.props.onSave(data);
  };
}

LocationEditDialog.propTypes = {
  app: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  location: PropTypes.object,
  submitText: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    app: state.app,
  };
}

const styled = withStyles(styles)(LocationEditDialog);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as LocationEditDialog };
