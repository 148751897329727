const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingLeft: theme.spacing(3),
  },
  tableHeader: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
});

export default styles;
