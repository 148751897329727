export const API_PROVIDER_COMMENTS_TABLE = 'API_PROVIDER_COMMENTS_TABLE';
export const API_PROVIDER_COMMENTS_LIST = 'API_PROVIDER_COMMENTS_LIST';
export const API_PROVIDER_COMMENTS_UPSERT = 'API_PROVIDER_COMMENTS_UPSERT';
export const API_PROVIDER_COMMENTS_GET = 'API_PROVIDER_COMMENTS_GET';
export const API_PROVIDER_COMMENTS_DELETE = 'API_PROVIDER_COMMENTS_DELETE';

export const queryProviderComments = (options, type = API_PROVIDER_COMMENTS_TABLE) => ({
  type,
  request: {
    url: '/ProviderComments',
    method: 'GET',
    params: { opts: options },
  },
});

export const listProviderComments = (
  providerId,
  contactId,
  locationId,
  clientId
) => {
  const include = [
    {
      association: 'user',
      attributes: ['id', 'first_name', 'last_name'],
    },
    {
      association: 'tags',
      attributes: ['id', 'name', 'namespace_id'],
      include: [
        {
          association: 'namespace',
          attributes: ['id', 'name'],
        },
      ],
    },
    {
      association: 'location',
      attributes: [
        'id',
        'name',
        'address1',
        'address2',
        'city',
        'state',
        'postal_code',
      ],
      where: {
        ...(!!locationId && { id: locationId }),
      },
      include: [
        {
          association: 'organization',
          attributes: ['id'],
        },
      ],
      required: !!locationId,
    },
  ];

  if (providerId) {
    include.push({
      association: 'providers',
      attributes: [],
      through: {
        attributes: [],
        where: {
          provider_id: providerId,
        },
      },
      required: true,
    });
  }

  if (contactId) {
    include.push({
      association: 'contacts',
      attributes: [],
      through: {
        attributes: [],
        where: {
          contact_id: contactId,
        },
      },
      required: true,
    });
  }

  return queryProviderComments(
    {
      where: {
        client_id: {
          $eq: clientId,
        },
      },
      order: [['comment_date', 'DESC']],
      include,
    },
    API_PROVIDER_COMMENTS_LIST
  );
};

export const getProviderComment = (commentId) => ({
  type: API_PROVIDER_COMMENTS_GET,
  id: commentId,
  request: {
    url: '/ProviderComments',
    method: 'GET',
    params: {
      opts: {
        where: {
          id: {
            $eq: commentId,
          },
        },
        include: [
          {
            association: 'user',
            attributes: ['id', 'first_name', 'last_name'],
          },
          {
            association: 'providers',
            attributes: ['id', 'first_name', 'last_name', 'middle_name'],
          },
          {
            association: 'contacts',
            attributes: ['id', 'first_name', 'last_name'],
          },
          {
            association: 'tags',
            attributes: ['id', 'name', 'namespace_id'],
            include: [
              {
                association: 'namespace',
                attributes: ['id', 'name'],
              },
            ],
          },
          {
            association: 'location',
            attributes: [
              'id',
              'name',
              'address1',
              'address2',
              'city',
              'state',
              'postal_code',
            ],
            include: [
              {
                association: 'organization',
                attributes: ['id'],
              },
            ],
            required: false,
          },
        ],
      },
    },
  },
});

export const upsertProviderComment = (data, nextAction) => {
  let options = {
    include: [],
  };
  if (data.providers) {
    options.include.push({ model: 'Providers', as: 'providers' });
  }
  if (data.contacts) {
    options.include.push({ model: 'Contacts', as: 'contacts' });
  }
  if (data.tags) {
    options.include.push({ model: 'Tags', as: 'tags' });
  }

  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      ...options,
      where: { id },
    };
  }

  return {
    type: API_PROVIDER_COMMENTS_UPSERT,
    id,
    data,
    request: {
      url: '/ProviderComments',
      method: 'PUT',
      params: { opts: options },
      data,
    },
    nextAction: nextAction ?? ((data.provider_id || data.contact_id)
        ? listProviderComments(
            data.provider_id,
            data.contact_id,
            null,
            data.client_id
          )
        : null),
  };
};

export const listProviderCommentsByTag = (
  tagId 
) => {
  const include = [
    {
      association: 'user',
      attributes: ['id', 'first_name', 'last_name'],
    },
    {
      association: 'tags',
      attributes: ['id', 'name', 'namespace_id'],
      include: [
        {
          association: 'namespace',
          attributes: ['id', 'name'],
        },
      ],
      where: {
        'id': tagId
      },
    },
    {
      association: 'location',
      attributes: [
        'id',
        'name',
        'address1',
        'address2',
        'city',
        'state',
        'postal_code',
      ],
      include: [
        {
          association: 'organization',
          attributes: ['id'],
        },
      ],
    },
  ];

  return queryProviderComments(
    {
      order: [['comment_date', 'DESC']],
      include,
    },
    API_PROVIDER_COMMENTS_LIST
  );
};

export const deleteProviderComment = (id) => {
  return {
    type: API_PROVIDER_COMMENTS_DELETE,
    id,
    request: {
      url: '/ProviderComments/',
      method: 'DELETE',
      data: [id],
    },
  };
};
