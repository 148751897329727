export const API_DOCUMENTS_TABLE = 'API_DOCUMENTS_TABLE';
export const API_DOCUMENTS_LIST = 'API_DOCUMENTS_LIST';
export const API_DOCUMENTS_UPSERT = 'API_DOCUMENTS_UPSERT';
export const API_DOCUMENTS_DELETE = 'API_DOCUMENTS_DELETE';

export const queryDocuments = (options, type = API_DOCUMENTS_TABLE) => ({
  type,
  request: {
    url: '/Documents',
    method: 'GET',
    params: { opts: options },
  },
});

export const listDocuments = () => (queryDocuments({
  order: [
    ['name', 'ASC'],
  ],
}, API_DOCUMENTS_LIST));

export const upsertDocument = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  return {
    type: API_DOCUMENTS_UPSERT,
    id,
    request: {
      url: '/Documents',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};

export const deleteDocument = (id, query) => ({
    type: API_DOCUMENTS_DELETE,
    id,
    request: {
      url: '/Documents',
      method: 'DELETE',
      params: { opts: null },
      data: [id],
    },
    nextAction: queryDocuments(query),
});
