import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { withRouter } from 'utils';
import { Button, Icon } from '@mui/material';
import {
  StoreUtil,
  PhoneUtil,
} from 'doctivity-shared/utils';

import {
  DialogForm,
  LoadingView,
} from 'components';

import {
  deleteContact,
} from 'store/actions/contactsActions';
import {
  listUsers,
} from 'store/actions/usersActions';
import {
  listProviderComments
} from 'store/actions/providerCommentsActions';

import { ContactDeleteDialog } from './ContactDeleteDialog';

const styles = (theme) => ({
  deleteIcon: {
    color: theme.palette.primary.main,
  },
  button: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1),
  },
});

const schema = {
  type: 'object',
  required: ['first_name', 'last_name'],
  properties: {
    first_name: { type: 'string', title: 'First Name' },
    last_name: { type: 'string', title: 'Last Name' },
    title: { type: 'string', title: 'Title' },
    company: { type: 'string', title: 'Company', maxLength: 75 },
    email: { type: 'string', title: 'Email' },
    phone: {
      type: 'string',
      title: 'Phone',
      mask: 'phone-ext',
    },
    mobile_phone: {
      type: 'string',
      title: 'Mobile',
      mask: 'phone-ext',
    },
    fax: {
      type: 'string',
      title: 'Fax',
      mask: 'phone-ext',
    },
    owner_user_id: {
      type: 'number',
      title: 'Owner',
      enumNames: [],
      enum: [],
    },
  },
};

const uiSchema = {
  first_name: {
    classNames: 'two-column-field',
  },
  last_name: {
    classNames: 'two-column-field',
  },
  title: {
    classNames: 'two-column-field',
  },
  company: {
    classNames: 'two-column-field',
  },
  email: {
    classNames: 'two-column-field',
  },
  phone: {
    classNames: 'two-column-field',
  },
  mobile_phone: {
    classNames: 'two-column-field',
  },
  fax: {
    classNames: 'two-column-field',
  },
  owner_user_id: {
    classNames: 'two-column-field',
  },
};

const NEW_CONTACT = {
};

class ContactEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteDialogOpen: false,
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadLongCache(this.props.users)
      || (this.props.usersClientId !== this.props.app.selectedClient)) {
      this.fetchUsers();
    }

    this.fetchProviderComments();
  }

  componentDidUpdate(prevProps) {
    const {
      providerComments
    } = this.props;

    if (StoreUtil.needsLoadLongCache(this.props.users)
      || (this.props.usersClientId !== this.props.app.selectedClient)) {
      this.fetchUsers();
    }

    if(!providerComments ||
      this.props.contactData?.id !== prevProps?.contactData?.id || 
      (providerComments.data?.length !== 0 &&
         StoreUtil.needsLoadLongCache(providerComments))) {
      this.fetchProviderComments();
    }
  }

  fetchUsers() {
    this.props.dispatch(listUsers(this.props.app.selectedClient));
  }

  fetchProviderComments() {
    const contactId = this.getContactId();
    if(contactId) {
      this.props.dispatch(
        listProviderComments(
          null,
          contactId,
          null,
          this.props.app.selectedClient
        )
      );
    }
  }

  getContactId() {
    const {
      contactData
    } = this.props;

    return contactData ? parseInt(contactData.id, 10) : null;
  }

  render() {
    const { classes, open, contactData, providerComments, submitText } = this.props;

    if (StoreUtil.isLoaded(this.props.users)) {
      const users = StoreUtil.getData(this.props.users);
      schema.properties.owner_user_id = {
        ...schema.properties.owner_user_id,
        enum: users.map((u) => u.id),
        enumNames: users.map((u) => `${u.last_name}, ${u.first_name}`),
      };
    } else {
      return <LoadingView />;
    }

    if (schema.properties.owner_user_id.enum) {
      if (this.props.user && !this.props.user.is_admin) {
        NEW_CONTACT.owner_user_id = this.props.user.id;
      }
    }

    return (
      <div>
        <DialogForm
          title={
            contactData && contactData.id > 0 ? 'Edit Contact' : 'Add Contact'
          }
          open={open}
          formData={contactData || NEW_CONTACT}
          schema={schema}
          uiSchema={uiSchema}
          onClose={this.props.onClose}
          onFormSubmit={this.onSave}
          onSecondarySubmit={this.onOpenDeleteDialog}
          submitText={submitText}
          secondaryText={providerComments?.data?.length === 0 ? <Button
          className={classes.button}
          color='secondary'
          margin='normal'
          variant='text'
          onClick={this.onOpenDeleteDialog}
        >
          <Icon className={classes.deleteIcon}>delete</Icon>
        </Button> : undefined }
          status={
            this.state.userDialogError ? this.state.userDialogErrorMessage : ''
          }
          statusIsError
        ></DialogForm>
        {this.state.deleteDialogOpen && (
          <ContactDeleteDialog
            open={this.state.deleteDialogOpen}
            onDelete={this.onDeleteContact}
            onClose={this.onCloseDeleteDialog}
            contactData={contactData}
          />
        )}
      </div>
    );
  }

  onOpenDeleteDialog = () => {
    this.setState({
      deleteDialogOpen: true,
    });
  };

  onCloseDeleteDialog = () => {
    this.setState({
      deleteDialogOpen: false,
    });
  };

  onDeleteContact = (contact) => {
    this.props.dispatch(deleteContact(contact.id));
    this.onCloseDeleteDialog();
    this.props.router.navigate(-1);
  }

  onSave = (data) => {
    if (data.phone) {
      data.phone = PhoneUtil.unformat(data.phone);
      if (!data.phone || !PhoneUtil.isValid(data.phone)) {
        this.setState({
          userDialogError: true,
          userDialogErrorMessage: 'Invalid phone number.',
        });
        return;
      }
    }
    if (data.mobile_phone) {
      data.mobile_phone = PhoneUtil.unformat(data.mobile_phone);
      if (!data.mobile_phone || !PhoneUtil.isValid(data.mobile_phone)) {
        this.setState({
          userDialogError: true,
          userDialogErrorMessage: 'Invalid mobile phone number.',
        });
        return;
      }
    }

    this.props.onSave(data);
  };
}

ContactEditDialog.propTypes = {
  router: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submitText: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  contactData: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
  app: PropTypes.object,
  usersClientId: PropTypes.number,
  providerComments: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    users: StoreUtil.get(state.users, StoreUtil.COMMON_LIST),
    usersClientId: state.users.listClientId,
    app: state.app,
    providerComments: StoreUtil.get(state.providerComments, StoreUtil.COMMON_LIST),
  };
}

const styled = withStyles(styles)(ContactEditDialog);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ContactEditDialog };
