import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import { MenuItem, Select } from '@mui/material';
import {
  DataUtil,
} from 'doctivity-shared/utils';
const styles = () => ({
  selectFilter: {
    width: 150,
  },
});

function AffiliationFilterForm(props) {
  const { classes, query, setQuery } = props;
  const [selectedFilter, setSelectedFilter] = useState('all');
  const clientId = useSelector((state) => state.app.selectedClient);
  const onChange = useCallback(
    (event) => {
      setSelectedFilter(event.target.value);
      const newQuery = DataUtil.deepCopy(query);
      let part = newQuery.include.find((i) => i.association === 'clients');
      if (!part) {
        part = {
          association: 'clients',
          attributes: ['id', 'name'],
        };
        newQuery.include.push(part);
      }
      switch (event.target.value) {
        case 'affiliated': {
          part.required = true;
          part.where = { id: clientId };
          break;
        }
        case 'nonaffiliated': {
          // TODO: How do we ask for non-affiliated via the query parameters?
          part.required = false;
          if(!newQuery.where) newQuery.where = {};
          newQuery.where['$clients.id$'] = { '$neq': clientId };
          break;
        }
        case 'all':
        default: {
          newQuery.include = newQuery.include.filter(
            (i) => i.association !== 'clients',
          );
          newQuery.include.push({
            association: 'clients',
            attributes: ['id', 'name'],
          });
          break;
        }
      }
      setQuery(newQuery);
    },
    [selectedFilter, clientId],
  );
  return (
    <Select
      className={classes.selectFilter}
      value={selectedFilter}
      onChange={onChange}
    >
      <MenuItem value='all'>All</MenuItem>
      <MenuItem value='affiliated'>Affiliated</MenuItem>
      {
        // TODO: <MenuItem value='nonaffiliated'>Non-Affiliated</MenuItem>
      }
    </Select>
  );
}

AffiliationFilterForm.propTypes = {
  classes: PropTypes.object,
  query: PropTypes.object,
  setQuery: PropTypes.func,
};

const styled = withStyles(styles)(AffiliationFilterForm);
export { styled as AffiliationFilterForm };
