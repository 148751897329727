import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';

import { AppBar, Toolbar, IconButton } from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';

import withStyles from '@mui/styles/withStyles';
import { withRouter, withIsMobile } from 'utils';
import logo from 'assets/images/logo_small.png';

const styles = (theme) => ({
  appBar: {
    flexGrow: 1,
    marginBottom: 0,
    zIndex: theme.zIndex.drawer - 1,
  },
  menuButton: {
    marginRight: 15,
    color: '#ffffff',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  loggedin: {
    flex: 1,
    textAlign: 'right',
  },
  authContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  clientButton: {
    color: '#ffffff',
    fontWeight: 200,
  },
  rangeContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  rangeControl: {
    minWidth: 120,
    marginBottom: 20,
  },
  breadcrumb: {
    marginLeft: 100,
  },
  logo: {
    padding: 8,
    width: 128,
  },
});

class Header extends React.Component {
  render() {
    const { classes, isMobile } = this.props;

    if (!isMobile) {
      return null;
    }

    return (
      <AppBar position='absolute' className={classes.appBar}>
        <Toolbar style={{ display: 'flex', alignItems: 'center' }}>
          {this.props.onDrawerOpen ? (
            <div style={{ flex: 1 }}>
              <IconButton
                className={classes.menuButton}
                onClick={this.props.onDrawerOpen}
                size='large'
              >
                <MenuIcon />
              </IconButton>
            </div>
          ) : (
            <div style={{ flex: 1 }} />
          )}
          <img src={logo} className={classes.logo} alt='Logo' />
          <div style={{ flex: 1 }} />
        </Toolbar>
      </AppBar>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  app: PropTypes.object,
  onDrawerOpen: PropTypes.func,
  dispatch: PropTypes.func.isRequired,
};

// TODO: Move to components
function mapStateToProps(state) {
  return {
    user: state.user,
    app: state.app,
  };
}

const styled = withStyles(styles)(withIsMobile(Header));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as Header };
