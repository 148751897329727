import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import { withRouter } from 'utils';
import { DialogForm } from 'components';

const styles = (theme) => ({
  dropDownColumn: {
    '&:before': {
      content: '"Allow in:"',
      display: 'inline-block',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      fontFamily: 'Roboto',
      width: '100%',
    }
  },
  optionsColumn: {
    '&:before': {
      content: '"Special Rules:"',
      display: 'inline-block',
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(2),
      fontFamily: 'Roboto',
      width: '100%',
    }
  }
});

const schema = {
  type: 'object',
  required: ['name'],
  properties: {
    name: { type: 'string', title: 'Name', maxLength: 20 },
    activity_notes_drop_down: { type: ['null', 'boolean'], title: 'Activity Notes' },
    providers_drop_down: { type: ['null', 'boolean'], title: 'Providers' },
    organizations_drop_down: { type: ['null', 'boolean'], title: 'Organizations' },
    required: { type: ['null', 'boolean'], title: 'Required' },
    allow_multiple: { type: ['null', 'boolean'], title: 'Allow Multiple' },
  },
};

const getUISchema = (classes) => ({
  'ui:order': ['name', 'activity_notes_drop_down', 'required', 'providers_drop_down', 'allow_multiple', 'organizations_drop_down'], 
  activity_notes_drop_down: {
    classNames: `${classes.dropDownColumn} two-column-field`,
  },
  organizations_drop_down: {
    classNames: 'two-column-field',
  },
  providers_drop_down: {
    classNames: 'two-column-field',
  },
  required: {
    classNames: `${classes.optionsColumn} two-column-field`,
  },
  allow_multiple: {
    classNames: 'two-column-field',
  },
});

class TagNamespaceEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const {
      open,
      tagNamespaceData,
      classes,
    } = this.props;
    const data = {...tagNamespaceData};
    if (tagNamespaceData) {
      data.allow_multiple = tagNamespaceData.activity_notes_multiple || tagNamespaceData.provider_multiple || tagNamespaceData.organization_multiple;
      data.required = tagNamespaceData.activity_notes_required || tagNamespaceData.provider_required || tagNamespaceData.organization_required;
    }

    return (
      <DialogForm
        title={tagNamespaceData && tagNamespaceData.id > 0 ? 'Edit Label Type' : 'Add Label Type'}
        open={open}
        formData={data}
        schema={schema}
        uiSchema={getUISchema(classes)}
        onClose={this.props.onClose}
        onFormSubmit={this.onSave}
      />
    );
  }

  onSave = (data) => {
    const newData = { ...data };
    newData.activity_notes_required = data.required;
    newData.activity_notes_multiple = data.allow_multiple;
    newData.providers_multiple = data.allow_multiple;
    newData.organizations_multiple = data.allow_multiple;
    newData.client_id = this.props.clientId;
    delete newData.required;
    delete newData.allow_multiple;
    this.props.onSave(newData);
  };
}

TagNamespaceEditDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  clientId: PropTypes.number,
  tagNamespaceData: PropTypes.object,
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  const { app } = state;
  return {
    clientId: app.selectedClient,
  };
}

const styled = withStyles(styles)(TagNamespaceEditDialog);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as TagNamespaceEditDialog };
