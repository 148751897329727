import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon, Card, CardContent, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  LoadingView,
  TitleTooltip,
  ProviderCommentsDialog,
  SmallIconTextButton,
  DynamicTable,
} from 'components';

import { withRouter } from 'utils';
import { DateUtil, StoreUtil, EncounterTypeUtil } from 'doctivity-shared/utils';

import {
  listProviderCommentsByTag,
  getProviderComment,
  upsertProviderComment,
} from 'store/actions/providerCommentsActions';

import { showConfirmationDialog } from 'store/actions/systemActions';

const styles = (theme) => ({
  card: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  spacer: {
    flex: 1,
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    borderBottom: '1px solid #cccccc',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'normal',
    },
  },
  commentUser: {
    fontWeight: 800,
    fontSize: 12,
    width: 200,
  },
  needsResolved: {
    fontWeight: 800,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    color: '#2E93fA',
    paddingTop: 2,
  },
  needsResolvedIcon: {
    fontSize: 16,
    paddingRight: 3,
  },
  commentBody: {
    display: 'flex',
  },
  commentSpacing: {
    height: 20,
  },
  commentBodyText: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    whiteSpace: 'pre-wrap',
  },
  commentDate: {
    fontSize: 12,
    color: '#999999',
    width: 110,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  encounterType: {
    fontSize: 12,
    color: '#999999',
    width: 125,
  },
  encounterIcon: {
    fontSize: 16,
    paddingRight: 3,
    position: 'relative',
    top: 3,
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 2,
  },
  actionButton: {
    height: 22,
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
  },
  namespace: {
    fontSize: 12,
    color: '#999999',
    paddingRight: theme.spacing(2),
  },
});

const columns = [
  {
    label: '',
    key: 'encounter_type_id',
    format: (id, row, rowClasses) => (
      <Icon className={rowClasses.encounterIcon}>
        {EncounterTypeUtil.getIcon(id)}
      </Icon>
    ),
    style: { width: 20 },
  },
  {
    label: 'User',
    key: 'user',
    format: (user) => user && `${user.last_name}, ${user.first_name}`,
    style: { width: 150 },
    hideForMobile: true,
  },
  {
    label: 'Note',
    key: 'body',
    sortable: false,
    format: (body) => {
      let shortBody = body;
      if (body && body.length > 100) {
        shortBody = `${body.substring(0, 100)}...`;
      }
      return shortBody;
    },
  },
  {
    label: 'Due Date',
    key: 'due_date',
    type: 'date',
    format: DateUtil.formatDateForUser,
    style: { width: 150 },
  },
  {
    label: 'Date & Time',
    key: 'comment_date',
    type: 'date',
    format: DateUtil.formatDateTimeFromDB,
    style: { width: 150 },
  },
];

class ProviderComments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
      editComment: {},
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.tag.data?.id !== prevProps.tag.data?.id
    ) {
      this.fetchData();
    }

    if (this.props.editComment !== prevProps.editComment) {
      // is the list data older than the edited data?
      if (
        StoreUtil.hasSavedSinceLoad(
          this.props.editComment,
          this.props.providerComments
        )
      ) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const { dispatch, tag } = this.props;
    const { id: tagId } = tag
    dispatch(
      listProviderCommentsByTag(
        tagId
      )
    );
  }

  render() {
    const { dialogOpen, editComment } = this.state;

    const {
      classes,
      providerComments,
      clientId,
      tag,
      comments,
    } = this.props;

    let commentData = editComment;
    if (editComment && editComment.id && comments && comments[editComment.id]) {
      const data = StoreUtil.getData(comments[editComment.id]);
      if (data && data.id) {
        commentData = data;
      }
    }
    let notes;
    if (
      !providerComments ||
      StoreUtil.isLoading(providerComments) ||
      StoreUtil.isSaving(editComment)
    ) {
      notes = <LoadingView />;
    } else {
      const data = StoreUtil.getData(providerComments);
      if (data && data.length > 0) {
        notes = (
          <DynamicTable
            columns={columns}
            data={data}
            showFilters={false}
            ContainerType={React.Fragment}
            onRowClick={this.onRowClick}
            rowClasses={classes}
          />
        );
      } else {
        notes = (
          <Typography className={classes.nothingFound}>
            No available activity.
          </Typography>
        );
      }
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <TitleTooltip
              title='Activity Notes'
              tooltip='An area to write important information relating to the affiliated Doctivity provider that can be viewed by all users. Entries can include physician liaison visits, market intelligence, marketing campaigns, and operational feedback.'
            />
            <div className={classes.spacer} />
            <SmallIconTextButton
              icon='add'
              text='Activity Note'
              onClick={this.openDialog}
            />
          </div>

          {notes}

          {dialogOpen && (
            <ProviderCommentsDialog
              clientId={clientId}
              tag={tag}
              data={commentData}
              open={dialogOpen}
              onSave={this.onSave}
              onClose={this.closeDialog}
            />
          )}
        </CardContent>
      </Card>
    );
  }

  openDialog = () => {
    if (this.props.user.is_admin) {
      this.props.dispatch(
        showConfirmationDialog({
          title: 'Admin Users Can Not Create Notes',
          content: 'Login as a non-admin user to create activity notes.',
          confirmLabel: 'OK',
          onConfirm: () => {
            // do nothing
          },
        })
      );
    } else {
      this.setState({
        dialogOpen: true,
        editComment: { body: '' },
      });
    }
  };

  editComment = (comment) => {
    const { dispatch } = this.props;

    dispatch(getProviderComment(comment.id)); // load all providers

    this.setState({
      dialogOpen: true,
      editComment: comment,
    });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  onSave = (data) => {
    const { tag, clientId, user, dispatch } = this.props;
    const { id: tagId } = tag
    
    if (data.body && data.body.length > 0) {
      data.client_id = clientId;
      data.user_id = user.id;
      dispatch(upsertProviderComment(data, listProviderCommentsByTag(tagId)));
    }
    this.closeDialog();
  };

  onRowClick = (row) => {
    this.props.router.navigate(`/notes/${row.id}`);
  };
}

ProviderComments.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  tag: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  providerComments: PropTypes.object,
  comments: PropTypes.object,
  editComment: PropTypes.object,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  const { user, providerComments, app } = state;

  return {
    user,
    providerComments: StoreUtil.get(providerComments, StoreUtil.COMMON_LIST),
    editComment: StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
    comments: providerComments.item,
    clientId: app.selectedClient
  };
}

const styled = withStyles(styles)(ProviderComments);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsByTag };
