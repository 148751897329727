import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_RVU_BENCHMARKS_TABLE,
  API_RVU_BENCHMARKS_LIST,
  API_RVU_BENCHMARKS_UPSERT,
} from 'store/actions/rvuBenchmarksActions';

export const rvuBenchmarks = (state = {}, action = null) => {
  const result = ReducerUtil.simpleReduce(
    state,
    action,
    API_RVU_BENCHMARKS_TABLE,
    API_RVU_BENCHMARKS_LIST,
    API_RVU_BENCHMARKS_UPSERT,
  );
  if (result.actionConsumed) {
    return result.state;
  }
  return state;
};
