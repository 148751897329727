import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import withStyles from '@mui/styles/withStyles';
import { Card, Typography, CardContent, Button, Icon } from '@mui/material';
import PropTypes from 'prop-types';

import { StoreUtil } from 'doctivity-shared/utils';
import { TableUtil, LocationsUtil, withRouter } from 'utils';

import { DynamicTable, LoadingView } from 'components';
import {
  LocationEditDialog,
  FindLocationDialog,
  PreventAddLocationDialog,
  ConfirmAddLocationDialog,
  ChangePrimaryLocationDialog,
} from 'locations';

import { queryLocations, upsertLocation } from 'store/actions/locationsActions';
import { upsertOrganization } from 'store/actions/organizationsActions';
import { loadAnalytics } from 'store/actions/analyticsActions';

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Name',
    key: 'name',
    fulltext: true,
  },
  {
    label: 'Address',
    key: 'address1',
    style: { minWidth: 120 },
    format: (address1, location) =>
      location.address2 ? address1 + ', ' + location.address2 : address1,
    filterFormatter: (value, committed) => {
      if (committed) {
        return LocationsUtil.formatAddress1(value);
      }
      return value;
    },
  },
  {
    label: 'City',
    key: 'city',
    fulltext: true,
  },
  {
    label: 'State',
    key: 'state',
    style: { width: 50 },
    filterExact: true,
    autoCapitalize: true,
  },
  {
    label: 'Organization',
    key: 'organization.name',
    fulltext: true,
    style: { width: 320 },
  },
  {
    label: 'Providers',
    key: 'provider_count',
    style: { width: 50 },
    filter: false,
    sortable: false,
    format: (count) => count.toLocaleString(),
  },
  {
    label: 'Contacts',
    key: 'contact_count',
    style: { width: 50 },
    filter: false,
    sortable: false,
    format: (count) => count.toLocaleString(),
  },
  {
    label: 'Primary Location',
    key: 'organization.primary_location_id',
    format: (primaryLocationId, location) =>
      TableUtil.boolToCheckIcon(primaryLocationId === location.id),
    style: { width: 120 },
    filter: false,
    sortable: true,
  },
];

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  dialogSubtitle: {
    color: '#00A887',
  },
  addLocationButton: {
    marginRight: theme.spacing(1),
  },
});

/**
 * Container for a table that shows all Locations at a given Organization
 */
class LocationsTable extends Component {
  constructor(props) {
    super(props);

    const { organization } = props;

    let where = {};

    if (organization && organization.id) {
      where = { id: organization.id };
    }

    this.state = {
      query: {
        attributes: [
          'id',
          'name',
          'address1',
          'address2',
          'city',
          'state',
          'postal_code',
        ],
        where: {},
        include: [
          {
            association: 'organization',
            attributes: ['id', 'name', 'primary_location_id'],
            required: !!organization,
            where,
            paranoid: false,
          },
        ],
        paranoid: false,
        limit: 25,
        offset: 0,
        order: organization
          ? [
            ['organization', 'primary_location_id', 'DESC'],
            ['id', 'ASC'],
          ]
          : undefined,
      },
      linkLocationOpen: false,
      changePrimaryLocationOpen: false,
      createLocationOpen: false,
      confirmAddLocationData: undefined,
      preventAddLocationData: undefined,
      showLoading: true,
    };
  }

  componentDidMount() {
    const { locations, locationCounts } = this.props;


    const oldQuery = sessionStorage.getItem('locationsTableQuery')
    if (oldQuery) {
      this.onQueryChange(JSON.parse(oldQuery))
    }

    if (
      StoreUtil.needsLoadNoCache(locationCounts) ||
      StoreUtil.needsLoadNoCache(locations)
    ) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      clientId,
      locations,
      organization,
      locationCounts,
      editingLocation,
      router,
    } = this.props;

    const didJustSave = StoreUtil.hasSavedSinceUpdate(
      editingLocation,
      prevProps.editingLocation
    );

    if (!organization && didJustSave && editingLocation?.data?.id) {
      router.navigate(`/locations/${editingLocation.data.id}`);
    } else if (
      clientId !== prevProps.clientId ||
      didJustSave ||
      StoreUtil.hasSavedSinceLoad(locations, prevProps.locations) ||
      StoreUtil.hasSavedSinceLoad(locationCounts, prevProps.locationCounts) ||
      (organization && (!prevProps.organization || prevProps.organization.id !== organization.id))
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { clientId, dispatch, organization } = this.props;

    const { query } = this.state;

    query.include.forEach((include) => {
      if (include.association === 'organization') {
        if (organization && organization.id) {
          // ignore all other filters of organization...
          include.where = { id: organization.id };
          include.required = true;

        } else {
          if (include.where && Object.keys(include.where).length > 0) {
            if (include.where.id) {
              delete include.where.id;
            }
            if (Object.keys(include.where).length > 0) {
              include.required = true;
            } else {
              include.required = false;
            }
          } else {
            include.required = false;
          }
        }
      }
    });

    console.log(query);
    organization
      ? dispatch(
        loadAnalytics({
          type: 'LOCATIONS_PROVIDERS_CONTACTS_COUNT',
          opts: query,
          filter: {
            client_id: clientId,
          },
        })
      )
      : dispatch(queryLocations(query));
  }

  render() {
    const { classes, organization, locations, locationCounts, user } =
      this.props;

    const {
      query,
      linkLocationOpen,
      changePrimaryLocationOpen,
      createLocationOpen,
      preventAddLocationData,
      confirmAddLocationData,
      showLoading,
    } = this.state;

    const modifiedColumns = organization
      ? columns.filter((column) => column.label !== 'Organization')
      : columns.slice(0, 5);

    if (
      showLoading &&
      ((!organization && StoreUtil.isLoading(locations)) ||
        (organization && StoreUtil.isLoading(locationCounts)))
    ) {
      return <LoadingView />;
    }

    return (
      <>
        {!organization && (
          <Helmet defer={false}>
            <title>Locations</title>
          </Helmet>
        )}
        <div className={!organization ? classes.container : undefined}>
          <Card>
            <CardContent>
              <DynamicTable
                header={
                  <div className={classes.headerContainer}>
                    <Typography className={classes.title}>
                      {organization ? 'Locations' : 'All Locations'}
                    </Typography>
                    {organization ? (
                      user.is_admin ? (
                        <div>
                          <Button
                            color='primary'
                            size='small'
                            className={classes.addLocationButton}
                            onClick={this.onOpenLinkLocation}
                          >
                            <Icon className={classes.leftIcon}>add</Icon>
                            Add Location
                          </Button>
                          <Button
                            color='primary'
                            size='small'
                            onClick={this.onOpenChangePrimaryLocation}
                          >
                            <Icon className={classes.leftIcon}>edit</Icon>
                            Change Primary
                          </Button>
                        </div>
                      ) : (
                        <Button
                          color='primary'
                          size='small'
                          className={classes.addLocationButton}
                          onClick={this.onOpenLinkLocation}
                        >
                          <Icon className={classes.leftIcon}>add</Icon>
                          Add Location
                        </Button>
                      )
                    ) : (
                      user.is_admin && (
                        <Button
                          color='primary'
                          size='small'
                          onClick={
                            organization
                              ? this.onOpenLinkLocation
                              : this.onOpenCreateLocation
                          }
                        >
                          <Icon className={classes.leftIcon}>add</Icon>
                          Add Location
                        </Button>
                      )
                    )}
                  </div>
                }
                columns={modifiedColumns}
                query={query}
                data={organization ? locationCounts : locations}
                noDataMessage='No locations found.'
                onQueryChange={this.onQueryChange}
                getRowHref={this.getRowHref}
                ContainerType={React.Fragment}
              />
              {linkLocationOpen && organization && (
                <FindLocationDialog
                  onClose={this.closeLinkDialog}
                  onSelect={this.onLinkLocationSelected}
                  open={linkLocationOpen}
                  organization={organization}
                />
              )}
              {changePrimaryLocationOpen && organization && (
                <ChangePrimaryLocationDialog
                  onClose={this.closeChangePrimaryDialog}
                  onSelect={this.onChangePrimaryLocationSelected}
                  open={changePrimaryLocationOpen}
                  organization={organization}
                />
              )}
              {confirmAddLocationData && organization && (
                <ConfirmAddLocationDialog
                  open={!!confirmAddLocationData}
                  locationData={confirmAddLocationData}
                  organization={organization}
                  onConfirm={this.onConfirmAddLocation}
                  onClose={this.closeConfirmAddDialog}
                />
              )}
              {preventAddLocationData && organization && (
                <PreventAddLocationDialog
                  open={!!preventAddLocationData}
                  locationData={preventAddLocationData}
                  onClose={this.closePreventAddDialog}
                />
              )}
              <LocationEditDialog
                open={createLocationOpen}
                onClose={this.closeCreateLocationDialog}
                onSave={this.onCreateLocation}
              />
            </CardContent>
          </Card>
        </div>
      </>
    );
  }

  closeLinkDialog = () => {
    this.setState({ linkLocationOpen: false }, this.fetchData.bind(this));
  };

  closeChangePrimaryDialog = () => {
    this.setState(
      { changePrimaryLocationOpen: false },
      this.fetchData.bind(this)
    );
  };

  closeConfirmAddDialog = () => {
    this.setState({ confirmAddLocationData: undefined });
  };

  closePreventAddDialog = () => {
    this.setState({ preventAddLocationData: undefined });
  };

  closeCreateLocationDialog = () => {
    this.setState({ createLocationOpen: false }, this.fetchData.bind(this));
  };

  onQueryChange = (query) => {
    this.setState({ query, showLoading: false }, this.fetchData.bind(this));
    sessionStorage.setItem('locationsTableQuery', JSON.stringify(query))
  };

  getRowHref = (row) => {
    return `/locations/${row.id}`;
  };

  onOpenLinkLocation = () => {
    if (this.props.organization) {
      this.setState({ linkLocationOpen: true });
    }
  };

  onOpenChangePrimaryLocation = () => {
    if (this.props.organization) {
      this.setState({ changePrimaryLocationOpen: true });
    }
  };

  onConfirmAddLocation = (location) => {
    const { organization, dispatch } = this.props;

    this.closeLinkDialog();
    dispatch(
      upsertLocation({
        ...location,
        organization_id: organization.id,
      })
    );
    this.closeLinkDialog();
  };

  onLinkLocationSelected = (location) => {
    if (this.props.organization) {
      if (location.organization_id) {
        this.setState({ preventAddLocationData: location });
      } else {
        this.setState({ confirmAddLocationData: location });
      }
    }
  };

  onChangePrimaryLocationSelected = (location) => {
    const { dispatch, organization } = this.props;
    if (this.props.organization && location.id) {
      dispatch(
        upsertOrganization({
          ...organization,
          primary_location_id: location.id,
        })
      );
    }
  };

  onOpenCreateLocation = () => {
    this.setState({ createLocationOpen: true });
  };

  onCreateLocation = (data) => {
    const { dispatch, user } = this.props;

    if (user.is_admin) {
      dispatch(upsertLocation(data));
    }

    this.setState({
      createLocationOpen: false,
    });
  };
}

LocationsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  user: PropTypes.object,
  locations: PropTypes.object,
  locationCounts: PropTypes.object,
  organization: PropTypes.object,
  editingLocation: PropTypes.object,
};

function mapStateToProps(state) {
  const { app, analytics, locations, user } = state;

  return {
    clientId: app.selectedClient,
    user,
    locations: StoreUtil.get(locations, StoreUtil.COMMON_TABLE),
    locationCounts: StoreUtil.get(
      analytics,
      'LOCATIONS_PROVIDERS_CONTACTS_COUNT'
    ),
    editingLocation: StoreUtil.get(locations, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(LocationsTable);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as LocationsTable };
