import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';

// TODO: Combine with index.js
import { user } from 'store/reducers/userReducer';
import { users } from 'store/reducers/usersReducer';
import { clients } from 'store/reducers/clientsReducer';
import { system } from 'store/reducers/systemReducer';
import { activityLogs } from 'store/reducers/activityLogsReducer';
import { providers } from 'store/reducers/providersReducer';
import { app } from 'store/reducers/appReducer';
import { uploads } from 'store/reducers/uploadsReducer';
import { taxonomy } from 'store/reducers/taxonomyReducer';
import { providerComments } from 'store/reducers/providerCommentsReducer';
import { providerReports } from 'store/reducers/providerReportsReducer';
import { scorecards } from 'store/reducers/scorecardsReducer';
import { analytics } from 'store/reducers/analyticsReducer';
import { procedures } from 'store/reducers/proceduresReducer';
import { visits } from 'store/reducers/visitsReducer';
import { documents } from 'store/reducers/documentsReducer';
import { rvuBenchmarks } from 'store/reducers/rvuBenchmarksReducer';
import { claims } from 'store/reducers/claimsReducer';
import { organizations } from 'store/reducers/organizationsReducer';
import { contacts } from 'store/reducers/contactsReducer';
import { counties } from 'store/reducers/countiesReducer';
import { favorites } from 'store/reducers/favoritesReducer';
import { markets } from 'store/reducers/marketsReducer';
import { locations } from 'store/reducers/locationsReducer';
import { tags } from 'store/reducers/tagsReducer';
import { tagNamespaces } from 'store/reducers/tagNamespacesReducer';
import { codes } from 'store/reducers/codesReducer';
import { loginSettings } from 'store/reducers/loginSettingsReducer';
import { providerTags } from 'store/reducers/providerTagsReducer';
import { organizationTags } from 'store/reducers/organizationTagsReducer';
import { dashboard } from 'store/reducers/dashboardReducer';
import { migrations } from 'store/reducers/migrationsReducer';

export default () => combineReducers({
  // router: connectRouter(history),
  app,
  user,
  users,
  clients,
  providers,
  system,
  activityLogs,
  providerComments,
  providerReports,
  uploads,
  taxonomy,
  scorecards,
  analytics,
  procedures,
  visits,
  documents,
  rvuBenchmarks,
  claims,
  organizations,
  contacts,
  counties,
  favorites,
  markets,
  locations,
  tags,
  tagNamespaces,
  codes,
  loginSettings,
  providerTags,
  organizationTags,
  dashboard,
  migrations,
});
