import {
  StoreUtil,
} from 'doctivity-shared/utils';

class FavoritesUtil {
  static getProvidersFromFavorites(favorites, clientId, userId) {
    const favoriteProviders = StoreUtil.get(
      favorites,
      'by_type',
      `${clientId}-${userId}-Providers`
    );

    if (
      favoriteProviders?.data?.rows?.length > 0 &&
      favoriteProviders.data.rows[0].provider
    ) {
      const newRows =
        favoriteProviders.data?.rows?.map((row) => row.provider) || [];
      const newFavoriteProviders = {
        ...favoriteProviders,
        data: {
          ...favoriteProviders.data,
          rows: newRows,
        },
      };
      return newRows.length > 0 ? newFavoriteProviders : undefined;
    }

    return favoriteProviders;
  }

  static getLocationsFromFavorites(favorites, clientId, userId) {
    const favoriteLocations = StoreUtil.get(
      favorites,
      'by_type',
      `${clientId}-${userId}-Locations`
    );

    if (
      favoriteLocations?.data?.rows?.length > 0 &&
      favoriteLocations.data.rows[0].location
    ) {
      const newRows =
        favoriteLocations.data?.rows?.map((row) => row.location) || [];
      const newFavoriteProviders = {
        ...favoriteLocations,
        data: {
          ...favoriteLocations.data,
          rows: newRows,
        },
      };
      return newRows.length > 0 ? newFavoriteProviders : undefined;
    }

    return favoriteLocations;
  }
}
export { FavoritesUtil };
export default FavoritesUtil;
