import React from 'react';
import withStyles from '@mui/styles/withStyles';
import TagIconImage from 'assets/images/tag_icon.svg';
import { PropTypes } from 'prop-types';

const styles = (theme) => ({
  tagIcon: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: 28,
    color: theme.palette.primary.main,
  },
});


function TagIcon({ classes }) {
  return (<img
            src={TagIconImage}
            alt='Label Icon'
            className={classes.tagIcon}
          />);
}
TagIcon.propTypes = {
  classes: PropTypes.object  
};
const styled = withStyles(styles)(TagIcon);
export { styled as TagIcon }
