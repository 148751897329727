import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  header: {
    backgroundColor: '#FAFAFA',
    zIndex: 9,
    transition: 'top 0.5s ease-in-out',
  },
});

class StickyHeader extends React.Component {
  constructor(props) {
    super(props);

    this.headerElement = React.createRef(null);

    this.state = {
      scrollDirection: 'down',
      lastScrollInflection: 0,
      lastScrollTop: 0,
      stickyTab: undefined,
    };
  }

  componentDidMount() {
    document.body.addEventListener('scroll', this.updateScrollDirection, true);
  }

  componentWillUnmount() {
    document.body.removeEventListener('scroll', this.updateScrollDirection);
  }

  render() {
    const { classes, children, headerTop } = this.props;
    const { stickyTab } = this.state;

    const isSticky = stickyTab === undefined ? true : stickyTab ? true : false;

    return (
      <div
        className={classes.header}
        style={{
          top:
            stickyTab === undefined ? undefined : stickyTab ? headerTop : -100,
          opacity: isSticky ? 1 : 0,
          position: stickyTab ? 'sticky' : 'relative',
        }}
        ref={this.headerElement}
      >
        {children}
      </div>
    );
  }

  updateScrollDirection = () => {
    const { onChangeIsSticky } = this.props;
    const { scrollDirection, lastScrollInflection, lastScrollTop } = this.state;
    const scrollTop = document.querySelector('main').scrollTop;

    const newState = {};
    if (scrollTop > lastScrollTop) {
      if (scrollDirection !== 'down') {
        newState.scrollDirection = 'down';
      }
      if (scrollTop > 128) {
        newState.stickyTab = false;
      }
      newState.lastScrollInflection = scrollTop;
    } else if (scrollTop < lastScrollTop) {
      if (scrollDirection !== 'up') {
        newState.scrollDirection = 'up';
        newState.lastScrollInflection = scrollTop;
      }
      if (scrollTop < lastScrollInflection - 50) {
        newState.stickyTab = true;
      }
    }

    if (newState.stickyTab !== undefined) {
      onChangeIsSticky?.(newState.stickyTab);
    }

    newState.lastScrollTop = scrollTop;

    this.setState({ ...newState });
  };
}

StickyHeader.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  headerTop: PropTypes.number,
  onChangeIsSticky: PropTypes.func,
};

const styled = withStyles(styles)(StickyHeader);
export { styled as StickyHeader };
