export const API_ACTIVITYLOG_TABLE = 'API_ACTIVITYLOG_TABLE';
export const API_ACTIVITYLOG_LIST = 'API_ACTIVITYLOG_LIST';
export const API_ACTIVITYLOG_UPSERT = 'API_ACTIVITYLOG_UPSERT';
export const API_ACTIVITYLOG_BY_USER = 'API_ACTIVITYLOG_BY_USER';

export const activityLogsByUserId = (userId, model, clientId, options = {}) => {
  const {
    where: typeWhere, include: typeInclude, attributes: typeAttributes, ...remainingOptions
  } = options;
  const include = [
    {
      association: model.toLowerCase().substring(0, model.length - 1),
      where: {
        client_id: model === 'Contacts' ? clientId : undefined,
        ...typeWhere,
      },
      required: true,
      include: typeInclude,
      attributes: typeAttributes,
    },
  ];

  return {
    type: API_ACTIVITYLOG_BY_USER,
    id: `${model}_${userId}`,
    request: {
      url: '/ActivityLogs',
      method: 'GET',
      params: {
        opts: {
          attributes: [
            'object_id',
            { $max: { col: 'ActivityLogs.created_at', alias: 'last_viewed' } },
          ],
          where: {
            model,
            user_id: userId,
          },
          include,
          group: ['object_id'],
          limit: 10,
          offset: 0,
          ...remainingOptions,
          order: [['last_viewed', 'DESC']],
        },
      },
    },
  };
};
export const queryActivityLogs = (options, type = API_ACTIVITYLOG_TABLE) => ({
  type,
  request: {
    url: '/ActivityLogs',
    method: 'GET',
    params: { opts: options },
  },
});

export const listActivityLogs = () => (queryActivityLogs({
  order: [
    ['name', 'ASC'],
  ],
}, API_ACTIVITYLOG_LIST));

export const upsertActivityLog = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  return {
    type: API_ACTIVITYLOG_UPSERT,
    id,
    request: {
      url: '/ActivityLogs',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};
