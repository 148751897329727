export const API_RVU_BENCHMARKS_TABLE = 'API_RVU_BENCHMARKS_TABLE';
export const API_RVU_BENCHMARKS_LIST = 'API_RVU_BENCHMARKS_LIST';
export const API_RVU_BENCHMARKS_UPSERT = 'API_RVU_BENCHMARKS_UPSERT';

export const queryRVUBenchmarks = (options, type = API_RVU_BENCHMARKS_TABLE) => ({
  type,
  request: {
    url: '/RVUBenchmarks',
    method: 'GET',
    params: { opts: options },
  },
});

export const listRVUBenchmarks = () => (queryRVUBenchmarks({
  order: [
    ['last_name', 'ASC'],
  ],
}, API_RVU_BENCHMARKS_LIST));

export const upsertRVUBenchmark = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  return {
    type: API_RVU_BENCHMARKS_UPSERT,
    id,
    request: {
      url: '/RVUBenchmarks',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};
