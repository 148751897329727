import React from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Typography,
  Icon,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  ProviderScorecardDialog,
  LoadingView,
  TitleTooltip,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  listScorecards,
  upsertScorecards,
} from 'store/actions/scorecardsActions';

import {
  loadAnalytics,
} from 'store/actions/analyticsActions';

const styles = (theme) => ({
  card: {
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
  benchmarkGroup: {
    // float: 'left',
  },
  benchmarks: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  benchmarkHeader: {
    fontWeight: 300,
    fontSize: 14,
    marginTop: 25,
    marginLeft: 45,
  },
  benchmarkTitle: {
    fontWeight: 300,
    fontSize: 10,
    marginLeft: 75,
  },
  benchmarkValue: {
    fontWeight: 500,
    marginLeft: 75,
    fontSize: 24,
    height: 28,
    color: '#777777',
  },
});

class ProviderScorecard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dialogOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.providerId !== prevProps.providerId
      || this.props.clientId !== prevProps.clientId) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      dispatch,
      providerId,
      clientId,
    } = this.props;

    dispatch(listScorecards(providerId, clientId));
    this.fetchBenchmarks();
  }

  fetchBenchmarks() {
    const {
      dispatch,
      specialty,
      state,
      clientId,
    } = this.props;

    if (specialty && state) {
      dispatch(loadAnalytics({
        type: 'RVU_BENCHMARK',
        filter: {
          taxonomy_code: specialty,
          state,
          client_id: clientId, // required for regular user for access validation
        },
      }));
    }
  }

  render() {
    const {
      dialogOpen,
    } = this.state;

    const {
      classes,
      user,
      scorecards,
      providerId,
      clientId,
      app,
      rvuBenchmarks,
      specialty,
      state,
      rvuGoal,
      providerName,
    } = this.props;

    let graph;
    if (!scorecards
      || StoreUtil.isLoading(scorecards)) {
      graph = (<LoadingView />);
    } else {
      const data = StoreUtil.getData(scorecards);
      if (data && data.length > 0) {
        const seriesByMonth = {
          or_cases: [],
          total_rvu: [],
          rvu_ranking: [],
        };
        const seriesKeys = Object.keys(seriesByMonth);
        const months = [];

        const end = moment(app.dateRange.end);
        const d = end.clone().subtract(1, 'year');

        while (!d.isAfter(end)) {
          months.push(d.format('MMM YY'));
          const scorecard = data.find((s) => (s.year === d.year() && s.month === d.month() + 1));
          seriesKeys.forEach((key) => {
            seriesByMonth[key].push(scorecard && scorecard[key] ? Math.round(Number(scorecard[key])) : 0);
          });
          d.add(1, 'month').endOf('month');
        }

        console.log(seriesByMonth);

        graph = (
          <Chart
            type='line'
            height={225}
            series={[
              {
                name: 'OR Cases',
                data: seriesByMonth.or_cases,
                type: 'column',
              },
              {
                name: 'Total RVUs',
                data: seriesByMonth.total_rvu,
                type: 'line',
              },
              {
                name: 'RVU Rank',
                data: seriesByMonth.rvu_ranking,
                type: 'hide',
              },
            ]}
            options={{
              chart: {
                type: 'line',
                stacked: false,
                toolbar: {
                  show: false,
                },
              },
              xaxis: {
                categories: months,
                tooltip: {
                  enabled: false,
                },
              },
              yaxis: [
                {
                  seriesName: 'OR Cases',
                  axisTicks: {
                    show: true,
                  },
                  title: {
                    text: 'Cases',
                  },
                },
                {
                  seriesName: 'Total RVUs',
                  opposite: true,
                  axisTicks: {
                    show: true,
                  },
                  title: {
                    text: 'RVUs',
                  },
                },
                {
                  seriesName: 'RVU Rank',
                  show: false,
                  min: 0,
                  max: 100,
                  labels: {
                    formatter: (value) => (`${value}%`),
                  },
                },

              ],
              responsive: [
                {
                  breakpoint: 1440,
                  options: {
                    xaxis: {
                      labels: {
                        hideOverlappingLabels: true,
                      }
                    }
                  }
                }
              ],
              tooltip: {
                enabled: true,
              },
              stroke: {
                curve: 'smooth',
                width: [0, 4, 4],
              },
            }}
          />
        );
      } else {
        graph = (
          <Typography className={classes.nothingFound}>
            No metrics data provided.
          </Typography>
        );
      }
    }

    let providerBenchmarks;
    if (rvuGoal) {
      providerBenchmarks = (
        <div className={classes.benchmarkGroup}>
          <Typography className={classes.benchmarkHeader}>
            {`Benchmark for ${providerName}:`}
          </Typography>
          <div className={classes.benchmarks}>
            <div>
              <Typography className={classes.benchmarkValue}>
                {`${Math.round(rvuGoal / 12).toLocaleString()}`}
              </Typography>
              <Typography className={classes.benchmarkTitle}>
                RVUs Per Month
              </Typography>
            </div>
          </div>
        </div>
      );
    }

    let benchmarks;
    if (specialty && state
      && rvuBenchmarks && rvuBenchmarks.data && rvuBenchmarks.data.length > 0) {
      const benchmarkData = rvuBenchmarks.data[0];
      const regionMedian = Math.round(benchmarkData[`${benchmarkData.region}_median`] / 12);
      const region75th = Math.round(benchmarkData[`${benchmarkData.region}_75th`] / 12);
      let regionName = benchmarkData.region;
      if (regionName && regionName.length > 0) {
        // capitalize the first letter
        regionName = regionName.charAt(0).toUpperCase() + regionName.slice(1);
      }
      benchmarks = (
        <div className={classes.benchmarkGroup}>
          <Typography className={classes.benchmarkHeader}>
            {`${benchmarkData.year} Monthly RVU Benchmarks for ${benchmarkData.specialtyName}:`}
          </Typography>
          <div className={classes.benchmarks}>
            <div>
              <Typography className={classes.benchmarkValue}>
                {`${Math.round(benchmarkData.national_median / 12).toLocaleString()}`}
              </Typography>
              <Typography className={classes.benchmarkTitle}>
                National Median
              </Typography>
            </div>
            {regionMedian && (
              <div>
                <Typography className={classes.benchmarkValue}>
                  {`${regionMedian.toLocaleString()}`}
                </Typography>
                <Typography className={classes.benchmarkTitle}>
                  {`${regionName} US Median`}
                </Typography>
              </div>
            )}
            {region75th && (
              <div>
                <Typography className={classes.benchmarkValue}>
                  {`${region75th.toLocaleString()}`}
                </Typography>
                <Typography className={classes.benchmarkTitle}>
                  {`${regionName} US 75%ile`}
                </Typography>
              </div>
            )}
          </div>
        </div>
      );
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <TitleTooltip
              title='Metrics'
              tooltip='Productivity metrics based on outpatient visits, surgical cases, total RVUs, and RVU ranking percentile. Hover over the bar to see the numbers for each month.'
            />
            {user.is_admin && (
              <Button
                color='primary'
                size='small'
                onClick={this.openDialog}
              >
                <Icon className={classes.leftIcon}>edit</Icon>
                Edit
              </Button>
            )}
          </div>

          {graph}

          <div className={classes.benchmarks}>
            {providerBenchmarks}
            {benchmarks}
          </div>

          <ProviderScorecardDialog
            clientId={clientId}
            providerId={providerId}
            data={scorecards}
            open={dialogOpen}
            onClose={this.closeDialog}
            onSave={this.onSave}
          />
        </CardContent>
      </Card>
    );
  }

  openDialog = () => {
    this.setState({ dialogOpen: true });
  };

  closeDialog = () => {
    this.setState({ dialogOpen: false });
  };

  onSave = (changedData) => {
    const {
      dispatch,
    } = this.props;

    if (changedData && changedData.length > 0) {
      dispatch(upsertScorecards(changedData));
    }
    this.closeDialog();
  };
}

ProviderScorecard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  user: PropTypes.object,
  scorecards: PropTypes.object,
  rvuBenchmarks: PropTypes.object,
  providerId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  specialty: PropTypes.string,
  state: PropTypes.string,
  rvuGoal: PropTypes.number,
  providerName: PropTypes.string,
  app: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    user,
    scorecards,
    app,
    analytics,
  } = state;

  return {
    user,
    app,
    scorecards: StoreUtil.get(scorecards, StoreUtil.COMMON_LIST),
    rvuBenchmarks: StoreUtil.get(analytics, 'RVU_BENCHMARK'),
  };
}

const styled = withStyles(styles)(ProviderScorecard);
const connected = connect(mapStateToProps)(styled);
export { connected as ProviderScorecard };
