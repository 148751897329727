import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { 
  PatientType,
  PatientTypes,
  PatientTypeDisplay,
} from 'doctivity-shared/utils';

import {
  setPatientType,
} from 'store/actions/appActions';

const styles = (theme) => ({
  element: {
    width: 168,
    marginRight: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
});

class PatientTypeSelect extends React.Component {
  constructor(props) {
    super(props);

    if (props.noCapturedClaims && props.value === PatientType.CAPTURED_CLAIMS) {
      this.state = {
        value: props.defaultValue || PatientType.ALL_PATIENTS,
      };
    } else {
      this.state = {
        value: props.value || props.defaultValue,
      };
    }
  }

  onChange = ({ target: { value } }) => {
    const { noModifyAppState, onChange } = this.props;
    this.setState({ value });
    if (!noModifyAppState) {
      this.props.dispatch(setPatientType(value));
    }
    onChange?.(value);
  };

  render() {
    const { classes } = this.props;

    let patientTypes = Object.entries(PatientTypeDisplay);
    let value = this.state.value;
    if (this.props.noCapturedClaims) {
      patientTypes = patientTypes.filter(
        ([filter]) => filter !== PatientType.CAPTURED_CLAIMS
      );
      if (value === PatientType.CAPTURED_CLAIMS) {
        value = PatientType.ALL_PATIENTS;
      }
    }

    return (
      <FormControl variant='outlined' className={classes.element}>
        <InputLabel>Patients</InputLabel>
        <Select value={value} onChange={this.onChange} label='Patients'>
          {patientTypes.map(([filter, display]) => (
            <MenuItem key={filter} value={filter}>
              {display}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
}

PatientTypeSelect.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  value: PropTypes.oneOf(PatientTypes),
  defaultValue: PropTypes.oneOf(PatientTypes),
  onChange: PropTypes.func,
  noCapturedClaims: PropTypes.bool,
  noModifyAppState: PropTypes.bool,
};

function mapStateToProps(state, props) {
  return {
    defaultValue: props.defaultValue || state.app.PatientTypeSelect,
    value: props.value || state.app.patientType,
  };
}

const styled = withStyles(styles)(PatientTypeSelect);
const connected = connect(mapStateToProps)(styled);
export { connected as PatientTypeSelect };
