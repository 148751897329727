import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  ClaimsDateProvider,
  TitleTooltip,
  ServiceLineSelect,
  PatientTypeSelect,
  MarketOrTagSelect,
  Paginateable,
  SharedAffiliationChart,
} from 'components';
import { convertToCSV } from 'documents/downloadTypes';
import {
  CsvUtil,
  DateUtil,
  StoreUtil,
  PatientType,
} from 'doctivity-shared/utils';
import { MarketsUtil, ServiceLineUtil, QueryParamUtil } from 'utils';
import { ClaimsPageQueryParams } from 'params';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { listMarkets } from 'store/actions/marketsActions';
import { upsertSavedCriteria } from 'store/actions/savedCriteriaActions';
import axiosInstance from 'utils/axiosUtil';
import fileDownload from 'js-file-download';
import { TagsUtil } from 'utils';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  headerDropDown: {
    minWidth: 220,
    marginBottom: theme.spacing(2),
  },
  sortRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  card: {},
  filterDropDown: {
    width: 120,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dropDown: {
    minWidth: 120,
    marginBottom: theme.spacing(2),
  },
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  filterLabel: {
    backgroundColor: 'white',
    paddingRight: 4,
  },
  noData: {
    textAlign: 'center',
    paddingTop: 50,
    minHeight: 200,
  },
  infoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  spacer: {
    width: theme.spacing(),
  },
  expandSpacer: {
    flexGrow: 1,
  },
  networkTooltip: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1),
    fontWeight: 400,
  },
  networkTooltipProviderLabel: {
    fontWeight: 500,
  },
});

class ClaimsSharedAffiliation extends React.Component {
  // Number of seconds to wait before saving the currently selected criteria
  saveCriteriaInterval = 3;

  saveCriteriaTimer = null;

  constructor(props) {
    super(props);

    this.state = {
      defaultParams: false, // Used when user does not have any saved criteria and going to /claims with no query string
      isMarket: true,
      market: null,
      tag: null,
      groupByServicelines: false,
      serviceline: 'all',
      before: false,
      affiliation: 'all',
      setParams: false,
      patientType: PatientType.ALL_PATIENTS,
      lastTimeCriteriaChanged: null,
      sort: ['shared_patients', 'DESC'],
      query: {
        limit: 50,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { dispatch, router } = this.props;

    if (
      !this.props.markets ||
      StoreUtil.needsLoadLongCache(this.props.markets)
    ) {
      dispatch(listMarkets(this.props.clientId));
    }

    if (StoreUtil.needsLoadNoCache(this.getData())) {
      this.fetchData();
    }
    const querySize = router.query.size - (router.query.has('client') ? 1 : 0);
    if (
      querySize === 0 ||
      (querySize === 1 && router.query.has('report'))
    ) {
      this.loadFromLastSaved();
    } else {
      this.setState({
        setParams: true, // tell update to use query string
      });
    }
    this.saveCriteriaTimer = setInterval(() => {
      if (this.state.lastTimeCriteriaChanged !== null) {
        let currentTime = moment();

        let duration = moment.duration(
          currentTime.diff(this.state.lastTimeCriteriaChanged),
        );
        let secondsSinceLastSave = duration.asSeconds();
        if (secondsSinceLastSave > this.saveCriteriaInterval) {
          // Build criteria_json
          // At this point we should have the most up to date criteria stored in state
          let criteriaArray = [
            {
              field: 'before',
              value: this.state.before,
            },
            {
              field: 'affiliation',
              value: this.state.affiliation,
            },
            {
              field: 'groupByServicelines',
              value: this.state.groupByServicelines,
            },
            {
              field: 'isMarket',
              value: this.state.isMarket,
            },
            {
              field: 'market',
              value: this.state.market?.id,
            },
            {
              field: 'tag',
              value: this.state.tag?.id,
            },
            {
              field: 'report',
              value: 'shared_affiliation',
            },
            {
              field: 'patient_type',
              value: this.state.patientType,
            },
            {
              field: 'serviceLine',
              value: this.state.serviceline,
            },
          ];
          dispatch(
            upsertSavedCriteria({
              criteria_type_id: 1,
              criteria_json: JSON.stringify({
                criteria: criteriaArray,
              }),
              client_id: this.props.clientId,
              user_id: this.props.user.id,
            }),
          );

          this.setState({
            lastTimeCriteriaChanged: null,
          });
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.saveCriteriaTimer);
  }

  getData() {
    if (this.state.groupByServicelines) {
      return this.props.networkByServiceline;
    }
    return this.props.network;
  }

  marketsChanged(newMarkets, oldMarkets) {
    if (
      newMarkets === null ||
      (oldMarkets === null && newMarkets !== oldMarkets)
    ) {
      return true;
    }
    if (newMarkets && oldMarkets && newMarkets.data && oldMarkets.data) {
      if (newMarkets.data.length !== oldMarkets.data.length) {
        return true;
      }
      if (
        newMarkets.meta &&
        newMarkets.meta.statusTime &&
        oldMarkets.meta &&
        oldMarkets.meta.statusTime
      ) {
        return (
          newMarkets.meta.statusTime.statusSuccess !==
          oldMarkets.meta.statusTime.statusSuccess
        );
      }
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;

    if (
      this.props.clientId !== prevProps.clientId ||
      this.marketsChanged(this.props.markets, prevProps.markets)
    ) {
      router.navigate('/claims?report=shared_affiliation');
      this.loadFromLastSaved();
      return;
    }

    if (this.state.setParams) {
      const queryClaimsDate = router.query.get(
        ClaimsPageQueryParams.CLAIMS_DATE.name,
      );
      const queryMarket = router.query.get(ClaimsPageQueryParams.MARKET.name);
      const queryServiceLine = router.query.get(
        ClaimsPageQueryParams.SERVICELINE.name,
      );
      const queryPatientType = router.query.get(
        ClaimsPageQueryParams.PATIENT_TYPE.name,
      );
      const queryAffiliation = router.query.get(
        ClaimsPageQueryParams.AFFILIATION.name,
      );
      const marketsLoaded = StoreUtil.isLoaded(this.props.markets);
      const queryIsMarket = router.query.get('isMarket');
      const queryGroupBy = router.query.get('groupByServicelines');
      const queryTag = router.query.get('tag');

      if (marketsLoaded) {
        let querySelectedMarket = null;
        const marketsData = StoreUtil.getData(this.props.markets);
        if (marketsData && marketsData.length > 0) {
          querySelectedMarket = marketsData.find(
            (market) => market.id === parseInt(queryMarket, 10),
          );
        }
        if (!querySelectedMarket && marketsData.length > 0) {
          querySelectedMarket = marketsData[0];
        }

        console.log('set state from query string', querySelectedMarket);
        this.setState(
          {
            setParams: false,
            before: String(queryClaimsDate).toLowerCase() == 'true' ?? false,
            isMarket: queryIsMarket === 'true' || !queryTag,
            market: querySelectedMarket,
            tag: queryTag ? { id: queryTag } : null,
            serviceline: queryServiceLine,
            patientType: queryPatientType ?? PatientType.ALL_PATIENTS,
            affiliation: queryAffiliation ?? 'all',
            groupByServicelines: queryGroupBy === 'true',
          },
          () => {
            this.fetchData();
          },
        );
      }
    }
    const querySize = router.query.size - (router.query.has('client') ? 1 : 0);
    if (
      this.state.defaultParams &&
      (querySize === 0 ||
        (querySize === 1 && router.query.has('report')))
    ) {
      const marketsLoaded = StoreUtil.isLoaded(this.props.markets);
      if (marketsLoaded) {
        // console.log('set default params');
        this.setState(
          {
            defaultParams: false,
            before: false,
            serviceline: ServiceLineUtil.getDefault(),
            isMarket: true,
            market: this.props.market,
            patientType: PatientType.ALL_PATIENTS,
            affiliation: 'all',
            groupByServicelines: false,
          },
          () => {
            this.fetchData();
          },
        );
      }
    }
  }

  loadFromLastSaved = () => {
    console.log('load last saved...');
    const { router } = this.props;
    axiosInstance
      .get('/UsersSavedCriteria', {
        params: {
          opts: {
            clientId: this.props.clientId,
            criteriaTypeId: 1,
          },
        },
      })
      .then((savedCriteria) => {
        if (savedCriteria.data && savedCriteria.data.length > 0) {
          const criteria = savedCriteria.data[0].criteria_json;

          let mostRecent = (
            typeof criteria === 'string' ? JSON.parse(criteria) : criteria
          )?.criteria;

          let mostRecentAffiliated = mostRecent.find(
            (c) => c.field === 'affiliation',
          );
          let mostRecentBefore = mostRecent.find((c) => c.field === 'before');
          let mostRecentServiceLine = mostRecent.find(
            (c) => c.field === 'serviceline',
          );
          let mostRecentMarket = mostRecent.find((c) => c.field === 'market');
          let mostRecentTag = mostRecent.find((c) => c.field === 'tag');
          let mostRecentIsMarket = mostRecent.find(
            (c) => c.field === 'isMarket',
          );
          let mostRecentGroupBy = mostRecent.find(
            (c) => c.field === 'groubByServicelines',
          );

          console.log('set state from recent search');
          QueryParamUtil.setQueryParamsFromProps(
            {
              report: 'shared_affiliation',
              market: mostRecentMarket?.value,
              tag: mostRecentTag?.value,
              isMarket: mostRecentIsMarket?.value ?? true,
              serviceline: mostRecentServiceLine?.value ?? 'all',
              before: mostRecentBefore?.value ?? false,
              affiliation: mostRecentAffiliated?.value,
              groupByServicelines: mostRecentGroupBy?.value ?? false,
              router,
            },
            ClaimsPageQueryParams,
          );

          this.setState({
            setParams: true,
          });
        } else {
          this.setState({
            defaultParams: true,
          });
        }
      });
  };

  updateQueryStringWithCriteria = () => {
    const { router } = this.props;
    console.log('oncriteria changed', this.state);

    QueryParamUtil.setQueryParamsFromProps(
      {
        report: 'shared_affiliation',
        before: this.state.before,
        isMarket: this.state.isMarket,
        market: this.state.market?.id,
        tag: this.state.tag?.id,
        serviceline: this.state.serviceline,
        affiliation: this.state.affiliation,
        patientType: this.state.patientType,
        groupByServicelines: this.state.groupByServicelines,
        router,
      },
      ClaimsPageQueryParams,
    );
  };

  fetchData() {
    const { dispatch, clientId } = this.props;
    const { serviceline, before, affiliation } = this.state;
    if (!this.state.market && !this.state.tag) {
      return;
    }
    dispatch(
      loadAnalytics({
        type: !this.state.groupByServicelines
          ? 'CLAIMS_SHARED_AFFILIATION'
          : 'CLAIMS_SHARED_AFFILIATION_BY_SERVICELINE',
        filter: {
          serviceline:
            serviceline === 'all'
              ? undefined
              : serviceline ?? ServiceLineUtil.getDefault(),
          before,
          client_id: clientId,
          group_by_serviceline: this.state.groupByServicelines,
          market: this.state.isMarket ? this.state.market : undefined,
          tag: !this.state.isMarket ? this.state.tag : undefined,
          patient_type: this.state.patientType || PatientType.ALL_PATIENTS,
          affiliated:
            affiliation === 'all'
              ? undefined
              : affiliation === 'affiliated'
                ? true
                : false,
        },
        opts: {
          ...this.state.query,
          sort: this.state.sort,
        },
      }),
    );
  }

  render() {
    const { classes } = this.props;
    const { patientType } = this.state;

    const network = this.getData();

    const hasChildren =
      ServiceLineUtil.findServiceLine(
        this.props.servicelines,
        this.state.serviceline,
      )?.children?.length > 0;
    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <FormControl
            variant='outlined'
            className={classes.headerDropDown}
            key='group-by'
          >
            <InputLabel className={classes.filterLabel}>Group By</InputLabel>
            <Select
              key='select'
              value={this.state.groupByServicelines}
              onChange={this.onGroupByChange}
              label='Group By'
            >
              <MenuItem value={false} key='providers'>
                Providers
              </MenuItem>
              <MenuItem value={true} key='servicelines'>
                Service Lines
              </MenuItem>
            </Select>
          </FormControl>
          <ClaimsDateProvider />
        </div>
        <Card className={classes.card}>
          <CardContent>
            <TitleTooltip
              title='Shared Patients by Provider Affiliation'
              tooltip='This chart shows the % of patients that are shared with other Affiliated Providers by the selected filters. The chart is sorted by a directional calculation of total projected patients or total projected pediatric patients.'
              onExport={this.onExport}
            />
            <div className={classes.dropDownContainer}>
              <FormControl
                variant='outlined'
                className={classes.filterDropDown}
                key='direction'
              >
                <InputLabel className={classes.filterLabel}>
                  Claims Date
                </InputLabel>
                <Select
                  key='select'
                  value={this.state.before}
                  onChange={this.onDirectionChange}
                  label='Patient Seen By'
                >
                  <MenuItem value={false} key='after'>
                    After
                  </MenuItem>
                  <MenuItem value key='before'>
                    Before
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.dropDown}>
                <MarketOrTagSelect
                  filter={{
                    type: this.state.isMarket ? 'market' : 'tag',
                    market: this.state.market,
                    tag: this.state.tag,
                  }}
                  onChange={this.onMarketChange}
                  allowTagSelection={true}
                  markets={this.props.markets}
                  market={this.props.market}
                  type='providers'
                />
              </div>
              <div className={classes.dropDown}>
                <ServiceLineSelect
                  value={this.state.serviceline || ServiceLineUtil.getDefault()}
                  onChange={this.onServicelineChange}
                  showSubServicelines={!this.state.groupByServicelines}
                  hideChildlessTopLevels={this.state.groupByServicelines}
                  showAll
                />
              </div>
              <div className={classes.dropDown}>
                <PatientTypeSelect
                  noCapturedClaims
                  noModifyAppState
                  onChange={this.onPatientTypeChange}
                  value={patientType.toUpperCase()}
                />
              </div>
              <FormControl
                variant='outlined'
                className={classes.dropDown}
                key='affiliated'
              >
                <InputLabel className={classes.filterLabel}>
                  Affiliation
                </InputLabel>
                <Select
                  key='select'
                  value={this.state.affiliation}
                  onChange={this.onAffiliationChange}
                  label='Affiliation'
                >
                  <MenuItem value='all' key='all'>
                    <em>All</em>
                  </MenuItem>
                  <MenuItem value='affiliated' key='affiliated'>
                    Affiliated
                  </MenuItem>
                  <MenuItem value='non-affiliated' key='non-affiliated'>
                    Non-Affiliated
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className={classes.sortRow}>
              <FormControl
                variant='outlined'
                className={classes.dropDown}
                key='sort'
              >
                <InputLabel className={classes.filterLabel}>Sort By</InputLabel>
                <Select
                  key='select'
                  value={this.state.sort[0]}
                  onChange={this.onSortChange}
                  label='Sort By'
                >
                  <MenuItem value='shared_patients' key='shared_patients'>
                    Total Shared Patients
                  </MenuItem>
                  <MenuItem
                    value='shared_patients_affiliated'
                    key='shared_patients_affiliated'
                  >
                    Patients from Affiliated Providers
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.spacer} />
              <FormControl
                variant='outlined'
                className={classes.dropDown}
                key='sort-direction'
              >
                <InputLabel className={classes.filterLabel}>
                  Sort Direction
                </InputLabel>
                <Select
                  key='select'
                  value={this.state.sort[1]}
                  onChange={this.onSortDirectionChange}
                  label='Sort Direction'
                >
                  <MenuItem value='DESC' key='DESC'>
                    Highest to Lowest
                  </MenuItem>
                  <MenuItem value='ASC' key='ASC'>
                    Lowest to Highest
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.expandSpacer} />
              {!this.state.groupByServicelines && network && (
                <Paginateable
                  data={network.data}
                  query={this.state.query}
                  onQueryChange={this.onQueryChange}
                />
              )}
            </div>
            <SharedAffiliationChart
              classes={classes}
              groupByServicelines={this.state.groupByServicelines}
              hasChildren={hasChildren}
              serviceline={this.state.serviceline}
              before={this.state.before}
              isMarket={this.state.isMarket}
              onServicelineClick={this.onServicelineClick}
            />
          </CardContent>
        </Card>
      </div>
    );
  }

  onServicelineClick = (sl) => {
    this.setState(
      {
        serviceline: sl.id,
      },
      this.onCriteriaChanged,
    );
  };

  onServicelineChange = (id) => {
    this.setState(
      {
        serviceline: id,
      },
      this.onCriteriaChanged,
    );
  };

  onDirectionChange = (event) => {
    this.setState(
      {
        before: event.target.value,
      },
      this.onCriteriaChanged,
    );
  };

  onAffiliationChange = (event) => {
    this.setState({ affiliation: event.target.value }, this.onCriteriaChanged);
  };

  onPatientTypeChange = (value) => {
    this.setState({ patientType: value }, this.onCriteriaChanged);
  };

  onMarketChange = (filter) => {
    this.setState((state) => {
      return {
        isMarket: filter.type === 'market',
        market: filter.type === 'market' ? filter.market : state.market,
        tag: filter.type === 'tag' ? filter.tag : state.tag,
      };
    }, this.onCriteriaChanged);
  };

  onCriteriaChanged = () => {
    this.updateQueryStringWithCriteria();
    this.fetchData();

    this.setState({
      lastTimeCriteriaChanged: moment(),
    });
  };

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };

  onSortChange = (event) => {
    this.setState(
      (state) => ({ sort: [event.target.value, state.sort[1]] }),
      this.fetchData,
    );
  };

  onSortDirectionChange = (event) => {
    this.setState(
      (state) => ({ sort: [state.sort[0], event.target.value] }),
      this.fetchData,
    );
  };

  onGroupByChange = (event) => {
    if (event.target.value) {
      const sl = ServiceLineUtil.findServiceLine(
        this.props.servicelines,
        this.state.serviceline,
      );
      if (sl && sl.parent_id) {
        this.setState({
          serviceline: sl.parent_id,
        });
      }
    }
    this.setState(
      { groupByServicelines: event.target.value },
      this.onCriteriaChanged,
    );
  };

  onExport = async () => {
    const { servicelines } = this.props;
    const { isMarket, tag, market, patientType } = this.state;
    const slId = parseInt(this.state.serviceline, 10);
    const selectedSL = servicelines?.find((l) => l.id === slId);
    const serviceline =
      this.state.serviceline === 'all'
        ? 'ALL'
        : selectedSL?.abbreviation ?? selectedSL?.name;
    const beforeOrAfter = this.state.before ? 'Before' : 'After';
    const tagNS =
      !isMarket &&
      TagsUtil.findNamespaceForTagId(tag.id, this.props.tagNamespaces);
    const tagName =
      tag && TagsUtil.findTag(tag.id, this.props.tagNamespaces)?.name;
    const label = isMarket ? market.name : `${tagNS?.name}: ${tagName}`;

    if (this.state.groupByServicelines) {
      // Claims Date (before after), Market / Label, Service Line, Patients (Projected or Pediatrics), Percent Affiliated, Percent Non Affiliated
      const mapping = {
        displayName: 'Shared Patients By Provider Affiliation',
        helpText:
          'Download a .csv of the Shared Patients By Provider Affiliation report.',
        downloadName: `doctivity-SHARED-AFFILIATION-${beforeOrAfter.toLocaleUpperCase()}-${label.toLocaleUpperCase()}-${serviceline.toLocaleUpperCase()}-{date}.csv`,
        initialQuery: {},
        columnMapping: {
          claims_date: {
            downloadName: 'Claims Date',
            format: () => beforeOrAfter,
          },
          label: {
            downloadName: 'Market / Label',
            format: () => label,
          },
          name: {
            downloadName: 'Service Line',
          },
          patient_type: {
            downloadName: 'Patients',
            format: () =>
              patientType === 'PEDIATRIC_PATIENTS' ? 'Pediatric' : 'Projected',
          },
          shared_patients: {
            downloadName: 'Percent Affiliated',
            format: (_v, row) => {
              const affiliated = Number.isNaN(row.shared_patients_affiliated)
                ? 0
                : row.shared_patients_affiliated;
              const total = Number.isNaN(row.shared_patients)
                ? 0
                : row.shared_patients;
              const percent = total < 1 ? 100 : (affiliated / total) * 100;
              return percent.toFixed(2);
            },
          },
          shared_patients_non_affiliated: {
            downloadName: 'Percent Non-Affiliated',
            format: (_v, row) => {
              const affiliated = Number.isNaN(row.shared_patients_affiliated)
                ? 0
                : row.shared_patients_affiliated;
              const total = Number.isNaN(row.shared_patients)
                ? 0
                : row.shared_patients;
              const percent =
                total < 1 ? 0 : ((total - affiliated) / total) * 100;
              return percent.toFixed(2);
            },
          },
        },
      };
      const csv = convertToCSV(
        mapping,
        this.props.networkByServiceline.data.rows,
      );
      const downloadName = mapping.downloadName.replace(
        '{date}',
        moment().format('YY_MM_DD'),
      );
      fileDownload(csv, downloadName);
    } else {
      //Provider NPI, Before/After, Market or Label, Patients, Affiliation, Service Line, % Affiliated, % Non Affiliated
      const columns = [
        'Provider NPI',
        'First Name',
        'Last Name',
        'Before/After',
        'Market/Label',
        'Affiliation Status',
        'Service Line',
        '% Affiliated',
        '% Non Affiliated',
      ];

      const data = [columns];
      const { network, servicelines, servicelineList } = this.props;
      const { isMarket, tag, market, patientType } = this.state;
      const slId = parseInt(this.state.serviceline, 10);
      const selectedSL = (servicelineList?.data ?? servicelines)?.find(
        (l) => l.id === slId,
      );
      const serviceline =
        this.state.serviceline === 'all'
          ? 'ALL'
          : selectedSL?.abbreviation ?? selectedSL?.name;
      const beforeOrAfter = this.state.before ? 'Before' : 'After';
      const tagNS =
        !isMarket &&
        TagsUtil.findNamespaceForTagId(tag.id, this.props.tagNamespaces);
      const tagName =
        tag && TagsUtil.findTag(tag.id, this.props.tagNamespaces)?.name;
      const label = isMarket ? market.name : `${tagNS?.name}: ${tagName}`;
      network.data.rows.forEach((row) => {
        const percent =
          (row.shared_patients_affiliated / row.shared_patients) * 100;
        data.push([
          row.npi,
          row.first_name,
          row.last_name,
          beforeOrAfter,
          label,
          row.is_affiliated ? 'Affiliated' : 'Non-affiliated',
          serviceline,
          Number.isNaN(percent) ? 0 : percent.toFixed(2),
          Number.isNaN(percent) ? 100 : (100 - percent).toFixed(2),
        ]);
      });
      const pediatric =
        patientType === 'PEDIATRIC_PATIENTS' ? '_PEDIATRIC' : '';
      const date = DateUtil.formatDateForUser(new Date());
      const filename = `doctivity_SHARED_${beforeOrAfter.toLocaleUpperCase()}_${(serviceline ?? 'Unknown').toLocaleUpperCase()}${pediatric}_${date}.csv`;
      fileDownload(CsvUtil.matrixToString(data), filename);
    }
  };
}

ClaimsSharedAffiliation.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  query: PropTypes.object.isRequired,
  network: PropTypes.object,
  networkByServiceline: PropTypes.object,
  market: PropTypes.object,
  markets: PropTypes.object,
  user: PropTypes.object,
  tagNamespaces: PropTypes.object,
  servicelines: PropTypes.array,
  servicelineList: PropTypes.array,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    network: StoreUtil.get(state.analytics, 'CLAIMS_SHARED_AFFILIATION'),
    networkByServiceline: StoreUtil.get(
      state.analytics,
      'CLAIMS_SHARED_AFFILIATION_BY_SERVICELINE',
    ),
    market: MarketsUtil.getSelectedMarket(state),
    markets: MarketsUtil.getMarkets(state),
    servicelines: state.claims && state.claims.servicelines_grouped,
    servicelineList: state.claims && state.claims.servicelines_list,
    user: state.user,
    tagNamespaces: StoreUtil.get(
      state.tagNamespaces,
      'API_TAG_NAMESPACES_LIST_BY_CLIENT',
    ),
  };
}

const styled = withStyles(styles)(ClaimsSharedAffiliation);
const connected = connect(mapStateToProps)(styled);
export { connected as ClaimsSharedAffiliation };
