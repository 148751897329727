import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Icon, Typography, Tooltip } from '@mui/material';

const styles = (theme) => ({
  affiliatedIcon: {
    fontSize: 20,
    verticalAlign: 'text-top',
    marginRight: theme.spacing(1),
    color:theme.palette.secondary.main, 
  },
  affiliatedInfoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
});

class AffiliatedIcon extends React.Component {
  render() {
    const { classes, type } = this.props;

    return (
      <Tooltip
        placement='bottom-start'
        title={
          <Typography className={classes.affiliatedInfoTip}>
            Affiliated {type}
          </Typography>
        }
      >
        <Icon className={classes.affiliatedIcon}>hub</Icon>
      </Tooltip>
    );
  }
}

AffiliatedIcon.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.oneOf(['Provider', 'Organization']).isRequired,
};

const styled = withStyles(styles)(AffiliatedIcon);
export { styled as AffiliatedIcon };
