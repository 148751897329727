import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, Stack, Typography } from '@mui/material';

const styles = (theme) => ({
  container: {
    paddingTop: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    '& > p': {
      margin: theme.spacing(2),
    }
  },
  buttonStack: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

class ContactDeleteDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, contactData, open, onClose, onDelete } = this.props;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby='simple-dialog-title'
        open={open}
      >
        <DialogTitle className={classes.title}>Delete Contact</DialogTitle>
        <div className={classes.container}>
          <Typography>
            Are you sure you want to delete the contact {contactData.first_name}&nbsp;
            {contactData.last_name}?
          </Typography>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='end'
            spacing={2}
          >
            <Button color='primary' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={() => onDelete(contactData)}>
              Delete
            </Button>
          </Stack>
        </div>
      </Dialog>
    );
  }
}

ContactDeleteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  contactData: PropTypes.object.isRequired,
};

function mapStateToProps() {
  return {};
}

const styled = withStyles(styles)(ContactDeleteDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as ContactDeleteDialog };
