import React from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  LoadingView,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  queryScorecards,
} from 'store/actions/scorecardsActions';

const styles = () => ({
  card: {
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  cardTitle: {
    flex: 1,
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class ClientScorecard extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.clientId !== prevProps.clientId) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      dispatch,
      clientId,
      app,
    } = this.props;

    const query = {
      attributes: [
        { $sum: { col: 'total_rvu', alias: 'total_rvu' } },
        'year',
        'month',
        'client_id',
      ],
      group: ['year', 'month'],
      where: {
        client_id: {
          $eq: clientId,
        },
        year: {
          $gte: moment(app.dateRange.end).year() - 1,
        },
      },
      order: [
        ['year', 'ASC'],
        ['month', 'ASC'],
      ],
      limit: 24,
      offset: 0,
    };

    dispatch(queryScorecards(query));
  }

  render() {
    const {
      classes,
      scorecards,
      app,
    } = this.props;

    let graph;
    if (!scorecards
      || StoreUtil.isLoading(scorecards)) {
      graph = (<LoadingView />);
    } else {
      const data = StoreUtil.getData(scorecards);
      if (data && data.rows && data.rows.length > 0) {
        const series = [];
        const months = [];

        const end = moment(app.dateRange.end);
        const d = end.clone().subtract(1, 'year');

        while (!d.isAfter(end)) {
          const scorecard = data.rows.find(
            (s) => (s.year === d.year() && s.month === d.month() + 1),
          );

          months.push(d.format('MMM YY'));
          series.push(scorecard && scorecard.total_rvu ? scorecard.total_rvu : 0);
          d.add(1, 'month').endOf('month');
        }

        graph = (
          <Chart
            type='bar'
            height={225}
            series={[
              {
                name: 'Total RVUs',
                data: series,
              },
            ]}
            options={{
              chart: {
                type: 'bar',
                stacked: false,
                toolbar: {
                  show: false,
                },
              },
              xaxis: {
                categories: months,
              },
              tooltip: {
                enabled: false,
              },
            }}
          />
        );
      } else {
        graph = (
          <Typography className={classes.nothingFound}>
            No RVU data found.
          </Typography>
        );
      }
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <Typography component='h2' variant='h6' color='primary' className={classes.cardTitle} gutterBottom>
              Total RVUs
            </Typography>
          </div>

          {graph}

        </CardContent>
      </Card>
    );
  }
}

ClientScorecard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  user: PropTypes.object,
  app: PropTypes.object,
  scorecards: PropTypes.object,
  clientId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const {
    user,
    scorecards,
    app,
  } = state;

  return {
    user,
    app,
    scorecards: StoreUtil.get(scorecards, StoreUtil.COMMON_TABLE),
  };
}

const styled = withStyles(styles)(ClientScorecard);
const connected = connect(mapStateToProps)(styled);
export { connected as ClientScorecard };
