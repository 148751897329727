class SpecialtyUtil {
  static getNameFromTaxonomy(taxonomy) {
    let specialty = '';
    if (taxonomy) {
      if (taxonomy.specialization) {
        specialty = taxonomy.specialization;
      } else if (taxonomy.classification) {
        specialty = taxonomy.classification;
      }
    }
    return specialty;
  }
}

module.exports = { SpecialtyUtil };
// export { ServiceLineUtil };
// export default ServiceLineUtil;
