import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import { Chip } from '@mui/material';
import TagIcon from 'assets/images/tag_icon.svg';

const styles = (theme) => ({
  anchor: {
    flex: '0 1 33%',
    textDecoration: 'none',
    '&:last-child': {
      flex: 1
    }
  },
  tagContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.75rem',
    paddingRight: '2rem',
    paddingBottom: '1rem',
    paddingTop: '1rem',
    cursor: 'pointer',
    borderColor: theme.palette.tertiary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
 },
  tag: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '25rem',
    gap: '0.25rem',
    flexWrap: 'wrap',
    fontWeight: '400',
    lineHeight: '1.5'
  },
  tagIcon: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: 32,
    color: theme.palette.primary.main,
  },
  tagNamespace: {
    fontSize: 12,
    paddingRight: 15,
    color: '#8e8e8e',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  tagBubble: {
    border: '2px solid ' + theme.palette.tertiary.main,
    borderRadius: '20px',
    padding: '0.5rem',
    fontWeight: 500,
  },
});

function Tag({ tag, classes, onClick }) {
  return (<a className={classes.anchor} onClick={onClick}>
      <div className={classes.tagContainer}>
        <div>
          <img
            src={TagIcon}
            alt='Label Icon'
            className={classes.tagIcon}
          />
        </div>
        <div className={classes.tag}>
          <span className={classes.tagNamespace}>{tag.namespace.name}</span>
          <Chip variant='outlined' label={tag.name}/>
        </div>
      </div>
    </a>
  );
}
Tag.propTypes = {
  classes: PropTypes.object,
  tag: PropTypes.object,
  onClick: PropTypes.func,
};
const styled = withStyles(styles)(Tag);
export { styled as Tag };
