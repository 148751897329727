import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_COUNTIES_BY_STATE,
  API_POSTALCODES_BY_COUNTY,
} from 'store/actions/countiesActions';

export const counties = (state = {}, action = null) => {
  let result = ReducerUtil.reduceQuery(state, action, API_COUNTIES_BY_STATE, 'counties_by_state');
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceQuery(state, action, API_POSTALCODES_BY_COUNTY, 'postalcodes_by_state');
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
