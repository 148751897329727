import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  LoadingView,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';

import {
  activityLogsByUserId,
} from 'store/actions/activityLogsActions';

const styles = () => ({
  card: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    minHeight: 250,
  },
  button: {
    color: '#444444',
    padding: 2,
    fontWeight: 200,
    fontSize: 12,
    textAlign: 'left',
  },
  noData: {
    textAlign: 'center',
    color: '#444444',
    fontWeight: 200,
    fontSize: 12,
  },
});

class RecentlyViewed extends React.Component {
  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.activityLogs)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.model !== prevProps.model
      || this.props.app.selectedClient !== prevProps.app.selectedClient
      || this.props.userId !== prevProps.userId) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(activityLogsByUserId(
      this.props.userId,
      this.props.model,
      this.props.app.selectedClient,
      { limit: 10 }, // count
    ));
  }

  render() {
    const {
      classes,
    } = this.props;

    if (!this.props.activityLogs || !StoreUtil.isLoaded(this.props.activityLogs)) {
      return (<LoadingView />);
    }

    const logs = this.props.activityLogs.data.rows;
    const objectName = this.props.model.toLowerCase().substring(0, this.props.model.length - 1);
    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography component='h2' variant='h6' color='primary' gutterBottom>
            {`Recent ${this.props.title || this.props.model}`}
          </Typography>

          <div className={classes.list}>
            { (!logs || logs.length === 0) && (
              <Typography className={classes.noData}>
                {`No ${(this.props.title || this.props.model).toLowerCase()} viewed.`}
              </Typography>
            )}
            { logs && logs.map((log) => (
              <Button
                key={log.object_id}
                size='small'
                onClick={() => {
                  this.onClick(log[objectName]);
                }}
                className={classes.button}
              >
                {this.props.name
                  ? this.props.name(log[objectName])
                  : log[objectName].name}
              </Button>
            ))}
          </div>
        </CardContent>
      </Card>
    );
  }

  onClick = (obj) => {
    const path = this.props.path || `${this.props.model.toLowerCase()}/`;
    this.props.router.navigate(`${path}${obj.id}`);
  };
}

RecentlyViewed.propTypes = {
  classes: PropTypes.object,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  model: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  activityLogs: PropTypes.object,
  title: PropTypes.string,
  path: PropTypes.string,
  name: PropTypes.func,
  app: PropTypes.object,
};

function mapStateToProps(state, props) {
  return {
    activityLogs: StoreUtil.get(
      state.activityLogs,
      'by_user',
      `${props.model}_${props.userId}`,
    ),
    app: state.app,
  };
}

const styled = withStyles(styles)(RecentlyViewed);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as RecentlyViewed };
