import AddressParser from 'parse-address';

class LocationsUtil {
  static formatAddress(location, includeZip) {
    return `${location.address1}${
      location.address2 ? `, ${location.address2}` : ''
    }, ${location.city}, ${location.state}${
      includeZip ? ', ' + location.postal_code : ''
    }`;
  }

  static formatFullNameAndAddress(location, includeZip) {
    return `${
      location.name ? location.name + ', ' : ''
    }${LocationsUtil.formatAddress(location, includeZip)}`;
  }

  static formatNameOrAddress(location, includeZip) {
    if (location.name && location.name !== '') {
      return location.name;
    } else {
      return LocationsUtil.formatAddress(location, includeZip);
    }
  }

  static spaceIfNotEmpty(str) {
    if (str && str.length > 0) {
      return ` ${str}`;
    }
    return '';
  }

  static formatAddress1(str) {
    if (str && str.length > 2) {
      str = str.toUpperCase();
      const parsed = AddressParser.parseLocation(str);
      if (parsed.type) {
        parsed.type = parsed.type.toUpperCase();
      }
      const endsWithSpace = str.endsWith(' ') ? ' ' : '';
      let lastWord = '';
      const words = str.split(' ');
      if (words.length > 1) {
        lastWord = words[words.length - 1];
        if (
          lastWord.length === 0 ||
          lastWord.length > 3 ||
          lastWord === parsed.suffix ||
          lastWord === parsed.street ||
          lastWord === parsed.type
        ) {
          lastWord = '';
        }
      }
      // console.log(`parsing ${str} with lastword (${lastWord}): `, parsed);
      if (parsed && (parsed.street || parsed.number)) {
        const firstPart = `${
          parsed.number || ''
        }${LocationsUtil.spaceIfNotEmpty(
          parsed.prefix
        )}${LocationsUtil.spaceIfNotEmpty(parsed.street)}`.trim();
        return `${firstPart}${LocationsUtil.spaceIfNotEmpty(
          parsed.type
        )}${LocationsUtil.spaceIfNotEmpty(
          parsed.suffix
        )}${LocationsUtil.spaceIfNotEmpty(
          lastWord
        )}${endsWithSpace}`.toUpperCase();
      }
    }
    return str;
  }
}

export { LocationsUtil };
export default LocationsUtil;