import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Icon } from '@mui/material';

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.secondary.accent,
    fontSize: 32,
    marginRight: theme.spacing(2),
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
});

class IconGridItem extends React.Component {
  render() {
    const { classes, iconName, children } = this.props;

    return (
      <div className={classes.container}>
        <Icon className={classes.icon}>{iconName}</Icon>

        <div className={classes.itemContainer}>{children}</div>
      </div>
    );
  }
}

IconGridItem.propTypes = {
  classes: PropTypes.object,
  iconName: PropTypes.string,
  children: PropTypes.node,
};

const styled = withStyles(styles)(IconGridItem);
export { styled as IconGridItem };
