import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_PROVIDER_COMMENTS_TABLE,
  API_PROVIDER_COMMENTS_LIST,
  API_PROVIDER_COMMENTS_UPSERT,
  API_PROVIDER_COMMENTS_GET,
  API_PROVIDER_COMMENTS_DELETE,
} from 'store/actions/providerCommentsActions';

export const providerComments = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_PROVIDER_COMMENTS_TABLE,
    API_PROVIDER_COMMENTS_LIST,
    API_PROVIDER_COMMENTS_UPSERT,
  );
  if (result.actionConsumed) {
    if (action.type === `${API_PROVIDER_COMMENTS_UPSERT}_SUCCESS`) {
      return {
        ...result.state,
        editItem: {
          ...result.state.editItem,
          wasNew: action.meta?.requestAction?.data?.id !== action.response?.data?.id,
        },
      };
    }
    return result.state;
  }
  result = ReducerUtil.reduceSingleGet(
    state,
    action,
    API_PROVIDER_COMMENTS_GET,
  );
  if (result.actionConsumed) {
    return result.state;
  }
  result = ReducerUtil.reduceUpsert(
    state,
    action,
    API_PROVIDER_COMMENTS_DELETE,
  );
  if (result.actionConsumed) {
    // delete into list
    return result.state;
  }

  return state;
};
