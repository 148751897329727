import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  TableWithDialog,
} from 'components';

import {
  queryRVUBenchmarks,
  upsertRVUBenchmark,
} from 'store/actions/rvuBenchmarksActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
});

const addComma = (val) => parseFloat(val).toLocaleString('en-US');

const specialtyName = (taxonomy) => {
  if (taxonomy.specialization) {
    return `${taxonomy.specialization} (${taxonomy.code})`;
  }
  if (taxonomy.classification) {
    return `${taxonomy.classification} (${taxonomy.code})`;
  }
  return `${taxonomy.grouping} (${taxonomy.code})`;
};
// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Year',
    key: 'year',
    style: { width: 100 },
  },
  {
    label: 'Specialty',
    key: 'taxonomy',
    format: specialtyName,
  },
  {
    label: 'National Median',
    key: 'national_median',
    style: { width: 150 },
    format: addComma,
  },
  {
    group: 'Eastern',
    label: 'Median',
    key: 'eastern_median',
    style: { width: 70 },
    format: addComma,
  },
  {
    group: 'Eastern',
    label: '75%tile',
    key: 'eastern_75th',
    style: { width: 70 },
    format: addComma,
  },
  {
    group: 'Midwest',
    label: 'Median',
    key: 'midwest_median',
    style: { width: 70 },
    format: addComma,
  },
  {
    group: 'Midwest',
    label: '75%tile',
    key: 'midwest_75th',
    style: { width: 70 },
    format: addComma,
  },
  {
    group: 'Southern',
    label: 'Median',
    key: 'southern_median',
    style: { width: 70 },
    format: addComma,
  },
  {
    group: 'Southern',
    label: '75%tile',
    key: 'southern_75th',
    style: { width: 70 },
    format: addComma,
  },
  {
    group: 'Western',
    label: 'Median',
    key: 'western_median',
    style: { width: 70 },
    format: addComma,
  },
  {
    group: 'Western',
    label: '75%tile',
    key: 'western_75th',
    style: { width: 70 },
    format: addComma,
  },
];

const schema = {
  type: 'object',
  required: ['year', 'specialty'],
  properties: {
    year: { type: 'number', title: 'Year' },
    specialty: { type: 'string', title: 'Taxonomy Code' },
    national_median: { type: 'number', title: 'National Median' },
    eastern_median: { type: 'number', title: 'Eastern Median' },
    eastern_75th: { type: 'number', title: 'Eastern 75%ile' },
    midwest_median: { type: 'number', title: 'Midwest Median' },
    midwest_75th: { type: 'number', title: 'Midwest 75%ile' },
    southern_median: { type: 'number', title: 'Southern Median' },
    southern_75th: { type: 'number', title: 'Southern 75%ile' },
    western_median: { type: 'number', title: 'Western Median' },
    western_75th: { type: 'number', title: 'Western 75%ile' },
  },
};

const uiSchema = {
  specialty: { classNames: 'two-column-field' },
  national_median: { classNames: 'two-column-field' },
  eastern_median: { classNames: 'two-column-field' },
  eastern_75th: { classNames: 'two-column-field' },
  midwest_median: { classNames: 'two-column-field' },
  midwest_75th: { classNames: 'two-column-field' },
  southern_median: { classNames: 'two-column-field' },
  southern_75th: { classNames: 'two-column-field' },
  western_median: { classNames: 'two-column-field' },
  western_75th: { classNames: 'two-column-field' },
};

class RVUBenchmarksPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        where: {
        },
        include: [
          {
            association: 'taxonomy',
          },
        ],
        order: [
          ['year', 'DESC'],
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const {
      rvuBenchmarks,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(rvuBenchmarks)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // anytime a user has been edited, update the list
    if (this.props.editingBenchmark !== prevProps.editingBenchmark) {
      // is the list data older than the edited data?
      if (StoreUtil.hasSavedSinceLoad(this.props.editingBenchmark, this.props.rvuBenchmarks)) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const {
      dispatch,
    } = this.props;

    const {
      query,
    } = this.state;

    dispatch(queryRVUBenchmarks({
      ...query,
    }));
  }

  render() {
    const {
      classes,
      user,
      rvuBenchmarks,
    } = this.props;

    const {
      query,
    } = this.state;

    return (
      <>
        <Helmet defer={false}>
          <title>RVU Benchmarks</title>
        </Helmet>
        <div className={classes.container}>
          <TableWithDialog
            title='RVU Benchmarks'
            dialogTitle='Benchmark'
            showFilters={false}
            columns={columns}
            query={query}
            onQueryChange={this.onQueryChange}
            data={rvuBenchmarks}
            dialogSchema={schema}
            dialogUISchema={uiSchema}
            newItem={{ year: moment().year() }}
            onSave={this.onSave}
            allowEdit={user.is_admin}
            allowNew={user.is_admin}
          />
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, () => {
      this.fetchData();
    });
  };

  onSave = (data) => {
    const {
      dispatch,
    } = this.props;

    // save data
    dispatch(upsertRVUBenchmark(data));
  };
}

RVUBenchmarksPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  user: PropTypes.object,
  editingBenchmark: PropTypes.object,
  rvuBenchmarks: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    rvuBenchmarks,
    user,
  } = state;

  return {
    user,
    rvuBenchmarks: StoreUtil.get(rvuBenchmarks, StoreUtil.COMMON_TABLE),
    editingBenchmark: StoreUtil.get(rvuBenchmarks, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(RVUBenchmarksPage);
const connected = connect(mapStateToProps)(styled);
export { connected as RVUBenchmarksPage };
