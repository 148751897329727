import { createRequestInstance, watchRequests, sendRequest } from 'redux-saga-requests';
import { createDriver } from 'redux-saga-requests-axios';
import { all, select } from 'redux-saga/effects';

import { autoSignOutSaga } from 'store/sagas/autoSignOutSaga';
import loginSaga from 'store/sagas/loginSaga';
import { logoutUser } from 'store/actions/loginActions';
import ChainedActionSaga from 'store/sagas/chainedActionSaga';
import ClientSelectSaga from 'store/sagas/clientSelectSaga';

function* onRequestSaga(request) {
  if (!request.params) {
    request.params = {};
  } 
  request.params.selected_client_id = yield select((state) => state.app.selectedClient)
  return request;
}

function onResponseSaga(response /* action */) {
  // if (action) {
  //   action.response = response;
  // }

  return response;
}

function* onErrorSaga(error) {
  // If we get a token error, log user out.
  console.log('ERROR: ', error);
  if (error.response
    && error.response.status === 403
    && localStorage.getItem('auth')) {
    console.log('LOGOUT SENT!');
    yield sendRequest(logoutUser());
    // window.location.reload(); // why would we do this?
  }
  return { error };
}

function onAbortSaga() {
}

function* rootSaga(axiosInstance) {
  yield createRequestInstance({
    driver: createDriver(axiosInstance),
    onRequest: onRequestSaga,
    onSuccess: onResponseSaga,
    onError: onErrorSaga,
    onAbort: onAbortSaga,
  });

  yield all([
    watchRequests({
      takeLatest: false, // true: aborts previous requests of the same type
    }),
    ...autoSignOutSaga,
    loginSaga(),
    ChainedActionSaga(),
    ClientSelectSaga(),
  ]);
}

export default rootSaga;
