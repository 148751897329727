import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { withAppbarHeight } from 'utils';

const styles = () => ({
  header: {
    position: 'sticky',
    backgroundColor: '#FAFAFA',
    zIndex: 10,
  },
});

class FixedHeader extends React.Component {
  constructor(props) {
    super(props);

    this.headerElement = React.createRef(null);

    this.state = {
      headerHeight: 0,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateNavDimensions);
  }

  componentDidUpdate() {
    this.updateNavDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateNavDimensions);
  }

  render() {
    const { classes, children, top, appbarHeight } = this.props;

    const topProvided = top !== undefined && top !== null;

    return (
      <div
        className={classes.header}
        ref={this.headerElement}
        style={{ top: topProvided ? top : appbarHeight }}
      >
        {children}
      </div>
    );
  }

  updateNavDimensions = () => {
    const { onChangeHeaderHeight } = this.props;
    const { headerHeight } = this.state;

    const newHeaderHeight = this.headerElement.current?.clientHeight || 0;
    if (headerHeight !== newHeaderHeight) {
      this.setState({ headerHeight: newHeaderHeight });
      onChangeHeaderHeight?.(newHeaderHeight);
    }
  };
}

FixedHeader.propTypes = {
  appbarHeight: PropTypes.number.isRequired,
  classes: PropTypes.object,
  children: PropTypes.node,
  onChangeHeaderHeight: PropTypes.func,
  top: PropTypes.number,
};

const styled = withStyles(styles)(withAppbarHeight(FixedHeader));
export { styled as FixedHeader };
