import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  FormControl,
  Autocomplete,
  TextField,
} from '@mui/material';
import {
  StoreUtil,
} from 'doctivity-shared/utils';
import {
  listReferringOrganizations,
} from 'store/actions/visitsActions';

const styles = (theme) => ({
  filterDropDown: {
    width: 300,
    marginRight: theme.spacing(2),
  },
});

class ReferringOrganizationSelect extends React.Component {
  componentDidMount() {
    if (!this.props.referringOrganizations
      || this.props.referringOrganizationsClientId !== this.props.clientId) {
      this.fetchData();
    }
  }

  componentDidUpdate() {
    if (!this.props.referringOrganizations
      || this.props.referringOrganizationsClientId !== this.props.clientId) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(listReferringOrganizations(this.props.clientId));
  }

  render() {
    const {
      classes,
    } = this.props;

    let options = [];
    if (StoreUtil.isLoaded(this.props.referringOrganizations)) {
      options = StoreUtil.getData(this.props.referringOrganizations).map(
        (org) => org.referring_organization,
      );
      options.unshift('All');
    }

    console.log('VALUE: ', this.props.value);

    return (
      <FormControl variant='outlined' className={classes.filterDropDown} key='filter-referring-org'>
        <Autocomplete
          options={options}
          autoComplete
          value={this.props.value || 'All'}
          onChange={this.onChange}
          renderInput={(params) => (
            <TextField {...params} label='Referring Organization' />
          )}
        />
      </FormControl>
    );
  }

  onChange = (event, newValue) => {
    if (newValue === 'All') {
      this.props.onChange(null);
    } else {
      this.props.onChange(newValue);
    }
  };
}

ReferringOrganizationSelect.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  referringOrganizations: PropTypes.object,
  referringOrganizationsClientId: PropTypes.number,
  value: PropTypes.string,
  clientId: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    referringOrganizationsClientId: state.visits.referringOrganizationsClientId,
    referringOrganizations: StoreUtil.get(state.visits, 'referringOrganizations'),
  };
}

const styled = withStyles(styles)(ReferringOrganizationSelect);
const connected = connect(mapStateToProps)(styled);
export { connected as ReferringOrganizationSelect };
