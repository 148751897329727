export const API_TAG_NAMESPACES_LOAD = 'API_TAG_NAMESPACES_LOAD';
export const API_TAG_NAMESPACES_LIST = 'API_TAG_NAMESPACES_LIST';
export const API_TAG_NAMESPACES_LIST_BY_CLIENT = 'API_TAG_NAMESPACES_LIST_BY_CLIENT';
export const API_TAG_NAMESPACES_TABLE = 'API_TAG_NAMESPACES_TABLE';
export const API_TAG_NAMESPACES_UPSERT = 'API_TAG_NAMESPACES_UPSERT';
export const API_TAG_NAMESPACES_PROVIDERS_LIST = 'API_TAG_NAMESPACES_PROVIDERS_LIST'

export const queryTagNamespaces = (options, type = API_TAG_NAMESPACES_TABLE) => ({
  type,
  request: {
    url: '/TagNamespaces',
    method: 'GET',
    params: { opts: options },
  },
});

export const listTagNamespaces = () => ({
  type: API_TAG_NAMESPACES_LIST,
  clientId,
  request: {
    url: '/TagNamespaces',
    method: 'GET',
    params: {
      opts: {
        include: [
          {
            attributes: ['id', 'name'],
            association: 'tags',
            order: [['name', 'ASC']],
          },
        ],
      },
    },
  },
});

export const listTagNamespacesByClient = (clientId) => ({
  type: API_TAG_NAMESPACES_LIST_BY_CLIENT,
  clientId,
  request: {
    url: '/TagNamespaces',
    method: 'GET',
    params: {
      opts: {
        where: {
          client_id: clientId,
        },
        include: [
          {
            attributes: ['id', 'name'],
            association: 'tags',
          },
        ],
        order: [['name', 'ASC'], ['tags', 'name', 'ASC']],
      },
    },
  },
});

export const listProviderTagNamespaces = (clientId) => ({
  type: API_TAG_NAMESPACES_PROVIDERS_LIST,
  clientId,
  request: {
    url: '/TagNamespaces',
    method: 'GET',
    params: {
      opts: {
        where: {
          providers_drop_down: true,
          client_id: clientId,
        },
        include: [
          {
            attributes: ['id', 'name'],
            association: 'tags',
            order: [['name', 'ASC']],
          },
        ],
      },
    },
  },
});

export const loadTagNamespace = (tagNamespaceId) => ({
  type: API_TAG_NAMESPACES_LOAD,
  id: tagNamespaceId,
  request: {
    url: '/TagNamespaces',
    method: 'GET',
    params: {
      opts: {
        where: {
          id: {
            $eq: tagNamespaceId,
          },
        },
        include: [
          {
            association: 'user',
            attributes: ['id', 'first_name', 'last_name', 'is_admin'],
          },
          {
            association: 'client',
            attributes: ['id', 'name'],
          },
        ],
      },
    },
  },
});


export const upsertTagNamespace = (data) => {
  let options = {
    include: [],
  };

  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      ...options,
      where: { id },
    };
  }

  return {
    type: API_TAG_NAMESPACES_UPSERT,
    id,
    request: {
      url: '/TagNamespaces',
      method: 'PUT',
      params: { opts: options },
      data,
    },
    nextAction: data.id && loadTagNamespace(data.id),
  };
};
