import React, { Component } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { IconButton, Icon } from '@mui/material';

import { StoreUtil } from 'doctivity-shared/utils';

import {
  favoritesByUser,
  upsertFavorite,
  deleteFavorite,
} from 'store/actions/favoritesActions';

const styles = () => ({
  base: {
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#ff8eea',
    },
  },
  favorite: {
    color: '#E300B8',
  },
  notFavorite: {
    color: '#E300B8',
  },
});

class FavoriteIconButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      changedValue: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.objectType !== prevProps.objectType ||
      this.props.objectId !== prevProps.objectId ||
      this.props.userId !== prevProps.userId ||
      this.props.clientId !== prevProps.clientId
    ) {
      this.resetData();
    }
    if (
      this.props.userId !== prevProps.userId ||
      this.props.clientId !== prevProps.clientId
    ) {
      this.fetchData();
    }
  }

  resetData() {
    this.setState({
      changedValue: null,
    });
    this.fetchData();
  }

  fetchData() {
    if (!StoreUtil.isLoaded(this.props.favorites)) {
      this.props.dispatch(
        favoritesByUser(this.props.clientId, this.props.userId),
      );
    }
  }

  isFavorite() {
    let isFavorite = false;
    if (this.state.changedValue !== null) {
      isFavorite = this.state.changedValue;
    } else if (this.getFavoriteId()) {
      isFavorite = true;
    }
    return isFavorite;
  }

  getFavoriteId() {
    if (StoreUtil.isLoaded(this.props.favorites)) {
      const favorites = StoreUtil.getData(this.props.favorites);
      const fav = favorites.find(
        (f) =>
          f.object_id === this.props.objectId &&
          f.object_type === this.props.objectType,
      );
      if (fav) {
        return fav.id;
      }
    }
    return null;
  }

  render() {
    const { classes } = this.props;

    const isFavorite = this.isFavorite();
    return (
      <IconButton
        color='primary'
        size='small'
        className={clsx(
          isFavorite ? classes.favorite : classes.notFavorite,
          classes.base,
        )}
        onClick={this.onClick}
      >
        <Icon fontSize='small'>
          {isFavorite ? 'favorite' : 'favorite_border'}
        </Icon>
      </IconButton>
    );
  }

  onClick = () => {
    const lastValue = this.isFavorite();
    const id = this.getFavoriteId();
    this.setState({
      changedValue: !lastValue,
    });
    if (lastValue && id) {
      this.props.dispatch(deleteFavorite(id, this.props.objectType));
    } else if (!lastValue) {
      this.props.dispatch(
        upsertFavorite(
          this.props.clientId,
          this.props.userId,
          this.props.objectType,
          this.props.objectId,
        ),
      );
    }
  };
}

FavoriteIconButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  favorites: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
  objectId: PropTypes.number.isRequired,
  objectType: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;
  return {
    userId,
    clientId,
    favorites: StoreUtil.get(
      state.favorites,
      'by_user',
      `${clientId}-${userId}`,
    ),
  };
}

const styled = withStyles(styles)(FavoriteIconButton);
const connected = connect(mapStateToProps)(styled);
export { connected as FavoriteIconButton };
