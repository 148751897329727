import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Icon,
  Switch,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  DynamicTable,
  FindProviderDialog,
} from 'components';

import {
  StoreUtil,
  DateUtil,
} from 'doctivity-shared/utils';

import {
  queryProviderReports,
  upsertProviderReport,
} from 'store/actions/providerReportsActions';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

class ProviderReportsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      findProviderOpen: false,
      query: {
        where: {
          user_id: 0, // set in fetch data
        },
        include: [
          {
            association: 'client',
            attributes: ['id', 'name', 'report_day_of_month'],
          },
          {
            association: 'provider',
            attributes: ['id', 'first_name', 'last_name', 'middle_name'],
          },
        ],
        order: [
          ['last_sent_date', 'DESC'],
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.user.id !== prevProps.user.id) {
      this.fetchData();
    }
    if (this.props.editingReport !== prevProps.editingReport) {
      if (StoreUtil.hasSavedSinceLoad(this.props.editingReport, this.props.data)) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const {
      dispatch,
      user,
    } = this.props;

    const {
      query,
    } = this.state;

    query.where.user_id = user.id;

    dispatch(queryProviderReports(query));
  }

  render() {
    const {
      classes,
      data,
      app,
    } = this.props;

    const {
      findProviderOpen,
      query,
    } = this.state;

    const noDataMessage = 'No reports will be sent.';
    return (
      <Card className={classes.card}>
        <CardContent>
          <DynamicTable
            ContainerType={React.Fragment}
            header={(
              <div className={classes.headerContainer}>
                <Typography component='h2' variant='h6' color='primary' gutterBottom>
                  Provider Email Reports
                </Typography>
                <div className={classes.header} />
                <Button
                  color='primary'
                  size='small'
                  onClick={this.onAddProvider}
                >
                  <Icon className={classes.leftIcon}>add</Icon>
                  Add Provider
                </Button>
              </div>
            )}
            columns={[
              {
                label: 'Provider',
                key: 'provider',
                format: (value) => `${value.last_name}, ${value.first_name} ${value.middle_name}`,
              },
              {
                label: 'Client',
                key: 'client.name',
              },
              {
                label: 'Last Sent',
                key: 'last_sent_date',
                format: DateUtil.formatFromDB,
                style: { width: 120 },
              },
              {
                label: 'Next Send',
                key: 'client.report_day_of_month',
                format: (day) => {
                  if (!day) {
                    return '';
                  }
                  let date = moment();
                  if (moment().date() >= day) {
                    date = date.add(1, 'months');
                  }
                  date = date.date(day);
                  return DateUtil.formatDateForUser(date);
                },
                style: { width: 120 },
              },
              {
                label: 'Enabled',
                key: 'enabled',
                // eslint-disable-next-line react/no-unstable-nested-components
                format: (enabled, row) => (
                  <Switch
                    size='small'
                    color='secondary'
                    onChange={() => { this.onEnableToggle(row); }}
                    checked={enabled}
                  />
                ),
                style: { width: 80 },
              },
            ]}
            showFilters={false}
            query={query}
            data={data}
            noDataMessage={noDataMessage}
            onQueryChange={this.onQueryChange}
          />

          { findProviderOpen && (
            <FindProviderDialog
              clientId={parseInt(app.selectedClient, 10)}
              open={findProviderOpen}
              onSelect={this.onProviderSelected}
            />
          )}
        </CardContent>
      </Card>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData());
  };

  onAddProvider = () => {
    this.setState({
      findProviderOpen: true,
    });
  };

  onProviderSelected = (provider) => {
    const {
      dispatch,
      user,
      app,
    } = this.props;

    if (provider) {
      let clientId = 0;
      if (user.is_admin) {
        clientId = parseInt(app.selectedClient, 10);
      } else if (user.clients && user.clients.length > 0) {
        clientId = user.clients[0].id;
      } else {
        // what to do when not an admin and no clients?
        clientId = parseInt(app.selectedClient, 10);
      }

      dispatch(upsertProviderReport({
        user_id: user.id,
        provider_id: provider.id,
        client_id: clientId,
        enabled: true,
      }));
    }

    this.setState({
      findProviderOpen: false,
    });
  };

  onEnableToggle = (row) => {
    const {
      dispatch,
    } = this.props;

    dispatch(upsertProviderReport({
      id: row.id,
      enabled: !row.enabled,
    }));
  };
}

ProviderReportsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  data: PropTypes.object,
  editingReport: PropTypes.object,
  app: PropTypes.object,
  user: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    providerReports,
    app,
  } = state;

  return {
    app,
    data: StoreUtil.get(providerReports, StoreUtil.COMMON_TABLE),
    editingReport: StoreUtil.get(providerReports, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(ProviderReportsTable);
const connected = connect(mapStateToProps)(styled);
export { connected as ProviderReportsTable };
