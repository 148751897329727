import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ConfirmationDialog } from 'components';
import { upsertProvider } from '../store/actions/providersActions';
import { upsertContact } from '../store/actions/contactsActions';

const styles = () => ({
  textInput: {
    width: '100%',
  },
  disclaimer: {
    width: '100%',
    textAlign: 'center',
    color: '#9E9E9E',
    fontStyle: 'italic',
    fontSize: 12,
  },
});

class EditProfileNotesDialog extends Component {
  constructor(props) {
    super(props);

    const formattedNotes = Array.isArray(props.data.profile_notes)
      ? props.data.profile_notes.join('\n')
      : props.data.profile_notes;

    this.state = {
      profileNotes: formattedNotes,
      dataModified: false,
      confirmCloseOpen: false,
    };
  }

  render() {
    const { classes, open, model } = this.props;
    const { profileNotes, confirmCloseOpen } = this.state;

    const isProvider = model === 'provider';

    return (
      <>
        <Dialog
          onClose={this.onClose}
          aria-labelledby='simple-dialog-title'
          fullWidth
          open={open}
          scroll='paper'
        >
          <DialogTitle>
            Edit {isProvider ? 'Provider' : 'Contact'} Profile
          </DialogTitle>
          <DialogContent>
            <TextField
              multiline
              minRows={3}
              value={profileNotes}
              className={classes.textInput}
              onChange={this.onTextChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose}>Cancel</Button>
            <Button onClick={this.onSave} variant='contained'>
              Save
            </Button>
          </DialogActions>
          <DialogContent style={{ paddingTop: 0 }}>
            <Typography className={classes.disclaimer}>
              NOTE - profile information can be seen by everyone in your
              company. These are not private to you.
            </Typography>
          </DialogContent>
        </Dialog>

        <ConfirmationDialog
          open={confirmCloseOpen}
          title='Close Dialog?'
          content='You will lose any unsaved changes.'
          onClose={this.onCloseConfirmCancel}
          onCancel={this.onCloseConfirmCancel}
          onConfirm={this.onClose}
        />
      </>
    );
  }

  onTextChange = (event) => {
    this.setState({
      profileNotes: event.target.value,
      dataModified: true,
    });
  };

  onSave = () => {
    const { data, dispatch, model, clientId } = this.props;
    const { profileNotes } = this.state;

    data.profile_notes = profileNotes;
    if (model === 'provider') {
      data.selectedClient = clientId;
      dispatch(upsertProvider(data));
    } else {
      dispatch(upsertContact(data));
    }
  };

  onCancel = (event, reason) => {
    const { dataModified } = this.state;

    if (dataModified && reason && reason === 'backdropClick') {
      this.setState({ confirmCloseOpen: true });
      return;
    }

    this.onClose();
  };

  onClose = () => {
    const { onClose } = this.props;

    if (onClose) {
      onClose();
    }
  };

  onCloseConfirmCancel = () => {
    this.setState({ confirmCloseOpen: false });
  };
}

EditProfileNotesDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  model: PropTypes.oneOf(['provider', 'contact']).isRequired,
  data: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const { app } = state;

  return {
    clientId: app.selectedClient,
  };
}

const styled = withStyles(styles)(EditProfileNotesDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as EditProfileNotesDialog };
