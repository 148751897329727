import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import {
  Icon,
  Button,
} from '@mui/material';

const styles = (theme) => ({
  button: {
    textDecoration: 'none',
    '&:last-child': {
      flex: 1,
    },
    color: theme.palette.secondary.main,
    fontWeight: 400,
    fontSize: 15,
    padding: 0,
    minWidth: 20,
  },
  tagContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0.75rem',
    paddingRight: '2rem',
    paddingBottom: '1rem',
    paddingTop: '0',
    cursor: 'pointer',
    borderColor: theme.palette.tertiary.main,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    flexBasis: '45%',
 },
  tag: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    flexWrap: 'wrap',
    fontWeight: '400',
    lineHeight: '1.5',
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  icon: {
    color: theme.palette.secondary.accent,
    fontSize: 32,
  },
  tagNamespace: {
    fontSize: 12,
    paddingRight: 15,
    color: '#8e8e8e',
    textDecoration: 'none',
    textTransform: 'uppercase',
  },
  tagBubble: {
    cursor: 'pointer',
    fontWeight: 500,
    marginRight: theme.spacing(1),
  },
});

function Tags({ tags, classes, onClick }) {
  return (
    <div className={classes.tagContainer}>
      <div>
        <Icon className={classes.icon}>sell</Icon>

      </div>
      <div className={classes.tag}>
        <span className={classes.tagNamespace}>{tags?.[0]?.namespace.name}</span>
        <div className={classes.tags}>
          {tags?.map((tag, i) => (
            <Button
              key={tag.id}
              className={classes.button}
              onClick={() => onClick(tag.id)}
            >
              {tag.name}
              { i < tags.length - 1 ? ',' : ''}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
Tags.propTypes = {
  classes: PropTypes.object,
  tags: PropTypes.object,
  onClick: PropTypes.func,
};
const styled = withStyles(styles)(Tags);
export { styled as Tags };
