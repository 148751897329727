import React from 'react';
import { SpecialtyUtil } from 'doctivity-shared/utils';
import { AffiliationFilterForm } from './AffiliatedFilter';
import dotProp from 'dot-prop-immutable';
import axiosInstance from 'utils/axiosUtil';
import {
  CsvUtil,
  DataUtil,
} from 'doctivity-shared/utils';
import {
  AffiliatedIcon,
} from 'components';

/**
 * Format a cell value
 * @name DataMapper
 * @function
 * @param {unknown} value
 * @param {unknown} row
 * @param {string} columnName
 * @param {ColumnMapping} mapping
 */

/**
 * @typedef {Object} ColumnMapping
 * @property {string} displayName - The user-facing name (if different from the key for this entry)
 * @property {string?} helpText - Extra help text (often includes column names that will be in the download)
 * @property {string?} downloadName - The CSV column name (if different than displayName)
 * @property {string?} type - Data type (string, number, boolean)
 * @property {boolean?} displayable - Whether to include the column in the user facing table (default: true)
 * @property {boolean?} downloadable - Whether to include this column in the download (default: true)
 * @property {DataMapper?} format - Optional method to produce the output value for this column
 */

/**
 * @typedef {Object} DataDownload
 * @property {string} displayName - The user facing name of the data
 * @property {string} downloadName - The name of the download
 *   This can use the following substitutions: {date}: dd-MM-yyyy will be inserted
 * @property {string} endpoint - The endpoint which the initialQuery is valid against
 * @property {Array} initialQuery - The initial query structure
 * @property {Object?} filterForm - An optional extra form for specific filtering
 * @property {Object.<string, ColumnMapping>} columnMapping - The mapping from query column names to user-facing columns names
 **/

/** @type {DataDownload[]} */
const availableDownloads = [
  {
    displayName: 'Provider Mailing Addresses',
    helpText:
      'Download a .csv of Provider Mailing Addresses. Fields: First Name, Last Name, Credential, Location Name, Address 1, Address 2, City, State, Zip',
    endpoint: 'Providers',
    downloadName: 'doctivity-providers-address_{date}.csv',
    filterForm: AffiliationFilterForm,
    initialQuery: {
      where: {},
      include: [
        {
          association: 'clients',
          attributes: ['id', 'name'],
        },
        {
          association: 'taxonomy',
        },
        {
          association: 'location',
        },
      ],
      order: [['id', 'ASC']],
    },
    columnMapping: {
      clients: {
        displayName: '',
        downloadName: 'Affiliated',
        sortable: false,
        filter: false,
        style: { width: 50 },
        format: (v, _row, _key, mapping) => {
          if (Array.isArray(v) && v.length > 0) {
            return (typeof mapping === 'object' && mapping.downloadName !== undefined) ? 'affiliated' : <AffiliatedIcon />;
          }
        }
      },
      last_name: {
        displayName: 'Last Name',
      },
      first_name: {
        displayName: 'First Name',
      },
      'taxonomy.classification': {
        displayName: 'Specialty',
        downloadable: false,
        format: (_, row) => SpecialtyUtil.getNameFromTaxonomy(row.taxonomy),
      },
      credential: {
        displayName: 'Credential',
        displayable: false,
      },
      'location.name': {
        displayName: 'Location Name',
        displayable: false,
      },
      'location.address1': {
        displayName: 'Address',
        downloadName: 'Address 1',
      },
      'location.address2': {
        displayName: 'Address 2',
        displayable: false,
      },
      'location.city': {
        displayName: 'City',
      },
      'location.state': {
        displayName: 'State',
        format: (v) => v?.toUpperCase(),
        style: { width: 50 },
      },
      'location.postal_code': {
        displayName: 'Zip',
        format: (v) => `000000${String(v)}`.slice(-5),
        style: { width: 80 },
      },
    },
  },
];

/**
 * Returns the DynamicTable columns for the given download type
 * @param {string} index - The index of the entry in availableDownloads to extract column definitions from
 */
function getColumns(idx) {
  const entry = availableDownloads[idx];
  return Object.entries(entry.columnMapping)
    .map(([name, e]) => {
      if (e.displayable === false) return undefined;

      return {
        key: name,
        label: e.displayName ?? name,
        sortable: e.sortable,
        filter: e.filter,
        format: e.format,
        style: e.style,
      };
    })
    .filter((v) => v !== undefined);
}

/**
 * @param {DataDownload} downloadInfo
 * @param {Object} query
 */
async function getCSV(downloadInfo, query) {
  const dataQuery = DataUtil.deepCopy(query);
  dataQuery.offset = 0;
  dataQuery.limit = 2000;

  const results = await axiosInstance.get(`/${downloadInfo.endpoint}`, {
    params: {
      opts: dataQuery,
    },
  });
  if (!results || !Array.isArray(results.data?.rows)) {
    throw new Error('Unable to retrieve data for download. Please try again.');
  }
  return convertToCSV(downloadInfo, results.data.rows);
}

/**
 * @param {DataDownload} downloadInfo
 * @param {Array} data
 */
function convertToCSV(downloadInfo, rows) {
  const columnMap = Object.entries(downloadInfo.columnMapping)
    .filter(
      ([, mapping]) =>
        mapping.downloadable || mapping.downloadable === undefined,
    )
    .reduce((acc, [key, mapping]) => {
      acc[key] = mapping;
      return acc;
    }, {});
  if (!rows || !Array.isArray(rows)) {
    throw new Error('Unable to use data for download. Please try again.');
  }
  const csvData = [];
  const csvIndexMap = {};
  rows.forEach((row, idx) => {
    if (idx === 0) {
      const header = [];
      Object.keys(columnMap).forEach((key) => {
        csvIndexMap[key] = columnMap[key];
        header.push(columnMap[key].downloadName ?? columnMap[key].displayName);
      });
      csvData.push(header);
    }
    const formattedRow = [];
    Object.entries(csvIndexMap).forEach(([key, mapping]) => {
      const value = dotProp.get(row, key);
      let formatted = mapping.format
        ? mapping.format(value, row, key, mapping)
        : value;
      if (typeof formatted === 'string') {
        formatted = `"${formatted.replace('"', '\"')}"`;
      }
      formattedRow.push(formatted);
    });
    csvData.push(formattedRow);
  });
  const csv = CsvUtil.matrixToString(csvData);
  return csv;
}

export { getColumns, availableDownloads, getCSV, convertToCSV };
