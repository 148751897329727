import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { withRouter } from 'utils';
import { DynamicTable, LoadingView } from 'components';
import { StoreUtil } from 'doctivity-shared/utils';
import { queryLocations } from '../store/actions/locationsActions';

const styles = () => ({});

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Name',
    key: 'name',
    fulltext: true,
    showFilter: false,
  },
  {
    label: 'Address',
    key: 'address1',
    style: { minWidth: 120 },
    showFilter: false,
    format: (address1, location) =>
      location.address2 ? address1 + ', ' + location.address2 : address1,
    filterFormatter: (value, committed) => {
      if (committed) {
        return LocationsUtil.formatAddress1(value);
      }
      return value;
    },
  },
  {
    label: 'City',
    key: 'city',
    fulltext: true,
    showFilter: false,
  },
  {
    label: 'State',
    key: 'state',
    style: { width: 50 },
    autoCapitalize: true,
    showFilter: false,
  },
  {
    label: 'Organization',
    key: 'organization.name',
    fulltext: true,
    showFilter: false,
    style: { width: 320 },
  },
];

class SimilarLocationsTable extends Component {
  constructor(props) {
    super(props);

    const { location } = props;

    let addressMatch = '';
    const addressWords = location.address1.split(' ');
    if (addressWords.length > 0) {
      addressMatch += addressWords[0];
    }
    if (addressWords.length > 1) {
      addressMatch += ' ' + addressWords[1].charAt(0);
    }
    addressMatch += '%';

    this.state = {
      query: {
        attributes: ['id', 'name', 'address1', 'address2', 'city', 'state'],
        where: {
          id: { $ne: location.id },
          city: location.city,
          state: location.state,
          address1: { $like: addressMatch },
        },
        include: [
          {
            association: 'organization',
            attributes: ['id', 'name', 'primary_location_id'],
            required: false,
            paranoid: false,
          },
        ],
        paranoid: false,
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    const { dispatch } = this.props;
    const { query } = this.state;

    dispatch(queryLocations(query));
  }

  render() {
    const { locations } = this.props;
    const { query } = this.state;

    if (StoreUtil.isLoading(locations)) {
      return <LoadingView />;
    }

    return (
      <DynamicTable
        header='Similar Locations'
        columns={columns}
        query={query}
        data={locations}
        noDataMessage='No locations found.'
        getRowHref={this.getRowHref}
        ContainerType={React.Fragment}
        onQueryChange={this.onQueryChange}
      />
    );
  }

  getRowHref = (row) => {
    return `/locations/${row.id}`;
  };

  onQueryChange = (query) => {
    this.setState({
      query,
    }, () => {
      this.fetchData();
    });
  }
}

SimilarLocationsTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  locations: PropTypes.object,
};

function mapStateToProps(state) {
  const { locations } = state;

  return {
    locations: StoreUtil.get(locations, StoreUtil.COMMON_TABLE),
  };
}

const styled = withStyles(styles)(SimilarLocationsTable);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as SimilarLocationsTable };
