import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  ListSubheader,
} from '@mui/material';
import {
  FindCodesDialog,
} from 'codes';
import {
  listClaimsServicelines,
} from 'store/actions/claimsActions';
import { ServiceLineUtil } from 'utils';

const styles = (theme) => ({
  filterDropDown: {
    width: 168,
    marginRight: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      minWidth: '100%',
    },
  },
  parentServiceline: {
    borderTop: '1px solid #cccccc',
  },
  subServiceline: {
    fontSize: 14,
    paddingLeft: theme.spacing(4),
  },
  select: {
    '& .MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover': {
      backgroundColor: '#00a887',
      color: 'white',
    },
  }
});

class ServiceLineSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      findCodesOpen: false,
    };
  }

  componentDidMount() {
    const { servicelines, setFindServiceLine } = this.props;

    if (!servicelines) {
      this.fetchData();
    }

    if (setFindServiceLine) {
      setFindServiceLine(this.findServiceline);
    }
  }

  fetchData() {
    this.props.dispatch(listClaimsServicelines());
  }

  render() {
    const {
      classes,
      multiple,
      hideChildlessTopLevels,
      showSpecificServicelineIds,
    } = this.props;

    const menuItems = [];
    if (this.props.servicelines && this.props.servicelines.length > 0) {
      let servicelines = this.props.servicelines;

      if (
        Array.isArray(showSpecificServicelineIds) &&
        showSpecificServicelineIds.length > 0
      ) {
        servicelines = servicelines.filter((serviceline) =>
          showSpecificServicelineIds.includes(serviceline.id)
        );
      }

      if (this.props.allowCodes) {
        let codesTypeLabel = 'By Codes';
        if (this.props.codesType === 'px') {
          codesTypeLabel = `By Px Codes (${this.props.codes.length})`;
        } else if (this.props.codesType === 'dx') {
          codesTypeLabel = `By Dx Codes (${this.props.codes.length})`;
        }
        menuItems.push(
          <MenuItem value='codes' key='codes'>
            <em>{codesTypeLabel}</em>
          </MenuItem>
        );
      }

      if (this.props.showAll) {
        menuItems.push(
          <MenuItem value='all' key='all'>
            <em>All</em>
          </MenuItem>
        );
      }

      servicelines.forEach((s) => {
        if (!hideChildlessTopLevels || s.children?.length > 0) {
          menuItems.push(
            <MenuItem
              value={s.id}
              key={s.id}
              className={classes.parentServiceline}
            >
              {s.name}
            </MenuItem>
          );
        }
        if (this.props.showSubServicelines) {
          s.children.forEach((ss) => {
            menuItems.push(
              <MenuItem
                value={ss.id}
                key={ss.id}
                className={classes.subServiceline}
              >
                {ss.name}
              </MenuItem>
            );
          });
        }
      });
    }

    let selected = this.props.value;
    if (this.props.allowCodes && (this.props.codesType === 'dx' || this.props.codesType === 'px')) {
      selected = ['codes'];
    }
    return (
      <>
        <FormControl
          variant='outlined'
          className={classes.filterDropDown}
          key='filter-serviceline'
        >
          <InputLabel>Service Line</InputLabel>
          <Select
            value={selected || null}
            onChange={this.onServicelineChange}
            label='Service Line'
            multiple={multiple}
            open={this.state.open}
            onOpen={this.onOpen}
            onClose={this.onClose}
            MenuProps={{
              PaperProps: {
                className: classes.select,
              }
            }}
          >
            {multiple && <ListSubheader 
              style={{ 
                borderBottom: '1px solid black', 
                fontSize: 14, 
                fontWeight: 500,
                color: '#000',
              }}
            >
              {selected.length} selected
            </ListSubheader>}
            {menuItems}
          </Select>
        </FormControl>
        {this.state.findCodesOpen && (
          <FindCodesDialog
            open={this.state.findCodesOpen}
            onClose={this.onCodesChange}
            codes={this.props.codes}
            codesType={this.props.codesType}
          />
        )}
      </>
    );
  }

  findServiceline(id) {
    return ServiceLineUtil.findServiceLine(this.props.servicelines, id);
  }

  onOpen = () => {
    this.setState({
      open: true,
    });
  };

  onClose = () => {
    this.setState({
      open: false,
    });
  };

  onServicelineChange = (event) => {
    const { multiple, value } = this.props;
    if (multiple) {
      let ids = event.target.value;
      if (ids.length > 0) {
        if (ids.includes('all')) {
          if (ids[ids.length - 1] === 'all') {
            ids = ['all']; // all was latest, make it only
          } else {
            ids = ids.filter((id) => id !== 'all'); // remove all on new select
          }
        } else if (ids.includes('codes')) {
          if (ids[ids.length - 1] === 'codes') {
            ids = ['codes']; // codes was latest, make it only
            return this.setState({ findCodesOpen: true });
            // Z20.828, Z20.822 , R09.81,R07.0
            // return this.props.onChange(['Z20.828', 'Z20.822', 'R09.81', 'R07.0'], 'dx_codes');
          } else {
            ids = ids.filter((id) => id !== 'codes'); // remove codes on new select
          }
        } else {
          const newValue = event.target.value.find(
            (selectedValue) => !value.includes(selectedValue)
          );
          if (newValue) {
            const serviceline = this.findServiceline(newValue);
            if (serviceline.parent_id) {
              ids = ids.filter((id) => id !== serviceline.parent_id);
            } else {
              ids = ids.filter(
                (id) =>
                  !serviceline.children.find(
                    (serviceline) => serviceline.id === id
                  )
              );
            }
          }
        }
        this.props.onChange(ids, 'servicelines');
      } else {
        // last item was deselected
        if (this.props.codesType === 'dx' || this.props.codesType === 'px') {
          return this.setState({ findCodesOpen: true });
        }
      }
    } else {
      const id = event.target.value;
      const serviceline = this.findServiceline(id);
      if (serviceline) {
        this.props.onChange(serviceline.id, serviceline.parent_id);
      } else {
        this.props.onChange(id);
      }
    }
  };

  onCodesChange = (codesType, codes) => {
    if (codes && codes.length > 0) {
      this.props.onChange(codes, codesType);
      this.setState({ open: false });
    } else {
      // cancel back to previous state
    }
    // close dialog
    this.setState({
      findCodesOpen: false,
      open: false,
    })
  };
}

ServiceLineSelect.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  showAll: PropTypes.bool,
  showSubServicelines: PropTypes.bool,
  hideChildlessTopLevels: PropTypes.bool,
  multiple: PropTypes.bool,
  allowCodes: PropTypes.bool,
  codes: PropTypes.array,
  codesType: PropTypes.string,
  servicelines: PropTypes.array,
  setFindServiceLine: PropTypes.func,
  showSpecificServicelineIds: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
};

function mapStateToProps(state) {
  return {
    servicelines: state.claims && state.claims.servicelines_grouped,
  };
}

const styled = withStyles(styles)(ServiceLineSelect);
const connected = connect(mapStateToProps)(styled);
export { connected as ServiceLineSelect };
