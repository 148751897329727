import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_PROVIDER_TAGS_TABLE,
  API_PROVIDER_TAGS_LIST,
  API_PROVIDER_TAGS_UPSERT,
  API_PROVIDER_TAGS_GET,
  API_PROVIDER_TAGS_PROVIDERS,
} from 'store/actions/providerTagsActions';

export const providerTags = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_PROVIDER_TAGS_TABLE,
    API_PROVIDER_TAGS_LIST,
    API_PROVIDER_TAGS_UPSERT,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceSingleGet(state, action, API_PROVIDER_TAGS_GET);
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceQuery(state, action, API_PROVIDER_TAGS_PROVIDERS, 'providers');
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
