import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  label: {
    fontSize: 12,
    color: '#8E8E8E',
    lineHeight: 1.5,
    marginTop: '0.5em',
  },
});

class OverlineLabel extends React.Component {
  render() {
    const {
      classes,
      children,
    } = this.props;

    return (
      <Typography className={classes.label}>
        {children}
      </Typography>
    );
  }
}

OverlineLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.string,
};
const styled = withStyles(styles)(OverlineLabel);
export { styled as OverlineLabel };
