/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { List, ListItemButton, ListItemText, Typography } from '@mui/material';
import { StoreUtil } from 'doctivity-shared/utils';
import { MarketsUtil } from 'utils';
import {
  listMarkets,
} from 'store/actions/marketsActions';
import LockIcon from '@mui/icons-material/Lock';

const styles = (theme) => ({
  main: {
    maxHeight: '25rem',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '50%',
  },
  options: {
    justifySelf: 'flex-end',
    alignSelf: 'flex-end',
  },
  lockIcon: {
    fontSize: 'inherit',
    marginRight: 2,
    marginTop: 'auto',
    marginBottom: 'auto',
    paddingRight: 6,
    color: '#999999',
  },
  parentServiceline: {
    borderTop: '1px solid #cccccc',
  },
  subServiceline: {
    fontSize: 14,
    paddingLeft: theme.spacing(4),
  },
  marketList: {
    overflowY: 'scroll',
    paddingTop: 0,
    flexGrow: 1,
  },
  listItem: {
    '&.MuiListItemButton-root': {
      '& p': {
        display: 'flex',
        flexDirection: 'row',
        justtifyContent: 'flex-start',
      },
      border: `1px solid ${theme.palette.tertiary.main}`,
      borderRadius: 4,
    },
  },
});

class MarketsSelectList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      market: props.selectedMarket,
    };
  }

  componentDidMount() {
    if (
      !this.props.markets ||
      StoreUtil.needsLoadLongCache(this.props.markets)
    ) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (
      this.props.clientId !== prevProps.clientId ||
      this.props.marketsClientId !== this.props.clientId
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(listMarkets(this.props.clientId));
  }

  render() {
    const { classes, market } = this.props;

    const markets = StoreUtil.getData(this.props.markets);

    return (
      <div className={classes.main}>
        <List className={classes.marketList} component='nav'>
          {markets?.map((m) => (
            <ListItemButton
              className={classes.listItem}
              selected={m?.id === market?.id}
              key={`market-${m.id}`}
              onClick={() => this.onSelectMarket(m)}
            >
              <Typography noWrap>
                <LockIcon
                  className={classes.lockIcon}
                  style={{
                    visibility: m.is_private ? 'visible' : 'hidden',
                  }}
                />
                <ListItemText primary={m.name} />
              </Typography>
            </ListItemButton>
          ))}
        </List>
      </div>
    );
  }
  getMarket(id = null) {
    const markets = StoreUtil.getData(this.props.markets);
    if (markets && markets.length > 0) {
      return markets.find((market) => market.id === id);
    }
    return null;
  }

  onSelectMarket = (mkt) => {
    this.props.onChange?.(mkt);
  };
}

MarketsSelectList.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  clientId: PropTypes.number,
  marketsClientId: PropTypes.number,
  markets: PropTypes.object,
  market: PropTypes.object,
  selectedMarket: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    marketsClientId: state.markets.clientId,
    markets: MarketsUtil.getMarkets(state),
    selectedMarket: MarketsUtil.getSelectedMarket(state),
  };
}

const styled = withStyles(styles)(MarketsSelectList);
const connected = connect(mapStateToProps)(styled);
export { connected as MarketsSelectList };
