import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Button,
  Icon,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Divider,
} from '@mui/material';
import {
  LoadingView,
  OverlineLabel,
  UnderlineData,
  ClaimsProviders,
  ClaimsByServiceline,
  FavoriteIconButton,
  FixedHeader,
  StickyHeader,
  BreadcrumbRow,
  IconGridItem,
  ClickToCopy
} from 'components';
import { PhoneUtil, StoreUtil, PatientTypes } from 'doctivity-shared/utils';
import { withRouter, withAppbarHeight } from 'utils';
import {
  loadOrganization,
  upsertOrganization,
} from 'store/actions/organizationsActions';
import { listClaimsByOrganization } from 'store/actions/claimsActions';

import {
  OrganizationEditDialog,
  OrganizationMigrationPage,
 } from 'organizations';
import { LocationsTable } from 'locations';
import { CodesPage } from 'codes';
import OrganizationIcon from 'assets/images/organization_icon.svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { OrganizationTagList } from 'tags';
import { ClaimsDateProvider } from 'components';

const styles = (theme) => ({
  leftIcon: {
    marginRight: theme.spacing(),
  },
  cardContainer: {
    marginTop: theme.spacing(3),
  },
  linksContainer: {
    position: 'absolute',
    textAlign: 'right',
    top: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      top: 48,
      right: 0,
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderColor: theme.palette.tertiary.main,
    maxWidth: 380,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      borderRight: 'none',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
  },
  detailsCard: {
    position: 'relative',
    display: 'flex',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cardDecorationBar: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary.accent,
    width: 13,
    top: 0,
    left: 0,
    height: '100%',
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    backgroundColor: theme.palette.tertiary.main,
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.tertiary.main,
    paddingLeft: 58,
    paddingBottom: 3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      alignItems: 'start',
    },
  },
  headerBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'normal'
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    fontSize: 'clamp(16px, 2.5vw, 24px)',
    paddingRight: 30,
  },
  headerItem: {
    fontSize: 14,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  headerItemNoPadding: {
    fontSize: 14,
    paddingRight: 15,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  headerItemSpacing: {
    marginLeft: theme.spacing(0),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(4.25)
    }
  },
  headerEmptySpacer: {
    paddingBottom: 21
  },
  location: {
    color: theme.palette.primary.main,
  },
  favIcon: {
    [theme.breakpoints.down('md')]: {
      marginTop: -6.5
    },
  },
  headerIcon: {
    color: theme.palette.secondary.accent,
    marginRight: theme.spacing(1),
    paddingTop: 4,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0
    },
  },
  tabs: {
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.tertiary.main,
  },
  spacer: {
    flex: 1,
  },
  headerDivider: {},
  tabPanel: {},
  container: {
    padding: theme.spacing(3),
  },
  verticalSpacer: {
    marginBottom: theme.spacing(3),
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  affiliateIcon: {
    fontSize: 20,
    verticalAlign: 'text-top',
    marginRight: theme.spacing(1),
    paddingTop: 7,
    [theme.breakpoints.down('md')]: {
      marginTop: -6.5
    },
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.bright,
    },
  },
  organizationIcon: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: 110,
  },
  organizationDetailsTitle: {
    color: theme.palette.primary.dark,
    flexShrink: 0,
    font: 'normal normal 24px/37px Roboto',
    textAlign: 'center',
  },
  editButton: {
    color: theme.palette.secondary.main,
  },
  detailsFields: {
    paddingLeft: theme.spacing(5),
    paddingRight: 100,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  detailsRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: theme.palette.tertiary.main,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      borderBottom: 'none',
    },
  },
  detailsItem: {
    flexBasis: '50%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: theme.palette.tertiary.main,
    },
  },
  phoneRow: {
    display: 'flex',
    alignItems: 'start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  phoneColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  organizationButton: {
    color: theme.palette.secondary.main,
    padding: 0,
    fontWeight: 400,
    fontSize: 13,
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
  },
});

class OrganizationDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editDialogOpen: false,
      headerHeight: 0,
      stickyTab: undefined,
      totalPatients: null,
    };
  }

  componentDidMount() {
    const { organization, claims } = this.props;

    if (StoreUtil.needsLoadNoCache(organization)) {
      this.fetchOrganization();
    }

    if (StoreUtil.needsLoadNoCache(claims)) {
      this.fetchClaims();
    }
  }

  componentDidUpdate(prevProps) {
    const { clientId, editing, organization, patientType, params } = this.props;

    // is object getting edited?
    if (
      params.organizationId !== prevProps.params.organizationId ||
      clientId !== prevProps.clientId
    ) {
      this.fetchOrganization();
    } else if (
      (StoreUtil.getData(editing) && editing !== prevProps.editing) ||
      editing !== prevProps.editing
    ) {
      // if so and it is newr than the last update
      if (StoreUtil.hasSavedSinceLoad(editing, organization)) {
        this.fetchOrganization();
      }
    }

    if (
      params.organizationId !== prevProps.params.organizationId ||
      patientType != prevProps.patientType
    ) {
      if (patientType !== 'CAPTURED_CLAIMS') {
        this.setState({ totalPatients: null });
      }
      this.fetchClaims();
    }
    if (this.state.totalPatients === null && 
      this.props.claims !== prevProps.claims &&
      StoreUtil.isLoaded(this.props.claims)) {
      let totalPatients = 0;
      const { claims } = this.props;
      if (this.hasClaimsAccess() && claims) {
        claims?.data?.forEach((d) => {
          if (d.serviceline && !d.serviceline.parent_id && d.total_patients > 0) {
            totalPatients += d.total_patients;
          }
        });
      }
      if (totalPatients) {
        if (totalPatients > 250) {
          totalPatients = Math.floor(Math.floor(totalPatients / 100) * 100);
        } else if (totalPatients > 10) {
          totalPatients = Math.floor(Math.floor(totalPatients / 10) * 10);
        }
      }
      this.setState({ totalPatients });
    }
  }

  fetchOrganization() {
    const { dispatch, clientId } = this.props;

    dispatch(loadOrganization(this.getOrgId(), clientId));
  }

  fetchClaims() {
    const { dispatch, patientType } = this.props;

    if (this.hasClaimsAccess()) {
      dispatch(listClaimsByOrganization(this.getOrgId(), patientType));
    }
  }

  getOrgId() {
    return parseInt(this.props.params.organizationId, 10);
  }

  hasClaimsAccess() {
    const { clientId, clients } = this.props;

    if (!this.client || this.client.id !== clientId) {
      this.client = null;
      if (clientId && StoreUtil.isLoaded(clients)) {
        const data = StoreUtil.getData(clients);
        this.client = data.find((c) => c.id === clientId);
      }
    }
    return this.client && this.client.has_claims_access;
  }

  render() {
    const {
      user,
      organization,
      claims,
      classes,
      editing,
      params,
      appbarHeight,
      patientType,
    } = this.props;
    const { totalPatients, headerHeight, stickyTab } = this.state;

    const currentTab = params.tab || 'details';

    if (
      !organization ||
      !StoreUtil.isLoaded(organization) ||
      organization.data.id !== this.getOrgId() ||
      StoreUtil.isSaving(editing)
    ) {
      return <LoadingView />;
    }

    if (!this.hasClaimsAccess()) {
      return <LoadingView />;
    }
    const specialty = '';

    const orgData = StoreUtil.getData(organization);

    let locationStr = '';
    const location = orgData.primary_location;
    if (location && location.city && location.state) {
      locationStr = `${location.city}, ${location.state}`;
    } else if (location && location.city) {
      locationStr = location.city;
    } else if (location && location.state) {
      locationStr = location.state;
    }

    return (
      <div>
        <Helmet defer={false}>
          <title>{orgData.name}</title>
        </Helmet>
        <FixedHeader onChangeHeaderHeight={this.onChangeHeaderHeight}>
          <div className={classes.breadcrumb}>
            <BreadcrumbRow
              label='Organizations'
              path='/organizations'
              icon='chevron_left'
            />
          </div>
          <div className={classes.header}>
            <Icon className={classes.headerIcon}>apartment</Icon>
            <Typography className={classes.headerTitle} style={{}}>
              {orgData.name}
            </Typography>

            <div className={classes.spacer} />
            <div className={classes.icons}>
              {orgData.affiliated && orgData.affiliated.length > 0 && (
                <Icon className={classes.affiliateIcon}>hub</Icon>
              )}
              <div className={classes.favIcon}>
                <FavoriteIconButton
                  objectType='Organizations'
                  objectId={orgData.id}
                />
              </div>
            </div>
          </div>
          <div className={classes.headerContent}>
            {specialty != '' || location ? (
              <div>
                <Typography className={classes.headerItemNoPadding}>
                  {specialty}
                </Typography>
                <Typography
                  className={`${classes.headerItem} ${classes.location}`}>
                  {locationStr}
                </Typography>
              </div>
            ) : (
              <div className={classes.headerEmptySpacer} />
            )}
          </div>
          <Divider
            style={{
              borderColor: stickyTab === false ? '#0000001f' : '#EDEDED',
              transition: 'borderColor 0.1s ease-in',
            }}
          />
        </FixedHeader>
        <StickyHeader
          headerTop={headerHeight + appbarHeight}
          onChangeIsSticky={this.onChangeIsSticky}
        >
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            value={currentTab}
            onChange={this.onTabChange}
          >
            <Tab label='Details & Locations' value='details' disableRipple />
            {this.hasClaimsAccess() && (
              <Tab label='Market Data' value='market' disableRipple />
            )}
            {this.hasClaimsAccess() && (
              <Tab label='Patient Migration' value='migration' disableRipple />
            )}
            {this.hasClaimsAccess() && (
              <Tab label='Captured Codes' value='codes' disableRipple />
            )}
          </Tabs>
          <Divider className={classes.headerDivider} />
        </StickyHeader>

        <div className={classes.container}>
          {currentTab !== 'details' && <ClaimsDateProvider />}
          {currentTab === 'details' && (
            <>
              <Card>
                <CardContent className={classes.detailsCard}>
                  <div className={classes.cardDecorationBar} />
                  <div className={classes.titleContainer}>
                    <Typography
                      noWrap
                      className={classes.organizationDetailsTitle}
                    >
                      Organization Details
                    </Typography>
                    <img
                      src={OrganizationIcon}
                      alt='Organization Icon'
                      className={classes.organizationIcon}
                    />
                    <OverlineLabel>FACILITY TYPE</OverlineLabel>
                    <UnderlineData>{orgData.facility_type}</UnderlineData>
                    <OverlineLabel>SPECIALTY</OverlineLabel>
                    <UnderlineData>{orgData.specialty}</UnderlineData>
                  </div>

                  <div className={classes.detailsFields}>
                    <div className={classes.detailsRow}>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='people'>
                          {this.hasClaimsAccess() && (
                            <>
                              <OverlineLabel>PROJECTED {patientType === 'PEDIATRIC_PATIENTS' ? 'PEDIATRIC' : ''} PATIENTS</OverlineLabel>
                              <UnderlineData>
                                ~ {(totalPatients ?? '0').toLocaleString()}
                              </UnderlineData>
                            </>
                          )}
                        </IconGridItem>
                      </div>
                    </div>
                    <div className={classes.detailsRow}>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='smartphone'>
                          <div className={classes.phoneRow}>
                            <div className={classes.phoneColumn}>
                              <OverlineLabel>PHONE</OverlineLabel>
                              <UnderlineData>
                                {PhoneUtil.formatNumber(orgData.phone)}
                              </UnderlineData>
                            </div>
                          </div>
                        </IconGridItem>
                      </div>
                    </div>
                    <div className={classes.detailsRow}>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='tag'>
                            <>
                              <OverlineLabel>NPI</OverlineLabel>
                              <UnderlineData>
                                <ClickToCopy>
                                  {this.props.organization.data.npi}
                                </ClickToCopy>  
                              </UnderlineData>
                            </>
                        </IconGridItem>
                      </div>
                    </div>
                  </div>

                  {user.is_admin && (
                    <div className={classes.linksContainer}>
                      <Button
                        variant='text'
                        color='primary'
                        onClick={this.onOpenEditDialog}
                        className={classes.editButton}
                      >
                        <EditOutlinedIcon className={classes.leftIcon} />
                        Edit
                      </Button>
                    </div>
                  )}
                </CardContent>
              </Card>
              <div className={classes.cardContainer}>
                <OrganizationTagList organization={orgData} />
              </div>
 
              <div className={classes.verticalSpacer} />
              <LocationsTable organization={orgData} />
            </>
          )}
          {currentTab === 'market' && this.hasClaimsAccess() && (
            <>
              <ClaimsByServiceline
                claims={claims}
                isProvider={false}
                name={orgData.name}
              />
              <div className={classes.cardContainer} />
              <ClaimsProviders organizationId={this.getOrgId()} />
            </>
          )}
          {currentTab === 'codes' && this.hasClaimsAccess() && (
            <CodesPage organization={orgData} />
          )}
          {currentTab === 'migration' && this.hasClaimsAccess() && (
            <OrganizationMigrationPage organization={orgData} />
          )}
        </div>
        {this.state.editDialogOpen && (
          <OrganizationEditDialog
            open={this.state.editDialogOpen}
            onSave={this.onSaveOrganization}
            onClose={this.onCloseEditDialog}
            organizationData={orgData}
          />
        )}
      </div>
    );
  }

  onTabChange = (event, newValue) => {
    this.props.router.navigate(`/organizations/${this.getOrgId()}/${newValue}`);
  };

  onOpenEditDialog = () => {
    this.setState({
      editDialogOpen: true,
    });
  };

  onCloseEditDialog = () => {
    this.setState({
      editDialogOpen: false,
    });
  };

  onSaveOrganization = (organization) => {
    const { dispatch, clientId } = this.props;

    organization.change_affiliation = {
      client_id: clientId,
      affiliated: organization.is_affiliated,
    };

    dispatch(upsertOrganization(organization));
    this.onCloseEditDialog();
  };

  onChangeHeaderHeight = (headerHeight) => {
    this.setState({ headerHeight });
  };

  onChangeIsSticky = (isSticky) => {
    this.setState({ stickyTab: isSticky });
  };
}

OrganizationDetailPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  appbarHeight: PropTypes.number.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  clientId: PropTypes.number,
  user: PropTypes.object,
  clients: PropTypes.object,
  organization: PropTypes.object,
  editing: PropTypes.object,
  claims: PropTypes.object,
  patientType: PropTypes.oneOf(PatientTypes),
};

function mapStateToProps(state, props) {
  return {
    user: state.user,
    app: state.app,
    clientId: state.app.selectedClient,
    organization: StoreUtil.get(
      state.organizations,
      StoreUtil.COMMON_ITEM,
      props.params.organizationId
    ),
    editing: StoreUtil.get(state.organizations, StoreUtil.COMMON_EDIT_ITEM),
    claims: StoreUtil.get(
      state.claims,
      'claims_by_organization',
      props.params.organizationId
    ),
    clients: StoreUtil.get(state.clients, StoreUtil.COMMON_LIST),
    patientType: state.app.patientType,
  };
}

const styled = withStyles(styles)(withAppbarHeight(OrganizationDetailPage));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as OrganizationDetailPage };
