class CsvUtil {
  // Parse a CSV row, accounting for commas inside quotes
  static parseRowToColumns(row) {
    let insideQuote = false,
      entries = [],
      entry = [];
    row.split('').forEach((character) => {
      if (character === '"') {
        insideQuote = !insideQuote;
      }
      if (character === ',' && !insideQuote) {
        entries.push(entry.join(''));
        entry = [];
      } else {
        entry.push(character);
      }
    });
    entries.push(entry.join(''));
    return entries;
  }

  static parseStringToRows(string) {
    let insideQuote = false,
      entries = [],
      entry = [];
    string.split('').forEach((character) => {
      if (character === '"') {
        insideQuote = !insideQuote;
      }
      if (character === '\n' && !insideQuote) {
        entries.push(entry.join(''));
        entry = [];
      } else {
        entry.push(character);
      }
    });
    entries.push(entry.join(''));
    return entries;
  }

  static stringToMatrix(csvString) {
    const rows = CsvUtil.parseStringToRows(csvString);
    return rows.map((row) => CsvUtil.parseRowToColumns(row));
  }

  static matrixToString(matrix) {
    return matrix.map((row) => row.join(',')).join('\n');
  }
}

export { CsvUtil };
export default CsvUtil;