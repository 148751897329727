import React from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import { Card, CardContent, Chip, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  TitleTooltip,
  LoadingView,
  ServiceLineSelect,
  SmallIconTextButton,
  // DataFilter,
} from 'components';
import { ServiceLineUtil, StringUtil, withIsMobile } from 'utils';
import { StoreUtil, PatientTypes } from 'doctivity-shared/utils';

import { FindOrganizationDialog } from 'organizations';

import { showDialog, hideDialog } from 'store/actions/systemActions';

import { listClaimsByOrganization } from 'store/actions/claimsActions';
import { PatientTypeSelect } from './PatientTypeSelect';
import { ColorUtil } from 'utils';

const LIMIT_SERVICELINES = 25;

const styles = (theme) => ({
  filters: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  filter: {
    marginRight: theme.spacing(),
    minWidth: 168,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  noData: {
    textAlign: 'center',
    paddingTop: 50,
    minHeight: 200,
  },
  lastFilter: {
    marginBottom: 0,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  back: {
    marginTop: theme.spacing(2),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

/*
const series = [
  {
    name: 'Ambulatory Surgery Center',
    data: [36, 0, 0, 0, 0, 0, 36],
  },
  {
    name: 'Outpatient',
    data: [0, 0, 50, 15, 13, 0, 94],
  },
  {
    name: 'Inpatient',
    data: [0, 28, 35, 0, 0, 58, 131],
  },
  {
    name: 'Office',
    data: [0, 0, 0, 11, 0, 197, 197],
  },
];

const categories = [
  'Foot',
  'Hip Revision Replacement',
  'Knee Revision Replacement',
  'General Medical Orthopedics',
  'Sports Medicine',
  'General Surgical Orthopedics',
  'Orthopedics',
];
*/
class ClaimsByServiceline extends React.Component {
  constructor(props) {
    super(props);

    this.cardElement = React.createRef(null);

    this.state = {
      compareOrg: null,
      serviceline: 'all',
    };
  }

  fetchCompareOrgData(org) {
    this.props.dispatch(
      listClaimsByOrganization(org.id, this.props.patientType)
    );
    this.setState({ compareOrg: org });
  }

  render() {
    const { classes, claims: storeClaims, isProvider, servicelines } = this.props;
    const claims = storeClaims?.claims ?? storeClaims?.data ?? [];
    const { serviceline } = this.state;
    let isLoading = StoreUtil.isLoading(storeClaims);

    let compareByServiceline = null;
    if (this.state.compareOrg) {
      const compareClaims = StoreUtil.get(
        this.props.claimsState,
        'claims_by_organization',
        parseInt(this.state.compareOrg.id, 10)
      );

      if (!StoreUtil.isLoaded(compareClaims)) {
        isLoading = true
      }

      compareByServiceline = !isLoading && this.groupByServiceline(
        StoreUtil.getData(compareClaims),
        false
      );
    }

    const categories = [];
    let series = [
      // the order of these can NOT change it matches the id numbers for location_type
      {
        name: 'Ambulatory Surgery Center',
        data: [],
        unknown: []
      },
      {
        name: 'Outpatient',
        data: [],
        unknown: []
      },
      {
        name: 'Office',
        data: [],
        unknown: []
      },
      {
        name: 'Inpatient',
        data: [],
        unknown: []
      },
      {
        name: 'Lab',
        data: [],
        unknown: []
      },
    ];

    if (this.props.patientType === 'CAPTURED_CLAIMS') {
      series = [{ name: 'Claims', data: [], unknown: [] }];
    }

    const byServiceline = this.groupByServiceline(claims);

    Object.keys(byServiceline).forEach((sl) => {
      const servicelineOuter = byServiceline[sl];
      const servicelineObjects = Object.values(servicelineOuter);
      let serviceline = null;
      if (servicelineObjects.length > 0) {
        serviceline = servicelineObjects[0].serviceline;
      }
      let isParent = false;
      if (serviceline) {
        isParent = serviceline.parent_id === null;
      }
      categories.push({ label: sl, isParent, servicelineId: serviceline?.id });
      this.addToSeries(series, byServiceline, sl);

      if (compareByServiceline) {
        categories.push({ label: sl, compare: true });
        this.addToSeries(series, compareByServiceline, sl);
      }
    });

    const topLevelServicelines = this.groupByServiceline(claims, false, true);
    const showSpecificServicelineIds =
      serviceline === 'all' ? [] : [serviceline];
    Object.keys(topLevelServicelines).forEach((sl) => {
      const servicelineOuter = topLevelServicelines[sl];
      const servicelineObjects = Object.values(servicelineOuter);
      let serviceline = null;
      if (servicelineObjects.length > 0) {
        serviceline = servicelineObjects[0].serviceline;
      }
      this.calculateShowTopLevel(
        series,
        topLevelServicelines,
        sl,
        serviceline?.id,
        showSpecificServicelineIds
      );
    });

    const rowCount = categories.length;

    const hasChildren =
      serviceline === 'all'
        ? true
        : ServiceLineUtil.hasChildren(servicelines, serviceline);

    // Create a "fake" version of the series data to show < 10 labels
    const modifiedSeriesData = series.map((innerSeries) => {
      return {
        name: innerSeries.name,
        data: innerSeries.data.map((value, index) => {
          let total = series.reduce((sum, rSeries) => sum + rSeries.data[index], 0);

          return {
            x: innerSeries.name,
            y: value,
            stackTotal: total,
            isUnknown: innerSeries.unknown.filter((v) => v === index).length > 0
          }
        })
      }
    });
    isLoading = isLoading || !this.props.claimsState || 
      StoreUtil.isLoading(this.props.claimsState?.claims_list ?? {}) ||
      StoreUtil.isLoading(this.props.claimsState?.payers_list ?? {}) || 
      StoreUtil.isLoading(this.props.claimsState?.servicelines_list ?? {}) || 
      StoreUtil.isLoading(this.props.claimsState?.servicelines_grouped ?? {});
    return (
        <Card ref={this.cardElement}>
          <CardContent>
            {isProvider ? (
              <TitleTooltip
                title='Patients by Service Line'
                tooltip='Displaying number of claims or projected patients by selected Service Lines.'
              />
            ) : (
              <TitleTooltip
                title='Patients by Service Line'
                tooltip='Displaying number of claims or projected patients by selected Service Lines.'
                actionIcon='compare_arrows'
                actionText='compare'
                onAction={this.onCompare}
              />
            )}
            <div className={classes.filters}>
              <div className={classes.filter}>
                <ServiceLineSelect
                  hideChildlessTopLevels
                  showSpecificServicelineIds={
                    serviceline === 'all'
                      ? showSpecificServicelineIds
                      : undefined
                  }
                  showAll
                  value={serviceline}
                  onChange={this.onServicelineChange}
                />
              </div>
              <div className={clsx(classes.filter, classes.lastFilter)}>
                <PatientTypeSelect />
              </div>
            </div>
            {serviceline !== 'all' && (
              <div className={classes.back}>
                <SmallIconTextButton
                  icon='chevron_left'
                  text='Top Level Service Lines'
                  onClick={() => this.onServicelineChange('all')}
                />
              </div>
            )}

            {this.state.compareOrg && (
              <Chip
                label={`Comparing ${this.state.compareOrg.name}`}
                onDelete={() => {
                  this.onCompareOrganization(null);
                }}
                variant='outlined'
              />
            )}
            {isLoading && <LoadingView />}
            {!isLoading && rowCount === 0 && hasChildren && (
              <Typography className={classes.noData}>
                No shared patients.
              </Typography>
            )}
            {!isLoading && rowCount === 0 && !hasChildren && (
              <Typography className={classes.noData}>
                No Sub-Service Lines available for this Service Line
              </Typography>
            )}
            {!isLoading && rowCount > 0 && (
              <Chart
                type='bar'
                height={Math.max(50 + rowCount * 26, 180)}
                options={{
                  chart: {
                    id: 'ClaimsByServiceline',
                    toolbar: {
                      show: false,
                    },
                    animations: {
                      enabled: false,
                    },
                    events: {
                      click: this.onChartClick,
                    },
                    stacked: true,
                  },
                  yaxis: {
                    labels: {
                      formatter: (obj) => {
                        return (obj.compare ? ' ' : obj.label);
                      },
                      minWidth: 100,
                    },
                  },
                  xaxis: {
                    categories,
                    labels: {
                      hideOverlappingLabels: true,
                      rotate: -45,
                      formatter: (val) => {
                        return StringUtil.numberForChart(val);
                      },
                    },
                    tickPlacement: 'on',
                  },
                  tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false,
                    x: {
                      formatter: (x) => {
                        if (this.state.compareOrg) {
                          if (x.compare) {
                            return `${this.state.compareOrg.name}<br />${x.label}`;
                          }
                          return `${this.props.name}<br />${x.label}`;
                        }
                        return `${x.label}`;
                      },
                    },
                    y: {
                      formatter: (val, opts) => {
                        let isUnknown = modifiedSeriesData[opts.seriesIndex].data[opts.dataPointIndex].isUnknown;
                        if (isUnknown) {
                          return '< 11';
                        }
                        let originalValue = series[opts.seriesIndex].data[opts.dataPointIndex];
                        return StringUtil.numberForChart(originalValue);
                      }
                    },
                  },
                  plotOptions: {
                    bar: {
                      horizontal: true
                    },
                  },
                  dataLabels: {
                    enabled: true,
                    dropShadow: {
                      enabled: true,
                      left: 0,
                      top: 0,
                      opacity: 0.4,
                    },
                    formatter: (val, opts) => {
                      let isUnknown = modifiedSeriesData[opts.seriesIndex].data[opts.dataPointIndex].isUnknown;
                      if (isUnknown) {
                        return '< 11';
                      }

                      let originalValue = series[opts.seriesIndex].data[opts.dataPointIndex];
                      return StringUtil.numberForChart(originalValue);
                    },
                    offsetY: 7,
                  },
                }}
                series={modifiedSeriesData}
                colors={ColorUtil.COLORS}
              />
            )}
          </CardContent>
        </Card>
    );
  }

  groupByServiceline(claims, limitCount = false, forceTopLevel = false) {
    const { serviceline } = this.state;
    const byServiceline = {};
    let count = 0;
    claims?.forEach((c) => {
      if (
        ((serviceline === 'all' || forceTopLevel) &&
          c.serviceline.parent_id === null) ||
        (!forceTopLevel && c.serviceline.parent_id === serviceline)
      ) {
        let name = c.serviceline.abbreviation ?? c.serviceline.name;
        let d = byServiceline[name];
        if (!d && (!limitCount || count < LIMIT_SERVICELINES)) {
          count++;
          d = {};
          byServiceline[name] = d;
        }
        if (d) {
          d[c.location_type || 1] = c;
        }
      }
    });
    return byServiceline;
  }

  addToSeries(series, byServiceline, sl) {
    let totalField = 'total_patients';
    if (this.props.patientType === 'CAPTURED_CLAIMS') {
      totalField = 'total_claims';
    }
    for (let i = 0; i < series.length; i++) {
      let total = 0;
      const claims = byServiceline[sl];

      if (claims?.[i + 1]) {
        // Track the indexes that will need a < 11 label
        if (claims[i+1][totalField] === null) {
          total = 10;
          series[i].unknown.push(series[i].data.length);
        } else {
          total = claims[i + 1][totalField] || 0;
        }
      }

      series[i].data.push(total);
    }
  }

  calculateShowTopLevel(
    series,
    byServiceline,
    sl,
    servicelineId,
    showSpecificServicelineIds
  ) {
    let totalField = 'total_patients';
    if (this.props.patientType === 'CAPTURED_CLAIMS') {
      totalField = 'total_claims';
    }
    let cumulativeTotal = 0;
    for (let i = 0; i < series.length; i++) {
      let total = 0;
      const claims = byServiceline[sl];
      if (claims?.[i + 1]) {
        total = claims[i + 1][totalField] || 0;
      }

      cumulativeTotal += total;
    }

    if (cumulativeTotal && showSpecificServicelineIds && servicelineId) {
      showSpecificServicelineIds.push(servicelineId);
    }
  }

  onCompare = () => {
    this.props.dispatch(
      showDialog(
        <FindOrganizationDialog
          id='org-compare-serviceline'
          onSelect={this.onCompareOrganization}
          open
        />
      )
    );
  };

  onCompareOrganization = (org) => {
    if (org) {
      this.fetchCompareOrgData(org);
    } else if (this.state.compareOrg) {
      this.setState({ compareOrg: null });
    }
    this.props.dispatch(hideDialog('org-compare-serviceline'));
  };

  onChartClick = (event, chartContext, config) => {
    const { isMobile } = this.props;
    if (!isMobile && config.dataPointIndex > -1) {
      const selectedServiceline =
        config.config.xaxis.categories[config.dataPointIndex];
      if (selectedServiceline.isParent) {
        this.setState({
          serviceline: selectedServiceline.servicelineId,
        });
      }
    }
  };

  onServicelineChange = (id) => {
    this.setState({
      serviceline: id,
    });
  };
}

ClaimsByServiceline.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  classes: PropTypes.object,
  claims: PropTypes.array,
  claimsState: PropTypes.object,
  isProvider: PropTypes.bool,
  name: PropTypes.string,
  servicelines: PropTypes.array,
  patientType: PropTypes.oneOf(PatientTypes),
};

function mapStateToProps(state) {
  const { app } = state;

  return {
    app,
    claimsState: state.claims,
    servicelines: state.claims && state.claims.servicelines_grouped,
    patientType: state.app.patientType,
  };
}

const styled = withStyles(styles)(withIsMobile(ClaimsByServiceline));
const connected = connect(mapStateToProps)(styled);
export { connected as ClaimsByServiceline };
