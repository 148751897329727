/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Card,
  CardContent,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Icon,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  CsvUtil,
  DataUtil,
  StoreUtil,
  PatientType,
  PatientTypes,
  ClaimQueryType,
  LocationTypeUtil,
} from 'doctivity-shared/utils';
import {
  ClaimsDateProvider,
  TitleTooltip,
  LoadingView,
  ServiceLineSelect,
  MarketOrTagSelect,
  Paginateable,
  ProviderProjectedPatientsChart,
  OrgProjectedPatientsChart,
  TaxonomySelect,
} from 'components';
import { MarketsUtil, ServiceLineUtil, QueryParamUtil } from 'utils';
import { ClaimsPageQueryParams } from 'params';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { setProviderType } from 'store/actions/appActions';
import axiosInstance from 'utils/axiosUtil';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { latestClaimsDate } from '../../../config/claims-date';
import { listMarkets } from 'store/actions/marketsActions';
import { upsertSavedCriteria } from 'store/actions/savedCriteriaActions';
import { setPatientType } from 'store/actions/appActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  filterDropDown: {
    width: 168,
    marginRight: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  colorPagination: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
      flexDirection: 'column',
    },
  },
  dropdownsContainer: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
  },
  pagination: {
    marginLeft: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'end',
  },
  headerDropDown: {
    minWidth: 220,
    marginBottom: theme.spacing(2),
  },
  providers: {
    width: '90%',
    paddingTop: 20,
    paddingBottom: 20,
    overflow: 'auto',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  providerRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 24,
    marginBottom: 4,
    color: '#2E93fA',
    fontSize: 12,
    fontWeight: 300,
    position: 'relative',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  providerBar: {
    position: 'absolute',
    backgroundColor: '#2E93fA',
    transitionProperty: 'width',
    transitionDelay: '1s',
    transitionDuration: '1s',
    width: 0,
    height: 24,
    top: 0,
    left: 0,
    borderRadius: 2,
    zIndex: 1,
    overflow: 'hidden',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  providerName: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    gap: 8,
    zIndex: 0,
  },
  providerNameIcon: {
    fontSize: 10,
    paddingLeft: 6,
  },
  providerCount: {
    position: 'absolute',
    top: 7,
    left: 200,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 0,
  },
  providerNameBar: {
    paddingTop: 6,
    zIndex: 2,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  providerCountBar: {
    position: 'absolute',
    top: 7,
    left: 200,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 3,
    overflow: 'hidden',
  },
  organizationCount: {
    position: 'absolute',
    top: 7,
    left: 440,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 0,
  },
  organizationCountBar: {
    position: 'absolute',
    top: 7,
    left: 440,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 3,
    overflow: 'hidden',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  spacer: {
    flex: 1,
  },
  infoTipIcon: {
    position: 'relative',
    top: -6,
    fontSize: 14,
    fontWeight: 200,
    color: '#999999',
  },
  infoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  affiliatedContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  affiliatedHeader: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  affiliated: {
    fontSize: 15,
    fontWeight: 500,
    border: 2,
    borderRadius: 4,
    borderStyle: 'solid',
    borderColor: 'rgba(32, 131, 244, 0.7)',
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
    display: 'flex',
    alignItems: 'center',
  },
  nonAffiliated: {
    fontSize: 15,
    fontWeight: 500,
    border: 2,
    borderRadius: 4,
    borderStyle: 'solid',
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
    borderColor: 'rgba(255, 97, 120, 0.7)',
    borderOpacity: 0.5,
    display: 'flex',
    alignItems: 'center',
  },
  affiliatedSpacer: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  affiliatedPercentage: {
    fontSize: 18,
    marginRight: theme.spacing(1),
  },
  affiliatedIcon: {
    fontSize: 16,
    fontWeight: 400,
    marginLeft: -16,
    marginRight: theme.spacing(2),
    color: theme.palette.secondary.main,
  },
  affiliatedInfoTipIcon: {
    position: 'relative',
    top: -4,
    marginLeft: theme.spacing(),
    fontSize: 14,
    fontWeight: 200,
    color: '#999999',
  },
  affiliatedInfoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  aggregatePercentage: {
    marginLeft: theme.spacing(1),
  },
  colorKeyContainer: {
    minHeight: 40,
    gap: theme.spacing(1),
  },
  colorKey: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  colorKeyIcon: {
    width: 14,
    height: 14,
    marginRight: 2,
    borderRadius: 2,
  },
  colorKeyText: {
    marginRight: 12,
    whiteSpace: 'nowrap',
  },
});

class ClaimsPage extends React.Component {
  findServiceLine = () => {};

  // Number of seconds to wait before saving the currently selected criteria
  saveCriteriaInterval = 3;

  saveCriteriaTimer = null;

  constructor(props) {
    super(props);

    this.state = {
      query: {
        offset: 0,
        limit: 200,
      },
      setParams: false,
      defaultParams: false, // Used when user does not have any saved criteria and going to /claims with no query string
      marketOrTagFilter: {
        type: 'market',
        market: props.market,
      },
      isMarket: true,
      market: null,
      tag: null,
      serviceline: null,
      codes: null,
      codesType: null,
      providerType: 'Providers',
      taxonomies: [],
      claimSetting: 0,
      lastTimeCriteriaChanged: null,
    };
  }

  componentDidMount() {
    const { dispatch, router } = this.props;

    if (
      !this.props.markets ||
      StoreUtil.needsLoadLongCache(this.props.markets)
    ) {
      dispatch(listMarkets(this.props.clientId));
    }

    if (router.query.size === 0) {
      this.loadFromLastSaved();
    } else {
      this.setState({
        setParams: true, // tell upate to use query params
      });
    }

    this.saveCriteriaTimer = setInterval(() => {
      if (this.state.lastTimeCriteriaChanged !== null) {
        let currentTime = moment();

        let duration = moment.duration(
          currentTime.diff(this.state.lastTimeCriteriaChanged),
        );
        let secondsSinceLastSave = duration.asSeconds();
        if (secondsSinceLastSave > this.saveCriteriaInterval) {
          // Build criteria_json
          // At this point we should have the most up to date criteria stored in state
          let criteriaArray = [
            {
              field: 'type',
              value: this.state.providerType,
            },
            {
              field: 'isMarket',
              value: this.state.isMarket,
            },
            {
              field: 'market',
              value: this.state.market?.id,
            },
            {
              field: 'tag',
              value: this.state.tag?.id,
            },
            {
              field: 'serviceLine',
              value: this.state.serviceline,
            },
            {
              field: 'claimSetting',
              value: this.state.claimSetting,
            },
            {
              field: 'taxonomies',
              value: this.state.taxonomies,
            },
          ];
          dispatch(
            upsertSavedCriteria({
              criteria_type_id: 1,
              criteria_json: JSON.stringify({
                criteria: criteriaArray,
              }),
              client_id: this.props.clientId,
              user_id: this.props.user.id,
            }),
          );

          this.setState({
            lastTimeCriteriaChanged: null,
          });
        }
      }
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.saveCriteriaTimer);
  }

  marketsChanged(newMarkets, oldMarkets) {
    if (
      newMarkets === null ||
      (oldMarkets === null && newMarkets !== oldMarkets)
    ) {
      return true;
    }
    if (newMarkets && oldMarkets && newMarkets.data && oldMarkets.data) {
      if (newMarkets.data.length !== oldMarkets.data.length) {
        return true;
      }
      if (
        newMarkets.meta &&
        newMarkets.meta.statusTime &&
        oldMarkets.meta &&
        oldMarkets.meta.statusTime
      ) {
        return (
          newMarkets.meta.statusTime.statusSuccess !==
          oldMarkets.meta.statusTime.statusSuccess
        );
      }
    }
    return false;
  }

  componentDidUpdate(prevProps) {
    const { router } = this.props;
    const { defaultParams } = this.state;

    if (this.props.clientId !== prevProps.clientId) {
      router.navigate('/claims');
      this.loadFromLastSaved();
      return;
    }

    const tabParamChanged = this.props.patientType !== prevProps.patientType;

    if (
      tabParamChanged ||
      this.marketsChanged(this.props.markets, prevProps.markets)
    ) {
      this.fetchData();
    }

    if (tabParamChanged) {
      QueryParamUtil.setQueryParamsFromProps(
        {
          patientType: this.props.patientType,
          claimsType: this.state.providerType,
          isMarket: this.state.isMarket,
          market: this.state.market?.id,
          tag: this.state.tag?.id,
          serviceline: this.state.serviceline,
          claimSetting: this.state.claimSetting,
          taxonomies: this.state.taxonomies,
          router,
        },
        ClaimsPageQueryParams,
      );
    }

    if (this.state.setParams) {
      console.log('componentDidUpdate-setParams');
      const queryClaimType = router.query.get(
        ClaimsPageQueryParams.CLAIMS_TYPE.name,
      );
      const queryMarket = router.query.get(ClaimsPageQueryParams.MARKET.name);
      const queryServiceLine = router.query.get(
        ClaimsPageQueryParams.SERVICELINE.name,
      );
      const queryClaimSetting = router.query.get(
        ClaimsPageQueryParams.CARE_SETTING.name,
      );
      const queryTaxonomies = router.query.get(
        ClaimsPageQueryParams.TAXONOMIES.name,
      );
      const queryIsMarket = router.query.get('isMarket');
      const queryTag = router.query.get('tag');

      const claimType =
        queryClaimType?.toLowerCase() === 'providers'
          ? 'Providers'
          : 'Organizations';
      const marketsLoaded = StoreUtil.isLoaded(this.props.markets);

      if (marketsLoaded) {
        let querySelectedMarket = null;
        const marketsData = StoreUtil.getData(this.props.markets);
        if (marketsData && marketsData.length > 0) {
          querySelectedMarket = marketsData.find(
            (market) => market.id === parseInt(queryMarket, 10),
          );
        }
        if (!querySelectedMarket) {
          querySelectedMarket = marketsData[0];
        }

        let taxonomiesRequest = [];
        if (queryTaxonomies) {
          taxonomiesRequest = queryTaxonomies.split(',');
        }

        let serviceLineRequest = [];
        if (queryServiceLine && queryServiceLine.includes(',')) {
          serviceLineRequest = Array.from(
            new Set(
              queryServiceLine
                .split(',')
                .map((sl) => parseInt(sl, 10))
                .filter((sl) => !Number.isNaN(sl)),
            ),
          );
        } else if (queryServiceLine !== 'all') {
          const slNumber = parseInt(queryServiceLine, 10);
          if (!Number.isNaN(slNumber)) {
            serviceLineRequest = [slNumber];
          }
        } else {
          serviceLineRequest = [ServiceLineUtil.getDefault()];
        }
        if (serviceLineRequest.length > 0) {
          serviceLineRequest = serviceLineRequest.filter(
            (sl) => sl !== 'NaN' && sl !== 'all',
          );
        }
        if (serviceLineRequest.length < 1) {
          serviceLineRequest = [ServiceLineUtil.getDefault()];
        }
        let claimSetting = parseInt(queryClaimSetting, 10);
        if (Number.isNaN(claimSetting)) {
          claimSetting = 0;
        }
        this.setState(
          {
            setParams: false,
            providerType: claimType,
            serviceline: serviceLineRequest,
            claimSetting,
            taxonomies: taxonomiesRequest,
            isMarket: queryIsMarket === 'true' || !queryTag,
            market: querySelectedMarket,
            tag: queryTag ? { id: queryTag } : null,
            marketOrTagFilter: {
              type: queryIsMarket === 'true' || !queryTag ? 'market' : 'tag',
              market: querySelectedMarket,
              tag: queryTag ? { id: queryTag } : null,
            },
          },
          () => {
            this.fetchData();
          },
        );
      }
    }

    if (defaultParams && router.query.size === 0) {
      console.log('setting default params', this.state, this.props);
      const marketsLoaded = StoreUtil.isLoaded(this.props.markets);
      console.log('markets loaded', marketsLoaded, this.props.markets);
      if (marketsLoaded) {
        this.setState(
          {
            defaultParams: false,
            providerType: 'Organizations',
            serviceline: ServiceLineUtil.getDefault(),
            isMarket: true,
            market: this.props.market,
            claimSetting: 0,
            marketOrTagFilter: {
              type: true,
              market: this.props.market,
              tag: null,
            },
          },
          () => {
            this.fetchData();
          },
        );
      }
    }
  }

  loadFromLastSaved = () => {
    const { router } = this.props;
    axiosInstance
      .get('/UsersSavedCriteria', {
        params: {
          opts: {
            clientId: this.props.clientId,
            criteriaTypeId: 1,
          },
        },
      })
      .then((savedCriteria) => {
        if (savedCriteria.data && savedCriteria.data.length > 0) {
          let mostRecent = (
            typeof savedCriteria.data[0].criteria_json === 'string'
              ? JSON.parse(savedCriteria.data[0].criteria_json)
              : savedCriteria.data[0].criteria_json
          )?.criteria;
          let mostRecentClaimsType = mostRecent.find((c) => c.field === 'type');
          let mostRecentMarket = mostRecent.find((c) => c.field === 'market');
          let mostRecentServiceLine = mostRecent.find(
            (c) => c.field === 'serviceLine',
          );
          let mostRecentClaimSetting = mostRecent.find(
            (c) => c.field === 'claimSetting',
          );
          let mostRecentTaxonomySetting = mostRecent.find(
            (c) => c.field === 'taxonomies',
          );
          let mostRecentTag = mostRecent.find((c) => c.field === 'tag');
          let mostRecentIsMarket = mostRecent.find(
            (c) => c.field === 'isMarket',
          );

          QueryParamUtil.setQueryParamsFromProps(
            {
              report: mostRecent?.report?.value,
              patientType: this.props.patientType,
              claimsType: mostRecentClaimsType?.value,
              serviceline: mostRecentServiceLine?.value ?? 1,
              claimSetting: parseInt(mostRecentClaimSetting?.value, 10) ?? 0,
              market: mostRecentMarket?.value,
              tag: mostRecentTag?.value,
              isMarket: mostRecentIsMarket?.value ?? true,
              taxonomies: mostRecentTaxonomySetting?.value ?? [],
              router,
            },
            ClaimsPageQueryParams,
          );
          this.setState({
            setParams: true,
          });
        } else {
          this.setState({
            defaultParams: true,
          });
        }
      });
  };

  fetchData(csv) {
    // console.log('FETCH DATA: ', this.state);
    const servicelineIds = this.getServicelineIds();
    const providerType = this.getProviderType();
    const type =
      providerType === 'Providers'
        ? ClaimQueryType.CLAIMS_PER_PROVIDER
        : ClaimQueryType.CLAIMS_PER_ORGANIZATION;
    const options = {
      type,
      filter: {
        serviceline:
          !servicelineIds || servicelineIds.length === 0
            ? ServiceLineUtil.getDefault()
            : servicelineIds,
        codes: this.getCodes(),
        codes_type: this.getCodesType(),
        market: this.state.isMarket ? this.state.market : undefined,
        tag: !this.state.isMarket ? this.state.tag : undefined,
        location_type:
          this.state.claimSetting === 0 ? undefined : this.state.claimSetting,
        patient_type: this.props.patientType,
        taxonomies:
          providerType === 'Providers' ? this.state.taxonomies : undefined,
        affiliated_client_id: this.props.clientId,
      },
      opts: this.state.query,
    };
    if (!options.filter.market && !options.filter.tag) {
      return;
    }
    if (!csv) {
      this.props.dispatch(
        loadAnalytics({
          ...options,
        }),
      );
      if (type === ClaimQueryType.CLAIMS_PER_ORGANIZATION) {
        this.props.dispatch(
          loadAnalytics({
            ...options,
            type: ClaimQueryType.CLAIMS_PER_ORGANIZATION_COUNTS,
          }),
        );
      } else if (type === ClaimQueryType.CLAIMS_PER_PROVIDER) {
        this.props.dispatch(
          loadAnalytics({
            ...options,
            type: ClaimQueryType.CLAIMS_PER_PROVIDER_COUNTS,
          }),
        );
      }
    } else {
      axiosInstance
        .post(
          '/Analytics',
          {
            ...options,
            format: 'csv',
            headers: {
              'Content-Type': 'text/csv',
            },
          },
          {
            params: {
              selected_client_id: this.props.clientId,
            },
          },
        )
        .then((response) => {
          let type = '';
          switch (this.props.patientType) {
            case PatientType.ALL_PATIENTS:
              type = options.type.replace('CLAIMS', 'PROJECTED_PATIENTS');
              break;
            case PatientType.CAPTURED_CLAIMS:
              type = options.type;
              break;
            case PatientType.PEDIATRIC_PATIENTS:
              type = options.type.replace(
                'CLAIMS',
                'PROJECTED_PEDIATRIC_PATIENTS',
              );
              break;
            default:
              break;
          }
          let firstServiceLineId = Array.isArray(servicelineIds)
            ? servicelineIds[0]
            : servicelineIds;
          if (!firstServiceLineId) {
            firstServiceLineId = ServiceLineUtil.getDefault();
          }
          const firstServiceLine = ServiceLineUtil.findServiceLine(
            this.props.servicelines,
            firstServiceLineId,
          );
          const servicelineName =
            firstServiceLine && firstServiceLine.name
              ? `_${firstServiceLine.name}`
              : '';
          const filename = `doctivity_${type}${servicelineName}_${moment().format('YY_MM_DD')}.csv`;
          try {
            const data = this.formatCSV(response.data, type);
            fileDownload(data, filename);
          } catch (err) {
            console.error(`Could not format csv for ${filename}`);
            console.error(err);
            fileDownload(response.data, filename);
          }
        });
    }
  }

  formatCSV(data, type) {
    let columns = CsvUtil.stringToMatrix(data);
    const urlPrefix = `${process.env.WEB_DOMAIN}/${
      type === ClaimQueryType.CLAIMS_PER_ORGANIZATION
        ? 'organizations'
        : 'providers'
    }`;
    if (columns.length > 0) {
      let locationTypeIndex = -1;
      let affiliatedIndex = -1;
      let idIndex = -1;
      for (let index = 0; index < columns[0].length; index++) {
        if (columns[0][index] === 'location_type') {
          locationTypeIndex = index;
          columns[0][index] = 'setting';
        } else if (
          columns[0][index] === 'organization.affiliated.0.id' ||
          columns[0][index] === 'provider.clients.0.id'
        ) {
          columns[0][index] = 'Affiliated';
          affiliatedIndex = index;
        } else if (
          columns[0][index] === 'organization.id' ||
          columns[0][index] === 'provider.id'
        ) {
          idIndex = index;
        }
      }

      if (affiliatedIndex > -1) {
        for (let index = 1; index < columns.length - 1; index++) {
          columns[index][affiliatedIndex] = columns[index][affiliatedIndex]
            ? 'Yes'
            : 'No';
        }
      } else {
        columns[0].push('Affiliated');
        for (let index = 1; index < columns.length - 1; index++) {
          columns[index].push('No');
        }
      }

      if (locationTypeIndex > -1) {
        columns[0].push('URL');
        // eslint-disable-next-line no-restricted-syntax
        for (let index = 1; index < columns.length - 1; index++) {
          const locationType = parseInt(columns[index][locationTypeIndex], 10);
          const locationTypeName = LocationTypeUtil.getName(locationType);
          columns[index][locationTypeIndex] = locationTypeName;
          if (idIndex > -1) {
            columns[index].push(
              `${urlPrefix}/${columns[index][idIndex]}/market`,
            );
          }
        }
      }

      columns = DataUtil.removeMatrixColumn(columns, idIndex);
      columns[columns.length - 1].push(
        'Captured claims represents the available Doctivity data and does not represent 100% of an individual market.',
      );
    }
    return CsvUtil.matrixToString(columns);
  }

  getServicelineIds() {
    const { patientType } = this.props;
    let serviceline = this.state.serviceline;
    if (patientType === PatientType.CAPTURED_CLAIMS) {
      if (this.getCodesType()) {
        return null;
      }
      if (!Array.isArray(serviceline)) {
        serviceline = [serviceline];
      }
    } else {
      if (Array.isArray(serviceline) && serviceline.length > 0) {
        serviceline = serviceline[0];
      }
    }
    return serviceline;
  }

  getCodes() {
    if (this.props.patientType !== PatientType.CAPTURED_CLAIMS) {
      return null;
    }
    if (this.state.codes) {
      if (Array.isArray(this.state.codes)) {
        return this.state.codes;
      }
      return [this.state.codes];
    }
    return null;
  }

  getCodesType() {
    if (this.props.patientType !== PatientType.CAPTURED_CLAIMS) {
      return null;
    }
    return this.state.codesType;
  }

  getProviderType() {
    return this.state.providerType || 'Organizations';
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };

  generateClaimsCountBar = (claims, index) => {
    const { classes, patientType } = this.props;

    let patientTypeLabel = 'patients';
    if (patientType === PatientType.CAPTURED_CLAIMS) {
      patientTypeLabel = 'claims';
    }

    return (
      <>
        <span>
          {` ${
            claims.total ? claims.total.toLocaleString() : '<11'
          } ${patientTypeLabel}`}
        </span>
        {claims.aggregateClaimsPercentage !== null &&
        claims.aggregateClaimsPercentage !== undefined ? (
          <span className={classes.aggregatePercentage}>
            {` ~${(claims.aggregateClaimsPercentage * 100).toLocaleString()}% ${
              index === 0 ? 'of Selected Market' : ''
            }`}
          </span>
        ) : (
          <></>
        )}
      </>
    );
  };

  render() {
    const {
      classes,
      providers,
      markets,
      organizations,
      providerCounts,
      organizationCounts,
      patientType,
      servicelines,
    } = this.props;

    const { query, claimSetting } = this.state;

    const providerType = this.getProviderType();

    let isLoading = false;
    let max = 100;
    let rows = [];
    let data = {};
    let totalMarket = 0;
    let affiliatedPercentage = -1;
    if (providerType === 'Providers') {
      if (
        !providers ||
        !StoreUtil.isLoaded(providers) ||
        !markets ||
        !StoreUtil.isLoaded(markets)
      ) {
        isLoading = true;
      } else {
        data = providers.data;
        rows = providers.data.rows;
        if (providerCounts?.data && providerCounts.data.total) {
          data = providerCounts.data;
          totalMarket = providerCounts.data.total;
          affiliatedPercentage = (
            (providerCounts.data.total_affiliated / providerCounts.data.total) *
            100
          ).toFixed(2);
        }
      }
    } else if (
      !organizations ||
      !StoreUtil.isLoaded(organizations) ||
      !markets ||
      !StoreUtil.isLoaded(markets)
    ) {
      isLoading = true;
    } else {
      data = organizations.data;
      rows = organizations.data.rows;
      if (organizationCounts?.data && organizationCounts.data.total) {
        data = organizationCounts.data;
        totalMarket = organizationCounts.data.total;
        affiliatedPercentage = (
          (organizationCounts.data.total_affiliated /
            organizationCounts.data.total) *
          100
        ).toFixed(2);
      }
    }

    if (affiliatedPercentage > -1) {
      affiliatedPercentage = Math.round(affiliatedPercentage * 100) / 100;
    }
    const nonAffiliatedPercentage =
      affiliatedPercentage > -1
        ? Math.round((100 - affiliatedPercentage).toFixed(2) * 100) / 100
        : 0;

    rows.forEach((claims) => {
      if (claims.total === null) {
        claims.total = 0;
      } else {
        claims.total = parseInt(claims.total, 10);
        if (totalMarket) {
          claims.aggregateClaimsPercentage = claims.total / totalMarket;
        }
        if (providerType === 'Providers') {
          if (claims.provider.clients && claims.provider.clients.length > 0) {
            claims.affiliated = true;
          }
        } else if (providerType === 'Organizations') {
          if (
            claims.organization.affiliated &&
            claims.organization.affiliated.length > 0
          ) {
            claims.affiliated = true;
          }
        }

        max = Math.max(max, claims.total);
      }
      if (
        claims.organization &&
        claims.organization.name &&
        claims.organization.name.length > 50
      ) {
        claims.organization.name = `${claims.organization.name.substring(
          0,
          50,
        )}...`;
      }
    });

    let servicelineIds =
      this.getServicelineIds() ?? ServiceLineUtil.getDefault();
    const oldestClaimsDate = new Date(
      latestClaimsDate.getFullYear() - 1,
      latestClaimsDate.getMonth() + 1,
    );
    console.error(
      this.props.patientType !== PatientType.CAPTURED_CLAIMS,
      this.props.patientType,
      PatientType.CAPTURED_CLAIMS,
    );
    return (
      <>
        <div className={classes.container}>
          <div className={classes.header}>
            <FormControl
              variant='outlined'
              className={classes.headerDropDown}
              key='filter-type'
            >
              <InputLabel>Type</InputLabel>
              <Select
                value={providerType}
                onChange={this.onProviderTypeChange}
                label='Type'
              >
                <MenuItem value='Providers' key='Providers'>
                  Providers
                </MenuItem>
                <MenuItem value='Organizations' key='Organizations'>
                  Organizations
                </MenuItem>
              </Select>
            </FormControl>
            <ClaimsDateProvider />
          </div>
          <Card>
            <CardContent>
              <TitleTooltip
                title='Market Data'
                tooltip={`Displaying claims analytics from ${oldestClaimsDate.toLocaleString(
                  'default',
                  { year: 'numeric', month: 'long' },
                )} through ${latestClaimsDate.toLocaleString('default', {
                  year: 'numeric',
                  month: 'long',
                })}`}
                onExport={() => this.fetchData(true)}
              />
              <div className={classes.filters}>
                <div className={classes.dropdownsContainer}>
                  {this.props.patientType !== PatientType.CAPTURED_CLAIMS && (
                    <FormControl
                      variant='outlined'
                      className={classes.filterDropDown}
                      key='patient-type'
                    >
                      <InputLabel className={classes.filterLabel}>
                        Patients
                      </InputLabel>
                      <Select
                        key='select'
                        value={this.props.patientType}
                        onChange={this.onPatientTypeChange}
                        label='Patients'
                      >
                        <MenuItem
                          value={PatientType.ALL_PATIENTS}
                          key='all_patients'
                        >
                          All Projected Patients
                        </MenuItem>
                        <MenuItem
                          value={PatientType.PEDIATRIC_PATIENTS}
                          key='pediatric_patients'
                        >
                          Projected Pediatric Patients
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                  <MarketOrTagSelect
                    filter={this.state.marketOrTagFilter}
                    onChange={this.onMarketOrTagChange}
                    allowTagSelection={true}
                    markets={this.props.markets}
                    market={this.props.market}
                    type={
                      providerType === 'Providers'
                        ? 'providers'
                        : 'organizations'
                    }
                  />
                  <ServiceLineSelect
                    value={servicelineIds}
                    onChange={this.onServicelineChange}
                    servicelines={servicelines}
                    codes={this.getCodes()}
                    codesType={this.getCodesType()}
                    setFindServiceLine={(findServiceLine) => {
                      this.findServiceLine = findServiceLine;
                    }}
                    multiple={patientType === PatientType.CAPTURED_CLAIMS}
                    allowCodes={patientType === PatientType.CAPTURED_CLAIMS}
                    showSubServicelines
                  />
                  {this.props.patientType !== PatientType.CAPTURED_CLAIMS && (
                    <FormControl
                      variant='outlined'
                      className={classes.filterDropDown}
                      key='filter-setting'
                    >
                      <InputLabel>Care Setting</InputLabel>
                      <Select
                        value={claimSetting}
                        onChange={this.onClaimSettingChange}
                        label='Care Setting'
                      >
                        {
                          // eslint-disable-next-line arrow-body-style
                          LocationTypeUtil.getAll()
                            .filter((type) => !type.codesOnly)
                            .map((type) => {
                              return (
                                <MenuItem value={type.id} key={type.id}>
                                  {type.name}
                                </MenuItem>
                              );
                            })
                        }
                      </Select>
                    </FormControl>
                  )}
                  {providerType === 'Providers' && (
                    <TaxonomySelect
                      onChange={this.onTaxonomiesChange}
                      values={
                        this.state.taxonomies.length > 0
                          ? this.state.taxonomies
                          : undefined
                      }
                    />
                  )}
                </div>
              </div>
              {affiliatedPercentage > -1 && (
                <div className={classes.affiliatedContainer}>
                  <div className={classes.affiliatedHeader}>
                    <span className={classes.affiliated}>
                      <Icon className={classes.affiliatedIcon}>hub</Icon>
                      <span className={classes.affiliatedPercentage}>
                        ~{affiliatedPercentage}%
                      </span>
                      <span>Affiliated</span>
                    </span>
                    <div className={classes.affiliatedSpacer} />
                    <span className={classes.nonAffiliated}>
                      <span className={classes.affiliatedPercentage}>
                        ~{nonAffiliatedPercentage}%
                      </span>
                      <span>Non-Affiliated</span>
                    </span>
                  </div>
                  <Tooltip
                    placement='bottom-start'
                    title={
                      <Typography className={classes.affiliatedInfoTip}>
                        {this.props.patientType !== PatientType.CAPTURED_CLAIMS
                          ? 'Projected patients are not unique across any given market, therefore market percentage is an approximation.'
                          : 'Captured claims represents the available Doctivity data and does not represent 100% of an individual market.'}
                      </Typography>
                    }
                  >
                    <Icon className={classes.affiliatedInfoTipIcon}>
                      info_outlined
                    </Icon>
                  </Tooltip>
                </div>
              )}
              {isLoading && <LoadingView />}
              {!isLoading && (
                <div className={classes.providers}>
                  {providerType === 'Providers' && (
                    <ProviderProjectedPatientsChart patientType={patientType} />
                  )}
                  {providerType === 'Organizations' && (
                    <OrgProjectedPatientsChart patientType={patientType} />
                  )}
                </div>
              )}
              <div className={classes.pagination}>
                <Paginateable
                  data={data}
                  query={query}
                  onQueryChange={this.onQueryChange}
                />
              </div>
            </CardContent>
          </Card>
        </div>
      </>
    );
  }

  onPatientTypeChange = (event) => {
    this.props.dispatch(setPatientType(event.target.value.toUpperCase()));
  };

  updateQueryStringWithCriteria = () => {
    const { router } = this.props;
    console.log('oncriteria changed', this.state);
    QueryParamUtil.setQueryParamsFromProps(
      {
        patientType: this.props.patientType,
        claimsType: this.state.providerType,
        isMarket: this.state.isMarket,
        market: this.state.market?.id,
        tag: this.state.tag?.id,
        serviceline: this.state.serviceline,
        claimSetting: this.state.claimSetting,
        taxonomies: this.state.taxonomies,
        router,
      },
      ClaimsPageQueryParams,
    );
  };

  onMarketOrTagChange = (filter) => {
    if (
      filter.type === this.state.marketOrTagFilter.type &&
      filter.market === this.state.market &&
      filter.tag === this.state.tag
    ) {
      return;
    }
    this.setState((state) => {
      const marketState = {
        isMarket: filter.type === 'market',
        market: filter.type === 'market' ? filter.market : state.market,
        tag: filter.type === 'tag' ? filter.tag : state.tag,
      };
      return {
        ...marketState,
        marketOrTagFilter: filter,
      };
    }, this.onCriteriaChanged);
  };

  onServicelineChange = (ids, idTypes) => {
    if (idTypes === 'dx' || idTypes === 'px') {
      this.setState(
        {
          codes: ids,
          codesType: idTypes,
          serviceline: null,
        },
        this.onCriteriaChanged,
      );
    } else {
      this.setState(
        {
          serviceline: ids,
          codes: null,
          codesType: null,
        },
        this.onCriteriaChanged,
      );
    }
  };

  onProviderTypeChange = (event) => {
    const { dispatch } = this.props;
    const providerType = event.target.value;

    dispatch(setProviderType(providerType));
    this.setState(
      {
        providerType,
      },
      this.onCriteriaChanged,
    );
  };

  onTaxonomiesChange = (selectedTaxonomies) => {
    const taxonomies = selectedTaxonomies.map((taxonomy) => taxonomy.id);
    this.setState({ taxonomies }, this.onCriteriaChanged);
  };

  onClaimSettingChange = (event) => {
    this.setState({ claimSetting: event.target.value }, this.onCriteriaChanged);
  };

  onCriteriaChanged = () => {
    this.updateQueryStringWithCriteria();
    this.fetchData();

    this.setState({
      lastTimeCriteriaChanged: moment(),
    });
  };
}

ClaimsPage.propTypes = {
  classes: PropTypes.object,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  providerCounts: PropTypes.object,
  query: PropTypes.object.isRequired,
  servicelineId: PropTypes.number,
  providerType: PropTypes.string,
  market: PropTypes.object,
  markets: PropTypes.object,
  organizations: PropTypes.object,
  organizationCounts: PropTypes.object,
  patientType: PropTypes.oneOf(PatientTypes),
  clientId: PropTypes.number,
  servicelines: PropTypes.array,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    providers: StoreUtil.get(state.analytics, 'CLAIMS_PER_PROVIDER'),
    organizations: StoreUtil.get(state.analytics, 'CLAIMS_PER_ORGANIZATION'),
    providerCounts: StoreUtil.get(
      state.analytics,
      'CLAIMS_PER_PROVIDER_COUNTS',
    ),
    organizationCounts: StoreUtil.get(
      state.analytics,
      'CLAIMS_PER_ORGANIZATION_COUNTS',
    ),
    market: MarketsUtil.getSelectedMarket(state),
    markets: MarketsUtil.getMarkets(state),
    servicelines: state.claims && state.claims.servicelines_grouped,
    patientType: state.app.patientType,
    clientId: state.app.selectedClient,
    providerType: state.app.providerType,
    user: state.user,
  };
}

const styled = withStyles(styles)(ClaimsPage);
const connected = connect(mapStateToProps)(styled);
export { connected as ClaimsPage };
