import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Grid } from '@mui/material';
import {
  ScrollableComponent,
  ClaimsNetwork,
  ClaimsNetworkAffiliated,
} from 'components';

import { ProviderSankey } from 'providers';

const styles = () => ({});

function SharedAffiliationCharts({ provider }) {
  const patientType = useSelector(({ app }) => app.patientType);
  const user = useSelector((state) => state.user);
  return (
    <>
      <Grid item xs={12}>
        <ScrollableComponent id='providers'>
          <ClaimsNetwork provider={provider} patientType={patientType} />
          {user.is_admin && <ProviderSankey providerId={provider.id} />}
        </ScrollableComponent>
      </Grid>
      <Grid item xs={12}>
        <ScrollableComponent id='affiliated'>
          <ClaimsNetworkAffiliated
            provider={provider}
            patientType={patientType}
          />
        </ScrollableComponent>
      </Grid>
    </>
  );
}

SharedAffiliationCharts.propTypes = {
  provider: PropTypes.object,
  patientType: PropTypes.string,
};

const styled = withStyles(styles)(SharedAffiliationCharts);
export { styled as SharedAffiliationCharts };
