import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import sharedStyles from './SharedStyles'

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { DynamicTable, LoadingView } from 'components';

import { Icon, Typography, Button, Tooltip } from '@mui/material';

import {
  queryOrganizations,
  upsertOrganization,
} from 'store/actions/organizationsActions';

import { OrganizationEditDialog } from './OrganizationEditDialog';
import { OrganizationsTabs } from './OrganizationsTabs';

class OrganizationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        label: '',
        key: 'affiliated',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: (affiliated) => {
          if (affiliated?.length > 0) {
            return (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 200,
                    }}
                  >
                    Affiliated Organization
                  </Typography>
                }
              >
                <div
                  style={{
                    width: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon className={props.classes.affiliatedIcon}>hub</Icon>
                </div>
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        label: 'Name',
        key: 'name',
        fulltext: true,
      },
      {
        label: 'Type',
        key: 'facility_type',
      },
      {
        label: 'NPI',
        key: 'npi',
        style: { minWidth: 140, verticalAlign: 'top' },
      },
      {
        label: 'City',
        key: 'primary_location.city',
        fulltext: true,
      },
      {
        label: 'State',
        key: 'primary_location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
      },
    ];

    this.state = {
      query: {
        order: [['name', 'ASC']],
        include: [
          {
            attributes: ['id', 'city', 'state'],
            association: 'primary_location',
          },
          {
            attributes: ['id'],
            association: 'affiliated',
            required: false,
            through: { attributes: [] }, // don't need the join table data
            where: {
              id: props.clientId,
            },
          },
        ],
        limit: 25,
        offset: 0,
      },
      addDialogOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();

    const oldQuery = sessionStorage.getItem('organizationsTableQuery');
    if (oldQuery) {
      this.onQueryChange(JSON.parse(oldQuery));
    }
  }

  componentDidUpdate(prevProps) {
    const { editing, router, organizations } = this.props;
    // is object getting edited?
    if (editing !== prevProps.editing) {
      const didJustSave = StoreUtil.hasSavedSinceUpdate(
        editing,
        prevProps.editing
      );

      if (didJustSave && editing?.data?.id) {
        router.navigate(`/organizations/${editing.data.id}`);
      }
      // if so and it is newr than the list, update list
      else if (StoreUtil.hasSavedSinceLoad(editing, organizations)) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const { dispatch } = this.props;

    const { query } = this.state;

    dispatch(queryOrganizations(query));
  }

  render() {
    const { user, organizations, editing, classes } = this.props;

    const { query } = this.state;

    // is a user being edited now?
    if (StoreUtil.isSaving(editing)) {
      return <LoadingView />;
    }

    return (
      <div>
        <OrganizationsTabs tab='organizations' />
        <div className={classes.container}>
          <DynamicTable
            header={
              <div className={classes.headerContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
                >
                  All Organizations
                </Typography>
                <div className={classes.tableHeader} />
                {user.is_admin && (
                  <Button
                    color='primary'
                    size='small'
                    onClick={this.onOpenAddDialog}
                  >
                    <Icon className={classes.leftIcon}>add</Icon>
                    Add Organization
                  </Button>
                )}
              </div>
            }
            columns={this.columns}
            query={query}
            data={organizations}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/organizations/${row.id}`}
          />
        </div>
        <OrganizationEditDialog
          open={this.state.addDialogOpen}
          onSave={this.onSaveOrganization}
          onClose={this.onCloseAddDialog}
        />
      </div>
    );
  }

  onQueryChange = (query) => {
    sessionStorage.setItem('organizationsTableQuery', JSON.stringify(query));
    this.setState({ query }, this.fetchData);
  };

  onOpenAddDialog = () => {
    this.setState({
      addDialogOpen: true,
    });
  };

  onCloseAddDialog = () => {
    this.setState({
      addDialogOpen: false,
    });
  };

  onSaveOrganization = (organization) => {
    organization.change_affiliation = {
      client_id: this.props.clientId,
      affiliated: organization.is_affiliated,
    };
    if (organization.name) {
      organization.name = organization.name.toUpperCase();
    }
    this.props.dispatch(upsertOrganization(organization));
    this.onCloseAddDialog();
  };
}

OrganizationsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object,
  organizations: PropTypes.object.isRequired,
  editing: PropTypes.object,
  clientId: PropTypes.number,
};

function mapStateToProps(state) {
  const { organizations, user } = state;

  return {
    user,
    organizations: StoreUtil.get(organizations, StoreUtil.COMMON_TABLE),
    editing: StoreUtil.get(organizations, StoreUtil.COMMON_EDIT_ITEM),
    clientId: state.app.selectedClient,
  };
}

const styled = withStyles(sharedStyles)(OrganizationsPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as OrganizationsPage };
