import React, { useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  ListSubheader,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { ServiceLineSelect, PatientTypeSelect } from 'components';
import {
  StoreUtil,
  SpecialtyUtil,
  PatientType,
} from 'doctivity-shared/utils';

const styles = (theme) => ({
  select: {
    '& .MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover': {
      backgroundColor: '#00a887',
      color: 'white',
    },
  },
  filterDropDown: {
    width: 320,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  specialtyDropDown: {
    width: 180,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dropDown: {
    marginBottom: theme.spacing(2),
  },
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  filterLabel: {
    backgroundColor: 'white',
    paddingRight: 4,
  },
});

function ComboFilter({ classes, setFilter }) {
  const rawSpecialties = useSelector((state) =>
    StoreUtil.get(state.analytics, 'CLAIMS_NETWORK_SPECIALTIES'),
  );
  const patientType = useSelector((state) =>
    state.app.patientType === PatientType.CAPTURED_CLAIMS
      ? PatientType.ALL_PATIENTS
      : state.app.patientType,
  );
  const [specialties, setSpecialties] = useState([]);
  const [specialtiesCode, setSpecialtiesCode] = useState({});
  const [serviceline, setServiceline] = useState('all');
  const [specialty, setSpecialty] = useState([]);

  const onSpecialtyChange = useCallback(
    (event) => {
      if (event.target.value.includes('all')) {
        setSpecialty([]);
        return;
      }
      const selected = new Set(event.target.value);
      setSpecialty(Array.from(selected));
    },
    [setSpecialty],
  );
  useEffect(() => {
    let specialties = [];
    if (StoreUtil.isLoaded(rawSpecialties)) {
      specialties = StoreUtil.getData(rawSpecialties).sort((a, b) =>
        SpecialtyUtil.getNameFromTaxonomy(a).localeCompare(
          SpecialtyUtil.getNameFromTaxonomy(b),
        ),
      );
    }
    setSpecialties(specialties);
    const specialtiesCode = {};
    specialties?.forEach((s) => {
      const name = SpecialtyUtil.getNameFromTaxonomy(s);
      if (name.trim().length === 0) return;
      if (specialtiesCode[name] === undefined) {
        specialtiesCode[name] = 0;
      }
      specialtiesCode[name] += 1;
    });
    setSpecialtiesCode(specialtiesCode);
  }, [rawSpecialties]);
  const onServicelineChange = useCallback(
    (id) => {
      setServiceline(id);
    },
    [setServiceline],
  );
  useEffect(() => {
    setFilter({ patientType, serviceline, specialty });
  }, [patientType, serviceline, specialty]);
  return (
    <>
      <div className={classes.dropDownContainer}>
        <div className={classes.dropDown}>
          <ServiceLineSelect
            value={serviceline}
            onChange={onServicelineChange}
            showSubServicelines
            showAll
          />
        </div>
        <FormControl
          variant='outlined'
          className={classes.specialtyDropDown}
          key='specialty'
        >
          <InputLabel shrink className={classes.filterLabel}>
            Specialty
          </InputLabel>
          <Select
            key='select'
            value={specialty}
            onChange={onSpecialtyChange}
            multiple={true}
            label='Specialty'
            notched
            displayEmpty
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <em>All</em>;
              }
              return selected
                .map((s) => {
                  const specialty = specialties?.find(
                    (sp) => sp.code === s,
                  );
                  return SpecialtyUtil.getNameFromTaxonomy(specialty);
                })
                .join(', ');
            }}
            MenuProps={{
              PaperProps: {
                className: classes.select,
              },
            }}
          >
            <ListSubheader
              style={{
                borderBottom: '1px solid black',
                fontSize: 14,
                fontWeight: 500,
                color: '#000',
              }}
            >
              {specialty.length} selected
            </ListSubheader>

            <MenuItem value='all' key='all'>
              <em>All</em>
            </MenuItem>
            {specialties &&
              specialties.map((s) => (
                <MenuItem value={s.code} key={s.code}>
                  {SpecialtyUtil.getNameFromTaxonomy(s)}&nbsp;
                  {specialtiesCode[SpecialtyUtil.getNameFromTaxonomy(s)] > 1
                    ? `(${s.code})`
                    : ''}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <div className={classes.dropDown}>
          <PatientTypeSelect noCapturedClaims />
        </div>
      </div>
    </>
  );
}
ComboFilter.propTypes = {
  classes: PropTypes.object.isRequired,
  setFilter: PropTypes.func,
};
const styled = withStyles(styles)(ComboFilter);
export { styled as ComboFilter };
