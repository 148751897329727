import React from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  FormControl,
  InputLabel,
  Icon,
  IconButton,
  Link,
  ListSubheader,
  Select,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import TableViewIcon from '@mui/icons-material/TableView';
import withStyles from '@mui/styles/withStyles';
import VanillaContextMenu from 'vanilla-context-menu';
import axiosInstance from 'utils/axiosUtil';
import { withIsMobile } from 'utils';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { convertToCSV } from 'documents/downloadTypes';

import {
  DynamicTable,
  TitleTooltip,
  LoadingView,
  SmallIconTextButton,
  ServiceLineSelect,
  PatientTypeSelect,
} from 'components';
import { showConfirmationDialog } from 'store/actions/systemActions';

import {
  CsvUtil,
  DataUtil,
  StoreUtil,
  SpecialtyUtil,
  PatientTypes,
  PatientType,
} from 'doctivity-shared/utils';
import { ColorUtil, ServiceLineUtil, withRouter } from 'utils';
import { loadAnalytics } from 'store/actions/analyticsActions';
import {
  queryProviders,
  API_PROVIDERS_LIST,
} from 'store/actions/providersActions';

const styles = (theme) => ({
  card: {},
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    marginBottom: theme.spacing(1),
  },
  provider2: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  filterDropDown: {
    width: 320,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  specialtyDropDown: {
    width: 180,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dropDown: {
    marginBottom: theme.spacing(2),
  },
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  filterLabel: {
    backgroundColor: 'white',
    paddingRight: 4,
  },
  noData: {
    textAlign: 'center',
    paddingTop: 50,
    minHeight: 200,
  },
  contextMenu: {
    background: '#3c3c3c',
    opacity: 0.93,
    fontFamily: 'Roboto',
    // text color for each item
    '& > *:not(hr)': {
      '&:hover': {
        background: '#e67e22',
      },
      fontSize: 13,
    },
  },
  colorKey: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 400,
    minHeight: 40,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  colorKeyIcon: {
    width: 14,
    height: 14,
    marginRight: 2,
    borderRadius: 2,
  },
  colorKeyText: {
    marginRight: 12,
  },
  providerLink: {
    color: theme.palette.secondary.main,
    textDecoration: 'none',
  },
  specializationDescriptorContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  specializationDescriptor: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  select: {
    '& .MuiMenuItem-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover': {
      backgroundColor: '#00a887',
      color: 'white',
    },
  },
  affiliatedIcon: {
    fontFamily: '"Material Icons" !important',
    color: theme.palette.secondary.main,
    fontSize: '14px',
    padding: theme.spacing(1),
  },
  tooltipLocation: {
    maxWidth: '22rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tooltipLocationCityState: {
    maxWidth: '20rem',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  hamberder: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
});

// patient count must be blank if the user has not
// selected a serviceline
const columns = (classes, serviceline) => [
  {
    label: '',
    key: 'affiliated',
    style: { width: 14 },
    sortable: false,
    showFilter: false,
    format: (affiliated) => {
      if (affiliated > 0) {
        return (
          <Tooltip
            placement='bottom-start'
            title={
              <Typography
                style={{
                  fontSize: 13,
                  fontWeight: 200,
                }}
              >
                Affiliated Provider
              </Typography>
            }
          >
            <div
              style={{
                width: 14,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon className={classes.affiliatedIcon}>hub</Icon>
            </div>
          </Tooltip>
        );
      }
      return <></>;
    },
  },

  {
    label: 'Provider Name',
    key: 'x',
    style: { width: 200 },
    showFilter: false,
    sortable: false,
  },
  {
    label: 'NPI',
    showFilter: false,
    key: 'provider.npi',
  },
  {
    label: 'Specialty',
    key: 'provider.taxonomy',
    showFilter: false,
    sortable: false,
    format: (taxonomy) => SpecialtyUtil.getNameFromTaxonomy(taxonomy),
  },
  {
    label: 'Shared Patients',
    key: 'y',
    showFilter: false,
    sortable: false,
    format: (value) => (serviceline !== 'all' ? value.toLocaleString() : ''),
  },
  {
    label: 'Location Name',
    key: 'provider.location.name',
    showFilter: false,
    sortable: false,
  },
  {
    label: 'Location City',
    key: 'provider.location.city',
    showFilter: false,
    sortable: false,
  },
  {
    label: 'Location State',
    key: 'provider.location.state',
    showFilter: false,
    sortable: false,
  },
];

class ClaimsNetwork extends React.Component {
  constructor(props) {
    super(props);

    this.cardElement = React.createRef(null);

    this.state = {
      serviceline: 'all',
      before: false,
      provider2: null,
      specialty: [],
      isTable: false,
      query: {
        type: 'CLAIMS_NETWORK',
        filter: {
          provider_id: props.provider.id,
          serviceline: undefined,
          provider_id_2: null,
          before: false,
          taxonomy_code: undefined,
          patient_type: props.patientType,
        },
        opts: {
          limit: 25,
          offset: 0,
        },
      },
    };
  }

  componentDidMount() {
    window.addEventListener('popstate', this.handlePopState);
    const { network } = this.props;

    if (StoreUtil.needsLoadNoCache(network)) {
      this.fetchAllData();
    } else {
      this.fetchNetworkAffiliated();
    }
    if (window.history.state?.provider2) {
      const { provider2 } = window.history.state;
      if (provider2?.id !== this.props.provider?.id) {
        this.setState({ provider2 }, this.fetchData);
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener('popstate', this.handlePopState);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.provider !== prevProps.provider ||
      this.props.patientType !== prevProps.patientType
    ) {
      this.fetchAllData();
    }

    if (
      !StoreUtil.isLoaded(prevProps.network) &&
      StoreUtil.isLoaded(this.props.network)
    ) {
      this.fetchNetworkAffiliated();
    }
  }

  fetchAllData() {
    this.setState(
      {
        query: {
          type: 'CLAIMS_NETWORK',
          filter: {
            provider_id: this.props.provider.id,
            serviceline:
              this.state.serviceline === 'all' || this.state.provider2
                ? undefined
                : this.state.serviceline,
            provider_id_2: this.state.provider2
              ? this.state.provider2.id
              : undefined,
            before: this.state.before,
            taxonomy_code:
              this.state.specialty.length < 1
                ? undefined
                : this.state.specialty,
            patient_type: this.props.patientType,
          },
          opts: {
            limit: 25,
            offset: 0,
          },
        },
      },
      () => {
        this.fetchData();
        this.fetchSpecialtiesData();
      },
    );
  }

  fetchData(csv) {
    const {
      dispatch,
      providers,
      provider,
      patientType,
      servicelines,
      network,
      clientId,
    } = this.props;

    const { query, before, serviceline } = this.state;

    if (providers?.list) {
      StoreUtil.setInvalid(providers, StoreUtil.COMMON_LIST);
    }

    if (!csv) {
      dispatch(
        loadAnalytics({
          ...query,
          filter: {
            ...query.filter,
            before,
            provider_id: provider.id,
            serviceline:
              this.state.serviceline === 'all' || this.state.provider2
                ? undefined
                : this.state.serviceline,
            provider_id_2: this.state.provider2
              ? this.state.provider2.id
              : undefined,
            before,
            taxonomy_code:
              this.state.specialty.length < 1
                ? undefined
                : this.state.specialty,
            patient_type: patientType,
          },
        }),
      );
    } else {
      axiosInstance
        .post('/Analytics', {
          ...query,
          headers: {
            'Content-Type': 'text/csv',
          },
        },
          {
            params: {
              selected_client_id: this.props.clientId,
            }
          }
        )
        .then((response) => {
          const providerKey = before ? 'provider1' : 'provider2';
          const networkProviderIds = network.data.rows.map(
            (network) => network[providerKey].id,
          );
          axiosInstance
            .get('/Providers', {
              params: {
                opts: {
                  where: { id: networkProviderIds },
                  include: [
                    {
                      association: 'clients',
                      attributes: ['id'],
                      through: { attributes: [] }, // don't need the join table data
                      where: {
                        id: clientId,
                      },
                      duplicating: false,
                      paranoid: false,
                      required: true,
                    },
                  ],
                },
              },
              format: 'csv',
              headers: {
                'Content-Type': 'text/csv',
              },
            })
            .then((affiliatedResponse) => {
              const servicelineName =
                serviceline !== 'all'
                  ? ServiceLineUtil.findServiceLine(servicelines, serviceline)
                      .name
                  : 'all';
              const beforeOrAfter = this.state.before ? 'before' : 'after';
              const filename = `doctivity_SHARED_${beforeOrAfter.toUpperCase()}_${provider.first_name}_${provider.last_name}_${servicelineName}_${moment().format('YY_MM_DD')}.csv`;
              try {
                const affiliatedMap = new Set(
                  affiliatedResponse.data.map((row) => row.id),
                );
                const pIdx = this.state.before ? 1 : 2;
                const mapping = {
                  displayName: 'Providers with Shared Patients',
                  helpText: '',
                  downloadName: filename,
                  initialQuery: {},
                  columnMapping: {
                    patient_type: {
                      downloadName: 'Patients',
                      format: () =>
                        patientType === 'PEDIATRIC_PATIENTS'
                          ? 'Pediatric'
                          : 'Projected',
                    },
                    shared_patients: {
                      downloadName: 'Shared Patients',
                      format: (_v, row) => {
                        return row.sum_shared_patients.toFixed(0);
                      },
                    },
                    pct1: {
                      downloadName: 'Percent 1',
                      format: (_v, row) => {
                        return row.pct1?.toFixed(2) ?? '';
                      },
                    },
                    pct2: {
                      downloadName: 'Percent 2',
                      format: (_v, row) => {
                        return row.pct2?.toFixed(2) ?? '';
                      },
                    },
                    [`provider_${pIdx}_id`]: {
                      downloadName: 'ID',
                    },
                    [`provider${pIdx}.npi`]: {
                      downloadName: 'NPI',
                    },
                    [`provider${pIdx}.first_name`]: {
                      downloadName: 'First Name',
                    },
                    [`provider${pIdx}.middle_name`]: {
                      downloadName: 'Middle Name',
                    },
                    [`provider${pIdx}.last_name`]: {
                      downloadName: 'Last Name',
                    },
                    [`provider${pIdx}.taxonomy.code`]: {
                      downloadName: 'Taxonomy Code',
                    },
                    [`provider${pIdx}.taxonomy.grouping`]: {
                      downloadName: 'Taxonomy Grouping',
                    },
                    [`provider${pIdx}.taxonomy.classification`]: {
                      downloadName: 'Taxonomy Classification',
                    },
                    [`provider${pIdx}.taxonomy.specialization`]: {
                      downloadName: 'Taxonomy Specialization',
                    },
                    [`provider${pIdx}.location.name`]: {
                      displayName: 'Location Name',
                      displayable: false,
                    },
                    [`provider${pIdx}.location.address1`]: {
                      displayName: 'Address',
                      downloadName: 'Address 1',
                    },
                    [`provider${pIdx}.location.address2`]: {
                      displayName: 'Address 2',
                      displayable: false,
                    },
                    [`provider${pIdx}.location.city`]: {
                      displayName: 'City',
                    },
                    [`provider${pIdx}.location.state`]: {
                      displayName: 'State',
                      format: (v) => v?.toUpperCase() ?? '',
                    },
                    [`provider${pIdx}.location.postal_code`]: {
                      displayName: 'Zip',
                      format: (v) => (v ? `000000${String(v)}`.slice(-5) : ''),
                    },
                    id: {
                      downloadName: 'Affiliated',
                      format: (_v, row) =>
                        affiliatedMap.has(row[`provider_${pIdx}_id`])
                          ? 'Affiliated'
                          : 'Non-Affiliated',
                    },
                  },
                };
                const csv = convertToCSV(mapping, response?.data?.rows);
                const downloadName = mapping.downloadName.replace(
                  '{date}',
                  moment().format('YY_MM_DD'),
                );
                fileDownload(csv, downloadName);
              } catch (err) {
                console.error(`Could not format csv for ${filename}`);
                console.error(err);
                fileDownload(response.data, filename);
              }
            });
        });
    }
  }

  fetchSpecialtiesData() {
    this.props.dispatch(
      loadAnalytics({
        type: 'CLAIMS_NETWORK_SPECIALTIES',
        filter: {
          provider_id: this.props.provider.id,
          serviceline:
            this.state.serviceline === 'all' || this.state.provider2
              ? undefined
              : this.state.serviceline,
          provider_id_2: this.state.provider2
            ? this.state.provider2.id
            : undefined,
          before: this.state.before,
          patient_type: this.props.patientType,
        },
      }),
    );
  }

  fetchNetworkAffiliated() {
    const { dispatch, network, clientId } = this.props;

    const { before } = this.state;

    if (network?.data?.rows) {
      const providerKey = before ? 'provider1' : 'provider2';
      const networkProviderIds = network.data.rows.map(
        (network) => network[providerKey]?.id,
      );
      dispatch(
        queryProviders(
          {
            where: { id: networkProviderIds },
            include: [
              {
                association: 'clients',
                attributes: ['id'],
                through: { attributes: [] }, // don't need the join table data
                where: {
                  id: clientId,
                },
                duplicating: false,
                paranoid: false,
                required: false,
              },
            ],
          },
          API_PROVIDERS_LIST,
        ),
      );
    }
  }

  formatCSV(data, affiliated) {
    let columns = CsvUtil.stringToMatrix(data);
    const affiliatedIds = affiliated.map((affiliated) => affiliated.id);
    const providerNum = this.state.before ? '1' : '2';
    if (columns.length > 0) {
      let provider2IdIndex = -1;
      let provider_2_IdIndex = -1;
      let taxonomyIdIndex = -1;
      for (let index = 0; index < columns[0].length; index++) {
        if (columns[0][index] === `provider_${providerNum}_id`) {
          provider2IdIndex = index;
        } else if (columns[0][index] === `provider${providerNum}.id`) {
          provider_2_IdIndex = index;
        } else if (columns[0][index] === `provider${providerNum}.taxonomy.id`) {
          taxonomyIdIndex = index;
        } else if (columns[0][index] === 'pct1') {
          columns[0][index] = 'Percent 1';
        } else if (columns[0][index] === 'pct2') {
          columns[0][index] = 'Percent 2';
        } else if (columns[0][index] === 'sum_shared_patients') {
          columns[0][index] = 'Shared Patients';
        }
      }

      columns[0].push('Affiliated');
      for (let index = 1; index < columns.length - 1; index++) {
        if (columns[0].length > 0) {
          const providerIdIndex =
            provider2IdIndex > -1 ? provider2IdIndex : provider_2_IdIndex;
          const providerId = parseInt(columns[index][providerIdIndex]);
          if (providerId) {
            if (affiliatedIds.includes(providerId)) {
              columns[index].push('Yes');
            } else {
              columns[index].push('No');
            }
          } else {
            columns[index].push('No');
          }
        }
      }

      const idsToRemove = [
        provider2IdIndex,
        provider_2_IdIndex,
        taxonomyIdIndex,
      ]
        .filter((id) => id > -1)
        .sort((a, b) => b - a);
      idsToRemove.forEach((id) => {
        columns = DataUtil.removeMatrixColumn(columns, id);
      });
    }
    return CsvUtil.matrixToString(columns);
  }

  render() {
    const { classes, network, provider, providers } = this.props;
    const { provider2 } = this.state;

    const networkAffiliatedData = StoreUtil.getData(providers);
    const isLoaded = !(
      !network ||
      !StoreUtil.isLoaded(network) ||
      (!provider2 &&
        (!networkAffiliatedData ||
          network.data.rows.length !== networkAffiliatedData.length))
    );

    const data = [];
    const providerKey = this.state.before ? 'provider1' : 'provider2';
    if (StoreUtil.isLoaded(network)) {
      if (this.state.provider2) {
        // show tree map as servicelines within single provider
        network?.data?.rows?.forEach((d) => {
          data.push({
            x: d.serviceline ? d.serviceline.name : 'loading...',
            y: parseInt(d.sum_shared_patients, 10),
            id: d[providerKey].id,
            pct1: d.pct1,
            pct2: d.pct2,
            serviceline: d.serviceline,
          });
        });
      } else {
        // show tree map as all providers, maybe within a serviceline
        network?.data?.rows?.forEach((d) => {
          if (d[providerKey]) {
            const affiliated =
              networkAffiliatedData?.find(
                (networkAffiliated) =>
                  networkAffiliated.id === d[providerKey].id,
              )?.clients?.length > 0 || false;
            data.push({
              x: `${d[providerKey].last_name}, ${d[providerKey].first_name}`,
              y: parseInt(d.sum_shared_patients, 10),
              pct1: d.pct1,
              pct2: d.pct2,
              provider: d[providerKey],
              id: d[providerKey].id,
              fillColor: affiliated
                ? ColorUtil.AffiliatedColor
                : ColorUtil.NonAffiliatedColor,
              affiliated,
            });
          }
        });
      }
    }

    const series = [
      {
        data,
      },
    ];

    let contextMenu;

    const options = {
      legend: {
        show: false,
      },
      chart: {
        height: 350,
        type: 'treemap',
        toolbar: {
          show: false,
        },
        events: {
          click: (event, chartContext, config) => {
            if (this.state.provider2) {
              this.navigateToProvider(config);
            } else {
              this.onChartClick(event, chartContext, config);
            }
          },
          dataPointMouseEnter: (event, chartContext, config) => {
            contextMenu = new VanillaContextMenu({
              scope: document.querySelector('main'),
              customClass: classes.contextMenu,
              menuItems: [
                {
                  label: 'Open Link in New Tab',
                  callback: () => {
                    this.navigateToProvider(config, true);
                  },
                },
                {
                  label: 'Open Link in New Window',
                  callback: () => {
                    this.navigateToProvider(config, false, true);
                  },
                },
                {
                  label: 'Open Link',
                  callback: () => {
                    this.navigateToProvider(config);
                  },
                },
              ],
            });
            this.setState({ contextMenu: contextMenu });
          },
          dataPointMouseLeave: () => {
            contextMenu?.off();
          },
        },
        animations: {
          enabled: false,
          easing: 'easeinout',
          speed: 500,
          animateGradually: {
            enabled: true,
            delay: 75,
          },
          dynamicAnimation: {
            enabled: true,
            speed: 150,
          },
        },
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        },
      },
      tooltip: {
        custom: ({ dataPointIndex }) => {
          const d = { ...series[0].data[dataPointIndex] };
          if (this.state.provider2) {
            Object.assign(d, { provider: this.state.provider2 });
          }
          if (d.provider) {
            let sharedPatients = '';
            let servicelineName = '';
            if (this.state.serviceline !== 'all') {
              servicelineName = ServiceLineUtil.findServiceLine(
                this.props.servicelines,
                this.state.serviceline,
              )?.name;
            } else {
              servicelineName = ServiceLineUtil.findServiceLine(
                this.props.servicelines,
                d.serviceline?.id,
              )?.name;
            }

            sharedPatients = `<div>${d.y.toLocaleString()} shared patients</div>`;
            if (this.state.before) {
              if (d.pct2) {
                sharedPatients += `<div>${d.pct2}% of ${provider.last_name}, ${provider.first_name}'s [${servicelineName}] patients are first seen by ${d.provider.last_name}, ${d.provider.first_name}</div>`;
              }
              if (d.pct1) {
                sharedPatients += `<div>${d.pct1}% of ${d.provider.last_name}, ${d.provider.first_name}'s [${servicelineName}] patients were later seen by ${provider.last_name}, ${provider.first_name}</div>`;
              }
            } else {
              // after
              if (d.pct1) {
                sharedPatients += `<div>${d.pct1}% of ${provider.last_name}, ${provider.first_name}'s [${servicelineName}] patients are later seen by ${d.provider.last_name}, ${d.provider.first_name}</div>`;
              }
              if (d.pct2) {
                sharedPatients += `<div>${d.pct2}% of ${d.provider.last_name}, ${d.provider.first_name}'s [${servicelineName}] patients were first seen by ${provider.last_name}, ${provider.first_name}</div>`;
              }
            }
            return `<div style="padding:8px; display: flex; flex-direction: row;">
              ${d.affiliated ? `<div style="width: 32px;"><span class="${classes.affiliatedIcon}">hub</span></div>` : '<div></div>'}
<div>
              <div><b>${d.x}</b></div>
              <div class="${classes.tooltipLocation}">${d.provider?.location?.name ?? ''}</div>
              <div class="${classes.tooltipLocationCityState}">${d.provider?.location?.city ?? ''}${d.provider?.location?.state ? ', ' + d.provider.location?.state ?? '' : ''}</div>
              <div>${SpecialtyUtil.getNameFromTaxonomy(d.provider.taxonomy)}
              ${sharedPatients}</div></div>`;
          }
          return `<div style="padding:8px;">
              <div><b>${d.x}</b></div>
              <div>${d.y.toLocaleString()} shared patients</div>
            </div>`;
        },
      },
    };

    let specialties = [];
    if (StoreUtil.isLoaded(this.props.specialties)) {
      specialties = StoreUtil.getData(this.props.specialties).sort((a, b) =>
        SpecialtyUtil.getNameFromTaxonomy(a).localeCompare(
          SpecialtyUtil.getNameFromTaxonomy(b),
        ),
      );
    }
    const specialtiesCode = {};
    specialties?.forEach((s) => {
      const name = SpecialtyUtil.getNameFromTaxonomy(s);
      if (name.trim().length === 0) return;
      if (specialtiesCode[name] === undefined) {
        specialtiesCode[name] = 0;
      }
      specialtiesCode[name] += 1;
    });
    let specialtyDisplay = '';
    if (this.state.specialty.length > 0) {
      const specialty = this.state.specialty
        .map((specialty) =>
          this.props.specialties?.data.find((s) => s.code === specialty),
        )
        .map(
          (specialty) =>
            [
              specialty?.grouping,
              specialty?.classification,
              specialty?.specialization,
            ]
              .filter((s) => s)
              .join(': ') + ` (${specialty?.code})`,
        );
      specialtyDisplay = specialty;
    }

    return (
      <Card className={classes.card} ref={this.cardElement}>
        <CardContent>
          <TitleTooltip
            title='Providers with Shared Patients'
            tooltip='Insight into relationships between providers based on their shared patients.'
            rightLabel={null && 'Based on claims from all ages'}
            onExport={this.onExportClick}
          />
          {this.state.provider2 ? (
            <div className={classes.provider2}>
              <Typography>
                Service Lines for claims with
                {this.state.before &&
                  ` ${this.props.provider.last_name}, ${this.props.provider.first_name} before `}
              </Typography>
              <Link
                className={classes.providerLink}
                href={`/providers/${this.state.provider2?.id}/market`}
              >
                <SmallIconTextButton
                  icon='person'
                  text={`${this.state.provider2.last_name}, ${this.state.provider2.first_name}`}
                />
              </Link>
              {!this.state.before &&
                ` after ${this.props.provider.last_name}, ${this.props.provider.first_name}`}
            </div>
          ) : (
            <div className={classes.dropDownContainer}>
              <FormControl
                variant='outlined'
                className={classes.filterDropDown}
                key='direction'
              >
                <InputLabel className={classes.filterLabel}>
                  Claims Date
                </InputLabel>
                <Select
                  key='select'
                  value={this.state.before}
                  onChange={this.onDirectionChange}
                  label='Claims Date'
                >
                  <MenuItem value={false} key='after'>
                    {`After ${provider.last_name}, ${provider.first_name}`}
                  </MenuItem>
                  <MenuItem value key='before'>
                    {`Before ${provider.last_name}, ${provider.first_name}`}
                  </MenuItem>
                </Select>
              </FormControl>
              <div className={classes.dropDown}>
                <ServiceLineSelect
                  value={this.state.serviceline}
                  onChange={this.onServicelineChange}
                  showSubServicelines
                  showAll
                />
              </div>
              <FormControl
                variant='outlined'
                className={classes.specialtyDropDown}
                key='specialty'
              >
                <InputLabel shrink className={classes.filterLabel}>
                  Specialty
                </InputLabel>
                <Select
                  key='select'
                  value={this.state.specialty}
                  onChange={this.onSpecialtyChange}
                  multiple={true}
                  label='Specialty'
                  notched
                  displayEmpty
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>All</em>;
                    }
                    return selected
                      .map((s) => {
                        const specialty = this.props.specialties?.data.find(
                          (sp) => sp.code === s,
                        );
                        return SpecialtyUtil.getNameFromTaxonomy(specialty);
                      })
                      .join(', ');
                  }}
                  MenuProps={{
                    PaperProps: {
                      className: classes.select,
                    },
                  }}
                >
                  <ListSubheader
                    style={{
                      borderBottom: '1px solid black',
                      fontSize: 14,
                      fontWeight: 500,
                      color: '#000',
                    }}
                  >
                    {this.state.specialty.length} selected
                  </ListSubheader>

                  <MenuItem value='all' key='all'>
                    <em>All</em>
                  </MenuItem>
                  {specialties &&
                    specialties.map((s) => (
                      <MenuItem value={s.code} key={s.code}>
                        {SpecialtyUtil.getNameFromTaxonomy(s)}&nbsp;
                        {specialtiesCode[SpecialtyUtil.getNameFromTaxonomy(s)] >
                        1
                          ? `(${s.code})`
                          : ''}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <div className={classes.dropDown}>
                <PatientTypeSelect noCapturedClaims />
              </div>
              <div className={classes.hamberder}>
                <IconButton
                  className={classes.menuButton}
                  onClick={() =>
                    this.setState({ isTable: !this.state.isTable })}
                  size='large'
                >
                  {this.state.isTable ? (
                    <Tooltip title='Show Chart'>
                      <ViewModuleIcon />
                    </Tooltip>
                  ) : (
                    <Tooltip title='Show Table'>
                      <TableViewIcon title='Show Table' />
                    </Tooltip>
                  )}
                </IconButton>
              </div>
            </div>
          )}
          {!isLoaded && <LoadingView />}
          {isLoaded && network.data.rows.length === 0 && (
            <Typography className={classes.noData}>
              No shared patients.
            </Typography>
          )}
          {!this.state.provider2 && network?.data?.rows?.length > 0 && (
            <>
              <div className={classes.specializationDescriptor}>
                {specialtyDisplay.length > 0 && (
                  <div className={classes.specializationDescriptorContainer}>
                    <div>Specialty:</div>
                    <div className={classes.specializationDescriptor}>
                      {specialtyDisplay.map((s, i) => (
                        <span key={i}>{s}</span>
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {!this.state.isTable && <div className={classes.colorKey}>
                <div
                  className={classes.colorKeyIcon}
                  style={{
                    backgroundColor: ColorUtil.AffiliatedColor,
                  }}
                />
                <div className={classes.colorKeyText}>Affiliated</div>

                <div
                  className={classes.colorKeyIcon}
                  style={{
                    backgroundColor: ColorUtil.NonAffiliatedColor,
                  }}
                />
                <div className={classes.colorKeyText}>Non-Affiliated</div>
              </div>}
            </>
          )}
          {this.state.provider2 && (
            <SmallIconTextButton
              icon='chevron_left'
              text='Show All Providers'
              onClick={this.removeProvider2}
            />
          )}
          {isLoaded && network?.data.rows.length > 0 && !this.state.isTable && (
            <Chart
              type='treemap'
              height={350}
              options={options}
              series={series}
              colors={ColorUtil.getColors()}
            />
          )}
          {isLoaded && network?.data.rows.length > 0 && this.state.isTable && (
            <DynamicTable
              data={{
                data: {
                  rows: data,
                  count: network.data.count.length,
                },
                meta: network.meta,
              }}
              query={this.state.query.opts}
              onQueryChange={(query) =>
                this.setState(
                  (state) => ({ query: { ...state.query, opts: query } }),
                  this.fetchData,
                )}
              columns={columns(this.props.classes, this.state.serviceline)}
              noDataMessage='No data.'
            />
          )}
        </CardContent>
      </Card>
    );
  }

  onExportClick = () => {
    if (this.state.serviceline !== 'all') {
      this.fetchData(true);
    } else {
      this.props.dispatch(
        showConfirmationDialog({
          title: 'Download Requirement',
          content:
            'Choose a Service Line to see Provider Details and Download Data.',
          confirmLabel: 'OK',
          onConfirm: () => {
            // do nothing
          },
        }),
      );
    }
  };

  onChartClick = (event, chartContext, config) => {
    const { isMobile } = this.props;

    if (!isMobile && !this.state.provider2 && config.dataPointIndex > -1) {
      const providerKey = this.state.before ? 'provider1' : 'provider2';
      const provider2 =
        this.props.network.data.rows[config.dataPointIndex][providerKey];
      this.setState(
        {
          provider2,
          specialty: [],
        },
        this.fetchData,
      );
      window.history.pushState(
        { ...window.history.state, provider2 },
        '',
        window.location.toString() + '#now',
      );
    }
  };

  navigateToProvider = (config, newTab = false, newWindow = false) => {
    const { provider2, before } = this.state;
    const providerKey = before ? 'provider1' : 'provider2';
    let selectedProvider = provider2;
    if (!selectedProvider) {
      selectedProvider =
        config && config.dataPointIndex > -1
          ? this.props.network.data.rows[config.dataPointIndex][providerKey]
          : undefined;
    }
    if (selectedProvider) {
      const url = `/providers/${selectedProvider.id}/market`;
      if (newTab) {
        window.open(url, '_blank');
      } else if (newWindow) {
        window.open(url, '_blank', 'rel=noopener noreferrer');
      } else {
        this.props.router.navigate(url);
      }
    }
  };

  removeProvider2 = () => {
    this.setState(
      {
        provider2: null,
      },
      this.fetchData,
    );
  };

  onServicelineChange = (id) => {
    this.setState(
      {
        serviceline: id,
      },
      this.fetchAllData,
    );
  };

  onDirectionChange = (event) => {
    this.setState(
      {
        before: event.target.value,
      },
      this.fetchAllData,
    );
  };

  onSpecialtyChange = (event) => {
    if (event.target.value.includes('all')) {
      this.setState(
        {
          specialty: [],
        },
        this.fetchData,
      );
      return;
    }
    this.setState(
      {
        specialty: event.target.value.filter((s) => s),
      },
      this.fetchData,
    );
  };

  handlePopState = (event) => {
    const { state } = event;
    if (state?.provider2) {
      this.setState({ provider2: state.provider2 }, this.fetchData);
    } else {
      this.setState({ provider2: undefined }, this.fetchData);
    }
  };
}

ClaimsNetwork.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  isMobile: PropTypes.bool.isRequired,
  servicelines: PropTypes.array,
  provider: PropTypes.object,
  network: PropTypes.object,
  providers: PropTypes.object,
  specialties: PropTypes.object,
  patientType: PropTypes.oneOf(PatientTypes),
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    network: StoreUtil.get(state.analytics, 'CLAIMS_NETWORK'),
    specialties: StoreUtil.get(state.analytics, 'CLAIMS_NETWORK_SPECIALTIES'),
    servicelines: state.claims && state.claims.servicelines_grouped,
    providers: StoreUtil.get(state.providers, StoreUtil.COMMON_LIST),
    patientType:
      state.app.patientType === PatientType.CAPTURED_CLAIMS
        ? PatientType.ALL_PATIENTS
        : state.app.patientType,
  };
}

const styled = withStyles(styles)(withIsMobile(ClaimsNetwork));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ClaimsNetwork };
