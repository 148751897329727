export const API_MARKETS_LIST = 'API_MARKETS_LIST';
export const API_MARKETS_UPSERT = 'API_MARKETS_UPSERT';
export const API_MARKETS_DELETE = 'API_MARKETS_DELETE';

export const listMarkets = (clientId, includeCounties) => ({
  type: API_MARKETS_LIST,
  request: {
    url: '/Markets',
    method: 'GET',
    params: {
      includeCounties,
      clientId,
    },
  },
});

export const upsertMarket = (data) => {
  const id = data.id || 0; // should be an id, but this is an array?
  return {
    type: API_MARKETS_UPSERT,
    id,
    request: {
      url: '/Markets',
      method: 'PUT',
      params: { opts: null },
      data,
    },
    nextAction: listMarkets(data.client_id, true),
  };
};

export const removeMarket = (id, clientId) => ({
  type: API_MARKETS_DELETE,
  id,
  request: {
    url: '/Markets',
    method: 'DELETE',
    params: { opts: null },
    data: [id],
  },
  nextAction: listMarkets(clientId, true),
});
