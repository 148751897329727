import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/*
* This class is a component inside AppRoot.
* To use it you can use systemAction.showConfirmationDialog(props)
*/
export function ConfirmationDialog({
  open,
  title,
  content,
  onClose,
  onConfirm,
  onCancel,
  cancelLabel,
  confirmLabel,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      { title && (
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      )}
      { content && (
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {content}
        </DialogContentText>
      </DialogContent>
      )}
      <DialogActions>
        { (onCancel || cancelLabel) && (
        <Button onClick={onCancel || onClose} color='primary'>
          { cancelLabel || 'Cancel' }
        </Button>
        )}
        <Button onClick={onConfirm} color='primary' autoFocus>
          { confirmLabel || 'Yes' }
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
};
