import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Button,
  Icon,
  Typography,
  FormControlLabel,
  Switch,
} from '@mui/material';

import {
  TableUtil,
  withRouter,
} from 'utils';

import {
  StoreUtil,
  DateUtil,
} from 'doctivity-shared/utils';

import {
  DynamicTable,
  UserEditDialog,
  LoadingView,
} from 'components';

import {
  queryUsers,
  upsertUser,
} from 'store/actions/usersActions';

import {
  listClients,
} from 'store/actions/clientsActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  tableHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacer: {
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  switchLabel: {
    fontSize: 12,
    fontWeight: 200,
  }
});

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'First Name',
    key: 'first_name',
    sortable: true,
    style: { minWidth: 100 },
  },
  {
    label: 'Last Name',
    key: 'last_name',
    sortable: true,
    style: { minWidth: 100 },
  },
  {
    label: 'Clients',
    key: 'clients',
    // join all clients names together
    format: (clients) => {
      if (clients && clients.length > 0) {
        return clients.map((client) => client.name).join(', ');
      }
      return '';
    },
    sortable: false,
    filter: false,
  },
  {
    label: 'Last Login',
    key: 'last_login',
    sortable: true,
    format: DateUtil.formatFromDB,
    filter: false,
  },
  {
    label: 'Admin',
    key: 'is_admin',
    sortable: true,
    sortDown: false,
    format: TableUtil.boolToCheckIcon,
    style: { width: 75 },
  },
  {
    label: 'Active',
    key: 'is_active',
    sortable: true,
    sortDown: false,
    format: TableUtil.boolToCheckIcon,
    style: { width: 75 },
  },
];

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        include: [
          {
            association: 'clients',
            attributes: ['id', 'name'],
          },
        ],
        order: [
          ['last_name', 'ASC'],
        ],
        limit: 25,
        offset: 0,
      },
      filterClient: false,
      isUserDialogOpen: false,
    };
  }

  componentDidMount() {
    const {
      users,
      dispatch,
      clients,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(users)) {
      this.fetchData();
    }

    if (StoreUtil.needsLoadLongCache(clients)) {
      dispatch(listClients());
    }
  }

  componentDidUpdate(prevProps) {
    const {
      users,
      dispatch,
      clients,
    } = this.props;
    // anytime a user has been edited, update the list
    if (this.props.editingUser !== prevProps.editingUser) {
      // is the list data older than the edited data?
      if (StoreUtil.hasSavedSinceLoad(this.props.editingUser, this.props.users)) {
        this.fetchData();
      }
    }

    if (StoreUtil.needsLoadNoCache(users)) {
      this.fetchData();
    }
    if (StoreUtil.needsLoadLongCache(clients)) {
      dispatch(listClients());
    }
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    const {
      query,
    } = this.state;

    const clientsInclude = query.include.find((i) => i.association === 'clients' );
    if (this.state.filterClient) {
      if (!clientsInclude.where) {
        clientsInclude.where = { };
      }
      clientsInclude.where.id = this.props.clientId;
      clientsInclude.required = true;
    } else {
      if (clientsInclude.where && clientsInclude.where.id) {
        delete clientsInclude.where.id;
      }
      clientsInclude.required = false;
    }

    console.log(query);
    this.props.dispatch(queryUsers(query));
  }

  render() {
    const {
      users,
      clients,
      editingUser,
      classes,
    } = this.props;

    const {
      query,
      isUserDialogOpen,
    } = this.state;

    // is a user being edited now?
    if (StoreUtil.isSaving(editingUser)) {
      return (<LoadingView />);
    }

    return (
      <>
        <Helmet defer={false}>
          <title>Users</title>
        </Helmet>
        <div className={classes.container}>
          <DynamicTable
            header={
              <div className={classes.tableHeaderContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
                >
                  Users
                </Typography>
                <div className={classes.spacer} />
                <FormControlLabel
                  control={<Switch checked={this.state.filterClient} onChange={this.onFilterClient} />}
                  label={<Typography className={classes.switchLabel}>Filter by Selected Client</Typography>}
                />
                <div className={classes.spacer} />
                <Button
                  color='primary'
                  size='small'
                  onClick={() => {
                    this.openUserDialog();
                  }}
                >
                  <Icon className={classes.leftIcon}>add</Icon>
                  Add User
                </Button>
              </div>
            }
            columns={columns}
            query={query}
            data={users}
            onRowClick={this.onRowClick}
            onQueryChange={this.onQueryChange}
          />
          <UserEditDialog
            open={isUserDialogOpen}
            clients={StoreUtil.getData(clients)}
            onClose={this.closeUserDialog}
            onSave={this.onSave}
          />
        </div>
      </>
    );
  }

  onRowClick = (row) => {
    this.props.router.navigate(`/users/${row.id}`);
  };

  onSave = (data) => {
    const {
      dispatch,
    } = this.props;

    dispatch(upsertUser(data));

    this.setState({
      isUserDialogOpen: false,
    });
  };

  openUserDialog = () => {
    this.setState({
      isUserDialogOpen: true,
    });
  };

  closeUserDialog = () => {
    this.setState({
      isUserDialogOpen: false,
    });
  };

  onFilterClient = (event) => {
    this.setState({
      filterClient: event.target.checked,
    }, this.fetchData);
  };
}

UsersPage.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
  clients: PropTypes.object,
  editingUser: PropTypes.object,
  classes: PropTypes.object,
  clientId: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    users: StoreUtil.get(state.users, StoreUtil.COMMON_TABLE),
    clients: StoreUtil.get(state.clients, StoreUtil.COMMON_LIST),
    editingUser: StoreUtil.get(state.users, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(UsersPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as UsersPage };
