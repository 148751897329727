import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  Typography,
} from '@mui/material';

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import {
  DynamicTable,
} from 'components';
import { FindContactDialog } from 'contacts/FindContactDialog';
import {
  addContactToOrganization,
  queryOrganizationContacts,
} from 'store/actions/organizationsActions';

const styles = (theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Last Name',
    key: 'last_name',
    fulltext: true,
    style: { minWidth: 100 },
  },
  {
    label: 'First Name',
    key: 'first_name',
    fulltext: true,
    style: { minWidth: 100 },
  },
  {
    label: 'Email',
    key: 'email',
    style: { width: 100 },
  },
  {
    label: 'Phone',
    key: 'phone',
    style: { width: 100 },
  },
];

class OrganizationContacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addContactOpen: false,
      query: {
        where: {
        },
        order: [
          ['last_name', 'DESC'],
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const {
      contacts,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(contacts)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (!prevProps.organization || this.props.organization.id !== prevProps.organization.id) {
      this.fetchData();
    }
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    const {
      organization,
    } = this.props;

    if (organization && organization.id) {
      this.props.dispatch(
        queryOrganizationContacts(
          organization.id,
          this.props.app.selectedClient,
          this.state.query,
        ),
      );
    }
  }

  render() {
    const {
      classes,
      contacts,
    } = this.props;

    const {
      query,
    } = this.state;

    return (
      <>
        <DynamicTable
          header={(
            <div className={classes.headerContainer}>
              <Typography component='h2' variant='h6' color='primary' gutterBottom>
                Contacts
              </Typography>
              <div className={classes.header} />
              {/*
                <Button
                  color='primary'
                  size='small'
                  onClick={this.onOpenAddContact}
                >
                  <Icon className={classes.leftIcon}>add</Icon>
                  Add Contact
                </Button>
              */}
            </div>
          )}
          showFilters={false}
          noDataMessage='No contacts in this organization.'
          columns={columns}
          query={query}
          data={contacts}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick}
        />
        { this.state.addContactOpen && (
          <FindContactDialog
            open={this.state.addContactOpen}
            onSelect={this.onContactSelected}
          />
        )}
      </>
    );
  }

  onRowClick = (row) => {
    this.props.router.navigate(`/contacts/${row.id}`);
  };

  /*
  onOpenAddContact = () => {
    this.setState({
      addContactOpen: true,
    });
  };

  onCloseAddContact = () => {
    this.setState({
      addContactOpen: false,
    });
  };
  */

  onContactSelected = (contact) => {
    this.onCloseAddContact();
    if (contact && contact.id) {
      this.props.dispatch(
        addContactToOrganization(
          this.props.organization.id,
          contact.id,
          this.state.query,
        ),
      );
    }
  };
}

OrganizationContacts.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  contacts: PropTypes.object.isRequired,
  classes: PropTypes.object,
  organization: PropTypes.object.isRequired,
  app: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    contacts: StoreUtil.get(state.organizations, 'contacts'),
    app: state.app,
  };
}

const styled = withStyles(styles)(OrganizationContacts);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as OrganizationContacts };
