import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_CLAIMS_SERVICE_LINES_TABLE,
  API_CLAIMS_SERVICE_LINES_LIST,
  API_CLAIMS_ORGANIZATIONS_LIST,
  API_CLAIMS_LIST,
  API_CLAIMS_PAYERS_LIST,
} from 'store/actions/claimsActions';

export const claims = (state = {}, action = null) => {
  let result = ReducerUtil.reduceTableGet(state, action, API_CLAIMS_SERVICE_LINES_TABLE, 'servicelines_table');
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceListGet(state, action, API_CLAIMS_SERVICE_LINES_LIST, 'servicelines_list');
  if (result.actionConsumed) {
    if (result.state.servicelines_list && result.state.servicelines_list.data) {
      // organize these by heiarchy
      const grouped = [];
      const groupedById = {};
      result.state.servicelines_list.data.forEach((s) => {
        if (!s.parent_id) {
          groupedById[s.id] = {
            ...s,
            children: groupedById[s.id] ? groupedById[s.id].children : [],
          };
          grouped.push(groupedById[s.id]);
        } else if (groupedById[s.parent_id]) {
          groupedById[s.parent_id].children.push(s);
        } else {
          groupedById[s.parent_id] = {
            id: s.parent_id,
            children: [{
              ...s,
            }],
          };
        }
      });
      return {
        ...result.state,
        servicelines_grouped: grouped,
      };
    }

    return result.state;
  }

  result = ReducerUtil.reduceListGet(state, action, API_CLAIMS_LIST, 'claims_list');
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceQuery(state, action, API_CLAIMS_ORGANIZATIONS_LIST, 'claims_by_organization');
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceListGet(state, action, API_CLAIMS_PAYERS_LIST, 'payers_list');
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
