import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { 
  Icon,
  Button,
  Typography,
  Menu,
  MenuItem
} from '@mui/material';
import axios from 'axios';
import axiosInstance from 'utils/axiosUtil';
import { StoreUtil } from 'doctivity-shared/utils';
import {
  DynamicTable,
  DocumentDialog,
} from 'components';
import {
  queryDocuments,
  upsertDocument,
  deleteDocument,
} from 'store/actions/documentsActions';

import {
  wakeupServer,
  showConfirmationDialog,
} from 'store/actions/systemActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  title: {
    padding: theme.spacing(3),
    paddingBottom: 51,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    backgroundColor: theme.palette.primary.accent,
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  spacer: {
    flex: 1,
  },
  button: {
    color: '#FFF',
  },
});

class UploadedDocuments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        where: { },
        offset: 0,
        limit: 25,
        order: [
          ['created_at', 'DESC'],
        ],
      },

      rowMenuEl: null,
      rowMenuData: null,

      isUploadDialogOpen: false,
      uploadDialogState: 'EDIT',
      uploadProgress: 0,

      editingDocument: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }

    // anytime a document has been edited, update the list
    if (this.props.documentItem !== prevProps.documentItem) {
      // is the list data older than the edited data?
      if (StoreUtil.hasSavedSinceLoad(this.props.documentItem, this.props.documents)) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const {
      dispatch,
      app,
    } = this.props;
    const { query } = this.state;

    // if (!user.is_admin) {
    //   query.where.status = 'UPLOADED'; // only show uploaded files to non users
    // }
    // restrict to current selected client
    query.where.client_id = app.selectedClient;
    dispatch(queryDocuments(query));
  }

  render() {
    const {
      classes,
      documents,
      app,
    } = this.props;

    const {
      query,
    } = this.state;

    const columns = [
      {
        label: 'Name',
        key: 'name',
        style: { width: 200 },
      },
      {
        label: 'Description',
        key: 'description',
        filter: false,
      },
      {
        label: 'Type',
        key: 'content_type',
        filter: false,
        style: { width: 100 },
        format: this.formatContentType,
      },
      {
        label: 'Size',
        key: 'size_bytes',
        filter: false,
        style: { width: 80 },
        format: this.formatSize,
      },
    ];
    // if (user.is_admin) {
    columns.push({
      label: 'Status',
      key: 'status',
      style: { width: 100 },
    });
    // }

    return (
      <>
        <Helmet defer={false}>
          <title>Documents</title>
        </Helmet>
        <div className={classes.container}>
          <DynamicTable
            header={
              <div className={classes.headerContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
                >
                  Documents
                </Typography>
                <div className={classes.spacer} />
                <Button
                  color='primary'
                  size='small'
                  onClick={() => this.openUploadDialog(undefined)}
                >
                  <Icon className={classes.leftIcon}>backup</Icon>
                  Upload Data
                </Button>
              </div>
            }
            columns={columns}
            data={documents}
            query={query}
            onQueryChange={this.onQueryChange}
            onRowClick={this.onRowClick}
            rowSettings={() => true}
            onRowSettingsClick={this.openRowSettingsMenu}
            noDataMessage='No documents found.'
          />
          <DocumentDialog
            clientId={app.selectedClient}
            isDataUpload={false}
            open={this.state.isUploadDialogOpen}
            onClose={this.closeUploadDialog}
            onExited={this.handleUploadExited}
            onUpload={this.startUpload}
            uploadState={this.state.uploadDialogState}
            uploadProgress={this.state.uploadProgress}
            editing={this.state.editingDocument || null}
          />
          <Menu
            anchorEl={this.state.rowMenuEl}
            open={Boolean(this.state.rowMenuEl)}
            onClose={this.closeRowSettingsMenu}
          >
            {this.isDocumentEditable(this.state.rowMenuData) && <MenuItem onClick={this.onEdit}>Edit</MenuItem>}
            <MenuItem onClick={this.onDownload}>Download</MenuItem>
            {this.isDocumentEditable(this.state.rowMenuData) && <MenuItem onClick={this.onDelete}>Delete</MenuItem>}
          </Menu>
        </div>
      </>
    );
  }

  startUpload = (data) => {
    if (this.state.editingDocument) {
      this.closeUploadDialog();
      this.props.dispatch(upsertDocument(data));
    } else {
      this.setState({
        uploadDialogState: 'LOADING',
      });

      const wakeupInterval = setInterval(
        () => {
          this.props.dispatch(wakeupServer());
        },
        5 * 60 * 1000, // ever 5 min make sure app doesn't logout
      );
      axiosInstance.post('/Documents', {
        name: data.name,
        description: data.description,
        client_id: data.client_id,
        original_filename: data.file.name,
        size_bytes: data.file.size,
        content_type: data.file.type,
      }, {
        params: {
          selected_client_id: data.client_id,
        }
      }).then((response) => {
        const result = response.data;
        axios(result.uploadUrl, {
          method: 'PUT',
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 1000) / progressEvent.total,
            ) / 10;
            this.setState({
              uploadProgress: percentCompleted,
            });
          },
          data: data.file,
          headers: {
            'content-type': data.file.type,
            // 'x-amz-acl': 'authenticated-read',
            // 'x-amz-meta-key': result.key,
          },
        }).then(() => {
          this.setState({
            uploadDialogState: 'SUCCESS',
          }, () => {
            this.fetchData();
          });
          clearInterval(wakeupInterval);
        }).catch(() => {
          this.setState({
            uploadDialogState: 'ERROR',
          });
          clearInterval(wakeupInterval);
        });
      });
    }
  };

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  openUploadDialog = (editingDocument) => {
    this.setState({
      isUploadDialogOpen: true,
      uploadDialogState: 'EDIT',
      editingDocument,
    });
  };

  closeUploadDialog = () => {
    if (this.state.uploadDialogState !== 'LOADING') {
      this.setState({
        isUploadDialogOpen: false,
        editingDocument: null,
      });
    }
  };

  handleUploadExited = () => {
    this.setState({
      uploadDialogState: 'EDIT',
    });
  };

  onRowClick = (row) => {
    axiosInstance.get(`/DocumentsDownload/${row.id}`).then((response) => {
      // create a link, click it, then remove it... ugly hack
      const link = document.createElement('a');
      link.href = response.data.url;
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  openRowSettingsMenu = (row, e) => {
    e.stopPropagation();
    this.setState({
      rowMenuEl: e.currentTarget,
      rowMenuData: row,
    });
  };

  closeRowSettingsMenu = () => {
    this.setState({
      rowMenuEl: null,
      rowMenuData: null,
    });
  };

  onEdit = () => {
    this.closeRowSettingsMenu();
    const doc = this.state.rowMenuData;
    if (doc && doc.id) {
      this.openUploadDialog(doc);
    }
  };

  onDelete = () => {
    this.closeRowSettingsMenu();
    const doc = this.state.rowMenuData;
    const { dispatch } = this.props;

    if (doc && doc.id) {
      dispatch(
        showConfirmationDialog({
          title: 'Delete Document',
          content:
            'This action will delete the selected upload for all users who can view it, and it cannot be undone.',
          confirmLabel: 'Delete',
          onConfirm: () => {
            dispatch(deleteDocument(doc.id, this.state.query));
          },
          onCancel: () => {
            // Do nothing
          },
        })
      );
    }
  };

  onDownload = () => {
    this.closeRowSettingsMenu();
    const doc = this.state.rowMenuData;
    if (doc && doc.id) {
      this.onRowClick(doc);
    }
  };

  formatSize = (size) => {
    if (size > 1048576) {
      return `${Number(Math.round(size / 1048576)).toLocaleString()}MB`;
    }
    if (size > 1024) {
      return `${Number(Math.round(size / 1024)).toLocaleString()}KB`;
    }
    if (size > 0) {
      return `${Number(size).toLocaleString()}B`;
    }
    return size;
  };

  formatContentType = (contentType) => {
    if (contentType && contentType.length > 0) {
      const parts = contentType.split('/');
      if (parts.length > 1) {
        return parts[1];
      }
      return contentType;
    }
    return '';
  };

  isDocumentEditable = (row) => {
    const {
      user,
    } = this.props;

    return row && (user.is_admin || row.user_id === user.id);
  }
}

UploadedDocuments.propTypes = {
  classes: PropTypes.object.isRequired,
  documents: PropTypes.object,
  documentItem: PropTypes.object,
  app: PropTypes.object,
  user: PropTypes.object,
  dispatch: PropTypes.func,
};

function mapStateToProps(state) {
  const {
    app,
    user,
    documents,
  } = state;

  return {
    app,
    user,
    documents: StoreUtil.get(documents, StoreUtil.COMMON_TABLE),
    documentItem: StoreUtil.get(documents, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(UploadedDocuments);
const connected = connect(mapStateToProps)(styled);
export { connected as UploadedDocuments };
