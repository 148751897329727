import {
  StoreUtil,
} from 'doctivity-shared/utils';

class ActivityLogsUtil {
  static getProvidersFromLogs(activityLogs, userId) {
    const logs = StoreUtil.get(activityLogs, 'by_user', `Providers_${userId}`);

    if (logs?.data?.rows?.length > 0 && logs.data.rows[0].provider) {
      const newRows = logs.data?.rows?.map((row) => row.provider) || [];
      const newFavoriteProviders = {
        ...logs,
        data: {
          ...logs.data,
          rows: newRows,
        },
      };
      return newRows.length > 0 ? newFavoriteProviders : undefined;
    }

    return logs;
  }

  static getContactsFromLogs(activityLogs, userId) {
    const logs = StoreUtil.get(activityLogs, 'by_user', `Contacts_${userId}`);

    if (logs?.data?.rows?.length > 0 && logs.data.rows[0].contact) {
      const newRows = logs.data?.rows?.map((row) => row.contact) || [];
      const newFavoriteContacts = {
        ...logs,
        data: {
          ...logs.data,
          rows: newRows,
        },
      };
      return newRows.length > 0 ? newFavoriteContacts : undefined;
    }

    return logs;
  }

  static getOrganizationsFromLogs(activityLogs, userId) {
    const logs = StoreUtil.get(
      activityLogs,
      'by_user',
      `Organizations_${userId}`
    );

    if (logs?.data?.rows?.length > 0 && logs.data.rows[0].organization) {
      const newRows = logs.data?.rows?.map((row) => row.organization) || [];
      const newOrganizations = {
        ...logs,
        data: {
          ...logs.data,
          rows: newRows,
        },
      };
      return newRows.length > 0 ? newOrganizations : undefined;
    }

    return logs;
  }

  static getLocationsFromLogs(activityLogs, userId) {
    const logs = StoreUtil.get(
      activityLogs,
      'by_user',
      `Locations_${userId}`
    );

    if (logs?.data?.rows?.length > 0 && logs.data.rows[0].location) {
      const newRows = logs.data?.rows?.map((row) => row.location) || [];
      const newLocations = {
        ...logs,
        data: {
          ...logs.data,
          rows: newRows,
        },
      };
      return newRows.length > 0 ? newLocations : undefined;
    }

    return logs;
  }
}
export { ActivityLogsUtil };
export default ActivityLogsUtil;
