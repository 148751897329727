import React from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  LoadingView,
  TitleTooltip,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  loadAnalytics,
} from 'store/actions/analyticsActions';
import { withIsMobile } from 'utils';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class VisitTypePieChart extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.providerId !== prevProps.providerId
      || this.props.department !== prevProps.department
      || this.props.referringOrganization !== prevProps.referringOrganization
      || this.props.clientId !== prevProps.clientId
      || this.props.app.dateRange !== prevProps.app.dateRange) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(loadAnalytics({
      type: 'VISIT_TYPES_BY_PROVIDER',
      filter: {
        provider_id: this.props.providerId,
        client_id: this.props.clientId,
        department: this.props.department,
        referring_organization: this.props.referringOrganization,
      },
      opts: {
        from: this.props.app.dateRange.start,
        to: this.props.app.dateRange.end,
        limit: 7, // number of types to show in pie chart before making "other"
      },
    }));
  }

  render() {
    const {
      classes,
      data,
      isMobile,
    } = this.props;

    let graph;
    if (!data
      || StoreUtil.isLoading(data)) {
      graph = (<LoadingView />);
    } else if (data && data.data && data.data.length > 0) {
      const labels = [];
      const counts = [];

      StoreUtil.getData(data).forEach((row) => {
        if (row.diagnosis_description) {
          if (row.diagnosis_description.length > 27) {
            labels.push(`${row.diagnosis_description.substring(0, 27)}...`);
          } else {
            labels.push(row.diagnosis_description);
          }
        } else {
          labels.push('Unavailable');
        }
        counts.push(row.count_diagnosis_description);
      });

      graph = (
        <Chart
          type='pie'
          {...(isMobile ? {} : {height: 210})}
          options={{
            ...(isMobile ? { legend: { horizontalAlign: 'left', position: 'bottom' } } : {}),
            labels,
          }}
          series={counts}
        />
      );
    } else {
      graph = (
        <Typography className={classes.nothingFound}>
          No visit type data available.
        </Typography>
      );
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <TitleTooltip
              title='Visit Types'
            />
          </div>

          {graph}

        </CardContent>
      </Card>
    );
  }
}

VisitTypePieChart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  data: PropTypes.object,
  providerId: PropTypes.number,
  clientId: PropTypes.number.isRequired,
  department: PropTypes.string,
  referringOrganization: PropTypes.string,
  isMobile: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
    analytics,
    app,
  } = state;

  return {
    app,
    data: StoreUtil.get(analytics, 'VISIT_TYPES_BY_PROVIDER'),
  };
}

const styled = withStyles(styles)(withIsMobile(VisitTypePieChart));
const connected = connect(mapStateToProps)(styled);
export { connected as VisitTypePieChart };
