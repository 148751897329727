import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_VISITS_TABLE,
  API_VISITS_DEPARTMENTS,
  API_VISITS_REFERRING_ORGANIZATIONS,
} from 'store/actions/visitsActions';

export const visits = (state = {}, action = null) => {
  let result = ReducerUtil.reduceTableGet(
    state,
    action,
    API_VISITS_TABLE,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceListGet(state, action, API_VISITS_DEPARTMENTS, 'departments');
  if (result.actionConsumed) {
    if (action.type === API_VISITS_DEPARTMENTS && action.clientId) {
      result.state.departmentsClientId = action.clientId;
    }
    return result.state;
  }

  result = ReducerUtil.reduceListGet(state, action, API_VISITS_REFERRING_ORGANIZATIONS, 'referringOrganizations');
  if (result.actionConsumed) {
    if (action.type === API_VISITS_REFERRING_ORGANIZATIONS && action.clientId) {
      result.state.referringOrganizationsClientId = action.clientId;
    }
    return result.state;
  }

  return state;
};
