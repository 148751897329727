import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import { DynamicTable, LoadingView, ContainedSmallIconButton } from 'components';

import { Typography } from '@mui/material';

import {
  queryContacts,
  upsertContact,
} from 'store/actions/contactsActions';

import { ContactEditDialog } from './ContactEditDialog';

const styles = (theme) => ({
  title: {
    padding: theme.spacing(3),
    paddingBottom: 51,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    backgroundColor: theme.palette.primary.accent,
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  container: {
    padding: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Last Name',
    key: 'last_name',
    fulltext: true,
    style: { minWidth: 70 },
  },
  {
    label: 'First Name',
    key: 'first_name',
    fulltext: true,
    style: { minWidth: 70 },
  },
  {
    label: 'Organization',
    key: 'location.organization.name',
    fulltext: true,
  },
  { 
    label: 'Company',
    key: 'company',
    style: { minWidth: 100 },
  },
  {
    label: 'Owner',
    key: 'owner.last_name',
    format: (value, row) => {
      if (row.owner) {
        return `${row.owner.last_name}, ${row.owner.first_name}`;
      }
      return '';
    },
    style: { width: 80 }
  },
];

class ContactsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        where: { },
        order: [
          ['last_name', 'ASC'],
        ],
        include: [
          {
            association: 'location',
            required: false,
            include: [{
              association: 'organization',
              attributes: ['id', 'name'],
              required: false,
            }],
          },
          {
            association: 'owner',
            attributes: ['id', 'first_name', 'last_name'],
          },
        ],
        limit: 25,
        offset: 0,
      },
      addDialogOpen: false,
    };
  }

  componentDidMount() {
    const {
      contacts,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(contacts)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    // if so and it is newr than the list, update list
    if ((this.props.editing !== prevProps.editing
        && StoreUtil.hasSavedSinceLoad(this.props.editing, this.props.contacts))
        || this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      dispatch,
    } = this.props;

    const {
      query,
    } = this.state;

    // trying to search by organization name
    if (query.include[0].include[0].where
      && Object.keys(query.include[0].include[0].where).length) {
      query.include[0].include[0].required = true;
      query.include[0].required = true;
    } else {
      query.include[0].include[0].required = false;
      query.include[0].required = false;
    }
    query.where.client_id = this.props.app.selectedClient;
    // console.log('QUERY: ', query);
    dispatch(queryContacts(query));
  }

  render() {
    const {
      contacts,
      editing,
      classes,
    } = this.props;

    const {
      query,
    } = this.state;

    // is a user being edited now?
    if (StoreUtil.isSaving(editing)) {
      return (<LoadingView />);
    }

    return (
      <>
        <Helmet defer={false}>
          <title>Contacts</title>
        </Helmet>
        <div className={classes.title}>
          <Typography className={classes.headerTitle}>Contacts</Typography>
          <ContainedSmallIconButton
            icon='add'
            text='New Contact'
            onClick={this.onOpenAddDialog}
          />
        </div>
        <div className={classes.container}>
          <DynamicTable
            header={
              <div className={classes.headerContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
                >
                  Contacts
                </Typography>
                <div className={classes.header} />
              </div>
            }
            columns={columns}
            query={query}
            data={contacts}
            noDataMessage='No Contacts Found'
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/contacts/${row.id}`}
          />
          <ContactEditDialog
            open={this.state.addDialogOpen}
            onSave={this.onSaveContact}
            onClose={this.onCloseAddDialog}
          />
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };

  onOpenAddDialog = () => {
    this.setState({
      addDialogOpen: true,
    });
  };

  onCloseAddDialog = () => {
    this.setState({
      addDialogOpen: false,
    });
  };

  onSaveContact = (contact) => {
    contact.client_id = this.props.app.selectedClient;
    this.props.dispatch(upsertContact(contact));
    this.onCloseAddDialog();
  };
}

ContactsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  app: PropTypes.object,
  contacts: PropTypes.object.isRequired,
  editing: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    contacts,
    user,
  } = state;

  return {
    app: state.app,
    user,
    contacts: StoreUtil.get(contacts, StoreUtil.COMMON_TABLE),
    editing: StoreUtil.get(contacts, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(ContactsPage);
const connected = connect(mapStateToProps)(styled);
export { connected as ContactsPage };
