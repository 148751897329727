import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Button,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
} from '@mui/material';

const styles = (theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  formItem: {
    marginTop: theme.spacing(2),
  },
  instructions: {
    fontSize: 12,
    color: '#333333',
    fontWeight: 300,
    paddingBottom: 4,
  },
});

class FindCodesDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      code_type: null,
      codes: null,
      codes_text: null,
    };
  }

  resetState() {
    this.setState({
      code_type: null,
      codes: null,
      codes_text: null,
    });
  }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <Dialog
        open={this.props.open}
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='sm'
        TransitionProps={{
          onExited: this.handleUploadExited,
        }}
      >
        <DialogTitle>
          Filter by Codes
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FormControl>
            <RadioGroup
              value={this.getCodesType()}
              name='radio-buttons-group'
              onChange={this.onTypeChange}
            >
              <FormControlLabel value='dx' control={<Radio />} label='Diagnoses (ICD-10-CM)' />
              <FormControlLabel value='px' control={<Radio />} label='Procedures (HCPCS & ICD-10-PCS)' />
            </RadioGroup>
          </FormControl>
          <Typography className={classes.instructions}>
            Provide all codes separated by commas.
          </Typography>
          <FormControl key='codes'>
            <TextField
              label='Codes'
              multiline
              rows={6}
              variant='outlined'
              inputProps={{ maxLength: 510 }}
              value={this.getCodesText()}
              onChange={this.onCodesChange}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={this.onClose} color='primary'>
            Filter
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  getCodesType() {
    if (this.state.code_type === null) {
      return this.props.codesType || 'dx';
    }
    return this.state.code_type ||'dx';
  }

  getCodes() {
    if (this.state.codes === null) {
      return this.props.codes || [];
    }
    return this.state.codes || [];
  }

  getCodesText() {
    if (this.state.codes_text === null && this.props.codes) {
      return this.props.codes.join(', ');
    }
    return this.state.codes_text || '';
  }

  onTypeChange = (event) => {
    this.setState({
      code_type: event.target.value,
    });
  };

  onCodesChange = (event) => {
    let codes = event.target.value.split(',');
    codes = codes.map((code) => code.trim()); // remove white space
    codes = codes.filter((code) => code.length > 0); // remove empty codes
    this.setState({
      codes_text: event.target.value,
      codes,
    });
  };

  onCancel = () => {
    this.resetState();
    this.props.onClose();
  };

  onClose = () => {
    this.resetState();
    this.props.onClose(this.getCodesType(), this.getCodes());
  };
}

FindCodesDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  codes: PropTypes.array,
  codesType: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

const styled = withStyles(styles)(FindCodesDialog);
export { styled as FindCodesDialog };
