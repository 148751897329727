import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';

import { BreadcrumbItem } from 'components';

const styles = (theme) => ({
  root: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
});

class Breadcrumb extends Component {
  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.root}>
        <BreadcrumbItem
          key='/'
          label=''
          icon='home'
          path='/'
        />
      </div>
    );
  }
}

Breadcrumb.propTypes = {
  classes: PropTypes.object.isRequired,
};

// TODO: Remove state reference and move to props
function mapStateToProps(state) {
  const { router } = state;
  return {
    location: router.location,
  };
}

const styled = withStyles(styles)(Breadcrumb);
const connected = connect(mapStateToProps)(styled);
export { connected as Breadcrumb };
