import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import TextField from '@mui/material/TextField';
// import FormControl from '@mui/material/FormControl';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormHelperText from '@mui/material/FormHelperText';
import DateTimePicker from '@mui/lab/DateTimePicker';

import withStyles from '@mui/styles/withStyles';
import { DateUtil } from 'doctivity-shared/utils';
import {
  format,
  parse,
  toDate,
  isAfter,
} from 'date-fns';

const styles = (theme) => ({
  container: {
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    '& > label': {
      transform: 'none',
      lineHeight: '120%',
      zIndex: 10,
    },
    '& > div': {
      backgroundColor: '#F8F8F8',
      width: '100%',
    },
  },
  cPathway: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& > label': {
      width: '60%',
      position: 'relative',
    },
    '& > div': {
      width: '35%',
      marginBottom: 16,
      marginTop: 16,
      backgroundColor: 'transparent',
    },
    '& > p': {
      position: 'absolute',
      right: theme.spacing.unit,
      bottom: theme.spacing.unit,
    },
  },
  cPathwayAlt: {
    backgroundColor: '#F5F5F5',
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    maxWidth: 800,
  },
  todayButton: {
    position: 'absolute',
    right: 35,
    top: 12,
    zIndex: 10,
    width: 40,
    minWidth: 40,
    maxWidth: 40,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  readOnlyContainer: {
    '& > div': {
      backgroundColor: '#F8F8F8',
      width: '100%',
      '& > div': {
        display: 'none',
      },
    },
  },
});

class DateTimeWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateError: false,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnError = this.handleOnError.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.onTodayClick = this.onTodayClick.bind(this);
  }

  onTodayClick() {
    const newDate = new Date();
    this.handleOnChange(newDate);
  }

  handleInputChange(event) {
    const value = event.target.value;
    if (value
      && value.length === DateUtil.LDML_DISPLAY_DATE_TIME_FORMAT.length
      && value.indexOf('_') === -1) {
      const newDate = parse(value, DateUtil.LDML_DISPLAY_DATE_TIME_FORMAT, new Date());
      if (newDate) {
        this.handleOnChange(newDate);
      }
    }
  }

  handleOnChange(value) {
    const {
      onChange,
      options,
    } = this.props;

    let formatted = value;
    if (formatted) {
      let formatString = DateUtil.LDML_FORM_DATETIME_FORMAT;
      if (options.csvMode) {
        formatString = DateUtil.LDML_DISPLAY_DATE_TIME_FORMAT;
      }
      formatted = format(value, formatString);
    }
    this.setState({
      dateError: false,
    });
    onChange(formatted);
  }

  handleOnError() {
    const {
      onChange,
    } = this.props;

    this.setState({
      dateError: true,
    });
    onChange('');
  }

  render() {
    const {
      schema,
      id,
      value,
      required,
      disabled,
      readonly,
      label,
      autofocus,
      rawErrors,
      options,
      classes,
    } = this.props;

    const {
      dateError,
    } = this.state;

    let description = null;
    let hasError = dateError;

    if (rawErrors != null) {
      description = rawErrors;
      hasError = true;
    } else if (schema.description && !dateError) {
      hasError = false;
      description = schema.description;
    }

    let formatString = DateUtil.LDML_FORM_DATETIME_FORMAT;
    if (options.csvMode) {
      formatString = DateUtil.LDML_DISPLAY_DATE_TIME_FORMAT;
    }

    let date = null;
    if (value && value.length > 29) {
      date = toDate(value.substring(0, 29)); // , SERVER_DATE_FORMAT
    } else if (value) {
      date = parse(value, formatString, new Date());
      if ((options.pathwayMode || options.csvMode) && Number.isNaN(date)) {
        // try straight parse, because could be in many formats
        date = toDate(value);
      }
    }
    let disableFuture = false;
    let disablePast = false;
    let minDate = new Date(1890, 1, 1);
    let maxDate = new Date(2100, 1, 1);

    if (options.birth) {
      // birth mode!
      disableFuture = true;
      disablePast = false;
    } else if (options.future) {
      disableFuture = false;
      disablePast = true;
    } else if (options.linked) {
      minDate = options.linked;
      if (minDate.length > 29) {
        minDate = toDate(minDate.substring(0, 29)); // , SERVER_DATE_FORMAT
      } else if (options.csvMode) {
        minDate = parse(minDate, DateUtil.LDML_FORM_DATETIME_FORMAT, new Date());
      } else {
        minDate = parse(minDate, formatString, new Date());
      }
      // must be after or equal linked date
      if (date && isAfter(minDate, date)) {
        if (options.csvMode) {
          hasError = true;
        } else {
          date = minDate;
        }
      }

      if (options.linkedMax) {
        maxDate = options.linkedMax;
        if (maxDate.length > 29) {
          maxDate = toDate(maxDate.substring(0, 29)); // , SERVER_DATE_FORMAT
        } else {
          maxDate = parse(maxDate, formatString, new Date());
        }

        // must be before or equal linked max date
        if (date && isAfter(date, maxDate)) {
          date = maxDate;
        }
      }
    }

    let extraTop = 16;
    let extraBottom = 8;

    let containerClsList;
    if (options.pathwayMode) {
      if (options.altRow) {
        containerClsList = `${classes.container} ${classes.cPathway} ${classes.cPathwayAlt}`;
      } else {
        containerClsList = `${classes.container}  ${classes.cPathway}`;
      }
      extraTop = 0;
      extraBottom = 0;
    } else {
      containerClsList = `${classes.container}`;
    }

    if (readonly) {
      containerClsList += ` ${classes.readOnlyContainer}`;
    }

    if (date) {
      date = date.oISOString();
    }

    const picker = (
      <DateTimePicker
        renderInput={(params) => <TextField {...params} />}
        className={containerClsList}
        id={id}
        required={required}
        error={hasError}
        clearable
        label={label}
        autoFocus={autofocus}
        disabled={disabled || readonly}
        value={date}
        onChange={this.handleOnChange}
        onError={this.handleOnError}
        // className={classes.filterInputField}
        inputFormat={DateUtil.LDML_DISPLAY_DATE_TIME_FORMAT}
        // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        // style={column.style}
        description={description}
        style={{ marginTop: extraTop, marginBottom: extraBottom }}
        keyboard
        disableFuture={disableFuture}
        disablePast={disablePast}
        minDate={minDate}
        maxDate={maxDate}
        disableOpenOnEnter
        onInputChange={this.handleInputChange}
      />
    );

    if (options.todayButton) {
      return (
        <div className={classes.wrapper}>
          <Button
            tabIndex={-1}
            size='small'
            className={classes.todayButton}
            onClick={this.onTodayClick}
          >
            <Icon>grade</Icon>
          </Button>
          {picker}
        </div>
      );
    }
    return picker;
  }
}
DateTimeWidget.defaultProps = {
  autofocus: false,
};

DateTimeWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  options: PropTypes.object,
  id: PropTypes.string.isRequired,
  value: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  rawErrors: PropTypes.array,
  label: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

const styled = withStyles(styles)(DateTimeWidget);
export { styled as DateTimeWidget };
