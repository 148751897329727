import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField';
import withStyles from '@mui/styles/withStyles';

function asNumber(value) {
  if (value === '') {
    return undefined;
  }
  if (/\.$/.test(value)) {
    // "3." can't really be considered a number even if it parses in js. The
    // user is most likely entering a float.
    return value;
  }
  if (/\.0$/.test(value)) {
    // we need to return this as a string here, to allow for input like 3.07
    return value;
  }
  const n = Number(value);
  const valid = typeof n === 'number' && !Number.isNaN(n);

  if (/\.\d*0$/.test(value)) {
    // It's a number, that's cool - but we need it as a string so it doesn't screw
    // with the user when entering dollar amounts or other values (such as those with
    // specific precision or number of significant digits)
    return value;
  }

  return valid ? n : value;
}

function processValue({ type, items }, value) {
  if (value === '') {
    return undefined;
  } if (
    type === 'array'
    && items
    && ['number', 'integer'].includes(items.type)
  ) {
    return value.map(asNumber);
  } if (type === 'boolean') {
    return value === 'true';
  } if (type === 'number') {
    return asNumber(value);
  }
  return value;
}

function getValue(event, multiple) {
  if (multiple) {
    return [].slice
      .call(event.target.options)
      .filter((o) => o.selected)
      .map((o) => o.value);
  }
  return event.target.value;
}

const styles = (theme) => ({
  container: {
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
  },
  cPathway: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    margin: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  cPathwayAlt: {
    // backgroundColor: '#F5F5F5',
  },
  input: {
    // backgroundColor: '#F8F8F8',
    minWidth: 150,
    maxWidth: 400,
  },
  inputPathway: {
    backgroundColor: 'transparent',
    width: '35%',
    marginBottom: 16,
  },
  inputLabel: {
    zIndex: 10,
    // transform: 'none',
    // lineHeight: '120%',
  },
  inputLabelPathway: {
    width: '60%',
    position: 'relative',
  },

  cPositives: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    margin: 0,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  inputPositives: {
    width: '45%',
    backgroundColor: 'transparent',
    marginBottom: 16,
  },
  inputLabelPositives: {
    width: '55%',
    position: 'relative',
  },
});

function SelectWidget(props) {
  const {
    schema,
    id,
    options,
    value,
    required,
    disabled,
    readonly,
    multiple,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    placeholder,
    rawErrors,
    classes,
  } = props;
  const { enumOptions, enumDisabled } = options;
  const emptyValue = multiple ? [] : '';

  let description;
  let hasError = false;

  if (rawErrors != null) {
    if ((value && value.length > 0)
      || required) {
      description = rawErrors;
      hasError = rawErrors != null;
    }
  } else {
    description = schema.description;
  }

  let containerClsList;
  let labelClsList;
  let inputClsList;

  if (options.pathwayMode) {
    labelClsList = `${classes.inputLabel} ${classes.inputLabelPathway}`;
    inputClsList = `${classes.input} ${classes.inputPathway}`;

    if (options.altRow) {
      containerClsList = `${classes.container} ${classes.cPathway} ${classes.cPathwayAlt}`;
    } else {
      containerClsList = `${classes.container}  ${classes.cPathway}`;
    }
  } else if (options.positivesMode) {
    labelClsList = `${classes.inputLabel} ${classes.inputLabelPositives}`;
    inputClsList = `${classes.input} ${classes.inputPositives}`;
    containerClsList = `${classes.container}  ${classes.cPositives}`;
  } else {
    labelClsList = `${classes.inputLabel}`;
    inputClsList = `${classes.input}`;
    containerClsList = `${classes.container}`;
  }

  return (
    <TextField
      className={containerClsList}
      id={id}
      select
      label={schema.title}
      error={hasError}
      required={required}
      disabled={disabled || readonly}
      autoFocus={autofocus}
      value={typeof value === 'undefined' || value == null ? emptyValue : value}
      helperText={description}
      onBlur={
        onBlur
        && ((event) => {
          const newValue = getValue(event, multiple);
          onBlur(id, processValue(schema, newValue));
        })
      }
      onFocus={
        onFocus
        && ((event) => {
          const newValue = getValue(event, multiple);
          onFocus(id, processValue(schema, newValue));
        })
      }
      onChange={(event) => {
        const newValue = getValue(event, multiple);
        onChange(processValue(schema, newValue));
      }}
      SelectProps={{
        native: true,
      }}
      margin='normal'
      InputProps={{
        className: inputClsList,
      }}
      InputLabelProps={{
        // shrink: true,
        className: labelClsList,
      }}
    >
      {!multiple && !schema.default && <option value=''>{placeholder}</option>}
      {enumOptions.map((option, i) => {
        const optionDisabled = enumDisabled && enumDisabled.indexOf(option.value) !== -1;
        return (
          <option key={i} value={option.value} disabled={optionDisabled}>
            {option.label}
          </option>
        );
      })}
    </TextField>
  );
}

SelectWidget.defaultProps = {
  autofocus: false,
};

SelectWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    enumDisabled: PropTypes.array,
    pathwayMode: PropTypes.bool,
    positivesMode: PropTypes.bool,
    altRow: PropTypes.bool,
  }).isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  readonly: PropTypes.bool,
  multiple: PropTypes.bool,
  autofocus: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  rawErrors: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

const styled = withStyles(styles)(SelectWidget);
export { styled as SelectWidget };
