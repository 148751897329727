import React from 'react';
import PropTypes from 'prop-types';

const UpgradeableContext = React.createContext({
  hasNavigated: false,
  setHasNavigated: () => {},
});

export const UpgradeableProvider = class UpgradeableProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasNavigated: false,
      lastCheck: 0,
      shouldCheck: false,
    };
  }

  setHasNavigated = (hasNavigated) => {
    const shouldCheck = new Date().getTime() > this.state.lastCheck + 15 * 60 * 1000;
    this.setState({ hasNavigated, shouldCheck });
  };

  didCheck = () => {
    this.setState({
      hasNavigated: false,
      shouldCheck: false,
      lastCheck: new Date().getTime(),
    });
  };

  render() {
    return (
      <UpgradeableContext.Provider
        value={{
          hasNavigated: this.state.hasNavigated,
          setHasNavigated: this.setHasNavigated,
          didCheck: this.didCheck,
          shouldCheck: this.state.shouldCheck,
        }}
      >
        {this.props.children}
      </UpgradeableContext.Provider>
    );
  }
};
UpgradeableProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default UpgradeableContext;
