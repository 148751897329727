import { ReducerUtil } from 'doctivity-shared/utils';

export const analytics = (state = {}, action = null) => {
  if (action.type.startsWith('ANALYTICS_')) {
    let type = action.type;
    let storeId = action.store_id;
    if (action.meta && action.meta.requestAction) {
      type = action.meta.requestAction.type;
      storeId = action.meta.requestAction.store_id;
    }
    const field = storeId || type.substring(10);
    let result;
    if (Array.isArray(action.data)) {
      result = ReducerUtil.reduceListGet(state, action, type, field);
    } else if (Number.isInteger(action.data)) {
      result = ReducerUtil.reduceQuery(state, action, type, field);
    } else {
      result = ReducerUtil.reduceTableGet(state, action, type, field);
    }
    if (result.actionConsumed) {
      return result.state;
    }
  }
  return state;
};
