import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  FormControl,
  FormLabel,
  Typography,
  Chip,
} from '@mui/material';

import {
  FormUtil,
} from 'doctivity-shared/utils';

import Dropzone from 'react-dropzone';

const styles = (theme) => ({
  container: {
    marginTop: theme.spacing.unit,
  },
  list: {
    maxHeight: 400,
    width: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    marginBottom: theme.spacing.unit * 4,
  },
  activeDropzone: {
    borderColor: '#2196f3',
  },
  acceptDropzone: {
    borderColor: '#00e676',
  },
  rejectDropzone: {
    borderColor: '#ff1744',
  },
  dropzoneText: {
    color: '#bdbdbd',
  },
  dropzoneAcceptedText: {
    color: 'inherit',
    paddingBottom: theme.spacing.unit,
  },
  dropzoneRejectedText: {
    color: theme.palette.error.main,
    paddingBottom: theme.spacing.unit,
  },
  fileChip: {
    margin: theme.spacing.unit,
  },
});

class FileWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      rejectedFiles: [],
    };

    this.onLoadFile = this.onLoadFile.bind(this);
    this.onDropAccepted = this.onDropAccepted.bind(this);
    this.onDropRejected = this.onDropRejected.bind(this);
    this.onFileDelete = this.onFileDelete.bind(this);
    this.onRejectedFileDelete = this.onRejectedFileDelete.bind(this);
  }

  onLoadFile(event) {
    const {
      files,
    } = this.state;

    const fileList = event.dataTransfer ? event.dataTransfer.files : event.target.files;

    for (let i = 0; i < fileList.length; i++) {
      const file = fileList.item(i);
      files.push(file);
    }

    return files;
  }

  onFileDelete(file) {
    const {
      files,
    } = this.state;

    this.setState({
      files: files.filter((item) => (item.name !== file.name)),
    });
  }

  onRejectedFileDelete(file) {
    const {
      rejectedFiles,
    } = this.state;

    this.setState({
      rejectedFiles: rejectedFiles.filter((item) => (item.name !== file.name)),
    });
  }

  onDropAccepted(acceptedFiles) {
    const {
      value,
      onChange,
    } = this.props;

    const {
      files,
    } = this.state;

    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        let name = file.name;
        if (!name) {
          name = file.path;
        }
        const dataUri = this.addNameToDataURI(reader.result, name);
        value.push(dataUri);

        onChange(value);
      };

      reader.readAsDataURL(file);
    });

    this.setState({
      files: files.concat(acceptedFiles),
    });
  }

  onDropRejected(files) {
    const {
      rejectedFiles,
    } = this.state;

    this.setState({
      rejectedFiles: rejectedFiles.concat(files),
    });
  }

  addNameToDataURI(dataURL, name) {
    return dataURL.replace(';base64', `;name=${encodeURIComponent(name)};base64`);
  }

  render() {
    const {
      rawErrors,
      required,
      schema,
      classes,
      options,
    } = this.props;

    const {
      files,
      rejectedFiles,
    } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.header}>
          <FormLabel component='legend'>{schema.title}</FormLabel>
        </div>
        <FormControl
          className={classes.list}
          required={required}
          error={rawErrors != null}
        >
          <Dropzone
            onDropAccepted={this.onDropAccepted}
            onDropRejected={this.onDropRejected}
            accept={options.accept}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps({ className: classes.dropzone })}>
                  <input {...getInputProps()} />
                  {files.map((file) => (
                    <Chip
                      label={`${file.name} (${FormUtil.ByteSizeToString(file.size)})`}
                      onDelete={() => (this.onFileDelete(file))}
                      key={file.name}
                      className={classes.fileChip}
                      variant='outlined'
                    />
                  ))}
                  {rejectedFiles.map((file) => (
                    <Chip
                      label={`Unable to upload ${file.name}`}
                      onDelete={() => (this.onRejectedFileDelete(file))}
                      key={file.name}
                      className={classes.fileChip}
                      variant='outlined'
                      color='secondary'
                    />
                  ))}
                  <Typography variant='body2' className={classes.dropzoneText}>
                    Drop files here or click to browse files
                  </Typography>
                  {options.acceptDescription
                    && (
                      <Typography variant='body2' className={classes.dropzoneText} key='acceptDescription'>
                        {options.acceptDescription}
                      </Typography>
                    )}
                </div>
              </section>
            )}
          </Dropzone>
        </FormControl>
      </div>
    );
  }
}

FileWidget.defaultProps = {
  options: {
    inline: false,
  },
};

FileWidget.propTypes = {
  schema: PropTypes.object.isRequired,
  options: PropTypes.shape({
    enumOptions: PropTypes.array,
    inline: PropTypes.bool,
    acceptDescription: PropTypes.string,
    accept: PropTypes.bool,
  }),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  rawErrors: PropTypes.array,
  classes: PropTypes.object,
};

const styled = withStyles(styles)(FileWidget);
export { styled as FileWidget };
