import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { 
  Button,
  Icon,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Tooltip,
  Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  upsertOrganizationTag,
  deleteOrganizationTag,
  queryOrganizations,
} from 'store/actions/organizationTagsActions';
import {
  TitleTooltip,
  SmallIconTextButton,
  DynamicTable,
} from 'components';

import { withRouter } from 'utils';
import { StoreUtil } from 'doctivity-shared/utils';

import { FindOrganizationDialog } from 'organizations';

const styles = (theme) => ({
  card: {},
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  spacer: {
    flex: 1,
  },
  commentHeader: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    borderBottom: '1px solid #cccccc',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'normal',
    },
  },
  commentUser: {
    fontWeight: 800,
    fontSize: 12,
    width: 200,
  },
  needsResolved: {
    fontWeight: 800,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    color: '#2E93fA',
    paddingTop: 2,
  },
  needsResolvedIcon: {
    fontSize: 16,
    paddingRight: 3,
  },
  commentBody: {
    display: 'flex',
  },
  commentSpacing: {
    height: 20,
  },
  commentBodyText: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    whiteSpace: 'pre-wrap',
  },
  commentDate: {
    fontSize: 12,
    color: '#999999',
    width: 110,
    textAlign: 'right',
    [theme.breakpoints.down('md')]: {
      textAlign: 'left',
    },
  },
  encounterType: {
    fontSize: 12,
    color: '#999999',
    width: 125,
  },
  encounterIcon: {
    fontSize: 16,
    paddingRight: 3,
    position: 'relative',
    top: 3,
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 2,
  },
  actionButton: {
    height: 22,
  },
  tags: {
    display: 'flex',
    flexDirection: 'row',
  },
  namespace: {
    fontSize: 12,
    color: '#999999',
    paddingRight: theme.spacing(2),
  },
  regRow: {
    '& .manageIcons': {
      visibility: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiButton-root': {
        color: theme.palette.tertiary.accent,
        padding: 0,
      },
    },
    '&:hover': {
      '& .manageIcons': {
        visibility: 'visible',
      },
    },
  },
  altRow: {
    '& .manageIcons': {
      visibility: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiButton-root': {
        color: theme.palette.tertiary.accent,
        padding: 0,
      },
    },
    '&:hover': {
      '& .manageIcons': {
        visibility: 'visible',
      },
    },
  },
  deleteOrganizationDialog: {
    fontFamily: 'Roboto',
    fontSize: '18px',
  },
  deleteOrganizationActions: {
    padding: theme.spacing(2),
  },
  deleteOrganizationContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(1),
    textAlign: 'center',
    fontSize: '22px',
    padding: theme.spacing(2),
    fontWeight: '300',
    '& h2': {
      color: theme.palette.primary.main,
      fontSize: '30px',
      fontWeight: '400',
    },
    '& span': {
      padding: theme.spacing(2),
    }
  },
  descriptorText: {
      fontSize: '18px',
      fontWeight: '300',
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
});

const columns = (self) => ([
    {
      label: '',
      key: 'affiliated',
      style: { width: 14 },
      sortable: false,
      showFilter: false,
      format: (affiliated) => {
        if (affiliated?.length > 0) {
          return (
            <Tooltip
              placement='bottom-start'
              title={
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 200,
                  }}
                >
                  Affiliated Organization
                </Typography>
              }
            >
              <div
                style={{
                  width: 14,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon className={self.props.classes.affiliatedIcon}>hub</Icon>
              </div>
            </Tooltip>
          );
        }
        return <></>;
      },
    },
    {
      label: 'Name',
      key: 'name',
      fulltext: true,
    },
    {
      label: 'Type',
      key: 'facility_type',
    },
    {
      label: 'NPI',
      key: 'npi',
      style: { minWidth: 140, verticalAlign: 'top' },
    },
    {
      label: 'City',
      key: 'primary_location.city',
      fulltext: true,
    },
    {
      label: 'State',
      key: 'primary_location.state',
      style: { width: 50 },
      filterExact: true,
      autoCapitalize: true,
    },
    {
      label: '',
      key: 'tags',
      style: { width: 50 },
      sortable: false,
      showFilter: false,
      format: (_clients, row) => {
        return (
          <span className='manageIcons' key={`manageRow-${row.id}`}>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                self.onRemoveOrganization(row);
                return false;
              }}
            >
              <Icon>cancel</Icon>
            </Button>
          </span>
        );
      },
    },
  ]);
 
class OrganizationsByTag extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRows: [], 
      query: {
        order: [['name', 'ASC']],
        include: [
          {
            attributes: ['id', 'city', 'state'],
            association: 'primary_location',
          },
          {
            attributes: ['id'],
            association: 'affiliated',
            required: false,
            through: { attributes: [] }, // don't need the join table data
            where: {
              id: props.clientId,
            },
          },
          {
            association: 'tags',
            attributes: [],
            duplicating: false,
            through: { attributes: [] },
            required: true,
            where: {
              id: props.tag?.id,
            },
          },
        ],
        limit: 25,
        offset: 0,
      }
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.tag?.id !== prevProps.tag?.id
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch } = this.props;
    const { query } = this.state;
    const tagsInclude = query.include.find((i) => i.association === 'tags');
    tagsInclude.where = { id: this.props.tag?.id };

    const clientsInclude = query.include.find((i) => i.association === 'affiliated');
    clientsInclude.where = { id: this.props.clientId };
    dispatch(queryOrganizations(query));
  }

  render() {
    const { dialogOpen, selectedRows, deleteOrganization, query } = this.state;
    const { 
      tag: currentTag,
      classes,
      organizations,
    } = this.props;

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <TitleTooltip
              title='Organizations'
            />
            <div className={classes.spacer} />
            <SmallIconTextButton
              icon='add'
              text='Add'
              onClick={this.openDialog}
            />
          </div>
          <DynamicTable
            columns={columns(this)}
            data={organizations}
            ContainerType={React.Fragment}
            query={query}
            onQueryChange={this.onQueryChange}
            onRowClick={this.onOrganizationRowClick}
            getRowHref={(row) => `/organizations/${row.id}`}
            classes={{
              regRow: classes.regRow,
              altRow: classes.altRow,
            }}
          />
          {dialogOpen && (
            <FindOrganizationDialog
              clientId={this.props.clientId}
              open={dialogOpen}
              onSelect={this.onOrganizationSelected}
              onMultiSelect={this.onOrganizationSelected}
              onSaveMultiSelect={this.onSaveMultiSelect}
              selectedRows={selectedRows}
            />
          )} 
          {deleteOrganization && (
          <Dialog className={classes.deleteOrganizationDialog} open={true}>
            <DialogContent className={classes.deleteOrganizationContent}>
              <h2>Remove Organization</h2>
              <Divider />
              <span>
                Are you sure you want to remove {deleteOrganization.name}&nbsp;from&nbsp;
                {currentTag?.name}?
              </span>
              <span className={classes.descriptorText}>
                This will immediately delete the organization from the label type.
                This action cannot be undone.
              </span>
            </DialogContent>
            <DialogActions className={classes.deleteOrganizationActions}>
              <Button
                onClick={() => {
                  this.setState({ deleteOrganization: undefined });
                }}
              >
                No, Cancel
              </Button>
              <Button
                variant='contained'
                onClick={() => {
                  this.removeOrganization(deleteOrganization, currentTag);
                  this.setState({ deleteOrganization: undefined });
                }}
              >
                Yes, Remove Organization 
              </Button>
            </DialogActions>
          </Dialog>
        )} 


        </CardContent>
      </Card>
    );
  }
  
  removeOrganization = (org, tag) => {
    if (this.props.dispatch) {
      this.props.dispatch(
        deleteOrganizationTag(
          [
            {
              organization_id: org.id,
              tag_id: tag.id,
            },
          ],
          org.id,
          queryOrganizations(this.state.query),
        ),
      );
    }
  };

  onRemoveOrganization = (org) => {
    this.setState({ deleteOrganization: org });
  }

  onSaveMultiSelect = () => {
    const rows = this.state.selectedRows;
    if (!rows || !Array.isArray(rows) || rows.length < 1) {
      this.setState({ dialogOpen: false, selectedRows: [] });
      return;
    }
    const orgIds = new Set(rows.map((t) => t.id));
    const currentIds = new Set(
      this.props.organizations.data?.rows?.map((r) => r.organization_id),
    );
    currentIds.forEach((id) => orgIds.delete(id));
    const {
      dispatch,
      tag: { id },
    } = this.props;
    if (orgIds.size > 0) {
      dispatch(
        upsertOrganizationTag(
          [...orgIds].map((p) => ({ tag_id: id, organization_id: p })),
          queryOrganizations(this.state.query),
        ),
      );
    }
    this.fetchData();
    this.setState({ dialogOpen: false, selectedRows: [] });
  }

  openDialog = () => this.setState({ dialogOpen: true });

  onQueryChange = (query) => {
    if (query.order) {
      let idIdx = -1;
      query.order.forEach((condition, idx) => {
        if (condition[0] === 'id') {
          idIdx = idx;
        }
      });
      if (idIdx > -1) {
        query.order.splice(idIdx, 1);
      }
    }
    this.setState({ query }, this.fetchData.bind(this));
  };

  onOrganizationSelected = (rows) => {
    if (rows === null) {
      this.setState({ selectedRows: [], dialogOpen: false });
      return;
    }
    this.setState({ selectedRows: [...rows] });
  }

  onRowClick = (row) => {
    this.props.router.navigate(`/organizations/${row.id}`);
  };
}

OrganizationsByTag.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  user: PropTypes.object,
  tag: PropTypes.object.isRequired,
  organizations: PropTypes.object,
};

function mapStateToProps(state) {
  const { user, app } = state;

  return {
    user,
    organizations: StoreUtil.get(state.organizationTags, 'organizations'),
    clientId: app.selectedClient
  };
}

const styled = withStyles(styles)(OrganizationsByTag);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as OrganizationsByTag };
