export const API_CONTACTS_TABLE = 'API_CONTACTS_TABLE';
export const API_CONTACTS_LIST = 'API_CONTACTS_LIST';
export const API_CONTACTS_UPSERT = 'API_CONTACTS_UPSERT';
export const API_CONTACTS_DELETE = 'API_CONTACTS_DELETE';
export const API_CONTACTS_LOAD = 'API_CONTACTS_LOAD';
export const API_CONTACTS_RELATIONS_LIST = 'API_CONTACTS_RELATIONS_LIST';

export const queryContacts = (options, type = API_CONTACTS_TABLE) => ({
  type,
  request: {
    url: '/Contacts',
    method: 'GET',
    params: { opts: options },
  },
});

export const listContacts = (clientId) => (queryContacts({
  where: {
    client_id: clientId,
  },
  order: [
    ['name', 'ASC'],
  ],
}, API_CONTACTS_LIST));

export const loadContact = (id, clientId) => ({
  type: API_CONTACTS_LOAD,
  id,
  request: {
    url: `/Contacts/${id}`,
    method: 'GET',
    params: {
      opts: {
        where: {
          client_id: clientId,
        },
        include: [
          {
            association: 'location',
            include: [
              {
                association: 'organization',
                attributes: ['id', 'name'],
              },
            ],
          },
          {
            association: 'owner',
            attributes: ['id', 'first_name', 'last_name'],
          },
        ],
      },
    },
  },
});

export const upsertContact = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  if (data.organizations) {
    if (!options) options = {};
    options.include = [
      { model: 'Organizations', as: 'organizations' },
    ];
  }

  return {
    type: API_CONTACTS_UPSERT,
    id,
    request: {
      url: '/Contacts',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};

export const deleteContact = (id) => {
  return {
    type: API_CONTACTS_DELETE,
    id,
    request: {
      url: '/Contacts',
      method: 'DELETE',
      data: [id],
    },
  };
};

