const ENCOUNTER_TYPES = [
  {
    id: 5,
    name: 'Office Visit (Provider/Staff)',
    icon: 'emoji_transportation',
  },
  { id: 4, name: 'Provider to Provider', icon: 'local_hospital' },
  { id: 1, name: 'Email', icon: 'email' },
  { id: 2, name: 'Call', icon: 'phone' },
  { id: 3, name: 'Text', icon: 'sms' },
  { id: 6, name: 'Virtual Meeting', icon: 'video_chat' },
  { id: 7, name: 'Event', icon: 'today' },
];

let BY_ID = null;
let IDS = null;
let NAMES = null;
class EncounterTypeUtil {
  static getAll() {
    return ENCOUNTER_TYPES;
  }

  static getAllWithUnspecified() {
    return ENCOUNTER_TYPES.concat([{ id: null, name: 'Unspecified' }]);
  }

  static getNames() {
    if (!NAMES) {
      NAMES = ENCOUNTER_TYPES.map((t) => t.name);
    }
    return NAMES;
  }

  static getIds() {
    if (!IDS) {
      IDS = ENCOUNTER_TYPES.map((t) => t.id);
    }
    return IDS;
  }

  static getType(id) {
    if (!BY_ID) {
      BY_ID = {};
      ENCOUNTER_TYPES.forEach((t) => (BY_ID[t.id] = t));
    }
    return BY_ID[id];
  }

  static getByName(name) {
    return ENCOUNTER_TYPES.find((t) => t.name === name);
  }

  static getName(id) {
    const t = EncounterTypeUtil.getType(id);
    if (t) {
      return t.name;
    }
    return '';
  }

  static getIcon(id) {
    const t = EncounterTypeUtil.getType(id);
    if (t) {
      return t.icon;
    }
    return '';
  }
}

module.exports = { EncounterTypeUtil };
// export { EncounterTypeUtil };
// export default EncounterTypeUtil;
