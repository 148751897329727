import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { ButtonBase, Icon, Tooltip, Typography } from '@mui/material';
import {
  StoreUtil,
  PatientType,
  PatientTypes,
  LocationTypeUtil,
} from 'doctivity-shared/utils';
import { LoadingView } from 'components';

const styles = (theme) => ({
  providerRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: 24,
    marginBottom: 4,
    color: '#2E93fA',
    fontSize: 12,
    fontWeight: 300,
    position: 'relative',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  providerBar: {
    position: 'absolute',
    backgroundColor: '#2E93fA',
    transitionProperty: 'width',
    transitionDelay: '1s',
    transitionDuration: '1s',
    width: 0,
    height: 24,
    top: 0,
    left: 0,
    borderRadius: 2,
    zIndex: 1,
    overflow: 'hidden',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  providerName: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: 2,
    gap: 8,
    zIndex: 0,
  },
  providerNameIcon: {
    fontSize: 10,
    paddingLeft: 6,
  },
  providerCount: {
    position: 'absolute',
    top: 7,
    left: 200,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 0,
  },
  providerNameBar: {
    paddingTop: 6,
    zIndex: 2,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  providerCountBar: {
    position: 'absolute',
    top: 7,
    left: 200,
    fontSize: 11,
    fontWeight: 300,
    zIndex: 3,
    overflow: 'hidden',
  },
  infoTip: {
    fontSize: 13,
    fontWeight: 200,
  },
  colorKeyContainer: {
    minHeight: 40,
    gap: theme.spacing(1),
  },
  colorKey: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: 12,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
  colorKeyIcon: {
    width: 14,
    height: 14,
    marginRight: 2,
    borderRadius: 2,
  },
  colorKeyText: {
    marginRight: 12,
    whiteSpace: 'nowrap',
  },
  aggregatePercentage: {
    marginLeft: theme.spacing(1),
  },
  colorPagination: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3),
    }
  }

});

function ProviderProjectsPatientsChart({ classes, patientType, legend }) {
  const providers = useSelector((state) =>
    StoreUtil.get(state.analytics, 'CLAIMS_PER_PROVIDER'),
  );
  const providerCounts = useSelector((state) =>
    StoreUtil.get(state.analytics, 'CLAIMS_PER_PROVIDER_COUNTS'),
  );
  const [claimRows, setClaimRows] = useState([]);
  const [maxValue, setMaxValue] = useState(100);
  let isLoading = false;

  const generateClaimsCountBar = useCallback(
    (claims, index) => {
      let patientTypeLabel = 'patients';
      if (patientType === PatientType.CAPTURED_CLAIMS) {
        patientTypeLabel = 'claims';
      }

      return (
        <>
          <span>
            {` ${
              claims.total ? claims.total.toLocaleString() : '<11'
            } ${patientTypeLabel}`}
          </span>
          {claims.aggregateClaimsPercentage !== null &&
          claims.aggregateClaimsPercentage !== undefined ? (
            <span className={classes.aggregatePercentage}>
              {` ~${(claims.aggregateClaimsPercentage * 100).toLocaleString()}% ${
                index === 0 ? 'of Selected Market' : ''
              }`}
            </span>
          ) : (
            <></>
          )}
        </>
      );
    },
    [classes, patientType],
  );
  useEffect(() => {
    let max = 100;
    let rows = [];
    let totalMarket = 0;
    let affiliatedPercentage = -1;
    if (!providers || !StoreUtil.isLoaded(providers)) {
      isLoading = true;
    } else {
      rows = providers.data.rows;
      if (providerCounts?.data && providerCounts.data.total) {
        totalMarket = providerCounts.data.total;
        affiliatedPercentage = (
          (providerCounts.data.total_affiliated / providerCounts.data.total) *
          100
        ).toFixed(2);
      }
    }

    if (affiliatedPercentage > -1) {
      affiliatedPercentage = Math.round(affiliatedPercentage * 100) / 100;
    }

    rows.forEach((claims) => {
      if (claims.total === null) {
        claims.total = 0;
      } else {
        claims.total = parseInt(claims.total, 10);
        if (totalMarket) {
          claims.aggregateClaimsPercentage = claims.total / totalMarket;
        }
        if (claims.provider.clients && claims.provider.clients.length > 0) {
          claims.affiliated = true;
        }
        max = Math.max(max, claims.total);
      }
      if (
        claims.organization &&
        claims.organization.name &&
        claims.organization.name.length > 50
      ) {
        claims.organization.name = `${claims.organization.name.substring(
          0,
          50,
        )}...`;
      }
    });
    setClaimRows(rows)
    setMaxValue(max)
  }, [StoreUtil.isLoaded(providers), providers?.data?.rows, providerCounts?.data?.rows]);
  if (isLoading) return <LoadingView />;

  return (
    <>
      {(legend === undefined || legend) && patientType !== PatientType.CAPTURED_CLAIMS &&
 (
        <div className={classes.colorPagination}>
          <div className={`${classes.colorKey} ${classes.colorKeyContainer}`}>
            {LocationTypeUtil.getAll()
                .filter((type) => !type.codesOnly && type.id !== 0)
                .map((type) => {
                  return (
                    <div className={classes.colorKey} key={type.id}>
                      <div
                        className={classes.colorKeyIcon}
                        style={{
                          backgroundColor: type.color,
                        }}
                      />
                      <div className={classes.colorKeyText}>{type.name}</div>
                    </div>
                  );
                })}
          </div>
        </div>
      )}
      {claimRows.map((claims, index) => (
        <ButtonBase
          key={`claims:${claims.provider_id}:${claims.location_type}`}
          className={classes.providerRow}
          style={{
            color: LocationTypeUtil.getColor(claims.location_type),
          }}
          href={`/providers/${claims.provider_id}/market`}
        >
          <div
            className={classes.providerBar}
            style={{
              width: `${
                (claims.total && claims.total > 0
                  ? claims.total / maxValue
                  : 10 / maxValue) * 100
              }%`,
              backgroundColor: LocationTypeUtil.getColor(claims.location_type),
            }}
          >
            <div className={classes.providerNameBar}>
              {claims.affiliated ? (
                <Tooltip
                  placement='bottom-start'
                  title={
                    <Typography className={classes.infoTip}>
                      Affiliated Provider
                    </Typography>
                  }
                >
                  <Icon className={classes.providerNameIcon}>hub</Icon>
                </Tooltip>
              ) : (
                <div style={{ minWidth: '16px' }}></div>
              )}
              {`${claims.provider.last_name}, ${claims.provider.first_name}`}
            </div>
            <div className={classes.providerCountBar}>
              {generateClaimsCountBar(claims, index)}
            </div>
          </div>
          <div className={classes.providerName}>
            {claims.affiliated ? (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography className={classes.infoTip}>
                    Affiliated Provider
                  </Typography>
                }
              >
                <Icon className={classes.providerNameIcon}>hub</Icon>
              </Tooltip>
            ) : (
              <div style={{ minWidth: '16px' }}></div>
            )}
            <div>{`${claims.provider.last_name}, ${claims.provider.first_name}`}</div>
          </div>
          <div className={classes.providerCount}>
            {generateClaimsCountBar(claims, index)}
          </div>
        </ButtonBase>
      ))}
    </>
  );
}
ProviderProjectsPatientsChart.propTypes = {
  classes: PropTypes.object,
  patientType: PropTypes.oneOf(PatientTypes).isRequired,
  legend: PropTypes.bool,
};
const Styled = withStyles(styles)(ProviderProjectsPatientsChart);
export { Styled as ProviderProjectedPatientsChart };
