import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Button,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  FormControl,
  TextField,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import {
  LoadingView,
} from 'components';

const styles = (theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  formItem: {
    marginTop: theme.spacing(2),
  },
  columns: {
    fontSize: 11,
    fontWeight: 300,
  },
  dropzone: {
    border: '1px solid #aaaaaa',
    borderRadius: 5,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  browseButton: {
    marginLeft: 20,
  },
});

class DocumentDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      name: null,
      description: null,
    };
  }

  resetState() {
    this.setState({
      selectedFile: null,
      name: null,
      description: null,
    });
  }

  render() {
    const {
      classes,
      open,
      uploadState,
      editing,
    } = this.props;

    const {
      selectedFile,
      name,
      description,
    } = this.state;

    let fileName = name;
    if (fileName == null) {
      if (selectedFile) {
        fileName = selectedFile.name;
      } else if (editing) {
        fileName = editing.name;
      }
    }

    return (
      <Dialog
        open={open}
        onClose={this.onClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='sm'
        TransitionProps={{
          onExited: this.handleUploadExited,
        }}
      >
        <DialogTitle>
          { editing ? 'Edit Document' : 'Upload Document' }
        </DialogTitle>
        <DialogContent>
          <form className={classes.form} noValidate autoComplete='off'>
            { editing
              ? (
                <div className={classes.dropzone}>
                  <Typography color='primary'>
                    { editing.original_filename }
                  </Typography>
                </div>
              )
              : (
                <Dropzone onDrop={this.selectFile} multiple={false}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      {
                        uploadState === 'EDIT'
                        && (
                          <div {...getRootProps()} className={classes.dropzone}>
                            <input {...getInputProps()} />
                            {
                              !selectedFile
                              && (
                                <Typography style={{ cursor: 'pointer' }}>
                                  Drag and drop the file here or
                                  <Button variant='outlined' className={classes.browseButton}>Browse Files</Button>
                                </Typography>
                              )
                            }
                            {
                              selectedFile
                              && (
                                <Typography color='primary' style={{ cursor: 'pointer' }}>
                                  {selectedFile.name}
                                </Typography>
                              )
                            }
                          </div>
                        )
                      }
                      {
                        (uploadState === 'LOADING')
                        && <LoadingView label={`${this.props.uploadProgress}% Uploaded`} />
                      }
                      {
                        uploadState === 'SUCCESS'
                        && (
                          <Typography>
                            Your file was successfully uploaded.
                            It could take a few more minutes before it is available to everyone.
                          </Typography>
                        )
                      }
                      {
                        uploadState === 'ERROR'
                        && (
                          <Typography>
                            There was a problem uploading your file. Please try again.
                          </Typography>
                        )
                      }
                    </section>
                  )}
                </Dropzone>
              )}
            {
              uploadState === 'EDIT'
              && (
                <>
                  <FormControl key='name' className={classes.formItem}>
                    <TextField
                      label='Name'
                      variant='outlined'
                      value={fileName || ''}
                      inputProps={{ maxLength: 50 }}
                      onChange={(e) => { this.setState({ name: e.target.value }); }}
                    />
                  </FormControl>
                  <FormControl key='description' className={classes.formItem}>
                    <TextField
                      label='Description'
                      multiline
                      rows={3}
                      variant='outlined'
                      inputProps={{ maxLength: 510 }}
                      value={description || (editing && editing.description) || ''}
                      onChange={(e) => { this.setState({ description: e.target.value }); }}
                    />
                  </FormControl>
                </>
              )
            }
          </form>
        </DialogContent>
        <DialogActions>
          {uploadState === 'EDIT'
            && (
              <>
                <Button onClick={this.onClose} color='primary'>
                  Cancel
                </Button>
                <Button variant='contained' onClick={this.startUpload} color='primary' disabled={!this.state.selectedFile && !editing}>
                  { editing ? 'Save' : 'Upload' }
                </Button>
              </>
            )}
          {
            uploadState === 'SUCCESS'
            && (
              <Button onClick={this.onClose} color='primary'>
                Close
              </Button>
            )
          }
          {
            uploadState === 'ERROR'
            && (
              <Button onClick={this.onClose} color='primary'>
                Close
              </Button>
            )
          }
        </DialogActions>
      </Dialog>
    );
  }

  handleUploadExited = () => {
    this.setState({
      selectedFile: null,
    });
    this.props.onExited();
  };

  selectFile = (files) => {
    this.setState({
      selectedFile: files[0],
    });
  };

  startUpload = () => {
    if (this.state.selectedFile) {
      this.props.onUpload({
        name: this.state.name,
        description: this.state.description,
        file: this.state.selectedFile,
        client_id: this.props.clientId,
      });
    } else if (this.props.editing) {
      this.props.onUpload({
        id: this.props.editing.id,
        name: this.state.name || this.props.editing.name,
        description: this.state.description || this.props.editing.description,
      });
    }
    this.resetState();
  };

  onClose = () => {
    this.resetState();
    this.props.onClose();
  };
}

DocumentDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  editing: PropTypes.object,
  uploadState: PropTypes.string.isRequired,
  uploadProgress: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const styled = withStyles(styles)(DocumentDialog);
export { styled as DocumentDialog };
