import { ObjectUtil } from 'utils';

class QueryParamUtil {
  static paramToArray(param) {
    if (param) {
      return param.split(',');
    }
  }

  static setQueryParamsFromProps(props, queryParamsEnum) {
    if (props.router) {
      const allParams = Object.values(queryParamsEnum);
      allParams.forEach((param) => {
        const value = ObjectUtil.getPropertyByString(props, param.accessor);
        if (Array.isArray(value)) {
          props.router.query.set(
            param.name,
            value.join(',')
          );
        } else if (value) {
          props.router.query.set(
            param.name,
            value.toLowerCase ? value.toLowerCase() : value
          );
        } else if (value === false || value === 0) {
          props.router.query.set(
            param.name,
            value.toLowerCase ? value.toLowerCase() : value
          );
        } else {
          props.router.query.delete(param.name);
        }
      });

      props.router.setQuery(props.router.query, { replace: true });
    }
  }
}

export { QueryParamUtil };
export default QueryParamUtil;
