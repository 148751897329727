import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

const styles = () => ({
  inputContainer: {
    width: '80%',
    maxWidth: 800,
    display: 'flex',
    boxSizing: 'border-box',
    flexDirection: 'column',
    position: 'relative',
  },
  inputLabel: {
    transform: 'none',
    lineHeight: '120%',
    zIndex: 10,
    position: 'relative',
  },
});

function TitleField(props) {
  const {
    id, title, required, classes, rawErrors,
  } = props;
  const legend = title;
  const style = {
    width: '100%',
  };

  const hasError = rawErrors != null;
  if (id.includes('root__')) {
    return <Typography style={style} variant='h6' color='primary' component='legend' id={id}>{legend}</Typography>;
  }
  return (
    <FormControl
      className={classes.inputContainer}
      required={required}
      error={hasError}
    >
      <InputLabel
        error={hasError}
        className={classes.inputLabel}
        shrink
        disableAnimation
        required={required}
      >
        {legend}
      </InputLabel>
    </FormControl>
  );
}

TitleField.propTypes = {
  id: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  required: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  rawErrors: PropTypes.array,
};

const styledPage = withStyles(styles)(TitleField);
export { styledPage as TitleField };
