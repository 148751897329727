import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
/* import {
  routerMiddleware,
} from 'connected-react-router'; */
// import { createLogger } from 'redux-logger';
import rootReducer from 'store/reducers/RootReducer';
import rootSaga from 'store/sagas/RootSaga';
import axiosInstance from 'utils/axiosUtil';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();

const clientAwareTransform = createTransform(
  (inboundState) => {
    return inboundState;
  },
  (outboundState, key) => {
    if (key !== 'app') return outboundState;
    let client = getQueryParam('client');
    if (client != '' && client != null) {
      client = parseInt(client, 10);
    } else {
      client = null;
    }
    return {
      ...outboundState,
      selectedClient: client || outboundState.selectedClient,
    };
  },
);

// see reducers/userReducer.js for more details on nested blacklist
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'app'],
  transforms: [clientAwareTransform],
};

const connectedHistoryReducer = rootReducer(history);
const persistedReducer = persistReducer(persistConfig, connectedHistoryReducer);
const sagaMiddleware = createSagaMiddleware();

// const attachPathNameToActionMiddleware = (store) => (next) => (action) => {
//   if (store.getState().router && store.getState().router.location) {
//     action.pathname = store.getState().router.location.pathname;
//   }

//   next(action);
// };
function getQueryParam(param) {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
}
export default function ConfigureStore(preloadedState) {
  const middleware = [
    // routerMiddleware(history),
    sagaMiddleware,
    // attachPathNameToActionMiddleware,
  ];

  // if (process.env.NODE_ENV !== 'production') {
  //   const loggerMiddleware = createLogger();
  //   middleware.push(loggerMiddleware);
  // }
  /* eslint-disable no-underscore-dangle */
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    persistedReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middleware)),
  );
  /* eslint-enable */

  sagaMiddleware.run(rootSaga, axiosInstance);

  const persistor = persistStore(store);
  return { store, persistor, history };
}
