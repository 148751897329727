export const API_ACTIVITY_TABLE = 'API_ACTIVITY_TABLE';

export const queryCodeActivity = (
  isProvider,
  isDiagnosis,
  id,
  store_id,
  options,
  type = API_ACTIVITY_TABLE
) => {
  if (!options.where) {
    options.where = {};
  }
  if (isProvider) {
    options.where.provider_id = id;
  } else {
    options.where.organization_id = id;
  }
  return {
    type,
    oid: id,
    store_id,
    request: {
      url: `/${isProvider ? 'Provider' : 'Organization'}${
        isDiagnosis ? 'Dx' : 'Px'
      }CodeCounts`,
      method: 'GET',
      params: { opts: options },
    },
  };
};