import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_PROVIDERS_TABLE,
  API_PROVIDERS_LIST,
  API_PROVIDERS_UPSERT,
  API_PROVIDERS_LOAD,
} from 'store/actions/providersActions';

export const providers = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_PROVIDERS_TABLE,
    API_PROVIDERS_LIST,
    API_PROVIDERS_UPSERT,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceSingleGet(state, action, API_PROVIDERS_LOAD);
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
