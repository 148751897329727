import React from 'react';
import Icon from '@mui/material/Icon';

class TableUtil {
  static boolToCheckIcon(boolVal) {
    if (typeof boolVal === 'string') {
      if (boolVal === 'false' || boolVal === '0') {
        boolVal = false;
      }
    }
    if (boolVal) {
      return (<Icon sx={{ fontSize: 12 }}>check</Icon>);
    }
    return '';
  }
}
export { TableUtil };
export default TableUtil;
