import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';

import { Typography, Tabs, Tab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import { DateUtil, StoreUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import {
  ProviderCommentsPage,
  ProviderCommentsReportPage,
  ProviderCommentDetailPage,
} from 'containers/pages';
import {
  getProviderComment,
  upsertProviderComment,
} from 'store/actions/providerCommentsActions';
import { showConfirmationDialog } from 'store/actions/systemActions';
import { ContainedSmallIconButton, ProviderCommentsDialog } from 'components';

const styles = (theme) => ({
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    backgroundColor: theme.palette.primary.accent,
  },
  headerContainer: {
    marginBottom: theme.spacing(3),
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingTop: 3,
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.primary.accent,
  },
  indicator: {
    backgroundColor: '#FFFFFF',
    height: 2,
    marginBottom: 8,
  },
  tabPanel: {
    color: '#E0E0E0',
    '&.Mui-selected': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
});

class ProviderCommentsPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editDialogOpen: false,
    };
  }

  componentDidMount() {
    const { dispatch, router } = this.props;
    const id = router.params.id;

    if (id) {
      dispatch(getProviderComment(id));
    }
  }

  componentDidUpdate(prevProps) {
    const { app, dispatch, router } = this.props;
    const id = router.params.id;

    if (id && (app.selectedClient !== prevProps.app.selectedClient || id !== prevProps.router.params.id)) {
      dispatch(getProviderComment(id));
    }
  }

  render() {
    const { classes, app, comment } = this.props;

    const currentTab = this.props.tab || 'comments';

    return (
      <>
        <Helmet defer={false}>
          <title>
            {comment
              ? `Activity Note - ${DateUtil.formatDateTimeFromDB(
                  comment.comment_date
                )}`
              : 'Activity Notes'}
          </title>
        </Helmet>
        <div className={classes.headerContainer}>
          <div className={classes.header}>
            <Typography className={classes.headerTitle}>
              Activity Notes
            </Typography>
            <ContainedSmallIconButton
              icon='add'
              text='New Note'
              onClick={this.openEditDialog}
            />
          </div>
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            TabIndicatorProps={{ className: classes.indicator }}
            value={currentTab}
            onChange={this.onTabChange}
          >
            <Tab
              className={classes.tabPanel}
              label='All'
              value='comments'
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Reporting'
              value='report'
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label={DateUtil.formatDateTimeFromDB(comment?.comment_date)}
              value='details'
              hidden={!comment}
              disableRipple
            />
          </Tabs>
        </div>
        {currentTab === 'comments' && <ProviderCommentsPage />}
        {currentTab === 'report' && <ProviderCommentsReportPage />}
        {currentTab === 'details' && <ProviderCommentDetailPage />}
        {this.state.editDialogOpen && (
          <ProviderCommentsDialog
            clientId={app.selectedClient}
            open={this.state.editDialogOpen}
            onSave={this.onSave}
            onClose={this.closeEditDialog}
          />
        )}
      </>
    );
  }

  onTabChange = (event, newValue) => {
    if (newValue === 'comments') {
      this.props.router.navigate('/notes');
    } else if (newValue === 'report') {
      this.props.router.navigate('/notes_report');
    }
    // else we are changing to the details tab with no id?
  };

  openEditDialog = () => {
    if (this.props.user.is_admin) {
      this.props.dispatch(
        showConfirmationDialog({
          title: 'Admin Users Can Not Create Notes',
          content: 'Login as a non-admin user to create activity notes.',
          confirmLabel: 'OK',
          onConfirm: () => {
            // do nothing
          },
        })
      );
    } else {
      this.setState({ editDialogOpen: true });
    }
  };

  closeEditDialog = () => {
    this.setState({ editDialogOpen: false });
  };

  onSave = (data) => {
    const { app, user, dispatch } = this.props;

    if (
      data.body &&
      data.body.length > 0 &&
      (data.providers.length > 0 ||
        data.contacts.length > 0 ||
        data.location_id)
    ) {
      data.client_id = app.selectedClient;
      data.user_id = user.id;

      dispatch(upsertProviderComment(data));
    }
    this.closeEditDialog();
  };
}

ProviderCommentsPageContainer.propTypes = {
  app: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  classes: PropTypes.object,
  comment: PropTypes.object,
  tab: PropTypes.string,
};

function mapStateToProps(state, props) {
  const { app, providerComments, user } = state;
  const { router } = props;
  const commentId = router.params.id;

  return {
    app,
    user,
    comment:
      commentId &&
      StoreUtil.get(providerComments, StoreUtil.COMMON_ITEM, commentId).data,
  };
}

const styled = withStyles(styles)(ProviderCommentsPageContainer);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsPageContainer };
