export const API_UPLOADS_LIST = 'API_UPLOADS_LIST';
export const API_UPLOAD_TYPES_LIST = 'API_UPLOAD_TYPES_LIST';

export const queryUploads = (options, type = API_UPLOADS_LIST) => ({
  type,
  request: {
    url: '/Uploads',
    method: 'GET',
    params: { opts: options },
  },
});

export const listUploadTypes = () => ({
  type: API_UPLOAD_TYPES_LIST,
  request: {
    url: '/UploadTypes',
    method: 'GET',
  },
});
