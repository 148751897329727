import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Icon, Typography, Tooltip } from '@mui/material';

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import { withRouter } from 'utils';

import {
  DynamicTable,
  RVUSparkline,
  ProvidersTabs,
} from 'components';

import {
  loadAnalytics,
} from 'store/actions/analyticsActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  affiliatedIcon: {
    fontSize: 14,
    verticalAlign: 'text-top',
    color: theme.palette.secondary.main,
  },
});

class AffiliatedProvidersPage extends React.Component {
  constructor(props) {
    super(props);

    // this controls which columns are displayed and how they are looked up in data
    this.columns = [
      {
        label: '',
        key: 'clients',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: () => {
          return (
            <Tooltip
              placement='bottom-start'
              title={
                <Typography
                  style={{
                    fontSize: 13,
                    fontWeight: 200,
                  }}
                >
                  Affiliated Provider
                </Typography>
              }
            >
              <div
                style={{
                  width: 14,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Icon className={props.classes.affiliatedIcon}>hub</Icon>
              </div>
            </Tooltip>
          );
        },
      },
      {
        label: 'Last Name',
        key: 'last_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'First Name',
        key: 'first_name',
        fulltext: true,
        style: { minWidth: 100 },
      },
      {
        label: 'NPI',
        key: 'npi',
        style: { minWidth: 140, verticalAlign: 'top' },
      },
      {
        label: 'City',
        key: 'location.city',
        format: (value, row) => row['location.city'] || '',
        fulltext: true,
      },
      {
        label: 'State',
        key: 'location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
        format: (value, row) => row['location.state'] || '',
      },
      {
        label: 'RVUs',
        key: 'total_rvu',
        style: { width: 100, verticalAlign: 'top' },
        showFilter: false,
        format: (value) => Number(value).toLocaleString(),
      },
      {
        label: 'Trend',
        key: 'id',
        format: (id) => {
          if (id) {
            return <RVUSparkline providerId={id} />;
          }
          return 'nope';
        },
        style: { width: 60, verticalAlign: 'top' },
        sortable: false,
        showFilter: false,
      },
    ];

    this.state = {
      query: {
        where: {},
        order: [
          ['total_rvu', 'DESC'],
          ['last_name', 'ASC'],
        ],
        include: [
          {
            attributes: ['id', 'city', 'state'],
            association: 'location',
          },
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { providers } = this.props;


    // check for session storage
    const oldQuery = sessionStorage.getItem('affiliatedProvidersTableQuery')
    if (oldQuery) {
      this.onQueryChange(JSON.parse(oldQuery))
    }
    if (StoreUtil.needsLoadNoCache(providers)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (this.props.app.selectedClient !== prevProps.app.selectedClient) {
      this.fetchData();
    }
  }

  onQueryChange = (query) => {
    sessionStorage.setItem('affiliatedProvidersTableQuery', JSON.stringify(query))
    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    const { dispatch, app } = this.props;

    const { query } = this.state;

    dispatch(
      loadAnalytics({
        type: 'AFFILIATED_PROVIDERS_WITH_RVUS',
        filter: {
          where: query.where,
          client_id: app.selectedClient,
          from: app.dateRange.start,
          to: app.dateRange.end,
          limit: query.limit,
          include: query.include,
          offset: query.offset,
          order: query.order,
        },
      })
    );
  }

  render() {
    const { classes, providers } = this.props;

    const { query } = this.state;

    return (
      <>
        <ProvidersTabs tab='affiliated_providers' />
        <div className={classes.container}>
          <DynamicTable
            header='Doctivity Providers'
            showFilters={true}
            columns={this.columns}
            noDataMessage='No AFFILIATED providers found.'
            query={query}
            data={providers}
            onQueryChange={this.onQueryChange}
            getRowHref={this.getRowHref}
          />
        </div>
      </>
    );
  }

  getRowHref = (row) => {
    return `/providers/${row.id}`;
  };
}

AffiliatedProvidersPage.propTypes = {
  router: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
};

function mapStateToProps(state) {
  const { analytics, app } = state;

  return {
    app,
    providers: StoreUtil.get(analytics, 'AFFILIATED_PROVIDERS_WITH_RVUS'),
  };
}

const styled = withStyles(styles)(AffiliatedProvidersPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as AffiliatedProvidersPage };
