import 'url-search-params-polyfill';

export const API_LOGIN = 'API_LOGIN';
export const API_AUTH_RENEW = 'API_AUTH_RENEW';
export const API_AUTH_CHECK = 'API_AUTH_CHECK';
export const API_AUTH_SMS_CODE = 'API_AUTH_SMS_CODE';

export const API_LOGOUT = 'API_LOGOUT';
export const API_FORGOT_PASSWORD = 'API_FORGOT_PASSWORD';
export const API_RESET_PASSWORD = 'API_RESET_PASSWORD';
export const API_RESEND_TWO_FACTOR = 'API_RESEND_TWO_FACTOR';
export const RESET_REDIRECT_URL = 'RESET_REDIRECT_URL';
export const VIEW_PAC = 'VIEW_PAC';
export const SELECT_PAC_FACILITY = 'SELECT_PAC_FACILITY';
export const OPEN_LOGOUT_ALERT = 'OPEN_LOGOUT_ALERT';
export const CLOSE_LOGOUT_POP_UP = 'CLOSE_LOGOUT_POP_UP';
export const SIGN_OUT_WARNING = 'SIGN_OUT_WARNING';
export const CLOSE_SIGN_OUT_WARNING = 'CLOSE_SIGN_OUT_WARNING';
export const TIMEOUT = 'TIMEOUT';
export const SHOW_LOGIN_ERROR_MESSAGE = 'SHOW_LOGIN_ERROR_MESSAGE';
export const SHOW_LOGIN_INFO_MESSAGE = 'SHOW_LOGIN_INFO_MESSAGE';

export const openLogOutAlert = () => ({
  type: OPEN_LOGOUT_ALERT,
});

export const loginUser = (usernameFromUser, passwordFromUser, returnToURL) => ({
  type: API_LOGIN,
  returnToURL,
  request: {
    url: '/auth/login',
    method: 'POST',
    timeout: 5000,
    data: {
      email: usernameFromUser,
      password: passwordFromUser,
    },
  },
});

export const checkUser = (email) => ({
  type: API_AUTH_CHECK,
  request: {
    url: '/auth/check',
    method: 'POST',
    timeout: 5000,
    data: {
      email,
    },
  },
});

export const validateSMSCode = (email, code) => ({
  type: API_AUTH_SMS_CODE,
  request: {
    url: '/auth/smsCode',
    method: 'POST',
    timeout: 5000,
    data: {
      email,
      code,
    },
  },
});

export const authRenew = (token, authType = undefined) => ({
  type: API_AUTH_RENEW,
  automated: true,
  request: {
    url: '/auth/renew',
    method: 'POST',
    timeout: 5000,
    data: {
      token,
      authType,
    },
  },
});

export const closeSignOutWarningPopUp = () => ({
  type: CLOSE_SIGN_OUT_WARNING,
});

export const closeLogOutPopUp = () => ({
  type: CLOSE_LOGOUT_POP_UP,
});

export const signoutWarning = () => ({
  type: SIGN_OUT_WARNING,
});

export const logoutUser = () => ({
  type: API_LOGOUT,
  request: {
    url: '/logout',
    method: 'GET',
  },
});

export const timeoutUser = () => ({
  type: TIMEOUT,
});

export const showLoginErrorMessage = (message) => ({
  type: SHOW_LOGIN_ERROR_MESSAGE,
  message,
});

export const showLoginInfoMessage = (message) => ({
  type: SHOW_LOGIN_INFO_MESSAGE,
  message,
});
