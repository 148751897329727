import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Link, Icon, Snackbar, Typography } from '@mui/material';
import { StoreUtil } from 'doctivity-shared/utils';

const styles = (theme) => ({
  snackbar: {
    '&.MuiSnackbar-anchorOriginBottomRight': {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    '&.MuiSnackbar-anchorOriginBottomRight .MuiSnackbarContent-root': {
      backgroundColor: theme.palette.primary.accent,
    },
    '&.MuiSnackbar-anchorOriginBottomRight .MuiSnackbarContent-root .MuiButton-text':
      {
        color: '#fff',
        paddingBottom: 0,
        borderBottom: '1px solid #fff',
      },
  },
  text: {
    paddingRight: theme.spacing(2),
    display: 'flex',
    '& span': {
      paddingRight: theme.spacing(1),
    }
  },
  link: {
    textTransform: 'uppercase',
    display: 'flex',
    paddingRight: theme.spacing(2),
  }
});

function ActivitySnackbar({ classes }) {
  const editComment = useSelector((state) =>
    StoreUtil.get(state.providerComments, StoreUtil.COMMON_EDIT_ITEM),
  );
  const [lastEditComment, setLastEditComment] = useState(editComment);
  const [snacktime, setSnacktime] = useState(false);
  useEffect(() => {
    if (
      editComment &&
      StoreUtil.isSaved(editComment) &&
      editComment !== lastEditComment &&
      editComment.wasNew
    ) {
      setSnacktime(true);
      setLastEditComment(editComment);
    }
  }, [editComment]);

  return (
    <Snackbar
      open={snacktime}
      autoHideDuration={4000}
      onClose={() => setSnacktime(false)}
      message={
        <Typography className={classes.text}>
          <Icon>check_circle</Icon>
          Activity Note Created.
        </Typography>
      }
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      className={classes.snackbar}
      action={<Link color='#fff' className={classes.link} underline='always' href={`/notes/${editComment?.data?.id}`}>View Note</Link>}
    />
  );
}

ActivitySnackbar.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styled = withStyles(styles)(ActivitySnackbar);
export { styled as ActivitySnackbar };
