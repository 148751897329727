import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { BreadcrumbItem } from './BreadcrumbItem';

const styles = (theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.primary.accent,
    paddingLeft: theme.spacing(2),
    ...theme.mixins.toolbar,
  },
});

class BreadcrumbRow extends Component {
  render() {
    const { classes, icon, label, path, onNavigate } = this.props;
    return (
      <div className={classes.container}>
        <BreadcrumbItem
          icon={icon}
          label={label}
          path={path}
          onNavigate={onNavigate}
        />
      </div>
    );
  }
}

BreadcrumbRow.propTypes = {
  classes: PropTypes.object.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
  path: PropTypes.string.isRequired,
  onNavigate: PropTypes.func,
};

const styled = withStyles(styles)(BreadcrumbRow);
export { styled as BreadcrumbRow };
