export const API_FAVORITES_BY_USER = 'API_FAVORITES_BY_USER';
export const API_FAVORITES_BY_TYPE = 'API_FAVORITES_BY_TYPE';
export const API_FAVORITES_UPSERT = 'API_FAVORITES_UPSERT';
export const API_FAVORITES_DELETE = 'API_FAVORITES_DELETE';

export const favoritesByUser = (clientId, userId) => ({
  type: API_FAVORITES_BY_USER,
  id: `${clientId}-${userId}`,
  request: {
    url: '/Favorites',
    method: 'GET',
    params: {
      opts: {
        where: {
          client_id: clientId,
          user_id: userId,
        },
        order: [
          ['object_id', 'ASC'],
        ],
      },
    },
  },
});

export const favoritesByType = (clientId, userId, objectType, options = {}) => {
  const {
    where: typeWhere, include: typeInclude, attributes: typeAttributes, ...remainingOptions
  } = options;
  const include = [
    {
      association: objectType.toLowerCase().substring(0, objectType.length - 1),
      where: typeWhere,
      include: typeInclude,
      attributes: typeAttributes,
    },
  ];
  if (objectType === 'Providers' || objectType === 'Contacts') {
    include[0].include = [
      {
        association: 'location',
        attributes: ['city', 'state'],
        include: [
          {
            attributes: ['id', 'name'],
            association: 'organization',
          },
        ],
        ...typeInclude,
      },
    ];
  }
  return {
    type: API_FAVORITES_BY_TYPE,
    id: `${clientId}-${userId}-${objectType}`,
    request: {
      url: '/Favorites',
      method: 'GET',
      params: {
        opts: {
          where: {
            client_id: clientId,
            user_id: userId,
            object_type: objectType,
          },
          include,
          order: [
            ['id', 'DESC'],
          ],
          limit: 25,
          offset: 0,
          ...remainingOptions,
        },
      },
    },
  };
};

export const upsertFavorite = (clientId, userId, objectType, objectId) => {
  return {
    type: API_FAVORITES_UPSERT,
    id: 0,
    objectType, // used to update lists in reducer
    request: {
      url: '/Favorites',
      method: 'PUT',
      data: {
        client_id: clientId,
        user_id: userId,
        object_type: objectType,
        object_id: objectId,
      },
    },
  };
};

export const deleteFavorite = (id, objectType) => ({
  type: API_FAVORITES_DELETE,
  id,
  objectType, // used to update lists in reducer
  request: {
    url: '/Favorites',
    method: 'DELETE',
    data: [id],
  },
});
