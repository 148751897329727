const states = [
  ['Alabama', 'AL', 'southern', '01'],
  ['Alaska', 'AK', 'western', '02'],
  ['American Samoa', 'AS', 'territory', '03'],
  ['Arizona', 'AZ', 'western', '04'],
  ['Arkansas', 'AR', 'southern', '05'],
  ['Armed Forces Americas', 'AA', 'territory', '00'],
  ['Armed Forces Europe', 'AE', 'territory', '00'],
  ['Armed Forces Pacific', 'AP', 'territory', '00'],
  ['California', 'CA', 'western', '06'],
  ['Colorado', 'CO', 'western', '08'],
  ['Connecticut', 'CT', 'eastern', '09'],
  ['Delaware', 'DE', 'eastern', '10'],
  ['District Of Columbia', 'DC', 'eastern', '11'],
  ['Florida', 'FL', 'southern', '12'],
  ['Georgia', 'GA', 'southern', '13'],
  ['Guam', 'GU', 'territory', '14'],
  ['Hawaii', 'HI', 'western', '15'],
  ['Idaho', 'ID', 'western', '16'],
  ['Illinois', 'IL', 'midwest', '17'],
  ['Indiana', 'IN', 'midwest', '18'],
  ['Iowa', 'IA', 'midwest', '19'],
  ['Kansas', 'KS', 'southern', '20'],
  ['Kentucky', 'KY', 'southern', '21'],
  ['Louisiana', 'LA', 'southern', '22'],
  ['Maine', 'ME', 'eastern', '23'],
  ['Marshall Islands', 'MH', 'territory', '68'],
  ['Maryland', 'MD', 'eastern', '24'],
  ['Massachusetts', 'MA', 'eastern', '25'],
  ['Michigan', 'MI', 'midwest', '26'],
  ['Minnesota', 'MN', 'midwest', '27'],
  ['Mississippi', 'MS', 'southern', '28'],
  ['Missouri', 'MO', 'southern', '29'],
  ['Montana', 'MT', 'western', '30'],
  ['Nebraska', 'NE', 'midwest', '31'],
  ['Nevada', 'NV', 'western', '32'],
  ['New Hampshire', 'NH', 'eastern', '33'],
  ['New Jersey', 'NJ', 'eastern', '34'],
  ['New Mexico', 'NM', 'western', '35'],
  ['New York', 'NY', 'eastern', '36'],
  ['North Carolina', 'NC', 'eastern', '37'],
  ['North Dakota', 'ND', 'midwest', '38'],
  ['Northern Mariana Islands', 'NP', 'territory', '69'],
  ['Ohio', 'OH', 'midwest', '39'],
  ['Oklahoma', 'OK', 'southern', '40'],
  ['Oregon', 'OR', 'western', '41'],
  ['Pennsylvania', 'PA', 'eastern', '42'],
  ['Puerto Rico', 'PR', 'territory', '43'],
  ['Rhode Island', 'RI', 'eastern', '44'],
  ['South Carolina', 'SC', 'southern', '45'],
  ['South Dakota', 'SD', 'midwest', '46'],
  ['Tennessee', 'TN', 'southern', '47'],
  ['Texas', 'TX', 'southern', '48'],
  ['US Virgin Islands', 'VI', '52'],
  ['Utah', 'UT', 'western', 'territory', '49'],
  ['Vermont', 'VT', 'eastern', '50'],
  ['Virginia', 'VA', 'eastern', '51'],
  ['Washington', 'WA', 'western', '53'],
  ['West Virginia', 'WV', 'eastern', '54'],
  ['Wisconsin', 'WI', 'eastern', '55'],
  ['Wyoming', 'WY', 'western', '56'],
];
/*
const provinces = [
  ['Alberta', 'AB'],
  ['British Columbia', 'BC'],
  ['Manitoba', 'MB'],
  ['New Brunswick', 'NB'],
  ['Newfoundland', 'NF'],
  ['Northwest Territory', 'NT'],
  ['Nova Scotia', 'NS'],
  ['Nunavut', 'NU'],
  ['Ontario', 'ON'],
  ['Prince Edward Island', 'PE'],
  ['Quebec', 'QC'],
  ['Saskatchewan', 'SK'],
  ['Yukon', 'YT'],
];
*/


let STATE_NAMES = null;
let STATE_ABBRS = null;
let STATE_OBJS = null;
class StateUtil {
  static abbr2Name(abbr) {
    const found = states.find((state) => (state[1] === abbr));
    if (found) {
      return found[0];
    }
    return null;
  }

  static name2abbr(abbr) {
    const found = states.find((state) => (state[2] === abbr));
    if (found) {
      return found[1];
    }
    return null;
  }

  static getNames() {
    if (!STATE_NAMES) {
      STATE_NAMES = states.map((state) => (state[0]));
    }
    return STATE_NAMES;
  }

  static getAbbreviations() {
    if (!STATE_ABBRS) {
      STATE_ABBRS = states.map((state) => (state[1]));
    }
    return STATE_ABBRS;
  }

  static getStates() {
    if (!STATE_OBJS) {
      STATE_OBJS = states.map((state) => ({ name: state[0], abbr: state[1], fips: state[3] }));
    }
    return STATE_OBJS;
  }

  static getRegion(abbr) {
    const found = states.find((state) => (state[1] === abbr));
    if (found) {
      return found[2];
    }
    return null;
  }

  static getAbbrForFips(fips) {
    const found = states.find((state) => (state[3] === fips));
    if (found) {
      return found[1];
    }
    return null;
  }

  static getFipsForAbbr(abbr) {
    const found = states.find((state) => (state[1] === abbr));
    if (found) {
      return found[3];
    }
    return null;
  }
}

module.exports = { StateUtil };
