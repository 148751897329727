import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  AffiliatedIcon,
  TitleTooltip,
  DynamicTable,
  VisitsSparkline,
} from 'components';
import { withRouter } from 'utils';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  loadAnalytics,
} from 'store/actions/analyticsActions';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class TopReferralsOutTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        limit: 50,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.providerId !== prevProps.providerId ||
      this.props.clientId !== prevProps.clientId ||
      this.props.app.dateRange !== prevProps.app.dateRange
    ) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, providerId, clientId } = this.props;

    dispatch(
      loadAnalytics({
        type: 'TOP_REFERRALS_BY_PROVIDER',
        filter: {
          provider_id: providerId,
          client_id: clientId,
        },
        opts: {
          ...this.state.query,
          from: this.props.app.dateRange.start,
          to: this.props.app.dateRange.end,
        },
      })
    );
  }

  render() {
    const { classes, data, providerId } = this.props;

    const noDataMessage = 'No referrals made.';
    return (
      <Card className={classes.card}>
        <CardContent>
          <DynamicTable
            ContainerType={React.Fragment}
            header={
              <TitleTooltip
                title='Top Referral Recipients'
                tooltip='List of top recipients that this provider is referring.'
              />
            }
            idKey='provider_id'
            columns={[
             {
                label: '',
                sortable: false,
                showFilter: false,
                key: 'provider.clients.id',
                type: 'bool',
                format: (value) => value ? <AffiliatedIcon /> : '',
                style: { width: 16 },
              },
              {
                label: 'Provider Referred',
                key: 'provider',
                sortable: false,
                format: (value) =>
                  `${value.last_name}, ${value.first_name} ${value.middle_name}`,
              },
              {
                label: 'Referrals',
                key: 'count',
                style: { width: 100 },
              },
              {
                label: 'Trend',
                key: 'provider.id',
                format: (_, row) => {
                  if (row?.provider_id) {
                    return <VisitsSparkline providerId={row.provider_id} referrerId={providerId} />;
                  }
                  return 'nope';
                },
                style: { width: 60, verticalAlign: 'top' },
                sortable: false,
                showFilter: false,
              },

            ]}
            showFilters={false}
            query={this.state.query}
            data={data}
            noDataMessage={noDataMessage}
            getRowHref={this.getRowHref}
            onQueryChange={this.onQueryChange}
          />
        </CardContent>
      </Card>
    );
  }

  onQueryChange = (query) => {
    this.setState(
      {
        query,
      },
      () => {
        this.fetchData();
      }
    );
  };

  getRowHref = (row) => {
    return `/providers/${row.provider_id}/visits`;
  };
}

TopReferralsOutTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  data: PropTypes.object,
  providerId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const {
    analytics,
    app,
  } = state;

  return {
    app,
    data: StoreUtil.get(analytics, 'TOP_REFERRALS_BY_PROVIDER'),
  };
}

const styled = withStyles(styles)(TopReferralsOutTable);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as TopReferralsOutTable };
