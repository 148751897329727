export const API_TAXONOMY_TABLE = 'API_TAXONOMY_TABLE';
export const API_TAXONOMY_LIST = 'API_TAXONOMY_LIST';
export const API_TAXONOMY_UPSERT = 'API_TAXONOMY_UPSERT';

export const queryTaxonomy = (options, type = API_TAXONOMY_TABLE) => ({
  type,
  request: {
    url: '/Taxonomy',
    method: 'GET',
    params: { opts: options },
  },
});

export const listTaxonomy = () => (queryTaxonomy({
  order: [
    ['code', 'ASC'],
  ],
}, API_TAXONOMY_LIST));

export const upsertTaxonomy = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  return {
    type: API_TAXONOMY_UPSERT,
    id,
    request: {
      url: '/Taxonomy',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};
