import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.API_BASE_URL, // this comes from config/webpack.common.config.js
  withCredentials: false, // this on true doesn't seem to be supported by AWS Lambda authorizer
});

axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('auth');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    // this is required for aws auth to run
    // the authorizerFunc will never be callsed with no header
    config.headers.Authorization = 'Bearer anonymous';
  }
  return config;
});

export { axiosInstance };

export default axiosInstance;
