import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import { Button, Icon } from '@mui/material';

const styles = (theme) => ({
  button: {
    textTransform: 'unset !important',
    borderRadius: 40,
    color: theme.palette.primary.accent
  },
  buttonText: {},
  icon: {
    fontSize: 14,
  },
});

class ContainedSmallIconButton extends Component {
  render() {
    const { classes, icon, text, onClick } = this.props;

    return (
      <Button
        key='button'
        color='primary'
        size='small'
        variant='contained'
        className={classes.button}
        onClick={onClick}
      >
        {icon && <Icon className={classes.icon}>{icon}</Icon>}
        <span className={classes.buttonText}>{text}</span>
      </Button>
    );
  }
}

ContainedSmallIconButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

const styled = withStyles(styles)(ContainedSmallIconButton);
export { styled as ContainedSmallIconButton };
