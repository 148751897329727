
import { StoreUtil } from './StoreUtil';

class FormUtil {
  static GetLoadStatus(loadedDataItem, dataName) {
    let status;
    let failure = false;
    if (StoreUtil.isSaveFailed(loadedDataItem)) {
      status = `There was an issue saving the ${dataName}.  Please try again later.`;
      failure = true;
    } else if (StoreUtil.isLoadFailed(loadedDataItem)) {
      status = `There was an issue retrieving the ${dataName}.  Please try again later.`;
      failure = true;
    } else if (StoreUtil.isSaved(loadedDataItem)) {
      status = 'Saved Successfully';
    } else if (StoreUtil.isSaving(loadedDataItem)) {
      status = 'Saving..';
    }

    return {
      status,
      failure,
    };
  }

  static ByteSizeToString(byteCount, decimals = 1) {
    if (byteCount > 1073741824) {
      return `${(byteCount / 1073741824).toFixed(decimals)}GB`;
    }
    if (byteCount > 1048576) {
      return `${(byteCount / 1048576).toFixed(decimals)}MB`;
    }
    if (byteCount > 1024) {
      return `${(byteCount / 1024).toFixed(decimals)}kB`;
    }
    return `${byteCount}B`;
  }
}

export { FormUtil };
export default FormUtil;
