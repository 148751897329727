import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import { TableUtil } from 'utils';

import { StoreUtil } from 'doctivity-shared/utils';

import { DynamicTable } from 'components';
import { loadAnalytics } from 'store/actions/analyticsActions';

const columns = [
  /*
  {
    label: 'Name',
    key: 'name',
    fulltext: true,
  },
  */
  {
    label: 'Address',
    key: 'address1',
    style: { minWidth: 120 },
    format: (address1, location) =>
      location.address2 ? address1 + ', ' + location.address2 : address1,
  },
  {
    label: 'City',
    key: 'city',
    fulltext: true,
  },
  {
    label: 'State',
    key: 'state',
    style: { width: 50 },
    filterExact: true,
    autoCapitalize: true,
  },
  {
    label: 'Organization',
    key: 'organization.name',
    fulltext: true,
    style: { width: 320 },
  },
  {
    label: 'Providers',
    key: 'provider_count',
    style: { width: 50 },
    filter: false,
    sortable: false,
    format: (count) => count.toLocaleString(),
  },
  {
    label: 'Contacts',
    key: 'contact_count',
    style: { width: 50 },
    filter: false,
    sortable: false,
    format: (count) => count.toLocaleString(),
  },
  {
    label: 'Primary Location',
    key: 'organization.primary_location_id',
    format: (primaryLocationId, location) =>
      TableUtil.boolToCheckIcon(primaryLocationId === location.id),
    style: { width: 120 },
    filter: false,
    sortable: true,
  },
];

const styles = (theme) => ({
  filters: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  instructions: {
    fontSize: 14,
    fontWeight: 300,
    flex: 1,
    color: theme.palette.primary.main,
  },
  table: {
    minHeight: 420,
  },
});

class ChangePrimaryLocationDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        attributes: [
          'id',
          'name',
          'address1',
          'address2',
          'city',
          'state',
          'postal_code',
        ],
        where: {},
        include: [
          {
            association: 'organization',
            attributes: ['id', 'name', 'primary_location_id'],
            required: true,
            where: { id: props.organization.id },
            paranoid: false,
          },
        ],
        paranoid: false,
        limit: 25,
        offset: 0,
        order: [
          ['organization', 'primary_location_id', 'DESC'],
          ['id', 'ASC'],
        ],
      },
    };
  }

  componentDidMount() {
    const { locationCounts } = this.props;

    if (StoreUtil.needsLoadNoCache(locationCounts)) {
      this.fetchData();
    }
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    const { dispatch, clientId } = this.props;
    const { query } = this.state;

    dispatch(
      loadAnalytics({
        type: 'LOCATIONS_PROVIDERS_CONTACTS_COUNT',
        opts: query,
        filter: {
          client_id: clientId,
        },
      })
    );
  }

  render() {
    const { classes, locationCounts, open } = this.props;
    const { query } = this.state;

    return (
      <Dialog
        onClose={this.onCancel}
        aria-labelledby='simple-dialog-title'
        open={open}
        fullWidth
        maxWidth='lg'
        scroll='paper'
      >
        <DialogTitle>Select a Primary Location</DialogTitle>
        <DialogContent dividers>
          <div className={classes.filters}>
            <Typography className={classes.instructions}>
              Primary Location is where the organization market data records and
              displays.
            </Typography>
          </div>
          <div className={classes.table}>
            <DynamicTable
              columns={columns}
              query={query}
              data={locationCounts}
              noDataMessage='No locations found.'
              onQueryChange={this.onQueryChange}
              onRowClick={this.onRowClick}
              ContainerType={React.Fragment}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onCancel} color='primary'>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  onCancel = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  onRowClick = (row) => {
    if (this.props.onSelect) {
      this.props.onSelect(row);
    }
  };
}

ChangePrimaryLocationDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  organization: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  clientId: PropTypes.number.isRequired,
  locationCounts: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    locationCounts: StoreUtil.get(
      state.analytics,
      'LOCATIONS_PROVIDERS_CONTACTS_COUNT'
    ),
  };
}

const styled = withStyles(styles)(ChangePrimaryLocationDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as ChangePrimaryLocationDialog };
