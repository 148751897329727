export const API_PROCEDURES_TABLE = 'API_PROCEDURES_TABLE';
export const API_PROCEDURES_LIST = 'API_PROCEDURES_LIST';
export const API_PROCEDURES_UPSERT = 'API_PROCEDURES_UPSERT';

export const queryProcedures = (options, type = API_PROCEDURES_TABLE) => ({
  type,
  request: {
    url: '/Procedures',
    method: 'GET',
    params: { opts: options },
  },
});

export const listProcedures = () => (queryProcedures({
  order: [
    ['hcpcs_code', 'DESC'],
  ],
}, API_PROCEDURES_LIST));

export const upsertProcedures = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  return {
    type: API_PROCEDURES_UPSERT,
    id,
    request: {
      url: '/Procedures',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};
