class ProviderNotesUtil {
  static allCountTypes = ['provider_count', 'contact_count', 'location_count'];

  static formatCountTypeForDisplay(countType) {
    switch (countType) {
      case 'provider_count':
        return 'Provider Count';
        break;
      case 'contact_count':
        return 'Contact Count';
        break;
      case 'location_count':
        return 'Location Count';
        break;
    }
  }
}

export { ProviderNotesUtil };
export default ProviderNotesUtil;
