import { success } from 'redux-saga-requests';

import { ReducerUtil } from 'doctivity-shared/utils';
import { TagsUtil } from 'utils';

import {
  API_TAG_NAMESPACES_LIST_BY_CLIENT,
  API_TAG_NAMESPACES_LOAD,
  API_TAG_NAMESPACES_LIST,
  API_TAG_NAMESPACES_TABLE,
  API_TAG_NAMESPACES_UPSERT,
} from 'store/actions/tagNamespacesActions';

export const tagNamespaces = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_TAG_NAMESPACES_TABLE,
    API_TAG_NAMESPACES_LIST,
    API_TAG_NAMESPACES_UPSERT,
  );
  if (result.actionConsumed) {
    if (success(API_TAG_NAMESPACES_UPSERT)) {
      // invalidate list by client
      result.state[API_TAG_NAMESPACES_LIST_BY_CLIENT] = null;
    }

    return result.state;
  }

  result = ReducerUtil.reduceListGet(
    state,
    action,
    API_TAG_NAMESPACES_LIST_BY_CLIENT,
    API_TAG_NAMESPACES_LIST_BY_CLIENT,
  );
  if (result.actionConsumed) {
    if (action.clientId) {
      result.state[API_TAG_NAMESPACES_LIST_BY_CLIENT].clientId = action.clientId;
    } else if (action.meta && action.meta.requestAction.clientId) {
      result.state[API_TAG_NAMESPACES_LIST_BY_CLIENT].clientId = action.meta.requestAction.clientId;
    }
    if (success(API_TAG_NAMESPACES_LIST_BY_CLIENT)) {
      TagsUtil.indexTags(result.state[API_TAG_NAMESPACES_LIST_BY_CLIENT]);
    }

    return result.state;
  }

  result = ReducerUtil.reduceSingleGet(state, action, API_TAG_NAMESPACES_LOAD);
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
