class DataUtil {
  // Remove element from array by value
  static remove(array, element) {
    const index = array.indexOf(element);
    if (index && index >= 0) {
      array.splice(index, 1);
    }
  }

  static isStringSame(str1, str2) {
    if (!str1 && !str2) {
      return true;
    }
    if (!str1) {
      return false;
    }
    return str1.localeCompare(str2, 'en', { sensitivity: 'base' }) === 0;
  }

  static formatNameForUser(user) {
    if (!user) {
      return '';
    }

    let name = '';
    if (user.last_name) {
      if (user.first_name) {
        name = `${user.first_name} ${user.last_name}`;
      } else {
        name = user.last_name;
      }
    } else if (user.email) {
      name = user.email;
    } else {
      name = user.id;
    }

    return name;
  }

  static deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static groupBy(arr, key) {
    return arr.reduce((accumulator, value) => {
      (accumulator[value[key]] = accumulator[value[key]] || []).push(value);
      return accumulator;
    }, {});
  }

  static removeMatrixColumn(matrix, columnIndex) {
    return matrix.map((row) =>
      row.filter((_,index) => index !== columnIndex)
    );
  }
}

export { DataUtil };
export default DataUtil;
