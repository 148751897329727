export const API_VISITS_TABLE = 'API_VISITS_TABLE';
export const API_VISITS_DEPARTMENTS = 'API_VISITS_DEPARTMENTS';
export const API_VISITS_REFERRING_ORGANIZATIONS = 'API_VISITS_REFERRING_ORGANIZATIONS';

export const queryVisits = (options, type = API_VISITS_TABLE) => ({
  type,
  request: {
    url: '/Visits',
    method: 'GET',
    params: { opts: options },
  },
});

export const listDepartments = (clientId) => ({
  type: API_VISITS_DEPARTMENTS,
  clientId,
  request: {
    url: '/Visits',
    method: 'GET',
    params: {
      opts: {
        attributes: ['department'],
        where: {
          client_id: clientId,
          department: { $ne: null },
        },
        group: ['department'],
      },
    },
  },
});

export const listReferringOrganizations = (clientId) => ({
  type: API_VISITS_REFERRING_ORGANIZATIONS,
  clientId,
  request: {
    url: '/Visits',
    method: 'GET',
    params: {
      opts: {
        attributes: ['referring_organization'],
        where: {
          client_id: clientId,
          referring_organization: { $ne: null },
        },
        group: ['referring_organization'],
      },
    },
  },
});
