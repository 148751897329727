import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_MARKETS_LIST,
  API_MARKETS_UPSERT,
  API_MARKETS_DELETE,
} from 'store/actions/marketsActions';

export const markets = (state = {}, action = null) => {
  let result = ReducerUtil.reduceListGet(state, action, API_MARKETS_LIST);
  if (result.actionConsumed) {
    if (action.type === API_MARKETS_LIST
      && action.request
      && action.request.params
      && action.request.params.clientId) {
      return {
        ...result.state,
        clientId: action.request.params.clientId,
      };
    }
    return result.state;
  }

  result = ReducerUtil.reduceUpsert(state, action, API_MARKETS_UPSERT);
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceUpsert(state, action, API_MARKETS_DELETE);
  if (result.actionConsumed) {
    // delete into list
    return result.state;
  }
  return state;
};
