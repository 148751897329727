import React from 'react';
import { connect } from 'react-redux';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  LoadingView,
  TitleTooltip,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  loadAnalytics,
} from 'store/actions/analyticsActions';
import { withIsMobile } from 'utils';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class PayerPieChart extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.providerId !== prevProps.providerId
      || this.props.department !== prevProps.department
      || this.props.referringOrganization !== prevProps.referringOrganization
      || this.props.clientId !== prevProps.clientId
      || this.props.app.dateRange !== prevProps.app.dateRange) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(loadAnalytics({
      type: 'PROVIDER_PAYERS',
      filter: {
        provider_id: this.props.providerId,
        client_id: this.props.clientId,
        department: this.props.department,
        referring_organization: this.props.referringOrganization,
      },
      opts: {
        from: this.props.app.dateRange.start,
        to: this.props.app.dateRange.end,
      },
    }));
  }

  render() {
    const {
      classes,
      data,
      isMobile,
    } = this.props;

    let graph;
    if (!data
      || StoreUtil.isLoading(data)) {
      graph = (<LoadingView />);
    } else if (data && data.data && data.data.length > 0) {
      const labels = [];
      const counts = [];
      let other = 0;
      let unknown = false;
      StoreUtil.getData(data).forEach((row) => {
        if (row.payer && row.payer.name && labels.length < 7) {
          if (row.payer.name === 'Other') {
            // other column exists alone?
            other += parseInt(row.count, 10);
            return;
          } if (row.payer.name.length > 27) {
            labels.push(`${row.payer.name.substring(0, 27)}...`);
          } else {
            labels.push(row.payer.name);
          }
          let count = parseInt(row.count, 10);
          if (!Number.isInteger(count)) {
            count = 0;
          }
          counts.push(count);
        } else {
          other++;
          if (!row.payer) {
            unknown = true;
          }
        }
      });
      if (other) {
        if (unknown) {
          labels.push('Other / Unknown');
        } else {
          labels.push('Other');
        }
        counts.push(other);
      }

      graph = (
        <Chart
          type='pie'
          {...(isMobile ? {height: 400} : {height: 310})}
          options={{
            ...(isMobile ? { legend: { horizontalAlign: 'left', position: 'bottom' } } : {}),
            labels,
          }}
          series={counts}
        />
      );
    } else {
      graph = (
        <Typography className={classes.nothingFound}>
          No payor data available.
        </Typography>
      );
    }

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.header}>
            <TitleTooltip
              title='Visit Payor'
              tooltip=''
            />
          </div>

          {graph}

        </CardContent>
      </Card>
    );
  }
}

PayerPieChart.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  data: PropTypes.object,
  providerId: PropTypes.number,
  clientId: PropTypes.number.isRequired,
  department: PropTypes.string,
  referringOrganization: PropTypes.string,
  isMobile: PropTypes.bool,
};

function mapStateToProps(state) {
  const {
    analytics,
    app,
  } = state;

  return {
    app,
    data: StoreUtil.get(analytics, 'PROVIDER_PAYERS'),
  };
}

const styled = withStyles(styles)(withIsMobile(PayerPieChart));
const connected = connect(mapStateToProps)(styled);
export { connected as PayerPieChart };
