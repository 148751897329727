import { success } from 'redux-saga-requests';

import {
  API_WAKEUP,
  APP_SHOW_DIALOG,
  APP_HIDE_DIALOG,
  APP_CONFIRMATION_DIALOG,
} from 'store/actions/systemActions';

export const system = (state = {}, action = null) => {
  switch (action.type) {
    case API_WAKEUP:
      return {
        ...state,
        wakeup: {
          ...state.wakeup,
          loading: true,
        },
      };
    case success(API_WAKEUP):
      // save timestamp of successful server wakeup
      return {
        ...state,
        wakeup: {
          ...state.wakeup,
          loading: false,
          lastMade: (new Date()).getTime(),
        },
      };
    case APP_CONFIRMATION_DIALOG:
      return {
        ...state,
        confirmationDialog: action.payload,
      };
    case APP_SHOW_DIALOG:
      return {
        ...state,
        dialogs: {
          ...state.dialogs,
          [action.payload.props.id]: action.payload,
        },
      };
    case APP_HIDE_DIALOG: {
      const dialogs = {
        ...state.dialogs,
      };
      if (dialogs[action.payload]) {
        delete dialogs[action.payload];
      }
      return {
        ...state,
        dialogs,
      };
    }
    default:
      return state;
  }
};
