import React, { Component } from 'react';
import { TablePagination } from '@mui/material';
import { StoreUtil } from 'doctivity-shared/utils';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
  },
});

class Paginateable extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
    this.onPageChange = this.onPageChange.bind(this);
    this.onRowsPerPageChange = this.onRowsPerPageChange.bind(this);
  }

  onPageChange(event, page) {
    const query = { ...this.props.query };

    query.offset = page * query.limit;

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  onRowsPerPageChange(event) {
    const query = { ...this.props.query };

    query.limit = event.target.value;
    query.offset = 0;

    if (this.props.onQueryChange) {
      this.props.onQueryChange(query);
    }
  }

  render() {
    const {
      data,
      query,
    } = this.props;

    /* DATA CAN BE SEVERAL THINGS!!
      IT CAN BE:
        1) StoreUtil LoadedData object
        2) An array
        3) An object with .data, .count,
    */
    let dataCount = -1;
    if (StoreUtil.isLoadedData(data)) {
      if (StoreUtil.getData(data)) {
        const d = StoreUtil.getData(data);
        if (Array.isArray(d.rows)) {
          dataCount = d.count;
        }
      }
    } else if (data && Array.isArray(data)) {
      dataCount = data.length;
    } else if (data && !data.loading && data.count >= 0) {
      dataCount = data.count;
    } else if (data && data.placeholder) {
      dataCount = 0;
    }

    let rowsPerPage = 25;
    let page = 0;
    let pages = 0;
    if (query) {
      rowsPerPage = query.limit;
      page = Math.floor(query.offset / rowsPerPage);
      pages = 0;
      if (dataCount > -1) {
        pages = Math.ceil(dataCount / rowsPerPage);
      }
    }

    return (
      <div>
        {pages >= 0 && (
          <TablePagination
            component='div'
            count={dataCount}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              'aria-label': 'Previous Page',
            }}
            nextIconButtonProps={{
              'aria-label': 'Next Page',
            }}
            labelDisplayedRows={
              ({ from, to, count }) => {
                if (count === -1) {
                  return `${from.toLocaleString()}-${to.toLocaleString()} more than ${to.toLocaleString()}`;
                }
                return `${from.toLocaleString()}-${to.toLocaleString()} of ${count.toLocaleString()}`;
              }
            }
            onPageChange={this.onPageChange}
            onRowsPerPageChange={this.onRowsPerPageChange}
            rowsPerPageOptions={[10, 25, 50, 100, 200]}
          />
        )}
      </div>
    );
  }
}

Paginateable.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  query: PropTypes.object,
  onQueryChange: PropTypes.func,
};

const styled = withStyles(styles)(Paginateable);
export { styled as Paginateable };
