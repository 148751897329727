import React, { useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import withStyles from '@mui/styles/withStyles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import dotProp from 'dot-prop-immutable';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
const ShortLabel = ({ children }) => (
  <div
    style={{
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '6rem',
    }}
  >
    {children}
  </div>
);
ShortLabel.propTypes = {
  children: PropTypes.node.isRequired,
};

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props) {
  // eslint-disable-next-line
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: 2,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const styles = (theme) => ({
  filterDropDown: {
    marginRight: theme.spacing(),
    width: '100%',
  },
  header: {
    color: theme.palette.primary.main,
    fontSize: 18,
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
    fontFamily: 'Roboto',
  },
  input: {
    margin: theme.spacing(1),
    width: '90%',
  },
  label: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
  },
  chiptainer: {
    margin: theme.spacing(1),
    overflow: 'hidden',
  },
  chip: {
    maxWidth: '12rem',
  },
  chipOpenLabel: {
    maxWidth: '15rem',
  },
});

/**
 * A search component that debounces the input and fetches suggestions from the server.
 * @param {Object} classes - The classes object to use for styling.
 * @param {Function} loadSuggestions - The function to call to load suggestions. It should return a promise that resolves to an array of suggestions.
 * @param {String} displayPath - The path to the property to display in the suggestions.
 * @param {Function} handleSelectedValue - The function to call when a value is selected.
 * @param {Array} selectedValues - The values that are selected.
 * @param {Boolean} multiple - Whether the search should allow multiple selections.
 * @param {String} title - The title to display in the search box.
 */
const DebouncedSearch = ({
  classes,
  loadSuggestions,
  displayPath,
  handleSelectedValue,
  selectedValues,
  multiple,
  title,
  limit,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [pendingValues, setPendingValues] = useState([...selectedValues]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setPendingValues([...selectedValues]);
  };

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const [inputValue, setInputValue] = useState();
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const debounceTimeout = useRef(null);
  const fetchSuggestions = async (query) => {
    if (query) {
      setLoading(true);
      try {
        const response = await loadSuggestions(query);
        setSuggestions(response);
      } catch (error) {
        console.error('Error fetching suggestions:', error);
      } finally {
        setLoading(false);
      }
    } else {
      setSuggestions([]);
    }
  };

  const handleInputChange = (_event, newInputValue) => {
    setInputValue(newInputValue);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      fetchSuggestions(newInputValue);
    }, 100);
  };
  return (
    <>
      <FormControl
        variant='outlined'
        className={classes.filterDropDown}
        key='filter-serviceline'
      >
        <InputLabel>{title}</InputLabel>
        <Select
          value='filter'
          label={title}
          open={false}
          onClick={handleClick}
          width='100%'
        >
          <MenuItem value='filter'>
            <div className={classes.label}>
              {selectedValues.map((option, i) => (
                <Chip
                  key={i}
                  variant='outlined'
                  label={dotProp.get(option, displayPath)}
                  className={classes.chip}
                  onDelete={
                    selectedValues?.length > 1 &&
                    (() => {
                      const newVal = selectedValues.filter(
                        (v) => v.id !== option.id,
                      );
                      handleSelectedValue(newVal);
                      setPendingValues(newVal);
                      handleClose();
                    })
                  }
                />
              ))}
            </div>
          </MenuItem>
        </Select>
      </FormControl>
      <StyledPopper open={open} anchorEl={anchorEl} placement='bottom-start'>
        <ClickAwayListener onClickAway={handleClose}>
          <div className={classes.chiptainer}>
            {selectedValues.length > 0 &&
              selectedValues.map((option, i) => (
                <Chip
                  key={i}
                  variant='outlined'
                  label={dotProp.get(option, displayPath)}
                  className={classes.chipOpenLabel}
                  onDelete={
                    selectedValues?.length > 1 &&
                    (() => {
                      const newVal = selectedValues.filter(
                        (v) => v.id !== option.id,
                      );
                      handleSelectedValue(newVal);
                      setPendingValues(newVal);
                      handleClose();
                    })
                  }
                />
              ))}
            <Box className={classes.header}>
              Choose up to {limit} {title}s
            </Box>
            <Autocomplete
              open
              close={handleClose}
              options={suggestions}
              inputValue={inputValue}
              noOptionsText='Start typing to search'
              getOptionLabel={(option) => {
                const val = dotProp.get(option, displayPath);
                return val ? val : '';
              }}
              loading={loading}
              multiple={multiple}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              onInputChange={handleInputChange}
              value={pendingValues}
              onChange={(event, newValue) => {
                if (newValue.length < 1) {
                  return;
                }
                const start =
                  newValue.length >= limit ? newValue.length - limit : 0;
                handleSelectedValue(newValue.slice(start, limit + 1));
                handleClose();
              }}
              PopperComponent={PopperComponent}
              renderTags={() => null}
              renderOption={(props, option) => (
                <li {...props}>
                  <Box
                    sx={{
                      flexGrow: 1,
                    }}
                  >
                    {option.name}
                  </Box>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  className={classes.input}
                  variant='standard'
                />
              )}
            />
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </>
  );
};
DebouncedSearch.propTypes = {
  classes: PropTypes.object.isRequired,
  loadSuggestions: PropTypes.func.isRequired,
  displayPath: PropTypes.string.isRequired,
  handleSelectedValue: PropTypes.func.isRequired,
  selectedValues: PropTypes.array.isRequired,
  multiple: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  limit: PropTypes.number,
};
export default withStyles(styles)(DebouncedSearch);
