export const API_ORGANIZATIONS_TABLE = 'API_ORGANIZATIONS_TABLE';
export const API_ORGANIZATIONS_LIST = 'API_ORGANIZATIONS_LIST';
export const API_ORGANIZATIONS_LOAD = 'API_ORGANIZATIONS_LOAD';
export const API_ORGANIZATIONS_UPSERT = 'API_ORGANIZATIONS_UPSERT';
export const API_ORGANIZATIONS_TABLE_PROVIDERS = 'API_ORGANIZATIONS_TABLE_PROVIDERS';
export const API_ORGANIZATIONS_TABLE_CONTACTS = 'API_ORGANIZATIONS_TABLE_CONTACTS';
export const API_ORGANIZATIONS_ADD_PROVIDER = 'API_ORGANIZATIONS_ADD_PROVIDER';
export const API_ORGANIZATIONS_REMOVE_PROVIDER = 'API_ORGANIZATIONS_REMOVE_PROVIDER';
export const API_ORGANIZATIONS_ADD_CONTACT = 'API_ORGANIZATIONS_ADD_CONTACT';
export const API_ORGANIZATIONS_REMOVE_CONTACT = 'API_ORGANIZATIONS_REMOVE_CONTACT';

export const queryOrganizations = (options, type = API_ORGANIZATIONS_TABLE) => ({
  type,
  request: {
    url: '/Organizations',
    method: 'GET',
    params: { opts: options },
  },
});

export const listOrganizations = () => (queryOrganizations({
  order: [
    ['name', 'ASC'],
  ],
}, API_ORGANIZATIONS_LIST));

export const loadOrganization = (id, clientId) => {

  const include = [
    {
      association: 'primary_location',
    },
    {
      association: 'tags',
      attributes: ['id', 'name', 'namespace_id'],
      ...(clientId ? { where: { client_id: clientId }, required: false } : {}),
      include: [
        {
          association: 'namespace',
          attributes: ['id', 'name'],
        },
      ],
    },
  ];

  if (clientId) {  // include if affiliated with this client
    include.push({
      attributes: ['id'],
      association: 'affiliated',
      where: { id:  clientId },
      required: false,
    });
  }
  
  return {
    type: API_ORGANIZATIONS_LOAD,
    id,
    request: {
      url: `/Organizations/${id}`,
      method: 'GET',
      params: {
        opts: {
          include,
        },
      },
    },
  };
};

export const upsertOrganization = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  return {
    type: API_ORGANIZATIONS_UPSERT,
    id,
    request: {
      url: '/Organizations',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};

export const queryOrganizationProviders = (orgId, options) => {
  if (options.include && options.include[0].association === 'location') {
    options.include[0].where.organization_id = orgId;
  } else {
    options.include = [
      {
        association: 'location',
        where: {
          organization_id: orgId,
        },
        required: true,
      },
    ];
  }

  return {
    type: API_ORGANIZATIONS_TABLE_PROVIDERS,
    request: {
      url: '/Providers',
      method: 'GET',
      params: {
        opts: {
          ...options,
        },
      },
    },
  };
};

export const queryOrganizationContacts = (orgId, clientId, options) => {
  if (!options.where) {
    options.where = {};
  }
  options.where.client_id = clientId;

  return {
    type: API_ORGANIZATIONS_TABLE_CONTACTS,
    orgId,
    clientId,
    request: {
      url: '/Contacts',
      method: 'GET',
      params: {
        opts: {
          ...options,
          include: [
            {
              association: 'location',
              attributes: ['id'],
              where: {
                organization_id: orgId,
              },
              required: true,
            },
          ],
        },
      },
    },
  };
};

export const addProviderToOrganization = (orgId, providerId, tableOptions) => {
  const id = `${orgId}_p${providerId}`; // used to grab data for props
  return {
    type: API_ORGANIZATIONS_ADD_PROVIDER,
    id,
    request: {
      url: '/ProvidersToOrganizations',
      method: 'PUT',
      params: { opts: null },
      data: {
        organization_id: orgId,
        provider_id: providerId,
      },
    },
    nextAction: queryOrganizationProviders(orgId, tableOptions),
  };
};

export const addContactToOrganization = (orgId, contactId, tableOptions) => {
  const id = `${orgId}_c${contactId}`; // used to grab data for props
  return {
    type: API_ORGANIZATIONS_ADD_CONTACT,
    id,
    request: {
      url: '/ContactsToOrganizations',
      method: 'PUT',
      params: { opts: null },
      data: {
        organization_id: orgId,
        contact_id: contactId,
      },
    },
    nextAction: queryOrganizationContacts(orgId, tableOptions),
  };
};
