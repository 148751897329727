import React, { Component } from 'react';
import { Provider } from 'react-redux';
import {
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
} from '@mui/material/styles';
import { PersistGate } from 'redux-persist/integration/react';

import ConfigureStore from 'store/ConfigureStore';
import { App } from 'containers/App';
import { CacheBuster } from 'containers/CacheBuster';
import { UpgradeableProvider } from './UpgradeableContext';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: '#00A887', // old 07594d, neutral green
      accent: '#214E56', // forest green
      bright: '#02E39D', // lime green,
      dark: '#0C8B75',
      danger: '#F74242', // red
      warning: '#DBA800', // yellow
    },
    secondary: {
      main: '#2082F4', // neutral blue
      accent: '#91DDCA', // pastel green
      bright: '#70D9FE', // sky blue
    },
    tertiary: {
      main: '#EDEDED', // soft gray
      secondary: '#DADADA',
      heavyGrey: '#C6C6C6',
      mediumGrey: '#333',
      accent: '#3D3D3D', // darker gray
    },
    quaternary: {
      main: '#FBA733', // Gold
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#2082F4',
            fontWeight: 500,
          },
          color: '#777777',
          fontWeight: 400,
          '&:hover': {
            color: '#2082F4',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#2082F4',
          height: 2,
          marginBottom: 8,
        },
      },
    },
  },
  overrides: {
    MuiTableCell: {
      root: {
        padding: '10px 8px 10px 8px',
        '&:last-child': {
          paddingRight: '10px',
        },
      },
    },
  },
});
const { store, persistor, history } = ConfigureStore();

export class Root extends Component {
  render() {
    return (
      <UpgradeableProvider>
        <CacheBuster>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                  <App history={history} />
                </ThemeProvider>
              </StyledEngineProvider>
            </PersistGate>
          </Provider>
        </CacheBuster>
      </UpgradeableProvider>
    );
  }
}
