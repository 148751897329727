class ColorUtil {
  static OLD_COLORS = [
    '#008FFB',
    '#00E396',
    '#FEB019',
    '#FF4560',
    '#775DD0',
    '#3F51B5',
    '#03A9F4',
  ];
  static COLORS = [
    '#537DC9',
    '#02E39D',
    '#FFBF69',
    '#FF6B6B',
    '#9937BA',
    '#25C3E9',
    '#FFDD6A',
    '#FA9090',
    '#6C52EB',
    '#93F0FF',
    '#2680E9',
    '#02E39D',
  ];

  static getColors(count = this.COLORS.length) {
    if (count <= this.COLORS.length) {
      return this.COLORS.slice(0, count);
    }

    return this.COLORS;
  }

  static AffiliatedColor = '#2083F4';
  static NonAffiliatedColor = '#FF6178';
}

export { ColorUtil };
export default ColorUtil;
