import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';

import { withRouter } from 'utils';
import {
  DialogForm,
} from 'components';

const styles = () => ({
});

const schema = {
  type: 'object',
  required: ['name'],
  properties: {
    name: { type: 'string', title: 'Name' },
  },
};

const uiSchema = {
};

const NEW_TAG = {
};

class TagEditDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const {
      open,
      tagData,
      validate 
    } = this.props;

    return (
      <DialogForm
        title={tagData && tagData.id > 0 ? 'Edit Label' : 'Add Label'}
        open={open}
        formData={tagData || NEW_TAG}
        schema={schema}
        uiSchema={uiSchema}
        onClose={this.props.onClose}
        onFormSubmit={this.onSave}
        validate={validate}
      />
    );
  }

  onSave = (data) => {
    this.props.onSave(data);
  };
}

TagEditDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  tagData: PropTypes.object,
  validate: PropTypes.func
};

const styled = withStyles(styles)(TagEditDialog);
const routed = withRouter(styled);
export { routed as TagEditDialog };
