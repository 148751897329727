import { loadProvider } from './providersActions'

export const API_PROVIDER_TAGS_UPSERT = 'API_PROVIDER_TAGS_UPSERT';
export const API_PROVIDER_TAGS_TABLE = 'API_PROVIDER_TAGS_TABLE ';
export const API_PROVIDER_TAGS_LIST = 'API_PROVIDER_TAGS_LIST ';
export const API_PROVIDER_TAGS_LOAD = 'API_PROVIDER_TAGS_LOAD ';
export const API_PROVIDER_TAGS_GET = 'API_PROVIDER_TAGS_GET ';
export const API_PROVIDER_TAGS_DELETE = 'API_PROVIDER_TAGS_DELETE';
export const API_PROVIDER_TAGS_PROVIDERS = 'API_PROVIDER_TAGS_PROVIDERS';

export const queryProviderTags = (options, type = API_PROVIDER_TAGS_TABLE) => ({
  type,
  request: {
    url: '/ProviderTags',
    method: 'GET',
    params: { opts: options },
  },
});

export const listProviderTags = () => (queryProviderTags({
  order: [
    ['last_name', 'ASC'],
  ],
}, API_PROVIDER_TAGS_LIST));

// duplicate of providerActions.queryProviders, to store in different location
export const queryProviders = (options, type = API_PROVIDER_TAGS_PROVIDERS) => ({
  type,
  request: {
    url: '/Providers',
    method: 'GET',
    params: { opts: options },
  },
});


export const loadProviderTagsByTagId = (tagId) => {
  const include = [
    {
      association: 'provider',
      attributes: ['id', 'name'],
    },
    {
      association: 'location',
      include: [
        {
          attributes: ['id', 'name'],
          association: 'organization',
        },
      ],
    },
 
    {
      association: 'tag',
      attributes: ['id', 'name', 'namespace_id'],
      include: [
        {
          association: 'namespace',
          attributes: ['id', 'name'],
        },
      ],
   },
 
  ];

  return {
    type: API_PROVIDER_TAGS_LOAD,
    id: providerId,
    request: {
      method: 'GET',
      url: '/ProviderTags',
      params: {
        opts: {
          where: {
            tag_id: tagId,
          },
          include,
        },
      },
    },
  };
};

export const loadProviderTagsByProviderId = (providerId) => {
  const include = [
    {
      association: 'provider',
      attributes: ['id', 'name'],
    },
    {
      association: 'location',
      include: [
        {
          attributes: ['id', 'name'],
          association: 'organization',
        },
      ],
    },
 
    {
      association: 'tag',
      attributes: ['id', 'name', 'namespace_id'],
      include: [
        {
          association: 'namespace',
          attributes: ['id', 'name'],
        },
      ],
   },
 
  ];

  return {
    type: API_PROVIDER_TAGS_LOAD,
    id: providerId,
    request: {
      method: 'GET',
      url: '/ProviderTags',
      params: {
        opts: {
          where: {
            provider_id: providerId,
          },
          include,
        },
      },
    },
  };
};

export const upsertProviderTag = (data, nextAction) => {
  let id = 0;
  let options = {
    include: [],
  };

  return {
    type: API_PROVIDER_TAGS_UPSERT,
    id,
    request: {
      url: '/ProviderTags',
      method: 'PUT',
      params: { opts: options },
      data,
    },
    nextAction
  };
};

export const deleteProviderTag = (data, providerId, nextAction) => {
  return {
    type: API_PROVIDER_TAGS_DELETE,
    id: undefined,
    request: {
      url: '/ProviderTags',
      method: 'DELETE',
      data
    },
    nextAction: !!nextAction ? nextAction : loadProvider(providerId, data.selectedClient),
  };
};
