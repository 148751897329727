import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Typography,
} from '@mui/material';
import {
  LoadingView,
} from 'components';
import { withRouter } from 'utils';
import {
  authRenew,
} from 'store/actions/loginActions';

const styles = (theme) => ({
  container: {
    marginTop: 50,
    width: 350,
  },
  spinner: {
    margin: 40,
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  button: {
    width: 150,
  },
  cardContent: {
    marginTop: 0,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
});

class SAMLPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authRenewSent: false,
      authFinished: false,
    };
  }

  render() {
    const {
      classes,
      dispatch,
      user,
    } = this.props;

    // handle error
    if (this.props.router.params?.success !== 'error') {
      if (!this.state.authRenewSent) {
        const token = window.location.search.substring(1);
        localStorage.setItem('auth', token);
        localStorage.setItem('authTime', Date.now());
        this.setState({
          authRenewSent: true,
        }, () => {
          dispatch(authRenew(token, 'saml'));
        });
      }

      return (
        <div>
          <Card className={classes.container}>
            <LoadingView className={classes.spinner} />
          </Card>
        </div>
      );
    }

    if (!this.state.authFinished && user.meta.authenticated) {
      // i dont' think this ever runs...
      this.setState({
        authFinished: true,
      }, () => {
        this.props.router.navigate('/');
      });
    }

    return (
      <Card className={classes.container}>
        <CardHeader title='Sign In' />
        <CardContent className={classes.cardContent}>
          <Typography variant='body2' color='error'>Unable to sign in.</Typography>

          <div className={classes.buttonContainer}>
            <Button
              className={classes.button}
              type='submit'
              color='primary'
              margin='normal'
              variant='contained'
              onClick={() => {
                window.location.replace('/');
              }}
            >
              Try Again
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }
}

SAMLPage.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

const styled = withStyles(styles)(SAMLPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as SAMLPage };
