/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  FormControl,
  Select,
  MenuItem,
  Typography,
  InputLabel,
} from '@mui/material';
import {
  EditMarketsDialog,
} from 'components';
import {
  StoreUtil,
} from 'doctivity-shared/utils';
import {
  MarketsUtil,
} from 'utils';
import {
  listMarkets,
} from 'store/actions/marketsActions';
import {
  setMarket,
} from 'store/actions/appActions';
import LockIcon from '@mui/icons-material/Lock';

const styles = (theme) => ({
  filterDropDown: {
    width: '75%',
    marginRight: theme.spacing(),
  },
  lockIcon: {
    fontSize: 'inherit',
    verticalAlign: 'text-top',
    marginRight: 2,
    paddingRight: 6,
    color: '#999999',
  },
  parentServiceline: {
    borderTop: '1px solid #cccccc',
  },
  subServiceline: {
    fontSize: 14,
    paddingLeft: theme.spacing(4),
  },
});

class MarketsSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      marketsEditOpen: false,
    };
  }

  componentDidMount() {
    if (!this.props.markets || StoreUtil.needsLoadLongCache(this.props.markets)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (this.props.clientId !== prevProps.clientId
      || this.props.marketsClientId !== this.props.clientId) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(
      listMarkets(this.props.clientId),
    );
  }

  render() {
    const {
      classes,
    } = this.props;

    const selectedMarket = this.props.market;
    let selectedMarketId = 0;
    if (selectedMarket && selectedMarket.id) {
      selectedMarketId = selectedMarket.id;
    }

    const markets = StoreUtil.getData(this.props.markets) || [];

    return (
      <FormControl
        variant='outlined'
        className={classes.filterDropDown}
        key='filter-serviceline'
      >
        <InputLabel>Market</InputLabel>
        <Select
          value={selectedMarketId}
          onChange={this.onMarketChange}
          label='Market'
          renderValue={(value) => {
            const market = markets.find((market) => market.id === value);
            if (!market) {
              return <></>;
            }
            return (
              <Typography noWrap>
                {market.is_private && <LockIcon className={classes.lockIcon} />}
                {market.name}
              </Typography>
            );
          }}
        >
          {markets.map((market) => (
            <MenuItem value={market.id} key={`market-${market.id}`}>
              <Typography noWrap>
                <LockIcon
                  className={classes.lockIcon}
                  style={{
                    visibility: market.is_private ? 'visible' : 'hidden',
                  }}
                />
                {market.name}
              </Typography>
            </MenuItem>
          ))}
          {(!markets || !markets.length) && selectedMarket && (
            <MenuItem value={0} key='market'>
              <Typography noWrap>
                <LockIcon
                  className={classes.lockIcon}
                  style={{
                    visibility: selectedMarket.is_private
                      ? 'visible'
                      : 'hidden',
                  }}
                />
                {selectedMarket.name}
              </Typography>
            </MenuItem>
          )}
          <MenuItem value='custom' key='custom'>
            <em>Edit Markets</em>
          </MenuItem>
        </Select>
        {this.state.marketsEditOpen && (
          <EditMarketsDialog
            open={this.state.marketsEditOpen}
            onClose={this.closeMarketsEditDialog}
            onChange={this.onMarketsChanged}
          />
        )}
      </FormControl>
    );
  }

  getMarket(id = null) {
    const markets = StoreUtil.getData(this.props.markets);
    if (markets && markets.length > 0) {
      return markets.find((market) => market.id === id);
    }
    return null;
  }

  onMarketChange = (event) => {
    if (event.target.value === 'custom') {
      this.openMarketsEditDialog();
    } else {
      const marketId = parseInt(event.target.value, 10);
      if (!Number.isNaN(marketId)) {
        const market = this.getMarket(marketId);
        if (market) {
          if (this.props.onChange) {
            this.props.onChange(market);
          } else {
            this.props.dispatch(setMarket(marketId));
          }
        }
      }
    }
  };

  openMarketsEditDialog = () => {
    this.setState({ marketsEditOpen: true });
  };

  closeMarketsEditDialog = () => {
    this.setState({ marketsEditOpen: false });
  };

  onMarketsChanged = () => {
    // this.fetchData();
  };
}

MarketsSelect.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  clientId: PropTypes.number,
  marketsClientId: PropTypes.number,
  markets: PropTypes.object,
  market: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    marketsClientId: state.markets.clientId,
    markets: MarketsUtil.getMarkets(state),
    //market: MarketsUtil.getSelectedMarket(state),
  };
}

const styled = withStyles(styles)(MarketsSelect);
const connected = connect(mapStateToProps)(styled);
export { connected as MarketsSelect };
