import React from 'react';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Button,
  Typography,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from '@mui/material';
import Dropzone from 'react-dropzone';
import {
  LoadingView,
} from 'components';

const styles = (theme) => ({
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
  },
  formItem: {
    marginTop: theme.spacing(2),
  },
  columns: {
    fontSize: 11,
    fontWeight: 300,
  },
  dropzone: {
    marginTop: theme.spacing(2),
    border: '1px solid #aaaaaa',
    borderRadius: 5,
    padding: theme.spacing(2),
    textAlign: 'center',
  },
});

class UploadDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFile: null,
      selectedType: '',
      selectedColumns: '',
    };
  }

  render() {
    const {
      classes,
      open,
      uploadState,
      types,
    } = this.props;

    return (
      <Dialog
        open={open}
        onClose={this.props.onClose}
        aria-labelledby='form-dialog-title'
        fullWidth
        maxWidth='sm'
        TransitionProps={{
          onExited: this.handleUploadExited,
        }}
      >
        {uploadState !== 'LOADING' && <DialogTitle>Upload</DialogTitle>}
        <DialogContent>
          <form className={classes.form} noValidate autoComplete='off'>
            {
              uploadState === 'EDIT'
              && (
                <>
                  <FormControl key='type'>
                    <InputLabel>CSV Type</InputLabel>
                    { types
                      ? (
                        <Select
                          value={this.state.selectedType}
                          onChange={this.onTypeChange}
                          label='CSV Type'
                        >
                          {types.map((type) => (
                            <MenuItem value={type.id} key={type.id}>{type.name}</MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <LoadingView />
                      )}
                  </FormControl>
                  { this.state.selectedColumns && (
                    <FormControl key='columns' className={classes.formItem}>
                      <TextField
                        label='CSV Columns'
                        multiline
                        rows={3}
                        disabled
                        variant='outlined'
                        value={this.state.selectedColumns}
                        InputProps={{
                          inputProps: { className: classes.columns },
                        }}
                      />
                    </FormControl>
                  )}
                </>
              )
            }
            <Dropzone onDrop={this.selectFile} multiple={false}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  {
                    uploadState === 'EDIT'
                    && (
                      <div {...getRootProps()} className={classes.dropzone}>
                        <input {...getInputProps()} />
                        {
                          !this.state.selectedFile
                          && (
                            <Typography style={{ cursor: 'pointer' }}>
                              Drag and drop a file here, or click here to select a file.
                            </Typography>
                          )
                        }
                        {
                          this.state.selectedFile
                          && (
                            <Typography color='primary' style={{ cursor: 'pointer' }}>
                              {this.state.selectedFile.name}
                            </Typography>
                          )
                        }
                      </div>
                    )
                  }
                  {
                    (uploadState === 'LOADING')
                    && <LoadingView label={`${this.props.uploadProgress}% Uploaded`} />
                  }
                  {
                    uploadState === 'SUCCESS'
                    && (
                      <Typography>
                        Your uploaded was successfully started.
                        Follow the upload status for further details.
                      </Typography>
                    )
                  }
                  {
                    uploadState === 'ERROR'
                    && (
                      <Typography>
                        There was a problem uploading your file. Please try again.
                      </Typography>
                    )
                  }
                </section>
              )}
            </Dropzone>
          </form>
        </DialogContent>
        <DialogActions>
          {uploadState === 'EDIT'
            && (
              <>
                <Button onClick={this.props.onClose} color='primary'>
                  Cancel
                </Button>
                <Button variant='contained' onClick={this.startUpload} color='primary' disabled={!this.state.selectedFile || !this.state.selectedType}>
                  Upload
                </Button>
              </>
            )}
          {
            uploadState === 'SUCCESS'
            && (
              <Button onClick={this.props.onClose} color='primary'>
                Close
              </Button>
            )
          }
          {
            uploadState === 'ERROR'
            && (
              <Button onClick={this.props.onClose} color='primary'>
                Close
              </Button>
            )
          }
        </DialogActions>
      </Dialog>
    );
  }

  handleUploadExited = () => {
    this.setState({
      selectedFile: null,
    });
    this.props.onExited();
  };

  onTypeChange = (event) => {
    const {
      types,
    } = this.props;

    if (types) {
      const uploadType = types.find((type) => type.id === event.target.value);
      if (uploadType) {
        this.setState({
          selectedType: uploadType.id,
          selectedColumns: uploadType.columns ? Object.keys(uploadType.columns).join(', ') : uploadType.description,
        });
      } else {
        this.setState({
          selectedType: '',
          selectedColumns: '',
        });
      }
    }
  };

  selectFile = (files) => {
    this.setState({
      selectedFile: files[0],
    });
  };

  startUpload = () => {
    if (this.state.selectedType && this.state.selectedFile) {
      this.props.onUpload(
        this.state.selectedType,
        this.state.selectedFile,
      );
    }
  };
}

UploadDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  types: PropTypes.array,
  uploadState: PropTypes.string.isRequired,
  uploadProgress: PropTypes.number,
  open: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  onExited: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

const styled = withStyles(styles)(UploadDialog);
export { styled as UploadDialog };
