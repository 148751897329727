import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Typography, Card, CardContent, Icon } from '@mui/material';

import { DynamicTable } from 'components';

import { withRouter } from 'utils';
import { DateUtil, EncounterTypeUtil, StoreUtil } from 'doctivity-shared/utils';
import { queryProviderComments } from 'store/actions/providerCommentsActions';

const styles = (theme) => ({
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  card: {
    marginBottom: theme.spacing(3),
  },
});

const columns = [
  {
    label: '',
    key: 'encounter_type_id',
    format: (id, row, rowClasses) => (
      <Icon className={rowClasses.encounterIcon}>
        {EncounterTypeUtil.getIcon(id)}
      </Icon>
    ),
    style: { width: 20 },
    hideForMobile: true,
  },
  {
    label: 'Provider/Contact',
    key: 'providers',
    format: (providers, row, rowClasses) => {
      const names = [];
      if (providers && providers.length > 0) {
        providers.forEach((p) => {
          names.push(`${p.last_name}, ${p.first_name}`);
        });
      }
      if (row.contacts && row.contacts.length > 0) {
        row.contacts.forEach((c) => {
          names.push(`${c.last_name}, ${c.first_name}`);
        });
      }
      return <div className={rowClasses.lineBreaks}>{names.join('\n')}</div>;
    },
    sortable: false,
    style: { width: 180 },
  },
  {
    label: 'User',
    key: 'user',
    sortable: false,
    format: (user) => user && `${user.last_name}, ${user.first_name}`,
    style: { width: 150 },
    hideForMobile: true,
  },
  {
    label: 'Note',
    key: 'body',
    sortable: false,
    format: (body) => {
      let shortBody = body;
      if (body && body.length > 100) {
        shortBody = `${body.substring(0, 100)}...`;
      }
      return shortBody;
    },
  },
  {
    label: 'Date & Time',
    key: 'comment_date',
    type: 'date',
    format: DateUtil.formatDateTimeFromDB,
    style: { width: 150 },
    hideForMobile: true,
  },
  {
    label: 'Due Date',
    key: 'due_date',
    type: 'date',
    format: DateUtil.formatDateForUser,
    style: { width: 150 },
  },

];

class UnresolvedNotesTable extends React.Component {
  constructor(props) {
    super(props);

    const { user, app } = props;

    this.state = {
      query: {
        distinct: true, // this keeps counts accurate with multiple rows
        where: {
          needs_followup: true,
          client_id: app.selectedClient,
          $or: [{ is_resolved: false }, { is_resolved: null }],
        },
        include: [
          {
            association: 'user',
            attributes: ['id', 'first_name', 'last_name'],
            where: {
              id: user.id,
            },
          },
          {
            association: 'providers',
            attributes: ['id', 'first_name', 'last_name', 'middle_name'],
          },
          {
            association: 'contacts',
            attributes: ['id', 'first_name', 'last_name'],
          },
        ],
        order: [['due_date', 'ASC']],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    const { comments } = this.props;
    if (StoreUtil.needsLoadNoCache(comments)) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch } = this.props;
    const { query } = this.state;
    dispatch(queryProviderComments(query));
  }

  render() {
    const { classes, comments } = this.props;
    const { query } = this.state;

    return (
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.tableHeader}>
            <Typography component='h2' variant='h6' color='primary'>
              Unresolved Activity Notes
            </Typography>
          </div>
          <DynamicTable
            columns={columns}
            data={comments}
            showFilters={false}
            ContainerType={React.Fragment}
            query={query}
            onQueryChange={this.onQueryChange}
            onRowClick={this.onRowClick}
            rowClasses={classes}
            getRowHref={(row) => `/notes/${row.id}`}
          />
        </CardContent>
      </Card>
    );
  }
  onQueryChange = (query) => {
    this.setState({
      query,
    }, () => {
      this.fetchData();
    });
  }

  onRowClick = (row) => {
    if (row.id) {
      this.props.router.replace(`/notes/${row.id}`)
    } else if (row.providers && row.providers.length > 0) {
      this.props.router.replace(`/providers/${row.providers[0].id}/notes`);
    } else if (row.contacts && row.contacts.length > 0) {
      this.props.router.replace(`/contacts/${row.contacts[0].id}`);
    }
  };
}

UnresolvedNotesTable.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  comments: PropTypes.object.isRequired,
  app: PropTypes.object,
};

function mapStateToProps(state) {
  const { user, providerComments } = state;

  return {
    user,
    comments: StoreUtil.get(providerComments, StoreUtil.COMMON_TABLE),
    app: state.app,
  };
}

const styled = withStyles(styles)(UnresolvedNotesTable);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as UnresolvedNotesTable };
