import React from 'react';
import { PropTypes } from 'prop-types';

import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  container: {
    width: '95%',
    margin: '0 auto',
  },
  footer: {
    width: '100%',
    textAlign: 'right',
  },
  footerFont: {
    [theme.breakpoints.down('md')]: {
      fontSize: 8,
    },
  },
});

class Footer extends React.Component {
  render() {
    const {
      classes,
    } = this.props;

    const year = (new Date()).getFullYear();
    return (
      <div className={classes.container}>
        <footer className={classes.footer}>
          <Typography variant='caption' className={classes.footerFont}>
            &copy; Copyright
            {` ${year} `}
            All Rights Reserved by Doctivity Incorporated.
            {` v${process.env.PACKAGE_VERSION}`}
          </Typography>
        </footer>
      </div>
    );
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styled = withStyles(styles)(Footer);
export { styled as Footer };
