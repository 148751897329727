export const APP_SELECT_CLIENT = 'APP_SELECT_CLIENT';
export const APP_SET_DATE_RANGE = 'APP_SET_DATE_RANGE';
export const APP_SET_SERVICELINE = 'APP_SET_SERVICELINE';
export const APP_SET_PROVIDERTYPE = 'APP_SET_PROVIDERTYPE';
export const APP_SET_MARKET = 'APP_SET_MARKET';
export const APP_SET_PATIENT_TYPE = 'APP_SET_PATIENT_TYPE';

export const selectClient = (clientId) => ({
  type: APP_SELECT_CLIENT,
  payload: clientId,
});

export const setDateRange = (config) => ({
  type: APP_SET_DATE_RANGE,
  payload: config,
});

export const setServiceline = (id, parentId) => ({
  type: APP_SET_SERVICELINE,
  payload: {
    id,
    parent_id: parentId,
  },
});

export const setProviderType = (providerType) => ({
  type: APP_SET_PROVIDERTYPE,
  payload: providerType,
});

export const setMarket = (marketId) => ({
  type: APP_SET_MARKET,
  payload: marketId,
});

/**
 * @param {keyof PatientType} patientType
 */
export const setPatientType = (patientType) => ({
  type: APP_SET_PATIENT_TYPE,
  payload: patientType,
});
