import {
  logoutUser,
  authRenew,
} from 'store/actions/loginActions';
import {
  take,
  put,
  cancel,
  fork,
  delay,
} from 'redux-saga/effects';

const TIMEOUT_PERIOD_MILLISECONDS = 2 * 60 * 60 * 1000; // 2 hours
const RENEW_PERIOD_MILLISECONDS = 10 * 60 * 1000; // 10 minutes

let lastActivity = 0;

function* waitAndRenewToken() {
  while (true) {
    yield delay(RENEW_PERIOD_MILLISECONDS);

    const token = localStorage.getItem('auth');
    if (token) {
      if (!lastActivity || Date.now() - lastActivity < RENEW_PERIOD_MILLISECONDS) {
        const authTime = localStorage.getItem('authTime');
        let timeSince = 0;
        if (authTime) {
          timeSince = Date.now() - parseInt(authTime, 10);
        }
        if (!timeSince || timeSince > (RENEW_PERIOD_MILLISECONDS / 2)) {
          // we have had activity and nobody else has renewed for at least half renew time
          yield put(authRenew(token));
        } // else it was renewed by another tab
      } else {
        // no activity has occurred since last renew, don't do anything
      }
    } else {
      // no token, keep looping until there is one
    }
  }
}

function* waitAndSignOutUser() {
  let timeoutPeriod = TIMEOUT_PERIOD_MILLISECONDS;
  while (true) {
    yield delay(timeoutPeriod);
    // at this point we know nothing has happened for timeout period in this browser

    // is there activity within another tab?
    const authTime = localStorage.getItem('authTime');
    let timeSince = 0;
    if (authTime) {
      timeSince = Date.now() - parseInt(authTime, 10);
    }

    if (!timeSince || timeSince >= TIMEOUT_PERIOD_MILLISECONDS) {
      // no there is no one doing anything, log out
      yield put(logoutUser());
      return; // kick out of loop
    }

    // wait renew time and check again?
    if (timeSince) {
      timeoutPeriod = TIMEOUT_PERIOD_MILLISECONDS - timeSince;
    } else {
      timeoutPeriod = TIMEOUT_PERIOD_MILLISECONDS;
    }
  }
}

function* activityWatchLoop() {
  while (true) {
    // starts the task in the background
    const signOutTask = yield fork(waitAndSignOutUser);

    // wait for the user to do something that is a request not response and not automated
    yield take((action) => action && action.type && !action.automated && !action.response);
    lastActivity = Date.now();
    // cancel sign out
    yield cancel(signOutTask);
  }
}

export const autoSignOutSaga = [
  activityWatchLoop(),
  waitAndRenewToken(),
];
