import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import { styled } from '@mui/material/styles';
import { Card, Icon } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const styles = (theme) => ({
  pastDue: {
    color: theme.palette.primary.danger,
  },
  dueSoon: {
    color: theme.palette.primary.warning,
  },
  activityBell: {
    fontSize: '24px',
    lineHeight: '24px',
    width: '24px',
  },
  activityCount: {
    color: 'white',
  },
  activityBellContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  activityBellCounter: {
    position: 'absolute',
    top: 4,
    left: 5,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counterText: {
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 500,
  },
  tooltip: {
    ['&.MuiTooltip-tooltip']: {
      border: 'none',
      backgroundColor: 'red',
    },
  },
  tooltipText: {
    padding: theme.spacing(1),
  },
});

const StyledTooltipContent = ({ text, classes }) => (
  <Card>
    <div className={classes.tooltipText}>{text}</div>
  </Card>
);
StyledTooltipContent.propTypes = {
  text: PropTypes.string,
  classes: PropTypes.object,
};

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: 'none',
    backgroundColor: 'transparent',
  },
}));

const ActivityBell = ({ classes, dueDate }) => {
  const dueDateLabel = `${(isPastDue ? 'Overdue' : 'Due')}: ${moment(dueDate).format('MM/DD')}`;
  const count = Math.abs(moment().diff(dueDate, 'days'));
  const isPastDue = moment().isAfter(dueDate, 'day');
  return (
    <StyledTooltip
      className={classes.tooltip}
      title={<StyledTooltipContent classes={classes} text={dueDateLabel} />}
      placement='right-end'
    >
      <div className={classes.activityBellContainer}>
        <Icon
          className={[
            isPastDue ? classes.pastDue : classes.dueSoon,
            'activityBell',
          ].join(' ')}
        >
          notifications
        </Icon>
        <svg
          className={classes.activityBellCounter}
          width='14px'
          height='20px'
          viewBox='0 0 24 24'
        >
          <foreignObject x={0} y={0} width={24} height={24}>
            <div className={classes.counterText}>{count > 0 ? count : ''}</div>
          </foreignObject>
        </svg>
      </div>
    </StyledTooltip>
  );
};
ActivityBell.propTypes = {
  classes: PropTypes.object.isRequired,
  dueDate: PropTypes.instanceOf(Date),
};

const styledBell = withStyles(styles)(ActivityBell);
export { styledBell as ActivityBell };
