class StringUtil {
  static providerName(provider) {
    if (provider) {
      return `${provider.last_name}, ${provider.first_name}${
        provider.credential ? ` | ${provider.credential}` : ''
      }`;
    }
    return '';
  }

  static b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  static numberForChart(number) {
    if (number > 0) {
      if (number > 1000) {
        return `${(Math.round(number / 100) / 10).toLocaleString()}k`;
      }
      return number;
    }
    return undefined;
  }

  static toTitleCase(str) {
    if (!str || typeof str !== 'string') {
      return str ?? '';
    }
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }
}

export { StringUtil };
export default StringUtil;
