import { ReducerUtil } from 'doctivity-shared/utils';

export const dashboard = (state = {}, action = null) => {
  let { storeId } = action;
  if (!storeId && action.meta?.requestAction?.storeId) {
    storeId = action.meta.requestAction.storeId;
  }
  if (!storeId) return state;

  let result = ReducerUtil.reduceListGet(
    state,
    action,
    'DASHBOARD_FAVORITE_PROVIDERS',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }
  result = ReducerUtil.reduceListGet(
    state,
    action,
    'DASHBOARD_RECENT_PROVIDERS',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceListGet(
    state,
    action,
    'DASHBOARD_FAVORITE_PROVIDER_ANALYTICS_DX',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceListGet(
    state,
    action,
    'DASHBOARD_FAVORITE_PROVIDER_ANALYTICS_PX',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceTableGet(
    state,
    action,
    'DASHBOARD_UNRESOLVED_ACTIVITY_NOTES_TABLE',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }
  result = ReducerUtil.reduceTableGet(
    state,
    action,
    'DASHBOARD_RECENT_ACTIVITY_NOTES_TABLE',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceListGet(
    state,
    action,
    'DASHBOARD_FAVORITE_PROVIDER_ANALYTICS_TOP_SERVICELINES',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceListGet(
    state,
    action,
    'DASHBOARD_FAVORITE_LOCATIONS',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }
  result = ReducerUtil.reduceListGet(
    state,
    action,
    'DASHBOARD_RECENT_LOCATIONS',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceQuery(
    state,
    action,
    'DASHBOARD_FAVORITE_LOCATION_ANALYTICS_ACTIVITY_NOTES',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceQuery(
    state,
    action,
    'DASHBOARD_FAVORITE_LOCATION_ANALYTICS_CONNECTIONS',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceListGet(
    state,
    action,
    'DASHBOARD_FAVORITE_CONTACTS',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }
  result = ReducerUtil.reduceListGet(
    state,
    action,
    'DASHBOARD_RECENT_CONTACTS',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceQuery(
    state,
    action,
    'DASHBOARD_FAVORITE_CONTACT_ANALYTICS_ACTIVITYNOTES',
    storeId,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
