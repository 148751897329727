import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  Typography,
} from '@mui/material';

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import {
  DynamicTable,
  FindProviderDialog,
} from 'components';

import {
  addProviderToOrganization,
  queryOrganizationProviders,
} from 'store/actions/organizationsActions';

const styles = (theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Last Name',
    key: 'last_name',
    fulltext: true,
    style: { minWidth: 100 },
  },
  {
    label: 'First Name',
    key: 'first_name',
    fulltext: true,
    style: { minWidth: 100 },
  },
  {
    label: 'Address',
    key: 'location.address1',
    fulltext: true,
  },
  {
    label: 'City',
    key: 'location.city',
    fulltext: true,
  },
  {
    label: 'State',
    key: 'location.state',
    style: { width: 50 },
    filterExact: true,
    autoCapitalize: true,
  },
];

class OrganizationProviders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addProviderOpen: false,
      query: {
        where: {
        },
        order: [
          ['last_name', 'ASC'],
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const {
      providers,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(providers)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (!prevProps.organization || this.props.organization.id !== prevProps.organization.id) {
      this.fetchData();
    }
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  fetchData() {
    const {
      organization,
    } = this.props;

    if (organization && organization.id) {
      this.props.dispatch(
        queryOrganizationProviders(
          organization.id,
          this.state.query,
        ),
      );
    }
  }

  render() {
    const {
      classes,
      providers,
    } = this.props;

    const {
      query,
    } = this.state;

    return (
      <>
        <DynamicTable
          header={
            <div className={classes.headerContainer}>
              <Typography
                component='h2'
                variant='h6'
                color='primary'
                gutterBottom
              >
                Providers
              </Typography>
              <div className={classes.header} />
              {/*
                <Button
                  color='primary'
                  size='small'
                  onClick={this.onOpenAddProvider}
                >
                  <Icon className={classes.leftIcon}>add</Icon>
                  Add Provider
                </Button>
              */}
            </div>
          }
          showFilters={false}
          noDataMessage='No providers in this organization.'
          columns={columns}
          query={query}
          data={providers}
          onQueryChange={this.onQueryChange}
          getRowHref={(row) => `/providers/${row.id}`}
        />
        {this.state.addProviderOpen && (
          <FindProviderDialog
            clientId={this.props.clientId}
            open={this.state.addProviderOpen}
            onSelect={this.onProviderSelected}
          />
        )}
      </>
    );
  }

  /*
  onOpenAddProvider = () => {
    this.setState({
      addProviderOpen: true,
    });
  };

  onCloseAddProvider = () => {
    this.setState({
      addProviderOpen: false,
    });
  };
  */

  onProviderSelected = (provider) => {
    // cancel sends a null provider, just close
    this.onCloseAddProvider();
    if (provider || provider.id) {
      this.props.dispatch(
        addProviderToOrganization(
          this.props.organization.id,
          provider.id,
          this.state.query,
        ),
      );
    }
  };
}

OrganizationProviders.propTypes = {
  dispatch: PropTypes.func.isRequired,
  providers: PropTypes.object.isRequired,
  classes: PropTypes.object,
  organization: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    providers: StoreUtil.get(state.organizations, 'providers'),
  };
}

const styled = withStyles(styles)(OrganizationProviders);
const connected = connect(mapStateToProps)(styled);
export { connected as OrganizationProviders };
