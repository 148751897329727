import React from 'react';
import axios from 'axios';

import {
  ConfirmationDialog,
} from 'components';

import PropTypes from 'prop-types';

import UpgradeableContext from './UpgradeableContext';

class CacheBuster extends React.Component {
  static contextType = UpgradeableContext;

  constructor(props) {
    super(props);
    this.state = {
      confirmationOpen: false,
      loading: true,
      isLatestVersion: false,
      showExtraHelp: false,
    };
  }

  componentDidMount() {
    this.checkUpdate(); 
  }

  componentDidUpdate() {
    if (this.context.hasNavigated && this.context.shouldCheck) {
      this.checkUpdate();
    }
  }

  checkUpdate() {
     axios.get(`/meta.json?d=${Date.now()}`).then((response) => {
      this.context.didCheck();
      const latestVersion = response.data.version;
      // this comes from config/webpack.common.config.js
      const currentVersion = process.env.PACKAGE_VERSION;

      const shouldForceRefresh = this.semverGreaterThan(
        latestVersion,
        currentVersion
      );
      const url = new URL(window.location.href);
      if (url.searchParams.has('cachedate')) {
        this.setState({ showExtraHelp: true }); 
      }

      if (shouldForceRefresh) {
        this.setState({
          loading: false,
          isLatestVersion: false,
          confirmationOpen: true,
        });
      } else if (url.searchParams.has('cachedate')) {
        // url.searchParams.delete('cachedate');
        // window.location.replace(url.toString()); // this code is reverting me back to old version and putting me into a loop to install new version
        this.setState({
          loading: false,
          isLatestVersion: true,
        });
      }
    });

  }

  render() {
    const { showExtraHelp, confirmationOpen } = this.state;
    const extraHelp = <p>You may need to press Shift+Reload for your browser to download the new version.</p>;
    const help = <p>A newer version of Doctivity is available. Would you like to update and restart?</p>;
    const content = showExtraHelp ? <>{help}{extraHelp}</> : help;
    return (
      <>
        {confirmationOpen && (
          <ConfirmationDialog
            open={confirmationOpen}
            title='Upgrade Doctivity'
            content={content}
            onConfirm={this.onRefreshAndReload}
            onClose={this.onCloseConfirmation}
          />
        )}
        {this.props.children}
      </>
    );
  }

  semverGreaterThan(versionA, versionB) {
    const versionsA = versionA.split(/\./g);

    const versionsB = versionB.split(/\./g);
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift());

      const b = Number(versionsB.shift());
      // eslint-disable-next-line no-continue
      if (a === b) continue;
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b);
    }
    return false;
  }

  onRefreshAndReload = async () => {
    this.onCloseConfirmation();
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      const cacheKeys = await caches.keys();
      await Promise.all(
        cacheKeys.map(async (key) => {
          await caches.delete(key);
        })
      );
    }
    const url = new URL(window.location.href);
    url.searchParams.set('cachedate', (new Date()).getTime());
    window.location.replace(url.toString());
  };

  onCloseConfirmation = () => {
    this.setState({
      confirmationOpen: false,
    });
  };
}

CacheBuster.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
export { CacheBuster };
