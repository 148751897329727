import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  TitleTooltip,
  DynamicTable,
} from 'components';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  loadAnalytics,
} from 'store/actions/analyticsActions';

const styles = (theme) => ({
  card: {
    marginTop: theme.spacing(3),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  nothingFound: {
    fontWeight: 300,
    fontSize: 12,
  },
});

class TopDepartmentsReferredTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        limit: 50,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.providerId !== prevProps.providerId
      || this.props.clientId !== prevProps.clientId
      || this.props.app.dateRange !== prevProps.app.dateRange) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      dispatch,
      providerId,
      clientId,
    } = this.props;

    dispatch(loadAnalytics({
      type: 'TOP_DEPARTMENTS_REFERRED_BY_PROVIDER',
      filter: {
        provider_id: providerId,
        client_id: clientId,
      },
      opts: {
        ...this.state.query,
        from: this.props.app.dateRange.start,
        to: this.props.app.dateRange.end,
      },
    }));
  }

  render() {
    const {
      classes,
      data,
    } = this.props;

    const noDataMessage = 'No referrals made.';
    return (
      <Card className={classes.card}>
        <CardContent>
          <DynamicTable
            ContainerType={React.Fragment}
            header={(
              <TitleTooltip
                title='Top Departments Referred'
                tooltip='List of top departments referred by this provider.'
              />
            )}
            idKey='provider_id'
            columns={[
              {
                label: 'Department Referred',
                key: 'department',
                sortable: false,
              },
              {
                label: 'Referrals',
                key: 'count',
                style: { width: 100 },
              },
            ]}
            showFilters={false}
            query={this.state.query}
            data={data}
            noDataMessage={noDataMessage}
            onQueryChange={this.onQueryChange}
          />
        </CardContent>
      </Card>
    );
  }

  onQueryChange = (query) => {
    this.setState({
      query,
    }, () => {
      this.fetchData();
    });
  };
}

TopDepartmentsReferredTable.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  app: PropTypes.object,
  data: PropTypes.object,
  providerId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const {
    analytics,
    app,
  } = state;

  return {
    app,
    data: StoreUtil.get(analytics, 'TOP_DEPARTMENTS_REFERRED_BY_PROVIDER'),
  };
}

const styled = withStyles(styles)(TopDepartmentsReferredTable);
const connected = connect(mapStateToProps)(styled);
export { connected as TopDepartmentsReferredTable };
