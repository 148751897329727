import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { listTagNamespacesByClient } from 'store/actions/tagNamespacesActions';
import { StoreUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { PropTypes } from 'prop-types';
import { Tags } from 'tags';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, Card, CardContent, Typography } from '@mui/material';
import { EditOrganizationTags } from 'tags'

const styles = (theme) => ({
  editButton: {
    color: theme.palette.secondary.main,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderColor: theme.palette.tertiary.main,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  detailsCard: {
    paddingTop: '0px !important',
    position: 'relative',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    width: '100%',
   '&:last-child': {
      paddingBottom: 0,
      flex: 1,
    },
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  noTagsAvailable: {
    fontSize: '13px',
    fontStyle: 'italic',
    color: '#9E9E9E',
    fontFamily: 'Roboto',
    width: '100%',
    textAlign: 'center',
    paddingBottom: theme.spacing(2),
  },
  disclaimerContent: {
    color: '#9E9E9E',
    fontStyle: 'italic',
    fontSize: 13,
    paddingBottom: theme.spacing(2),
  },

});

function OrganizationTagList({ classes, organization, router }) {
  const tagNamespaces = useSelector((state) => StoreUtil.get(state.tagNamespaces, 'API_TAG_NAMESPACES_LIST_BY_CLIENT')?.data);
  const tagNamespacesAvailable = (tagNamespaces ?? []).length > 0;
  const clientId = useSelector((state) => state.app.selectedClient);
  const [open, setOpen] = useState(false)
  function editOrganization() {
    setOpen(true)
  }
  const { tags } = organization
  const tagsByNamespace = tags?.reduce((acc, val) => {
    if (acc[val.namespace.name] === undefined) {
      acc[val.namespace.name] = [];
    }
    acc[val.namespace.name].push(val);
    return acc;
  }, {});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listTagNamespacesByClient(clientId));
  }, [clientId]);

  return (
    <Card>
      <div className={classes.titleContainer}>
        <Typography component='h2' variant='h6' color='primary'>
          Organization Labels
        </Typography>
        <div className={classes.linksContainer}>
          <Button
            variant='text'
            color='primary'
            onClick={editOrganization}
            className={classes.editButton}
            disabled={tagNamespacesAvailable < 1}
          >
            <EditOutlinedIcon className={classes.leftIcon} />
            Edit
          </Button>
        </div>
      </div>
      <CardContent className={classes.detailsCard}>
        {Object.entries(tagsByNamespace)?.map(([namespace, tags]) => (
          <Tags tags={tags} key={namespace} onClick={(id) => {
            router.navigate(`/labels/${id}/organizations`);
          }} />
        ))}
        { tagNamespacesAvailable < 1 ? <span className={classes.noTagsAvailable}>
          <Typography className={classes.disclaimerContent}>
          This client does not currently support organization labelling.
          </Typography>
          <Typography className={classes.disclaimerContent}>
          Please contact Doctivity support to add label types and labels.
          </Typography>
        </span>:undefined}
        {Object.keys(tagsByNamespace).length === 0 &&<span className={classes.noTagsAvailable}>
          <Typography className={classes.disclaimerContent}>
            Labels allow you to group organizations. Click Edit to browse and add labels.
            </Typography>
          <Typography className={classes.disclaimerContent}>
Contact a Doctivity admin if you’d like a new Label Type or Labels added to your client.
            </Typography></span>}
      </CardContent>
      { open && (
        <EditOrganizationTags open={open} organization={organization} setOpen={(v) => setOpen(v)} />
      )}
    </Card>
  );
}

OrganizationTagList.propTypes = {
  classes: PropTypes.object,
  organization: PropTypes.object,
  router: PropTypes.object.isRequired,
};
const styled = withStyles(styles)(OrganizationTagList);
const routed = withRouter(styled);
export { routed as OrganizationTagList };
