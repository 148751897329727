import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  TableWithDialog,
  LoadingView,
} from 'components';

import {
  queryLoginSettings,
  upsertLoginSettings,
} from 'store/actions/loginSettingsActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
});

const schema = {
  type: 'object',
  required: ['email_domain'],
  properties: {
    email_domain: { type: 'string', title: 'Email Domain' },
    failed_login_attempts: { type: 'number', title: 'Failed Login Attempts' },
    failed_login_timeout: { type: 'number', title: 'Failed Login Timeout' },
    two_factor_bypass_time: { type: 'number', title: 'Two Factor Bypass Time' },
    password_length: { type: 'number', title: 'Minimum Password Length', default: 8, minimum: 8 },
  }
};

const uiSchema = {
  failed_login_attempts: {
    classNames: 'two-column-field',
  },
  failed_login_timeout: {
    classNames: 'two-column-field',
  },
  two_factor_bypass_time: {
    classNames: 'two-column-field',
  },
  password_length: {
    classNames: 'two-column-field',
  },
};

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Email Domain (not including "@")',
    key: 'email_domain',
  },
  {
    label: 'Failed Login Attempts',
    key: 'failed_login_attempts',
  },
  {
    label: 'Failed Login Timeout (seconds)',
    key: 'failed_login_timeout',
  },
  {
    label: 'Two Factor Bypass Time (hours)',
    key: 'two_factor_bypass_time',
  },
  {
    label: 'Minimum Password Length',
    key: 'password_length',
  },
];

class LoginSettingsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        order: [
          ['email_domain', 'ASC'],
        ],
        limit: 25,
        offset: 0,
      },
    };

    this.onSave = this.onSave.bind(this);
    this.onQueryChange = this.onQueryChange.bind(this);
  }

  componentDidMount() {
    const {
      loginSettings: loginSettings,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(loginSettings)) {
      this.fetchData();
    }

  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (this.props.editing !== prevProps.editing) {
      // if so and it is newr than the list, update list
      if (StoreUtil.hasSavedSinceLoad(this.props.editing, this.props.loginSettings)) {
        this.fetchData();
      }
    }

  }

  onSave(data) {
    const {
      dispatch,
    } = this.props;

    dispatch(upsertLoginSettings(data));
  }

  onQueryChange(query) {
    this.setState({ query }, this.fetchData.bind(this));
  }

  fetchData() {
    const {
      dispatch,
    } = this.props;

    const {
      query,
    } = this.state;

    dispatch(queryLoginSettings(query));

  }

  render() {
    const {
      loginSettings: loginSettings,
      editing,
      classes,
    } = this.props;

    const {
      query,
    } = this.state;

    // is a user being edited now?
    if (StoreUtil.isSaving(editing)) {
      return (<LoadingView />);
    }

    return (
      <>
        <Helmet defer={false}>
          <title>Login Settings</title>
        </Helmet>
        <div className={classes.container}>
          <TableWithDialog
            title='Login Settings'
            dialogTitle='Login Setting'
            columns={columns}
            dialogSchema={schema}
            dialogUISchema={uiSchema}
            query={query}
            data={loginSettings}
            onQueryChange={this.onQueryChange}
            onSave={this.onSave}
            showFilters={false}
          />
        </div>
      </>
    );
  }
}

LoginSettingsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  loginSettings: PropTypes.object.isRequired,
  editing: PropTypes.object,
};

function mapStateToProps(state) {
  const {
    loginSettings: loginSettings,
  } = state;

  return {
    loginSettings: StoreUtil.get(loginSettings, StoreUtil.COMMON_TABLE),
    editing: StoreUtil.get(loginSettings, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(LoginSettingsPage);
const connected = connect(mapStateToProps)(styled);
export { connected as LoginSettingsPage };
