export const API_LOCATIONS_TABLE = 'API_LOCATIONS_TABLE';
export const API_LOCATIONS_LIST = 'API_LOCATIONS_LIST';
export const API_LOCATIONS_UPSERT = 'API_LOCATIONS_UPSERT';
export const API_LOCATIONS_LOAD = 'API_LOCATIONS_LOAD';
export const API_LOCATIONS_TABLE_PROVIDERS = 'API_LOCATIONS_TABLE_PROVIDERS';
export const API_LOCATIONS_TABLE_CONTACTS = 'API_LOCATIONS_TABLE_CONTACTS';
export const API_LOCATIONS_TABLE_PROVIDER_COMMENTS =
  'API_LOCATIONS_TABLE_PROVIDER_COMMENTS';

import { queryProviderComments } from './providerCommentsActions';
import { queryProviders } from './providersActions';
import { queryContacts } from './contactsActions';

export const queryLocations = (options, type = API_LOCATIONS_TABLE) => ({
  type,
  request: {
    url: '/Locations',
    method: 'GET',
    params: { opts: options },
  },
});

export const listLocations = () =>
  queryLocations(
    {
      where: {},
      order: [['name', 'ASC']],
    },
    API_LOCATIONS_LIST
  );

export const loadLocation = (id) => ({
  type: API_LOCATIONS_LOAD,
  id,
  request: {
    url: `/Locations/${id}`,
    method: 'GET',
    params: {
      opts: {
        include: [
          {
            association: 'organization',
            attributes: ['id', 'name', 'primary_location_id'],
          },
        ],
      },
    },
  },
});

export const upsertLocation = (data) => {
  let options = null;
  let id = 0;

  if (data.id && data.id > 0) {
    id = data.id;
    options = {
      where: { id },
    };
  }

  if (data.organizations) {
    if (!options) options = {};
    options.include = [{ model: 'Organizations', as: 'organizations' }];
  }

  return {
    type: API_LOCATIONS_UPSERT,
    id,
    request: {
      url: '/Locations',
      method: 'PUT',
      params: { opts: options },
      data,
    },
  };
};

/**
 *
 * @param {*} locationId
 * @returns count property which contains the total number of providers at that location
 */
export const getProvidersCount = (locationId) => {
  return queryProviders({
    where: {
      location_id: locationId,
    },
    // All we need is the count property of findAndCount all,
    // so include the bare minimum to get that
    limit: 1,
    offset: 0,
  });
};

/**
 *
 * @param {*} locationId
 * @returns count property which contains the total number of contacts at that location
 */
export const getContactsCount = (locationId) => {
  return queryContacts({
    where: {
      location_id: locationId,
    },
    // All we need is the count property of findAndCount all,
    // so include the bare minimum to get that
    limit: 1,
    offset: 0,
  });
};

export const queryLocationProviders = (locationId, options) => {
  if (options.include && options.include[0].association === 'location') {
    options.include[0].where.id = locationId;
  } else {
    options.include = [
      {
        association: 'location',
        where: {
          id: locationId,
        },
        required: true,
      },
    ];
  }

  return {
    type: API_LOCATIONS_TABLE_PROVIDERS,
    request: {
      url: '/Providers',
      method: 'GET',
      params: {
        opts: {
          ...options,
        },
      },
    },
  };
};

export const queryLocationContacts = (locationId, clientId, options) => {
  if (!options.where) {
    options.where = {};
  }
  options.where.client_id = clientId;

  return {
    type: API_LOCATIONS_TABLE_CONTACTS,
    locationId,
    clientId,
    request: {
      url: '/Contacts',
      method: 'GET',
      params: {
        opts: {
          ...options,
          include: [
            {
              association: 'location',
              attributes: ['id'],
              where: {
                id: locationId,
              },
              required: true,
            },
          ],
        },
      },
    },
  };
};

export const queryLocationProviderComments = (
  clientId,
  locationId,
  options
) => {
  return queryProviderComments(
    {
      where: {
        client_id: {
          $eq: clientId,
        },
      },
      order: [['comment_date', 'DESC']],
      include: [
        {
          association: 'user',
          attributes: ['id', 'first_name', 'last_name'],
        },
        {
          association: 'providers',
          attributes: ['id', 'first_name', 'last_name', 'middle_name'],
        },
        {
          association: 'contacts',
          attributes: ['id', 'first_name', 'last_name'],
        },
        {
          association: 'location',
          attributes: [
            'id',
            'name',
            'address1',
            'address2',
            'city',
            'state',
            'postal_code',
          ],
          where: {
            id: locationId,
          },
          required: true,
        },
      ],
      ...options,
    },
    API_LOCATIONS_TABLE_PROVIDER_COMMENTS
  );
};