import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import fileDownload from 'js-file-download';

import { ConfirmationDialog } from 'components';
import axiosInstance from 'utils/axiosUtil';

const styles = () => ({
  textInput: {
    width: '100%',
  },
});

class DownloadAffiliateDataDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      confirmCloseOpen: false,
    };
  }

  render() {
    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            Download Affiliate Data for {this.props.clientName}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
                Download a csv of current Affiliate Providers and Organizations
                for this client, and if you want to delete Affiliate Data
                <br /><br />
                Note: Downloading will offer a next step to delete data.
                <br /><br/>
                <Button variant='text' onClick={this.onDownloadOrganizationClick}>Download Organizations</Button>
                <br />
                <Button variant='text' onClick={this.onDownloadProvidersClick}>Download Providers</Button>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.onCancel || this.props.onClose}
              color='primary'
            >
              Cancel
            </Button>
            <Button onClick={this.onDeleteClick} color='primary' autoFocus>
              Delete Data
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmationDialog
          open={this.state.confirmCloseOpen}
          title={`Deleting ALL Affiliate Data for ${this.props.clientName}`}
          content={(
              <>
                Clicking DELETE will delete ALL Affiliate data for Providers and Organizations fot this client. This cannot be undone.
                <br /><br />
                You will have the only copy of the data as it is now.
              </>
            
          )}
          confirmLabel='Delete'
          onClose={this.onCloseConfirmCancel}
          onCancel={this.onCloseConfirmCancel}
          onConfirm={this.onConfirmDelete}
        />
      </>
    );
  }

  onDownloadOrganizationClick = () => {
    axiosInstance.get('/AffiliatedOrganizationsDownload/' + this.props.clientId).then((result) => {
      fileDownload(result.data, 'affiliated_orgs_export.csv');
    });
  }

  onDownloadProvidersClick = () => {
    axiosInstance.get('/AffiliatedProvidersDownload/' + this.props.clientId).then((result) => {
      fileDownload(result.data, 'affiliated_providers_export.csv');
    });
  }

  onDeleteClick = () => {
    this.setState({
      confirmCloseOpen: true
    });
  }

  onCloseConfirmCancel = () => {
    this.setState({
      confirmCloseOpen: false
    }, () => {
      if (this.props.onClose) {
        this.props.onClose();
      }
    });
  };

  onConfirmDelete = () => {
    axiosInstance.delete('/AffiliatedOrganizationsAndProviders', {
      data: {
        clientId: this.props.clientId
      }
    }).then(() => {
      this.setState({
        confirmCloseOpen: false
      }, () => {
        if (this.props.onClose) {
          this.props.onClose();
        }
      });
    });
  }
}

DownloadAffiliateDataDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  clientId: PropTypes.number,
  clientName: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  confirmLabel: PropTypes.string,
  onCancel: PropTypes.func,
  cancelLabel: PropTypes.string,
};

function mapStateToProps() {
  return {
  };
}

const styled = withStyles(styles)(DownloadAffiliateDataDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as DownloadAffiliateDataDialog };
