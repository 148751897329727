import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'utils';
import axiosInstance from 'utils/axiosUtil';
import {
  Card,
  CardContent,
  Button,
  Icon,
  Snackbar,
  SnackbarContent,
  Typography,
  Grid,
} from '@mui/material';

import {
  StoreUtil,
  DateUtil,
  PhoneUtil,
} from 'doctivity-shared/utils';

import {
  UserEditDialog,
  LoadingView,
  OverlineLabel,
  UnderlineData,
  ProviderReportsTable,
} from 'components';

import {
  loadUser,
  upsertUser,
} from 'store/actions/usersActions';

import {
  listClients,
} from 'store/actions/clientsActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  card: {
    marginTop: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    paddingBottom: 51,
    backgroundColor: theme.palette.primary.accent,
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: '2',
    WebkitBoxOrient: 'vertical',
  },
  vertical: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  spacer: {
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
});

class UserPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isUserDialogOpen: false,
      // Used to show snackbars for any kind of background action. eg Resend Invite.
      action: null,
      actionResult: null,
    };
  }

  componentDidMount() {
    const {
      dispatch,
      clients,
    } = this.props;

    this.fetchUser();

    if (StoreUtil.needsLoadLongCache(clients)) {
      dispatch(listClients());
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.router.params.id !== prevProps.router.params.id) {
      this.fetchUser();
    }

    // anytime a user has been edited, update the list
    if (this.props.editingUser !== prevProps.editingUser) {
      // is the list data older than the edited data?
      if (StoreUtil.hasSavedSinceLoad(this.props.editingUser, this.props.user)) {
        this.fetchUser();
      }
    }
  }

  fetchUser() {
    const {
      dispatch,
    } = this.props;

    const id = this.props.router.params.id;
    dispatch(loadUser(id));
  }

  render() {
    const {
      user,
      clients,
      editingUser,
      classes,
    } = this.props;

    const {
      action,
      isUserDialogOpen,
    } = this.state;

    // is a user being edited now?
    const userData = StoreUtil.getData(user);
    if (StoreUtil.isLoading(user) || userData == null || StoreUtil.isSaving(editingUser)) {
      return (<LoadingView />);
    }

    let clientStr = '';
    let clientLabel = 'Client';
    if (userData.is_admin) {
      clientStr = 'Administrator (All Clients)';
    } else if (userData.clients && userData.clients.length > 0) {
      if (userData.clients.length > 1) {
        clientLabel = 'Clients';
      }
      clientStr = userData.clients.map((c) => c.name).join(', ');
    }

    return (
      <>
        <div className={classes.header}>
          <Typography className={classes.headerTitle}>
            {`${userData.last_name}, ${userData.first_name}`}
          </Typography>
          <div className={classes.spacer} />
        </div>
        <div className={classes.container}>
          <Card className={classes.card}>
            <CardContent>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <OverlineLabel>{clientLabel}</OverlineLabel>
                  <UnderlineData>{clientStr}</UnderlineData>

                  <OverlineLabel>Email</OverlineLabel>
                  <UnderlineData>{userData.email || ''}</UnderlineData>

                  <OverlineLabel>Phone</OverlineLabel>
                  <UnderlineData>
                    {userData.phone
                      ? PhoneUtil.formatNumber(userData.phone)
                      : ''}
                  </UnderlineData>
                </Grid>
                <Grid item xs={12} md={4}>
                  <OverlineLabel>Last Login</OverlineLabel>
                  <UnderlineData>
                    {userData.last_login
                      ? DateUtil.formatFromNow(userData.last_login)
                      : ''}
                  </UnderlineData>

                  <OverlineLabel>Active / Enabled</OverlineLabel>
                  <UnderlineData>
                    {userData.is_active ? 'Yes' : 'No'}
                  </UnderlineData>
                </Grid>
                <Grid item xs={12} md={4} className={classes.vertical}>
                  <Button
                    variant='text'
                    color='primary'
                    onClick={this.onEditClick}
                  >
                    <Icon className={classes.leftIcon}>edit</Icon>
                    Edit User
                  </Button>
                  {userData.last_login && (
                    <>
                      <Button
                        variant='text'
                        color='primary'
                        disabled={action === 'password_reset'}
                        onClick={() => {
                          this.sendPasswordReset();
                        }}
                      >
                        <Icon className={classes.leftIcon}>send</Icon>
                        Send Password Reset
                      </Button>
                    </>
                  )}
                  {!userData.last_login && (
                    <>
                    <Button
                      variant='text'
                      color='primary'
                      disabled={action === 'send_invite'}
                      onClick={() => {
                        this.resendInvite();
                      }}
                    >
                      <Icon className={classes.leftIcon}>send</Icon>
                      Resend Invite Email
                    </Button>
                    </>
                  )}
                </Grid>
              </Grid>
            </CardContent>
          </Card>

          {user.is_admin && <ProviderReportsTable user={userData} />}

          <UserEditDialog
            open={isUserDialogOpen}
            clients={StoreUtil.getData(clients)}
            userData={userData}
            onClose={this.closeUserDialog}
            onSave={this.onSave}
          />

          {this.state.actionResult && (
            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={!!this.state.actionResult}
              autoHideDuration={6000}
              onClose={this.handleCloseActionResult}
            >
              <SnackbarContent
                message={<span>{this.state.actionResult.message}</span>}
              />
            </Snackbar>
          )}
        </div>
      </>
    );
  }

  onSave = (data) => {
    const {
      dispatch,
    } = this.props;

    dispatch(upsertUser(data));

    this.setState({
      isUserDialogOpen: false,
    });
  };

  onEditClick = () => {
    this.setState({
      isUserDialogOpen: true,
    });
  };

  closeUserDialog = () => {
    this.setState({
      isUserDialogOpen: false,
    });
  };

  sendPasswordReset = () => {
    const email = StoreUtil.getData(this.props.user).email;

    this.setState({
      action: 'password_reset',
    });

    axiosInstance.put('/auth/recovery', {
      email,
    })
      .then(() => {
        this.setState({
          action: null,
          actionResult: {
            type: 'success',
            message: 'Password reset has been sent successfully.',
          },
        });
      })
      .catch((error) => {
        this.setState({
          action: null,
          actionResult: {
            type: 'error',
            message: error.response.data.message,
          },
        });
      });
  };

  resendInvite = () => {
    const userId = this.props.router.params.id;

    this.setState({
      action: 'send_invite',
    });
    axiosInstance.put(`/Users/${userId}/invite`)
      .then(() => {
        this.setState({
          action: null,
          actionResult: {
            type: 'success',
            message: 'Invite resent.',
          },
        });
      })
      .catch((error) => {
        this.setState({
          action: null,
          actionResult: {
            type: 'error',
            message: error.response.data.message,
          },
        });
      });
  };

  handleCloseActionResult = () => {
    this.setState({
      actionResult: null,
    });
  };
}

UserPage.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clients: PropTypes.object,
  editingUser: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object,
};

function mapStateToProps(state, props) {
  const {
    users,
    clients,
  } = state;

  return {
    clients: StoreUtil.get(clients, StoreUtil.COMMON_LIST),
    editingUser: StoreUtil.get(users, StoreUtil.COMMON_EDIT_ITEM),
    user: StoreUtil.get(users, StoreUtil.COMMON_ITEM, props.params && props.params.id),
  };
}

const styled = withStyles(styles)(UserPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as UserPage };
