import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  Grid,
} from '@mui/material';

import {
  VisitsTabs,
  DepartmentSelect,
  ReferringOrganizationSelect,
  MonthlyVisitsBarChart,
  TopReferralsInTable,
  PayerPieChart,
  VisitTypePieChart,
  ReferringAffiliationPieChart,
  DateRangeSelector,
} from 'components';

import { MarketsUtil } from 'utils';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dropdowns: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
});

class VisitsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      department: null,
      referringOrganization: null,
      query: {
        where: {},
        include: [
          {
            association: 'taxonomy',
          },
        ],
        order: [['id', 'DESC']],
        limit: 25,
        offset: 0,
      },
    };
  }

  render() {
    const { classes } = this.props;

    return (
      <>
        <VisitsTabs tab='visits' />

        <div className={classes.container}>
          <div className={classes.filters}>
            <div className={classes.dropdowns}>
              <DepartmentSelect
                value={this.state.department}
                onChange={this.onDepartmentChange}
              />

              <ReferringOrganizationSelect
                value={this.state.referringOrganization}
                onChange={this.onReferringOrganizationChange}
              />
            </div>

            <DateRangeSelector />
          </div>

          <MonthlyVisitsBarChart
            department={this.state.department}
            referringOrganization={this.state.referringOrganization}
            clientId={this.props.clientId}
          />

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TopReferralsInTable
                clientId={this.props.clientId}
                department={this.state.department}
                referringOrganization={this.state.referringOrganization}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PayerPieChart
                clientId={this.props.clientId}
                department={this.state.department}
                referringOrganization={this.state.referringOrganization}
              />
              <VisitTypePieChart
                clientId={this.props.clientId}
                department={this.state.department}
                referringOrganization={this.state.referringOrganization}
              />
              <ReferringAffiliationPieChart
                clientId={this.props.clientId}
                department={this.state.department}
                referringOrganization={this.state.referringOrganization}
              />
            </Grid>
            <div style={{ minHeight: 200 }}>&nbsp;</div>
          </Grid>
        </div>
      </>
    );
  }

  onDepartmentChange = (newDepartment) => {
    this.setState({
      department: newDepartment,
    });
  };

  onReferringOrganizationChange = (newOrg) => {
    this.setState({
      referringOrganization: newOrg,
    });
  };
}

VisitsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  clientId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    market: MarketsUtil.getSelectedMarket(state),
  };
}

const styled = withStyles(styles)(VisitsPage);
const connected = connect(mapStateToProps)(styled);
export { connected as VisitsPage };
