import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'utils';

const styles = () => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    color: '#fff',
  },
  icon: {
    fontSize: 20,
  },
  iconNoText: {
    fontSize: 20,
  },
});

class BreadcrumbItem extends Component {
  render() {
    const { classes, label, icon, path } = this.props;

    let iconCls = classes.icon;
    if (!label) {
      iconCls = classes.iconNoText;
    }

    return (
      <Button
        color='inherit'
        className={classes.button}
        size='small'
        id={path}
        onClick={this.onClick}
      >
        {icon && <Icon className={iconCls}>{icon}</Icon>}
        {label}
      </Button>
    );
  }

  onClick = () => {
    const { router, onNavigate, path } = this.props;
    router.navigate(path, { state: { fromBreadcrumb: true } });
    onNavigate?.();
  };
}

BreadcrumbItem.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  icon: PropTypes.string,
  label: PropTypes.string,
  path: PropTypes.string.isRequired,
  onNavigate: PropTypes.func,
};

const styled = withStyles(styles)(BreadcrumbItem);
const routed = withRouter(styled);
export { routed as BreadcrumbItem };
