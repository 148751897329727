import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_CONTACTS_TABLE,
  API_CONTACTS_LIST,
  API_CONTACTS_UPSERT,
  API_CONTACTS_LOAD,
  API_CONTACTS_DELETE,
} from 'store/actions/contactsActions';

export const contacts = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_CONTACTS_TABLE,
    API_CONTACTS_LIST,
    API_CONTACTS_UPSERT,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceSingleGet(state, action, API_CONTACTS_LOAD);
  if (result.actionConsumed) {
    return result.state;
  }
  result = ReducerUtil.reduceUpsert(state, action, API_CONTACTS_DELETE);
  if (result.actionConsumed) {
    // delete into list
    return result.state;
  }

  return state;
};
