import React from 'react';
import PropTypes from 'prop-types';

function TextareaWidget(props) {
  const { BaseInput } = props.registry.widgets;
  return <BaseInput multiline {...props} variant='outlined' />;
}

TextareaWidget.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  registry: PropTypes.object,
};

export default TextareaWidget;
export { TextareaWidget };
