import React, { useState } from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

export function MaskedPhoneNumberExtensionFormat(props) {
  return MaskedPhoneNumberFormat({ allowExtension: true, ...props });
}

export default function MaskedPhoneNumberFormat(props) {
  const { inputRef, onChange, allowExtension, ...other } = props;

  const [longFormat, setLongFormat] = useState(
    allowExtension && props.value?.length > 10
  );

  const changeFormatIfNeeded = (newValue) => {
    if (allowExtension) {
      let includeExtension = newValue?.length >= 10;
      if (!longFormat && includeExtension) {
        setLongFormat(true);
      } else if (longFormat && !includeExtension) {
        setLongFormat(false);
      }
    }
  };

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onClick={() => {
        changeFormatIfNeeded(props.value);
      }}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });

        changeFormatIfNeeded(values.value);
      }}
      format={longFormat ? '(###) ###-#### ext ######' : '(###) ###-#### '}
      mask=' '
    />
  );
}

MaskedPhoneNumberFormat.propTypes = {
  inputRef: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  allowExtension: PropTypes.bool,
  value: PropTypes.string,
};
