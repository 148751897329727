import { StoreUtil } from 'doctivity-shared/utils';

const DEFAULT_MARKET = {
  name: 'Pennsylvania',
  states: [
    {
      abbr: 'PA',
    },
  ],
};

let LAST_MARKET = undefined;

class MarketsUtil {
  static getMarkets(state) {
    if (state.markets.clientId === state.app.selectedClient) {
      const markets = StoreUtil.get(state.markets, StoreUtil.COMMON_LIST);
      if (StoreUtil.isLoaded(markets)) {
        const data = StoreUtil.getData(markets);
        if (data && data.length === 0) {
          return {
            ...markets,
            data: [MarketsUtil.getDefaultMarket()]
          };
        }
      }
      return markets;
    }
    return undefined;
  }

  static getMarket(state, id) {
    const markets = MarketsUtil.getMarkets(state);
    if (StoreUtil.isLoaded(markets)) {
      const marketsData = StoreUtil.getData(markets);
      if (marketsData && marketsData.length > 0) {
        return marketsData.find((market) => market.id === id);
      }
    }
    return null;
  }

  static getSelectedMarket(state) {
    let market;
    if (state.app.market) {
      market = MarketsUtil.getMarket(state, state.app.market);
    }

    if (!market) {
      const markets = MarketsUtil.getMarkets(state);
      if (StoreUtil.isLoaded(markets)) {
        const marketsData = StoreUtil.getData(markets);
        if (marketsData && marketsData.length > 0) {
          market = marketsData[0];
        }
      } else if (markets && StoreUtil.isLoading(markets) && LAST_MARKET) {
        market = LAST_MARKET;
      } else {
        return null; // markets are loading, wait for them
      }
    }

    if (!market) {
      market = MarketsUtil.getDefaultMarket();
    }
    LAST_MARKET = market;
    return market;
  }

  static getDefaultMarket() {
    // in future this could be based on client
    return DEFAULT_MARKET;
  }
}
export { MarketsUtil };
export default MarketsUtil;
