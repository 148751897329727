import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'utils';
import { Typography, Button } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { FindLocationDialog } from 'locations';

const styles = (theme) => ({
  container: {
    display: 'flex',
  },
  address: {
    textAlign: 'left',
    padding: 0,
    color: theme.palette.secondary.main,
    fontWeight: 400,
    fontSize: 13,
  },
  locationName: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  leftIcon: {
    marginRight: 4,
    marginLeft: -4,
    color: theme.palette.secondary.main,
  },
  leftIconButton: {
    alignSelf: 'end',
    justifySelf: 'end',
  },
});

class LocationView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locationFindOpen: false,
    };
  }

  render() {
    const { classes, location } = this.props;

    let currentLocation;
    if (!location || !location.id) {
      currentLocation = <Typography>Location not set</Typography>;
    } else {
      currentLocation = (
        <>
          {location && (
            <div className={classes.locationName}>
              <Button
                onClick={() => {
                  this.props.router.navigate(`/locations/${location.id}`);
                }}
                variant='text'
                className={classes.address}
              >
                <div>
                  {location.name && <div>{location.name}</div>}
                  {location.address1 && <div>{location.address1}</div>}
                  {location.address2 && <div>{location.address2}</div>}
                  <div>
                    {location.city}, {location.state}, {location.postal_code}
                  </div>
                </div>
              </Button>
            </div>
          )}
        </>
      );
    }

    return (
      <div className={classes.container}>
        {currentLocation}
        {this.props.onChange && (
          <Button
            onClick={this.onChangeLocation}
            className={classes.leftIconButton}
          >
            <EditOutlinedIcon className={classes.leftIcon} />
          </Button>
        )}
        {this.state.locationFindOpen && (
          <FindLocationDialog
            location={this.props.location}
            onClose={this.closeFindDialog}
            onSelect={this.onLocationSelected}
            open={this.state.locationFindOpen}
          />
        )}
      </div>
    );
  }

  closeFindDialog = () => {
    this.setState({
      locationFindOpen: false,
    });
  };

  onChangeLocation = () => {
    this.setState({
      locationFindOpen: true,
    });
  };

  onLocationSelected = (location) => {
    this.closeFindDialog();
    if (this.props.onChange) {
      this.props.onChange(location);
    }
  };
}

LocationView.propTypes = {
  router: PropTypes.object,
  classes: PropTypes.object,
  location: PropTypes.object,
  onChange: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    marketsClientId: state.markets.clientId,
  };
}

const styled = withStyles(styles)(LocationView);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as LocationView };
