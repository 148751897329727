import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  DynamicTable,
} from 'components';

import {
  queryProcedures,
} from 'store/actions/proceduresActions';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
});

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Code',
    key: 'hcpcs_code',
    style: { width: 100 },
  },
  {
    label: 'Description',
    key: 'description',
  },
  {
    label: 'Medicare Reimbursement',
    key: 'medicare_reimbursement',
    style: { width: 200 },
    format: (val) => parseFloat(val).toLocaleString('en-US', { style: 'currency', currency: 'USD' }),
  },
];

class ProceduresPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        where: {
        },
        order: [
          ['hcpcs_code', 'ASC'],
        ],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const {
      procedures,
    } = this.props;

    if (StoreUtil.needsLoadNoCache(procedures)) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      dispatch,
    } = this.props;

    const {
      query,
    } = this.state;

    dispatch(queryProcedures({
      ...query,
    }));
  }

  render() {
    const {
      classes,
      procedures,
    } = this.props;

    const {
      query,
    } = this.state;

    return (
      <>
        <Helmet defer={false}>
          <title>Procedures</title>
        </Helmet>
        <div className={classes.container}>
          <DynamicTable
            header='Procedures'
            columns={columns}
            query={query}
            data={procedures}
            onQueryChange={this.onQueryChange}
            onSave={this.onSave}
          />
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, () => {
      this.fetchData();
    });
  };
}

ProceduresPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object,
  procedures: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  const {
    procedures,
  } = state;

  return {
    procedures: StoreUtil.get(procedures, StoreUtil.COMMON_TABLE),
  };
}

const styled = withStyles(styles)(ProceduresPage);
const connected = connect(mapStateToProps)(styled);
export { connected as ProceduresPage };
