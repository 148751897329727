import { takeEvery } from 'redux-saga/effects';
import { success, error } from 'redux-saga-requests';
import {
  API_LOGIN,
  API_AUTH_RENEW,
  API_LOGOUT,
  API_AUTH_SMS_CODE,
} from 'store/actions/loginActions';

function* setToken(action) {
  // the action type is tested below in loginsaga, before this is called
  if (action.data && action.data.token) {
    yield localStorage.setItem('auth', action.data.token);
    yield localStorage.setItem('authTime', Date.now());
  }
}

function* clearToken() {
  yield localStorage.removeItem('auth');
  yield localStorage.removeItem('authTime');
}

function* loginSaga() {
  yield takeEvery(success(API_LOGIN), setToken);
  yield takeEvery(success(API_AUTH_SMS_CODE), setToken);
  yield takeEvery(success(API_AUTH_RENEW), setToken);
  yield takeEvery(success(API_LOGOUT), clearToken);
  yield takeEvery(error(API_LOGOUT), clearToken);
}

export default loginSaga;
