import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';;
import LockIcon from '@mui/icons-material/Lock';

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Icon,
  IconButton,
} from '@mui/material';
import PropTypes from 'prop-types';

import {
  StoreUtil,
} from 'doctivity-shared/utils';

import {
  LoadingView,
  SmallIconTextButton,
  EditMarketDialog,
} from 'components';

import {
  listMarkets,
  upsertMarket,
  removeMarket,
} from 'store/actions/marketsActions';
import { showConfirmationDialog } from 'store/actions/systemActions';

const styles = (theme) => ({
  marketDiv: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  marketName: {
    marginRight: theme.spacing(2),
  },
  addButton: {},
  lockIcon: {
    fontSize: 'inherit',
    verticalAlign: 'text-top',
    marginRight: 2,
    paddingRight: 6,
    color: '#999999',
  },
});

class EditMarketsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editOpen: false,
      editMarket: null,
    };
  }

  componentDidMount() {
    console.log('componentDidMount');
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (this.props.clientId !== prevProps.clientId) {
      console.log('componentDidUpdate');
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(listMarkets(this.props.clientId, true));
  }

  render() {
    const { classes } = this.props;

    return (
      <Dialog
        onClose={this.onClose}
        aria-labelledby='simple-dialog-title'
        fullWidth
        open={this.props.open}
        scroll='paper'
      >
        <DialogTitle>Edit Markets</DialogTitle>
        <DialogContent dividers>
          {this.buildMarketList()}
          <SmallIconTextButton
            icon='add'
            text='Add Market'
            className={classes.addButton}
            onClick={this.onAddMarket}
          />
          {this.state.editOpen && (
            <EditMarketDialog
              open={this.state.editOpen}
              onSave={this.onSave}
              onClose={this.closeMarketEditDialog}
              market={this.state.editMarket}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.onClose} color='primary'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  buildMarketList() {
    if (!this.props.markets && StoreUtil.isLoaded(this.props.markets)) {
      return <LoadingView />;
    }
    const markets = StoreUtil.getData(this.props.markets);
    if (!markets) {
      return null;
    }
    return markets.map((market) => (
      <div key={`market-${market.id}`} className={this.props.classes.marketDiv}>
        <LockIcon
          className={this.props.classes.lockIcon}
          style={{ visibility: market.is_private ? 'visible' : 'hidden' }}
        />
        <Typography className={this.props.classes.marketName}>
          {market.name}
        </Typography>
        <IconButton
          onClick={() => {
            this.onEditMarket(market);
          }}
        >
          <Icon>edit</Icon>
        </IconButton>
        <IconButton
          onClick={() => {
            this.onRemoveMarket(market);
          }}
        >
          <Icon>delete</Icon>
        </IconButton>
      </div>
    ));
  }

  openMarketEditDialog(market) {
    this.setState({
      editOpen: true,
      editMarket: market,
    });
  }

  closeMarketEditDialog = () => {
    this.setState({
      editOpen: false,
      editMarket: null,
    });
  };

  onAddMarket = () => {
    this.openMarketEditDialog(null);
  };

  onRemoveMarket = (market) => {
    const { dispatch, clientId } = this.props;

    dispatch(
      showConfirmationDialog({
        title: 'Delete Market',
        content:
          'This action will remove the market for all users who can view it, and it cannot be undone.',
        confirmLabel: 'Delete',
        onConfirm: () => {
          dispatch(removeMarket(market.id, clientId));
        },
        onCancel: () => {
          // Do nothing
        },
      })
    );
  };

  onEditMarket = (market) => {
    this.openMarketEditDialog(market);
  };

  onSave = (market) => {
    market.client_id = this.props.clientId;
    this.props.dispatch(upsertMarket(market));
    this.closeMarketEditDialog();
  };

  onClose = () => {
    // need to check if changes have been made here
    if (this.props.onChange) {
      this.props.onChange();
    }

    if (this.props.onChange) {
      this.props.onClose();
    }
  };
}

EditMarketsDialog.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  markets: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    markets: StoreUtil.get(state.markets, StoreUtil.COMMON_LIST),
  };
}

const styled = withStyles(styles)(EditMarketsDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as EditMarketsDialog };
