import React, { Component } from 'react';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';

import { StoreUtil } from 'doctivity-shared/utils';

const styles = () => ({
  centerContent: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    minWidth: 400,
  },
  tableBody: {
    fontWeight: 300,
  },
  regRow: {
    height: 30,
  },
  altRow: {
    backgroundColor: 'rgba(0,0,0,0.03)',
    height: 30,
  },
  headerCell: {
    verticalAlign: 'bottom',
    lineHeight: '1em',
    whiteSpace: 'nowrap',
  },
  monthCell: {
    padding: 2,
    paddingLeft: 8,
    fontSize: 12,
    textAlign: 'center',
    fontWeight: 'inherit',
  },
  inputCell: {
    width: 90,
    padding: 2,
  },
  textField: {},
  inputField: {
    fontSize: 12,
    fontWeight: 300,
    padding: 4,
  },
  actionSpacer: {
    flex: 1,
  },
});

class ProviderBillingDataDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      stateCounter: 0, // faster to update this vs forcing array to change
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      this.resetData();
    }
  }

  resetData() {
    let oldData = this.props.data;
    if (!oldData) {
      oldData = [];
    }
    const newData = [];

    // this assumes the data is ordered by year/month
    const endDate = moment().subtract(1, 'month').endOf('month'); // last full month
    let startDate = endDate.clone().subtract(5, 'month').endOf('month'); // 6 months before that

    // check if old data has older than 6 month date
    if (oldData.length > 0) {
      const d = this.getDate(oldData[0].year, oldData[0].month+1).endOf('month');
      if (d.isBefore(startDate)) {
        startDate = d;
      }
    }

    const date = startDate.clone();
    const dateToData = oldData.reduce((acc, d) => {
      acc[`${d.year}-${d.month}`] = d;
      return acc;
    }, {});
    while (!date.isAfter(endDate)) {
      const value = dateToData[`${date.year()}-${date.month()}`];
      if (value) {
        newData.push({
          ...value,
          month: value.month + 1,
        });
      } else {
        newData.push({
          year: date.year(),
          month: date.month() + 1,
          charged: 0,
          hb_charged: 0,
          pb_charged: 0,
        });
      }
      date.add(1, 'day').endOf('month');
    }

    this.setState({ data: newData.reverse() });
  }

  render() {
    const { classes, open } = this.props;

    const { data } = this.state;

    const body = data.map((scorecard, i) => this.getRow(i, scorecard));

    return (
      <Dialog
        onClose={this.onCancel}
        aria-labelledby='simple-dialog-title'
        open={open}
        fullWidth
        maxWidth='sm'
        scroll='paper'
      >
        <DialogTitle>Provider Billing</DialogTitle>
        <DialogContent dividers className={classes.centerContent}>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table className={classes.table} stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    key='month'
                    className={classes.headerCell}
                    padding='normal'
                    style={{ textAlign: 'center' }}
                  >
                    Month
                  </TableCell>
                  <TableCell
                    key='revenue'
                    className={classes.headerCell}
                    padding='normal'
                  >
                    Total Billing
                  </TableCell>
                  <TableCell
                    key='pb_charged'
                    className={classes.headerCell}
                    padding='normal'
                  >
                    Professional Billing
                  </TableCell>
                  <TableCell
                    key='hb_charged'
                    className={classes.headerCell}
                    padding='normal'
                  >
                    Hospital Billing
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tableBody}>{body}</TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button color='primary' size='small' onClick={this.onAddMonth}>
            Add Month
          </Button>
          <div className={classes.actionSpacer} />
          <Button onClick={this.onCancel} color='primary'>
            Cancel
          </Button>
          <Button onClick={this.onSave} color='primary' variant='contained'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  getRow(index, scorecard) {
    const { classes } = this.props;

    const year = scorecard.year;
    const month = scorecard.month;

    return (
      <TableRow
        className={index % 2 ? classes.altRow : classes.regRow}
        key={`row${year}${month}`}
      >
        <TableCell
          key={`cellmonth${year}${month}`}
          className={classes.monthCell}
          padding='normal'
          component='th'
          scope='col'
        >
          {this.getDate(year, month).format('MMM YYYY')}
        </TableCell>
        {this.getTextCell('revenue', scorecard)}
        {this.getTextCell('hb_charged', scorecard)}
        {this.getTextCell('pb_charged', scorecard)}
      </TableRow>
    );
  }

  getTextCell(key, scorecard) {
    const { classes } = this.props;

    const year = scorecard.year;
    const month = scorecard.month;

    // tell react it is controlled, never pass undefined
    const value = scorecard[key] ? scorecard[key] : '';
    return (
      <TableCell
        key={`cell${key}${year}${month}`}
        className={classes.inputCell}
        padding='normal'
      >
        <TextField
          key={`input${key}${year}${month}`}
          className={classes.textField}
          autocomplete='off'
          inputProps={{
            className: classes.inputField,
            // endAdornment: <InputAdornment position='end'>%</InputAdornment>,
          }}
          margin='none'
          type='number'
          min={0}
          max={32767}
          onChange={(e) => {
            this.onDataChange(scorecard, key, e.target.value);
          }}
          value={value}
        />
      </TableCell>
    );
  }

  getDate(year, month) {
    return moment()
      .year(year)
      .month(month - 1);
  }

  onAddMonth = () => {
    this.setState((state) => {
      const lastMonth = state.data[state.data.length - 1];
      let year = lastMonth.year;
      let month = lastMonth.month - 1;
      if (month < 1) {
        month = 12;
        year--;
      }
      state.data.push({
        year,
        month,
      });
      return { stateCounter: state.stateCounter + 1 };
    });
  };

  onDataChange = (scorecard, key, value) => {
    const numValue = Number(value);
    if (!value || value.length === 0) {
      // is empty
      if (scorecard[key] && scorecard[key] > 0) {
        // had previous value
        this.setState((prevState) => {
          scorecard.modified = true;
          scorecard[key] = null;
          return { stateCounter: prevState.stateCounter + 1 };
        });
      }
      return;
    }

    if (
      numValue > 0 && // can't be negative
      (key === 'rvu_ranking' || Number.isInteger(numValue)) // if not percentale, can't be decimal
    ) {
      this.setState((prevState) => {
        scorecard.modified = true;
        scorecard[key] = value;

        return { stateCounter: prevState.stateCounter + 1 };
      });
    }
  };

  onCancel = () => {
    const { onClose } = this.props;

    this.resetData();
    if (onClose) {
      onClose();
    }
  };

  onSave = () => {
    const { data } = this.state;

    const { clientId, providerId } = this.props;

    const scorecards = Object.values(data).filter((s) => s.modified);

    // set provider and client on all data
    scorecards.forEach((scorecard) => {
      scorecard.client_id = clientId;
      scorecard.provider_id = providerId;
      if (scorecard.id === 0) delete scorecard.id;
      scorecard.pb_charged = parseInt(scorecard.pb_charged, 10) || 0;
      scorecard.hb_charged = parseInt(scorecard.hb_charged, 10) || 0;
      scorecard.charged = parseInt(scorecard.revenue, 10) || undefined;
      delete scorecard.modified;
    });

    this.props.onSave(scorecards.filter((s) => s.charged > 0));
  };
}

ProviderBillingDataDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  open: PropTypes.bool.isRequired,
  data: PropTypes.object,
  providerId: PropTypes.number.isRequired,
  clientId: PropTypes.number.isRequired,
};

const styled = withStyles(styles)(ProviderBillingDataDialog);
export { styled as ProviderBillingDataDialog };
