import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import sharedStyles from './SharedStyles'

import { StoreUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { DynamicTable } from 'components';

import { Icon, Tooltip, Typography } from '@mui/material';

import { queryOrganizations } from 'store/actions/organizationsActions';

import { OrganizationEditDialog } from './OrganizationEditDialog';
import { OrganizationsTabs } from './OrganizationsTabs';

class AffiliatedOrganizationsPage extends React.Component {
  constructor(props) {
    super(props);
    const { clientId } = props;

    this.columns = [
      {
        label: '',
        key: 'affiliated',
        style: { width: 14 },
        sortable: false,
        showFilter: false,
        format: (affiliated) => {
          if (affiliated?.length > 0) {
            return (
              <Tooltip
                placement='bottom-start'
                title={
                  <Typography
                    style={{
                      fontSize: 13,
                      fontWeight: 200,
                    }}
                  >
                    Affiliated Organization
                  </Typography>
                }
              >
                <div
                  style={{
                    width: 14,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Icon className={props.classes.affiliatedIcon}>hub</Icon>
                </div>
              </Tooltip>
            );
          }
          return <></>;
        },
      },
      {
        label: 'Name',
        key: 'name',
        fulltext: true,
      },
      {
        label: 'Type',
        key: 'facility_type',
      },
      {
        label: 'NPI',
        key: 'npi',
        style: { minWidth: 140, verticalAlign: 'top' },
      },
      {
        label: 'City',
        key: 'primary_location.city',
        fulltext: true,
      },
      {
        label: 'State',
        key: 'primary_location.state',
        style: { width: 50 },
        filterExact: true,
        autoCapitalize: true,
      },
    ];

    this.state = {
      query: {
        order: [['name', 'ASC']],
        include: [
          {
            attributes: ['id', 'city', 'state'],
            association: 'primary_location',
          },
          { association: 'affiliated', where: { id: clientId } },
        ],
        limit: 25,
        offset: 0,
      },
      addDialogOpen: false,
    };
  }

  componentDidMount() {
    const oldQuery = sessionStorage.getItem('affiliatedOrganizationsTableQuery')
    if (oldQuery) {
      this.onQueryChange(JSON.parse(oldQuery))
    }
    this.fetchData();
  }

  fetchData() {
    const { dispatch } = this.props;

    const { query } = this.state;

    dispatch(queryOrganizations(query));
  }

  render() {
    const { organizations, classes } = this.props;

    const { query } = this.state;

    return (
      <div>
        <OrganizationsTabs tab='affiliated_organizations' />
        <div className={classes.container}>
          <DynamicTable
            header={
              <div className={classes.headerContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
                >
                  Affiliated Organizations
                </Typography>
                <div className={classes.tableHeader} />
              </div>
            }
            columns={this.columns}
            noDataMessage='No AFFILIATED organizations found.'
            query={query}
            data={organizations}
            onQueryChange={this.onQueryChange}
            onRowClick={(row) => {
              if (row.id) {
                this.props.router.navigate(`/organizations/${row.id}`);
              }
            }}
          />
        </div>
        <OrganizationEditDialog
          open={this.state.addDialogOpen}
          onSave={this.onSaveOrganization}
          onClose={this.onCloseAddDialog}
        />
      </div>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);

    sessionStorage.setItem('affiliatedOrganizationsTableQuery', JSON.stringify(query))
  };
}

AffiliatedOrganizationsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object,
  organizations: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const { organizations, user } = state;

  return {
    user,
    organizations: StoreUtil.get(organizations, StoreUtil.COMMON_TABLE),
    clientId: state.app.selectedClient,
  };
}

const styled = withStyles(sharedStyles)(AffiliatedOrganizationsPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as AffiliatedOrganizationsPage };
