import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { PropTypes } from 'prop-types';
import { latestClaimsDate } from '../../config/claims-date';
import { DateUtil } from 'doctivity-shared/utils';

const styles = (theme) => ({
  dataHeader: {
    marginLeft: 'auto',
    fontSize: 13,
    fontFamily: 'Roboto',
    textAlign: 'right',
    paddingBottom: theme.spacing(2),
  }
});

function ClaimsDateProvider(props) {
  const oldestClaimsDate = new Date(
    latestClaimsDate.getFullYear() - 1,
    latestClaimsDate.getMonth() + 1,
  );
  const { classes } = props;
  return (
    <div className={classes.dataHeader}>
      Data:&nbsp;
      {DateUtil.formatDateForUser(oldestClaimsDate)}
      &nbsp; through &nbsp;
     {latestClaimsDate.getUTCMonth()+1}/
     {latestClaimsDate.getUTCDate()}/
     {latestClaimsDate.getUTCFullYear()}
    </div>
  );
}
ClaimsDateProvider.propTypes = {
  classes: PropTypes.object.isRequired,
};
const styled = withStyles(styles)(ClaimsDateProvider);
export { styled as ClaimsDateProvider }
