export const API_CLAIMS_SERVICE_LINES_TABLE = 'API_CLAIMS_SERVICE_LINES_TABLE';
export const API_CLAIMS_SERVICE_LINES_LIST = 'API_CLAIMS_SERVICE_LINES_LIST';
export const API_CLAIMS_ORGANIZATIONS_LIST = 'API_CLAIMS_ORGANIZATION_LIST';
export const API_CLAIMS_LIST = 'API_CLAIMS_LIST';
export const API_CLAIMS_PAYERS_LIST = 'API_CLAIMS_PAYERS_LIST';

export const queryClaimsServicelines = (options, type = API_CLAIMS_SERVICE_LINES_TABLE) => ({
  type,
  request: {
    url: '/ClaimsServicelines',
    method: 'GET',
    params: { opts: options },
  },
});

export const listClaimsServicelines = () => (queryClaimsServicelines({
  order: [
    ['name', 'ASC'],
  ],
}, API_CLAIMS_SERVICE_LINES_LIST));

export const listClaimsByProvider = (providerId, patientType) => ({
  type: API_CLAIMS_LIST,
  request: {
    url: patientType === 'PEDIATRIC_PATIENTS'
      ? '/PediatricProviderPatientCounts'
      : patientType === 'ALL_PATIENTS' 
        ? '/Claims'
        : '/ProviderServicelineClaimTotals',
    method: 'GET',
    params: {
      opts: {
        where: {
          provider_id: providerId,
        },
        include: [
          {
            association: 'serviceline',
            attributes: ['id', 'name', 'parent_id', 'abbreviation'],
            required: true,
            /*
            where: {
              parent_id: showSubServicelines
                ? { $ne: null }
                : null, // we only want top level
            },
            */
          },
        ],
        order: [
          patientType === 'CAPTURED_CLAIMS'
            ? ['total_claims', 'DESC']
            : ['total_patients', 'DESC'],
        ],
      },
    },
  },
});

export const listClaimsByOrganization = (organizationId, patientType) => ({
  type: API_CLAIMS_ORGANIZATIONS_LIST,
  id: organizationId,
  request: {
    url: patientType === 'PEDIATRIC_PATIENTS'
      ? '/PediatricOrganizationPatientCounts'
      : patientType === 'ALL_PATIENTS' 
        ? '/ClaimsOrganizations'
        : '/OrganizationServicelineClaimTotals',
    method: 'GET',
    params: {
      opts: {
        where: {
          organization_id: organizationId,
        },
        include: [
          {
            association: 'serviceline',
            attributes: ['id', 'name', 'parent_id', 'abbreviation'],
            required: true,
            /*
            where: {
              parent_id: null, // we only want top level
            },
            */
          },
        ],
        order: [
          patientType === 'CAPTURED_CLAIMS'
            ? ['total_claims', 'DESC']
            : ['total_patients', 'DESC'],
        ],
      },
    },
  },
});

export const listClaimsPayersByProvider = (providerId, patientType) => ({
  type: API_CLAIMS_PAYERS_LIST,
  request: {
    url: patientType === 'PEDIATRIC_PATIENTS' ? '/PediatricProviderPayers' : '/ClaimsPayers',
    method: 'GET',
    params: {
      opts: {
        where: {
          provider_id: providerId,
        },
        include: [
          {
            association: 'payer',
            attributes: ['id', 'name', 'type'],
          },
        ],
        order: [
          ['percent', 'DESC'],
        ],
      },
    },
  },
});
