import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Sparklines,
  SparklinesCurve,
} from 'react-sparklines';
import {
  StoreUtil,
} from 'doctivity-shared/utils';
import {
  loadAnalytics,
} from 'store/actions/analyticsActions';

const styles = () => ({
  text: {
    minHeight: '1em',
    marginBottom: '0.5em',
  },
});

function dateStr(date) {
  if (date) {
    if (typeof date === 'string' || date instanceof String) {
      return date.substring(0, 7);
    }
    return date.format('YYYY-MM');
  }
  return '';
}
function getStoreId(providerId, app) {
  // eslint-disable-next-line prefer-template
  return 'referrer_sparkline('
    + app.selectedClient + '-'
    + providerId + '-'
    + dateStr(app.dateRange.start) + '-'
    + dateStr(app.dateRange.end) + ')';
}

class ReferrerSparkline extends React.Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (this.props.app.selectedClient !== prevProps.app.selectedClient
      || this.props.app.dateRange !== prevProps.app.dateRange
      || this.props.providerId !== prevProps.providerId) {
      this.fetchData();
    }
  }

  fetchData() {
    const {
      data,
      app,
      providerId,
      dispatch,
    } = this.props;

    if (StoreUtil.needsLoadLongCache(data)) {
      dispatch(loadAnalytics({
        type: 'PROVIDER_REFERRED_OUT_COUNT_BY_MONTH',
        store_id: getStoreId(providerId, app),
        filter: {
          client_id: app.selectedClient,
          referrer_id: providerId,
          from: app.dateRange.start,
          to: app.dateRange.end,
        },
      }));
    }
  }

  render() {
    const {
      data,
    } = this.props;

    let color = '#66666688';

    if (!data || !StoreUtil.isLoaded(data)) {
      return '';
    }
    const counts = data.data.map((row) => row.count_referrer_id);

    if (counts.length >= 6) {
      const start = (counts[0] + counts[1] + counts[2]) / 3;
      const end = (counts[counts.length - 3]
        + counts[counts.length - 2]
        + counts[counts.length - 1]) / 3;

      const diff = end / start;
      if (diff > 1.1) {
        color = '#00A887cc';
      } else if (diff < 0.9) {
        color = '#ff434399';
      }
    }
    return (
      <Sparklines data={counts} width={60} height={18}>
        <SparklinesCurve color={color} style={{ fill: 'none', strokeWidth: 2 }} divisor={0.5} />
      </Sparklines>
    );
  }
}

ReferrerSparkline.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  app: PropTypes.object,
  data: PropTypes.object,
  providerId: PropTypes.number.isRequired,
};

function mapStateToProps(state, props) {
  const {
    app,
    analytics,
  } = state;

  return {
    app,
    data: StoreUtil.get(analytics, getStoreId(props.providerId, app)),
  };
}

const styled = withStyles(styles)(ReferrerSparkline);
const connected = connect(mapStateToProps)(styled);
export { connected as ReferrerSparkline };
