import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import withStyles from '@mui/styles/withStyles';
import {
  Card,
  Typography,
  CardContent,
  Tabs,
  Tab,
  Button,
  Icon,
  Divider,
} from '@mui/material';
import { IconGridItem } from 'components';

import { StoreUtil, PhoneUtil } from 'doctivity-shared/utils';
import PropTypes from 'prop-types';
import { LocationsUtil, withRouter, withAppbarHeight } from 'utils';
import LocationIcon from 'assets/images/location_icon.svg';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import {
  LoadingView,
  OverlineLabel,
  UnderlineData,
  FavoriteIconButton,
  FixedHeader,
  StickyHeader,
  SmallIconTextButton,
  ProviderComments,
  BreadcrumbRow,
} from 'components';

import {
  LocationProviders,
  LocationContacts,
  LocationEditDialog,
  SimilarLocationsTable,
} from 'locations';
import {
  loadLocation,
  upsertLocation,
  getProvidersCount,
  getContactsCount,
} from 'store/actions/locationsActions';
import { showConfirmationDialog } from 'store/actions/systemActions';
import { FindOrganizationDialog } from 'organizations';

const styles = (theme) => ({
  tabs: {
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.tertiary.main,
  },
  container: {
    padding: theme.spacing(3),
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    backgroundColor: theme.palette.tertiary.main,
  },
  headerContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: theme.palette.tertiary.main,
    paddingLeft: theme.spacing(5.5),
    paddingBottom: 3,
    fontWeight: 400,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      alignItems: 'start',
    },
  },
  headerItem: {
    fontSize: 14,
    paddingLeft: 15,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  headerEmptySpacer: {
    paddingBottom: 21
  },
  headerTitle: {
    fontSize: 24,
    fontWeight: 500,
    fontSize: 'clamp(16px, 2.5vw, 24px)',
    paddingRight: 30,
  },
  favIcon: {
    [theme.breakpoints.down('md')]: {
      marginTop: -6.5
    },
  },
  headerIcon: {
    color: theme.palette.secondary.accent,
    marginRight: theme.spacing(1),
    paddingTop: 4,
    [theme.breakpoints.down('md')]: {
      paddingTop: 0
    },
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    borderColor: theme.palette.tertiary.main,
    maxWidth: 380,
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      borderRight: 'none',
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
  },
  detailsCard: {
    position: 'relative',
    display: 'flex',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  cardDecorationBar: {
    position: 'absolute',
    backgroundColor: theme.palette.secondary.accent,
    width: 13,
    top: 0,
    left: 0,
    height: '100%',
  },
  linksContainer: {
    position: 'absolute',
    textAlign: 'right',
    top: theme.spacing(2),
    right: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      top: 14,
      right: 0,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(),
  },
  similarLocations: {
    marginTop: theme.spacing(3),
  },
  organization: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  organizationIcon: {
    marginRight: 2,
    color: '#999999',
  },
  spacer: {
    flex: 1,
  },
  divider: {
    backgroundColor: theme.palette.tertiary.main,
  },
  locationIcon: {
    alignSelf: 'center',
    justifySelf: 'center',
    width: 110,
  },
  locationDetailsTitle: {
    color: theme.palette.primary.dark,
    flexShrink: 0,
    font: 'normal normal 24px/37px Roboto',
    textAlign: 'center',
  },
  editButton: {
    color: theme.palette.secondary.main,
  },
  detailsFields: {
    paddingLeft: theme.spacing(5),
    paddingRight: 100,
    flexGrow: 1,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  detailsRow: {
    display: 'flex',
    alignItems: 'center',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    borderColor: theme.palette.tertiary.main,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      borderBottom: 'none',
    },
  },
  detailsItem: {
    flexBasis: '50%',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      paddingLeft: 0,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderColor: theme.palette.tertiary.main,
    },
  },
  phoneRow: {
    display: 'flex',
    alignItems: 'start',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  phoneColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  organizationButton: {
    color: theme.palette.secondary.main,
    padding: 0,
    fontWeight: 400,
    fontSize: 13,
  },
  editOrgButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
});

class LocationDetailPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      setOrgModalOpen: false,
      editDialogOpen: false,
      headerHeight: 0,
      stickyTab: undefined,
      selectedOrganization: null,
    };
  }

  componentDidMount() {
    const { params } = this.props;

    if (params.id) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { clientId, params, editingLocation, location } = this.props;

    if (
      params.id &&
      (clientId !== prevProps.clientId || params.id !== prevProps.params.id)
    ) {
      this.fetchData();
    }

    // anytime a location has been edited, update the list
    if (editingLocation !== prevProps.editingLocation) {
      // is the list data older than the edited data?
      if (StoreUtil.hasSavedSinceLoad(editingLocation, location)) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const { dispatch, user } = this.props;

    const id = this.getId();
    dispatch(loadLocation(id));
    if (user.is_admin) {
      dispatch(getProvidersCount(id));
      dispatch(getContactsCount(id));
    }
  }

  getId() {
    return parseInt(this.props.params.id, 10);
  }

  render() {
    const {
      classes,
      params,
      location,
      user,
      clientId,
      providerCount,
      contactCount,
      appbarHeight,
    } = this.props;
    const {
      editDialogOpen,
      headerHeight,
      stickyTab,
      setOrgModalOpen,
      selectedOrganization
    } = this.state;

    const currentTab = params.tab || 'details';
    const locationData = StoreUtil.getData(location);

    let locationStr = '';

    if (locationData && locationData.address1 && locationData.city && locationData.state && locationData.postal_code) {
      if (locationData.address2) {
        locationStr = `${locationData.address1} ${locationData.address2} ${locationData.city}, ${locationData.state} ${locationData.postal_code}`;
      }
      else {
        locationStr = `${locationData.address1} ${locationData.city}, ${locationData.state} ${locationData.postal_code}`;
      }
    }

    console.log(locationData)

    let locationNameStr = '';
    if (locationData && locationData.name) {
      locationNameStr = `${locationData.name}`;
    }

    if (
      !locationData ||
      StoreUtil.isLoading(location) ||
      (user.isAdmin &&
        (providerCount === undefined ||
          providerCount === null ||
          contactCount === undefined ||
          contactCount === null))
    ) {
      return <LoadingView />;
    }

    return (
      <>
        <Helmet defer={false}>
          <title>{LocationsUtil.formatAddress(locationData, false)}</title>
        </Helmet>
        <FixedHeader onChangeHeaderHeight={this.onChangeHeaderHeight}>
          <div className={classes.breadcrumb}>
            <BreadcrumbRow
              label='Locations'
              path='/locations'
              icon='chevron_left'
            />
          </div>
          <div className={classes.header}>
            <Icon className={classes.headerIcon}>place</Icon>
            <Typography className={classes.headerTitle}>
              {locationStr}
            </Typography>
            <div className={classes.spacer} />
            <div className={classes.favIcon}>
              <FavoriteIconButton
                objectType='Locations'
                objectId={locationData.id}
              />
            </div>
          </div>
          <div className={classes.headerContent}>
            {locationNameStr ? (
              <Typography className={classes.headerItem}>
                {locationNameStr}
              </Typography>
            ) : (
              <div className={classes.headerEmptySpacer} />
            )}
          </div>
          <Divider
            style={{
              borderColor: stickyTab === false ? '#0000001f' : '#EDEDED',
              transition: 'borderColor 0.1s ease-in',
            }}
          />
        </FixedHeader>
        <StickyHeader
          headerTop={headerHeight + appbarHeight}
          onChangeIsSticky={this.onChangeIsSticky}
        >
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            value={currentTab}
            onChange={this.onTabChange}
          >
            <Tab label='Details' value='details' disableRipple />
            <Tab label='Providers' value='providers' disableRipple />
            <Tab label='Contacts' value='contacts' disableRipple />
            <Tab label='Activity Notes' value='notes' disableRipple />
          </Tabs>
          <Divider />
        </StickyHeader>
        <div className={classes.container}>
          {currentTab === 'details' && (
            <>
              <Card>
                <CardContent className={classes.detailsCard}>
                  <div className={classes.cardDecorationBar} />
                  <div className={classes.titleContainer}>
                    <Typography noWrap className={classes.locationDetailsTitle}>
                      Location Details
                    </Typography>
                    <img
                      src={LocationIcon}
                      alt='Location Icon'
                      className={classes.locationIcon}
                    />
                    <OverlineLabel>LOCATION NAME</OverlineLabel>
                    <UnderlineData>{locationData.name}</UnderlineData>
                  </div>

                  <div className={classes.detailsFields}>
                    <div className={classes.detailsRow}>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='place'>
                          <OverlineLabel>ADDRESS</OverlineLabel>
                          <UnderlineData>
                            {LocationsUtil.formatAddress(locationData, true)}
                          </UnderlineData>
                        </IconGridItem>
                      </div>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='apartment'>
                          <OverlineLabel>ORGANIZATION</OverlineLabel>
                          <UnderlineData>
                            {locationData.organization ? (
                              <div className={classes.organization}>
                                <Button
                                  onClick={() => {
                                    this.props.router.navigate(
                                      `/organizations/${locationData.organization.id}`
                                    );
                                  }}
                                  variant='text'
                                  className={classes.organizationButton}
                                >
                                  {locationData.organization.name}
                                </Button>
                                {user.is_admin &&
                                  locationData.organization
                                    .primary_location_id !==
                                  locationData.id && (
                                    <SmallIconTextButton
                                      icon='close'
                                      text='clear'
                                      onClick={this.onDetachOrganization}
                                    />
                                  )}
                               {locationData.organization
                                  .primary_location_id === locationData.id &&
                                  ' (primary)'}
                              </div>
                            ) : (<div className={classes.orgEditButton}>
                              No organization
                              {user.is_admin &&
                                !locationData.organization &&
                                (<Button
                                  variant='text'
                                  color='primary'
                                  onClick={this.onOpenOrgEditModal}
                                  className={classes.editButton}
                                >
                                  <EditOutlinedIcon className={classes.leftIcon} />
                                </Button>
                               )}</div> 
                            )}
                          </UnderlineData>
                        </IconGridItem>
                      </div>
                    </div>
                    <div className={classes.detailsRow}>
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='smartphone'>
                          <div className={classes.phoneRow}>
                            <div
                              className={classes.phoneColumn}
                              style={{ marginRight: 48 }}
                            >
                              <OverlineLabel>PHONE</OverlineLabel>
                              <UnderlineData>
                                {PhoneUtil.formatNumber(locationData.phone)}
                              </UnderlineData>
                            </div>
                            <div className={classes.phoneColumn}>
                              <OverlineLabel>FAX</OverlineLabel>
                              <UnderlineData>
                                {PhoneUtil.formatNumber(locationData.fax)}
                              </UnderlineData>
                            </div>
                          </div>
                        </IconGridItem>
                      </div>
                    </div>
                    <div
                      className={classes.detailsRow}
                      style={{ borderBottom: 'none' }}
                    >
                      <div className={classes.detailsItem}>
                        <IconGridItem iconName='email'>
                          <OverlineLabel>EMAIL</OverlineLabel>
                          <UnderlineData>{locationData.email}</UnderlineData>
                        </IconGridItem>
                      </div>
                    </div>
                  </div>

                  {user.is_admin && (
                    <div className={classes.linksContainer}>
                      <Button
                        variant='text'
                        color='primary'
                        onClick={this.onOpenEditDialog}
                        className={classes.editButton}
                      >
                        <EditOutlinedIcon className={classes.leftIcon} />
                        Edit
                      </Button>
                    </div>
                  )}
                  {!locationData.organization &&
                    providerCount === 0 &&
                    contactCount === 0 &&
                    user.is_admin && (
                      <Typography style={{ color: '#d32f2f' }}>
                        This location is not attached to any organizations,
                        providers, or contacts.
                      </Typography>
                    )}
                </CardContent>
              </Card>
              <Card className={classes.similarLocations}>
                <CardContent>
                  <SimilarLocationsTable location={locationData} />
                </CardContent>
              </Card>
            </>
          )}
          {currentTab === 'providers' && (
            <LocationProviders location={locationData} />
          )}
          {currentTab === 'contacts' && (
            <LocationContacts location={locationData} />
          )}
          {currentTab === 'notes' && (
            <ProviderComments clientId={clientId} location={locationData} />
          )}
          <LocationEditDialog
            location={locationData}
            open={editDialogOpen}
            onClose={this.onCloseEditDialog}
            onSave={this.onSave}
          />
          {setOrgModalOpen && (
            <FindOrganizationDialog
              clientId={clientId}
              open={setOrgModalOpen}
              onMultiSelect={(orgs) => {
                const org = orgs.pop();
                if (org !== null) {
                  this.setState({ selectedOrganization: org });
                } else {
                  this.setState({ setOrgModalOpen: false, selectedOrganization: null });
                }
              }}
              onSelect={(param) => {
                if (param === null ) this.setState({ setOrgModalOpen: false, selectedOrganization: null });
              }}
              onSaveMultiSelect={() => {
                this.setState({ setOrgModalOpen: false, selectedOrganization: null });
                this.props.dispatch(
                  upsertLocation({
                    ...locationData,
                    organization_id: selectedOrganization.id,
                    organization: selectedOrganization,
                  })
                );
              }}
              selectedRows={selectedOrganization ? [selectedOrganization] : []}
            />
          )}
        </div>
      </>
    );
  }

  onOpenOrgEditModal = () => {
    this.setState({ setOrgModalOpen: true });
  }

  onOpenEditDialog = () => {
    this.setState({
      editDialogOpen: true,
    });
  };

  onCloseEditDialog = () => {
    this.setState({
      editDialogOpen: false,
    });
  };

  onSave = (data) => {
    const { dispatch } = this.props;

    dispatch(upsertLocation(data));

    this.setState({
      editDialogOpen: false,
    });
  };

  onTabChange = (event, newValue) => {
    this.props.router.navigate(`/locations/${this.getId()}/${newValue}`);
  };

  onChangeHeaderHeight = (headerHeight) => {
    this.setState({ headerHeight });
  };

  onChangeIsSticky = (isSticky) => {
    this.setState({ stickyTab: isSticky });
  };

  onDetachOrganization = () => {
    if (this.props.location) {
      const data = {
        ...StoreUtil.getData(this.props.location),
      };
      if (data && data.organization_id) {
        this.props.dispatch(
          showConfirmationDialog({
            title: 'Are you sure?',
            content:
              'Removing the organization from this location will remove the organization from all related providers and contacts.',
            confirmLabel: 'Clear',
            cancelLabel: 'Cancel',
            onConfirm: () => {
              data.organization_id = null;
              if (data.organization) {
                delete data.organization;
              }
              this.props.dispatch(upsertLocation(data));
            },
          })
        );
      }
    }
  };
}

LocationDetailPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  params: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  appbarHeight: PropTypes.number.isRequired,
  clientId: PropTypes.number,
  location: PropTypes.object,
  editingLocation: PropTypes.object,
  editComment: PropTypes.object,
  providerCount: PropTypes.number,
  contactCount: PropTypes.number,
};

function mapStateToProps(state, props) {
  const { locations, app, user } = state;
  const locationId = props.params.id;

  const providers = StoreUtil.get(state.providers, StoreUtil.COMMON_TABLE);
  const contacts = StoreUtil.get(state.contacts, StoreUtil.COMMON_TABLE);

  return {
    clientId: app.selectedClient,
    user,
    location:
      locationId && StoreUtil.get(locations, StoreUtil.COMMON_ITEM, locationId),
    editingLocation: StoreUtil.get(locations, StoreUtil.COMMON_EDIT_ITEM),
    // TODO: create StoreUtil helper for getting count of table
    providerCount: providers.data?.count,
    contactCount: contacts.data?.count,
  };
}

const styled = withStyles(styles)(withAppbarHeight(LocationDetailPage));
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as LocationDetailPage };
