import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import fileDownload from 'js-file-download';
import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import { LoadingView, ServiceLineSelect, DynamicTable } from 'components';
import { ServiceLineUtil } from 'utils';
import { StoreUtil } from 'doctivity-shared/utils';

import { CsvUtil, DataUtil, LocationTypeUtil } from 'doctivity-shared/utils';

import { queryMigrations } from 'store/actions/migrationsActions';
import { GetApp } from '@mui/icons-material';
import axiosInstance from 'utils/axiosUtil';
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';

const formatZip3 = (value) => ('0000' + String(value)).slice(-3);

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Service Line',
    key: 'serviceline.name',
  },
  {
    label: 'Care Setting',
    key: 'location_type',
    style: { width: 100 },
    format: (value) => (value ? LocationTypeUtil.getName(value) : ''),
    filter: false,
    sortable: false,
  },
  {
    label: 'County',
    key: 'county.county_name',
  },
  {
    label: 'State',
    key: 'county.state',
    style: { width: 80 },
  },
  {
    label: 'Zip3',
    key: 'zip3',
    format: formatZip3,
  },
  {
    label: 'Patients',
    key: 'total_patients',
    style: { width: 100 },
    format: (value) => (value ? value.toLocaleString() : '< 11'),
  },
];

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  card: {},
  pdfButton: {
    marginLeft: theme.spacing(),
    flex: 1,
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  pdfIcon: {
    fontSize: 16,
    fontWeight: 200,
  },
  filterDropDown: {
    width: 200,
    marginRight: theme.spacing(),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  dropDown: {
    marginBottom: theme.spacing(2),
  },
  dropDownContainer: {
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
});

class OrganizationMigrationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceline: null,
      useCounty: true,
      careSetting: 0,
      hasDefaultedCareSetting: false,
      query: {
        where: {},
        include: [
          {
            attributes: ['id', 'name', 'parent_id'],
            association: 'serviceline',
            required: true,
            where: {}, // this gets set in filtered query
          },
          {
            // this will get removed in fetchData if we are using zip3
            association: 'county',
            required: true,
          },
        ],
        order: [['total_patients', 'DESC']],
        limit: 50,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    if (this.getData() === null) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.getData() === null) {
      this.fetchData();
    }
    const data = this.getData();
    if (
      prevProps.migrations !== this.props.migrations &&
      data &&
      !this.state.hasDefaultedCareSetting &&
      StoreUtil.isLoaded(data)
    ) {
      let max = -999;
      let careSetting = null;
      data.data?.rows?.forEach((d) => {
        if (d.total_patients > max) {
          careSetting = d.location_type;
          max = d.total_patients;
        }
      });
      if (careSetting !== this.state.careSetting && careSetting !== null) {
        this.setState(
          { careSetting, hasDefaultedCareSetting: true },
          this.fetchData,
        );
      }
    }
  }

  getId() {
    if (this.props.organization) {
      return this.props.organization.id;
    }
    return null;
  }

  getTableName() {
    return this.state.useCounty
      ? 'PatientMigrationCounty'
      : 'PatientMigrationZip3';
  }

  getFilename() {
    const { organization } = this.props;

    const name = organization ? organization.name : '';

    return `Doctivity_${name}_migration_${
      this.state.useCounty ? 'county' : 'zip3'
    }_${moment().format('YY_MM_DD')}.csv`;
  }

  getDataKey() {
    return this.state.useCounty ? 'county' : 'zip';
  }

  getData() {
    if (this.props.migrations) {
      const data = this.props.migrations[this.getDataKey()];
      if (data && data.id === this.getId()) {
        return data;
      }
    }
    return null;
  }

  getFilteredQuery() {
    const query = { ...this.state.query }; // need to add deep copy here if something shouldnt be changed below
    if (!this.state.useCounty) {
      query.include = query.include.filter(
        (inc) => inc.association !== 'county',
      );
    }

    const servicelineInclude = query.include.find(
      (inc) => inc.association === 'serviceline',
    );
    const id = this.getServicelineId();
    if (id !== 'all') {
      servicelineInclude.where.id = id;
      if (servicelineInclude.where.parent_id === null) {
        delete servicelineInclude.where.parent_id;
      }
    } else {
      if (servicelineInclude.where.id) {
        delete servicelineInclude.where.id;
      }
      servicelineInclude.where.parent_id = null;
    }

    if (this.state.careSetting) {
      query.where.location_type = this.state.careSetting;
    } else if (query.where.location_type) {
      delete query.where.location_type;
    }

    return query;
  }

  fetchData() {
    this.props.dispatch(
      queryMigrations(
        !!this.state.useCounty,
        this.getId(),
        this.getDataKey(),
        this.getFilteredQuery(),
      ),
    );
  }

  fetchCSVDownload() {
    axiosInstance
      .get(`/${this.getTableName()}`, {
        params: {
          opts: {
            ...this.getFilteredQuery(),
            format: 'csv',
          },
        },
      })
      .then((response) => {
        const filename = this.getFilename(); // TODO: build filename
        try {
          const data = this.formatCSV(response.data);
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(response.data, filename);
        }
      });
  }

  formatCSV(data) {
    let csvColumns = CsvUtil.stringToMatrix(data);
    let zip3Index = undefined;
    if (csvColumns.length > 0) {
      const idColumnIndecesToRemove = [];
      let locationSettingColumn = 0;
      // Loop through again to clean up all '*id' columns,
      // once we have changed the name of the id columns we want to keep
      for (let index = 0; index < csvColumns[0].length; index++) {
        if (
          csvColumns[0][index].endsWith('_id') ||
          csvColumns[0][index].endsWith('.id') ||
          csvColumns[0][index] === 'serviceline.parent_id'
        ) {
          idColumnIndecesToRemove.push(index);
        } else if (csvColumns[0][index] === 'location_type') {
          csvColumns[0][index] = 'Care Setting';
          locationSettingColumn = index;
        } else if (csvColumns[0][index] === 'total_patients') {
          csvColumns[0][index] = 'Captured Patients';
        } else if (csvColumns[0][index] === 'serviceline.name') {
          csvColumns[0][index] = 'Service Line';
        } else if (csvColumns[0][index] === 'county.county_name') {
          csvColumns[0][index] = 'County';
        } else if (csvColumns[0][index] === 'county.state') {
          csvColumns[0][index] = 'State';
        } else if (csvColumns[0][index] === 'zip3') {
          csvColumns[0][index] = 'Zip 3';
          zip3Index = index;
        }
      }
      if (zip3Index) {
        csvColumns.forEach((row, index) => {
          if (index === 0) return;
          row[zip3Index] = formatZip3(row[zip3Index]);
        });
      }

      if (locationSettingColumn) {
        for (let row = 1; row < csvColumns.length; row++) {
          csvColumns[row][locationSettingColumn] = LocationTypeUtil.getName(
            parseInt(csvColumns[row][locationSettingColumn], 10),
          );
        }
      }

      for (let index = idColumnIndecesToRemove.length; index >= 0; index--) {
        csvColumns = DataUtil.removeMatrixColumn(
          csvColumns,
          idColumnIndecesToRemove[index],
        );
      }
    }

    return CsvUtil.matrixToString(csvColumns);
  }

  getServicelineId() {
    return this.state.serviceline || this.props.servicelineId;
  }

  render() {
    const { classes } = this.props;
    const migrations = this.getData();

    if (!migrations) {
      return <LoadingView />;
    }

    let columnsByType = [...columns];
    if (this.state.useCounty) {
      // remove zip3
      columnsByType = columnsByType.filter((column) => column.key !== 'zip3');
    } else {
      // remove county
      columnsByType = columnsByType.filter(
        (column) => column.key !== 'county.county_name',
      );
      // remove state
      columnsByType = columnsByType.filter(
        (column) => column.key !== 'county.state',
      );
    }

    if (this.state.careSetting) {
      columnsByType = columnsByType.filter(
        (column) => column.key !== 'location_type',
      );
    }

    if (this.state.serviceline !== 'all') {
      columnsByType = columnsByType.filter(
        (column) => column.key !== 'serviceline.name',
      );
    }

    return (
      <Card>
        <CardContent>
          <div className={classes.tableHeader}>
            <Typography component='h2' variant='h6' color='primary'>
              {this.state.useCounty
                ? 'Captured Patients by County'
                : 'Captured Patients by First 3 Zip Code Digits'}
            </Typography>
            <div className={classes.pdfButton}>
              <IconButton
                onClick={() => {
                  this.fetchCSVDownload();
                }}
                size='large'
              >
                <GetApp className={classes.pdfIcon} />
              </IconButton>
            </div>
          </div>
          <div className={classes.dropDownContainer}>
            <div className={classes.dropDown}>
              <ServiceLineSelect
                value={this.getServicelineId()}
                onChange={this.onServicelineChange}
                showSubServicelines
                showAll
              />
            </div>
            <div className={classes.dropDown}>
              <FormControl
                variant='outlined'
                className={classes.filterDropDown}
                key='filter-setting'
              >
                <InputLabel>Care Setting</InputLabel>
                <Select
                  value={this.state.careSetting}
                  onChange={this.onCareSettingChange}
                  label='Care Setting'
                >
                  {
                    // eslint-disable-next-line arrow-body-style
                    LocationTypeUtil.getAll()
                      .filter((type) => !type.codesOnly)
                      .map((type) => {
                        return (
                          <MenuItem value={type.id} key={type.id}>
                            {type.id === 0 ? <em>{type.name}</em> : type.name}
                          </MenuItem>
                        );
                      })
                  }
                </Select>
              </FormControl>
            </div>
            <div className={classes.dropDown}>
              <FormControl
                variant='outlined'
                className={classes.filterDropDown}
                key='area'
              >
                <InputLabel className={classes.filterLabel}>
                  Patient Home
                </InputLabel>
                <Select
                  key='select'
                  value={this.state.useCounty}
                  onChange={this.onCountyChange}
                  label='Patient Home'
                >
                  <MenuItem value key='county'>
                    County
                  </MenuItem>
                  <MenuItem value={false} key='zip3'>
                    Zip 3
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>

          <DynamicTable
            idKey={[
              'serviceline_id',
              this.state.useCounty ? 'county_id' : 'zip3',
              'location_type',
            ]}
            noDataMessage='No available patient migration data.'
            ContainerType={React.Fragment}
            columns={columnsByType}
            query={this.state.query}
            data={migrations}
            onQueryChange={this.onQueryChange}
            onRowClick={this.onRowClick}
          />
        </CardContent>
      </Card>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };

  onCountyChange = (event) => {
    this.setState({ useCounty: event.target.value }, this.fetchData);
  };

  onServicelineChange = (id) => {
    this.setState(
      {
        serviceline: id,
      },
      this.fetchData,
    );
  };

  onCareSettingChange = (event) => {
    this.setState({ careSetting: event.target.value }, this.fetchData);
  };
}

OrganizationMigrationPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  organization: PropTypes.object,
  migrations: PropTypes.object,
  servicelineId: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    migrations: state.migrations,
    servicelineId: ServiceLineUtil.getSelected(state),
  };
}

const styled = withStyles(styles)(OrganizationMigrationPage);
const connected = connect(mapStateToProps)(styled);
export { connected as OrganizationMigrationPage };
