import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const TruncatedText = ({ text, className }) => {
  const spanRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [overflowCount, setOverflowCount] = useState(0);

  useEffect(() => {
    const checkOverflow = () => {
      const span = spanRef.current;
      if (span) {
        if (text.length === 1) {
          return;
        }
        span.innerText = text.join(' ');
        const isOverflow = span.scrollWidth > span.clientWidth;
        if (!isOverflow && isOverflowing) {
          setIsOverflowing(false);
          setOverflowCount(0);
          span.innerText = text.slice(0, text.length - overflowCount).join(' ');
        }
        if (isOverflow) {
          const words = text;
          let truncatedText = words[0] + ' ';
          for (let i = 1; i < words.length; i++) {
            truncatedText += words[i] + ' ';
            span.innerText = truncatedText;
            if (span.scrollWidth > span.clientWidth) {
              setIsOverflowing(true);
              setOverflowCount(Math.max(0, words.length - i - 1));
              break;
            }
          }
        }
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);

    return () => {
      window.removeEventListener('resize', checkOverflow);
    };
  }, [text, isOverflowing]);

  return (
    <div
      className={className}
      style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
    >
      <span
        ref={spanRef}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {text.join(' ')}
      </span>
      {isOverflowing && overflowCount > 0 ? (
        <span style={{ marginLeft: '5px', marginRight: '5px' }}>
          (+{overflowCount})
        </span>
      ) : (
        ''
      )}
    </div>
  );
};
TruncatedText.propTypes = {
  text: PropTypes.array.isRequired,
  className: PropTypes.string,
};
export default TruncatedText;
