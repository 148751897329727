import { success, error, abort } from 'redux-saga-requests';
import jmespath from 'jmespath';

import {
  API_LOGIN,
  API_LOGOUT,
  API_AUTH_RENEW,
  API_AUTH_CHECK,
  API_AUTH_SMS_CODE,
  SHOW_LOGIN_ERROR_MESSAGE,
  SHOW_LOGIN_INFO_MESSAGE,
} from 'store/actions/loginActions';

const hasSessionDied = (action) => {
  // This already happens on RootSaga, why need it here? turning it off down below
  let responseObject;
  if (action.error && action.error.response) {
    responseObject = action.error.response;
  } else if (action.meta
    && action.meta.requestAction
    && action.meta.requestAction.response) {
    responseObject = action.meta.requestAction.response;
  }

  if (!responseObject) {
    return false;
  }

  if (responseObject.status === 401 || responseObject.status === 403) {
    return true;
  }

  return false;
};

export const user = (state = {}, action = null) => {
  switch (action.type) {
    case API_LOGOUT:
      return {};
    case API_LOGIN: {
    // check for path to return to
      let returnToURL = jmespath.search(state, 'meta.returnToURL');
      if (!returnToURL && action.returnToURL) {
        returnToURL = action.returnToURL;
      }
      return {
        ...state,
        meta: {
          ...state.meta,
          loginIsCalling: true,
          loginCallTime: (new Date()).getTime(),
          authenticated: false,
          errorMessage: '',
          infoMessage: '',
          returnToURL,
        },
      };
    }
    case success(API_LOGIN): {
      const response = action.data;
      if (response.authType === 'smsCode') {
        // two factor required
        return {
          ...state,
          meta: {
            ...state.meta,
            loginIsCalling: false,
            errorMessage: '',
            infoMessage: '',
            authType: 'smsCode',
            digits: response.digits,
            phone: response.phone,
          },
        };
      }
      // user logged in without two factor
      const userData = action.data;
      const newState = {
        ...state,
        ...userData,
        meta: {
          ...state.meta,
          loginIsCalling: false,
          authenticated: true,
          authenticatedTime: (new Date()).getTime(),
          errorMessage: '',
          infoMessage: '',
        },
      };
      return newState;
    }
    case error(API_LOGIN):
      return {
        ...state,
        meta: {
          ...state.meta,
          loginIsCalling: false,
          errorMessage: action.error.response.data.errors[0].message ?? 'Login failed. Please check your credentials.',
          infoMessage: '',
          authenticated: false,
        },
      };
    case abort(API_LOGIN):
      return {
        ...state,
        meta: {
          ...state.meta,
          loginIsCalling: false,
          authenticated: false,
          errorMessage: '',
          infoMessage: '',
        },
      };
    case success(API_AUTH_RENEW): {
      let userData;
      if (action.data.id) { // user data sent
        userData = action.data;
      }
      return {
        ...state,
        ...userData,
        meta: {
          ...state.meta,
          authenticated: true,
        },
      };
    }
    case API_AUTH_CHECK: {
      return {
        ...state,
        meta: {
          ...state.meta,
          loginIsCalling: true,
          loginCallTime: (new Date()).getTime(),
          authType: null,
          authenticated: false,
          errorMessage: '',
          infoMessage: '',
        },
      };
    }
    case success(API_AUTH_CHECK): {
      // save user to state
      const response = action.data;

      if (response.authType === 'saml') {
        // console.log('redirect to: ', response.loginUrl);
        window.location.replace(response.loginUrl); // redirect
      }
      const newState = {
        ...state,
        meta: {
          ...state.meta,
          loginIsCalling: false,
          authenticated: false,
          authType: response.authType,
          errorMessage: '',
          infoMessage: '',
        },
      };

      return newState;
    }
    case error(API_AUTH_CHECK): {
      return {
        ...state,
        meta: {
          ...state.meta,
          loginIsCalling: false,
          errorMessage: 'Login failed. Please check your credentials.',
          authType: null,
          infoMessage: '',
          authenticated: false,
        },
      };
    }
    case API_AUTH_SMS_CODE: {
      return {
        ...state,
        meta: {
          ...state.meta,
          loginIsCalling: true,
          loginCallTime: (new Date()).getTime(),
          authType: null,
          authenticated: false,
          errorMessage: '',
          infoMessage: '',
        },
      };
    }
    case success(API_AUTH_SMS_CODE): {
      // user logged in without two factor
      const userData = action.data;
      const newState = {
        ...state,
        ...userData,
        meta: {
          ...state.meta,
          loginIsCalling: false,
          authenticated: true,
          authenticatedTime: (new Date()).getTime(),
          errorMessage: '',
          infoMessage: '',
        },
      };

      return newState;
    }
    case error(API_AUTH_SMS_CODE): {
      return {
        ...state,
        meta: {
          ...state.meta,
          loginIsCalling: false,
          errorMessage: 'Login failed. Please try again, we will send a new validation code.',
          authType: null,
          infoMessage: '',
          authenticated: false,
        },
      };
    }
    case SHOW_LOGIN_ERROR_MESSAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          errorMessage: action.message,
          infoMessage: '',
        },
      };
    case SHOW_LOGIN_INFO_MESSAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          infoMessage: action.message,
          errorMessage: '',
        },
      };
    default:
      if (hasSessionDied(action)
      && state && state.meta
      && state.meta.authenticated) {
        return {
          meta: {
            returnToURL: action.pathname,
          },
        };
      }
      if (state && state.meta
      && state.meta.authenticated
      && state.meta.returnToURL
      && action.type.includes('LOCATION_CHANGE')
      && action.pathname === state.meta.returnToURL) {
        return {
          ...state,
          meta: {
            ...state.meta,
            returnToURL: null,
          },
        };
      }
      return state;
  }
};
