import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'utils';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Tabs, Tab, Typography } from '@mui/material';
import { UploadedDocuments } from './UploadedDocuments';
import { DataDownload } from './DataDownload';
const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.accent,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingTop: 3,
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.primary.accent,
  },
  indicator: {
    backgroundColor: '#FFFFFF',
    height: 2,
    marginBottom: 8,
  },
  headerDivider: {},
  tabPanel: {
    color: '#E0E0E0',
    '&.Mui-selected': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
});

class DocumentsPage extends React.Component {
  constructor(props) {
    super(props);
    const currentTab =  window.location.href.indexOf('data') > -1 ? 'data' : 'resources'
    this.state = {
      currentTab,
    };
  }

  render() {
    const { currentTab } = this.state;
    const { classes } = this.props;

    return (
      <>
        <div className={classes.container}>
          <Helmet defer={false}>
            <title>Documents</title>
          </Helmet>
          <div className={classes.header}>
            <Typography className={classes.headerTitle}>Documents</Typography>
          </div>
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            TabIndicatorProps={{ className: classes.indicator }}
            value={currentTab}
            onChange={this.onTabChange}
          >
            <Tab
              className={classes.tabPanel}
              label='Resources'
              value='resources'
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Data'
              value='data'
              disableRipple
            />
          </Tabs>
        </div>
        {currentTab === 'resources' && <UploadedDocuments />}
        {currentTab === 'data' && <DataDownload />}
      </>
    );
  }

  onTabChange = (_, currentTab) => {
    this.setState({ currentTab });
    if (currentTab === 'data') {
      this.props.router.navigate(`/documents/${currentTab}`, { replace: true });
    }
  }
}

DocumentsPage.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object,
  app: PropTypes.object,
  user: PropTypes.object,
  dispatch: PropTypes.func,
};

function mapStateToProps(state) {
  const { app, user } = state;

  return {
    app,
    user,
  };
}

const styled = withStyles(styles)(DocumentsPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as DocumentsPage };
