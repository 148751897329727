import enUS from 'date-fns/locale/en-US';
import formatRelative from 'date-fns/formatRelative';
import addDays from 'date-fns/addDays';

// TODO: get rid of this
import moment from 'moment';

const DAYSOFWEEK = [
  {
    value: 0,
    label: 'Sunday',
    abbreviation: 'Su',
  },
  {
    value: 1,
    label: 'Monday',
    abbreviation: 'M',
  },
  {
    value: 2,
    label: 'Tuesday',
    abbreviation: 'Tu',
  },
  {
    value: 3,
    label: 'Wednesday',
    abbreviation: 'W',
  },
  {
    value: 4,
    label: 'Thursday',
    abbreviation: 'Th',
  },
  {
    value: 5,
    label: 'Friday',
    abbreviation: 'F',
  },
  {
    value: 6,
    label: 'Saturday',
    abbreviation: 'S',
  },
];

const formatRelativeLocale = {
  lastWeek: "'Last' eeee",
  yesterday: "'Yesterday'",
  today: "'Today'",
  tomorrow: "'Tomorrow'",
  nextWeek: "eeee",
  other: 'M/d/yy',
};

const locale = {
  ...enUS,
  formatRelative: (token) => formatRelativeLocale[token],
};

const currentYear = Number(moment().format('YY'));
moment.parseTwoDigitYear = (yearString) => {
  const year = Number(yearString);
  if (year <= (currentYear + 1)) {
    return Number(yearString) + 2000;
  }
  return Number(yearString) + 1900;
};

class DateUtil {
  // fns https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  static get LDML_DISPLAY_DATE_TIME_FORMAT() {
    return 'MM/dd/yyy hh:mm aa';
  }

  static get LDML_DISPLAY_DATE_TIME_INPUT_FORMAT() {
    return 'MM/dd/yyyy hh:mm aa';
  }

  static get DISPLAY_DATE_TIME_FORMAT() {
    return 'MM/DD/YYYY hh:mm A';
  }

  // fns https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
  static get LDML_DISPLAY_FORMAT() {
    return 'MM/dd/yyyy';
  }

  static get DISPLAY_FORMAT() {
    return 'MM/DD/YYYY';
  }

  static get LDML_FORM_DATE_FORMAT() {
    return 'yyyy-MM-dd';
  }

  static get FORM_DATE_FORMAT() {
    return 'YYYY-MM-DD';
  }

  static get FORM_DATETIME_FORMAT() {
    return 'YYYY-MM-DDTHH:mm:ss.SSSZ';
  }

  static get DATENOW() {
    return moment().format('YYYY-MM-DD');
  }

  static inRange(date, start, end) {
    if (moment(date) <= moment(end) && moment(date) >= moment(start)) {
      return true;
    }

    return false;
  }

  static parseDateFromUser(dateUserString) {
    const date = moment(dateUserString, DateUtil.DISPLAY_FORMAT);
    return date;
  }

  static parseDateFromDB(dateDbString) {
    let date;
    if (dateDbString && dateDbString.length > 10) {
      date = moment(dateDbString.substring(0, 10)); // , SERVER_DATE_FORMAT
    } else if (dateDbString) {
      date = moment(dateDbString, DateUtil.FORM_DATE_FORMAT);
    }

    if (Number.isNaN(date)) {
      date = moment(dateDbString);
    }

    return date;
  }

  static formatDateForUser(date) {
    if (date) {
      return moment(date).format(DateUtil.DISPLAY_FORMAT);
    }

    return '';
  }

  static formatHistoryDateTimeFromDB(date) {
    if (date) {
      const m = `${moment.utc(date).local().format().substring(0, 20)}00:00`;
      return moment(m).format(DateUtil.DISPLAY_DATE_TIME_FORMAT);
    }
    return '';
  }

  static formatDateTimeFromDB(date) {
    if (date) {
      const m = moment(date, DateUtil.FORM_DATETIME_FORMAT);
      return m.format(DateUtil.DISPLAY_DATE_TIME_FORMAT);
    }
    return '';
  }

  static formatFromDB(date) {
    if (date) {
      const m = moment.utc(new Date(date), DateUtil.FORM_DATE_FORMAT);
      return m.format(DateUtil.DISPLAY_FORMAT);
    }
    return '';
  }

  static formatDisplayToDB(date) {
    if (date) {
      const m = moment.utc(new Date(date), DateUtil.DISPLAY_FORMAT);
      return m.format(DateUtil.FORM_DATE_FORMAT);
    }
    return '';
  }

  static formatToDB(date) {
    if (date) {
      return moment(date).format(DateUtil.FORM_DATE_FORMAT);
    }
    return '';
  }

  static formatNowForDB() {
    return moment().format();
  }

  static dayOfWeekToAbbreviation(dayOfWeek) {
    const day = DAYSOFWEEK.filter((d) => d.value === dayOfWeek);
    if (day.length > 0) {
      return day[0].abbreviation;
    }
    return null;
  }

  static formatFromNow(date) {
    const m = moment(date, DateUtil.FORM_DATETIME_FORMAT);
    return m.fromNow();
  }

  static formatRelativeToday(date) {
    if (typeof date === 'string' || date instanceof String) {
      date = new Date(date);
    }
    return formatRelative(date, new Date(), { locale });
  }

  static listUnitsBetween(startDate, endDate, units = 'days') {
    let days = [];
    let currentDate = moment(startDate);
    const lastDate = moment(endDate);
    while (currentDate <= lastDate) {
      days.push(moment(currentDate).format('YYYY-MM-DD'));
      currentDate = moment(currentDate).add(1, units);
    }

    return days;
  }
}

export { DateUtil, DAYSOFWEEK };
export default DateUtil;
