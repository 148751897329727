import { success } from 'redux-saga-requests';
import { ReducerUtil } from 'doctivity-shared/utils';
import {
  API_UPLOADS_LIST,
  API_UPLOAD_TYPES_LIST,
} from 'store/actions/uploadsActions';

export const uploads = (state = {
  list: [],
  types: {},
  meta: {
    loading: true,
  },
}, action = null) => {
  const result = ReducerUtil.reduceListGet(state, action, API_UPLOAD_TYPES_LIST, 'types');
  if (result.actionConsumed) {
    return result.state;
  }

  switch (action.type) {
    case API_UPLOADS_LIST:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
        },
      };
    case success(API_UPLOADS_LIST):
      return {
        ...state,
        list: action.data,
        meta: {
          ...state.meta,
          loading: false,
        },
      };
    default:
      return state;
  }
};
