import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { Typography, Button, Icon } from '@mui/material';

import { PhoneUtil, StoreUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import { DynamicTable } from 'components';
import { FindContactDialog } from 'contacts';

import { queryLocationContacts } from 'store/actions/locationsActions';
import { upsertContact } from 'store/actions/contactsActions';
import { ConfirmAddContactDialog } from 'locations';

const styles = (theme) => ({
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  header: {
    marginRight: theme.spacing(2),
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
});

// this controls which columns are displayed and how they are looked up in data
const columns = [
  {
    label: 'Last Name',
    key: 'last_name',
    fulltext: true,
    style: { minWidth: 100 },
  },
  {
    label: 'First Name',
    key: 'first_name',
    fulltext: true,
    style: { minWidth: 100 },
  },
  {
    label: 'Phone',
    key: 'phone',
    format: (phone) => PhoneUtil.formatNumber(phone),
  },
  {
    label: 'Email',
    key: 'email',
  },
];

class LocationContacts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      linkContactOpen: false,
      confirmAddContactData: undefined,
      query: {
        where: {},
        order: [['last_name', 'ASC']],
        limit: 25,
        offset: 0,
      },
    };
  }

  componentDidMount() {
    const { contacts } = this.props;

    if (StoreUtil.needsLoadNoCache(contacts)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { location, contacts, editingContacts } = this.props;

    if (!prevProps.location || location.id !== prevProps.location.id) {
      this.fetchData();
    }

    // is object getting edited?
    if (editingContacts !== prevProps.editingContacts) {
      if (StoreUtil.hasSavedSinceLoad(editingContacts, contacts)) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const { location, clientId } = this.props;

    if (location && location.id) {
      this.props.dispatch(
        queryLocationContacts(location.id, clientId, this.state.query)
      );
    }
  }

  render() {
    const { classes, contacts, clientId, location } = this.props;

    const { query, linkContactOpen, confirmAddContactData } = this.state;

    return (
      <>
        <DynamicTable
          header={
            <div className={classes.headerContainer}>
              <Typography
                component='h2'
                variant='h6'
                color='primary'
                gutterBottom
              >
                Contacts
              </Typography>
              <div className={classes.header} />
              <Button
                color='primary'
                size='small'
                onClick={this.onOpenLinkContact}
              >
                <Icon className={classes.leftIcon}>add</Icon>
                Link Contact
              </Button>
            </div>
          }
          showFilters={true}
          noDataMessage='No contacts in this location.'
          columns={columns}
          query={query}
          data={contacts}
          onQueryChange={this.onQueryChange}
          onRowClick={this.onRowClick}
        />
        <FindContactDialog
          clientId={clientId}
          open={linkContactOpen}
          onSelect={this.onContactSelected}
        />
        {confirmAddContactData && (
          <ConfirmAddContactDialog
            open={!!confirmAddContactData}
            locationData={location}
            contact={confirmAddContactData}
            onConfirm={this.onConfirmAddContact}
            onClose={this.closeConfirmAddContactDialog}
          />
        )}
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData.bind(this));
  };

  onRowClick = (row) => {
    this.props.router.navigate(`/contacts/${row.id}`);
  };

  onOpenLinkContact = () => {
    this.setState({
      linkContactOpen: true,
    });
  };

  onCloseLinkContact = () => {
    this.setState({
      linkContactOpen: false,
    });
  };

  onConfirmAddContact = (contact) => {
    const { location, dispatch } = this.props;

    if (contact && location && location.id) {
      dispatch(upsertContact({ ...contact, location_id: location.id }));
    }
    this.onCloseLinkContact();
  };

  closeConfirmAddContactDialog = () => {
    this.setState({ confirmAddContactData: undefined });
  };

  onContactSelected = (contact) => {
    const { location } = this.props;

    if (contact && location && location.id) {
      this.setState({ confirmAddContactData: contact });
    } else {
      // cancel sends a null contact, just close
      this.onCloseLinkContact();
    }
  };
}

LocationContacts.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  contacts: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  editingContacts: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    clientId: state.app.selectedClient,
    contacts: StoreUtil.get(state.locations, 'contacts'),
    editingContacts: StoreUtil.get(state.contacts, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(LocationContacts);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as LocationContacts };
