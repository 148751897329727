import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  FormControl,
  InputLabel,
  Typography,
  Button,
  Select,
  MenuItem,
} from '@mui/material';
import { SpecialtiesTreeDialog } from './SpecialtiesTreeDialog';

const styles = (theme) => ({
  filterDropDown: {
    width: 168,
    marginRight: theme.spacing(),
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  lockIcon: {
    fontSize: 'inherit',
    verticalAlign: 'text-top',
    marginRight: 2,
    paddingRight: 6,
    color: '#999999',
  },
  parentServiceline: {
    borderTop: '1px solid #cccccc',
  },
  subServiceline: {
    fontSize: 14,
    paddingLeft: theme.spacing(4),
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
  select: {
    width: '100%',
  },
});

class TaxonomySelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      specialtiesTreeOpen: false,
      label: 'All',
    };
  }

  render() {
    const { classes, values } = this.props;

    const { label, specialtiesTreeOpen } = this.state;

    return (
      <>
        <FormControl
          variant='outlined'
          className={classes.filterDropDown}
          key='filter-taxonomy'
        >
          <InputLabel>Specialties</InputLabel>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              this.openSpecialtiesTreeDialog();
            }}
            className={classes.button}
            disableRipple
          >
            <Select
              value='custom'
              label='Specialties'
              open={false}
              className={classes.select}
              renderValue={() => {
                return (
                  <Typography align='left' noWrap>
                    {label}
                  </Typography>
                );
              }}
            >
              <MenuItem value='custom'></MenuItem>
            </Select>
          </Button>
        </FormControl>
        <SpecialtiesTreeDialog
          open={specialtiesTreeOpen}
          onClose={this.closeSpecialtiesTreeDialog}
          onChange={this.onSpecialtiesTreeChangeChange}
          values={values}
        />
      </>
    );
  }

  onSpecialtiesTreeChangeChange = (selectedTaxonomies, userChanged) => {
    const label =
      selectedTaxonomies?.length > 0
        ? selectedTaxonomies.map((taxonomy) => taxonomy.name).join(', ')
        : 'All';

    if (userChanged) {
      this.props.onChange(selectedTaxonomies);
      this.closeSpecialtiesTreeDialog();
    }
    this.setState({ label });
  };

  openSpecialtiesTreeDialog = () => {
    this.setState({ specialtiesTreeOpen: true });
  };

  closeSpecialtiesTreeDialog = () => {
    this.setState({ specialtiesTreeOpen: false });
  };
}

TaxonomySelect.propTypes = {
  classes: PropTypes.object,
  dispatch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.array,
};

function mapStateToProps() {
  return {};
}

const styled = withStyles(styles)(TaxonomySelect);
const connected = connect(mapStateToProps)(styled);
export { connected as TaxonomySelect };
