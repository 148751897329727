import React from 'react';
import { PropTypes } from 'prop-types';

import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
  loader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  progress: {
    margin: theme.spacing(2),
  },
  label: {
    fontSize: 14,
  },
});

class LoadingView extends React.Component {
  render() {
    const {
      classes,
      label,
    } = this.props;

    return (
      <div className={classes.loader}>
        <CircularProgress className={classes.progress} color='primary' />
        {
          label
          && (
            <Typography className={classes.label}>
              {label}
            </Typography>
          )
        }
      </div>
    );
  }
}

LoadingView.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
};

const styled = withStyles(styles)(LoadingView);
export { styled as LoadingView };
