import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import axiosInstance from 'utils/axiosUtil';

import {
  StoreUtil,
} from 'doctivity-shared/utils';
import {
  TableUtil,
  withRouter,
} from 'utils';
import {
  BreadcrumbRow,
  DynamicTable,
  FixedHeader,
  LoadingView,
  SmallIconTextButton,
} from 'components';

import {
  Button,
  Icon,
  Typography,
} from '@mui/material';

import {
  queryTagNamespaces,
  upsertTagNamespace,
} from 'store/actions/tagNamespacesActions';

import { showConfirmationDialog } from 'store/actions/systemActions';
import { TagNamespaceEditDialog } from './TagNamespaceEditDialog';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  spacer: {
    flex: 1,
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  switchLabel: {
    fontSize: 12,
    fontWeight: 200,
  },
  title: {
    padding: theme.spacing(3),
    paddingBottom: 51,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    backgroundColor: theme.palette.primary.accent,
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  headerButtonText: {
    color: '#FFF'
  },
  regRow: {
    '& .manageIcons': {
      visibility: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiButton-root': {
        color: theme.palette.tertiary.accent,
        padding: 0,
      },
    },
    '&:hover': {
      '& .manageIcons': {
        visibility: 'visible',
      },
    },
  },
  altRow: {
    '& .manageIcons': {
      visibility: 'hidden',
      display: 'flex',
      justifyContent: 'flex-start',
      '& .MuiButton-root': {
        color: theme.palette.tertiary.accent,
        padding: 0,
      },
    },
    '&:hover': {
      '& .manageIcons': {
        visibility: 'visible',
      },
    },
  },


});

// this controls which columns are displayed and how they are looked up in data
const columns = (self) => ([
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Activity Notes',
    key: 'activity_notes_drop_down',
    format: TableUtil.boolToCheckIcon,
    style: { width: 150 },
  },
  {
    label: 'Providers',
    key: 'providers_drop_down',
    format: TableUtil.boolToCheckIcon,
  },
  {
    label: 'Organizations',
    key: 'organizations_drop_down',
    format: TableUtil.boolToCheckIcon,
  },
  {
    label: '',
    key: 'tags',
    style: { width: 50 },
    sortable: false,
    showFilter: false,
    format: (_clients, row) => {
      return (
        <span className='manageIcons' key={`manageRow-${row.id}`}>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              self.onRemoveLabel(row);
              return false;
            }}
          >
            <Icon>cancel</Icon>
          </Button>
        </span>
      );
    },
  },

]);

class TagNamespacesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: {
        order: [
          ['name', 'ASC'],
        ],
        include: [
          {
            attributes: ['id', 'name'],
            association: 'client',
          },
        ],
        limit: 50,
        offset: 0,
      },
      addDialogOpen: false,
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.tagNamespaces)) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    // is object getting edited?
    if (this.props.editing !== prevProps.editing) {
      // if so and it is newer than the list, update list
      if (StoreUtil.hasSavedSinceLoad(this.props.editing, this.props.tagNamespaces)) {
        this.fetchData();
      }
    }
  }

  fetchData() {
    const {
      query,
    } = this.state;

    if (!query.where) {
      query.where = { };
    }
    query.where.client_id = this.props.clientId;
    this.props.dispatch(queryTagNamespaces(query));
  }

  render() {
    const {
      tagNamespaces,
      editing,
      classes,
    } = this.props;

    const {
      query,
    } = this.state;

    // is a user being edited now?
    if (StoreUtil.isSaving(editing)) {
      return (<LoadingView />);
    }

    return (
      <>
        <Helmet defer={false}>
          <title>Labels</title>
        </Helmet>
        <FixedHeader>
          <div className={classes.breadcrumb}>
            <BreadcrumbRow
              label='Labels'
              path='/labels'
              icon='chevron_left'
            />
          </div>
        </FixedHeader>
        <div className={classes.container}>
          <div className={classes.detailCard}/>
          <DynamicTable
            header={
              <div className={classes.headerContainer}>
                <Typography
                  component='h2'
                  variant='h6'
                  color='primary'
                  gutterBottom
                >
                  Label Types
                </Typography>
                <div className={classes.spacer} />
                <div className={classes.spacer} />
                <SmallIconTextButton
                  onClick={this.onOpenAddDialog}
                  icon='add'
                  text='Add Label Type'
                />
            </div>
            }
            classes={{
              regRow: classes.regRow,
              altRow: classes.altRow,
            }}
            columns={columns(this)}
            query={query}
            data={!StoreUtil.isLoading(tagNamespaces) && tagNamespaces}
            onQueryChange={this.onQueryChange}
            onRowClick={(row) => {
              if (row.id) {
                this.props.router.navigate(`/label_types/${row.id}`);
              }
            }}
          />
          <TagNamespaceEditDialog
            open={this.state.addDialogOpen}
            onSave={this.onSaveTagNamespace}
            onClose={this.onCloseAddDialog}
          />
        </div>
      </>
    );
  }

  onBack = () => {
    this.props.router.navigate('/labels');
  };

  onQueryChange = (query) => {
    this.setState({ query }, this.fetchData);
  };

  onOpenAddDialog = () => {
    this.setState({
      addDialogOpen: true,
    });
  };

  onCloseAddDialog = () => {
    this.setState({
      addDialogOpen: false,
    });
  };

  onSaveTagNamespace = (tagNamespace) => {
    tagNamespace.user_id = this.props.user.id;
    this.props.dispatch(upsertTagNamespace(tagNamespace));
    this.onCloseAddDialog();
  };

  onRemoveLabel = async(row) => {
    const params = new URLSearchParams();
    params.append('label_type_id', row.id);
    const { dispatch } = this.props;
    const counts = await axiosInstance.get(`/v2/label_types/counts?${params.toString()}`);
    if (counts && counts.data && counts.data.label_total === 0) {
      dispatch(
        showConfirmationDialog({
          title: 'Delete Label Type',
          content: `Are you sure you want to remove the label type ${row.name} and all of it's labels?`,
          confirmLabel: 'Remove',
          onConfirm: async () => {
            const removeResult = await axiosInstance.delete(`/v2/label_types/${row.id}`);
            if (removeResult && removeResult.data && removeResult.data.success) {
              // success!
            } else {
              alert('There was a problem removing this label type. Please try again or contact Doctivity support for help.');
            }
            this.fetchData();
          },
          onCancel: () => {
            // no-op
          }
        })
      );
    } else {
      dispatch(
        showConfirmationDialog({
          title: 'Remove Label Type',
          content:
            'You cannot remove a label type that has labels associated with it. Please remove those labels first.',
          confirmLabel: 'OK',
          onConfirm: () => {
          },
        })
      );
    }
  }


}

TagNamespacesPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  user: PropTypes.object,
  tagNamespaces: PropTypes.object.isRequired,
  editing: PropTypes.object,
  clientId: PropTypes.number,
};

function mapStateToProps(state) {
   const {
    app
  } = state;

 return {
    user: state.user,
    clientId: app.selectedClient,
    tagNamespaces: StoreUtil.get(state.tagNamespaces, StoreUtil.COMMON_TABLE),
    editing: StoreUtil.get(state.tagNamespaces, StoreUtil.COMMON_EDIT_ITEM),
  };
}

const styled = withStyles(styles)(TagNamespacesPage);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as TagNamespacesPage };
