import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

export const Expandable = ({ baseHeight, children, className }) => {
  const [expanded, setExpanded] = useState(false);
  const [overflow, setOverflow] = useState(false);

  return (
    <>
      <div
        className={className}
        style={{
          position: 'relative',
          overflow: 'hidden',
          maxHeight: expanded ? null : baseHeight ?? 200,
        }}
        ref={(v) => v && setOverflow(v.offsetHeight < v.scrollHeight)}
      >
        {children}
      </div>
      {overflow && <Button onClick={() => setExpanded(true)}>Show More</Button>}
    </>
  );
};

Expandable.propTypes = {
  children: PropTypes.node,
  baseHeight: PropTypes.number,
  className: PropTypes.string,
};

export default Expandable;
