import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { ValidationUtil } from 'doctivity-shared/utils';
import { withRouter } from 'utils';
import axiosInstance from 'utils/axiosUtil';
import { showLoginErrorMessage } from 'store/actions/loginActions';

const styles = ({
  page: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width: '100%',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    marginTop: 30,
  },
  helpContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 400,
    marginTop: 30,
    textAlign: 'center',
  },
});

class ConfirmRecoveryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        password: '',
        confirmPassword: '',
      },
      error: null,
      isRegistering: false,
      registerSuccess: false,
      passwordLength: ''
    };

    this.onChange = this.onChange.bind(this);
  }

  async componentDidMount() {
    const location = this.props.router.location;
    const token = new URLSearchParams(location.search).get('token');
    if (token){
      axiosInstance.get(`/auth/recovery/${token}`).then(async (response) => {
        if (!response.data.result.isValid) {
          this.props.dispatch(showLoginErrorMessage(response.data.message));
          this.props.router.navigate('/login');
        }
        await this.setPasswordLength(response.data.result.password_length)
      });
    }
    else {
      await this.setPasswordLength(8)
    }
    
  }

  async setPasswordLength(passwordLength) {
    this.setState({passwordLength: passwordLength})
  }

  render() {
    const {
      // user,
      classes,
    } = this.props;

    const {
      form,
      registerSuccess,
    } = this.state;

    if (registerSuccess) {
      return (
        <Card className={classes.container}>
          <CardContent>
            <Typography>Your password has been reset.</Typography>
            <Link to='/login'><Button variant='contained' color='primary'>Login</Button></Link>
          </CardContent>
        </Card>
      );
    }

    return (
      <Card className={classes.container}>
        <form className={classes.form}>
          <CardHeader title='Reset Password' />
          <CardContent>
            {this.state.error ? <Typography color='error'>{this.state.error}</Typography> : null}
            <TextField
              id='password'
              type='password'
              name='password'
              label='Password'
              placeholder='Enter password'
              onChange={this.onChange}
              value={form.password}
              className={classes.textField}
              margin='normal'
              fullWidth
              helperText={`Password must contain a lowercase letter, an uppercase letter, a number, a special character, and be at least ${this.state.passwordLength} characters long.`}
            />
            <TextField
              id='confirmPassword'
              type='password'
              name='confirmPassword'
              label='Confirm Password'
              placeholder='Enter password'
              onChange={this.onChange}
              value={form.confirmPassword}
              className={classes.textField}
              margin='normal'
              fullWidth
            />
            <div className={classes.buttonContainer}>
              <Button
                id='login'
                className={classes.button}
                color='primary'
                margin='normal'
                variant='contained'
                disabled={this.registerButtonDisabled()}
                onClick={this.onSubmit}
              >
                Submit
              </Button>
            </div>
          </CardContent>
        </form>
      </Card>
    );
  }

  registerButtonDisabled() {
    const {
      form,
      isRegistering,
    } = this.state;

    if (isRegistering) {
      return true;
    }

    if (!form.password || !form.confirmPassword) {
      return true;
    }

    return false;
  }

  onChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [e.target.name]: e.target.value,
      },
    }));
  };

  onSubmit = () => {
    const {
      password,
      confirmPassword,
    } = this.state.form;
    const location = this.props.router.location;

    if (password !== confirmPassword) {
      this.setState({
        error: 'Passwords must match.',
      });
      return;
    }

    if (!ValidationUtil.isPasswordValid(password, this.state.passwordLength)) {
      this.setState({
        error: `Password must contain a lowercase letter, an uppercase letter, a number, a special character, and be at least ${this.state.passwordLength} characters long.`,
      });
      return;
    }

    const token = new URLSearchParams(location.search).get('token');
    axiosInstance.post(`/auth/recovery/${token}`, {
      token,
      password: this.state.form.password,
      confirmPassword: this.state.form.confirmPassword,
    }).then((response) => {
      this.setState({
        registerSuccess: response.data,
      });
    }).catch((err) => {
      this.setState({
        error: err.response.data.error.message,
      });
    });
  };
}

ConfirmRecoveryPage.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

function mapStateToProps() {
  return {};
}

const styledPage = withStyles(styles)(ConfirmRecoveryPage);
const connected = connect(mapStateToProps)(styledPage);
const routed = withRouter(connected);

export { routed as ConfirmRecoveryPage };
