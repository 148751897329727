import React from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export function useAppbarHeight() {
  const {
    mixins: { toolbar },
    breakpoints,
  } = useTheme();
  const toolbarDesktopQuery = breakpoints.up('sm');
  const toolbarLandscapeQuery = `${breakpoints.up(
    'xs'
  )} and (orientation: landscape)`;
  const isDesktop = useMediaQuery(toolbarDesktopQuery);
  const isLandscape = useMediaQuery(toolbarLandscapeQuery);
  let currentToolbarMinHeight;
  if (isDesktop) {
    currentToolbarMinHeight = {
      minHeight: 0,
    };
  } else if (isLandscape) {
    currentToolbarMinHeight = toolbar[toolbarLandscapeQuery] ?? {
      minHeight: 48,
    };
  } else {
    currentToolbarMinHeight = toolbar;
  }
  return currentToolbarMinHeight.minHeight;
}

const withAppbarHeight = (Component) =>
  function WithAppbarHeight(props) {
    const appbarHeight = useAppbarHeight();

    return <Component {...props} appbarHeight={appbarHeight} />;
  };

export { withAppbarHeight };
export default withAppbarHeight;
