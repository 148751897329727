import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    fontSize: 14,
  },
  locationStack: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  locationStackTitle: {
    fontWeight: 600,
    fontSize: 18,
  },
  spacer: {
    marginBottom: theme.spacing(2),
  },
  existingLocation: {
    marginRight: theme.spacing(12),
  },
});

class ConfirmAddProviderDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, locationData, provider, open, onClose } = this.props;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby='simple-dialog-title'
        open={open}
      >
        <div className={classes.container}>
          <DialogTitle className={classes.title}>
            Confirm New Location For {provider.last_name}, {provider.first_name}{' '}
            (NPI {provider.npi})
          </DialogTitle>
          <DialogContent>
            <div className={classes.content}>
              <div className={classes.locationStack}>
                {provider.location ? (
                  <div className={classes.existingLocation}>
                    <Typography className={classes.locationStackTitle}>
                      Current Location
                    </Typography>
                    {provider.location.organization && (
                      <Typography color='primary'>
                        {provider.location.organization.name}
                      </Typography>
                    )}
                    <Typography>{provider.location.address1},</Typography>
                    {provider.location.address2 && (
                      <Typography>{provider.location.address2},</Typography>
                    )}
                    <Typography>{`${provider.location.city}, ${provider.location.state} ${provider.location.postal_code}`}</Typography>
                  </div>
                ) : (
                  <div className={classes.existingLocation}>
                    <Typography className={classes.locationStackTitle}>
                      Current Location
                    </Typography>
                    <Typography>None</Typography>
                  </div>
                )}
                <div>
                  <Typography className={classes.locationStackTitle}>
                    New Location
                  </Typography>
                  {locationData.organization && (
                    <Typography color='primary'>
                      {locationData.organization.name}
                    </Typography>
                  )}
                  {/* <Typography color='primary'>{locationData.name}</Typography> */}
                  <Typography>{locationData.address1},</Typography>
                  {locationData.address2 && (
                    <Typography>{locationData.address2},</Typography>
                  )}
                  <Typography>{`${locationData.city}, ${locationData.state} ${locationData.postal_code}`}</Typography>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button color='primary' onClick={onClose}>
              Cancel
            </Button>
            <Button
              color='primary'
              variant='contained'
              onClick={this.onConfirm}
            >
              Confirm
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }

  onConfirm = () => {
    const { provider, onConfirm, onClose } = this.props;

    if (onConfirm) {
      onConfirm(provider);
    }
    onClose();
  };
}

ConfirmAddProviderDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  locationData: PropTypes.object.isRequired,
  provider: PropTypes.object.isRequired,
};

function mapStateToProps() {
  return {};
}

const styled = withStyles(styles)(ConfirmAddProviderDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as ConfirmAddProviderDialog };
