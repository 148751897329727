import React,
{
  Component,
} from 'react';
import { PropTypes } from 'prop-types';
import {
  Routes,
  Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import {
  Typography,
  Hidden,
} from '@mui/material';

import {
  LoginPage,
  RegisterPage,
  RecoveryPage,
  ConfirmRecoveryPage,
  SAMLPage,
} from 'containers/pages';

import { Header } from 'containers/Header';
import { Footer } from 'components/Footer';

import Background from 'assets/images/login_background.jpg';
import Logo from 'assets/images/login_logo.svg';

const styles = (theme) => ({
  page: {
    fontFamily: 'sans-serif',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    boxSizing: 'border-box',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexGrow: 1,
    width: '100%',
  },
  background: {
    position: 'absolute',
    width: '100%',
    minHeight: '100vh',
    top: 0,
    left: 0,
    backgroundImage: `url(${Background})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: 'blur(3px)',
    '-webkit-filter': 'blur(3px)',
    opacity: 0.35,
    zIndex: -1,
  },
  actionArea: {
    width: '100%',
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-evenly',
  },
  spacer: {
    flex: 1,
  },
  sell: {
    fontSize: 36,
    [theme.breakpoints.down('md')]: {
      fontSize: 24,
    },
    fontWeight: 600,
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    paddingLeft: 30,
    paddingRight: 30,
  },
  logo: {
    marginTop: 80,
    [theme.breakpoints.up('md')]: {
      height: 134,
    },
    [theme.breakpoints.down('md')]: {
      width: '80%',
    },
  },
});

class LoginRoot extends Component {
  render() {
    const {
      classes,
    } = this.props;

    return (
      <div className={classes.page}>
        <div className={classes.background} />
        <div className={classes.content}>
          <Hidden mdDown>
            <Header />
          </Hidden>
          <div className={classes.spacer} />
          <div className={classes.actionArea}>
            <div>
              <Routes>
                <Route
                  exact
                  path='/saml/:success'
                  element={<SAMLPage />}
                />
                <Route
                  exact
                  path='/register'
                  element={<RegisterPage />}
                />
                <Route
                  exact
                  path='/recovery'
                  element={<RecoveryPage />}
                />
                <Route
                  exact
                  path='/recovery/confirm'
                  element={<ConfirmRecoveryPage />}
                />
                <Route
                  path='*'
                  element={<LoginPage />}
                />
              </Routes>
            </div>
            <img src={Logo} alt='Doctivity Logo' className={classes.logo} />
          </div>
          <div className={classes.spacer} />
          <Typography className={classes.sell}>
            Driving Over $60M in Unplanned Revenue to Date
          </Typography>
          <div className={classes.spacer} />
          <Footer />
        </div>
      </div>
    );
  }
}

LoginRoot.propTypes = {
  classes: PropTypes.object.isRequired,
};

function mapStateToProps() {
  return { };
}

const styledApp = withStyles(styles)(LoginRoot);
const connectedApp = connect(mapStateToProps)(styledApp);
export { connectedApp as LoginRoot };
