const { ja } = require("date-fns/locale");

const PatientType = {
  ALL_PATIENTS: 'ALL_PATIENTS',
  PEDIATRIC_PATIENTS: 'PEDIATRIC_PATIENTS',
  CAPTURED_CLAIMS: 'CAPTURED_CLAIMS',
};

const PatientTypes = Object.keys(PatientType);

const PatientTypeDisplay = {
  [PatientType.ALL_PATIENTS]: 'Projected Patients',
  [PatientType.PEDIATRIC_PATIENTS]: 'Projected Pediatric Patients',
  [PatientType.CAPTURED_CLAIMS]: 'Captured Claims',
};

const ClaimQueryType = {
  CLAIMS_PER_PROVIDER: 'CLAIMS_PER_PROVIDER',
  CLAIMS_PER_PROVIDER_COUNTS: 'CLAIMS_PER_PROVIDER_COUNTS',
  CLAIMS_PER_ORGANIZATION: 'CLAIMS_PER_ORGANIZATION',
  CLAIMS_PER_ORGANIZATION_COUNTS: 'CLAIMS_PER_ORGANIZATION_COUNTS',
};

module.exports = {
  PatientType,
  PatientTypes,
  PatientTypeDisplay,
  ClaimQueryType
}
