import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Helmet } from 'react-helmet';
import withStyles from '@mui/styles/withStyles';
import { PatientType, PatientTypes } from 'doctivity-shared/utils';
import { QueryParamUtil, MarketsUtil, withRouter } from 'utils';
import { Typography, Tabs, Tab } from '@mui/material';
import { ClaimsPage } from 'containers/pages';
import { setPatientType } from 'store/actions/appActions';
import { ClaimsSharedAffiliation } from './ClaimsSharedAffiliation';
import { setMarket } from 'store/actions/appActions';
import { ClaimsPageQueryParams } from 'params';
import ServiceLineUtil from '../utils/ServiceLineUtil';
import { PayorChart } from 'market-data';

const styles = (theme) => ({
  container: {
    backgroundColor: theme.palette.primary.accent,
  },
  header: {
    padding: theme.spacing(3),
    paddingBottom: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  headerTitle: {
    color: '#FFFFFF',
    fontSize: 24,
    paddingRight: 30,
  },
  tabs: {
    paddingTop: 3,
    paddingLeft: theme.spacing(3),
    backgroundColor: theme.palette.primary.accent,
  },
  indicator: {
    backgroundColor: '#FFFFFF',
    height: 2,
    marginBottom: 8,
  },
  headerDivider: {},
  tabPanel: {
    color: '#E0E0E0',
    '&.Mui-selected': {
      color: '#FFFFFF',
    },
    '&:hover': {
      color: '#FFFFFF',
    },
  },
});

class ClaimsTabs extends React.Component {
  constructor(props) {
    super(props);

    const query = this.getQuery();

    const tab = (
      query.report ??
      ((query.patientType ?? props.patientType) ===
          PatientType.CAPTURED_CLAIMS ?
        PatientType.CAPTURED_CLAIMS :
        PatientType.ALL_PATIENTS)
    ).toLowerCase();

    this.state = {
      tab,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;

    const query = this.getQuery();

    if (query.patientType) {
      dispatch(setPatientType(query.patientType.toUpperCase()));
    }

    if (query.market) {
      dispatch(setMarket(query.market));
    }
  }

  getQuery = () => {
    const { router } = this.props;

    const query = {};
    
    query.report = router.query.get(ClaimsPageQueryParams.REPORT);

    query.patientType = router.query.get(
      ClaimsPageQueryParams.PATIENT_TYPE.name
    );

    query.serviceline = QueryParamUtil.paramToArray(
      router.query.get(ClaimsPageQueryParams.SERVICELINE.name)
    );

    query.codes = QueryParamUtil.paramToArray(router.query.get('codes'));

    query.taxonomies = QueryParamUtil.paramToArray(
      router.query.get(ClaimsPageQueryParams.TAXONOMIES.name)
    );

    query.taxonomies = QueryParamUtil.paramToArray(
      router.query.get(ClaimsPageQueryParams.TAXONOMIES.name)
    );


    query.claimSetting = parseInt(
      router.query.get(ClaimsPageQueryParams.CARE_SETTING.name) ?? 0
    );

    query.claimsType = router.query.get(ClaimsPageQueryParams.CLAIMS_TYPE.name);

    query.market = parseInt(
      router.query.get(ClaimsPageQueryParams.MARKET.name)
    );

    query.isMarket = router.query.get('isMarket') === 'true';
    query.tag = parseInt(router.query.get('tag'));
    query.groupByServicelines = parseInt(router.query.get('groupByServicelines'));

    query.report = router.query.get('report');

    query.claimsDate = router.query.get(ClaimsPageQueryParams.CLAIMS_DATE.name);

    query.affiliation = router.query.get(
      ClaimsPageQueryParams.AFFILIATION.name
    );

    query.codesType = router.query.get('codesType');

    return query;
  };


  render() {
    const { classes } = this.props;
    const { tab } = this.state;

    const query = this.getQuery();
    let tabContent = <></>;
    switch(tab) {
      case 'shared_affiliation':
        tabContent = <ClaimsSharedAffiliation query={query} router={this.props.router} />
        break;
      case 'payors':
        tabContent = <PayorChart />
        break;
      default: 
        tabContent= <ClaimsPage query={query} router={this.props.router} />
    }



    return (
      <>
        <div className={classes.container}>
          <Helmet defer={false}>
            <title>Market Data</title>
          </Helmet>
          <div className={classes.header}>
            <Typography className={classes.headerTitle}>Market Data</Typography>
          </div>
          <Tabs
            variant='scrollable'
            className={classes.tabs}
            TabIndicatorProps={{ className: classes.indicator }}
            value={tab}
            onChange={this.onTabChange}
          >
            <Tab
              className={classes.tabPanel}
              label='Projected Patients'
              value={PatientType.ALL_PATIENTS.toLowerCase()}
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Captured Claims'
              value={PatientType.CAPTURED_CLAIMS.toLowerCase()}
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Shared Affiliation'
              value='shared_affiliation'
              disableRipple
            />
            <Tab
              className={classes.tabPanel}
              label='Payors'
              value='payors'
              disableRipple
            />
          </Tabs>
        </div>
        {tabContent}
      </>
    );
  }

  onTabChange = (event, newValue) => {
    const { router, dispatch } = this.props;
    switch(newValue) {
      case 'shared_affiliation':
        QueryParamUtil.setQueryParamsFromProps(
          { report: 'shared_affiliation', router },
          ClaimsPageQueryParams
        );
        break;
      case 'payors':
        QueryParamUtil.setQueryParamsFromProps(
          { report: 'payors', router },
          ClaimsPageQueryParams
        );
        break;
      default:
        dispatch(setPatientType(newValue.toUpperCase()));
        const query = this.getQuery();
        if (query.serviceline === 'all' || query.serviceline?.at(0) === 'all') {
          query.serviceline = ServiceLineUtil.getDefault();
        }
        router.navigate('/claims');
    }
    this.setState({ tab: newValue });
  };
}

ClaimsTabs.propTypes = {
  router: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  patientType: PropTypes.oneOf(PatientTypes),
  market: PropTypes.object,
  user: PropTypes.object,
  classes: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    patientType: state.app.patientType,
    market: MarketsUtil.getSelectedMarket(state),
    user: state.user,
  };
}

const styled = withStyles(styles)(ClaimsTabs);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ClaimsTabs };
