import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import {
  Card,
  CardHeader,
  CardContent,
  TextField,
  Button,
  Typography,
} from '@mui/material';
import {
  LoadingView,
} from 'components';
import {
  checkUser,
  loginUser,
  validateSMSCode,
} from 'store/actions/loginActions';
import { wakeupServer } from 'store/actions/systemActions';
import { withIsMobile, withRouter } from 'utils';

const styles = (theme) => ({
  container: {
    marginTop: 50,
    width: 350,
  },
  spinner: {
    margin: 40,
  },
  textField: {
    // allow last pass to work here, it is disabled everywhere else in index.html
    '& div[data-lastpass-icon-root]': {
      display: 'inline',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  button: {
    width: 150,
  },
  forgotPasswordContainer: {
    textAlign: 'right',
    marginBottom: '5px',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
  cardContent: {
    marginTop: 0,
    paddingTop: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  codeInput: {
    width: 40,
    marginRight: 6,
  },
  mobileCodeInput: {
    marginRight: 6,
  },
  link: {
    color: '#000000DE',
  },
  spacer: {
    flex: 1,
  },
});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.codeInputs = [null, null, null, null, null, null];

    // set the initial component state
    this.state = {
      userInput: {
        email: '',
        password: '',
        smsCode: ['', '', '', '', '', '', '', ''],
      },
      emailEntered: false,
    };
  }

  componentDidMount() {
    this.checkWakeupTime();
  }

  componentDidUpdate() {
    const { user } = this.props;
    const { emailEntered, userInput } = this.state;
    this.checkWakeupTime();

    // If email screen but 2FA code is complete, it must have failed, so clear it
    if (
      (!user.meta || !user.meta.authType || !emailEntered) &&
      this.isCodeCompleted()
    ) {
      this.setState({
        userInput: { ...userInput, smsCode: ['', '', '', '', '', '', '', ''] },
      });
    }
  }

  checkWakeupTime() {
    const { system } = this.props;

    // server needs to be woken up every 15 min...
    if (
      !system ||
      !system.wakeup ||
      (!system.wakeup.loading &&
        (!system.wakeup.lastMade ||
          system.wakeup.lastMade + 15 * 60 * 1000 < new Date().getTime()))
    ) {
      this.wakeupServer();
    }
  }

  wakeupServer() {
    const { dispatch } = this.props;

    dispatch(wakeupServer());
  }

  checkUser() {
    const { email } = this.state.userInput;
    const { dispatch } = this.props;

    if (email) {
      dispatch(checkUser(email));
    }
  }

  login() {
    const { email, password } = this.state.userInput;
    const { dispatch } = this.props;

    if (email && password) {
      dispatch(loginUser(email, password, window.location.pathname));
    }
  }

  isCodeCompleted = () => {
    const { email, smsCode } = this.state.userInput;
    const { user } = this.props;

    if (user.meta) {
      const code = smsCode.slice(0, user.meta.digits).join('');
      if (email && code && code.length === user.meta.digits) {
        return true;
      }
    }

    return false;
  };

  validateCode() {
    const { email, smsCode } = this.state.userInput;
    const { dispatch, user } = this.props;

    const code = smsCode.slice(0, user.meta.digits).join('');
    if (email && code && code.length === user.meta.digits) {
      dispatch(validateSMSCode(email, code));
    }
  }

  render() {
    const { userInput, emailEntered } = this.state;

    const { user, classes, system, isMobile } = this.props;

    // wait for server to start up
    if (
      !system ||
      !system.wakeup ||
      !system.wakeup.lastMade ||
      system.wakeup.loading
    ) {
      return (
        <div>
          <Card className={classes.container}>
            <LoadingView
              label='Starting Services'
              className={classes.spinner}
            />
          </Card>
        </div>
      );
    }

    let form;
    let buttonText = 'Next';
    let buttonDisabled = true;
    let extraButtonText = null;
    let onExtraButtonClick = null;
    // wait for server to start up
    if (
      user.meta &&
      user.meta.loginIsCalling &&
      new Date().getTime() - user.meta.loginCallTime < 1000 * 30
    ) {
      form = <LoadingView className={classes.spinner} />;
    } else if (!user.meta || !user.meta.authType || !emailEntered) {
      form = (
        <>
          <TextField
            id='email'
            name='email'
            label='Email'
            placeholder='Email'
            onChange={this.onInputChange}
            value={userInput.email}
            className={classes.textField}
            margin='normal'
            fullWidth
            autoFocus
          />
          <div>
            <Typography variant='body2' display='inline'>
              Need help?{' '}
              <a
                href='mailto: support@doctivityhealth.com'
                className={classes.link}
              >
                support@doctivityhealth.com
              </a>
            </Typography>
          </div>
        </>
      );
      buttonDisabled = userInput.email.length < 2;
      buttonText = 'Next';
    } else if (user.meta.authType === 'password') {
      form = (
        <>
          <Typography variant='body2'>{`Email: ${userInput.email}`}</Typography>
          <Button margin='normal' onClick={this.onChangeEmail}>
            Not you? Change Email
          </Button>
          <TextField
            id='password'
            type='password'
            name='password'
            label='Password'
            placeholder='Password'
            onChange={this.onInputChange}
            value={userInput.password}
            className={classes.textField}
            margin='normal'
            fullWidth
            autoFocus
          />
        </>
      );
      extraButtonText = 'Forgot Password';
      onExtraButtonClick = this.onForgotPassword;
      buttonDisabled = userInput.password.length < 2;
      buttonText = 'Sign In';
    } else if (user.meta.authType === 'smsCode') {
      const fields = [];
      if (isMobile) {
        fields.push(
          <TextField
            key={'smsCode-mobile'}
            onChange={(event) => {
              this.onSingleCodeChange(event);
            }}
            value={this.state.userInput.smsCode.join('')}
            autoFocus={true}
            variant='outlined'
            className={classes.mobileCodeInput}
            margin='dense'
            type='tel'
            min='0'
            inputMode='numeric'
            pattern='[0-9]*'
            textcontentype='oneTimeCode'
            autoComplete='one-time-code'
            inputRef={(input) => {
              this.codeInputs.forEach((codeInput, index) => {
                this.codeInputs[index] = input;
              });
            }}
          />
        );
      } else {
        for (let i = 0; i < user.meta.digits; i++) {
          fields.push(
            <TextField
              key={`smsCode${i}`}
              onChange={(event) => {
                this.onCodeChange(event, i);
              }}
              onKeyDown={(event) => {
                if (event.key === 'Backspace') {
                  this.onCodeBackspace(i);
                }
              }}
              onSelect={() => this.onCodeInputSelect(i)}
              value={this.state.userInput.smsCode[i]}
              autoFocus={i === 0}
              variant='outlined'
              className={classes.codeInput}
              margin='dense'
              type='tel'
              min='0'
              inputmode='numeric'
              pattern='[0-9]*'
              textContentType='oneTimeCode'
              autoComplete='one-time-code'
              inputRef={(input) => {
                this.codeInputs[i] = input;
              }}
            />
          );
        }
      }
      form = (
        <>
          <Typography variant='body2'>
            {`Enter the validation code sent to ${user.meta.phone}.`}
          </Typography>
          {fields}
        </>
      );
      extraButtonText = 'Resend Code';
      onExtraButtonClick = () => this.login();
      buttonDisabled = userInput.smsCode.join('').length !== user.meta.digits;
      buttonText = 'Sign In';
    }

    const infoMessages =
      user.meta && user.meta.infoMessage ? user.meta.infoMessage : null;
    const errors =
      user.meta && user.meta.errorMessage ? user.meta.errorMessage : null;

    return (
      <div>
        <Helmet defer={false}>
          <title>Doctivity</title>
        </Helmet>
        <Card className={classes.container}>
          <CardHeader title='Sign In' />
          <CardContent className={classes.cardContent}>
            <form onSubmit={this.onNext}>
              {infoMessages && (
                <Typography variant='body2'>{infoMessages}</Typography>
              )}
              {errors && (
                <Typography variant='body2' color='error'>
                  {errors}
                </Typography>
              )}

              {form}
              <div className={classes.buttonContainer}>
                {extraButtonText && (
                  <>
                    <Button
                      id='extraButton'
                      margin='normal'
                      onClick={onExtraButtonClick}
                    >
                      {extraButtonText}
                    </Button>
                    <div className={classes.spacer} />
                  </>
                )}
                <Button
                  id='login'
                  className={classes.button}
                  type='submit'
                  color='primary'
                  margin='normal'
                  variant='contained'
                  disabled={buttonDisabled}
                  onClick={this.onNext}
                >
                  {buttonText}
                </Button>
              </div>
            </form>
          </CardContent>
        </Card>
        <div className={classes.linksContainer}>
          <a
            href='https://www.doctivityhealth.com/'
            target='_blank'
            className={classes.link}
            rel='noreferrer'
          >
            <Typography>What is Doctivity?</Typography>
          </a>
          <a
            href='https://www.doctivityhealth.com/request-demo'
            target='_blank'
            className={classes.link}
            rel='noreferrer'
          >
            <Typography>I need an account</Typography>
          </a>
        </div>
      </div>
    );
  }

  onInputChange = (event) => {
    const { userInput } = this.state;

    const field = event.target.name;
    userInput[field] = event.target.value;

    this.setState({
      userInput,
    });
  };

  onChangeEmail = () => {
    const { userInput } = this.state;

    this.setState({
      emailEntered: false,
      userInput: { ...userInput, password: '' },
    });
  };

  onCodeInputSelect = (i) => {
    // Place cursor at end so we can guarantee that last digit is most recently pressed digit
    this.codeInputs[i].focus();
    const val = this.codeInputs[i].value;
    this.codeInputs[i].value = '';
    this.codeInputs[i].value = val;
  };

  onCodeBackspace = (i) => {
    const userInput = this.state.userInput;
    const codes = userInput.smsCode;
    codes[i] = '';

    this.setState(
      (state) => ({ userInput: state.userInput }),
      () => {
        if (i > 0) {
          this.codeInputs[i - 1].focus();
        }
      }
    );
  };

  onSingleCodeChange = (event) => {
    const userInput = this.state.userInput;
    const codes = userInput.smsCode;

    const str = event.target.value;
    if (str && str.length === this.props.user.meta.digits) {
      // copy and pasted full code in
      const chars = str.trim().split('');
      for (
        let x = 0;
        x < chars.length && x < this.props.user.meta.digits;
        x++
      ) {
        const intVal = parseInt(chars[x], 10);
        if (!Number.isNaN(intVal)) {
          codes[x] = intVal;
        }
      }
      if (chars.length < this.props.user.meta.digits) {
        this.codeInputs[0].focus();
      } else {
        // submit...
        this.onNext();
      }
    } else {
      // Get last digit so that a user can just type once to replace existing digit
      let isDigits = true;
      const intVals = str.split('').map((ch) => {
        const res = parseInt(ch, 10) % 10;
        if (Number.isNaN(res)) {
          isDigits = false;
        }
        return res;
      });
      if (isDigits && intVals.length <= codes.length) {
        let index = 0;
        for (index = 0; index < intVals.length; index++) {
          codes[index] = intVals[index];
        }
        for (index; index < codes.length; index++) {
          codes[index] = '';
        }

        if (intVals.length < this.props.user.meta.digits - 1) {
          this.codeInputs[0].focus();
        } else {
          // submit...
          this.onNext();
        }
      }
    }

    this.setState((state) => ({ userInput: state.userInput }));
  };

  onCodeChange = (event, i) => {
    const userInput = this.state.userInput;
    const codes = userInput.smsCode;

    const str = event.target.value;
    if (str && str.length === this.props.user.meta.digits) {
      // copy and pasted full code in
      const chars = str.trim().split('');
      for (
        let x = 0;
        x < chars.length && x < this.props.user.meta.digits - i;
        x++
      ) {
        const intVal = parseInt(chars[x], 10);
        if (!Number.isNaN(intVal)) {
          codes[i + x] = intVal;
        }
      }
      if (i + chars.length < this.props.user.meta.digits) {
        this.codeInputs[i + chars.length].focus();
      } else {
        // submit...
        this.onNext();
      }
    } else {
      // Get last digit so that a user can just type once to replace existing digit
      const intVal = parseInt(str, 10) % 10;
      if (!Number.isNaN(intVal)) {
        codes[i] = intVal;

        if (i < this.props.user.meta.digits - 1) {
          this.codeInputs[i + 1].focus();
        } else {
          // submit...
          this.onNext();
        }
      } else {
        codes[i] = '';
      }
    }

    this.setState((state) => ({ userInput: state.userInput }));
  };

  onForgotPassword = () => {
    this.props.router.navigate('/recovery');
  };

  onNext = (event) => {
    if (event) {
      event.preventDefault();
    }

    const { email, password } = this.state.userInput;
    const { user } = this.props;

    if (email && !password) {
      this.checkUser();
      this.setState({ emailEntered: true });
    } else if (user.meta.authType === 'smsCode') {
      this.validateCode();
    } else if (email && password) {
      this.login();
    }
  };
}

LoginPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  user: PropTypes.object,
  system: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    user: state.user,
    system: state.system,
  };
}

const styledLoginPage = withStyles(styles)(withIsMobile(LoginPage));
const connectedLoginPage = connect(mapStateToProps)(styledLoginPage);
const routed = withRouter(connectedLoginPage);
export { routed as LoginPage };
