import React from 'react';
import { PropTypes } from 'prop-types';
import { Box, styled } from '@mui/material';
import { LoadingView } from 'components';

const Card = styled(Box)({
  background: '#FFF',
  boxShadow: '0px 0px 10px #0000001F',
  borderRadius: '10px',
  padding: '10px 15px',
  height: '360px',
});

function AnalyticsCard({ children, isLoading }) {
  return <Card>{isLoading ? <LoadingView /> : children}</Card>;
}

AnalyticsCard.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isLoading: PropTypes.bool,
};

export default AnalyticsCard;
