import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import withStyles from '@mui/styles/withStyles';

import { StoreUtil } from 'doctivity-shared/utils';
import { DynamicTable } from 'components';
import { FavoritesUtil } from 'utils';
import { favoritesByType } from 'store/actions/favoritesActions';
import { LocationsTabs } from './LocationsTabs';

const columns = [
  {
    label: 'Name',
    key: 'name',
    fulltext: true,
  },
  {
    label: 'Address',
    key: 'address1',
    style: { minWidth: 120 },
    format: (address1, location) =>
      location.address2 ? address1 + ', ' + location.address2 : address1,
    filterFormatter: (value, committed) => {
      if (committed) {
        return LocationsUtil.formatAddress1(value);
      }
      return value;
    },
  },
  {
    label: 'City',
    key: 'city',
    fulltext: true,
  },
  {
    label: 'State',
    key: 'state',
    style: { width: 50 },
    filterExact: true,
    autoCapitalize: true,
  },
  {
    label: 'Organization',
    key: 'organization.name',
    fulltext: true,
    style: { width: 320 },
  },
];

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
});

class FavoriteLocationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      query: {
        attributes: [
          'id',
          'name',
          'address1',
          'address2',
          'city',
          'state',
          'postal_code',
        ],
        where: {},
        include: [
          {
            association: 'organization',
            attributes: ['id', 'name', 'primary_location_id'],
            required: false,
            paranoid: false,
          },
        ],
        paranoid: false,
        limit: 25,
        offset: 0,
      },
      showLoading: true,
    };
  }

  componentDidMount() {
    const { favorites } = this.props;

    if (StoreUtil.needsLoadNoCache(favorites)) {
      this.fetchData();
    }
  }

  fetchData() {
    const { dispatch, userId, clientId } = this.props;

    const { query } = this.state;

    dispatch(favoritesByType(clientId, userId, 'Locations', query));
  }

  render() {
    const { favorites, classes } = this.props;

    const { query } = this.state;

    return (
      <>
        <LocationsTabs tab='favorite_locations' />
        <div className={classes.container}>
          <DynamicTable
            header='Favorite Locations'
            columns={columns}
            query={query}
            data={favorites}
            onQueryChange={this.onQueryChange}
            getRowHref={(row) => `/locations/${row.id}`}
          />
        </div>
      </>
    );
  }

  onQueryChange = (query) => {
    this.setState({ query, showLoading: true }, this.fetchData.bind(this));
  };
}

FavoriteLocationsPage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  clientId: PropTypes.number.isRequired,
  favorites: PropTypes.object.isRequired,
  userId: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  const { favorites } = state;

  const userId = state.user ? state.user.id : null;
  const clientId = state.app.selectedClient;

  return {
    userId,
    clientId,
    favorites: FavoritesUtil.getLocationsFromFavorites(
      favorites,
      clientId,
      userId
    ),
  };
}

const styled = withStyles(styles)(FavoriteLocationsPage);
const connected = connect(mapStateToProps)(styled);
export { connected as FavoriteLocationsPage };
