import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_FAVORITES_BY_USER,
  API_FAVORITES_BY_TYPE,
  API_FAVORITES_UPSERT,
  API_FAVORITES_DELETE,
} from 'store/actions/favoritesActions';

function invalidateType(state, action) {
  if (
    state &&
    state.by_type &&
    action &&
    action.meta &&
    action.meta.requestAction &&
    action.meta.requestAction.objectType
  ) {
    const objectType = action.meta.requestAction.objectType;
    Object.keys(state.by_type).forEach((key) => {
      if (key.endsWith(objectType)) {
        delete state.by_type[key];
      }
    });
  }
  if (state.by_user) {
    delete state.by_user;
  }
}

export const favorites = (state = {}, action = null) => {
  let result = ReducerUtil.reduceQuery(
    state,
    action,
    API_FAVORITES_BY_USER,
    'by_user'
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceQuery(
    state,
    action,
    API_FAVORITES_BY_TYPE,
    'by_type'
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceUpsert(state, action, API_FAVORITES_UPSERT);
  if (result.actionConsumed) {
    // insert into list
    if (action.response && action.response.data && result.state.by_user) {
      const key = `${action.response.data.client_id}-${action.response.data.user_id}`;
      if (result.state.by_user[key]) {
        const list = result.state.by_user[key].data;
        list.push(action.response.data);
      }
    }
    // invalidate dashboard lists and user favorites
    invalidateType(result.state, action);
    return result.state;
  }

  result = ReducerUtil.reduceUpsert(state, action, API_FAVORITES_DELETE);
  if (result.actionConsumed) {
    // delete into list
    if (
      result.state.by_user &&
      action &&
      action.meta &&
      action.meta.requestAction &&
      action.meta.requestAction.id
    ) {
      const deletedId = action.meta.requestAction.id;
      Object.keys(result.state.by_user).forEach((key) => {
        const list = result.state.by_user[key].data;
        if (list) {
          const i = list.findIndex((f) => f.id === deletedId);
          if (i >= 0) {
            list.splice(i, 1);
          }
        }
      });
    }

    // invalidate dashboard lists and user favorites
    invalidateType(result.state, action);
    return result.state;
  }

  return state;
};
