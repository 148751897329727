import React, { Component } from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';

import { Dialog, DialogTitle, Stack, Typography } from '@mui/material';

const styles = (theme) => ({
  container: {
    paddingTop: 0,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(2),
  },
  buttonStack: {
    marginLeft: 0,
    marginRight: 0,
    paddingLeft: 0,
    paddingRight: 0,
  },
});

class ProviderCommentDeleteDialog extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, data, open, onClose, onDelete } = this.props;

    return (
      <Dialog
        onClose={onClose}
        aria-labelledby='simple-dialog-title'
        open={open}
      >
        <DialogTitle className={classes.title}>Delete Activity Note</DialogTitle>
        <div className={classes.container}>
          <Typography>
            This action will delete the selected Activity Note for all users within this client. There will be no record of the Activity Note or any of the items on it. This cannot be undone.
          </Typography>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='end'
            spacing={2}
          >
            <Button color='primary' onClick={onClose}>
              Cancel
            </Button>
            <Button variant='contained' color='primary' onClick={() => onDelete(data)}>
              Delete
            </Button>
          </Stack>
        </div>
      </Dialog>
    );
  }
}

ProviderCommentDeleteDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

function mapStateToProps() {
  return {};
}

const styled = withStyles(styles)(ProviderCommentDeleteDialog);
const connected = connect(mapStateToProps)(styled);
export { connected as ProviderCommentDeleteDialog};
