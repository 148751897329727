import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Typography,
  Card,
  CardContent,
  FormControl,
  TextField,
  IconButton,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
} from '@mui/material';
import { GetApp } from '@mui/icons-material';

import DatePicker from '@mui/lab/DatePicker';

import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';
import Chart from 'react-apexcharts';
import { axiosInstance, StringUtil } from 'utils';
import {
  CsvUtil,
  StoreUtil,
  DateUtil,
} from 'doctivity-shared/utils';

import { LoadingView } from 'components';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { listTagNamespacesByClient } from 'store/actions/tagNamespacesActions';
import { listUsers } from 'store/actions/usersActions';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { TagsUtil } from '../utils';
import { ColorUtil } from 'utils';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    minWidth: 260,
  },
});

const minimumStartDate = new Date(2018, 0, 1);

class ProviderCommentsByLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterUser: null,
      labelType: 0,
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.activityNotes)) {
      this.fetchData();
    }

    if (StoreUtil.needsLoadLongCache(this.props.users) || this.props.usersClientId !== this.props.app.selectedClient) {
      this.fetchUsers();
    }

    if (StoreUtil.needsLoadLongCache(this.props.tagNamespaces) || this.props.tagNamespaces.clientId !== this.props.clientId) {
      return this.fetchTags();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient
      || this.state.labelType !== prevState.labelType
      || this.props.dateRange.startDate.getDate() !== prevProps.dateRange.startDate.getDate()
      || this.props.dateRange.endDate.getDate() !== prevProps.dateRange.endDate.getDate()) {
      this.fetchData();

    } else if (StoreUtil.needsLoadMediumCache(this.props.activityNotes)) {
      this.fetchData();

    } else if (
      StoreUtil.hasSavedSinceUpdate(
        this.props.editComment,
        prevProps.editComment
      )
    ) {
      this.fetchData();
    }

    if (
      StoreUtil.needsLoadLongCache(this.props.users) ||
      this.props.usersClientId !== this.props.app.selectedClient
    ) {
      this.fetchUsers();
    }

    if (StoreUtil.needsLoadLongCache(this.props.tagNamespaces) || this.props.tagNamespaces.clientId !== this.props.clientId) {
      return this.fetchTags();
    } else if (!this.state.labelType) {
      const namespaces = TagsUtil.getNamespacesByType('activity_notes', this.props.tagNamespaces) || [];
      if (namespaces.length > 0) {
        // set default first label type
        this.setState({ labelType: namespaces[0].id });
      }
    }
  }

  getFilter() {
    return {
      client_id: this.props.clientId,
      user_id: this.state.filterUser?.id,
      label_type: this.state.labelType,
      comment_date: {
        $gte: DateUtil.formatDateTimeFromDB(this.props.dateRange.startDate),
        $lte: DateUtil.formatDateTimeFromDB(this.props.dateRange.endDate),
      },
    };
  }

  fetchData() {
    if (this.state.labelType) { // don't fetch until we have a label type
      this.props.dispatch(
        loadAnalytics({
          type: 'PROVIDER_COMMENTS_BY_LABEL_TYPE',
          filter: this.getFilter(),
        })
      );
    }
  }

  fetchUsers() {
    const { dispatch, app } = this.props;
    dispatch(listUsers(app.selectedClient));
  }

  fetchTags() {
    this.props.dispatch(
      listTagNamespacesByClient(
        this.props.clientId,
      ),
    );
  }

  fetchCSVDownload() {
    const { filterUser } = this.state;

    axiosInstance
      .post('/Analytics', {
        type: 'PROVIDER_COMMENTS_BY_LABEL_TYPE',
        filter: this.getFilter(),
        format: 'csv',
        headers: {
          'Content-Type': 'text/csv',
        },
      },
      {
        params: {
          selected_client_id: this.props.clientId,
        }
      })
      .then((response) => {
        const filename =
          filterUser && filterUser !== 'All Users'
            ? `${filterUser.first_name}_${
                filterUser.last_name
              }_doctivity_notes_by_label_${moment().format('YY_MM_DD')}.csv`
            : `doctivity_notes_by_label_${moment().format('YY_MM_DD')}.csv`;
        try {
          const data = this.formatCSV(response.data);
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(response.data, filename);
        }
      });
  }

  formatCSV(data) {
    let csvColumns = CsvUtil.stringToMatrix(data);
    return CsvUtil.matrixToString(csvColumns);
  }

  render() {
    const { classes, activityNotes, users } = this.props;

    if (!StoreUtil.isLoaded(activityNotes) || !StoreUtil.isLoaded(users)) {
      return <LoadingView />;
    }

    const categories = [];
    const data = [];
    StoreUtil.getData(activityNotes).forEach((row) => {
      categories.push(row.name);
      data.push(row.activity_note_count);
    });
    const series = [{
      data,
    }];

    const options = [
      'All Users',
      ...StoreUtil.getData(users),
    ];

    const namespaces = TagsUtil.getNamespacesByType('activity_notes', this.props.tagNamespaces) || [];
    let namespace = null;
    if (this.state.labelType) {
      namespace = TagsUtil.findNamespace(this.state.labelType, this.props.tagNamespaces);
    }
    if (!namespace && namespaces.length > 0) {
      namespace = namespaces[0];
    }

    const height = (categories.length * 30) + 100;

    return (
      <div>
        <Card>
          <CardContent>
            <div className={classes.header}>
              <Typography component='h2' variant='h6' color='primary'>
                Activity Notes By Label
              </Typography>
              <div className={classes.pdfButton}>
                <IconButton
                  onClick={() => {
                    this.fetchCSVDownload();
                  }}
                  size='large'
                >
                  <GetApp className={classes.pdfIcon} />
                </IconButton>
              </div>
              <div className={classes.spacer} />
            </div>

            <div className={classes.filters}>
              <FormControl className={classes.typeControl}>
                <InputLabel>Label Type</InputLabel>
                <Select
                  value={namespace?.id || 0}
                  onChange={this.onChangeLabelType}
                  label='Label Type'
                >
                  {!namespaces?.length && (
                    <MenuItem value={0} key={0}>
                      <em>No label types</em>
                    </MenuItem>
                  )}
                  {namespaces?.map((t) => (
                    <MenuItem value={t.id} key={t.id}>
                      {t.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className={classes.filterSpace} />

              <FormControl className={classes.userFilter}>
                <Autocomplete
                  options={options}
                  getOptionLabel={(u) =>
                    u !== 'All Users' ? `${u.last_name}, ${u.first_name}` : u}
                  autoComplete
                  disableClearable
                  defaultValue={'All Users'}
                  value={this.state.filterUser || 'All Users'}
                  onChange={this.onChangeUser}
                  renderInput={(params) => (
                    <TextField {...params} label='User' />
                  )}
                />
              </FormControl>

              <div className={classes.filterSpace} />

              <FormControl className={classes.rangeControl}>
                <DatePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // inputProps={{
                      //   ...params.inputProps,
                      //   readOnly: true,
                      // }}
                    />
                  )}
                  label='From'
                  name='from'
                  value={this.props.dateRange.startDate}
                  minDate={minimumStartDate}
                  maxDate={this.props.dateRange.endDate}
                  onChange={this.onChangeStartDate}
                  inputFormat={DateUtil.LDML_DISPLAY_FORMAT}
                />
              </FormControl>
              <div className={classes.filterSpace} />
              <FormControl className={classes.rangeControl}>
                <DatePicker
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                  label='To'
                  value={this.props.dateRange.endDate}
                  minDate={this.props.dateRange.startDate}
                  maxDate={new Date()}
                  onChange={this.onChangeEndDate}
                  name='to'
                  inputFormat={DateUtil.LDML_DISPLAY_FORMAT}
                />
              </FormControl>
                
              <div className={classes.filterSpace} />
            </div>

            <Chart
              type='bar'
              height={height}
              options={{
                chart: {
                  id: 'ActivityNotesByLabel',
                  toolbar: {
                    show: false,
                  },
                  animations: {
                    enabled: false,
                  },
                  stacked: true,
                },
                yaxis: {
                  labels: {
                    minWidth: 100,
                  },
                },
                xaxis: {
                  categories,
                },
                tooltip: {
                  enabled: false,
                },
                plotOptions: {
                  bar: {
                    horizontal: true,
                  }
                },
                dataLabels: {
                  enabled: true,
                  dropShadow: {
                    enabled: true,
                    left: 0,
                    top: 0,
                    opacity: 0.4,
                  },
                  formatter: StringUtil.numberForChart,
                  offsetY: 0,
                },
                colors: ColorUtil.getColors(7),
              }}
              series={series}
            />
          </CardContent>
        </Card>
      </div>
    );
  }

  onChangeUser = (event, newUser) => {
    this.setState(
      { filterUser: newUser !== 'All Users' ? newUser : null },
      this.fetchData
    );
  };

  onChangeStartDate = (newDate) => {
    if (moment(newDate).isAfter(moment(minimumStartDate))) {
      this.props.onChangeStartDate(newDate);
    }
  };

  onChangeEndDate = (newDate) => {
    this.props.onChangeEndDate(newDate);
  };

  onChangeLabelType = (event) => {
    this.setState({
      labelType: event.target.value
    });
  }
}

ProviderCommentsByLabel.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
  usersClientId: PropTypes.number,
  editComment: PropTypes.object,
  activityNotes: PropTypes.object.isRequired,
  tagNamespaces: PropTypes.object,
  dateRange: PropTypes.object,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    app: state.app,
    user: state.user,
    clientId: state.app.selectedClient,
    users: StoreUtil.get(state.users, StoreUtil.COMMON_LIST),
    usersClientId: state.users.listClientId,
    editComment: StoreUtil.get(state.providerComments, StoreUtil.COMMON_EDIT_ITEM),
    activityNotes: StoreUtil.get(state.analytics, 'PROVIDER_COMMENTS_BY_LABEL_TYPE'),
    tagNamespaces: TagsUtil.getAllNamespaces(state.tagNamespaces),
  };
}

const styled = withStyles(styles)(ProviderCommentsByLabel);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsByLabel };
