import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_ACTIVITYLOG_BY_USER,
  API_ACTIVITYLOG_TABLE,
  API_ACTIVITYLOG_LIST,
  API_ACTIVITYLOG_UPSERT,
} from 'store/actions/activityLogsActions';

export const activityLogs = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_ACTIVITYLOG_TABLE,
    API_ACTIVITYLOG_LIST,
    API_ACTIVITYLOG_UPSERT,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceQuery(state, action, API_ACTIVITYLOG_BY_USER, 'by_user');
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
