import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_ORGANIZATIONS_TABLE,
  API_ORGANIZATIONS_LIST,
  API_ORGANIZATIONS_UPSERT,
  API_ORGANIZATIONS_LOAD,
  API_ORGANIZATIONS_ADD_PROVIDER,
  API_ORGANIZATIONS_ADD_CONTACT,
  API_ORGANIZATIONS_TABLE_CONTACTS,
  API_ORGANIZATIONS_TABLE_PROVIDERS,
} from 'store/actions/organizationsActions';

export const organizations = (state = {}, action = null) => {
  let result = ReducerUtil.simpleReduce(
    state,
    action,
    API_ORGANIZATIONS_TABLE,
    API_ORGANIZATIONS_LIST,
    API_ORGANIZATIONS_UPSERT,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceSingleGet(state, action, API_ORGANIZATIONS_LOAD);
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceTableGet(state, action, API_ORGANIZATIONS_TABLE_PROVIDERS, 'providers');
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceTableGet(state, action, API_ORGANIZATIONS_TABLE_CONTACTS, 'contacts');
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceUpdate(
    state,
    action,
    API_ORGANIZATIONS_ADD_PROVIDER,
    'id',
    false,
    'orgToProviders',
    'orgToProviders',
    false,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  result = ReducerUtil.reduceUpdate(
    state,
    action,
    API_ORGANIZATIONS_ADD_CONTACT,
    'id',
    false,
    'orgToContacts',
    'orgToContacts',
    false,
  );
  if (result.actionConsumed) {
    return result.state;
  }

  return state;
};
