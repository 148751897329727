import { ReducerUtil } from 'doctivity-shared/utils';

import {
  API_ACTIVITY_TABLE,
} from 'store/actions/codesActions';

export const codes = (state = {}, action = null) => {
  if (action.type.startsWith(API_ACTIVITY_TABLE)) {
    let type = action.type;
    let storeId = action.store_id;
    let id = action.oid;
    if (action.meta && action.meta.requestAction) {
      type = action.meta.requestAction.type;
      storeId = action.meta.requestAction.store_id;
      id = action.meta.requestAction.oid;
    }

    const result = ReducerUtil.reduceQuery(state, action, type, storeId);
    if (result.actionConsumed) {
      result.state[storeId].id = id;
      return result.state;
    }
  }
  return state;
};
