import React from 'react';
import { Tab } from '@mui/material';

export function LinkTab(props) {
  return (
    <Tab
      component='a'
      onClick={(event) => {
        event.preventDefault();
      }}
      disableRipple
      {...props}
    />
  );
}

export default LinkTab;
