import React from 'react';
import { Helmet } from 'react-helmet';
import { PropTypes } from 'prop-types';
import {
  Card,
  CardContent,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';

import {
  withRouter,
  TagsUtil,
} from 'utils';

const styles = (theme) => ({
  container: {
    padding: theme.spacing(3),
  },
  condensedCell: {
    padding: 2,
    fontSize: 12,
    fontWeight: 'inherit',
  },
  emptyExpandedRowData: {
    fontSize: 12,
    fontWeight: 'inherit',
  },
  tagType: {
    color: theme.palette.primary.main,
  },
  filterDropDown: {
    width: '15rem',
    textOverflow: 'ellipsis',
    paddingRight: '1rem',
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.accent,
    },
    flex: '1 1 160px',
  },
  noneOption: {
    fontStyle: 'italic',
    color: 'rgb(25,25,25,0.5);',
  },
  dropdownContainer: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: theme.spacing(3),
  },
  select: {
    height: '4rem',
  },
});

class TagsDropdowns extends React.Component {
  render() {
    const { selectedTags, classes, embedded } = this.props;
    const selectedTagsByNamespace =
      selectedTags.reduce((acc, val) => {
        const namespace = TagsUtil.findNamespaceForTagId(val.id, this.props.tagNamespaces);
        if (namespace) {
          if (!acc[namespace.id]) {
            acc[namespace.id] = [];
          }
          acc[namespace.id].push(String(val.id));
        } else {
          console.error('Unable to find namespace for tag id: ', val.id);
        }
        return acc;
      }, {}) ?? {};

    const namespaces = TagsUtil.getNamespacesByType(this.props.type, this.props.tagNamespaces);
    const children = namespaces.map((namespace) => {
      const multiple = namespace.providers_multiple;
      return (
        <FormControl
          variant='outlined'
          className={classes.filterDropDown}
          key={`filter-tag-namespace-${namespace.id}`}
        >
          <InputLabel id={`ns-label-${namespace.id}`} shrink={true}>
            {namespace.name}
          </InputLabel>
          <Select
            notched={true}
            {...(multiple ? { multiple: true } : {})}
            className={classes.select}
            displayEmpty
            labelId={`ns-label-${namespace.id}`}
            value={selectedTagsByNamespace[namespace.id] ?? (multiple ? [] : '')}
            label={namespace.name}
            onChange={this.handleTagNamespaceChange}
            renderValue={(selected) => {
              if (multiple && selectedTagsByNamespace?.[namespace.id]?.length > 0) {
                return selectedTagsByNamespace?.[namespace.id]
                  ?.map((tagId) => TagsUtil.findTag(tagId, this.props.tagNamespaces)?.name || '')
                  .join(', ');
              }
              const selectedTagId = Array.isArray(selected) && selected.length === 1 ? selected[0] : selected;
              const selectedTag = TagsUtil.findTag(selectedTagId, this.props.tagNamespaces);
              if (selectedTag) {
                return selectedTag.name;
              }
              return <em>None</em>;
            }}
          >
            <MenuItem
              key={0}
              value={multiple ? [] : ''}
              namespaceid={namespace.id}
            >
              <span className={classes.noneOption}>
                <em>None</em>
              </span>
            </MenuItem>
            {namespace.tags.map((tag) => (
              <MenuItem
                key={tag.id}
                value={String(tag.id)}
                namespaceid={namespace.id}
              >
                {multiple && (
                  <Checkbox
                    checked={
                      selectedTagsByNamespace?.[namespace.id]?.indexOf(
                        String(tag.id),
                      ) > -1
                    }
                  />
                )}
                <ListItemText>{tag.name}</ListItemText>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    });
    if (embedded) {
      return <div className={classes.dropdownContainer}>{children}</div>;
    }
    return (
      <>
        <Helmet defer={false}>
          <title>Labels</title>
        </Helmet>
        <div className={classes.container}>
          <Card>
            <CardContent className={classes.dropdownContainer}>
              {children}
            </CardContent>
          </Card>
        </div>
      </>
    );
  }

  handleTagNamespaceChange = (event, row) => {
    const namespaceId = row.props.namespaceid;
    if (row.props.value === '' || (Array.isArray(row.props.value) && row.props.value.length === 0)){
      this.props.clearSelectedNamespace(parseInt(namespaceId, 10));
      return;
    }
    const tagId = parseInt(row.props.value, 10);
    const tag = TagsUtil.findTag(tagId, this.props.tagNamespaces);
    this.props.selectTag(tag);
  };
}

TagsDropdowns.propTypes = {
  classes: PropTypes.object.isRequired,
  tagNamespaces: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['all', 'providers', 'activity_notes']),
  embedded: PropTypes.bool,
  selectTag: PropTypes.func,
  clearSelectedNamespace: PropTypes.func,
  selectedTags: PropTypes.arrayOf(PropTypes.object),
};
TagsDropdowns.defaultProps = {
  type: 'all',
  embedded: false,
};

const styled = withStyles(styles)(TagsDropdowns);
const routed = withRouter(styled);
export { routed as TagsDropdowns };
