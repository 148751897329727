import {
  takeEvery,
  put,
} from 'redux-saga/effects';

function* doAction(action) {
  yield put(action.meta.requestAction.nextAction);
}

function checkForAction(action) {
  return action.meta
    && action.meta.requestAction
    && action.type.endsWith('SUCCESS')
    && action.meta.requestAction.nextAction;
}

function* ChainedActionSaga() {
  yield takeEvery(checkForAction, doAction);
}

export default ChainedActionSaga;
